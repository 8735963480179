import DcBaseComponent from 'general/js/dc/dc-base-component';
import createEvent from "../../../general/js/create-event";
import Accordion from "../../accordion/js/accordion.component";

/***
 * Constant for marking event and prevent endless loop of dispatching events
 * @type {string}
 */
const IGNORE_AT_SYNC_PROPERTY_NAME = 'ignoreAtSync';

export default class CommunicationPreferencesComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.contactNumberElementId = this.getChildAttribute(this.element, 'contact-id');
        this.mobileNumberRegexp = this.getChildAttribute(this.element, 'mobile-number-regexp');
    }

    static getNamespace() {
        return 'comm-pref';
    }

    static getRequiredRefs() {
        return ['accordion', 'canContactCheckbox', 'pref'];
    }

    onInit() {
        this.contactNumberInput = document.getElementById(this.contactNumberElementId);
        if (!this.contactNumberInput) {
            throw new Error('form has no contact number field');
        }

        this.textCheckbox = this.findChildrenWithAttribute('text')[0];
        if (this.textCheckbox) {
            this.contactNumberInput.addEventListener('change', this.onContactNumberChange);
        }
        this.accordion = new Accordion(this.refs.accordion);
        this.accordion.init();
        this.refs.canContactCheckbox.addEventListener('change', this.onCanContactCheckboxChange);
        this.refs.pref.forEach(pref => pref.addEventListener('change', this._onPrefChange))
    }

    onContactNumberChange = (e) => {
        if (this.refs.canContactCheckbox.checked && !this.accordion.wasExpanded) {
            if (this.textCheckbox) {
                this.textCheckbox.checked = this.isNumberMobile();
            }
        }
    };

    onCanContactCheckboxChange = (e) => {
        if (this.refs.canContactCheckbox.checked) {
            this.activatePreferences();
        } else {
            this.deactivatePreferences();
        }
    };

    activatePreferences() {
        this.refs.pref.forEach(checkbox => {
            if (checkbox === this.textCheckbox) {
                checkbox.checked = this.isNumberMobile();
            } else {
                checkbox.checked = true;
            }

            const event = createEvent('change');
            event[IGNORE_AT_SYNC_PROPERTY_NAME] = true;
            checkbox.dispatchEvent(event);
        });
    }

    _onPrefChange = (event) => {
        if (event[IGNORE_AT_SYNC_PROPERTY_NAME]) {
            return;
        }

        const hasAnyPref = this.refs.pref.some(pref => pref.checked);

        if (hasAnyPref) {
            if (!this.refs.canContactCheckbox.checked) {
                this.refs.canContactCheckbox.checked = true;
            }
        } else {
            if (this.refs.canContactCheckbox.checked) {
                this.refs.canContactCheckbox.checked = false;
            }
        }
    };

    deactivatePreferences() {
        this.refs.pref.forEach(checkbox => {
            checkbox.checked = false;
            const event = createEvent('change');
            event[IGNORE_AT_SYNC_PROPERTY_NAME] = true;
            checkbox.dispatchEvent(event);
        });
    }

    isNumberMobile() {
        const contactNumberValue = this.contactNumberInput.value;
        return contactNumberValue.match(this.mobileNumberRegexp);
    }

    onDestroy() {
        this.accordion.destroy();
    }
}
