import PropTypes from 'prop-types';
import React, { forwardRef, useState, useEffect } from 'react';

const arrowDirections = {
    up: 'up',
    down: 'down'
};

const Dropdown = forwardRef(({
    id,
    name,
    onSelect = () => {},
    disabled = false,
    options = undefined,
    placeholder = '',
    initialValue = undefined,
    className = undefined,
}, ref) => {
    const [arrowDirection, setArrowDirection] = useState(arrowDirections.up);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [optionsData, setOptionsData] = useState([]);
    const [inputValues, setInputValues] = useState({
        label: '',
        value: ''
    });

    useEffect(() => {
        const closeDropDown = () => {
            if (isDropdownActive) {
                toggleDropdown(false);
            }
        };

        document.addEventListener('click', closeDropDown);

        return () => {
            document.removeEventListener('click', closeDropDown);
        };
    }, [isDropdownActive]);

    useEffect(() => {
        if (initialValue && !inputValues.label) {
            setInputValues({ ...initialValue });
        }
    }, [initialValue, inputValues]);

    useEffect(() => {
        setOptionsData(options);
    }, [options]);

    useEffect(() => {
        setArrowDirection(isDropdownActive ? arrowDirections.up : arrowDirections.down);
    }, [isDropdownActive]);

    const toggleDropdown = (value) => {
        if (value) {
            setIsDropdownActive(true);
            setOptionsData(options);
        } else {
            setIsDropdownActive(false);
        }
    };

    const onOptionSelected = (value, label) => {
        setInputValues({
            label,
            value
        });
        setIsDropdownActive(false);
        onSelect(value, label);
    };

    return (
        <div className={`article-search-dropdown ${className}`}>
            <button
                onClick={() => toggleDropdown(!isDropdownActive)}
                className="article-search-dropdown__btn"
                disabled={disabled}
                type="button"
                aria-expanded={isDropdownActive}
            >
                {inputValues.label ? <span className='article-search-dropdown__btn-text'>{inputValues.label}</span> : (
                    <span className="article-search-dropdown__placeholder">{placeholder}</span>
                )}
                <div className={`article-search-dropdown__arrow article-search-dropdown__arrow--${arrowDirection}`}></div>
            </button>
            <input
                ref={ref}
                defaultValue={inputValues.value}
                type="hidden"
                name={name}
                id={id}
                required={true}
                disabled
            />
            {isDropdownActive && <ul className="article-search-dropdown__list">
                {optionsData.map((option, index) => (
                    <li
                        key={`${id}-${index}`}
                        data-value={option.value}
                        onClick={() => onOptionSelected(option.value, option.label)}
                        className={`article-search-dropdown__list-item
                        ${inputValues.label === option.label ? 'selected' : ''}
                        `}>{option.label}</li>
                ))}
            </ul>
            }
        </div>
    );
});

Dropdown.propTypes = {
    registerName: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    initialValue: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })),
    className: PropTypes.string,
};

export default Dropdown;
