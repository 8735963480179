import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import viewport from 'general/js/viewport';
import Scroller from 'general/js/scroller';
import SUB_NAVIGATION_NAMINGS from './sub-navigation-constants';
import trackerService from '@General/js/tracker-service.js'

const BOTTOM_COORDINATE = 800;

const SubNavigationBottom = ({ ctaButtons }) => {
    const [isVisible, setIsVisible] = useState(viewport.isCoordinateAbove(BOTTOM_COORDINATE));
    const topScrollPoint = document.getElementById('top-scroll-point');
    let observer;

    useEffect(() => {
        return () => {
            onDestroy();
        };
    }, []);

    function onDestroy() {
        if (observer) {
            observer.disconnect();
        }
    }

    const getScroller = (element) => {
        const scroller = new Scroller(element, { duration: 400 });

        return scroller;
    };

    const scrollToTop = (element) => {
        const scroller = getScroller(element);

        return scroller.scrollToTop(true);
    };

    return (
        <>
            <div
                className='cta-block-buttons__grid-item scroll-button is-button-hidden'
                onClick={() => scrollToTop(topScrollPoint)}
            >
                <a
                    className="btn cta-block-buttons__btn bottom-navigation__top-button-wrapper">
                    <div className='bottom-navigation__top-button'>
                        <svg className="icon bottom-navigation__icon"
                            width="20" height="20" focusable="false"
                            role="img" aria-hidden="true">
                            <use xlinkHref="#icon-top"></use>
                        </svg>
                    </div>
                    <span>Top</span>
                </a>
            </div>
            {
                ctaButtons.map((button, idx) => {
                    const iconName = SUB_NAVIGATION_NAMINGS.find((item) =>
                        button.shortText.toLowerCase().includes(item.shortName))?.iconName;
                    const properIconName = button.iconName ? button.iconName : iconName;

                    return (
                        <div
                            key={idx}
                            className={`cta-block-buttons__grid-item ${idx === 0
                                ? 'cta-block-buttons__grid-item--main'
                                : 'cta-block-buttons__grid-item--secondary'}`}>
                            <a
                                href={button.url}
                                onClick={() => { trackerService.track(button.clickTrigger); } }
                                className={`btn cta-block-buttons__btn sub-navigation-buttons--mobile sub-navigation-buttons_btn ${idx === 0
                                    ? 'cta-block-buttons__btn--main'
                                    : 'cta-block-buttons__btn--secondary'}`}>
                                <svg className="icon bottom-navigation__icon" width="21" height="21" focusable="false" role="img" aria-hidden="true">
                                    {
                                        idx === 0
                                            ? (<use xlinkHref={`#icon-${properIconName}-red`}></use>)
                                            : (<use xlinkHref={`#icon-${properIconName}-black`}></use>)
                                    }
                                </svg>
                                {button.shortText}
                            </a>
                            <a
                                href={button.url}
                                onClick={() => { trackerService.track(button.clickTrigger); } }
                                className='btn cta-block-buttons__btn sub-navigation-buttons--desktop cta-block-buttons__btn--main'>
                                {button.text}
                            </a>
                        </div>
                    );
                })
            }
        </>
    );
};

SubNavigationBottom.propTypes = {
    ctaButtons: PropTypes.array.isRequired,
};

export default SubNavigationBottom;
