import React from 'react';
import PropTypes from 'prop-types';

export default function SvgIcon({ name, width, height }) {
    if (name != null && width != null && height != null) {
        return (
            <svg
                className="budget-list__icon icon"
                width={width.toString()}
                height={height.toString()}
                focusable="false"
                role="img"
                aria-hidden="true"
            >
                <use xlinkHref={`#icon-${name}`}></use>
            </svg>
        );
    }
    return null;
}

export const SvgIconType = {
    name: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
};

SvgIcon.propTypes = SvgIconType;
