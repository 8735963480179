import device, { DEVICE_TYPE_TABLET, DEVICE_TYPE_DESKTOP } from 'general/js/device';
import constants from 'general/js/constants';
import HtmlHelper from 'general/js/html-helper';
import styleObserver from 'general/js/style-observer';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import navListTemplate from '../html/nav-list.hbs';
import PageOverviewDesktop from './page-overview-desktop';
import PageOverviewMobile from './page-overview-mobile';

const TYPE_MOBILE = 'mobile';
const TYPE_TABLET = 'tablet';
const TYPE_DESKTOP = 'desktop';
export default class PageOverviewComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.instances = [
            {
                type: TYPE_MOBILE,
                instance: null
            },
            {
                type: TYPE_TABLET,
                instance: null
            },
            {
                type: TYPE_DESKTOP,
                instance: null
            }
        ];

        this.isMobile = device.isViewportTypeLt(DEVICE_TYPE_TABLET);
        this.isDesktop = device.isViewportTypeGe(DEVICE_TYPE_TABLET);
        this.desktopContainer = document.getElementById('page-overview__nav');
    }

    static getNamespace() {
        return 'page-overview';
    }

    static getRequiredRefs() {
        return ['menu', 'placeholder', 'firstTitle', 'itemsContainer', 'close'];
    }

    _isSitecore() {
        return (window.Sitecore && Sitecore.PageModes && Sitecore.PageModes.PageEditor);
    }

    onInit() {
        const items = this._getItemsData();

        if (!this._isSitecore()) {
            this._hideEmptySections();
        }

        if (items.length > 0) {
            this.desktopContainer.classList.add('is-disabled');
            this.mobileLinks = this._createList(items, true);
            this.links = this._createList(items, false);
            this._fillNavigationPlaceholder(items[0]);
            styleObserver.onLoad(() => this._onStylesLoaded());
        }
    }

    _onStylesLoaded() {
        this._onDeviceTypeChanged();
        this.deviceTypeChangeUnsubscribe = device.subscribeOnDeviceTypeChange(this._onDeviceTypeChanged, true);
        this.desktopContainer.classList.remove('is-disabled');
    }

    _getItemsData() {
        let result = [];
        if (this.refs.sections && this.refs.sections.length > 0) {
            result = this.refs.sections
                .filter((sectionElement) => {
                    if (this._isSitecore()) {
                        return true;
                    }

                    return sectionElement.hasChildNodes() && sectionElement.firstChild.hasChildNodes();
                })
                .map((sectionElement) => {
                    const sectionOptionsString = this.getChildAttribute(sectionElement, 'options');
                    const sectionOptions = JSON.parse(sectionOptionsString);
                    return {
                        id: sectionElement.id,
                        show: sectionOptions.showInMenu,
                        title: sectionOptions.menuTitle
                    };
                })
                .filter(item => item.show);
        }

        return result;
    }

    _hideEmptySections() {
        this.refs.sections
            .filter((sectionElement) => !(sectionElement.hasChildNodes() && sectionElement.firstChild.hasChildNodes()))
            .forEach((sectionElement) => {
                sectionElement.style.display = 'none';
                sectionElement.classList.remove('development-page-main__section--show');
            });
    }

    _createList(items, isMobile) {
        if (isMobile) {
            const mobileListHtml = navListTemplate({ items });
            const mobileListElement = HtmlHelper.createElement(mobileListHtml);
            const mobileContainer = document.querySelector('.sub-navigation__full-view');

            mobileContainer.insertAdjacentElement('beforeend', mobileListElement);

            return [...mobileListElement.querySelectorAll('a')];
        }

        const listHtml = navListTemplate({ items });
        const listElement = HtmlHelper.createElement(listHtml);
        this.desktopContainer.insertAdjacentElement('beforeend', listElement);

        return [...listElement.querySelectorAll('a')];
    }

    _fillNavigationPlaceholder(firstItem) {
        if (firstItem) {
            this.refs.firstTitle.innerText = firstItem.title;
        }
    }

    _onDeviceTypeChanged = () => {
        if (this.isDesktop) {
            this._createDesktop();
        } else if (this.isMobile) {
            this._createMobile();
        }
    };

    _createMobile() {
        const mobile = this.instances.find((i) => i.type === TYPE_MOBILE);
        if (mobile.instance) {
            return;
        }
        this._leaveOnlyOneInstance(TYPE_MOBILE);
        const scrollOffset = constants.FIXED_CTA_HEIGHT_MOBILE;
        mobile.instance = new PageOverviewMobile(this.element, this.mobileLinks, this.refs, scrollOffset);
    }

    _createTablet() {
        const tablet = this.instances.find((i) => i.type === TYPE_TABLET);
        if (tablet.instance) {
            return;
        }
        this._leaveOnlyOneInstance(TYPE_TABLET);
        const scrollOffset = constants.FIXED_CTA_HEIGHT_TABLET;
        tablet.instance = new PageOverviewMobile(this.element, this.links, this.refs, scrollOffset);
    }

    _createDesktop() {
        const desktop = this.instances.find((i) => i.type === TYPE_DESKTOP);
        if (desktop.instance) {
            return;
        }
        this._leaveOnlyOneInstance(TYPE_DESKTOP);
        const scrollOffset = constants.FIXED_CTA_HEIGHT_DESKTOP;
        desktop.instances = new PageOverviewDesktop(this.element, this.links, this.refs, scrollOffset);
    }

    _leaveOnlyOneInstance(type) {
        this.instances
            .filter(i => i.type !== type)
            .forEach(i => {
                if (i.instance) {
                    i.instance.destroy();
                    i.instance = null;
                }
            });
    }

    onDestroy() {
        if (this.deviceTypeChangeUnsubscribe) {
            this.deviceTypeChangeUnsubscribe();
            this.deviceTypeChangeUnsubscribe = null;
        }
        this.instances.forEach(i => i.instance.destroy());
    }
}
