
import anime from 'animejs';
import api from 'general/js/api';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';

const DURATION = 400;

export default class NotificationBarComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.closeBarTrigger = this.refs.closeButton;
    }

    static getNamespace() {
        return 'notification-bar';
    }

    onInit() {
        this.closeUrl = this.options.closeUrl;

        if (this.closeBarTrigger && this.closeUrl) {
            this.closeBarTrigger.addEventListener('click', this.handleCloseBar);
        }
    }

    handleCloseBar = () => {
        anime({
            targets: this.element,
            opacity: 0,
            height: 0,
            duration: DURATION,
            easing: 'linear',
            complete: () => {
                api.post(this.closeUrl);
            }
        });
    }
}
