import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentCard from '../../../development-card/js/development-card';

class ParticipatingDevelopmentsSearchCard extends React.PureComponent {
    render() {
        return <DevelopmentCard {...this.props} showButton={true} />;
    }
}

export default ParticipatingDevelopmentsSearchCard;
