import React from 'react';
import nanoId from 'nano-id';
import { createRoot } from 'react-dom/client';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import EventSearch from './event-search';

export default class EventSearchComponent extends DcBaseComponent {
    static getNamespace() {
        return 'event-search';
    }

    onInit() {
        const root = createRoot(this.element);
        root.render(<EventSearch {...this.options} renderId={nanoId()} />);
    }

    onDestroy() {
        root.unmount();
    }
}
