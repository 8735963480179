import React from 'react';
import PropTypes from 'prop-types';
import SearchMobileSorting from '../../../listing-v3/js/components/search-mobile-sorting';
import SearchDesktopSorting from '../../../listing-v3/js/components/search-desktop-sorting';

export const CURRENT_LABEL_STYLES = {
    background: '#000',
    color: '#fff',
};

export const DEFAULT_LABEL_STYLES = {
    background: '#fff',
    color: '#000',
};

export const DISABLED_LABEL_STYLES = {
    background: '#b2b2b2',
    color: '#fff'
};

class DevelopmentsSearchSorting extends React.PureComponent {
    render() {
        const { sortConfig, filters, onFiltersApply, isFiltersMobile } = this.props;
        const name = sortConfig.name;
        const value = filters[name];
        const label = sortConfig.label;
        const currentOption = sortConfig.options.find((option) => option.value === value);
        const currentOptionLabel = currentOption && currentOption.text;

        const searchProps = {
            label,
            onFiltersApply,
            value,
            name,
            sortOptions: sortConfig.options,
            currentOptionLabel,
            currentLabelStyles: CURRENT_LABEL_STYLES,
        };

        return (
            <div className="developments-search__sorting-v2">
                {isFiltersMobile
                    ? <SearchMobileSorting searchProps={searchProps} />
                    : <SearchDesktopSorting searchProps={searchProps} />

                }
            </div>
        );
    }
}

DevelopmentsSearchSorting.propTypes = {
    sortConfig: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    isFiltersMobile: PropTypes.bool.isRequired
};

export default DevelopmentsSearchSorting;
