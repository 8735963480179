import DcBaseComponent from 'general/js/dc/dc-base-component';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';
import $ from 'jquery';
import 'slick-carousel';
import device, {
    DEVICE_TYPE_DESKTOP,
    DEVICE_TYPE_DESKTOP_WIDE,
    DEVICE_TYPE_MOBILE,
    DEVICE_TYPE_TABLET,
} from '../../../general/js/device';
import viewport from '../../../general/js/viewport';

export default class ParticipatingDevelopmentsCarouselComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.slideCarousel = null;
    }

    static getNamespace() {
        return 'participating-developments-carousel';
    }

    /*static getRequiredRefs() {
        return ['items'];
    }*/

    onViewportResize = () => {
        this.equalHeight('.development-card__title');
    };

    onInit() {
        const cardSlides = $('.participating-developments__slides-wrapper');
        if (cardSlides.length) {
            const slidesCount = cardSlides[0].children.length;
            if (slidesCount > 0) {
                this.initSlider(cardSlides);

                this.equalHeight('.development-card__title');
                viewport.subscribeOnResize(this.onViewportResize);
            }
        }
    }

    initSlider(cardSlides) {
        if (!cardSlides) {
            throw new Error('Slider should contain at least 2 sliders and navs/dots');
        }

        const $cardSlides = $(cardSlides);
        this.slideCarousel = new SlickResponsive(
            $cardSlides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                fade: false,
                accessibility: true,
                draggable: false,
                infinite: false,
                swipe: true,
                touchMove: false,
                arrows: true,
                mobileFirst: true,
                rows: 0,
            },
            {
                [DEVICE_TYPE_MOBILE]: {
                    slidesToShow: 1.18,
                },
                [DEVICE_TYPE_TABLET]: {
                    slidesToShow: 2,
                },
                [DEVICE_TYPE_DESKTOP_WIDE]: {
                    slidesToShow: 3,
                },
            }
        );
    }

    onDestroy() {
        if (this.slideCarousel !== null) {
            this.slideCarousel.slick('unslick');
            this.slideCarousel = null;
        }
    }

    equalHeight(element) {
        const elements = Array.from(document.querySelectorAll(element));
        const allHeights = [];

        elements.forEach((el, i) => {
            el.style.height = 'auto';
            allHeights.push(el.clientHeight);
        });

        elements.forEach((el, i) => {
            el.style.height = Math.max.apply(Math, allHeights) + 'px';
        });
    }
}
