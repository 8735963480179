import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import FaqSearch from './faq-search';

export default class FaqSearchComponent extends DcBaseComponent {
    static getNamespace() {
        return 'faq-search';
    }

    onInit() {
        const { predictiveSearchUrl, placeholderMessage, noResultsSuggestionText, additionText, defaultItemsToShow, maxItemsToShow } = this.options;
        ReactDOM.render(
            (
                <FaqSearch
                    predictiveSearchUrl={predictiveSearchUrl}
                    noResultsSuggestionText={noResultsSuggestionText}
                    additionText={additionText}
                    placeholderMessage={placeholderMessage}
                    defaultItemsToShow={defaultItemsToShow}
                    maxItemsToShow={maxItemsToShow}
                />
            ), this.element
        );
    }
}
