const tooltipPosition = (event) => {
    // if tooltip interaction
    if (event.target.hasAttribute('data-tooltip-trigger') && event.target.closest('[data-tooltip]') && event.target.closest('[data-tooltip]').querySelector('[data-tooltip-content]')) {
        // get the tooltip elements
        const tooltipTrigger = event.target;
        const tooltip = tooltipTrigger.closest('[data-tooltip]');
        const tooltipContent = tooltip.querySelector('[data-tooltip-content]');

        // remove any existing settings
        tooltipContent.removeAttribute('style');

        // set default positioning
        let offsetX = 0;
        let maxLeft = 20;
        let maxRight = document.body.clientWidth - 20;

        // if tooltip within container
        if (tooltipContent.closest('[data-tooltip-container]')) {
            // get the container
            const tooltipContainer = tooltipContent.closest('[data-tooltip-container]');

            // set the new positioning values
            tooltipContent.style.maxWidth = `${tooltipContainer.getBoundingClientRect().width}px`;
            maxLeft = tooltipContainer.getBoundingClientRect().left < maxLeft ? maxLeft : tooltipContainer.getBoundingClientRect().left;
            maxRight = tooltipContainer.getBoundingClientRect().right > maxRight ? maxRight : tooltipContainer.getBoundingClientRect().right;
        }

        // if tooltip is forced right but doesn't fit
        if (tooltip.classList.contains('tooltip--right') && tooltipContent.getBoundingClientRect().right > maxRight) {
            // remove force right class
            tooltip.classList.remove('tooltip--right');
        }

        // prevent going off left
        if (tooltipContent.getBoundingClientRect().left < maxLeft) {
            offsetX = Math.abs(tooltipContent.getBoundingClientRect().left - maxLeft);
        } else if (tooltipContent.getBoundingClientRect().right > maxRight) { // prevent going off right
            offsetX = (maxRight - tooltipContent.getBoundingClientRect().right);
        }

        // if offsetX set
        if (offsetX !== 0) {
            // move the tooltip content
            tooltipContent.style.transform = `translateX(calc(-50% + ${offsetX}px))`;
        }

        // set content to appear above trigger if in lower half of screen
        if (tooltipTrigger.getBoundingClientRect().top > window.innerHeight / 2) {
            tooltip.classList.add('tooltip--above');
        } else {
            tooltip.classList.remove('tooltip--above');
        }

        // prevent two tooltips open at same time
        const tooltipsWithFocus = document.querySelector('[data-tooltip-trigger]:focus');

        if (tooltipsWithFocus && tooltipsWithFocus !== tooltipTrigger) {
            tooltipTrigger.focus();
        }

        tooltip.classList.add('tooltip--active');
    }
}

const tooltipReady = () => {
    document.addEventListener('focusin', tooltipPosition);
    document.addEventListener('mouseover', tooltipPosition);
}

document.addEventListener('DOMContentLoaded', tooltipReady);
