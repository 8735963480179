import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import AccordionManager from './accordion-manager';
import TabsManager from './tabs-manager';

export default class CollapsibleTabsComponent extends DcBaseComponent {
    constructor(element, beforeNext) {
        super(element);

        this.beforeNext = beforeNext;
        this.deviceTypeSubscriber = null;
        this.instance = null;
        this.isTablet = false;
        this.isInited = false;
    }

    static getNamespace() {
        return 'collapsible-tabs';
    }

    static getRequiredRefs() {
        return ['accordionInstances', 'tabsInstances'];
    }

    onInit() {
        this.deviceTypeSubscriber = device.subscribeOnDeviceTypeChange(
            () => this._onDeviceTypeChanged(),
            true
        );
    }

    _onDeviceTypeChanged = () => {
        const isTablet = device.isViewportTypeGe(DEVICE_TYPE_TABLET);

        if (isTablet) {
            if (!this.isInited || !this.isTablet) {
                this._createTabletView();
            }
        } else {
            if (!this.isInited || this.isTablet) {
                this._createMobileView();
            }
        }

        this.isInited = true;
    };

    _createMobileView() {
        this._destroyInstance();
        this.instance = new AccordionManager(false, this.element, this.refs, { beforeNext: this.beforeNext });
        this.isTablet = false;
    }

    _createTabletView() {
        this._destroyInstance();
        this.instance = new TabsManager(this.element, this.refs, { beforeNext: this.beforeNext });
        this.isTablet = true;
    }

    _destroyInstance() {
        if (this.instance) {
            this.instance.destroy();
        }
    }

    onDestroy() {
        this._destroyInstance();
    }
}
