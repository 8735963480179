import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ArticleList from './components/article-list';

export default class ArticleListComponent extends DcBaseComponent {

    static getNamespace() {
        return 'article-list';
    }

    onInit() {
        this.render();
    }

    render() {
        ReactDOM.render(<ArticleList model={this.options} />, this.element);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
