import constants from '../constants';
import GoogleMarkerDevelopment from './google-marker-development';
import GoogleMarkerDoctor from './google-marker-doctor';
import GoogleMarkerPharmacy from './google-marker-pharmacy';
import GoogleMarkerBus from './google-marker-bus';
import GoogleMarkerTrain from './google-marker-train';
import GoogleMarkerLeisure from './google-marker-leisure';
import GoogleMarkerPost from './google-marker-post';
import GoogleMarkerBank from './google-marker-bank';
import GoogleMarkerBell from './google-marker-bell';
import GoogleMarkerCinema from './google-marker-cinema';
import GoogleMarkerCoffeeShop from './google-marker-coffee-shop';
import GoogleMarkerDentist from './google-marker-dentist';
import GoogleMarkerHairdresser from './google-marker-hairdresser';
import GoogleMarkerKey from './google-marker-key';
import GoogleMarkerLibrary from './google-marker-library';
import GoogleMarkerMuseum from './google-marker-museum';
import GoogleMarkerOpenSpace from './google-marker-open-space';
import GoogleMarkerOpticians from './google-marker-opticians';
import GoogleMarkerPetrolStation from './google-marker-petrol-station';
import GoogleMarkerPlaceOfWorship from './google-marker-place-of-worship';
import GoogleMarkerPublicHouse from './google-marker-public-house';
import GoogleMarkerRestaurant from './google-marker-restaurant';
import GoogleMarkerShopping from './google-marker-shopping';
import GoogleMarkerSpade from './google-marker-spade';
import GoogleMarkerTheatre from './google-marker-theatre';

export default class MarkerCreator {
    static create(type, ...rest) {
        switch (type) {
            case constants.MARKER_TYPE_DEVELOPMENT:
                return new GoogleMarkerDevelopment(...rest);
            case constants.MARKER_TYPE_BANK:
                return new GoogleMarkerBank(...rest);
            case constants.MARKER_TYPE_BELL:
                return new GoogleMarkerBell(...rest);
            case constants.MARKER_TYPE_BUS:
                return new GoogleMarkerBus(...rest);
            case constants.MARKER_TYPE_CINEMA:
                return new GoogleMarkerCinema(...rest);
            case constants.MARKER_TYPE_COFFEE_SHOP:
                return new GoogleMarkerCoffeeShop(...rest);
            case constants.MARKER_TYPE_DENTIST:
                return new GoogleMarkerDentist(...rest);
            case constants.MARKER_TYPE_DOCTOR:
                return new GoogleMarkerDoctor(...rest);
            case constants.MARKER_TYPE_HAIRDRESSER:
                return new GoogleMarkerHairdresser(...rest);
            case constants.MARKER_TYPE_KEY:
                return new GoogleMarkerKey(...rest);
            case constants.MARKER_TYPE_LEISURE:
                return new GoogleMarkerLeisure(...rest);
            case constants.MARKER_TYPE_LIBRARY:
                return new GoogleMarkerLibrary(...rest);
            case constants.MARKER_TYPE_MUSEUM:
                return new GoogleMarkerMuseum(...rest);
            case constants.MARKER_TYPE_OPEN_SPACE:
                return new GoogleMarkerOpenSpace(...rest);
            case constants.MARKER_TYPE_OPTICIANS:
                return new GoogleMarkerOpticians(...rest);
            case constants.MARKER_TYPE_PETROL_STATION:
                return new GoogleMarkerPetrolStation(...rest);
            case constants.MARKER_TYPE_PHARMACY:
                return new GoogleMarkerPharmacy(...rest);
            case constants.MARKER_TYPE_PLACE_OF_WORSHIP:
                return new GoogleMarkerPlaceOfWorship(...rest);
            case constants.MARKER_TYPE_POST:
                return new GoogleMarkerPost(...rest);
            case constants.MARKER_TYPE_PUBLIC_HOUSE:
                return new GoogleMarkerPublicHouse(...rest);
            case constants.MARKER_TYPE_RESTAURANT:
                return new GoogleMarkerRestaurant(...rest);
            case constants.MARKER_TYPE_SHOPPING:
                return new GoogleMarkerShopping(...rest);
            case constants.MARKER_TYPE_SPADE:
                return new GoogleMarkerSpade(...rest);
            case constants.MARKER_TYPE_THEATRE:
                return new GoogleMarkerTheatre(...rest);
            case constants.MARKER_TYPE_TRAIN:
                return new GoogleMarkerTrain(...rest);
            default:
                throw new Error(`Unknown pin type ${type}`);
        }
    }
}
