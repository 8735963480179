import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class DevTablePagination extends React.PureComponent {
    getTotalPages = (pageSize, totalCount) => {
        if (totalCount <= pageSize) {
            return 1;
        }

        return Math.ceil(totalCount / pageSize);
    };

    getActivePage = (offset, pageSize) => {
        return offset / pageSize;
    };

    getOffset = (page, pageSize) => {
        return page * pageSize;
    };

    createDisplayedPages = (page, totalPages) => {
        if (page >= totalPages) {
            throw new Error('page must be in totalPages interval');
        }

        if (totalPages <= constants.MAX_PAGES_DISPLAYED) {
            return Array.from({ length: totalPages }, (x, i) => i); // [0, 1, 2]
        }

        if (page < constants.MAX_PAGES_DISPLAYED / 2) {
            return Array.from({ length: constants.MAX_PAGES_DISPLAYED }, (x, i) => i); // [0, 1, 2, 3, 4]
        }

        if (page > totalPages - constants.MAX_PAGES_DISPLAYED / 2) {
            return Array.from(
                { length: constants.MAX_PAGES_DISPLAYED },
                (x, i) => i + totalPages - constants.MAX_PAGES_DISPLAYED
            ); // [10, 11, 12, 13, 14]
        }

        const start = page - Math.floor(constants.MAX_PAGES_DISPLAYED / 2);

        return Array.from({ length: constants.MAX_PAGES_DISPLAYED }, (x, i) => i + start); // [7, 8, 9, 10, 11]
    };

    isNewActivePageOnDisplay = (page) => {
        return this.currentDisplayedPages.includes(page);
    };

    isFirstPageOnDisplay = () => {
        return this.currentDisplayedPages.includes(0);
    };

    isLastPageOnDisplay = () => {
        return this.currentDisplayedPages.includes(this.lastPage);
    };

    onLinkClick = (page) => {
        return () => {
            this.props.onPageClick(
                this.getOffset(page, constants.MAX_PROPS_ON_PAGE),
                this.currentDisplayedPages
            );
        };
    };

    render() {
        this.totalPages = this.getTotalPages(
            constants.MAX_PROPS_ON_PAGE,
            this.props.pagination.totalCount
        );
        this.activePage = this.getActivePage(
            this.props.pagination.offset,
            constants.MAX_PROPS_ON_PAGE
        );
        this.firstPage = 0;
        this.lastPage = this.totalPages - 1;
        this.currentDisplayedPages = this.props.currentDisplayedPages;

        if (!this.isNewActivePageOnDisplay(this.activePage)) {
            this.currentDisplayedPages = this.createDisplayedPages(
                this.activePage,
                this.totalPages
            );
        }

        return (
            <div
                className={classNames({
                    'dev-table-pagination': true,
                    'dev-table-pagination--hidden': !this.props.isExpanded,
                })}
            >
                {
                    this.totalPages > 1 &&
                <ul className="dev-table-pagination__list">
                    <li className="dev-table-pagination__item">
                        <a
                            className={classNames({
                                'dev-table-pagination__link': true,
                                'dev-table-pagination__link--arrow': true,
                                'dev-table-pagination__link--active':
                                    this.activePage !== this.firstPage,
                            })}
                            onClick={
                                this.activePage !== this.firstPage
                                    ? this.onLinkClick(this.activePage - 1)
                                    : () => {}
                            }
                        >
                            <svg className="icon" width="4" height="8">
                                <use xlinkHref="#icon-arrow-left-thin"></use>
                            </svg>
                        </a>
                    </li>
                    {this.currentDisplayedPages.map((page, i) => (
                        <li className="dev-table-pagination__item" key={i}>
                            <a
                                className={classNames({
                                    'dev-table-pagination__link': true,
                                    'dev-table-pagination__link--active': page === this.activePage,
                                })}
                                onClick={
                                    page !== this.activePage ? this.onLinkClick(page) : () => {}
                                }
                            >
                                {page + 1}
                            </a>
                        </li>
                    ))}
                    {!this.isLastPageOnDisplay() && (
                        <>
                            <li className="dev-table-pagination__item">
                                <a className="dev-table-pagination__link">...</a>
                            </li>
                            <li className="dev-table-pagination__item">
                                <a
                                    className="dev-table-pagination__link"
                                    onClick={this.onLinkClick(this.lastPage)}
                                >
                                    {this.lastPage + 1}
                                </a>
                            </li>
                        </>
                    )}
                    <li className="dev-table-pagination__item dev-table-pagination__item--ml-4">
                        <a
                            className={classNames({
                                'dev-table-pagination__link': true,
                                'dev-table-pagination__link--arrow': true,
                                'dev-table-pagination__link--active':
                                    this.activePage !== this.lastPage,
                            })}
                            onClick={
                                this.activePage !== this.lastPage
                                    ? this.onLinkClick(this.activePage + 1)
                                    : () => {}
                            }
                        >
                            <svg className="icon" width="4" height="8">
                                <use xlinkHref="#icon-arrow-right-thin"></use>
                            </svg>
                        </a>
                    </li>
                </ul>
                }
            </div>
        );
    }
}

DevTablePagination.propTypes = {
    isExpanded: PropTypes.bool,
    onPageClick: PropTypes.func,
    currentDisplayedPages: PropTypes.arrayOf(PropTypes.number),
    pagination: PropTypes.shape({
        offset: PropTypes.number,
        totalCount: PropTypes.number,
    }),
};

export default withContext(DevTablePagination, {
    currentDisplayedPages: 'currentDisplayedPages',
    pagination: 'pagination',
    onPageClick: 'onPageClick',
});
