import React from 'react';
import PropTypes from 'prop-types';
import TabsList from './tabs-list.jsx';
import SortingRow from './sorting-row.jsx';
import PropertyDisplay from './property-display.jsx';
import Pagination from './pagination.jsx';
import Spinner from '../../../spinner/js/spinner.jsx';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class PropertyTable extends React.PureComponent {
    componentDidUpdate(prevProps) {
        if (this.props.isOnMobileViewport !== prevProps.isOnMobileViewport) {
            this.setNewPageSizeData();
        }
    }

    changeActiveTab = (tab) => {
        const partialState = {
            activeTab: tab
        };
        const params = {
            pageSize: this.props.currentPageSize,
            offset: 0,
            channel: partialState.activeTab.id,
            orderBy: constants.HEADINGS[constants.DEFAULT_ACTIVE_HEAD].id + constants.SORT_TYPES[constants.DEFAULT_ACTIVE_SORT].id
        };
        this.props.getData(params, partialState);
    };

    changeActiveSort = (headIndex, sortIndex) => {
        const partialState = {
            activeHead: headIndex,
            activeSortType: sortIndex,
            activeTab: this.props.activeTab
        };
        const params = {
            pageSize: this.props.currentPageSize,
            offset: 0,
            channel: this.props.activeTab.id,
            orderBy: constants.HEADINGS[partialState.activeHead].id + constants.SORT_TYPES[partialState.activeSortType].id
        };
        this.props.getData(params, partialState);
    };

    changeActivePage = (offset, currentDisplayedPages) => {
        const partialState = {
            pagination: {
                ...this.props.pagination,
                offset: offset
            },
            currentDisplayedPages: currentDisplayedPages,
            activeHead: this.props.activeHead,
            activeSortType: this.props.activeSortType,
            activeTab: this.props.activeTab
        };
        const params = {
            pageSize: partialState.pagination.pageSize,
            offset: partialState.pagination.offset,
            channel: this.props.activeTab.id,
            orderBy: constants.HEADINGS[partialState.activeHead].id + constants.SORT_TYPES[partialState.activeSortType].id
        };
        this.props.getData(params, partialState);
    };

    setNewPageSizeData = () => {
        const partialState = {
            pagination: {
                ...this.props.pagination,
                pageSize: this.props.currentPageSize,
                offset: 0,
            },
        };
        const params = {
            pageSize: this.props.currentPageSize,
            offset: 0,
            channel: this.props.activeTab.id,
            orderBy: constants.HEADINGS[this.props.activeHead].id + constants.SORT_TYPES[this.props.activeSortType].id
        };
        this.props.getData(params, partialState);
    };

    getCurrentPropertiesPosition = () => {
        const { offset, pageSize, totalCount } = this.props.pagination;

        if (totalCount === 0) {
            return '0-0';
        }

        const start = offset + 1;
        const end = start + pageSize - 1;

        if (end > totalCount) {
            return start + '-' + totalCount;
        }

        return start + '-' + end;
    };

    render() {
        const propertyAmountText = `Showing ${this.getCurrentPropertiesPosition()} of ${this.props.pagination.totalCount} properties 
        ${this.props.tabs.length === 1
                ? this.props.tabs[0].textName
                : this.props.activeTab.textName}`;
        return (
            <div className="property-table">
                <div className="property-table__container-row">
                    <TabsList
                        onTabClick={this.changeActiveTab}
                    />
                </div>
                <div className="property-table__container-row property-table__container-row--border-top">
                    <div className="property-table__tab-section">
                        <p className="property-table__amount-text">{propertyAmountText}</p>
                        {!this.props.isTextShowing && (
                            <>
                                <table className="property-table__table">
                                    <SortingRow
                                        headings={constants.HEADINGS}
                                        sortTypes={constants.SORT_TYPES}
                                        onSortClick={this.changeActiveSort}
                                    />
                                    <PropertyDisplay />
                                </table>
                                <Pagination
                                    onPageClick={this.changeActivePage}
                                />
                            </>
                        )}
                        {this.props.isTextShowing && (
                            <div className="property-table__message">
                                <h4 className="property-table__message-title">{this.props.message.title}</h4>
                                <p className="property-table__message-text">{this.props.message.text}</p>
                            </div>
                        )}
                    </div>
                </div>
                {this.props.isGettingData &&
                    <div className='property-table__spinner-container'>
                        <Spinner isActive={true} />
                    </div>
                }
            </div>
        );
    }
}

PropertyTable.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    })),
    currentPageSize: PropTypes.number,
    getData: PropTypes.func,
    isOnMobileViewport: PropTypes.bool,
    isTextShowing: PropTypes.bool,
    message: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string
    }),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    }),
    activeHead: PropTypes.number,
    activeSortType: PropTypes.number,
    pagination: PropTypes.shape({
        offset: PropTypes.number,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number
    })
};

export default withContext(PropertyTable, {
    tabs: 'tabs',
    currentPageSize: 'currentPageSize',
    getData: 'getData',
    isOnMobileViewport: 'isOnMobileViewport',
    isTextShowing: 'isTextShowing',
    isGettingData: 'isGettingData',
    message: 'message',
    activeTab: 'activeTab',
    activeHead: 'activeHead',
    activeSortType: 'activeSortType',
    pagination: 'pagination'
});
