import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '@General/js/api';
import BudgetMessage from './components/budget-message.jsx';
import BudgetCalculator from './budget-calculator.jsx';
import { BudgetCalculatorStoreProvider } from './store/budget-calculator-store.jsx';
import { LOADING_MESSAGE, ERROR_MESSAGE } from './constants.js';
import Filter from './store/filter.js';

export default function BudgetLoader({ options }) {
    const [state, setState] = useState({
        message: LOADING_MESSAGE,
        isMessageShown: true,
        model: null,
        isHidden: false,
    });

    useEffect(() => {
        if (
            options != null &&
            typeof options === 'object' &&
            typeof options.propertyTypesApiUrl === 'string'
        ) {
            api.get(options.propertyTypesApiUrl)
                .then(({ data }) => {
                    if (Filter.isFilterValid(data)) {
                        setState({
                            model: { ...options, propertyTypeFilter: data },
                            isHidden: false,
                            isMessageShown: false,
                            message: null,
                        });
                    } else {
                        console.log(
                            'Budget calculator did not receive propertyType data and was hidden'
                        );
                        setState({
                            model: null,
                            isHidden: true,
                            isMessageShown: false,
                            message: null,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setState({
                        model: null,
                        isHidden: false,
                        isMessageShown: true,
                        message: ERROR_MESSAGE,
                    });
                });
        } else if (
            options != null &&
            typeof options === 'object' &&
            typeof options.settings === 'object'
        ) {
            if (Filter.isFilterValid(options.settings[0])) {
                setState({
                    model: {
                        ...options,
                        periodButtonsDisplayed: options.buttonsSettings.billFrequencyButtonsSettings,
                        propertyTypeFilter: options.settings[0],
                        productTypeFilter: { options: options.settings },
                        isGenericCalculator: true,
                        services: options.settings[0].services, 
                    },
                    isHidden: false,
                    isMessageShown: false,
                    message: null,
                });
            }
        } else {
            setState({
                model: null,
                isHidden: true,
                isMessageShown: false,
                message: null,
            });
        }
    }, []);

    if (state.isMessageShown) {
        return <BudgetMessage title={state.message.title} text={state.message.text} />;
    }

    if (state.isHidden) {
        return <div></div>;
    }

    return (
        <BudgetCalculatorStoreProvider options={state.model}>
            <BudgetCalculator />
        </BudgetCalculatorStoreProvider>
    );
}

BudgetLoader.propTypes = {
    options: PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        propertyTypesApiUrl: PropTypes.string,
        services: PropTypes.arrayOf(
            PropTypes.shape({
                colorHex: PropTypes.string,
                defaultPrice: PropTypes.number,
                hasTooltip: PropTypes.bool,
                icon: PropTypes.string,
                maxPrice: PropTypes.number,
                minPrice: PropTypes.number,
                title: PropTypes.string,
                tooltip: PropTypes.shape({
                    icon: PropTypes.string,
                    text: PropTypes.string,
                }),
            })
        ),
    }),
};
