import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentsSearchFilters from '../components/developments-search-filters';
import DevelopmentsSearchSorting from '../components/developments-search-sorting';
import { withContext } from '../context';

class DevelopmentsSearchControlsSection extends React.PureComponent {
    render() {
        const {
            filtersConfig, filters, onFiltersApply, isFiltersMobile
        } = this.props;

        if (!filtersConfig || filtersConfig.length === 0) {
            return null;
        }

        const sortConfig = filtersConfig.find(filter => filter.isSort);
        const restFilters = filtersConfig.filter(filter => filter !== sortConfig);
        return (
            <div className="developments-search__controls-section">
                <div className="section">
                    <div className="container">
                        <div className="developments-search__controls-section-inner">
                            <DevelopmentsSearchFilters filtersConfig={restFilters}
                                isFiltersMobile={isFiltersMobile}/>
                            {sortConfig ?
                                <DevelopmentsSearchSorting sortConfig={sortConfig}
                                    filters={filters}
                                    onFiltersApply={onFiltersApply}/> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DevelopmentsSearchControlsSection.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    isFiltersMobile: PropTypes.bool.isRequired,
};

export default withContext(DevelopmentsSearchControlsSection, {
    filtersConfig: 'filtersConfig',
    filters: 'filters',
    onFiltersApply: 'onFiltersApply',
    isFiltersMobile: 'isFiltersMobile',
});
