import React from 'react';
import PropTypes from 'prop-types';
import SuggestionGroup from './suggestion-group';

const SuggestionsGroups = function ({ groups, onHighlightedElementUpdate }) {
    return (
        <React.Fragment>
            {groups.map(group =>
                <SuggestionGroup
                    onHighlightedElementUpdate={onHighlightedElementUpdate}
                    key={group.id}
                    group={group}/>)}
        </React.Fragment>
    );
};

SuggestionsGroups.propTypes = {
    groups: PropTypes.array.isRequired,
    onHighlightedElementUpdate: PropTypes.func.isRequired,
};

export default SuggestionsGroups;
