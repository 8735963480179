import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import ParticipatingDevelopmentsSearchList from './participating-developments-search-list';

class ParticipatingDevelopmentsSearchResults extends React.PureComponent {
    render() {
        const { items } = this.props;

        if (items.length === 0) {
            return null;
        }

        return (
            <div className="participating-developments-search__results">
                <ParticipatingDevelopmentsSearchList items={items}/>
            </div>
        );
    }
}

ParticipatingDevelopmentsSearchResults.propTypes = {
    items: PropTypes.array.isRequired
};

export default withContext(ParticipatingDevelopmentsSearchResults, {
    items: 'items'
});
