import React from 'react';
import PropTypes from 'prop-types';

const FeaturedArticleCard = (props) => {
    const { categoryTitle, description, link, tagText, thumbnailImageAlt, thumbnailImageSrc, title } = props;
    return (
        <a href={link} title={title} className='featured-article-card'>
            <img
                className="lazyload"
                data-sizes="40vw"
                alt={thumbnailImageAlt}
                width={585}
                height={390}
                data-srcset={thumbnailImageSrc}
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            />

            <div className="featured-article-card__info-container">
                <div>
                    <div className="featured-article-card__tags">
                        {tagText && <div className='featured-article-card__featured-tag'>{tagText}</div>}
                        {categoryTitle && <div className='featured-article-card__category-tag'>{categoryTitle}</div>}
                    </div>
                    <div className='featured-article-card__title'>{title}</div>
                    <div className="featured-article-card__description richtext" dangerouslySetInnerHTML={{ __html: description }}></div>
                </div>
                
            </div>
        </a>
    );
};

FeaturedArticleCard.propTypes = {
    categoryTitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    tagText: PropTypes.string,
    thumbnailImageAlt: PropTypes.string,
    thumbnailImageSrc: PropTypes.string,
    title: PropTypes.string,

};

export default FeaturedArticleCard;
