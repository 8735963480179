export const COLORS = {
    fillColor: 'rgba(255, 255, 255, 0.5)',
    lineWidth: 4,
    greyColor: '#b7b7b7',
    redColor: '#d3164b',
    greenColor: '#2B784C',
    blackColor: '#000000',
    whiteColor: '#FFFFFF',
    strokeColor: 'rgba(0, 0, 0, 0.5)',
    indigoColor: '#3C165A',
    sunriseColor: '#F6A200',
    sandColor: '#CABC96',
    hoverColor: 'rgba(38, 166, 91, 0.5)',
    hoverIndigoColor: 'rgba(61, 22, 90, 0.5)',
    hoverSunriseColor: 'rgba(246, 164, 0, 0.5)',
    hoverSandColor: 'rgba(202, 188, 150, 0.5)',
};
export const DEFAULT_NUMBER_DESKTOP = 16;
export const DEFAULT_NUMBER_MOBILE = 8;
export const SHAPE_RECT = 'rect';
export const SHAPE_POLY = 'poly';
export const AREA_TYPE_UNRELEASED = {
    mainColor: COLORS.greyColor,
    isClickable: false,
};
export const AREA_TYPE_SOLD = {
    mainColor: COLORS.redColor,
    isClickable: false,
};
export const AREA_TYPE_RELEASED = {
    mainColor: COLORS.greenColor,
    oneBedColor: COLORS.sunriseColor,
    twoBedsColor: COLORS.indigoColor,
    threeBedsColor: COLORS.sandColor,
    isClickable: true,
};
export const AREA_STATUS_UNRELEASED = 'unreleased';
export const AREA_STATUS_SOLD = 'sold';
export const AREA_STATUS_RELEASED = 'released';
export const AREA_STATUS_COMINGSOON = 'comingSoon';
export const AREA_STATUS_TYPE_PUBLISHED = {
    [AREA_STATUS_UNRELEASED]: AREA_TYPE_UNRELEASED,
    [AREA_STATUS_SOLD]: AREA_TYPE_SOLD,
    [AREA_STATUS_RELEASED]: AREA_TYPE_RELEASED,
};
export const AREA_STATUS_TYPE_UNPUBLISHED = {
    [AREA_STATUS_UNRELEASED]: AREA_TYPE_UNRELEASED,
    [AREA_STATUS_SOLD]: AREA_TYPE_SOLD,
    [AREA_STATUS_RELEASED]: AREA_TYPE_UNRELEASED,
};
export const getAreaStatusType = (isPublished, status) => {
    return isPublished ? AREA_STATUS_TYPE_PUBLISHED[status] : AREA_STATUS_TYPE_UNPUBLISHED[status];
};
