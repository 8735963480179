import React from 'react';
import PropTypes from 'prop-types';
import VacanciesSearchZeroResults from './vacancies-search-zero-results';
import VacanciesSearchList from './vacancies-search-list';

class VacanciesSearchContent extends React.PureComponent {
    render() {
        const { isInitialRequestFulfilled, items, animatingContentRef } = this.props;
        const isResultsEmpty = items.length === 0 && isInitialRequestFulfilled;

        return (
            <div className="vacancies-search__content">
                <div className="vacancies-search__inner" ref={animatingContentRef}>
                    {!isResultsEmpty ? <VacanciesSearchList/> : <VacanciesSearchZeroResults/>}
                </div>
            </div>
        );
    }
}

VacanciesSearchContent.propTypes = {
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    animatingContentRef: PropTypes.func.isRequired,
};

export default VacanciesSearchContent;
