import $ from 'jquery';
import 'slick-carousel';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import GalleryVideoStateObserver from 'general/js/video/gallery-video-state-observer';

const AUTOPLAY_SPEED = 7000;

export default class LivingInCarouselComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.imageCarousel = null;
        this.carousel = null;
        this.isAutoplayStopped = false;
        this.videoObserver = null;
        this.videoSlides = this.refs.video || [];
        this.slidingTime = 'slidingTime' in this.options ? this.options.slidingTime : AUTOPLAY_SPEED;
    }

    static getNamespace() {
        return 'living-in-carousel';
    }

    static getRequiredRefs() {
        return ['slides'];
    }

    onInit() {
        const slidesCount = this.refs.slides.children.length;
        if (slidesCount > 0) {
            if (this.videoSlides.length > 0) {
                this.videoObserver = new GalleryVideoStateObserver(this.element);
            }
            if (slidesCount === 1) {
                this._initSlideElement(this.refs.slides.children[0]);
            } else {
                this._initSlider();
            }
        }
    }

    _initSlider() {
        const { slides } = this.refs;

        if (!slides) {
            throw new Error('Slider should contain at least 2 slides');
        }

        const $slides = $(slides);

        this.carousel = $slides.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            zIndex: 1,
            arrows: true,
            dots: false,
            swipe: true,
            prevArrow: `<button type="button" class="btn living-in-carousel__arrow-button living-in-carousel__arrow-button--prev" aria-label="Previous Slide" data-dc-gtm-selector="LivingInGalleryNextButton">
                            <svg width="9" height="17" class="icon living-in-carousel__arrow-button-icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-left-thin"></use>
                            </svg>
                        </button>`,
            nextArrow: `<button type="button" class="btn living-in-carousel__arrow-button living-in-carousel__arrow-button--next" aria-label="Next Slide" data-dc-gtm-selector="LivingInGalleryNextButton">
                            <svg width="9" height="17" class="icon living-in-carousel__arrow-button-icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-right-thin"></use>
                            </svg>
                        </button>`,
            autoplay: true,
            autoplaySpeed: this.slidingTime,
            rows: 0,
        });

        this.carousel.on('beforeChange', () => {
            this._onBeforeSlideChange();
        });

        this.carousel.on('afterChange', (event, slick, currentSlide) => {
            this._onAfterSlideChange(slick, currentSlide);
        });

        this._initStartSlide();

        this.element.addEventListener('mouseenter', this._onSliderMouseEnter.bind(this));
        this.element.addEventListener('mouseleave', this._onSliderMouseLeave.bind(this));
        this.element.addEventListener('click', this._onSliderClick.bind(this));
    }

    _onSliderMouseEnter() {
        if (!this.isAutoplayStopped) {
            this.carousel.slick('slickPause');
        }
    }

    _initStartSlide() {
        const mainSlick = this.carousel.slick('getSlick');
        const slickCurrentSlide = this.carousel.slick('slickCurrentSlide');
        this._initSlickSlide(mainSlick, slickCurrentSlide);
    }

    _initSlickSlide(slick, slide) {
        this._initSlideElement(slick.$slides.get(slide));
    }

    _initSlideElement(element) {
        if (this.videoSlides.includes(element)) {
            this.videoObserver.addVideo(element);
        }
    }

    _stopAutoPlay() {
        this.carousel.slick('slickPause');
    }

    _onSliderMouseLeave() {
        if (!this.isAutoplayStopped) {
            this.carousel.slick('slickPlay');
        }
    }

    _onSliderClick() {
        if (!this.isAutoplayStopped) {
            this.isAutoplayStopped = true;
            this._stopAutoPlay();
        }
    }

    _onBeforeSlideChange() {
        if (this.videoObserver) {
            this.videoObserver.pauseVideo();
        }
    }

    _onAfterSlideChange(slick, currentSlide) {
        this._initSlickSlide(slick, currentSlide);
    }

    onDestroy() {
        if (this.carousel !== null) {
            this.carousel.slick('unslick');
            this.carousel = null;
        }
    }
}
