import DcBaseComponent from 'general/js/dc/dc-base-component';

const DEFAULT_VISIBLE_ITEMS_COUNT = 3;

export default class ParticipatingDevelopmentsGridComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.visibleItemsCount =
            parseInt(this.getChildAttribute(this.element, 'visible-count')) ||
            DEFAULT_VISIBLE_ITEMS_COUNT;
        this.items = this.refs.items || [];
        this.showButton = this.refs.showButton;
        this.isExpanded = false;
    }

    static getNamespace() {
        return 'participating-developments-grid';
    }

    static getRequiredRefs() {
        return ['items'];
    }

    onInit() {
        if (this.items.length > this.visibleItemsCount) {
            if (this.showButton) {
                this._handleInit();
            } else {
                throw new Error(
                    'There should be a showButton in case we have more items then collapsed view can hold'
                );
            }
        }
    }

    _handleInit() {
        this._collapse();
        this._addListeners();
    }

    _addListeners() {
        this.showButton.addEventListener('click', this._onShowButtonClick);
    }

    _onShowButtonClick = e => {
        if (this.isExpanded) {
            this._collapse();
        } else {
            this._expand();
        }
    };

    _expand() {
        this.isExpanded = true;
        this._applyExpanded(this.isExpanded);
        // remove button and unsubscribe on first expand
        this.showButton.style.display = 'none';
        this.showButton.removeEventListener('click', this._onShowButtonClick);
    }

    _collapse() {
        this.isExpanded = false;
        this._applyExpanded(this.isExpanded);
    }

    _applyExpanded(isExpanded) {
        const extraItems = this._getExtraItems();
        if (isExpanded) {
            this.element.classList.add('is-expanded');
            extraItems.forEach(item => item.classList.remove('visually-hidden'));
        } else {
            this.element.classList.remove('is-expanded');
            extraItems.forEach(item => item.classList.add('visually-hidden'));
        }
    }

    _getExtraItems() {
        return this.items.slice(this.visibleItemsCount);
    }
}
