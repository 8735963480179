import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class HeroVideoComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        // elements
        this.heroVideo = this.element;
        this.button = this.refs.button;
        this.video = this.refs.video;

        // variables
        this.contentFade = this.heroVideo.dataset.heroVideoContentFade === 'true';
        this.contentFadeDelay = this.heroVideo.dataset.heroVideoContentFadeDelay ? this.heroVideo.dataset.heroVideoContentFadeDelay : 10000;

        // functions
        this.playToggle = this.playToggle.bind(this);
    }

    static getNamespace() {
        return 'hero-video';
    }

    static getRequiredRefs() {
        return ['button', 'video'];
    }

    onInit() {
        if (this.contentFade) {
            setTimeout(() => {
                this.heroVideo.classList.remove('hero-video--darken');
                this.heroVideo.classList.add('hero-video--fade-content');
            }, this.contentFadeDelay);
        }

        this.button.addEventListener('click', this.playToggle);
        this.video.addEventListener('click', this.playToggle);
    }

    playToggle() {
        if (this.video.paused) {
            this.video.play();
            this.button.classList.remove('hero-video__button--paused');
        } else {
            this.video.pause();
            this.button.classList.add('hero-video__button--paused');
        }
    }
}
