import React from 'react';
import PropTypes from 'prop-types';
import AccordionComponent from '../../../accordion/js/accordion.component';

class VacanciesSearchCard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.accordion = null;
    }

    accordionRef = element => {
        if (element) {
            if (this.accordion === null) {
                this.accordion = new AccordionComponent(element);
                this.accordion.init();
            }
        } else if (this.accordion !== null) {
            this.accordion.destroy();
            this.accordion = null;
        }
    };

    render() {
        const { item, index } = this.props;

        return (
            <div
                className={`vacancy-card ${index === 0 ? 'is-expanded' : 'is-collapsed'}`}
                ref={this.accordionRef}
            >
                <button
                    type="button"
                    className="vacancy-card__header"
                    data-dc-accordion-ref="trigger"
                >
                    <div className="vacancy-card__info">
                        <div className="vacancy-card__job-title">{item.title}</div>
                        <div className="vacancy-card__location">
                            {item.region ? `${item.region}, ` : ''}
                            {item.location}
                        </div>
                    </div>

                    <svg
                        width="14"
                        height="8"
                        className="vacancy-card__icon icon"
                        focusable="false"
                        role="img" aria-hidden="true"
                        data-dc-accordion-ref="icon"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </button>
                <div className="vacancy-card__body" data-dc-accordion-ref="content">
                    <div className="vacancy-card__content">
                        <div className="vacancy-card__info">
                            <div className="vacancy-card__salary">
                                <strong>Salary: </strong>
                                {item.salary}
                            </div>
                            <div className="vacancy-card__closing-date">
                                <strong> Closing Date: </strong>
                                {item.expiry}
                            </div>
                        </div>

                        <a
                            className="vacancy-card__link btn-secondary btn-secondary--green"
                            href={item.url}
                        >
                            Find out more & Apply
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

VacanciesSearchCard.propTypes = {
    item: PropTypes.object.isRequired
};

export default VacanciesSearchCard;
