import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import PropertiesSearchItem from './properties-search-item';

class PropertiesSearchList extends React.PureComponent {
    render() {
        const { items } = this.props;

        return (
            <div className="properties-search__list">
                {items.map(item => <PropertiesSearchItem key={item.id} item={item}/>)}
            </div>
        );
    }
}

PropertiesSearchList.propTypes = {
    items: PropTypes.array.isRequired,
};

export default withContext(PropertiesSearchList, {
    items: 'items'
});
