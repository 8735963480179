import React from 'react';
import PropTypes from 'prop-types';
import UrlHelper from '@General/js/url-helper.js';
import notificationsService from '@General/js/notifications-service.js';
import Spinner from '@Components/spinner/js/spinner.jsx';
import api from 'general/js/api';
import analyticsService from 'general/js/analytics-service'

const GEOLOCATION_ERROR_PERMISSION_DENIED = 1;
const GEOLOCATION_ERROR_POSITION_UNAVAILABLE = 2;
const GEOLOCATION_ERROR_TIMEOUT = 3;
const GA_EVENTS_USEMYLOCATION = 'useMyLocation';

const DEFAULT_GEOLOCATION_ERROR =
    'Using your location is unavailable at the moment. Please try again later';

const DEFAULT_GEOCODE_ERROR =
    'Your location is unknown. Please use the search box provided';

export default class LocationButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLocationFindingInProgress: false,
        };
    }

    onUseMyLocationClick = () => {

        if (!this.state.isLocationFindingInProgress) { 
            this.setState({ isLocationFindingInProgress: true });

            if (/Edg/.test(navigator.userAgent)) { // Overcoming Edge browser location issue 
                const STATIC_MAPS_API_KEY = window.appConfig.GOOGLE_MAPS_API_KEY;
                const url = `https://www.googleapis.com/geolocation/v1/geolocate?key=${STATIC_MAPS_API_KEY}`;

                api.post(url).then((result) => {
                    const { lat, lng } = result.data.location;
                    this.processLocation(lat, lng);
                }).catch((error) => {
                    this.setState({ isLocationFindingInProgress: false });
                    notificationsService.warn(this.getGeolocationErrorMessage(error));
                });

            } else {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        this.processLocation(latitude, longitude);
                    },
                    (error) => {
                        this.setState({ isLocationFindingInProgress: false });
                        notificationsService.warn(this.getGeolocationErrorMessage(error));
                    }
                );
            }
        }
    };

    getGeolocationErrorMessage(error) {
        switch (error.code) {
            case GEOLOCATION_ERROR_PERMISSION_DENIED:
                return 'Your settings do not allow McCarthy Stone to use your location. Please update your browser settings to use this feature.';
            case GEOLOCATION_ERROR_POSITION_UNAVAILABLE:
            case GEOLOCATION_ERROR_TIMEOUT:
                return DEFAULT_GEOLOCATION_ERROR;
            default:
                return error.message || DEFAULT_GEOLOCATION_ERROR;
        }
    }

    getSearchUrlByQuery(query) {
        const { searchByLocation } = this.props;
        // Replace %20 with + to match URLs generated server-side and for readability
        return UrlHelper.getUrlByParams(searchByLocation.path, {
            [searchByLocation.params.query]: query
        }).replace( /%20/g, "+" );
    }

    getSearchUrlById(id) {
        const { searchByLocation } = this.props;
        return UrlHelper.getUrlByParams(searchByLocation.path, {
            [searchByLocation.params.id]: id
        });
    }

    processLocation(latitude, longitude) {
        const { searchByLocation } = this.props;
        const url = `${searchByLocation.reverseGeocodeUrl}?latitude=${latitude}&longitude=${longitude}`;

        api.post(url).then((response) => {
            this.setState({ isLocationFindingInProgress: false });
            if (response.data) {
                analyticsService._send(GA_EVENTS_USEMYLOCATION);

                window.location.href = response.data.query ? this.getSearchUrlByQuery(response.data.query) : this.getSearchUrlById(response.data.placeId);
            } else {
                notificationsService.warn(DEFAULT_GEOCODE_ERROR);
            }
        }).catch((error) => {
            console.error(error);
            this.setState({ isLocationFindingInProgress: false });
            notificationsService.warn(DEFAULT_GEOCODE_ERROR);
        });
        
    }

    render() {
        const { isLocationFindingInProgress } = this.state;

        return (
            <button
                onClick={this.onUseMyLocationClick}
                className="btn quick-search__location-button"
                type="button"
            >
                <Spinner mobileSmall={true} isFaded={true} isActive={isLocationFindingInProgress} />
                <div className="button-content">
                    Or use my location
                    <svg
                        className="icon quick-search__location-button-icon"
                        width="12"
                        height="11"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-geo-arrow" />
                    </svg>
                </div>
            </button>
        );
    }
}

LocationButton.propTypes = {
    searchByLocation: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.object.isRequired,
    }).isRequired,
};
