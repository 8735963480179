import React from 'react';
import PropTypes from 'prop-types';
import nanoId from 'nano-id';
import { withContext } from '../context.jsx';
import constants from '../constants.js';

class PropertiesDisplay extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
          filterCurrentProperties: [],
          filterValues:{},
          allCurrentProperties: [],
          allProperties: [],

        };
    }
    componentDidMount() {
        const { childRef } = this.props;

        childRef(this);

        this.state.allCurrentProperties = this.props.currentProperties;

      }
      componentWillUnmount() {
       const { childRef } = this.props;
        childRef(undefined);
      }



    static getDerivedStateFromProps(props, state) {
        var _new = {
            filterValues: JSON.parse(JSON.stringify(props.filterValues)),
            allProperties:state.allCurrentProperties.filter((currentPropertie, index) => {


                switch(props.filterValues?.ownershipValue) {

                    // "For sale only"
                    case props.tableFilters.ownershipType[1].ownershipValue:
                        return currentPropertie.availableForSale === true
                                && (props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0)
                                && ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.salesFormattedPrice.length !==0))
                                && (((props.filterValues?.price) >= currentPropertie.salesPrice) || props.filterValues?.price === "" )

                    // "To rent only"
                    case props.tableFilters.ownershipType[2].ownershipValue:
                        return currentPropertie.availableForRent === true
                                && (!currentPropertie.opsoAvailable || !props.tableSettings.isOpsoEnabled)
                                && (props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0)
                                && ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.rentalFormattedPrice.length !==0))
                                && (((props.filterValues?.price) >= currentPropertie.rentalPrice) || props.filterValues?.price === "" )

                    // "Shared Ownership"
                    case props.tableFilters.ownershipType[3].ownershipValue:
                        return currentPropertie.availableForSale === true
                                && (props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0)
                                && ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.opsoSalesFormattedPrice.length !==0))
                                && (((props.filterValues?.price) >= currentPropertie.opsoSalesPrice) || props.filterValues?.price === "" )

                    // "All Properties"
                    case props.tableFilters.ownershipType[0].ownershipValue:
                        return props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0

                    default:
                      return true;

                  }
            }),
            filterCurrentProperties: props.currentProperties.filter((currentPropertie, index) => {


                switch(props.filterValues?.ownershipValue) {

                    // "For sale only"
                    case props.tableFilters.ownershipType[1].ownershipValue:
                        return currentPropertie.availableForSale === true
                                && (props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0)
                                && ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.salesFormattedPrice.length !==0))
                                && (((props.filterValues?.price) >= currentPropertie.salesPrice) || props.filterValues?.price === "" )

                    // "To rent only"
                    case props.tableFilters.ownershipType[2].ownershipValue:
                        return currentPropertie.availableForRent === true
                                && ( !currentPropertie.opsoAvailable || !props.tableSettings.isOpsoEnabled)
                                && (props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0)
                                && ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.rentalFormattedPrice.length !==0))
                                && (((props.filterValues?.price) >= currentPropertie.rentalPrice) || props.filterValues?.price === "" )


                    // "Shared Ownership"
                    case props.tableFilters.ownershipType[3].ownershipValue:
						return currentPropertie.availableForSale === true
                                && (props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0)
                                && ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.opsoSalesFormattedPrice.length !==0))
                                && (((props.filterValues?.price) >= currentPropertie.opsoSalesPrice) || props.filterValues?.price === "" )
								&& ((props.filterValues?.ownershipValue.length !== 0) === (currentPropertie.opsoAvailable && props.tableSettings.isOpsoEnabled))

                    // "All Properties"
                    case props.tableFilters.ownershipType[0].ownershipValue:
                        return props.filterValues?.bedrooms?.includes(currentPropertie.numberOfBedrooms) || props.filterValues?.bedrooms.length == 0

                    default:
                      return true;

                  }
            }),


        };
        return _new;
    }
    render() {
        const currPage = this.props.currentPage;
        const endIndex = currPage * 6;
        const startIndex = endIndex - 6;

        return (
            <div className="properties-table__table">

            {
                (this.state.filterCurrentProperties.length != 0) ?
                this.state.filterCurrentProperties.map((property, index) => {
                    if(index >= startIndex && index < endIndex) {
				   let switchSaleTooltip;
				   let switchRentTooltip;
                   let switchSaleLabel;
                   let switchRentLabel;
                   let switchSalePrice;
                   let switchRentPrice;
                   let saleLabelText;

                   if((property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !this.props.tableSettings.showOpsoPercentagePrices)
                   || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !property.showOpsoPricingOnWebsite)) {
					switchSaleTooltip = '';
					switchRentTooltip = '';
                    switchSaleLabel = '';
                    switchRentLabel = '';
                    switchSalePrice = this.props.tableSettings.opsoAvailableText;
                    switchRentPrice = null;
                   }else if(property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && property.showOpsoPricingOnWebsite && this.props.tableSettings.showOpsoPercentagePrices && property.opsoSalesPrice == 0) {
					switchSaleTooltip = '';
					switchRentTooltip = '';
                    switchSaleLabel = '';
                    switchRentLabel = '';
                    switchSalePrice = this.props.tableSettings.opsoAvailableText;
                    switchRentPrice = null;
				   }else if(property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && property.showOpsoPricingOnWebsite && this.props.tableSettings.showOpsoPercentagePrices && property.opsoSalesPrice != 0) {
					switchSaleTooltip = property.opsoSalesFormattedPriceTooltip
					switchRentTooltip = '';
                    switchSaleLabel = this.props.tableSettings.sharedOwnershipLabel;
                    switchRentLabel = '';
                    switchSalePrice =  property.opsoSalesFormattedPrice || this.props.tableSettings.notApplicableLabel;
                    switchRentPrice = null;
                   }else {
					switchSaleTooltip = '';
					switchRentTooltip = '';
                    switchSaleLabel = this.props.tableSettings.saleLabel;
                    switchRentLabel = this.props.tableSettings.rentLabel;
                    switchSalePrice =  property.salesFormattedPrice && property.availableForSale ? property.salesFormattedPrice : this.props.tableSettings.notApplicableLabel;
                    switchRentPrice = property.rentalFormattedPrice && property.availableForRent ?  property.rentalFormattedPrice : this.props.tableSettings.notApplicableLabel;
                   }
                   const areaLabel = constants.HEADINGS.find(element => element.id === "Area").displayName;
                   const renderId = nanoId();

                    return (
                        <div className="properties-table__row " key={renderId}>
                            <div className="properties-table__info">
                                <div className="properties-table__name">
                                    <h4><a href={property.buttonUrl}>{this.props.tableSettings.propertyLabel} {property.plotNumber}</a></h4>
                                </div>
                                <div className="properties-table__bedroom properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.numberOfBedrooms} {this.props.tableSettings.bedroomTooltip}</div>
                                    <span>{property.numberOfBedrooms}</span>
                                </div>
                                <div className="properties-table__floor properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.friendlyFloorTitle}</div>
                                    <span>{property.friendlyFloorIndex}</span>
                                </div>
                                <div className="properties-table__area properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.area} {this.props.tableSettings.squareMetresTooltip}</div>

                                    {property.area}<span>{areaLabel}</span>
                                </div>
                            </div>

                            <div className="properties-table__prices">
                                <div className="properties-table__sale-price">
                                    <span className="properties-table__prices--label">{switchSaleLabel}</span>
									{switchSaleTooltip && (
                                        <div className="tooltip tooltip--dark" data-tooltip>
                                            <button aria-describedby={`tooltip_${renderId}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                            <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}`} role="tooltip">
                                                <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                    __html: switchSaleTooltip,
                                                }}></div>
                                            </div>
                                        </div>
									)}
                                    <span className="properties-table__prices--price" dangerouslySetInnerHTML={{ __html: switchSalePrice }}></span>
                                </div>
                                {!property.hideRentPrices && (property.hideRentPrices !== undefined) &&(
                                    <div className="properties-table__rental-price">
                                        <span className="properties-table__prices--label">{switchRentLabel}</span>
										{switchRentTooltip && (
                                            <div className="tooltip tooltip--dark" data-tooltip>
                                                <button aria-describedby={`tooltip_${renderId}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                                <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}`} role="tooltip">
                                                    <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                        __html: switchRentTooltip,
                                                    }}></div>
                                                </div>
                                            </div>
										)}
										<span className="properties-table__prices--price" dangerouslySetInnerHTML={{ __html: switchRentPrice} }></span>
                                    </div>
                                )}
                            </div>
                            <a href={property.buttonUrl} className="properties-table__arrow-icon">
                                <span className="arrow-icon">
                                    <svg className="property-table__table-cell-btn-icon icon">
                                        <use xlinkHref="#icon-arrow-right-thin"></use>
                                    </svg>
                                </span>

                            </a>
                        </div>

                        )

                    }
                }):

                this.state.allProperties.map((property, index) => {
                    if(index >= startIndex && index < endIndex) {
			       let switchSaleTooltip;
				   let switchRentTooltip;
                   let switchSaleLabel;
                   let switchRentLabel;
                   let switchSalePrice;
                   let switchRentPrice;
                   if((property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !this.props.tableSettings.showOpsoPercentagePrices) || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !property.showOpsoPricingOnWebsite) || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && property.showOpsoPricingOnWebsite && this.props.tableSettings.showOpsoPercentagePrices && property.opsoSalesPrice == 0)) {
					switchSaleTooltip = '';
					switchRentTooltip = '';
                    switchSaleLabel = '';
                    switchRentLabel = '';
                    switchSalePrice = this.props.tableSettings.opsoAvailableText;
                    switchRentPrice = null;
                   }else if(property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && property.showOpsoPricingOnWebsite && this.props.tableSettings.showOpsoPercentagePrices && property.opsoSalesPrice != 0) {
					switchSaleTooltip = property.opsoSalesFormattedPriceTooltip
					switchRentTooltip = '';
                    switchSaleLabel = this.props.tableSettings.sharedOwnershipLabel;
                    switchRentLabel = '';
                    switchSalePrice =  property.opsoSalesFormattedPrice || this.props.tableSettings.notApplicableLabel;
                    switchRentPrice = null;
                   }else {
					switchSaleTooltip = '';
					switchRentTooltip = '';
                    switchSaleLabel = this.props.tableSettings.saleLabel;
                    switchRentLabel = this.props.tableSettings.rentLabel;
                    switchSalePrice =  property.salesFormattedPrice && property.availableForSale ? property.salesFormattedPrice : this.props.tableSettings.notApplicableLabel;
                    switchRentPrice = property.rentalFormattedPrice && property.availableForRent ?  property.rentalFormattedPrice : this.props.tableSettings.notApplicableLabel;

                   }
                   const areaLabel = constants.HEADINGS.find(element => element.id === "Area").displayName;
                   const renderId = nanoId();

                    return (
                        <div className="properties-table__row " key={renderId}>
                            <div className="properties-table__info">
                                <div className="properties-table__name">
                                    <h4><a href={property.buttonUrl}>{this.props.tableSettings.propertyLabel} {property.plotNumber}</a></h4>
                                </div>
                                <div className="properties-table__bedroom properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.numberOfBedrooms} {this.props.tableSettings.bedroomTooltip}</div>
                                    <span>{property.numberOfBedrooms}</span>
                                </div>
                                <div className="properties-table__floor properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.floor}</div>
                                    <span>{property.friendlyFloorIndex}</span>
                                </div>
                                <div className="properties-table__area properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.area} {this.props.tableSettings.squareMetresTooltip}</div>

                                    {property.area}<span>{areaLabel}</span>
                                </div>
                            </div>

                            <div className="properties-table__prices">
                                <div className="properties-table__sale-price">
                                    <span className="properties-table__prices--label">{switchSaleLabel}</span>
									{switchSaleTooltip && (
                                        <div className="tooltip tooltip--dark" data-tooltip>
                                            <button aria-describedby={`tooltip_${renderId}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                            <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}`} role="tooltip">
                                                <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                    __html: switchSaleTooltip,
                                                }}></div>
                                            </div>
                                        </div>
									)}
                                    <span className="properties-table__prices--price" dangerouslySetInnerHTML={{ __html: switchSalePrice }}></span>
                                </div>
                                {!property.hideRentPrices && (property.hideRentPrices !== undefined) &&(
                                    <div className="properties-table__rental-price">
                                        <span className="properties-table__prices--label">{switchRentLabel}</span>
										{switchRentTooltip && (
                                            <div className="tooltip tooltip--dark" data-tooltip>
                                                <button aria-describedby={`tooltip_${renderId}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                                <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}`} role="tooltip">
                                                    <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                        __html: switchRentTooltip,
                                                    }}></div>
                                                </div>
                                            </div>
										)}
										<span className="properties-table__prices--price" dangerouslySetInnerHTML={{ __html: switchRentPrice} }></span>
                                    </div>
                                )}

                            </div>
                            <a href={property.buttonUrl} className="properties-table__arrow-icon">
                                <span className="arrow-icon">
                                    <svg className="property-table__table-cell-btn-icon icon">
                                        <use xlinkHref="#icon-arrow-right-thin"></use>
                                    </svg>
                                </span>

                            </a>
                        </div>

                        )

                    }
                })
            }



            </div>
        );
    }
}

PropertiesDisplay.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    })),


    currentProperties: PropTypes.arrayOf(
        PropTypes.shape({
            area: PropTypes.string,
            bedrooms: PropTypes.number,
            floor: PropTypes.string,
            number: PropTypes.string,
            prices: PropTypes.object,
            propertyPageHref: PropTypes.string,
            recordId: PropTypes.string,
            status: PropTypes.string,
            propertyType: PropTypes.string,
        })

    ),

    tableSettings: PropTypes.objectOf(PropTypes.string),
    tableFilters: PropTypes.objectOf(PropTypes.string),
    isOnMobileViewport: PropTypes.bool,
    visibleColumns: PropTypes.objectOf(PropTypes.bool),
    filterValues: PropTypes.objectOf(PropTypes.string),
};

export default withContext(PropertiesDisplay, {
    currentProperties: 'currentProperties',
    isOnMobileViewport: 'isOnMobileViewport',
    tableFilters: 'tableFilters',
    visibleColumns: 'visibleColumns',
    tabs: 'tabs',
    tableSettings: 'tableSettings',
});
