import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ isDisabled, isChecked, onChange, text, value }) => {
    const handleOnChange = (e) => {
        if (!isDisabled || isChecked) {
            onChange(e);
        }
    };

    return (
        <div className="checkbox-v2">
            <label className="checkbox-v2__label">
                <input
                    type="checkbox"
                    value={value}
                    className={`${isDisabled ? 'is-disabled' : ''}`}
                    checked={isChecked}
                    onChange={handleOnChange}
                />
                <span className="checkbox-v2__placeholder">
                    <svg
                        width="15"
                        height="12"
                        className="icon checkbox-v2__tick-icon"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-tick" />
                    </svg>
                </span>
                <span className="checkbox-v2__text">{text}</span>
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default Checkbox;
