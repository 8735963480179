import DcBaseComponent from 'general/js/dc/dc-base-component';
import styleObserver from '../../../general/js/style-observer';
import device, { DEVICE_TYPE_DESKTOP } from '../../../general/js/device';

const CURVE_TRANSPARENT_WIDTH_RATIO = 0.35;

export default class HeroComponent extends DcBaseComponent {

    static getNamespace() {
        return 'hero';
    }

    static getRequiredRefs() {
        return ['canvas'];
    }

    onInit() {
        this.addListeners();
    }

    addListeners() {
        styleObserver.onLoad(() => this._handleCanvas());
        device.subscribeOnDeviceTypeChange(this._onDeviceTypeChanged);
    }

    _onDeviceTypeChanged = () => {
        this._handleCanvas();
    };

    _handleCanvas() {
        const isDesktop = device.isViewportTypeGe(DEVICE_TYPE_DESKTOP);
        if (isDesktop) {
            this._setCanvasWidth(false);
        } else {
            this._setCanvasWidth(true);
        }
    }

    _setCanvasWidth(reset = false) {
        const canvas = this.refs.canvas;
        if (reset) {
            canvas.style.marginLeft = '0px';
        } else {
            const canvasHeight = canvas.clientHeight;
            const margin = -1 * CURVE_TRANSPARENT_WIDTH_RATIO * canvasHeight;
            canvas.style.marginLeft = Math.round(margin) + 'px';
        }
    }
}
