import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Range } from 'react-range';
import { withSearchMobileFiltersContext } from '../search-mobile-filters-context.js';

const LEGAL_VALUES = [1, 15, 30, 45, 60];
const STEP = 1;
const DEFAULT_VALUE = 30;

class MobileSearchRadius extends React.Component {
    constructor(props) {
        super(props);

        this.legalValues = this.getLegalValues(this.props.config.options);
        this.minValue = this.legalValues[0];
        this.maxValue = this.legalValues[this.legalValues.length - 1];

        this.state = {
            currentValue: parseInt(this.props.value, 10) || DEFAULT_VALUE,
        };
    }

    getLegalValues = (options) => {
        if (Array.isArray(options) && options.length >= 2) {
            return options.map((option) => parseInt(option.value, 10));
        }
        return LEGAL_VALUES;
    };

    changeRangeHandler = ([value]) => {
        // easier for user to click on a point if we check values around the click area
        const possibleValue = [value - 1, value, value + 1].find((elem) =>
            this.legalValues.includes(elem)
        );

        if (possibleValue !== undefined) {
            this.setState({ currentValue: possibleValue });
            this.props.onChange(this.props.config.name, possibleValue.toString());
        }
    };

    render() {
        return (
            <div className="mobile-search-radius">
                <Range
                    step={STEP}
                    min={this.minValue}
                    max={this.maxValue}
                    values={this.legalValues}
                    disabled={true}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            className="mobile-search-radius__range mobile-search-radius__range--absolute"
                            style={{
                                ...props.style,
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ index, props }) => (
                        <div
                            {...props}
                            className="mobile-search-radius__mark"
                            style={{
                                ...props.style,
                            }}
                        >
                            <div
                                className={classNames({
                                    'mobile-search-radius__label': true,
                                    'mobile-search-radius__label--highlighted':
                                        !this.props.config.isDisabled &&
                                        this.legalValues[index] === this.state.currentValue,
                                })}
                            >
                                {this.legalValues[index]}
                            </div>
                        </div>
                    )}
                />
                <Range
                    step={STEP}
                    min={this.minValue}
                    max={this.maxValue}
                    disabled={this.props.config.isDisabled}
                    values={this.props.config.isDisabled ? [] : [this.state.currentValue]}
                    onChange={this.changeRangeHandler}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            className="mobile-search-radius__range"
                            style={{
                                ...props.style,
                            }}
                        >
                            <div
                                className="mobile-search-radius__track"
                                style={{
                                    ...props.style,
                                }}
                            ></div>
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            className="mobile-search-radius__thumb"
                            style={{
                                ...props.style,
                            }}
                        />
                    )}
                />
            </div>
        );
    }
}

MobileSearchRadius.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withSearchMobileFiltersContext(MobileSearchRadius, {
    onChange: 'onChange',
});
