import React from 'react';
import PropTypes from 'prop-types';
import PropertyCard from '../../../property-card/js/property-card';

export default class PropertiesSearchItem extends React.PureComponent {
    render() {
        const { item } = this.props;
        return (
            <div className="properties-search__item">
                <PropertyCard item={item} showButton={true} titleSize="h4" />
            </div>
        );
    }
}

PropertiesSearchItem.propTypes = {
    item: PropTypes.object.isRequired
};
