export default class AppEvent {
    constructor(target, eventType, func) {
        this.target = target;
        this.eventType = eventType;
        this.func = func;

        target.addEventListener(eventType, func, false);
    }

    remove = () => {
        this.target.removeEventListener(this.eventType, this.func, false);
    };
}
