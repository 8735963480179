import DcBaseComponent from 'general/js/dc/dc-base-component';
import AccordionManager from '../../collapsible-tabs/js/accordion-manager';

export default class AccordionGroupComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.instance = null;
    }

    static getNamespace() {
        return 'accordion-group';
    }

    static getRequiredRefs() {
        return ['accordionInstances'];
    }

    onInit() {
        if (this.options.allowMultiple != null && this.options.allowMultiple === false) {
            this.instance = new AccordionManager(false, this.element, this.refs);
        } else {
            this.instance = new AccordionManager(true, this.element, this.refs);
        }
    }
}
