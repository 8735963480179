import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import constants from 'general/js/constants';
import AbstractMenu from './abstract-menu';

const ADD_LISTENERS_DELAY = 300;
export default class MobileMenu extends AbstractMenu {
    constructor(id, { trigger, content, closeTrigger }) {
        super(id);
        this.trigger = trigger;
        this.content = content;
        this.closeTrigger = closeTrigger;
        this.isBootstraped = false;
    }

    init() {
        this.beforeBootstrapCheck();
        this.addListeners();
    }

    canBeRegistered() {
        return this.trigger && this.content && this.closeTrigger;
    }

    addListeners() {
        device.subscribeOnDeviceTypeChange(this.onDeviceTypeChanged);
    }

    onDeviceTypeChanged = () => {
        this.beforeBootstrapCheck();
    };

    beforeBootstrapCheck() {
        if (device.isViewportTypeLt(DEVICE_TYPE_TABLET)) {
            if (!this.isBootstraped) {
                this.isBootstraped = true;
                this.bootstrap();
            }
        } else {
            if (this.isOpened()) {
                this._close();
            }
        }
    }

    bootstrap() {
        this._addOpenListeners();
        this.addTransitionClass();
    }

    _addOpenListeners() {
        this.trigger.addEventListener('click', this.onTriggerClick);
    }

    _addCloseListeners() {
        setTimeout(() => {
            document.addEventListener('click', this.onOuterClick);
            document.addEventListener('touchstart', this.onOuterClick);
            this.closeTrigger.addEventListener('click', this.onCloseTriggerClick);
        }, ADD_LISTENERS_DELAY);
    }

    _removeOpenListeners() {
        this.trigger.removeEventListener('click', this.onTriggerClick);
    }

    _removeCloseListeners() {
        document.removeEventListener('click', this.onOuterClick);
        document.removeEventListener('touchstart', this.onOuterClick);
        this.closeTrigger.removeEventListener('click', this.onCloseTriggerClick);
    }

    onTriggerClick = e => {
        if (!this.isOpened()) {
            this._open();
        } else {
            this._close();
        }
    };

    _open() {
        this.open();
        this._addCloseListeners();
        this._removeOpenListeners();
    }

    _close() {
        this.close();
        this._removeCloseListeners();
        this._addOpenListeners();
    }

    onCloseTriggerClick = e => {
        if (this.isOpened()) {
            this._close();
        }
    };

    getContentElement() {
        return this.content;
    }

    handleOpen() {
        this._setPointerEventsDelay(this.closeTrigger, constants.DEFAULT_TRANSITION_DURATION);
        this.showContent();
        this.trigger.setAttribute('aria-expanded', 'true');
    }

    handleClose() {
        this.hideContent();
        this.trigger.setAttribute('aria-expanded', 'false');
    }

    onOuterClick = e => {
        let target = e.target;
        if (
            this.content !== target &&
            this.trigger !== target &&
            !this.content.contains(target) &&
            !this.trigger.contains(target)
        ) {
            this._close();
        }
    };

    _setPointerEventsDelay(element, delay) {
        element.style.pointerEvents = 'none';
        setTimeout(() => {
            element.style.pointerEvents = 'all';
        }, delay);
    }
}
