import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_MOBILE_WIDE } from 'general/js/device';
import printHtmlElement from './print-function';
import { getPrintMarkup } from './amenities-print-map';
import markerBuilder from '../../map/js/markers/marker-url-builder';

const selectors = {
    amenitiesMap: '.amenities__map',
    mapImage: '.amenities__map-image',
    footer: '.footer',
    brochurePreviewImg: '.development-brochure__image-wrapper img',
    gallerySlide: '.media-with-caption--within-gallery',
};

export default class AmenitiesComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.imageSrc = null;
    }

    static getNamespace() {
        return 'amenities';
    }

    onInit() {
        this._addListeners();

        this._insertPrintMap();
    }

    _addListeners() {
        this.refs.btn.addEventListener('click', this._handlePrintBtnClick);
    }

    _getMobileMap(thumbnailDataSrcSet) {
        const map = document.querySelector(selectors.amenitiesMap);
        const mapOptions = JSON.parse(map.getAttribute('data-dc-map-options'));
        const newMapSrc = this._buildMobileStaticMapURL(mapOptions);
        map.style.display = 'block';
        const printMapMarkup = getPrintMarkup(newMapSrc, thumbnailDataSrcSet, this.refs.btn);

        return printMapMarkup;
    }

    _buildMobileStaticMapURL(options) {
        const STATIC_MAPS_API_KEY = window.appConfig.GOOGLE_MAPS_API_KEY;
        const locations = options.locations.map((location) => markerBuilder(location, options.mapSettings.markerFolderUrl)).join('&');
        const url = 'https://maps.googleapis.com/maps/api/staticmap?' +
            `center=${options.map.center}` +
            `&zoom=${options.map.zoom}&size=640x430` +
            `&key=${STATIC_MAPS_API_KEY}` + locations + '&style=feature:poi|element:labels|visibility:off';

        return url;
    }

    _hideMobileMap() {
        setTimeout(() => {
            document.querySelector(selectors.amenitiesMap).style.display = 'none';
        }, 800);
    }

    _insertPrintMap() {
        const loadInterval = setInterval(() => {
            const originalThumbnail = document.querySelector(selectors.brochurePreviewImg) ||
                document.querySelectorAll(selectors.gallerySlide)[0].querySelector('img');
            const isOriginalThumbnailLoaded = originalThumbnail &&
                (originalThumbnail.getAttribute('data-srcset') && originalThumbnail.getAttribute('data-srcset') !== '');

            if (isOriginalThumbnailLoaded) {
                const thumbnailDataSrcSet = originalThumbnail.getAttribute('data-srcset');
                const footer = document.querySelector(selectors.footer);

                if (device.isViewportTypeLe(DEVICE_TYPE_MOBILE_WIDE)) {
                    clearInterval(loadInterval);
                    const mobilePrintMap = this._getMobileMap(thumbnailDataSrcSet);

                    footer.insertAdjacentHTML('afterend', mobilePrintMap);

                    const mapWrapper = document.querySelector(selectors.amenitiesMap);
                    mapWrapper.style.display = 'none';
                } else {
                    const imageSrc = document.querySelector(selectors.mapImage);
                    if (imageSrc) {
						const isLazyloadImage = (imageSrc.classList.contains('lazyload') || imageSrc.classList.contains('lazyloaded'));
                        const imageUrl = isLazyloadImage ? (imageSrc.getAttribute('data-src') || imageSrc.getAttribute('data-srcset')) : imageSrc.getAttribute('src');
                        clearInterval(loadInterval);
                        const printMapMarkup = getPrintMarkup(imageUrl, thumbnailDataSrcSet, this.refs.btn);
                        footer.insertAdjacentHTML('afterend', printMapMarkup);
                    }
                }
            }
        }, 200);
    }

    _handlePrintBtnClick = () => {
        const printConfig = {
            printMode: '',
            pageTitle: '',
            templateString: '',
            popupProperties: '',
            closePopup: false,
            stylesheets: '',
            styles: null
        };

        printHtmlElement().printElement(document.getElementById('print-area'), printConfig);

        if (device.isViewportTypeLe(DEVICE_TYPE_MOBILE_WIDE)) {
            this._hideMobileMap();
        }
    };
}
