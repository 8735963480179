import Area from './area';
import Helper from './helper';
import utils from './utils';

export default class Polygon extends Area {
    constructor(coords, attributes, app) {
        super('polygon', coords, attributes, app);

        this._coords = {
            points: coords.points || [{ x: 0, y: 0 }],
            isOpened: coords.isOpened || false
        };
        this._el = document.createElementNS(
            Area.SVG_NS,
            this._coords.isOpened ? 'polyline' : 'polygon'
        );
        this._groupEl.appendChild(this._el);
        this._helpers = {
            points: []
        };

        for (let i = 0, c = this._coords.points.length; i < c; i++) {
            this._helpers.points.push(
                (new Helper(
                    this._groupEl,
                    this._coords.points[i].x,
                    this._coords.points[i].y,
                    'movePoint'
                )
                ).setId(i)
            );
        }

        this._selectedPoint = -1;
        this.redraw();
    }

    close = () => {
        const polyline = this._el;
        this._el = document.createElementNS(Area.SVG_NS, 'polygon');
        this._groupEl.replaceChild(this._el, polyline);
        this._coords.isOpened = false;
        this.redraw().deselect();
    };

    setSVGCoords = (coords) => {
        const polygonPointsAttrValue = coords.points.reduce((previousValue, currentItem) => previousValue + currentItem.x + ' ' + currentItem.y + ' ', '');
        this._el.setAttribute('points', polygonPointsAttrValue);
        this._helpers.points.forEach((helper, i) => {
            helper.setCoords(coords.points[i].x, coords.points[i].y);
        });
        return this;
    };

    setCoords = (coords) => {
        this._coords.points = coords.points;
        return this;
    };

    addPoint = (x, y) => {
        if (!this._coords.isOpened) {
            throw new Error('This polygon is closed!');
        }

        const helper = new Helper(this._groupEl, x, y, 'movePoint');
        helper.setId(this._helpers.points.length);
        this._helpers.points.push(helper);
        this._coords.points.push({
            x,
            y
        });
        this.redraw();
        return this;
    };

    dynamicDraw = (x, y, isRightAngle) => {
        const tempCoords = {
            points: [].concat(this._coords.points)
        };

        if (isRightAngle) {
            const rightPointCoords = Polygon.getRightAngleLineLastPointCoords(
                this._coords, { x, y }
            );
            x = rightPointCoords.x;
            y = rightPointCoords.y;
        }

        tempCoords.points.push({ x, y });
        this.redraw(tempCoords);
        return tempCoords;
    };

    onProcessDrawing = (e) => {
        const coords = utils.getRightCoords(e.pageX, e.pageY, this._app);
        this.dynamicDraw(coords.x, coords.y, e.shiftKey);
    };

    onAddPointDrawing = (e) => {
        let newPointCoords = utils.getRightCoords(e.pageX, e.pageY, this._app);

        if (e.shiftKey) {
            newPointCoords = Polygon.getRightAngleLineLastPointCoords(this._coords, newPointCoords);
        }

        this.addPoint(newPointCoords.x, newPointCoords.y);
    };

    onStopDrawing = (e) => {
        if (e.type === 'click' || (e.type === 'keydown' && e.keyCode === 13)) {
            if (Polygon.testCoords(this._coords)) {
                this.close();
                this._app.removeAllEvents()
                    .setIsDraw(false)
                    .resetNewArea();
            }
        }
        e.stopPropagation();
    };

    edit = (editingType, dx, dy) => {
        const tempParams = Object.create(this._coords);

        switch (editingType) {
            case 'move':
                for (let i = 0, c = tempParams.points.length; i < c; i++) {
                    tempParams.points[i].x += dx;
                    tempParams.points[i].y += dy;
                }
                break;

            case 'movePoint':
                tempParams.points[this.selected_point].x += dx;
                tempParams.points[this.selected_point].y += dy;
                break;

            default: throw new Error('Canot perform edit');
        }

        return tempParams;
    };

    onProcessEditing = (e) => {
        const editType = this._app.getEditType();
        this.redraw(
            this.edit(
                editType,
                e.pageX - this.editingStartPoint.x,
                e.pageY - this.editingStartPoint.y
            )
        );
        this.editingStartPoint.x = e.pageX;
        this.editingStartPoint.y = e.pageY;
    };

    onStopEditing = (e) => {
        const editType = this._app.getEditType();

        this.setCoords(
            this.edit(
                editType,
                e.pageX - this.editingStartPoint.x,
                e.pageY - this.editingStartPoint.y
            )
        ).redraw();
        this._app.removeAllEvents();
    };

    toString = () => 'Polygon {points: [' + this._coords.points.map(item => '[' + item.x + ', ' + item.y + ']').join(', ') + ']}';

    toMapElement = () => {
        const obj = {
            shape: 'poly',
            coords: this._coords.points.map(point => [point.x, point.y]).flat()
        };

        obj.alt = this._attributes.alt || '';
        obj.floor = this._attributes.floor || '';
        obj.href = this._attributes.href || '';
        obj.recordId = this._attributes.recordId || '';
        obj.sold = !!this._attributes.sold;
        obj.title = this._attributes.title || '';

        return obj;
    };

    static transformCoordsForInit = (coords) => {
        let obj = {};

        const points = coords.reduce((acc, el, i) => {
            if (i % 2 !== 0) {
                obj.y = el;
                acc.push(obj);
            } else {
                obj = {};
                obj.x = el;
            }

            return acc;
        }, []);

        return {
            points,
            isOpened: false
        };
    };

    getCoordsForDisplayingInfo() {
        return {
            x: this._coords.points[0].x,
            y: this._coords.points[0].y
        };
    }

    static testCoords = coords => coords.points.length >= 3;

    static testHTMLCoords = coords => coords.length >= 6 && coords.length % 2 === 0;

    static getCoordsFromHTMLArray = (htmlCoordsArray) => {
        if (!Polygon.testHTMLCoords(htmlCoordsArray)) {
            throw new Error('This html-coordinates is not valid for polygon');
        }

        const points = [];
        for (let i = 0, c = htmlCoordsArray.length / 2; i < c; i++) {
            points.push({
                x: htmlCoordsArray[2 * i],
                y: htmlCoordsArray[2 * i + 1]
            });
        }

        return {
            points
        };
    };

    static getRightAngleLineLastPointCoords = (originalCoords, newPointCoords) => {
        const TANGENS = {
            DEG_22: 0.414,
            DEG_67: 2.414
        };
        const lastPointIndex = originalCoords.points.length - 1;
        const lastPoint = originalCoords.points[lastPointIndex];
        const dx = newPointCoords.x - lastPoint.x;
        const dy = -(newPointCoords.y - lastPoint.y);
        const tan = dy / dx;
        let x = newPointCoords.x;
        let y = newPointCoords.y;

        if (dx > 0 && dy > 0) {
            if (tan > TANGENS.DEG_67) {
                x = lastPoint.x;
            } else if (tan < TANGENS.DEG_22) {
                y = lastPoint.y;
            } else if (Math.abs(dx) > Math.abs(dy)) {
                x = lastPoint.x + dy;
            } else {
                y = lastPoint.y - dx;
            }
        } else if (dx < 0 && dy > 0) {
            if (tan < -TANGENS.DEG_67) {
                x = lastPoint.x;
            } else if (tan > -TANGENS.DEG_22) {
                y = lastPoint.y;
            } else if (Math.abs(dx) > Math.abs(dy)) {
                x = lastPoint.x - dy;
            } else {
                y = lastPoint.y + dx;
            }
        } else if (dx < 0 && dy < 0) {
            if (tan > TANGENS.DEG_67) {
                x = lastPoint.x;
            } else if (tan < TANGENS.DEG_22) {
                y = lastPoint.y;
            } else if (Math.abs(dx) > Math.abs(dy)) {
                x = lastPoint.x + dy;
            } else {
                y = lastPoint.y - dx;
            }
        } else if (dx > 0 && dy < 0) {
            if (tan < -TANGENS.DEG_67) {
                x = lastPoint.x;
            } else if (tan > -TANGENS.DEG_22) {
                y = lastPoint.y;
            } else if (Math.abs(dx) > Math.abs(dy)) {
                x = lastPoint.x - dy;
            } else {
                y = lastPoint.y + dx;
            }
        }

        return {
            x,
            y
        };
    };

    static createAndStartDrawing = (firstPointCoords, app) => {
        const newArea = new Polygon({
            points: [firstPointCoords],
            isOpened: true
        }, {}, app);

        app.addEvent(app.refs.container, 'mousemove', newArea.onProcessDrawing.bind(newArea))
            .addEvent(app.refs.container, 'click', newArea.onAddPointDrawing.bind(newArea))
            .addEvent(document, 'keydown', newArea.onStopDrawing.bind(newArea))
            .addEvent(newArea._helpers.points[0]._el, 'click', newArea.onStopDrawing.bind(newArea));

        return newArea;
    };
}
