import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import trackerService from '@General/js/tracker-service.js'

const GA_EVENT_CALCULATOR_COST = 'calculatorCosts';

export default function BudgetOpenButton({ dispatchGaEvent }) {
    const [state, dispatch] = useBudgetCalculatorStore();

    return (
        <div className="budget-open-button">
            <button
                className="budget-open-button__button"
                type="button"
                onClick={() => {
                    dispatch({ type: 'TOGGLE_CALC' });
                    trackerService.track('ButtonClick_CalculateCosts');
                    dispatchGaEvent(GA_EVENT_CALCULATOR_COST);
                }}
            >
                <svg
                    className="budget-open-button__icon icon"
                    width="16"
                    height="16"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                >
                    <use xlinkHref="#icon-calc"></use>
                </svg>
                <p className="budget-open-button__text">
                    {state.isCalcShown ? 'Close' : 'Calculate my own costs'}
                </p>
                <div
                    className={classNames({
                        'budget-open-button__cross': true,
                        'budget-open-button__cross--opened': state.isCalcShown,
                    })}
                    aria-hidden="true"
                ></div>
            </button>
            <p className="budget-tip budget-tip--mt-10">
                {state.isCalcShown
                    ? 'Adjust your costs using the sliders or input the cost directly'
                    : 'Defaults based on approx. cost of detached 4 bedroom house'}
            </p>
        </div>
    );
}
BudgetOpenButton.propTypes = {
    dispatchGaEvent: PropTypes.func
};

