import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ParticipatingDevelopmentsSearch from './participating-developments-search';

export default class ParticipatingDevelopmentsSearchComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.filtersConfig = [
            {
                defaultValue: '',
                name: 'googlePlaceId'
            }
        ];
    }

    static getNamespace() {
        return 'participating-developments-search';
    }

    getSearchOptions() {
        const {
            searchUrl,
        } = this.options;
        return {
            endpointUrl: searchUrl,
        };
    }

    getPredictiveSearchOptions() {
        return this.options.predictiveSearchOptions;
    }

    onInit() {
        this.render();
    }

    render() {
        ReactDOM.render(
            <ParticipatingDevelopmentsSearch
                zeroResults={this.options.zeroResults}
                filtersConfig={this.filtersConfig}
                options={this.getSearchOptions()}
                predictiveSearchOptions={this.getPredictiveSearchOptions()}
            />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
