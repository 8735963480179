import BedroomFilter from './bedroom-filter';
import Filter from './filter';
import FilterOption from './filter-option';

export default class ProductTypeFilterOption extends FilterOption {
    constructor({
        productType,
        label = '',
        zeroPriceText,
        hideCalculations = false,
        replacementDescription,
        bedroomFilter,
        options,
    }) {
        super({ label });
        this.productType = productType;
        this.zeroPriceText = zeroPriceText;
        this.hideCalculations = hideCalculations;
        this.replacementDescription = replacementDescription;
        this.bedroomFilter = this.setBedroomFilter(bedroomFilter);
        this.value = productType != null ? productType : '';
        this.options = options;
    }

    setBedroomFilter = (settings) => {
        if (Filter.isFilterValid(settings)) {
            return new BedroomFilter(settings);
        }

        return null;
    };
}
