import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class MobileFiltersCheckbox extends React.Component {
    onChange = (e) => {
        const { isDisabled, isChecked, onChange } = this.props;
        if (!isDisabled || isChecked) {
            onChange(e);
        }
    };

    render() {
        const { isDisabled, isChecked, text, gtmSelector } = this.props;
        return (
            <div className="mobile-filters-checkbox">
                <label
                    className="mobile-filters-checkbox__label"
                    data-dc-gtm-selector={gtmSelector}
                >
                    <input
                        type="checkbox"
                        className={classNames({
                            'mobile-filters-checkbox__input': true,
                            'visually-hidden': true,
                            'is-disabled': isDisabled,
                        })}
                        checked={isChecked}
                        onChange={this.onChange}
                    />
                    <span className="mobile-filters-checkbox__placeholder">
                        <span className="mobile-filters-checkbox__text">{text}</span>
                        <span className="mobile-filters-checkbox__cross"></span>
                    </span>
                </label>
            </div>
        );
    }
}

MobileFiltersCheckbox.defaultProps = {
    isDisabled: false,
};

MobileFiltersCheckbox.propTypes = {
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    gtmSelector: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
