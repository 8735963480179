import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import ZeroSearchResults from '../../../zero-search-results/js/zero-search-results';

class DevelopmentsSearchZeroResults extends React.PureComponent {
    render() {
        const { zeroResults } = this.props;

        return (
            <div className="developments-search__zero-results">
                <ZeroSearchResults html={zeroResults.message}/>
            </div>
        );
    }
}

DevelopmentsSearchZeroResults.propTypes = {
    zeroResults: PropTypes.shape({
        message: PropTypes.string.isRequired
    }).isRequired
};

export default withContext(DevelopmentsSearchZeroResults, {
    zeroResults: 'zeroResults'
});
