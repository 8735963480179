import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import SlickResponsive from './slick-responsive';

class SlickCarousel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.currentSlideIndex = null;
        this.rootRef = React.createRef();
        this.carousel = null;
    }

    createCarousel(element, persistPosition = false) {
        const initialSlide = persistPosition && this.currentSlideIndex !== null ? this.currentSlideIndex : 0;
        const { responsiveOptions, afterChange } = this.props;

        let options = this.props.options;
        options = { ...options, initialSlide };

        this.carousel = new SlickResponsive($(element), options, responsiveOptions);
        if (afterChange) {
            this.carousel.on('afterChange', afterChange);
        }
    }

    destroyCarousel() {
        if (this.carousel) {
            this.currentSlideIndex = this.carousel.slick('slickCurrentSlide');
            this.carousel.slick('unslick');
            this.carousel.off('afterChange');
        }
    }

    componentDidUpdate(prevProps) {
        this.createCarousel(this.rootRef.current, this.props.slides === prevProps.slides);
    }

    componentWillUnmount() {
        if (this.carousel) {
            this.carousel.slick('unslick');
        }
    }

    render() {
        const { slides, rootClass } = this.props;

        if (slides.length === 0) {
            return null;
        }

        this.destroyCarousel();

        return (
            <div className={`${rootClass}`} ref={this.rootRef}>
                {this.props.children}
            </div>
        );
    }
}

SlickCarousel.defaultProps = {
    responsiveOptions: {},
    rootClass: '',
};

SlickCarousel.propTypes = {
    options: PropTypes.object.isRequired,
    slides: PropTypes.array.isRequired,
    responsiveOptions: PropTypes.object,
    afterChange: PropTypes.func,
    rootClass: PropTypes.string,
};

export default SlickCarousel;
