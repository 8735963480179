export const CONTROL_TYPE_CHECKLIST = 'checklist';
export const CONTROL_TYPE_SELECT = 'dropdown';
export const CONTROL_TYPE_RADIOGROUP = 'radiogroup';
export const FILTER_NAME_PRICE = 'maxPrice';
export const FILTER_NAME_RENT = 'maxRent';
export const FILTER_NAME_OPSO = 'maxOpsoPrice';
export const FILTER_NAME_RADIUS = 'searchRadius';
export const FILTER_NAME_OWNERSHIP = 'ownershipType';
export const FILTER_OWNERSHIP_SALE = 'sale';
export const FILTER_OWNERSHIP_RENT = 'rent';
export const FILTER_OWNERSHIP_OPSO = 'opso';
export const FILTER_OWNERSHIP_BEDROOMS = 'numberOfBedrooms';
export const FILTER_OWNERSHIP_AVAILABILITY = 'availability';
export const FILTER_OWNERSHIP_TYPE = 'propertyTypes';
export const FILTER_TRACK_CATEGORY = 'Search Results';
export const FILTER_TRACK_ACTION = 'Search filters > applied';
export const PATHNAME_SALE = '/retirement-properties-for-sale/';
export const PATHNAME_RENT = '/retirement-properties-to-rent/';
export const PATHNAME_OPSO = '/shared-ownership-retirement-properties/';
export const FILTER_SORT_PRICE_LOW_HIGH = 'Price';
export const FILTER_SORT_PRICE_HIGH_LOW = 'PriceDescending';

export const filters = [
    'ownershipType',
    'maxRent',
	'maxOpsoPrice',
    'maxPrice',
    'searchRadius',
    'propertyTypes',
    'numberOfBedrooms',
    'availability',
    'sort'
];

export const initialPayloadState = {
    offset: 0,
    channel: 'Both',
    availability: [],
    maxPrice: '',
    maxRent: '',
	maxOpsoPrice: '',
    numberOfBedrooms: [],
    ownershipType: '',
    propertyTypes: [],
    searchRadius: '',
    sort: 'Price'
};
