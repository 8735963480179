import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MobileFiltersRadiobutton = ({
    isDisabled,
    text,
    id,
    value,
    onChange,
    setRadiobuttonValue,
    radiobuttonValue,
    filterOptions
}) => {
    const onRadioChange = (filterValue, currentValue) => {
        setRadiobuttonValue(currentValue);
        onChange(filterValue, currentValue);
    };

    return (
        <div className="mobile-filters-radiobutton">
            <label className="mobile-filters-radiobutton__label">
                <input
                    type="radio"
                    className={classNames({
                        'mobile-filters-radiobutton__input': true,
                        'visually-hidden': true,
                        'is-disabled': isDisabled,
                    })}
                    checked={radiobuttonValue === value && radiobuttonValue !== null}
                    name={id}
                    value={value}
                    onChange={(e) => onRadioChange('ownershipType', e.target.value)}
                />
                <span className="mobile-filters-radiobutton__placeholder"></span>
                <span className="mobile-filters-radiobutton__text">{text}</span>
            </label>
        </div>
    );
};

MobileFiltersRadiobutton.defaultProps = {
    isDisabled: false,
};

MobileFiltersRadiobutton.propTypes = {
    isDisabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    onChange: PropTypes.func.isRequired,
    radiobuttonValue: PropTypes.bool,
    setRadiobuttonValue: PropTypes.func,
};

export default MobileFiltersRadiobutton;
