import React from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import axios from 'axios';
import api from 'general/js/api';
import SequentialRequestsProxy from 'general/js/api/sequential-requests-proxy';

const PAGINATION_DEFAULT = {
    perPage: 0,
    totalCount: 0,
    currentPage: 1,
    pageCount: 0,
};

const FILTER_CHANGE_DEBOUNCE_TIME = 400;
const ANIMATION_TIME_DISAPPEARING = 100;
const ANIMATION_TIME_APPEARING = 400;

const defaultStaticOptions = {
    makeInitialRequest: true,
    // do not prolong loading
    minLoadingTime: 0
};

export default class Listing extends React.Component {
    constructor(props) {
        super(props);
        this.api = new SequentialRequestsProxy(api);
        this.animatingContentElement = null;
        this.innerComponent = null;

        this.lastLoadingTimeout = null;
        this.isLoadingProlonged = false;

        const { options: { items, pagination, data }, initialFilters, staticOptions } = props;

        this.staticOptins = { ...defaultStaticOptions, ...staticOptions };

        this.hasInitialItems = Array.isArray(items);
        const initialPagination = pagination || PAGINATION_DEFAULT;

        this.state = {
            items: this.hasInitialItems ? items : [],
            // data can be some custom object to init, update on every search and pass to the component
            data,
            filters: initialFilters,
            appliedFilters: initialFilters,
            pagination: initialPagination,
            isLoading: false,
            isLoadingMore: false,
            isFiltersApplied: false,
            isInitialRequestFulfilled: this.hasInitialItems
        };

        this.currentPage = initialPagination.currentPage;
    }

    animatingContentRef = (element) => {
        this.animatingContentElement = element;
    };

    innerComponentRef = (component) => {
        this.innerComponent = component;
    };

    componentDidMount() {
        if (this.hasInitialItems) {
            this._notifyInnerComponentOnFiltersApply(this.state.appliedFilters);
        } else if (this.staticOptins.makeInitialRequest) {
            this.loadData();
        }
    }

    startLoading() {
        const { minLoadingTime } = this.staticOptins;
        if (minLoadingTime) {
            // clear previous timeout if new loading process has been started
            if (this.lastLoadingTimeout) {
                clearTimeout(this.lastLoadingTimeout);
                this.lastLoadingTimeout = null;
            }
        }

        this.setState({ isLoading: true });

        if (minLoadingTime) {
            // set new timeout to prolong loading
            this.lastLoadingTimeout = setTimeout(() => {
                // if the flag is set - we should deal with state
                if (this.isLoadingProlonged) {
                    this.setState({ isLoading: false });
                }
                this.isLoadingProlonged = false;
                this.lastLoadingTimeout = null;
            }, minLoadingTime);
        }
    }

    stopLoading() {
        if (this.staticOptins.minLoadingTime) {
            // if the var is present - it means we need to prolong loading
            // and not set it to false right away
            if (this.lastLoadingTimeout === null) {
                this.setState({ isLoading: false });
            } else {
                this.isLoadingProlonged = true;
            }
        } else {
            this.setState({ isLoading: false });
        }
    }

    animateChange = (changeAction) => {
        if (this.animatingContentElement) {
            anime.timeline()
                .add({
                    targets: this.animatingContentElement,
                    opacity: [1, 0],
                    easing: 'easeInOutQuart',
                    duration: ANIMATION_TIME_DISAPPEARING,
                    complete: () => {
                        if (changeAction) {
                            changeAction();
                        }
                    }
                })
                .add({
                    targets: this.animatingContentElement,
                    opacity: [0, 1],
                    easing: 'easeInOutQuart',
                    duration: ANIMATION_TIME_APPEARING
                });
        } else {
            changeAction();
        }
    };

    loadData() {
        this.startLoading();
        this.getData().then((result) => {
            this.animateChange(() => {
                const { pagination, items, data } = result;
                const newPagination = pagination ? pagination : this.state.pagination;
                this.setState({
                    items,
                    data,
                    pagination: newPagination,
                    isInitialRequestFulfilled: true,
                    isFiltersApplied: true
                });
                this.currentPage = newPagination.currentPage;
                this.stopLoading();
            });
        }, (error) => {
            // hide after error only when our request has been canceled
            if (!axios.isCancel(error)) {
                this.stopLoading();
            }
        });
    }

    loadNextPageData() {
        if (!this.state.isLoadingMore) {
            this.setState({ isLoadingMore: true }, () => {
                this.getData(this.state.items.length).then((result) => {
                    const { pagination, items } = result;
                    const newPagination = pagination ? pagination : this.state.pagination;
                    this.setState({
                        items: [...this.state.items, ...items],
                        pagination: newPagination,
                        isLoadingMore: false
                    });
                    this.currentPage = newPagination.currentPage;
                }, () => {
                    this.setState({ isLoadingMore: false });
                });
            });
        }
    }

    updateFilters(filters, apply = true, debounce = false) {
        const newFilters = { ...this.state.filters, ...filters };
        this.setFilters(newFilters, apply, debounce);
    }

    getFilters() {
        return this.state.filters;
    }

    _notifyInnerComponentOnFiltersApply(filters) {
        if (this.innerComponent.handleFiltersApply) {
            this.innerComponent.handleFiltersApply(filters);
        }
    }

    setFilters(filters, apply = true, debounce = false) {
        const newState = {
            filters,
        };

        if (apply) {
            newState.appliedFilters = filters;
            this._notifyInnerComponentOnFiltersApply(filters);
        } else {
            // set filters without applying
            newState.isFiltersApplied = false;
        }

        this.setState(newState, () => {
            if (apply) {
                if (this.loadDataTimeout) {
                    clearTimeout(this.loadDataTimeout);
                    this.loadDataTimeout = null;
                }

                if (debounce) {
                    this.loadDataTimeout = setTimeout(() => {
                        this.loadData();
                        this.loadDataTimeout = null;
                    }, FILTER_CHANGE_DEBOUNCE_TIME);
                } else {
                    this.loadData();
                }
            }
        });
    }

    onLoadMore = () => {
        this.loadNextPageData();
    };

    getData(offset = 0) {
        const customParams = this.props.options.customParams || {};
        const params = { ...this.state.filters, ...customParams };
        params.offset = offset;
        params.pageSize = this.props.options.pageSize;

        return this.api.post(this.props.options.endpointUrl, params, {
            headers: this.props.options.customHeaders ? this.props.options.customHeaders : {}
        }).then((result) => {
            return result.data;
        });
    }

    render() {
        const { isInitialRequestFulfilled, filters, appliedFilters, items, data, pagination, isLoading, isLoadingMore } = this.state;

        return this.props.render({
            isInitialRequestFulfilled,
            filters,
            appliedFilters,
            items,
            data,
            pagination,
            isLoading,
            isLoadingMore,
            onFiltersChange: this.onFiltersChange,
            animateChange: this.animateChange,
            onLoadMore: this.onLoadMore,
            animatingContentRef: this.animatingContentRef,
            innerComponentRef: this.innerComponentRef
        });
    }
}

Listing.propTypes = {
    options: PropTypes.shape({
        data: PropTypes.object,
        endpointUrl: PropTypes.string.isRequired,
        items: PropTypes.array,
        pagination: PropTypes.object,
        customParams: PropTypes.object,
        customHeaders: PropTypes.object,
        pageSize: PropTypes.number
    }).isRequired,
    staticOptions: PropTypes.shape({
        minLoadingTime: PropTypes.number
    }),
    render: PropTypes.func.isRequired,
    initialFilters: PropTypes.object.isRequired
};
