import React from 'react';
import PropTypes from 'prop-types';
import api from 'general/js/api';
import SequentialRequestsProxy from 'general/js/api/sequential-requests-proxy';
import PropertiesTable from './components/properties-table.jsx';
import constants from './constants.js';
import { PropertiesTableContext } from './context.jsx';

class PropertiesTableDataContainer extends React.PureComponent {
    constructor(props) {
        super(props);
        this.searchUrl = this.props.searchUrl;
        this.api = new SequentialRequestsProxy(api);
        this.storageId = constants.DEFAULT_STORAGE_PREFIX + this.props.developmentId;
        this.rentalJourney = this.searchUrl.toString().includes("isRentalJourney=True");
        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.removeStateFromStorage();
        
        // if (this.props.rentalsDisabledForDevelopment) {
        //     return this.loadInitialDataForSelling(true);
        // }

        // if (this.props.rentalOnlyDevelopment) {
        //     return this.loadInitialDataForRental(true);
        // }
        // return this.loadInitialDataForBoth();
        return this.loadInitialDataForSelling(true);
    }

    isSitecore = () => {
        return window.Sitecore && window.Sitecore.PageModes && window.Sitecore.PageModes.PageEditor;
    };

    getPageSize = () => {
        return this.props.isOnMobileViewport
            ? constants.MAX_PROPS_ON_MOBILE
            : 300;
    };

    getDefaultVisibleColumns = () => {
        return constants.HEADINGS.reduce((acc, val) => {
            acc[val.camelCaseId] = val.visibleByDefault;
            return acc;
        }, {});
    };



    getInitialState = () => {
        return {
            isComponentHidden: false,
            isTextShowing: true,
            isGettingData: false,
            message: constants.LOADING_MESSAGE,
            tabs: [],
            activeTab: this.rentalJourney ? { ...constants.TABS[constants.RENTAL_TAB] } : { ...constants.TABS[constants.SELLING_TAB] },
            currentProperties: [],
            visibleColumns: this.getDefaultVisibleColumns(),
            ...this.getDefaultNavState(),
        };
    };

    getDefaultNavState = () => {
        return {
            activeHead: constants.DEFAULT_ACTIVE_HEAD,
            activeSortType: constants.DEFAULT_ACTIVE_SORT,
            currentDisplayedPages: [1],
            pagination: {
                offset: 0,
                pageSize: this.getPageSize(),
                totalCount: 0,
            },
        };
    };

    isTabWithProperties = (props) => {
        return props && props.length && props.length !== 0;
    };

    loadInitialDataForBoth = () => {

        if (this.rentalJourney) {
            this.loadInitialDataForSelling(false);
        }
        else {
            this.loadInitialDataForRental(false);
        }
    };

    loadInitialDataForRental = (loadInitialView) => {
        api.post(this.searchUrl, this.getRentalInitialParams())
            .then((result) => {
                if (this.isTabWithProperties(result.data.properties)) {
                    this.setState(
                        (prevState) => {
                            return {
                                ...this.getSetTabAction(
                                    prevState,
                                    result.data,
                                    constants.TABS[constants.RENTAL_TAB]
                                ),
                            };
                        },
                        () => {
                            if (loadInitialView) {
                                this.activateInitialView();
                            }
                            else {
                                this.loadInitialDataForSelling(true);
                            }
                        }
                    );
                } else {

                    if (loadInitialView) {
                        this.activateInitialView();
                    }
                    else {
                        this.loadInitialDataForSelling(true);
                    }
                }
            })
            .catch((error) => {
                this.setState(this.showServerErrorAction);
                console.error(error);
            });
    };

    loadInitialDataForSelling = (loadInitialView) => {
        api.post(this.searchUrl, this.getSellingInitialParams())
            .then((result) => {
                if (this.isTabWithProperties(result.data.properties)) {
                    this.setState(
                        (prevState) => ({
                            ...this.getSetTabAction(
                                prevState,
                                result.data,
                                constants.TABS[constants.SELLING_TAB]
                            ),
                        }),
                        () => {
                            if (loadInitialView) {
                                this.activateInitialView();
                            }
                            else {
                                this.loadInitialDataForRental(true);
                            }
                        }
                    );
                } else {
                    if (loadInitialView) {
                        this.activateInitialView();
                    }
                    else {
                        this.loadInitialDataForRental(true);
                    }
                }
            })
            .catch((error) => {
                this.setState(this.showServerErrorAction);
                console.error(error);
            });
    };

    activateInitialView = () => {

        if (this.rentalJourney) {

            this.state.activeTab = constants.TABS[constants.RENTAL_TAB];  
        }

        if (this.isTabWithProperties(this.state.currentProperties)) {
            const prevSession = this.getStateFromStorage();

            if (prevSession) {
                this.getInitialDataFromOldSession(prevSession);
            } else {
                this.setState(this.showPropertiesAction);
            }
        } else if (!this.isSitecore()) {
            this.setState(this.hideComponentAction, () => {
                this.hideParentSection();
            });
        }
    };

    hideParentSection = () => {
        this.props.parentSectionRef.style.display = 'none';
    };

    getInitialDataFromOldSession = ({ params, partialState }) => {
        api.post(this.searchUrl, params)
            .then((result) => {
                if (this.isTabWithProperties(result.data.properties)) {
                    this.setState(() => ({
                        ...this.getSetNewDataAction(partialState, result.data),
                        ...this.showPropertiesAction,
                    }));
                } else {
                    this.setState(this.showPropertiesAction);
                    this.removeStateFromStorage();
                }
            })
            .catch((error) => {
                this.setState(this.showPropertiesAction);
                this.removeStateFromStorage();
                console.error(error);
            });
    };

    getData = (params, partialState) => {
        this.setState(
            {
                ...this.showGettingDataAction,
                ...partialState,
            },
            () => {
                api.post(this.searchUrl, params)
                    .then((result) => {
                        if (this.isTabWithProperties(result.data.properties)) {
                            this.setState(() => ({
                                ...this.showPropertiesAction,
                            }));
                            this.saveStateInStorage(params, partialState);
                        } else {
                            this.setState(() => ({
                                ...this.getSetNewDataAction(partialState, result.data),
                                ...this.showMessageWithNoPropsAction,
                            }));
                            this.removeStateFromStorage();
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            ...this.getDefaultNavState(),
                            ...this.showServerErrorAction,
                        });
                        this.removeStateFromStorage();
                        console.error(error);
                    });
            }
        );
    };

    resetPagination = () => {
        this.setState({
            ...this.state,
            ...this.getDefaultNavState()
        });
    };

    saveStateInStorage = (params, partialState) => {
        try {
            window.sessionStorage.setItem(this.storageId, JSON.stringify({ params, partialState }));
        } catch (error) {
            console.error(error);
        }
    };

    getStateFromStorage = () => {
        try {
            return JSON.parse(window.sessionStorage.getItem(this.storageId));
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    removeStateFromStorage = () => {
        try {
            window.sessionStorage.removeItem(this.storageId);
        } catch (error) {
            console.error(error);
        }
    };

    getInitialParams = () => ({
        pageSize: this.getPageSize(),
        offset: 0,
        orderBy:
            constants.HEADINGS[constants.DEFAULT_ACTIVE_HEAD].id +
            constants.SORT_TYPES[constants.DEFAULT_ACTIVE_SORT].id,
    });

    getRentalInitialParams = () => ({
        ...this.getInitialParams(),
        channel: null,
    });

    getSellingInitialParams = () => ({
        ...this.getInitialParams(),
        channel: null,
    });

    showPropertiesAction = {
        isTextShowing: false,
        isGettingData: false,
    };

    showMessageWithNoPropsAction = {
        isTextShowing: true,
        isGettingData: false,
        message: constants.NO_PROPERTIES_MESSAGE,
    };

    hideComponentAction = {
        isComponentHidden: true,
    };

    showGettingDataAction = {
        isGettingData: true,
    };

    showServerErrorAction = {
        isTextShowing: true,
        isGettingData: false,
        message: constants.ERROR_MESSAGE,
    };

    getSetTabAction = (prevState, data, currentTab) => ({
        pagination: {
            ...prevState.pagination,
            ...data.pagination,
        },
        currentProperties: [...data.properties],
        tabs: [currentTab, ...prevState.tabs],
        activeTab: currentTab,
        visibleColumns: { ...data.visibleColumns },
    });

    getSetNewDataAction = (partialState, data) => ({
        ...this.getDefaultNavState(),
        ...partialState,
        pagination: {
            ...data.pagination,
        },
        currentProperties: [...data.properties],
        visibleColumns: { ...data.visibleColumns },
    });

    getContextValue = () => {
        return {
            tableSettings: this.props.tableSettings,
            tableFilters: this.props.tableFilters,
            currentPageSize: this.getPageSize(),
            getData: this.getData,
            resetPagination: this.resetPagination,
            isOnMobileViewport: this.props.isOnMobileViewport,
            ...this.state,

        };
    };

    render() {
       
        return (
            
            !this.state.isComponentHidden && (
                <PropertiesTableContext.Provider value={this.getContextValue()}>
                    <PropertiesTable />
                </PropertiesTableContext.Provider>
            )
        );
    }
}

PropertiesTableDataContainer.propTypes = {
    rentalsDisabledForDevelopment: PropTypes.bool,
    rentalOnlyDevelopment: PropTypes.bool,
    searchUrl: PropTypes.string,
    tableFilters: PropTypes.array.isRequired,
    isOnMobileViewport: PropTypes.bool,
    parentSectionRef: PropTypes.instanceOf(Element),
    developmentId: PropTypes.string,
};

export default PropertiesTableDataContainer;
