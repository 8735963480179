import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '@General/js/dc/dc-base-component';
import InteractiveSitePlan from './interactive-site-plan.jsx';
import NewInteractiveSitePlan from './interactive-site-plan-v2.jsx';
import { createInteractiveSitePlanApi } from './create-interactive-site-plan-api.js';
import { InteractiveSitePlanStoreProvider } from './use-interactive-site-plan-store.jsx';

export default class InteractiveSitePlanComponent extends DcBaseComponent {
    static getNamespace() {
        return 'interactive-site-plan';
    }

    onInit() {
        this.store = createInteractiveSitePlanApi(this.options);
        this.render();
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    render() {
        const isNewVersion = this.options.enableAutomatedLegend;
        ReactDOM.render(
            <InteractiveSitePlanStoreProvider store={this.store}>
              <NewInteractiveSitePlan isNewVersion={isNewVersion} />
            </InteractiveSitePlanStoreProvider>,
            this.element
        );
    }
}
