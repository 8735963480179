import React from 'react';
import BudgetOpenButton from './budget-open-button.jsx';
import PropTypes from 'prop-types';
import BudgetTitle from '../components/budget-title.jsx';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import Filter from '../store/filter';

export default function BudgetOpenWrapper({ dispatchGaEvent }) {
    const [state] = useBudgetCalculatorStore();
    if (
        Filter.isFilterValid(state.propertyTypeFilter) &&
        state.propertyTypeFilter.hideCalculations === true
    ) {
        const {
            replacementTitle,
            replacementSubtitle,
        } = state.propertyTypeFilter.activeOption.replacementDescription;

        return <BudgetTitle title={replacementTitle} subtitle={replacementSubtitle} />;
    }
    return <BudgetOpenButton dispatchGaEvent={dispatchGaEvent} />;
}

BudgetOpenWrapper.propTypes = {
    dispatchGaEvent: PropTypes.func
};

