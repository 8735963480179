import React, { useEffect, useState, useRef } from 'react';
import { useStore } from 'effector-react/compat';
import debounce from 'lodash/debounce';
import constants from '@General/js/constants.js';
import { useInteractiveSitePlanStore } from './use-interactive-site-plan-store.jsx';
import Region from './region.js';
import {
    COLORS,
    DEFAULT_NUMBER_DESKTOP,
    DEFAULT_NUMBER_MOBILE,
    SHAPE_RECT,
    SHAPE_POLY,
    getAreaStatusType,
} from './constants.js';
import trackerService from '@General/js/tracker-service.js'

export default function NewImageMapper() {
    const [store, api] = useInteractiveSitePlanStore();
    const state = useStore(store);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [hasTracked, setHasTracked] = useState(false);
    const [, setRenderCounter] = useState(0);
    const containerEl = useRef(null);
    const imgEl = useRef(null);
    const canvasEl = useRef(null);
    const ctx = canvasEl.current ? canvasEl.current.getContext('2d') : null;

    const styles = {
        canvas: {
            position: 'absolute',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            zIndex: 2,
        },
        img: {
            zIndex: 1,
            userSelect: 'none',
            width: '100%',
            height: '100%',
        },
    };

    useEffect(() => {
        const onResize = debounce(() => {
            setRenderCounter((prevState) => prevState + 1);
        }, 500);

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [setRenderCounter]);

    const scaleCoords = (coords) => {
        const imgWidth = state.floor.originalWidth;
        const width = imgEl.current.clientWidth;
        const scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
        return coords.map((coord) => coord * scale);
    };

    const computeRectCenter = (scaledCoords) => {
        if (!scaledCoords) return [0, 0];

        const n = scaledCoords.length / 2;
        const { y, x } = scaledCoords.reduce(
            ({ y, x }, val, idx) => (!(idx % 2) ? { y, x: x + val / n } : { y: y + val / n, x }),
            { y: 0, x: 0 }
        );
        return [x, y];
    };

    const computePolyCenter = (scaledCoords) => {
        if (!scaledCoords) return [0, 0];

        const points = scaledCoords.reduce(
            (a, v, i, s) => (i % 2 ? a : [...a, { x: s[i], y: s[i + 1] }]),
            []
        );

        const region = new Region(points);

        return region.centroid();
    };

    const getFontForNumber = (
        textSize
    ) => `bold normal normal ${textSize}px/25px AzoSans, Helvetica, Arial,
    sans-serif`;

    const drawNumber = (number, coords, textColor) => {
        if (number) {
            number += '';

            const textSize =
                window.innerWidth < constants.VIEWPORT_WIDTH_TABLET ?
                    DEFAULT_NUMBER_MOBILE :
                    DEFAULT_NUMBER_DESKTOP;

            ctx.font = getFontForNumber(textSize);
            const width = ctx.measureText(number).width;
            ctx.fillStyle = textColor;
            ctx.fillText(number, coords[0] - width / 2, coords[1] + textSize / 4);
            ctx.fillStyle = COLORS.fillColor;
        }
    };

    const drawrect = (lineWidth, strokeColor, coords, fillColor) => {
        const [left, top, right, bot] = coords;
        ctx.fillStyle = fillColor;
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = strokeColor;
        ctx.strokeRect(left, top, right - left, bot - top);
        ctx.fillRect(left, top, right - left, bot - top);
        ctx.fillStyle = COLORS.fillColor;
    };

    const drawpoly = (lineWidth, strokeColor, scaledCoords, fillColor) => {
        const coords = scaledCoords.reduce(
            (a, v, i, s) => (i % 2 ? a : [...a, s.slice(i, i + 2)]),
            []
        );

        ctx.fillStyle = fillColor;
        ctx.beginPath();
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = strokeColor;
        const first = coords.unshift();
        ctx.moveTo(first[0], first[1]);
        coords.forEach((c) => ctx.lineTo(c[0], c[1]));
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        ctx.fillStyle = COLORS.fillColor;
    };

    const drawFigure = (shape, drawSettings, scaledCoords, number, textColor) => {
        let centerCoords;

        switch (shape) {
            case SHAPE_RECT:
                drawrect(...drawSettings);
                centerCoords = computeRectCenter(scaledCoords);
                break;
            case SHAPE_POLY:
                drawpoly(...drawSettings);
                centerCoords = computePolyCenter(scaledCoords);
                break;
            default:
                console.error(`Shape ${shape} is unknown to image-mapper`);
                return;
        }

        drawNumber(number, centerCoords, textColor);
    };

    const renderShapeByBedroomAmount = (area, scaledCoords, textColor, shapeColor) => {
        const drawSettings = [
            COLORS.lineWidth,
            COLORS.blackColor,
            scaledCoords,
            shapeColor
        ];
        drawFigure(area.shape, drawSettings, scaledCoords, area.number, textColor);
    };

    const renderPrefilledAreas = () => {
        state.floor.map.areas.forEach((area) => {
            const { purchasePrice, rentalPrice } = area;
            const zeroPurchasePrice = (purchasePrice === '' || purchasePrice === null) || purchasePrice === 0;
            const zeroRentalPrice = (rentalPrice === '' || rentalPrice === null) || rentalPrice === 0;

            if ((zeroPurchasePrice && zeroRentalPrice) && area.bedroomAmount > 0) {
                area.bedroomAmount = 0;
                area.mapPlotStatus = '';
            }

            const scaledCoords = scaleCoords(area.coords);
            let drawSettings;
            let shapeColor;

            switch (area.bedroomAmount) {
                case 0:
                    if (typeof getAreaStatusType(area.isPublished, area.mapPlotStatus) === 'undefined') {
                        shapeColor = COLORS.greyColor;
                    } else {
                        shapeColor = getAreaStatusType(area.isPublished, area.mapPlotStatus)?.mainColor;
                    }

                    drawSettings = [
                        COLORS.lineWidth,
                        COLORS.blackColor,
                        scaledCoords,
                        shapeColor
                    ];
                    if (area.mapPlotStatus === 'sold') {
                        drawFigure(area.shape, drawSettings, scaledCoords, area.number, COLORS.whiteColor);
                    } else {
                        drawFigure(area.shape, drawSettings, scaledCoords, area.number, COLORS.blackColor);
                    }
                    break;
                case 1:
                    shapeColor = getAreaStatusType(area.isPublished, area.mapPlotStatus)?.oneBedColor;
                    renderShapeByBedroomAmount(
                        area,
                        scaledCoords,
                        COLORS.blackColor,
                        getAreaStatusType(area.isPublished, area.mapPlotStatus).oneBedColor
                    );
                    break;
                case 2:
                    shapeColor = getAreaStatusType(area.isPublished, area.mapPlotStatus)?.twoBedsColor;
                    renderShapeByBedroomAmount(
                        area,
                        scaledCoords,
                        COLORS.whiteColor,
                        getAreaStatusType(area.isPublished, area.mapPlotStatus)?.twoBedsColor
                    );
                    break;
                case 3:
                    shapeColor = getAreaStatusType(area.isPublished, area.mapPlotStatus)?.threeBedsColor;
                    renderShapeByBedroomAmount(
                        area,
                        scaledCoords,
                        COLORS.blackColor,
                        shapeColor
                    );
                    break;
                default:
                    break;
            }
        });
    };

    const hoverOn = (area) => {
        if (getAreaStatusType(area.isPublished, area.mapPlotStatus)?.isClickable) {
            let drawSettings;
            switch (area.bedroomAmount) {
                case 1:
                    drawSettings = [
                        COLORS.lineWidth,
                        COLORS.blackColor,
                        area.scaledCoords,
                        COLORS.hoverSunriseColor,
                    ];

                    drawFigure(area.shape, drawSettings, area.scaledCoords, area.number, COLORS.blackColor);
                    break;
                case 2:
                    drawSettings = [
                        COLORS.lineWidth,
                        COLORS.blackColor,
                        area.scaledCoords,
                        COLORS.hoverIndigoColor,
                    ];

                    drawFigure(area.shape, drawSettings, area.scaledCoords, area.number, COLORS.whiteColor);
                    break;
                case 3:
                    drawSettings = [
                        COLORS.lineWidth,
                        COLORS.blackColor,
                        area.scaledCoords,
                        COLORS.hoverSandColor,
                    ];

                    drawFigure(area.shape, drawSettings, area.scaledCoords, area.number, COLORS.blackColor);
                    break;
                default:
                    break;
            }
        }
    };

    const hoverOff = () => {
        ctx.clearRect(0, 0, canvasEl.current.width, canvasEl.current.height);
        renderPrefilledAreas();
    };

    const click = (area, index, event) => {
        event.preventDefault();
        if (!hasTracked) {
            setHasTracked(true);
            trackerService.track('ButtonClick_InteractiveSitePlan');
        }
        api.openPopupEvt({ area });
    };

    const initCanvas = () => {
        canvasEl.current.width = imgEl.current.clientWidth;
        canvasEl.current.height = imgEl.current.clientHeight;
        ctx.clearRect(0, 0, canvasEl.current.width, canvasEl.current.height);
        ctx.fillStyle = COLORS.fillColor;

        renderPrefilledAreas();
    };

    if (isImageLoaded) {
        initCanvas();
    }

    return (
        <div className="interactive-site-plan__map" ref={containerEl}>
            <img
                loading="lazy"
                style={styles.img}
                src={state.floor.image}
                useMap={`#${state.floor.map.name}`}
                alt=""
                ref={imgEl}
                onLoad={() => {
                    setIsImageLoaded(true);
                }}
                onDragStart={(e) => e.preventDefault()}
            />
            <canvas ref={canvasEl} style={styles.canvas} />
            {isImageLoaded && (
                <map name={state.floor.map.name}>
                    {state.floor.map.areas.map((area, index) => {
                        const scaledCoords = scaleCoords(area.coords);
                        const extendedArea = { ...area, scaledCoords };
                        const isClickable = getAreaStatusType(area.isPublished, area.mapPlotStatus)?.isClickable;
                        return (
                            <area
                                style={{
                                    cursor: isClickable ? 'pointer' : 'not-allowed',
                                }}
                                key={area._id || index}
                                onMouseEnter={hoverOn.bind(this, extendedArea)}
                                onMouseLeave={hoverOff}
                                onClick={isClickable ? click.bind(this, extendedArea, index) : null}
                                title={area.title ? area.title : null}
                                alt={area._id}
                                coords={scaledCoords.join(',')}
                                shape={area.shape}
                            />
                        );
                    })}
                </map>
            )}
        </div>
    );
}
