import React, { useState } from 'react';

function EventSearchCard(props) {
    const [expanded, setExpanded] = useState(false);
    const [flipped, setFlipped] = useState(false);

    const nthNumber = (number) => {
        if (number > 3 && number < 21) {
            return 'th';
        }

        switch (number % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const startDateTime = new Date(props.startDateTime);
    const endDateTime = new Date(props.endDateTime);

    const startYear = startDateTime.getFullYear();
    const startMonth = monthNames[startDateTime.getMonth()];
    const startDay = startDateTime.getDate();
    const startHours = startDateTime.getHours();
    const startMinutes = startDateTime.getMinutes();

    const endYear = endDateTime.getFullYear();
    const endMonth = monthNames[endDateTime.getMonth()];
    const endDay = endDateTime.getDate();
    const endHours = endDateTime.getHours();
    const endMinutes = endDateTime.getMinutes();

    const startEndDateDifferent = startDay !== endDay || startMonth !== endMonth || startYear !== endYear;
    const hasTime = !(startHours === startMinutes && startHours === 0);

    let dateTimeText = `${props.isRecurring ? 'Next event ' : ''}<time className="event-search-card__date" dateTime="${props.startDateTime}">${startDay}<sup>${nthNumber(startDay)}</sup> ${startMonth}${startYear !== endYear ? ` ${startYear}` : ''}`;

    if (startEndDateDifferent) {
        dateTimeText +=  `</time> to <time className="event-search-card__date" dateTime="${props.endDateTime}">${endDay}<sup>${nthNumber(endDay)}</sup> ${endMonth}${startYear !== endYear ? ` ${endYear}` : ''}</time>`;
    }

    if (hasTime) {
        if (startEndDateDifferent) {
            dateTimeText += `<time className="event-search-card__date" dateTime="${props.startDateTime}">`;
        }

        dateTimeText +=  ` ${startHours < 10 ? `0${startHours}` : startHours}:${startMinutes < 10 ? `0${startMinutes}` : startMinutes}</time>`;
        dateTimeText +=  `&ndash;<time className="event-search-card__date" dateTime="${props.endDateTime}">${endHours < 10 ? `0${endHours}` : endHours}:${endMinutes < 10 ? `0${endMinutes}` : endMinutes}</time>`;
    } else if (!startEndDateDifferent) {
        dateTimeText += '</time>';
    }

    let recurringText;

    if (props.recurringUntil) {
        const recurringUntilDateTime = new Date(props.recurringUntil);

        const recurringUntilYear = recurringUntilDateTime.getFullYear();
        const recurringUntilMonth = monthNames[recurringUntilDateTime.getMonth()];
        const recurringUntilDay = recurringUntilDateTime.getDate();

        recurringText = <span>{props.frequency} until <time className="event-search-card__date" dateTime={props.recurringUntil}>{recurringUntilDay}<sup>{nthNumber(recurringUntilDay)}</sup> {recurringUntilMonth}{startYear !== recurringUntilYear ? ` ${recurringUntilYear}` : ''}</time></span>
    }

    let distanceText;
    if (props.distanceFromSearch) {
        distanceText = <span>{props.distanceFromSearch}</span>
    }

    return (
        <div className={`event-search-card ${expanded ? 'event-search-card--expanded' : ''} ${flipped ? 'event-search-card--flipped' : ''}`}>
            <div className="event-search-card__inner">
                <div className="event-search-card__front" ref={node => node && (flipped ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}>
                    <div className="event-search-card__header">
                        <div className="event-search-card__title">
                            <a
                                className="event-search-card__title-link"
                                href={props.development.url}
                                onClick={() => props.tracking('searchResultsClickthrough', {
                                    clickthroughType: 'development',
                                    developmentName: props.development.name,
                                    developmentId: props.development.externalId,
                                    developmentDivision: props.development.division,
                                    developmentProduct: props.development.product,
                                    developmentStatus: props.development.status,
                                    salesforcePlotID: props.development.siteId,
                                    })}>{props.development.name}</a>
                            <span className="event-search-card__title-description">{props.title}</span>
                        </div>
                        {props.image &&
                            <img
                                alt={props.image.altText}
                                className="event-search-card__image"
                                loading="lazy"
                                src={props.image.url}
                            />
                        }
                    </div>
                    <div className="event-search-card__details">
                    <div className="event-search-card__details-item event-search-card__details-item--date">
                            <span className="event-search-card__details-primary" dangerouslySetInnerHTML={{ __html: dateTimeText }}></span>
                            {recurringText}
                        </div>
                        <div className="event-search-card__details-item event-search-card__details-item--location">
                            <span className="event-search-card__details-primary">{props.address}</span>
                            {distanceText}
                        </div>
                    </div>
                    <div className="event-search-card__description" dangerouslySetInnerHTML={{ __html: props.shortDescription }}></div>
                    {props.moreDescription &&
                        <div className="event-search-card__expand">
                            <div className="event-search-card__expand-content" dangerouslySetInnerHTML={{ __html: props.moreDescription }} id={`${props.id}-more-description-expand`} role="region"></div>
                            <button
                                aria-controls={`${props.id}-more-description-expand`}
                                aria-expanded={expanded}
                                className="event-search-card__expand-button event-search-card__expand-button--expand"
                                onClick={() => {
                                    !expanded && props.tracking('eventReadMore', {title: `${props.title} at ${props.development.name}`});
                                    setExpanded(!expanded);
                                }}
                                type="button">Read {expanded ? 'less' : 'more'}</button>
                            <button
                                aria-controls={`${props.id}-more-description-flip`}
                                aria-expanded={flipped}
                                className="event-search-card__expand-button event-search-card__expand-button--flip"
                                onClick={() => {
                                    props.tracking('eventReadMore', {title: `${props.title} at ${props.development.name}`});
                                    setFlipped(true);
                                }}
                                type="button">Read more</button>
                        </div>
                    }
                    {props.url &&
                        <div className="event-search-card__footer">
                            <a
                                className="btn-secondary event-search-card__footer-button"
                                href={props.url}
                                onClick={() => props.tracking('eventRsvpCta', {title: `${props.title} at ${props.development.name}`})}>Book now</a>
                        </div>
                    }
                </div>
                {props.moreDescription &&
                    <div className="event-search-card__back" id={`${props.id}-more-description-flip`} role="region" ref={node => node && (!flipped ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}>
                        <div className="event-search-card__back-header">
                            <button
                                aria-controls={`${props.id}-more-description-flip`}
                                aria-expanded={flipped}
                                className="event-search-card__back-close"
                                onClick={() => setFlipped(false)}
                                type="button"><span className="visually-hidden">Close</span></button>
                        </div>
                        <span className="event-search-card__title-description">{props.title} at {props.development.name}</span>
                        <div className="event-search-card__back-content" dangerouslySetInnerHTML={{ __html: props.moreDescription }}></div>
                        {props.url &&
                            <div className="event-search-card__footer">
                                <a
                                    className="btn-secondary event-search-card__footer-button"
                                    href={props.url}
                                    onClick={() => props.tracking('eventRsvpCta', {title: `${props.title} at ${props.development.name}`})}>Book now</a>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default EventSearchCard;
