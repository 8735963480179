import React from 'react';
import PropTypes from 'prop-types';

import { devSearchTypeItem } from '@Components/developments-search-v2/js/dev-search-types.js';
import DevPropertyTable from './components/campaign-property-table.jsx';

const CampaignPropertyTableDataContainer = ({
    item,
    paginationThreshold,
}) => (
    <DevPropertyTable
        item={item}
        paginationThreshold={paginationThreshold} />
);

CampaignPropertyTableDataContainer.propTypes = {
    paginationThreshold: PropTypes.number,
    item: devSearchTypeItem,
};

export default CampaignPropertyTableDataContainer;
