export const TRACKING_LVL_COOKIE_NAME = 'mcs-actions-tracking-level';

export const COOKIES_TYPE = {
    statistics: 'Statistics',
    marketing: 'Marketing',
};

export const getCookie = (name) => {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');

    if (parts.length === 2) { return parts.pop().split(';').shift(); }

    return '';
};
