const HEADINGS = [
    {
        id: 'Number',
        camelCaseId: 'number',
        displayName: 'No.',
        isOnMobile: true,
        visibleByDefault: true,
    },
    {
        id: 'Bedrooms',
        camelCaseId: 'bedrooms',
        displayName: 'Beds',
        isOnMobile: true,
        visibleByDefault: true,
    },
    {
        id: 'Prices',
        camelCaseId: 'prices',
        displayName: 'Price',
        isOnMobile: true,
        visibleByDefault: true,
    },
    {
        id: 'Floor',
        camelCaseId: 'floor',
        displayName: 'Floor',
        isOnMobile: false,
        visibleByDefault: true,
    },
    {
        id: 'Status',
        camelCaseId: 'status',
        displayName: 'Status',
        isOnMobile: false,
        visibleByDefault: true,
    },
    {
        id: 'Area',
        camelCaseId: 'area',
        displayName: 'SQ.M',
        isOnMobile: false,
        visibleByDefault: true,
    },
    {
        id: 'PropertyType',
        camelCaseId: 'propertyType',
        displayName: 'Type',
        isOnMobile: false,
        visibleByDefault: false,
    },
    {
        id: null,
        camelCaseId: null,
        displayName: 'view button column',
        isOnMobile: true,
        visibleByDefault: true,
    }
];
const SORT_TYPES = [
    {
        id: 'Asc',
        mod: 'up'
    },
    {
        id: 'Desc',
        mod: 'down'
    }
];
const TABS = [
    {
        id: 'Selling',
        displayName: 'For sale',
        textName: 'for sale',
    },
    {
        id: 'Rental',
        displayName: 'To rent',
        textName: 'to rent (pcm)'
    }
];
const MAX_PROPS_ON_MOBILE = 90;
const MAX_PROPS_ON_DESKTOP = 6;
const MAX_PAGES_DISPLAYED_MOBILE = 2;
const MAX_PAGES_DISPLAYED_DESKTOP = 5;
const LOADING_MESSAGE = {
    title: 'We\'re sorry to keep you waiting',
    text: 'It\'s taking a while, but this page is still loading. Please wait.'
};
const NO_PROPERTIES_MESSAGE = {
    title: 'We\'re sorry, there\'s been an error',
    text: 'We can\'t find the properties you\'re looking for. Please try again later.'
};
const ERROR_MESSAGE = {
    title: 'We\'re sorry, there\'s a problem with the connection',
    text: 'Please try again later.'
};
const SELLING_TAB = 0;
const RENTAL_TAB = 1;
const DEFAULT_ACTIVE_HEAD = 4;
const DEFAULT_ACTIVE_SORT = 0;
const ASC_SORT_INDEX = 0;
const DESC_SORT_INDEX = 1;
const DEFAULT_STORAGE_PREFIX = 'propTableFor';

export default {
    HEADINGS,
    SORT_TYPES,
    TABS,
    MAX_PROPS_ON_DESKTOP,
    MAX_PROPS_ON_MOBILE,
    MAX_PAGES_DISPLAYED_MOBILE,
    MAX_PAGES_DISPLAYED_DESKTOP,
    LOADING_MESSAGE,
    NO_PROPERTIES_MESSAGE,
    ERROR_MESSAGE,
    SELLING_TAB,
    RENTAL_TAB,
    DEFAULT_ACTIVE_HEAD,
    DEFAULT_ACTIVE_SORT,
    ASC_SORT_INDEX,
    DESC_SORT_INDEX,
    DEFAULT_STORAGE_PREFIX
};
