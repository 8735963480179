import React from 'react';
import PropTypes from 'prop-types';
import MonthsCarousel from './months-carousel';
import DaysCarousel from './days-carousel';

class DatePicker extends React.Component {
    render() {
        const {
            months, days, selectedDate, onMonthChange, onDayClick
        } = this.props;

        return (
            <div className="time-slot-picker__date-picker">
                <div className="form-control">
                    <div className="form-control__header">
                        <div className="form-control__label">Select a date</div>
                    </div>
                    <div className="form-control__content">
                        <MonthsCarousel months={months} onMonthChange={onMonthChange}/>
                        <DaysCarousel days={days} onDayClick={onDayClick}
                            selectedDate={selectedDate}/>
                    </div>
                </div>
            </div>
        );
    }
}

DatePicker.propTypes = {
    months: PropTypes.array.isRequired,
    days: PropTypes.array.isRequired,
    onMonthChange: PropTypes.func.isRequired,
    onDayClick: PropTypes.func.isRequired,
    selectedDate: PropTypes.string,
};

export default DatePicker;
