import AbstractVideo from './abstract-video';

export default class VimeoVideo extends AbstractVideo {
    constructor(options) {
        super(options);

        this.id = options.id;

        /**
         * @type {Promise<Player>}
         * @private
         */
        this._playerPromise = null;
        this.checkEagerInit();
    }

    checkEagerInit() {
        if (this.isEagerInit) {
            this.init();
        }
    }

    init() {
        this.player.then(() => {
            // just trigger player, do not do anything
        });
    }

    get player() {
        if (this._playerPromise === null) {
            this._playerPromise = import('@vimeo/player').then(module => {
                const Player = module.default;
                const player = new Player(this.getPlayerElement(), {
                    id: this.id,
                    width: '100%'
                });
                player.on('play', this._onPlay);
                player.on('pause', this._onPause);
                player.on('ended', this._onPause);
                this.emitInit();
                return player;
            });
        }

        return this._playerPromise;
    }

    set player(newPlayerPromise) {
        this._playerPromise = newPlayerPromise;
    }

    _onPlay = () => {
        if (!this.isStarted) {
            this.emitStart();
        }
        this.emitPlay();
    };

    _onPause = () => {
        this.emitPause();
    };

    /**
     * @param {Function} action - a function that receives player and returns promise that is fulfilled once the action is done
     * @return Promise<Player>
     * @private
     */
    _do(action) {
        const newPlayerPromise = this.player.then(player => {
            return action(player).then(() => {
                return player;
            }, (error) => {
                console.error(error);
                return player;
            });
        });

        this.player = newPlayerPromise;
        return newPlayerPromise;
    }

    async handlePlay() {
        return this._do((player) => player.play());
    }

    async handlePause() {
        return this._do((player) => player.pause());
    }

    async getThumbnailUrl() {
        return import('jsonp').then((module) => {
            const jsonp = module.default;
            return new Promise((resolve, reject) => {
                jsonp(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${this.id}`, null, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        const thumbnailUrl = data.thumbnail_url;
                        if (thumbnailUrl) {
                            resolve(thumbnailUrl);
                        } else {
                            return reject('response doesn\'t have thumbnail_url');
                        }
                    }
                });
            });
        });
    }

    getDefaultImageTitle() {
        return 'Vimeo video preview';
    }

    getThumbnailOnLoadFunction()  {return null; }
}
