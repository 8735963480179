import BedroomFilter from './bedroom-filter';
import Filter from './filter';
import FilterOption from './filter-option';
import { PROPERTY_ICONS } from '../constants';

export default class PropertyTypeFilterOption extends FilterOption {
    constructor({
        propertyType,
        label = '',
        icon = 'apartment',
        zeroPriceText,
        hideCalculations = false,
        replacementDescription,
        bedroomFilter,
    }) {
        super({ label });

        this.propertyType = propertyType;
        this.zeroPriceText = zeroPriceText;
        this.hideCalculations = hideCalculations;
        this.replacementDescription = replacementDescription;
        this.bedroomFilter = this.setBedroomFilter(bedroomFilter);
        this.value = propertyType != null ? propertyType : '';
        this.svgIcon = this.setSvgIcon(icon);
    }

    setBedroomFilter = (settings) => {
        if (Filter.isFilterValid(settings)) {
            return new BedroomFilter(settings);
        }

        return null;
    };

    setSvgIcon = (icon) => {
        switch (icon) {
            case 'Apartment':
                return PROPERTY_ICONS.apartment;
            case 'Cottage':
                return PROPERTY_ICONS.cottage;
            case 'Bungalow':
                return PROPERTY_ICONS.bungalow;
            default:
                return PROPERTY_ICONS.apartment;
        }
    };
}
