import React from 'react';
import PropTypes from 'prop-types';

export default function BudgetText({ text }) {
    return <div className="budget-text" dangerouslySetInnerHTML={{ __html: text }}></div>;
}

BudgetText.propTypes = {
    text: PropTypes.string,
};
