import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../checkbox/js/checkbox.jsx';
import CheckboxPopupDescription from './checkbox-popup-description.jsx';

export default class DropdownChecklist extends React.Component {
    constructor(props) {
        super(props);

        this.triggerRef = React.createRef();
    }

    onChange = (changedCheckboxValue, isChecked) => {
        const currentValue = this.props.value;
        let newValue = null;
        if (isChecked) {
            newValue = [...currentValue, changedCheckboxValue];
        } else {
            newValue = currentValue.filter(value => value !== changedCheckboxValue);
        }
        this.props.onChange(newValue);
    };

    onSubmit = e => {
        e.preventDefault();
        this.props.onApply();
    };

    onKeyDown = e => {
        if (e.which === 27) {
            this.props.onClose();
            if (this.props.isActive) {
                this.triggerRef.current.focus();
            }
        }
    };

    render() {
        const {
            label,
            icon,
            options,
            value,
            isActive,
            onClick,
            onClear,
            isClearDisabled,
            afterPopupDescriptionOpen
        } = this.props;
        return (
            <div
                onKeyDown={this.onKeyDown}
                className={`search-desktop-filters__control ${isActive ? 'is-active' : ''}`}
            >
                <button
                    ref={this.triggerRef}
                    className="search-desktop-filters__control-main"
                    aria-label={`filter ${label}`}
                    aria-haspopup="listbox"
                    aria-expanded={isActive}
                    onClick={onClick}
                    data-dc-gtm-selector="ExplorePropertiesDropdownTrigger"
                >
                    {icon ? (
                        <svg
                            className="icon search-desktop-filters__control-icon"
                            width="22"
                            height="22"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                        >
                            <use xlinkHref={`#icon-${icon}`} />
                        </svg>
                    ) : null}
                    <div className="search-desktop-filters__control-label">{label}</div>
                    <svg
                        width="13"
                        height="8"
                        className="icon search-desktop-filters__control-arrow"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </button>
                <div className="search-desktop-filters__control-dropdown">
                    <form onSubmit={this.onSubmit}>
                        <ul className="search-desktop-filters__control-checklist" role="listbox">
                            {options.map((option, index) => {
                                const isChecked = value.includes(option.value);
                                return (
                                    <li
                                        className="search-desktop-filters__control-checklist-item"
                                        role="option"
                                        aria-selected={isChecked}
                                        key={index}
                                    >
                                        <Checkbox
                                            isDisabled={option.isDisabled}
                                            isChecked={isChecked}
                                            text={option.text}
                                            gtmSelector="ExplorePropertiesDropdownCheckbox"
                                            onChange={e =>
                                                this.onChange(option.value, e.target.checked)
                                            }
                                        />
                                        <CheckboxPopupDescription
                                            text={option.popupText}
                                            url={option.popupUrl}
                                            afterOpen={afterPopupDescriptionOpen}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                        <button type="submit" className="btn search-filters-apply-button">
                            Apply
                        </button>
                        <button
                            onClick={onClear}
                            disabled={isClearDisabled}
                            type="button"
                            className="btn search-filters-clear-button"
                        >
                            Clear selection
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

DropdownChecklist.defaultProps = {
    value: []
};

DropdownChecklist.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    isActive: PropTypes.bool.isRequired,
    isClearDisabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    afterPopupDescriptionOpen: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired
};
