import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class CarouselComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        // elements
        this.carousel = this.element;
        this.buttonNext = this.refs.buttonNext;
        this.buttonPrevious = this.refs.buttonPrevious;
        this.slider = this.refs.slider;
        this.slides = this.slider.querySelectorAll('[data-carousel-slide]');
        this.pagination;

        // static
        this.dragThreshold = 75;

        // variables
        this.active = false;
        this.autoplay = this.carousel.dataset.carouselAutoplay === 'true';
        this.autoplaySpeed = this.carousel.dataset.carouselAutoplaySpeed ? this.carousel.dataset.carouselAutoplaySpeed : 7000;
        this.draggable = this.carousel.dataset.carouselDraggable === 'true';
        this.loop = this.carousel.dataset.carouselLoop === 'true';
        this.showPagination = this.carousel.dataset.carouselPagination === 'true';
        this.sliderOffset = 0;

        // functions
        this.jumpToPage = this.jumpToPage.bind(this);
        this.moveNext = this.moveNext.bind(this);
        this.movePrevious = this.movePrevious.bind(this);
        this.setCarousel = this.setCarousel.bind(this);
        this.touchStart = this.touchStart.bind(this);
        this.touchMove = this.touchMove.bind(this);
        this.touchEnd = this.touchEnd.bind(this);
    }

    static getNamespace() {
        return 'carousel';
    }

    static getRequiredRefs() {
        return ['buttonNext', 'buttonPrevious', 'slider'];
    }

    onInit() {
        if (this.slides.length > 0) {
            // pagination
            if (this.showPagination) {
                this.pagination = document.createElement('div');
                this.pagination.classList.add('carousel__pagination');
                this.carousel.appendChild(this.pagination);

                this.pagination.addEventListener('click', this.jumpToPage);
            }

            this.setCarousel();

            // base events
            window.addEventListener('resize', this.setCarousel);
            this.buttonNext.addEventListener('click', () => this.moveNext(this.loop, true));
            this.buttonPrevious.addEventListener('click', () => this.movePrevious(this.loop));

            // touch events
            this.slider.addEventListener('touchstart', this.touchStart);
            this.slider.addEventListener('touchmove', this.touchMove);
            this.slider.addEventListener('touchend', this.touchEnd);
            this.slider.addEventListener('touchcancel', this.touchEnd);

            if (this.draggable) {
                this.slider.addEventListener('mousedown', this.touchStart);
                this.slider.addEventListener('mousemove', this.touchMove);
                this.slider.addEventListener('mouseup', this.touchEnd);
                this.slider.addEventListener('mouseleave', this.touchEnd);

                this.carousel.classList.add('carousel--draggable');
            }

            // autoplay
            if (this.autoplay) {
                this.interval = setInterval(() => this.moveNext(true, false), this.autoplaySpeed);
            }
        }
    }

    setCarousel() {
        // calculate current slides per page
        let currentSlidesPerPage = 0;
        let initialSlideOffset = 0;

        this.slides.forEach((slide, slideIndex) => {
            // get initial slide offset in case slides are centred
            if (!slideIndex) {
                initialSlideOffset = slide.offsetLeft;
            }

            // check right of slide is within the page view (use right in case we're peeking)
            if (((slide.offsetLeft + Math.abs(initialSlideOffset)) + slide.offsetWidth) - this.slider.clientWidth <= 1) {
                currentSlidesPerPage++;
            }
        });

        // if slides per page changed
        if (currentSlidesPerPage !== this.slidesPerPage) {
            // set slides
            this.pageCount = Math.ceil(this.slides.length / currentSlidesPerPage);
            this.slideIndex = 0;
            this.slidesPerPage = currentSlidesPerPage;
            this.active = this.pageCount > 1;

            // set pagination
            if (this.pagination) {
                // clear pagination buttons
                this.pagination.innerHTML = '';

                // create and append a pagination button for each page
                for (let paginationIndex = 0; paginationIndex < this.pageCount; paginationIndex++) {
                    const paginationButton = document.createElement('button');
                    paginationButton.classList.add('carousel__pagination__button');
                    paginationButton.setAttribute('data-carousel-jump-to-page', paginationIndex);
                    this.pagination.append(paginationButton);
                }
            }

            // set carousel active
            if (this.active) {
                this.carousel.classList.add('carousel--active');
            } else {
                this.carousel.classList.remove('carousel--active');
            }
        }

        this.setSliderPosition();
    }

    setSliderPosition() {
        // get current slide offset
        this.sliderOffset = -this.slides[this.slideIndex].offsetLeft;

        // if offset beyond the end of the slider, offset to max scroll
        if (Math.abs(this.sliderOffset) > (this.slider.scrollWidth - this.slider.clientWidth)) {
            this.sliderOffset = -Math.abs(this.slider.scrollWidth - this.slider.clientWidth);
        }

        // move slider to slide index position
        this.slider.style.transform = `translateX(${this.sliderOffset}px)`;

        // enable/disable buttons if not looping and at the start or end of the slides
        if (!this.loop) {
            if (this.slideIndex === 0) {
                this.buttonPrevious.setAttribute('disabled', 'true');
            } else {
                this.buttonPrevious.removeAttribute('disabled');
            }

            if (this.slideIndex + this.slidesPerPage >= this.slides.length) {
                this.buttonNext.setAttribute('disabled', 'true');
            } else {
                this.buttonNext.removeAttribute('disabled');
            }
        }

        // add next slide class for peeking style
        this.slides.forEach((slide, index) => {
            if ((index < this.slideIndex && index < this.slides.length - this.slidesPerPage) || index >= this.slideIndex + this.slidesPerPage) {
                slide.classList.add('carousel__slide--out-of-view');
            } else {
                slide.classList.remove('carousel__slide--out-of-view');
            }
        });

        // update pagination
        if (this.pagination) {
            // get page index
            const pageIndex = Math.ceil(this.slideIndex / this.slidesPerPage);

            // loop pagination buttons
            this.pagination.childNodes.forEach((paginationButton, paginationIndex) => {
                // if current page
                if (pageIndex === paginationIndex) {
                    // add active class
                    paginationButton.classList.add('carousel__pagination__button--active');
                } else {
                    // remove active class
                    paginationButton.classList.remove('carousel__pagination__button--active');
                }
            })
        }
    }

    moveNext(loop, pause) {
        // stop the autoplay
        if (pause) {
            clearInterval(this.interval);
        }

        // if active, move to next slide or loop to start if looping
        if (this.active) {
            if (this.slideIndex + this.slidesPerPage < this.slides.length) {
                this.slideIndex = this.slideIndex + this.slidesPerPage;
            } else if (loop) {
                this.slideIndex = 0;
            }

            this.setSliderPosition();
        }
    }

    movePrevious(loop) {
        // stop the autoplay
        clearInterval(this.interval);

        // move to previous slide loop tp end if looping
        if (this.slideIndex - this.slidesPerPage >= 0) {
            this.slideIndex = this.slideIndex - this.slidesPerPage;
        } else if (loop) {
            this.slideIndex = (this.pageCount - 1) * this.slidesPerPage;
        }

        this.setSliderPosition();
    }

    jumpToPage(event) {
        clearInterval(this.interval);

        if (event.target.classList.contains('carousel__pagination__button') && !event.target.classList.contains('carousel__pagination__button--active')) {
            this.slideIndex = event.target.dataset.carouselJumpToPage * this.slidesPerPage;
        }

        this.setSliderPosition();
    }

    touchStart(event) {
        const x = event.clientX ? event.clientX : event.touches && event.touches[0] ? event.touches[0].clientX : null;
        const y = event.clientY ? event.clientY : event.touches && event.touches[0] ? event.touches[0].clientY : null;

        // if active, reset touch and get the touch start position
        if (this.active && x !== null && y !== null) {
            this.lockHorizontal = false;
            this.lockVertical = false;
            this.touchActive = true;
            this.touchDistanceX = null;
            this.touchDistanceY = null;
            this.touchStartX = x;
            this.touchStartY = y;

            this.slider.classList.add('carousel__slider--swipe');
        }
    }

    touchMove(event) {
        const x = event.clientX ? event.clientX : event.touches && event.touches[0] ? event.touches[0].clientX : null;
        const y = event.clientY ? event.clientY : event.touches && event.touches[0] ? event.touches[0].clientY : null;

        // if active, get the drag distance and move the slider
        if (this.active && this.touchActive && x !== null && y !== null) {
            this.touchDistanceX = x - this.touchStartX;
            this.touchDistanceY = y - this.touchStartY;

            // if horizontal swipe
            if (!this.lockHorizontal && Math.abs(this.touchDistanceX) > Math.abs(this.touchDistanceY)) {
                // set vertical lock and move slider to touch position
                this.lockVertical = true;
                this.slider.style.transform = `translateX(${this.sliderOffset + this.touchDistanceX}px)`;
            } if (!this.lockVertical && Math.abs(this.touchDistanceX) < Math.abs(this.touchDistanceY)) {
                // set horizontal lock
                this.lockHorizontal = true;
            }

            // if swiping, prevent page scroll
            if (this.lockVertical) {
                event.preventDefault();
            }

            this.slider.classList.add('carousel__slider--swiping');
        }
    }

    touchEnd() {
        // if touch active
        if (this.touchActive) {
            // end the touch event
            this.touchActive = false;
            this.slider.classList.remove('carousel__slider--swipe');
            this.slider.classList.remove('carousel__slider--swiping');

            // if active, move to next/previous slide if the drag is long enough
            if (this.active && !this.lockHorizontal && this.touchDistanceX) {
                if (this.touchDistanceX > this.dragThreshold) {
                    this.movePrevious(false);
                } else if (this.touchDistanceX < -this.dragThreshold) {
                    this.moveNext(false, true);
                } else {
                    this.setSliderPosition();
                }
            }

            // clear the locks
            this.lockVertical = false;
            this.lockHorizontal = false;
        }
    }
}
