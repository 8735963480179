import React from 'react';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import BudgetList from '../components/budget-list.jsx';
import { PERIOD_SETTINGS, PERIOD_TITLE } from '../constants';

export default function BudgetPeriod() {
    const [state, dispatch] = useBudgetCalculatorStore();
    const togglePeriod = (value) => {
        dispatch({
            type: 'TOGGLE_PRICE_MODE',
            priceMode: value,
        });
    };

    return (
        <BudgetList
            radios={PERIOD_SETTINGS.map((btn) => {
                return {
                    ...btn,
                    isVisible: state.periodButtonsDisplayed[btn.visibilityField] === undefined ? true : state.periodButtonsDisplayed[btn.visibilityField],
                };
            })}
            title={PERIOD_TITLE}
            changeCallback={togglePeriod}
            activeValue={state.priceMode}
        />
    );
}
