import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import debounce from 'lodash/debounce';
import { withContext } from '../context';
import Scroller from '../../../../general/js/scroller';
import DevelopmentsSearchItemPopup from '../components/developments-search-item-popup';
import DevelopmentSearchCard from '../components/development-search-card';
import CirclesPreloader from '../../../circles-preloader/js/circles-preloader';
import { devSearchTypeItem } from '../dev-search-types.js';

const STEP = 10;

class DevelopmentsSearchList extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getNewState({
            position: 0,
            items: [],
            hasMore: true,
            currentTitle: null,
            highlightedId: null,
        });
    }

    getMoreItems = () => {
        this.setState(this.getNewState);
    };

    onMarkerStateChange = (isActive, marker) => {
        const { tabsMode } = this.props;
        let currentState = this.state;
        let currentItem = this.getCurrentItem(currentState, marker);

        const scrollCallback = () => {
            if (!tabsMode && isActive && currentItem) {
                new Scroller(currentItem.scrollingRef.current, {
                    duration: 400,
                }).shortestScrollTo();
            }
        };

        if (currentItem) {
            this.setState(this.getHighlightedState(marker), scrollCallback);
            return;
        }

        while (!currentItem && currentState.hasMore) {
            currentState = this.getNewState(currentState);
            currentItem = this.getCurrentItem(currentState, marker);
        }

        this.setState({ ...currentState, ...this.getHighlightedState(marker) }, scrollCallback);
    };

    getHighlightedState = (marker) => ({
        highlightedId: marker.isActive ? marker.id : null,
    });

    getCurrentItem = (currentState, marker) =>
        currentState.items.find((item) => item.development.id === marker.id);

    getNewState = (prevState) => {
        const { items } = this.props;

        const totalLength = items.length;
        const currentPosition = prevState.position;
        const remainingLength = totalLength - currentPosition;

        let newPosition = currentPosition;
        let hasMore = false;
        let currentTitle = prevState.currentTitle;
        if (remainingLength >= STEP) {
            newPosition = currentPosition + STEP;
            hasMore = true;
        } else if (remainingLength > 0) {
            newPosition = currentPosition + remainingLength;
        }

        const newItems = items.slice(currentPosition, newPosition).reduce((acc, elem) => {
            if (elem.groupTitle !== currentTitle) {
                acc.push({
                    scrollingRef: React.createRef(),
                    isTitle: true,
                    titleText: elem.groupTitle,
                    development: {},
                });
                currentTitle = elem.groupTitle;
            }

            elem.scrollingRef = React.createRef();
            elem.isTitle = false;
            acc.push(elem);
            return acc;
        }, []);
        const displayedItems = prevState.items.concat(newItems);

        return {
            position: newPosition,
            items: displayedItems,
            hasMore,
            currentTitle,
        };
    };

    render() {
        const { markers } = this.props;

        return (
            <>
                <InfiniteScroll
                    next={this.getMoreItems}
                    hasMore={this.state.hasMore}
                    loader={
                        <div className="development-search-overlay-preloader__inner">
                            <CirclesPreloader isVisible={true} caption={'Loading Developments'} />
                        </div>
                    }
                    dataLength={this.state.items.length}
                    style={{ overflow: 'initial' }}
                >
                    {this.state.items.map((item, index) => (
                        <div
                            className="developments-search__item"
                            key={item.isTitle ? index : item.development.id}
                            ref={item.scrollingRef}
                        >
                            {item.isTitle ? null : (
                                <DevelopmentSearchCard
                                    item={item}
                                    isHighlighted={item.development.id === this.state.highlightedId}
                                />
                            )}
                        </div>
                    ))}
                </InfiniteScroll>
                {this.props.items.map((item) => (
                    <DevelopmentsSearchItemPopup
                        key={item.development.id}
                        item={item}
                        marker={markers[item.development.id]}
                        onMarkerStateChange={debounce(this.onMarkerStateChange, 200)}
                    />
                ))}
            </>
        );
    }
}

DevelopmentsSearchList.defaultProps = {
    markers: {},
};

DevelopmentsSearchList.propTypes = {
    markers: PropTypes.object,
    items: PropTypes.arrayOf(devSearchTypeItem),
    tabsMode: PropTypes.bool.isRequired,
    onCardScroll: PropTypes.func,
};

export default withContext(DevelopmentsSearchList, {
    tabsMode: 'tabsMode',
});
