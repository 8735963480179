import React from 'react';
import PropTypes from 'prop-types';

const ArticleCard = (props) => {
    const { item, category } = props;

    return (
        <div className='post-preview'>
            <div title={item.title} className="post-preview__thumbnail">
                <div className="aspectratio" data-ar="16:9">
                    {item.thumbnailImageSrcSet &&
                        <img
                            className="lazyload post-preview__image"
                            data-sizes="auto" alt={item.thumbnailImageAlt}
                            data-srcset={item.thumbnailImageSrcSet}
							src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        />
                    }
                </div>
            </div>
            
               <div className='post-preview__content'> {category && <div className='post-preview__tag'>{category}</div>}</div>
                 <p className="post-preview__title">
                 <a className='post-preview__content' href={item.url} title={item.title}>{item.title}</a>
                </p>
                <p className="post-preview__description post-preview__content">{item.description}</p>
            
        </div>
    );
};

ArticleCard.propTypes = {
    category: PropTypes.string,
    item: PropTypes.shape({
        articleEndDate: PropTypes.number,
        articleLinkText: PropTypes.string,
        articlePostedDate: PropTypes.number,
        author: PropTypes.string,
        description: PropTypes.string,
        isFeaturedArticle: PropTypes.bool,
        isRecommendedReads: PropTypes.bool,
        thumbnailImageSrcSet: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
    })
};

export default ArticleCard;
