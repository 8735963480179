import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class SortingRow extends React.PureComponent {
    isSortActive = (headIndex, sortIndex) => {
        return headIndex === this.props.activeHead && sortIndex === this.props.activeSortType;
    };

    isHeadActive = (headIndex) => {
        return headIndex === this.props.activeHead;
    };

    getToggledSortIndex = (headIndex) => {
        if (!this.isHeadActive(headIndex)) {
            return constants.ASC_SORT_INDEX;
        }

        return this.props.activeSortType === constants.ASC_SORT_INDEX
            ? constants.DESC_SORT_INDEX
            : constants.ASC_SORT_INDEX;
    };

    render() {
        return (
            <thead className="property-table__table-head">
                <tr className="property-table__table-row">
                    {this.props.headings.map((head, headIndex) => {
                        if (this.props.visibleColumns[head.camelCaseId] || head.id === null) {
                            return (
                                <th
                                    className={classNames({
                                        'property-table__table-cell': true,
                                        'property-table__table-cell--heading': true,
                                        'property-table__table-cell--none-tablet': !head.isOnMobile,
                                    })}
                                    scope="col"
                                    key={head.id + headIndex}
                                >
                                    {head.id && (
                                        <a
                                            className="property-table__sort-container-link"
                                            aria-label={`Toggle sorting order by ${head.displayName}`}
                                            onClick={() =>
                                                this.props.onSortClick(
                                                    headIndex,
                                                    this.getToggledSortIndex(headIndex)
                                                )
                                            }
                                        >
                                            {head.displayName}
                                            <ul className="property-table__sort-list">
                                                {this.props.sortTypes.map((type, sortIndex) => (
                                                    <li
                                                        className="property-table__sort-item"
                                                        key={head.id + type.id + sortIndex}
                                                    >
                                                        <div
                                                            className={classNames({
                                                                'property-table__sort-arrow': true,
                                                                [`property-table__sort-arrow--${type.mod}`]: true,
                                                                'property-table__sort-arrow--active': this.isSortActive(
                                                                    headIndex,
                                                                    sortIndex
                                                                ),
                                                            })}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </a>
                                    )}
                                    {!head.id && (
                                        <span className="visually-hidden">{head.displayName}</span>
                                    )}
                                </th>
                            );
                        }
                    })}
                </tr>
            </thead>
        );
    }
}

SortingRow.propTypes = {
    headings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            camelCaseId: PropTypes.string,
            displayName: PropTypes.string,
            isOnMobile: PropTypes.bool,
            visibleByDefault: PropTypes.bool,
        })
    ),
    sortTypes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            mod: PropTypes.string,
        })
    ),
    activeHead: PropTypes.number,
    activeSortType: PropTypes.number,
    onSortClick: PropTypes.func,
    visibleColumns: PropTypes.objectOf(PropTypes.bool),
};

export default withContext(SortingRow, {
    activeHead: 'activeHead',
    activeSortType: 'activeSortType',
    visibleColumns: 'visibleColumns',
});
