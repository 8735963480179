import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import CampaignPropertySearch from './campaign-property-search';

export default class CampaignPropertySearchComponent extends DcBaseComponent {
    static getNamespace() {
        return 'campaign-property-search';
    }

    onInit() {
        ReactDOM.render(
            <CampaignPropertySearch options={this.options} />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
