import React from 'react';
import PropTypes from 'prop-types';
import { FILTER_NAME_RADIUS, FILTER_NAME_OWNERSHIP } from '../constants.js';
import MobileSearchRadius from './mobile-search-radius.jsx';
import MobileSearchOwnership from './mobile-filters-ownership.jsx';
import MobileSearchChecklist from './mobile-filters-checklist.jsx';

export default class MobileFiltersItem extends React.Component {
    render() {
        const { value, config } = this.props;
        return (
            <div className="search-mobile-filters__item">
                <div className="search-mobile-filters__item-label">{config.label}</div>
                <div className="search-mobile-filters__item-content">
                    {config.name === FILTER_NAME_OWNERSHIP && (
                        <MobileSearchOwnership config={config} value={value} />
                    )}

                    {config.name === FILTER_NAME_RADIUS && (
                        <MobileSearchRadius key={value} config={config} value={value} />
                    )}

                    {config.name !== FILTER_NAME_RADIUS &&
                        config.name !== FILTER_NAME_OWNERSHIP && (
                            <MobileSearchChecklist config={config} value={value} />
                        )}
                </div>
            </div>
        );
    }
}

MobileFiltersItem.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
};
