import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import buildMarker from './markers/marker-url-builder';
import device, { DEVICE_TYPE_MOBILE_WIDE } from '../../../general/js/device';

const GoogleMapStatic = (props) => {
    const DEFAULT_LAT = 53.2;
    const DEFAULT_LNG = -1.7;
    const DEFAULT_ZOOM = 12;
    const { locations, mapSettings, map, developmentName, parent } = props;
    const imageRef = useRef(null);
	const imageAlt = `Map of the local area surrounding ${developmentName}`;

    const options = {
        center: `${map.center && map.center.lat || DEFAULT_LAT},${map.center && map.center.lng || DEFAULT_LNG}`,
        zoom: map.zoom || DEFAULT_ZOOM,
        size: ((size = parent.getBoundingClientRect()) => Math.trunc(size.width) + 'x' + Math.trunc(size.width / 2))(),
    };


    useEffect(() => {
        parent.classList.add('amenities__map--is-visible');
    }, []);

    const getImageurl = () => {
        const STATIC_MAPS_API_KEY = window.appConfig.GOOGLE_MAPS_API_KEY;
        const markerLocations = locations.map((location) => buildMarker(location, mapSettings.markerFolderUrl)).join('&');
        const url = 'https://maps.googleapis.com/maps/api/staticmap?' +
          'center=' + options.center +
          '&zoom=' + options.zoom +
          '&size=' + options.size +
          '&key=' + STATIC_MAPS_API_KEY +
          markerLocations + '&style=feature:poi|element:labels|visibility:off';

        return url;
    };

    if ((mapSettings.minimumDeviceSize && !device.isViewportTypeGe(mapSettings.minimumDeviceSize)) ||
        !device.isViewportTypeGt(DEVICE_TYPE_MOBILE_WIDE)) {
        return null;
    }

    return (
        <img
            ref={imageRef}
			alt={imageAlt}
            className='lazyload amenities__map-image'
            data-sizes="auto"
            data-srcset={getImageurl()}
			src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
        >
        </img>
    );
};

GoogleMapStatic.propTypes = {
    parent: PropTypes.node,
    key: PropTypes.string,
    mapSettings: PropTypes.shapeOf({
        markerFolderUrl: PropTypes.string,
        endpoint: PropTypes.string,
        height: PropTypes.number,
        mapAPIType: PropTypes.string,
        mapType: PropTypes.string,
        width: PropTypes.number,
        zoom: PropTypes.number,
        minimumDeviceSize: PropTypes.string,
    }),
    map: PropTypes.shapeOf({
        center: PropTypes.shapeOf({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }),
    }),
    locations: PropTypes.arrayOf({
        id: PropTypes.string,
        type: PropTypes.string,
        lat: PropTypes.number,
        lng: PropTypes.number,
        popup: PropTypes.shapeOf({
            title: PropTypes.string,
            text: PropTypes.string,
        })
    })
};

export default GoogleMapStatic;
