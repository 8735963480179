import dcFactory from './dc/dc-factory';
import trackerService from '@General/js/tracker-service.js'

class App {
    constructor() {
        this.dcFactory = dcFactory;
        this.config = {
            GOOGLE_MAPS_API_KEY: window.appConfig.GOOGLE_MAPS_API_KEY,
            LOQATE_BASE_URL: '/webapi/loqate/',
            EVENT_BOOKING_PERCENTAGE_URL: '/webapi/events/event-booking-percentage',
            EVENTS_LIST_BOOKING_PERCENTAGE_URL: '/webapi/events/events-list-booking-percentage'
        };
        this.state = window.appState || {};
    }

    init() {
        this.dcFactory.init();
        trackerService.init();

        /* Attach Talkative event */
        window.talkativeCustomConfig = {
            events: {
                enterInteraction() { trackerService.track('ButtonClick_CallMeBack'); }
            }
        };
    }

    getConfig(property, defaultValue = undefined) {
        return property in this.config ? this.config[property] : defaultValue;
    }

    getRequiredConfig(property) {
        if (!(property in this.config)) {
            throw new Error(`cannot find a property «${property}» in config`);
        }
        return this.config[property];
    }
}

const instance = new App();
export default instance;
