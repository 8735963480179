import React, { useEffect } from 'react';
import device, { DEVICE_TYPE_MOBILE } from 'general/js/device';
import { useStore } from 'effector-react/compat';
import { useInteractiveSitePlanStore } from './use-interactive-site-plan-store.jsx';
import {
    AREA_TYPE_UNRELEASED,
    AREA_TYPE_SOLD,
    AREA_TYPE_RELEASED,
    AREA_STATUS_SOLD,
} from './constants.js';

export default function PropertyKeys() {
    const [store] = useInteractiveSitePlanStore();
    const state = useStore(store);
    const legendItems = state.legend.items;

    let timeout;
    let isMobile = device.isViewportTypeLe(DEVICE_TYPE_MOBILE);

    useEffect(() => {
        window.addEventListener('resize', () => {
            timeout = setTimeout(() => {
                clearTimeout(timeout);
                isMobile = device.isViewportTypeLe(DEVICE_TYPE_MOBILE);
            }, 700);
        });
    }, []);

    const getKeysForFloor = (floorIndex) => {
        const allPropertyKeys = state.floors[floorIndex].map.areas.map((area) => {
            return [area.bedroomAmount, area.mapPlotStatus, area.coords];
        });

        return allPropertyKeys;
    };

    const getKeyParamsByBedroomNumber = (bedroomNumber, status) => {
        let zeroBedPropItem;
        let zeroBedPropColor;
        const statusItem = legendItems.filter((item) => item.numberOfBedrooms === bedroomNumber);
        const releasedItem = statusItem.find((item) => item.numberOfBedrooms === bedroomNumber);

        switch (bedroomNumber) {
            case 0:
                if (status.includes(AREA_STATUS_SOLD)) {
                    zeroBedPropItem = statusItem.find((item) => item.plotStatus.includes(AREA_STATUS_SOLD));
                    zeroBedPropColor = AREA_TYPE_SOLD.mainColor;
                } else {
                    zeroBedPropItem = statusItem.find((item) => !item.plotStatus.includes(AREA_STATUS_SOLD));
                    zeroBedPropColor = AREA_TYPE_UNRELEASED.mainColor;
                }
                
                return {
                    keyColor: {
                        background: zeroBedPropColor,
                    },
                    title: zeroBedPropItem.title,
                    mobileTitle: zeroBedPropItem.mobileTitle
                };
            case 1:
                return {
                    keyColor: {
                        background: AREA_TYPE_RELEASED.oneBedColor
                    },
                    title: releasedItem.title,
                    mobileTitle: releasedItem.mobileTitle
                };
            case 2:
                return {
                    keyColor: {
                        background: AREA_TYPE_RELEASED.twoBedsColor
                    },
                    title: releasedItem.title,
                    mobileTitle: releasedItem.mobileTitle
                };
            case 3:
                return {
                    keyColor: {
                        background: AREA_TYPE_RELEASED.threeBedsColor
                    },
                    title: releasedItem.title,
                    mobileTitle: releasedItem.mobileTitle
                };
            default:
                break;
        }
    };

    const getKeyParams = (key) => {
        let keyParams;

        switch (key[0]) {
            case 0:
                keyParams = getKeyParamsByBedroomNumber(0, key[1]);
                break;
            case 1:
                keyParams = getKeyParamsByBedroomNumber(1);
                break;
            case 2:
                keyParams = getKeyParamsByBedroomNumber(2);
                break;
            case 3:
                keyParams = getKeyParamsByBedroomNumber(3);
                break;
            default:
                break;
        }

        return keyParams;
    };

    const getUniqueKeys = (floorIndex) => {
        const floorKeys = getKeysForFloor(floorIndex);
        const uniqueKeys = [];

        floorKeys.forEach((floorKey) => {
            if (uniqueKeys.length > 0) {
                const isUnique = !uniqueKeys.some((k) =>
                    floorKey[0] === k[0] && floorKey[1] === k[1])
                    && floorKey[2].length > 0;

                if (isUnique) {
                    uniqueKeys.push(floorKey);
                }
            } else {
                uniqueKeys.push(floorKey);
            }
        });

        return uniqueKeys;
    };

    const getSortedUniqueKeys = (floorIndex) => {
        const uniqueKeys = getUniqueKeys(floorIndex);
        let releasedKeys = [];
        const unreleasedKeys = [];

        uniqueKeys.forEach((key) => {
            if (key[0] === 0) {
                unreleasedKeys.push(key);
            } else {
                releasedKeys.push(key);
            }
        });

        releasedKeys = releasedKeys.sort((a, b) => a[0] - b[0]);

        return [...releasedKeys, ...unreleasedKeys];
    };

    const getBedroomKey = (uniqueKeys) => (
        <>
            {
                uniqueKeys.map((key, index) => {
                    return (
                        <span key={index + 1} className='interactive-site-plan__property-key-wrapper'>
                            <div
                                className='interactive-site-plan__property-key-indicator'
                                style={getKeyParams(key).keyColor}
                            ></div>
                            {
                                <span
                                    className={`interactive-site-plan__property-key-text interactive-site-plan__property-key--${key[1]}-bedroom`}
                                    style={{}}>
                                    {isMobile ? getKeyParams(key).mobileTitle : getKeyParams(key).title}
                                </span>
                            }
                        </span>
                    );
                })
            }
        </>
    );

    const renderKeys = () => {
        const renderingKeys = state.floors.map((floor, index) => {
            if (floor.name === state.floor.name) {
                return (
                    <React.Fragment key={index + 1}>
                        {getBedroomKey(getSortedUniqueKeys(index))}
                    </React.Fragment>
                );
            }

            return null;
        });

        return renderingKeys;
    };

    const keys = renderKeys();

    return (
        <>
            <div className="interactive-site-plan__property-keys">
                {keys}
            </div>
        </>
    );
}
