export default class AbstractManager {
    constructor(element, refs, options = {}) {
        this.element = element;
        this.refs = refs;
        this.options = options;
    }

    beforeNext(next) {
        if (!this.options.beforeNext) return;

        if (typeof this.options.beforeNext === 'function') {
            this.options.beforeNext(next);
        }
    }
}
