import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import nanoId from 'nano-id';
import { withContext } from '../context.jsx';

class PropertyDisplay extends React.PureComponent {
    render() {
        return (
           
            <tbody className="property-table__table-body">
                {this.props.currentProperties.map((property) => {
                    const priceField = this.props.activeTab.id.toLowerCase();
                    const switchPriceValue = property.prices[priceField]
                        ? property.prices[priceField].priceValue
                        : '';

                    const pricesIndex = Object.keys(property.prices)[0];
                    const propertyPrice = (property && property.prices[pricesIndex])
                  
                        && property.prices[pricesIndex].priceValue;
                        if((property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !this.props.tableSettings.showOpsoPercentagePrices) || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !property.showOpsoPricingOnWebsite)) {

                           this.props.tableSettings.sharedOwnershipLabel;
                        }
                    return (
                        <tr className="property-table__table-row" key={nanoId()}>
                            <th className="property-table__table-cell" role="cell" scope="row">
                                {property.number}
                            </th>
                            <td className="property-table__table-cell">{property.bedrooms}</td>
                            {((property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !this.props.tableSettings.showOpsoPercentagePrices) || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !property.showOpsoPricingOnWebsite))  ?
                            <td
                                className="property-table__table-cell">{this.props.tableSettings.sharedOwnershipLabel}</td>
                                
                            :<td
                            className="property-table__table-cell"
                            dangerouslySetInnerHTML={{
                                __html: this.props.tabs.length === 1
                                    ? propertyPrice
                                    : switchPriceValue
                            }}
                        ></td>
                } 
                            {this.props.visibleColumns.floor && (
                                <td className="property-table__table-cell property-table__table-cell--none-tablet">
                                    {property.floor}
                                </td>
                            )}
                            {this.props.visibleColumns.status && (
                                <td
                                    className={classNames({
                                        'property-table__table-cell': true,
                                        'property-table__table-cell--none-tablet': true,
                                        'property-table__table-cell--color-orange':
                                            property.status === 'Under Offer',
                                        'property-table__table-cell--color-red':
                                            property.status === 'Reserved',
                                    })}
                                >
                                    {property.status}
                                </td>
                            )}
                            {this.props.visibleColumns.area && (
                                <td className="property-table__table-cell property-table__table-cell--none-tablet">
                                    {property.area}
                                </td>
                            )}
                            {this.props.visibleColumns.propertyType && (
                                <td className="property-table__table-cell property-table__table-cell--none-tablet">
                                    {property.propertyType}
                                </td>
                            )}
                            <td className="property-table__table-cell">
                                <a
                                    className="property-table__table-cell-btn link"
                                    href={property.propertyPageHref}
                                >
                                    <span className="property-table__table-cell-btn-text">
                                        {this.props.isOnMobileViewport ? 'View' : 'View property'}
                                    </span>
                                    <svg className="property-table__table-cell-btn-icon icon">
                                        <use xlinkHref="#icon-arrow-right-thin"></use>
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        );
    }
}

PropertyDisplay.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    })),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string,
    }),
    currentProperties: PropTypes.arrayOf(
        PropTypes.shape({
            area: PropTypes.string,
            bedrooms: PropTypes.number,
            floor: PropTypes.string,
            number: PropTypes.string,
            prices: PropTypes.object,
            propertyPageHref: PropTypes.string,
            recordId: PropTypes.string,
            status: PropTypes.string,
            propertyType: PropTypes.string,
            opsoAvailable: PropTypes.bool
        })
    ),
    isOnMobileViewport: PropTypes.bool,
    visibleColumns: PropTypes.objectOf(PropTypes.bool),
    tableSettings: PropTypes.objectOf(PropTypes.string),

};

export default withContext(PropertyDisplay, {
    activeTab: 'activeTab',
    currentProperties: 'currentProperties',
    isOnMobileViewport: 'isOnMobileViewport',
    visibleColumns: 'visibleColumns',
    tabs: 'tabs',
    tableSettings: 'tableSettings',

});
