import constants from 'general/js/constants';

export default class FormValidityExpander {
    constructor(element, expand, isExpandedGetter = null) {
        this.element = element;
        this.expand = expand;
        this.isExpandedGetter = isExpandedGetter;
        this.hasNoState = isExpandedGetter === null;

        this.element.setAttribute(constants.ATTRIBUTE_VALIDATE_ALWAYS, '');
        this.element.addEventListener(constants.EVENT_FIELD_VALIDATION_FAILED, this._onInnerFieldValidationFailed);
    }

    destroy() {
        this.element.removeEventListener(constants.EVENT_FIELD_VALIDATION_FAILED, this._onInnerFieldValidationFailed);
    }

    _onInnerFieldValidationFailed = () => {
        if (this.hasNoState || !this.isExpandedGetter()) {
            this.expand();
        }
    }
}
