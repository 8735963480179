import React from 'react';
import PropTypes from 'prop-types';
import SelectedFilterValue from './selected-filter-value';

class SelectedFilterChecklist extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    onValueRemove = (removedValue) => {
        const { filterValue, filterConfig } = this.props;
        const newValue = filterValue.filter(value => value !== removedValue);
        this.props.onFiltersChange({ [filterConfig.name]: newValue });
    };

    render() {
        const { filterConfig, filterValue } = this.props;
        const selectedOptions = filterConfig.options.filter(option => filterValue.includes(option.value));

        return (
            <React.Fragment>
                {selectedOptions.map(option => (
                    <SelectedFilterValue
                        filterLabel={filterConfig.label}
                        key={option.value}
                        selectedText={option.selectedText}
                        text={option.text}
                        value={option.value}
                        onClick={this.onValueRemove}/>
                ))}
            </React.Fragment>
        );
    }
}

SelectedFilterChecklist.propTypes = {
    filterValue: PropTypes.array.isRequired,
    filterConfig: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
};

export default SelectedFilterChecklist;
