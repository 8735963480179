import React from 'react';
import PropTypes from 'prop-types';
import analyticsService from '@General/js/analytics-service.js';

function MoreArticlesCard(props) {
    const { article } = props;

    return (
        <div className="more-articles-card">
            <div className="more-articles-card__img-wrapper">
                <img
                    alt={article.thumbnailImageAlt}
                    className="more-articles-card__img"
                    loading="lazy"
                    src={article.thumbnailImageSrc}
                    srcSet={article.thumbnailImageSrcSet}
                />
            </div>
            <div className="more-articles-card__content">
                <a
                    href={article.url}
                    onClick={() => {analyticsService._send('moreArticles', {title: article.title})}}>
                    <span>{article.category}</span>
                </a>
                <h4>{article.title}</h4>
                <p>{article.description}</p>
            </div>
        </div>
    );
}

MoreArticlesCard.propTypes = {
    article: PropTypes.array,
};

export default MoreArticlesCard;
