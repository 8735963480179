
let target = document.querySelector('#main-content');
let multistepForm = document.querySelector('.multistep-form');
    let observer = new MutationObserver(function (mutations) { 
        mutations.forEach(function (mutation) {
    let nextButton = document.querySelector('.multistep-form__next');
        if (typeof (nextButton) != 'undefined' && nextButton != null) {
    
            nextButton.addEventListener("click", function () {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        }
    });
});
 
let config = {
    childList: true,
    subtree: true,
 }
if (typeof (multistepForm) != 'undefined' && multistepForm != null) {
    observer.observe(target, config);

}

