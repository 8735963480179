import DcBaseComponent from 'general/js/dc/dc-base-component';
import Scroller from "general/js/scroller";
import formsConstants from '../../form/js/constants';

export default class FormWizardStep extends DcBaseComponent {
    constructor(element, { index, onContinue, onBack }) {
        super(element);

        this._index = index;
        this._group = `group-${index}`;
        this._onContinue = onContinue;
        this._onBack = onBack;
    }

    static getNamespace() {
        return 'form-wizard-step';
    }

    static getRequiredRefs() {
        return ['content'];
    }

    onInit() {
        if (this.refs.continue) {
            this.refs.continue.addEventListener('click', this._onContinueClick);
        }

        if (this.refs.back) {
            this.refs.back.addEventListener('click', this._onBackClick);
        }

        const inputs = [...this.element.querySelectorAll(formsConstants.PARSLEY_INCLUDE_SELECTOR)];
        inputs.forEach((inputNode) => {
            inputNode.setAttribute('data-parsley-group', this.getGroup());
        });

        this.hide();
    }

    _onBackClick = () => {
        this._onBack();
    };

    _onContinueClick = () => {
        this._onContinue();
    };

    getGroup() {
        return this._group;
    }

    getIndex() {
        return this._index;
    }

    show() {
        this.element.classList.add('is-active');
        this.element.classList.remove('is-inactive');
        (new Scroller(this.element, { duration: 300 })).scrollToTop(true);
    }

    hide() {
        this.element.classList.remove('is-active');
        this.element.classList.add('is-inactive');
    }

    afterStepChange(newStepIndex) {
        if (this._index < newStepIndex) {
            this.element.classList.add('is-valid');
        } else {
            this.element.classList.remove('is-valid');
        }
    }

    /**
     * @return {?Promise}
     */
    isValid() {
        return null;
    }
}
