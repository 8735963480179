import DcBaseComponent from 'general/js/dc/dc-base-component';
import eventsService, { EVENT_BOOKING_ERROR_VALUE } from 'general/js/events-service';
import utils from 'general/js/utils';

const EVENT_BOOKING_RATE_THRESHOLD_TO_SHOW = 50;

export default class EventStatusComponent extends DcBaseComponent {
    static getNamespace() {
        return 'event-status';
    }

    onInit() {
        this.format = this.getChildAttribute(this.element, 'format');
        if (!this.format) {
            throw new Error('Format should be defined');
        }
        this.companyId = this.getChildAttribute(this.element, 'company-id');
        if (!this.companyId) {
            throw new Error('company id should be defined');
        }

        this.eventsElements = this.refs.events;
        if (this.eventsElements) {
            this._handleEventsList(this.eventsElements);
        } else {
            this._handleSingleEvent();
        }
    }

    /**
     * @param {HTMLElement} element
     * @return {?string}
     * @private
     */
    _getElementEventId(element) {
        return this.getChildAttribute(element, 'event-id');
    }

    _handleEventsList(elements) {
        const ids = elements.map(el => this._getElementEventId(el));
        eventsService.getEventsListBookingPercentage(this.companyId, ids).then(values => {
            values.forEach((value, index) => {
                this._renderValue(elements[index], value);
            });
        });
    }

    _isErrorValue(value) {
        return value === EVENT_BOOKING_ERROR_VALUE;
    }

    _handleSingleEvent() {
        const id = this._getElementEventId(this.element);
        eventsService.getEventBookingPercentage(this.companyId, id).then(value => {
            this._renderValue(this.element, value);
        });
    }

    /**
     * @param {HTMLElement} element
     * @param value
     * @private
     */
    _renderValue(element, value) {
        if (this._isErrorValue(value)) {
            console.error('Couldn\'t get booking information for an event in the list');
        } else if (value >= EVENT_BOOKING_RATE_THRESHOLD_TO_SHOW) {
            element.textContent = utils.stringFormat(this.format, value);
            element.classList.add('is-visible');
        }
    }
}
