import onScriptLoaded from './load-google-maps-script';
import MarkerCreator from './markers/marker-creator';
import constants from "./constants";

const DEFAULT_LAT = 53.2;
const DEFAULT_LNG = -1.7;

const DEFAULT_ZOOM = 12;
// streets-buildings
const MAX_ZOOM = 19;
// continents level
const MIN_ZOOM = 1;

const DEFAULT_MARKER_TYPE = constants.MARKER_TYPE_DEVELOPMENT;

const BOUNDS_PADDING = {
    bottom: 0,
    left: 20,
    right: 20,
    top: constants.DEFAULT_POPUP_VERTICAL_OFFSET
};

export default class GoogleMap {
    constructor(element, mapOptions = {}, options = {}) {
        this.element = element;

        this.defaultMapOptions = {
            mapTypeControl: false,
            streetViewControl: false,
        };

        this.mapOptions = {
            center: {
                lat: mapOptions.center && mapOptions.center.lat || DEFAULT_LAT,
                lng: mapOptions.center && mapOptions.center.lng || DEFAULT_LNG
            },
            zoom: mapOptions.zoom || DEFAULT_ZOOM,
            maxZoom: MAX_ZOOM,
            minZoom: MIN_ZOOM,
        };

        this.options = {
            eager: false,
            ...options
        };

        this.initPromise = null;
        this.markers = {};
        this.activeMarkerId = null;

        if (this.options.eager) {
            this._initialRender();
        }
    }

    _initialRender() {
        // just call map instance as soon as possible
        const mapPromise = this._getMap();
    }

    async _getMap() {
        if (this.initPromise === null) {
            const loadScriptPromise = new Promise((resolve, reject) => {
                onScriptLoaded(() => {
                    resolve();
                });
            });
            this.initPromise = loadScriptPromise.then(() => this._createMap());
        }

        return this.initPromise;
    }

    _createMap() {
        const map = new google.maps.Map(this.element, { ...this.defaultMapOptions, ...this.mapOptions });
        map.addListener('click', () => {
            this._deactivateCurrentMarker();
        });
        return map;
    }

    async _updateMarkers(locations) {
        const map = await this._getMap();

        const newLocationsByIds = this._getLocationsById(locations);
        const newLocationsIds = Object.keys(newLocationsByIds);

        const locationsIdsToRemove = this._getLocationsIdsToRemove(newLocationsIds);
        if (locationsIdsToRemove.length > 0) {
            this._removeMarkersByIds(locationsIdsToRemove);
        }

        const locationsToAdd = this._getLocationsToAdd(newLocationsByIds);
        if (locationsToAdd.length > 0) {
            await this._addMarkers(locationsToAdd, map);
        }

        if (locationsIdsToRemove.length > 0 || locationsToAdd.length > 0) {
            // something has changed
            if (this.getCurrentMarkersCount() === 0) {
                // return to origin focus and center
                this._applyInitialCenter(map);
            } else {
                // if we have some markers
                this._fitCurrentMarkers(map);
            }

        }

        const activeMarker = this.getMarkerById(this.activeMarkerId);
        if (!activeMarker) {
            this.activeMarkerId = null;
        }
    }

    _getLocationsById(locations) {
        return locations.reduce((result, location) => {
            result[this._getLocationId(location)] = location;
            return result;
        }, {});
    }

    _addMarkers(locations, map) {
        if (locations.length === 0) {
            return;
        }
        const newMarkers = {};
        locations.forEach(location => {
            const id = this._getLocationId(location);
            newMarkers[id] = this._createMarker(id, location, map);
        });

        this.markers = { ...this.markers, ...newMarkers };
    }

    _applyInitialCenter(map) {
        map.setCenter(this.mapOptions.center);
        map.setZoom(this.mapOptions.zoom);
    }

    _fitCurrentMarkers(map) {
        const bounds = new google.maps.LatLngBounds();
        const currentMarkers = Object.values(this.markers);
        currentMarkers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds, BOUNDS_PADDING);
    }

    _removeMarkersByIds(ids) {
        ids.forEach(id => {
            const marker = this.markers[id];
            marker.destroy();
        });
        this.markers = Object.keys(this.markers)
            .filter(id => !ids.includes(id))
            .reduce((result, id) => {
                result[id] = this.markers[id];
                return result;
            }, {})
    }

    _createMarker(
        id,
        location,
        map
    ) {
        return MarkerCreator.create(
            location.type || DEFAULT_MARKER_TYPE,
            id,
            this,
            map,
            this._getLocationPosition(location),
            location.popup
        );
    }

    _getLocationsToAdd(newLocationsByIds) {
        const currentLocationsIds = this._getCurrentLocationsIds();

        return Object.keys(newLocationsByIds).reduce((result, newLocationId) => {
            if (!currentLocationsIds.includes(newLocationId)) {
                result.push(newLocationsByIds[newLocationId]);
            }
            return result;
        }, []);
    }

    _getLocationsIdsToRemove(newLocationsIds) {
        return this._getCurrentLocationsIds().reduce((result, id) => {
            if (!newLocationsIds.includes(id)) {
                result.push(id);
            }
            return result;
        }, []);
    }

    _getCurrentLocationsIds() {
        return Object.keys(this.markers);
    }

    _getLocationPosition(location) {
        return { lat: location.lat, lng: location.lng };
    }

    _getLocationId(location) {
        let id = null;
        if (location.id) {
            id = location.id;
        } else if (location.lat && location.lng) {
            id = `${location.lat},${location.lng}`;
        }
        return id;
    }

    _deactivateCurrentMarker() {
        if (this.activeMarkerId) {
            const activeMarker = this.getMarkerById(this.activeMarkerId);
            activeMarker.handleDeactivate();
            this.activeMarkerId = null;
        }
    }

    onMarkerDeactivate = (id) => {
        if (this.activeMarkerId === id) {
            const marker = this.getMarkerById(id);
            marker.handleDeactivate();
            this.activeMarkerId = null;
        }
    };

    onMarkerActivate = (id) => {
        const newMarker = this.getMarkerById(id);
        if (this.activeMarkerId) {
            const activeMarker = this.getMarkerById(this.activeMarkerId);
            if (this.activeMarkerId !== id) {
                this.activeMarkerId = id;
                activeMarker.handleDeactivate();
                newMarker.handleActivate();
            } else {
                this.activeMarkerId = null;
                activeMarker.handleDeactivate();
            }
        } else {
            this.activeMarkerId = id;
            newMarker.handleActivate();
        }
    };

    getMarkerByLocation(location) {
        return this.markers[this._getLocationId(location)];
    }

    getMarkerById(id) {
        return this.markers[id];
    }

    getMarkersById() {
        return this.markers;
    }

    async updateMarkers(locations) {
        await this._updateMarkers(locations);
    }


    async fitCurrentMarkers() {
        if (this.getCurrentMarkersCount() > 0) {
            const map = await this._getMap();
            this._fitCurrentMarkers(map);
        }
    }

    getCurrentMarkersCount() {
        return Object.keys(this.markers).length;
    }
}
