import Stickyfill from 'stickyfilljs';
import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class StickyColumnComponent extends DcBaseComponent {
    static getNamespace() {
        return 'sticky-column';
    }

    onInit() {
        Stickyfill.add(this.element);
    }
}
