import React from 'react';
import PropTypes from 'prop-types';
import device, { DEVICE_TYPE_MOBILE } from '../../../general/js/device';
import DevelopmentStage from './development-stage';

export default class DevelopmentTimeline extends React.Component {
    constructor(...props) {
        super(...props);

        this.state = {
            lastFinishedIndex: null,
            firstFutureIndex: null,
            isMobile: device.isViewportTypeLe(DEVICE_TYPE_MOBILE),
            isExpanded: true,
            reversedProps: this.props.stages.slice().reverse()
        };

        this.onButtonExpand = this.onButtonExpand.bind(this);
        this.resize = this.resize.bind(this);
        this.setStagesProps = this.setStagesProps.bind(this);
    }

    componentDidMount() {
        let timeout;

        window.addEventListener('resize', () => {
            clearTimeout(timeout);
            timeout = setTimeout(this.resize, 800);
        });

        this.setIndices();
    }

    resize() {
        this.setState(() => {
            return {
                isMobile: device.isViewportTypeLe(DEVICE_TYPE_MOBILE),
            };
        });

        if (device.isViewportTypeGt(DEVICE_TYPE_MOBILE)) {
            this.showAllStages();
        }
    }

    showAllStages = () => {
        const stageElems = document.querySelectorAll('.development-timeline__stage');
        stageElems.forEach((stage) => {
            stage.style.display = 'flex';
        });
    }

    onButtonExpand() {
        this.setState(() => {
            return {
                isExpanded: !this.state.isExpanded
            };
        });
    }

    getLastFinishedIndex = () => {
        const reversedProps = this.state.reversedProps;
        const firstCurrentIndex = reversedProps.findIndex((prop) => prop.isCurrent === true);

        return firstCurrentIndex;
    }

    setIndices = () => {
        const firstCurrentIndex = this.getLastFinishedIndex();
        const lastFinishedIndex = this.props.stages.length - 1 - firstCurrentIndex;
        let firstFutureIndex = lastFinishedIndex + 1;
        if (lastFinishedIndex === this.props.stages.length) {
            firstFutureIndex = 0;
        }

        this.setState({
            lastFinishedIndex,
            firstFutureIndex
        });
    }

    setStagesProps() {
        const reversedProps = this.state.reversedProps.slice();
        const firstCurrentIndex = this.getLastFinishedIndex();

        reversedProps.forEach((prop, index) => {
            if (firstCurrentIndex !== -1 && index >= firstCurrentIndex) {
                prop.isCurrent = true;
            }

            if (prop.value.trim() === '') {
                prop.value = 'TBC';
            }
        });

        return reversedProps.reverse();
    }

    render() {
        const { isMobile, lastFinishedIndex, firstFutureIndex, isExpanded } = this.state;
        const stagesProps = this.setStagesProps();
        const isStagesVisible = stagesProps.length > 0;

        const header = this.props.title && isStagesVisible ? (
            <div className='development-timeline__header'>
                <span className='development-timeline__header-title'>{this.props.title}</span>
            </div>) : null;

        return (
            <>
                {header}
                {isStagesVisible && (
                    <div className='development-timeline__stages-wrapper'
                        style={{
                            paddingTop: header ? '30px' : 0,
                            marginBottom: !isMobile && this.props.showTimelineDates ? '30px' : 0,
                        }}>
                        <div className='development-timeline__stages'>
                            {
                                stagesProps.map(({ value, isCurrent, title }, index) => {
                                    return (
                                        (title && value) &&
                                        <DevelopmentStage
                                            showTimelineDates={this.props.showTimelineDates}
                                            key={index}
                                            isMobile={isMobile}
                                            isFinished={isCurrent}
                                            isNextStageFinished={index === stagesProps.length - 1 ? false : stagesProps[index + 1].isCurrent}
                                            length={stagesProps.length}
                                            index={index}
                                            title={title}
                                            date={value}
                                            lastFinishedIndex={lastFinishedIndex}
                                            firstFutureIndex={firstFutureIndex}
                                            isExpanded={isExpanded}
                                        />
                                    );
                                })
                            }
                        </div>

                        <div className='development-timeline__button-wrapper'>
                            <button
                                className='development-timeline__button'
                                onClick={() => this.onButtonExpand()}>{`See ${isExpanded ? 'more' : 'less'} details`}
                            </button>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

DevelopmentTimeline.propTypes = {
    stages: PropTypes.array.isRequired,
    title: PropTypes.string,
    showTimelineDates: PropTypes.bool.isRequired
};
