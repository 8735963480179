import React from 'react';
import PropTypes from 'prop-types';

const NoResultsCampaignSearch = ({ zeroResultsMessage }) => {
    return (
        <div className='richtext'>
            <div
                className='campaign-search-results__no-results'
                dangerouslySetInnerHTML={{ __html: zeroResultsMessage }} />
        </div>
    );
};

NoResultsCampaignSearch.propTypes = {
    zeroResultsMessage: PropTypes.string,
};

export default NoResultsCampaignSearch;
