import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class DevTablePagination extends React.PureComponent {
    getTotalPages = (pageSize, totalCount) => {
        if (totalCount <= pageSize) {
            return 1;
        }

        return Math.ceil(totalCount / pageSize);
    };

    getActivePage = (offset, pageSize) => {
        return offset / pageSize;
    };

    getOffset = (page, pageSize) => {
        return page * pageSize;
    };
    getPageSize = () => {
       
        return this.props.isOnMobileViewport
            ? constants.MAX_PROPS_ON_MOBILE
            : constants.MAX_PROPS_ON_DESKTOP;
    };
  
    getNumberOfPages = () => {
        return this.props.isOnMobileViewport
        ? constants.MAX_PAGES_DISPLAYED_MOBILE
        : constants.MAX_PAGES_DISPLAYED_DESKTOP;
    };
 
    createDisplayedPages = (page, totalPages) => {
        

        if (page >= totalPages) {
            throw new Error('page must be in totalPages interval');
        }

        if (totalPages <= this.getNumberOfPages()) {
            return Array.from({ length: totalPages }, (x, i) => i); // [0, 1, 2]
        }

        if (page < this.getNumberOfPages() / 2) {
            return Array.from({ length: this.getNumberOfPages() }, (x, i) => i); // [0, 1, 2, 3, 4]
        }

        if (page > totalPages - this.getNumberOfPages() / 2) {
            return Array.from(
                { length: this.getNumberOfPages() },
                (x, i) => i + totalPages - this.getNumberOfPages()
            ); // [10, 11, 12, 13, 14]
        }

        const start = page - Math.floor(this.getNumberOfPages() / 2);

        return Array.from({ length: this.getNumberOfPages() }, (x, i) => i + start); // [7, 8, 9, 10, 11]
    };
    changeDisplayedPages = (page, totalPages) => {
        this.setState({
            currentDisplayedPages: this.createDisplayedPages(page, totalPages)
        });
    };

    isNewActivePageOnDisplay = (page) => {
        return this.currentDisplayedPages.includes(page);
    };

    isFirstPageOnDisplay = () => {
        return this.currentDisplayedPages.includes(0);
    };

    isLastPageOnDisplay = () => {
        return this.currentDisplayedPages.includes(this.lastPage);
    };


    onLinkClick = (page) => {
        return () => {
            this.props.onPageClick(
                this.getOffset(page, this.getPageSize()),
                this.currentDisplayedPages
            );
        };
    };

    render() {
        this.totalPages = this.getTotalPages(
            this.getPageSize(),
            this.props.pagination.totalCount
        );
        this.activePage = this.getActivePage(
            this.props.pagination.offset,
            this.getPageSize()
        );
        this.firstPage = 0;
        this.lastPage = this.totalPages - 1;
        this.currentDisplayedPages = this.props.currentDisplayedPages;

        if (!this.isNewActivePageOnDisplay(this.activePage)) {
            this.currentDisplayedPages = this.createDisplayedPages(
                this.activePage,
                this.totalPages
            );
        }
        return (
            <div
                className={classNames({
                    'dev-table-pagination dev-table-pagination-v2': true,
                    'dev-table-pagination--hidden': !this.props.isExpanded,
                })}

            >
                {
                this.totalPages > 1 &&
                <ul className="dev-table-pagination__list">
                    <li className="dev-table-pagination__item">
                        <a
                            className={classNames({
                                'dev-table-pagination__link dev-table-pagination-v2__link': true,
                                'dev-table-pagination__link--arrow dev-table-pagination-v2__link--arrow': true,
                                'dev-table-pagination__link--active dev-table-pagination-v2__link--active':
                                    this.activePage !== this.firstPage,
                            })}
                            onClick={
                                this.activePage !== this.firstPage
                                    ? this.onLinkClick(this.activePage - 1)
                                    : () => {}
                            }
                        >
                            <svg className="icon" width="4" height="8">
                                <use xlinkHref="#icon-arrow-left-thin"></use>
                            </svg>
                        </a>
                    </li>
                    {this.currentDisplayedPages.map((page, i) => (
                        <li className="dev-table-pagination__item" key={i}>
                            <a
                                className={classNames({
                                    'dev-table-pagination__link dev-table-pagination-v2__link': true,
                                    'dev-table-pagination__link--active dev-table-pagination-v2__link--active': page === this.activePage,
                                })}
                                onClick={
                                    page !== this.activePage ? this.onLinkClick(page) : () => {}
                                }
                            >
                                {Math.floor(page) + 1}
                            </a>
                        </li>
                    ))}
                    {!this.isLastPageOnDisplay() && (
                        <>
                            <li className="dev-table-pagination__item">
                                <a className="dev-table-pagination__link dev-table-pagination-v2__link">...</a>
                            </li>
                            <li className="dev-table-pagination__item">
                                <a
                                    className="dev-table-pagination__link dev-table-pagination-v2__link"
                                    onClick={this.onLinkClick(this.lastPage)}
                                >
                                    {this.lastPage + 1}
                                </a>
                            </li>
                        </>
                    )}
                    <li className="dev-table-pagination__item dev-table-pagination__item--ml-4">
                        <a
                            className={classNames({
                                'dev-table-pagination__link dev-table-pagination-v2__link': true,
                                'dev-table-pagination__link--arrow dev-table-pagination-v2__link--arrow': true,
                                'dev-table-pagination__link--active dev-table-pagination-v2__link--active':
                                    this.activePage !== this.lastPage,
                            })}
                            onClick={
                                this.activePage !== this.lastPage
                                    ? this.onLinkClick(this.activePage + 1)
                                    : () => {}
                            }
                        >
                            <svg className="icon" width="4" height="8">
                                <use xlinkHref="#icon-arrow-right-thin"></use>
                            </svg>
                        </a>
                    </li>
                </ul>
                }
            </div>
        );
    }
}

DevTablePagination.propTypes = {
    isExpanded: PropTypes.bool,
    onPageClick: PropTypes.func,
    isOnMobileViewport: PropTypes.bool,
    currentDisplayedPages: PropTypes.arrayOf(PropTypes.number),
    pagination: PropTypes.shape({
        offset: PropTypes.number,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number,
    }),
};

export default withContext(DevTablePagination, {
    currentDisplayedPages: 'currentDisplayedPages',
    pagination: 'pagination',
    onPageClick: 'onPageClick',
    isOnMobileViewport: 'isOnMobileViewport',
});
