import axios from 'axios';
import app from './app';
import UrlHelper from './url-helper';

const loquateApi = axios.create({
    timeout: 15000,
});

const ENTITY_TYPE_POSTCODE = 'Postcode';
const ERROR_CODE_NOT_RESPONDED_IN_TIME = 1005;

class LoquateService {
    constructor() {
        this.baseUrl = app.getRequiredConfig('LOQATE_BASE_URL');
    }

    async checkEmail(text) {
        return this._performRequest('checkemail', { Email: text });
    }

    async checkPhone(text) {
        return this._performRequest('checkphone', { Phone: text });
    }

    async find(text) {
        if (text.replace(/[\s]/gi, '') === '') {
            return [];
        }
        return this._performRequest('findaddresses', { Text: text });
    }

    async getAddressesByPostCode(text) {
        const postcodeId = await this._findPostCodeId(text);
        if (postcodeId) {
            return this._findAddresses(postcodeId);
        }
        throw new Error('No post code was found for the provided query');
    }

    async getAddressDetails(id) {
        const items = await this._retrieveAddress(id);
        return items[0];
    }

    async _findPostCodeId(text) {
        const items = await this._performRequest('getcontainer', {
            Text: text
        });
        const postcodeItem = items.find(item => item.Type === ENTITY_TYPE_POSTCODE);
        if (postcodeItem) {
            return postcodeItem.Id;
        }

        return null;
    }

    async _findAddresses(postcodeId) {
        return this._performRequest('getcontaineraddresses', { Container: postcodeId });
    }

    async _retrieveAddress(id) {
        return this._performRequest('getaddressdetails', { Id: id });
    }

    _getApiUrl(methodPath, params) {
        return UrlHelper.getUrlByParams(`${this.baseUrl}${methodPath}`, {
            ...params
        });
    }

    async _performRequest(methodPath, params) {
        try {
            const response = await loquateApi.post(this._getApiUrl(methodPath, params));
            const data = response.data;
            const items = data.Items;
            if (items && Array.isArray(items)) {
                if (items.length > 0 && (items[0].Error !== null)) {
                    const error = items[0];
                    this._handleApiError(error.Error);
                }
                // in case we have zero items - return it like success empty result
                return items;
            }
            this._throwUnknownError();
        } catch (e) {
            this._throwUnknownError();
        }
    }

    _handleApiError(code) {
        switch (code) {
            case ERROR_CODE_NOT_RESPONDED_IN_TIME:
                throw new Error('We couldn\'t get the response, the server didn\'t respond in time. Please try again in a few minutes');
            default:
                this._throwUnknownError();
        }
    }

    _throwUnknownError() {
        throw new Error('An error occurred during your request. Please, try again in a few minutes');
    }
}

export default (new LoquateService());
