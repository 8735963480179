import React from 'react';
import PropTypes from 'prop-types';

const ArticleCard = (props) => {
    const item = props.item;

    return (

        <div className={'post-preview post-preview--' + item.articleCategoryColourScheme}>
            <div className="post-preview__thumbnail">
                <div className="aspectratio" data-ar="16:9">
                    {item.thumbnailImageSrcSet &&
                        <img
                            className="lazyload post-preview__image"
                            data-sizes="auto" alt={item.thumbnailImageAlt}
                            data-srcset={item.thumbnailImageSrcSet}
							src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        />
                    }
                </div>
                </div>
            <div className="post-preview__content">
                {item.articleTag && <div className='post-preview__tag'>{item.articleTag}</div>}
                <p className="post-preview__title">
                    <a href={item.url} title={item.title}>
                        {item.title}
                    </a>
                </p>
                <p className="post-preview__description">{item.description}</p>
            </div>
        </div>

    );
};

ArticleCard.propTypes = {
    item: PropTypes.shape({
        articleCategoryColourScheme: PropTypes.string,
        articleEndDate: PropTypes.number,
        articleLinkText: PropTypes.string,
        articlePostedDate: PropTypes.number,
        author: PropTypes.string,
        description: PropTypes.string,
        isFeaturedArticle: PropTypes.bool,
        isRecommendedReads: PropTypes.bool,
        thumbnailImageSrcSet: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
        articleTag: PropTypes.string,
    })
};

export default ArticleCard;
