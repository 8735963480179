import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import viewport from 'general/js/viewport';

const BOTTOM_COORDINATE = 600;

const LIST_ITEM_SELECTOR = '.page-overview__list-item';

const navigationWrapperStyles = {
    styleProperty: 'height',
    value: '130vh',
};

const DevelopmentSubNavigation = (props) => {
    const {
        navigationPreviewWrapper,
        navigationWrapper,
        navigationOpened,
        closeButton,
        goToButton,
    } = props.refs;

    const header = document.querySelector('header.main-wrapper__section');
    const ctaHeader = document.querySelector('.cta-block-aside__header');
    const notificationBar = document.querySelector('.notification-bar');

    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const navHeight =
        navigationPreviewWrapper &&
        parseInt(
            window.getComputedStyle(navigationPreviewWrapper, null).getPropertyValue('height'),
            10
        );

    const [isMenuOpened, setIsMenuOpened] = useState(0);
    const [isMobile, setIsMobile] = useState(device.isViewportTypeLt(DEVICE_TYPE_TABLET));

    useEffect(() => {
        if (document.querySelectorAll(LIST_ITEM_SELECTOR).length > 3) {
            setElementStyle(navigationOpened, navigationWrapperStyles);
        }
        viewport.subscribeOnResize(onResize);

        return () => {
            removeButtonsListeners();
        };
    }, []);

    useEffect(() => {
        moveNotificationBar();
        if (isMobile) {
            window.addEventListener('scroll', setHeaderOnScrollBehavior, false);
            addButtonsListeners();
            setNavigationsSettingsByState();
            moveNavigationPreviewBarByState();
        } else {
            removeButtonsListeners();
            setIsMenuOpened(false);
            closeList();
            setDefaultNavValues();
            setClassClassByValue(navigationWrapper, false, 'navigation-wrapper-opened-height');
            setSidebarScrollBehavior();
        }

        return () => {
            window.removeEventListener('scroll', setHeaderOnScrollBehavior);
        };
    }, [isMobile]);

    useEffect(() => {
        setNavigationsSettingsByState();
    }, [isMenuOpened]);

    const onResize = () => {
        if (device.isViewportTypeLt(DEVICE_TYPE_TABLET)) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const setElementStyle = (element, { styleProperty, value }) => {
        if (element) element.style = `${styleProperty}:${value}`;
    };

    const setClassClassByValue = (element, isAdding, additionalClass) => {
        if (isAdding) {
            element.classList?.add(additionalClass);
        } else {
            element.classList?.remove(additionalClass);
        }
    };

    const setHeaderVisible = () => {
        setClassClassByValue(navigationPreviewWrapper, true, 'navigation-moved');
        setClassClassByValue(header, false, 'header-moved');
    };

    const setHeaderHidden = () => {
        setClassClassByValue(navigationPreviewWrapper, false, 'navigation-moved');
        setClassClassByValue(header, true, 'header-moved');
    };

    const setDefaultNavValues = () => {
        setClassClassByValue(navigationPreviewWrapper, false, 'navigation-moved');
        setClassClassByValue(header, false, 'header-moved');
    };

    const setHeaderOnScrollBehavior = () => {
        const topScrollButton = document.querySelector('#mobile-cta .scroll-button');
        setTimeout(() => {
            if (topScrollButton) {
                if (viewport.isCoordinateAbove(BOTTOM_COORDINATE)) {
                    topScrollButton.classList.remove('is-button-hidden');
                } else {
                    topScrollButton.classList.add('is-button-hidden');
                }
            }
        });
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > 300) {
            if (st > lastScrollTop) {
                setHeaderHidden();
            } else {
                setHeaderVisible();
            }

            lastScrollTop = st <= 0 ? 0 : st;
        }
    };

    const setSidebarScrollBehavior = () => {
        ['load', 'resize', 'scroll'].forEach((eventType) => {
            window.addEventListener(eventType, sidebarScrollBehavior);
        });
    };

    const sidebarScrollBehavior = () => {
        const sticky = document.querySelector('[data-dc-component-sticky-column]'); // get sticky column

        if (sticky && window.innerWidth > 1023) { // sticky found and desktop only
            const scrollDistance = parseInt(window.scrollY); // get page scroll distance
            const stickyHeight = sticky.offsetHeight; // get height of sticky element
            const stickyParentTop = sticky.parentElement.offsetTop; // get sticky parent offset top
            const viewportHeight = window.innerHeight; // get viewport height

            const maxTravel = stickyHeight - viewportHeight; // calculate maximum distance the sticky column needs to travel

            if (stickyHeight > viewportHeight && scrollDistance > stickyParentTop) { // if sticky column heigher than viewport and scrol is beyond it's parent top
                sticky.style.transform = `translateY(-${scrollDistance - stickyParentTop < maxTravel ? scrollDistance - stickyParentTop : maxTravel}px)`; // set new sticky offset
            } else {
                sticky.style.transform = 'translateY(0)'; // clear sticky offset
            }
        } else if (sticky) {
            sticky.style.transform = 'translateY(0)'; // clear sticky offset
        }
    };

    const onClickChangeToOpen = () => {
        setIsMenuOpened(true);
    };

    const onClickChangeToClose = () => {
        setIsMenuOpened(false);
    };

    const moveNavigationPreviewBarByState = () => {
        if (isMenuOpened) {
            setClassClassByValue(navigationPreviewWrapper, false, 'navigation-moved');
        } else {
            setClassClassByValue(navigationPreviewWrapper, true, 'navigation-moved');
        }
    };

    const moveNotificationBar = () => {
        const ctaBlockStyles = {
            mobile: {
                styleProperty: 'margin-top',
                value: navHeight ? `${navHeight + 60}px` : '130px',
            },
            desktop: {
                styleProperty: 'margin-top',
                value: 0,
            },
        };

        const changeMargin = (element, value) => {
            if (element) element.style.marginTop = `${value}`;
        };

        if (isMobile) {
            notificationBar
                ? changeMargin(notificationBar, ctaBlockStyles.mobile.value)
                : setElementStyle(ctaHeader, ctaBlockStyles.mobile);
        } else {
            notificationBar
                ? changeMargin(notificationBar, ctaBlockStyles.desktop.value)
                : setElementStyle(ctaHeader, ctaBlockStyles.desktop);
        }
    };

    const setNavigationsSettingsByState = () => {
        const listMargin = {
            styleProperty: 'margin-top',
            value: `${navHeight + 45}px`,
        };

        if (isMenuOpened) {
            openList();
            setClassClassByValue(navigationWrapper, true, 'navigation-wrapper-opened-height');
        } else {
            closeList();
            setClassClassByValue(navigationWrapper, false, 'navigation-wrapper-opened-height');
        }

        const list = document.querySelector('.page-overview__nav-list');

        if (isMobile) {
            setElementStyle(list, listMargin);
        }

        if (list) {
            list.addEventListener('click', (e) => {
                if (e.target.closest(LIST_ITEM_SELECTOR)) {
                    onClickChangeToClose();
                    closeList();
                }
            });
        }
    };

    const openList = () => {
        document.body.classList.add('prevent-scroll');
        navigationOpened.classList.add('is-opened');
    };

    const closeList = () => {
        document.body.classList.remove('prevent-scroll');
        navigationOpened.classList.remove('is-opened');
    };

    const addButtonsListeners = () => {
        goToButton.addEventListener('click', onClickChangeToOpen);
        closeButton.addEventListener('click', onClickChangeToClose);
    };

    const removeButtonsListeners = () => {
        goToButton.removeEventListener('click', onClickChangeToOpen);
        closeButton.removeEventListener('click', onClickChangeToClose);
    };

    return (
        <React.Fragment>
            <div className="sub-navigation-buttons__grid">
                {props.ctaButtons.map((button, index) => {
                    return (
                        <div key={button.text} className="sub-navigation-buttons__grid-item">
                            <a
                                href={
                                    button.url.includes('download-brochure')
                                        ? button.url.replace('download-brochure', 'post-brochure')
                                        : button.url
                                }
                                className={`sub-navigation-buttons__btn sub-navigation-buttons__btn--${
                                    index === 0 ? 'main' : 'secondary'
                                }`}
                            >
                                {button.shortText}
                            </a>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default DevelopmentSubNavigation;

DevelopmentSubNavigation.propTypes = {
    refs: PropTypes.array.isRequired,
    ctaButtons: PropTypes.object.isRequired,
};
