import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import DevelopmentCarouselCard from './development-carousel-card';

export default class DevelopmentCarouselCardComponent extends DcBaseComponent {

    static getNamespace() {
        return 'development-carousel-card';
    }

    onInit() {
        this.render();
    }

    render() {
        ReactDOM.render(<DevelopmentCarouselCard />, this.element);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
