import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import RecentlyViewedArticlesCarousel from './recently-viewed-articles-carousel';
import RecentlyViewedArticlesCard from './recently-viewed-articles-card';

const RecentlyViewedArticles = (props) => {
    const { recentlyViewedArticlesApiUrl, title } = props;

    const recentArticlesLocalStorage = localStorage.getItem('__RECENT-ARTICLES__');
    const currentlyViewedArticle = document.querySelector(
        '[data-article-id]'
    );
    const currentArticleId = currentlyViewedArticle ? currentlyViewedArticle.dataset.articleId : '';
    const [articleData, setArticleData] = useState([]);

    useEffect(() => {
        fetchArticleData();

        if (currentArticleId) {
            updateRecentArticlesLocalStorage();
        }
    }, [currentArticleId]);


    const updateRecentArticlesLocalStorage = () => {
        let articleIds = [];

        if (recentArticlesLocalStorage && currentArticleId) {
            const ids = JSON.parse(recentArticlesLocalStorage);

            const filteredIds = ids.filter(
                (id) => (id !== currentArticleId),
                []
            );

            articleIds = [currentArticleId, ...filteredIds];
        }

        if (!recentArticlesLocalStorage && currentArticleId) {
            articleIds = [currentArticleId];
        }

        localStorage.setItem(
            '__RECENT-ARTICLES__',
            JSON.stringify(articleIds.slice(0, 3)) // Setting 4 items the current ID is removed in 'fetchArticleData()' so only 3 will be rendered
        );
    };

    const fetchArticleData = async () => {
        if (!recentArticlesLocalStorage) return;

        const articlesIds = JSON.parse(recentArticlesLocalStorage);

        if (!articlesIds || articlesIds.length === 0) return;

        const articleQuery = articlesIds
            .filter(
                (article) => article !== currentArticleId
            )
            .map((article) => `articleIds=${article}`)
            .join('&');

        axios({
            method: 'get',
            url:
            `/${recentlyViewedArticlesApiUrl}?${articleQuery}`,
        }).then((res) => {
            if (!res.data) {
                console.error('Failed to fetch data');
            }

            setArticleData(res.data);
        }).catch(() => {
            console.error('Failed to fetch data');
        });
    };

    const renderArticles = () =>
        articleData.map((article, index) => (
            <RecentlyViewedArticlesCard key={index} article={article} />
        ));

    const renderCarousel = () => (
        <RecentlyViewedArticlesCarousel>
            {articleData.map((article, index) => (
                <RecentlyViewedArticlesCard key={index} article={article} />
            ))}
        </RecentlyViewedArticlesCarousel>
    );

    return (
        <>
            {articleData && articleData.length > 0 && (
                <div className="recently-viewed-articles__container">
                    <h3>{title}</h3>
                    <div className="recently-viewed-articles-card__wrapper">{renderArticles()}</div>
                    <div className="recently-viewed-articles-card__carousel">
                        {renderCarousel()}
                    </div>
                </div>
            )}
        </>
    );
};

RecentlyViewedArticles.propTypes = {
    recentlyViewedArticles: PropTypes.string,
    title: PropTypes.string
};

export default RecentlyViewedArticles;
