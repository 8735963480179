import VideoComponent from '../../../components/video/js/video.component';

export default class GalleryVideoStateObserver {
    constructor(galleryElement) {
        this.galleryElement = galleryElement;

        this.playingVideo = null;
        this.slideElements = new Set();
        this.startedSlides = []
    }

    addVideo(slideElement, slideIndex) {
        if (!this.slideElements.has(slideElement)) {
            this.slideElements.add(slideElement);

            // override options from markup
            const videoComponent = new VideoComponent(slideElement, { inline: true }, () => {
                this.startedSlides.push(slideIndex);
                this._onPlayTrigger(videoComponent);
            });
            videoComponent.init();
        }
    }

    pauseVideo() {
        if (this.playingVideo) {
            this.playingVideo.pause();
            this.playingVideo = null;
        }
    }

    /**
     * @param {VideoComponent} videoComponent
     * @private
     */
    _onPlayTrigger = (videoComponent) => {
        this._startObserveVideo(videoComponent.getVideo());
    };

    /**
     * @param {AbstractVideo} video
     * @private
     */
    _startObserveVideo(video) {
        // despite we haven't start the video yet and just triggered play
        // we immediately assign it to playingVideo in case we change the current slideElement during video initialization
        // so in this case not started video will be successfully paused
        this.playingVideo = video;
        video.onPlay(() => {
            this._onVideoStartsPlaying(video);
        });
        video.onPause(() => {
            this._onVideoStopsPlaying();
        });
        this.galleryElement.classList.add('is-video-started');
    }

    _onVideoStartsPlaying(video) {
        this.playingVideo = video;
        this.galleryElement.classList.add('is-video-playing');
    }

    _onVideoStopsPlaying() {
        this.playingVideo = null;
        this.galleryElement.classList.remove('is-video-playing');
    }
}
