export default class GooglePopup extends google.maps.OverlayView {
    constructor(position, contentElement) {
        super();
        this.position = position;
        this._createAnchor();
        this.setContent(contentElement);
    }

    _createAnchor() {
        this.anchor = document.createElement('div');
        this.anchor.classList.add('map-popup');
        this.anchor.style.cursor = 'auto';
    }

    setContent(element) {
        this.anchor.innerHTML = '';
        this.anchor.insertAdjacentElement('beforeend', element);
        // stop events from from bubbling
        google.maps.OverlayView.preventMapHitsAndGesturesFrom(element);
    }

    remove() {
        this._removeFromDOM();
    }

    _removeFromDOM() {
        if (this.anchor.parentElement) {
            this.anchor.parentElement.removeChild(this.anchor);
        }
    }

    onAdd() {
        /** Called when the popup is added to the map. */
        this.getPanes().floatPane.appendChild(this.anchor);
    }

    onRemove() {
        /** Called when the popup is removed from the map. */
        this._removeFromDOM();
    }

    draw() {
        /** Called when the popup needs to draw itself. */
        const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
        // Hide the popup when it is far out of view.
        const display =
            Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

        if (display === 'block') {
            this.anchor.style.left = divPosition.x + 'px';
            this.anchor.style.top = divPosition.y + 'px';
        }
        if (this.anchor.style.display !== display) {
            this.anchor.style.display = display;
        }
    }
}
