import React from 'react';
import PropTypes from 'prop-types';
import { AFTERLINE_COLORS, AFTERLINE_PARAMS } from './constant';

const tickIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 75"
        width="50"
        height="50"
        className="tick-icon"
    >
        <path d="M54.26,27.6l-.1-.1a2.43,2.43,0,0,0-3.42,0L33.23,45l-6.17-6.18a2.44,2.44,0,0,0-3.43,0l-.1.1a2.43,2.43,0,0,0,0,3.42l6.18,6.18h0l3.52,3.52,21-21A2.43,2.43,0,0,0,54.26,27.6Z" />
    </svg>
);

export default function DevelopmentStage(props) {
    let isVisible;
    let afterlineHeight;
    let afterlineMargin;
    const { isFinished, index, title, date, isMobile, length, isNextStageFinished,
        isExpanded, lastFinishedIndex, firstFutureIndex, showTimelineDates } = props;
    const realDate = isFinished && date.trim().toUpperCase() === 'TBC' ? '' : date;
    const afterlineColor = isFinished ? AFTERLINE_COLORS.FINISHED : AFTERLINE_COLORS.PROGRESS;

    const leftAfterlineStyles = {
        background: afterlineColor,
        opacity: index === 0 ? '0' : '1'
    };

    const rightAfterlineStyles = {
        background: isNextStageFinished
            ? AFTERLINE_COLORS.FINISHED
            : AFTERLINE_COLORS.PROGRESS,
        opacity: index === length - 1 ? '0' : '1'
    };

    if (isExpanded) {
        if (
            (isFinished && index < lastFinishedIndex) ||
            (!isFinished && index > firstFutureIndex)
        ) {
            isVisible = 'none';
        }
    } else {
        isVisible = 'flex';
    }

    if (index === lastFinishedIndex) {
        afterlineHeight = isExpanded
            ? AFTERLINE_PARAMS.HEIGHT.NOT_EXPANDED
            : AFTERLINE_PARAMS.HEIGHT.EXPANDED;
        afterlineMargin = isExpanded
            ? AFTERLINE_PARAMS.MARGIN.NOT_EXPANDED
            : AFTERLINE_PARAMS.MARGIN.EXPANDED;
    } else if (index === firstFutureIndex) {
        afterlineHeight = AFTERLINE_PARAMS.HEIGHT.EXPANDED;
        afterlineMargin = AFTERLINE_PARAMS.MARGIN.EXPANDED;
    }

    const mobileAfterlineStyles = {
        ...leftAfterlineStyles,
        height: afterlineHeight,
        margin: afterlineMargin
    };

    const verticalStage =
        <div className={`development-timeline__stage ${isFinished ? 'stage-finished' : 'stage-not-finished'} 
            development-timeline__stage--mobile`}
        style={{ display: isVisible }}>
            <div className='development-timeline__stage-wrapper'>
                <div className='development-timeline__stage-icon icon-wrapper'>{tickIcon}</div>
                <div className='development-timeline__stage-text'>
                    <div
                        className='development-timeline__stage-title'
                        style={{ marginTop: !showTimelineDates || realDate === '' ? '7px' : 0 }}>
                        <span>{title}</span>
                    </div>
                    <div className='development-timeline__stage-date'>
                        <span>{showTimelineDates && realDate}</span>
                    </div>
                </div>
            </div>
            <div className='development-timeline__stage-afterline' style={mobileAfterlineStyles}></div>
        </div>;

    const horizontalStage =
        <div className={`development-timeline__stage ${isFinished ? 'stage-finished' : 'stage-not-finished'} 
            development-timeline__stage--desktop`}>
            <div className='development-timeline__stage-wrapper' 
                style={{
                    marginBottom: !showTimelineDates ? '32px' : 0,
                }}>
                <div className='development-timeline__stage-title'><span>{title}</span></div>
                <div className='development-timeline__stage-visual'>
                    <div className='development-timeline__stage-icon icon-wrapper'>{tickIcon}</div>
                    <div className='development-timeline__stage-afterline'>
                        <div
                            className='development-timeline__stage-afterline--left'
                            style={leftAfterlineStyles}></div>
                        <div
                            className='development-timeline__stage-afterline--right'
                            style={rightAfterlineStyles}></div>
                    </div>
                </div>
                { showTimelineDates &&
                    <div className='development-timeline__stage-date'><span>{realDate}</span></div>
                }
            </div>
        </div>;

    const developmentStage = isMobile ? verticalStage : horizontalStage;

    return (
        <>{developmentStage}</>
    );
}

DevelopmentStage.propTypes = {
    isFinished: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    index: PropTypes.number,
    length: PropTypes.number,
    isNextStageFinished: PropTypes.bool,
};
