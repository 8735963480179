import DcBaseComponent from 'general/js/dc/dc-base-component';
import Lightbox from '../../../general/js/lightbox';

export default class LightboxTriggerComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this._lightboxInstance = null;
        this.btn = this.element;
        this.src = this.options.src;
        this.width = this.options.width;
        this.height = this.options.height;
        this.isDragging = false;
    }

    static getNamespace() {
        return 'lightbox-trigger';
    }

    onInit() {
        this._addListeners();
    }

    _addListeners() {
        this.btn.addEventListener('click', this._invokeBtnClick);
        this.btn.addEventListener('touchend', this._invokeBtnTouchStart);
        this.btn.addEventListener('touchmove', this._invokeBtnTouchMove);
    }

    _invokeBtnClick = e => {
        this._openLightbox();
    };

    _invokeBtnTouchStart = e => {
        if (this.isDragging) {
            return;
        }
        this._openLightbox();
    };

    _invokeBtnTouchMove = e => {
        this._setDragging();
    };

    _setDragging() {
        this.isDragging = true;
    }

    _openLightbox() {
        const lightbox = this._getLightbox();
        lightbox.open();
    }

    _getLightbox() {
        if (this._lightboxInstance === null) {
            if (this.src) {
                this._lightboxInstance = new Lightbox(this.src, this.width, this.height);
            }
        }

        return this._lightboxInstance;
    }
}
