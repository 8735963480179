import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from "../../../checkbox/js/checkbox.jsx";

export default class MobileFiltersChecklist extends React.Component {

    constructor(props) {
        super(props);
    }

    onChange = (changedCheckboxValue, isChecked) => {
        const currentValue = this.props.value;
        let newValue = null;
        if (isChecked) {
            newValue = [...currentValue, changedCheckboxValue];
        } else {
            newValue = currentValue.filter(value => value !== changedCheckboxValue);
        }
        this.props.onChange(newValue);
    };

    render() {
        const { label, options, value } = this.props;
        return (
            <div
                className="search-mobile-filters__checklist">
                <div className="search-mobile-filters__checklist-label">{label}</div>
                <div className="search-mobile-filters__checklist-list">
                    {options.map((option, index) => {
                        const isChecked = value.includes(option.value);
                        return (
                            <div
                                className="search-mobile-filters__checklist-item"
                                role="option"
                                aria-selected={isChecked}
                                key={index}>
                                <Checkbox
                                    isDisabled={option.isDisabled}
                                    isChecked={isChecked}
                                    text={option.text}
                                    onChange={e => this.onChange(option.value, e.target.checked)}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

MobileFiltersChecklist.defaultProps = {
    value: []
};

MobileFiltersChecklist.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};
