import React from 'react';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';

export default function BudgetDescription() {
    const [state] = useBudgetCalculatorStore();

    return (
        <div className="budget-description">
            <div className="budget-description__text-column">
                <div className="text-section-subtitle">{state.heading}</div>
                <h2 className="budget-description__title text-section-title">{state.title}</h2>
                <div
                    className="budget-description__text richtext"
                    dangerouslySetInnerHTML={{ __html: state.text }}
                ></div>
            </div>
            <div className="budget-description__img">
                <img className="lazyload" data-srcset={state.imageUrl} data-sizes="auto" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="" />
            </div>
        </div>
    );
}
