import GoogleMarker from '../google-marker';
import pinGrey from '../../img/pin-leisure-grey.svg';
import pinGreen from '../../img/pin-leisure-green.svg';

export default class GoogleMarkerLeisure extends GoogleMarker {
    getIconGrey() {
        return pinGrey;
    }

    getIconGreen() {
        return pinGreen;
    }
}
