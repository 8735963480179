import React from 'react';
import PropTypes from 'prop-types';

export default function BudgetTitle({ title, subtitle }) {
    return (
        <div className="budget-title">
            <h3 className="budget-title__title">{title}</h3>
            <p className="budget-title__subtitle">{subtitle}</p>
        </div>
    );
}

BudgetTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};
