import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_TABLET } from '../../../general/js/device';
import anime from 'animejs';

const HIDDEN_CLASS = 'is-hidden';
const DEFAULT_INITIAL_VISIBLE_ITEMS_MOBILE = 8;
const DEFAULT_INITIAL_VISIBLE_ITEMS_TABLET = 12;
const DEFAULT_LOAD_MORE_AMOUNT_MOBILE = 4;
const DEFAULT_LOAD_MORE_AMOUNT_TABLET = 12;

export default class FeaturedLocationsComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.initAmountMobile =
            parseInt(this.options.initAmountMobile) || DEFAULT_INITIAL_VISIBLE_ITEMS_MOBILE;
        this.initAmountTablet =
            parseInt(this.options.initAmountTablet) || DEFAULT_INITIAL_VISIBLE_ITEMS_TABLET;
        this.moreAmountMobile =
            parseInt(this.options.moreAmountMobile) || DEFAULT_LOAD_MORE_AMOUNT_MOBILE;
        this.moreAmountTablet =
            parseInt(this.options.moreAmountTablet) || DEFAULT_LOAD_MORE_AMOUNT_TABLET;

        this.visibleItemsCount = 0;
        this.items = this.refs.items || [];
        this.showMoreButton = this.refs.showMoreButton;
    }

    static getNamespace() {
        return 'featured-locations';
    }

    static getRequiredRefs() {
        return ['items', 'showMoreButton'];
    }

    onInit() {
        if (this.items.length <= 0) {
            throw new Error('There are should be at least one location to render component');
        }

        this._handleDevice();

        if (this.isMobile) {
            this._initMobile();
        } else {
            this._initTablet();
        }
    }

    _handleDevice() {
        this._onDeviceTypeChanged();
        device.subscribeOnDeviceTypeChange(this._onDeviceTypeChanged);
    }

    _initMobile() {
        if (this.items.length > this.initAmountMobile) {
            this._showMore(this.initAmountMobile);
            this._showButton();
        } else {
            this._showMore(this.items.length);
        }
    }

    _initTablet() {
        if (this.items.length > this.initAmountTablet) {
            this._showMore(this.initAmountTablet);
            this._showButton();
        } else {
            this._showMore(this.items.length);
        }
    }

    _onDeviceTypeChanged = () => {
        this.isMobile = device.isViewportTypeLt(DEVICE_TYPE_TABLET);
    };

    _onshowMoreClick = e => {
        if (this.isMobile) {
            this._showMore(this.moreAmountMobile, { onClick: true });
        } else {
            this._showMore(this.moreAmountTablet, { onClick: true });
        }
    };

    _showMore(amount, options = {}) {
        const extraItems = this._getExtraItems(amount);
        this._showExtraItems(extraItems);
        if (this.visibleItemsCount >= this.items.length) {
            this._hideButton();
        } else {
            this.showMoreButton.setAttribute('aria-expanded', 'true');
        }
        if (options.onClick) {
            this._focusItemLink(extraItems[0]);
        }
    }

    _showExtraItems(extraItems) {
        const notEmptyArray = extraItems && Array.isArray(extraItems) && extraItems.length > 0;
        if (notEmptyArray) {
            extraItems.forEach((item, index) => {
                item.classList.remove(HIDDEN_CLASS);
            });
            extraItems.forEach((item, index) => {
                anime({
                    targets: item,
                    opacity: [0, 1],
                    translateY: ['-100%', '0%'],
                    duration: 200,
                    delay: 50 * index,
                    easing: 'easeOutQuad'
                });
            });
            this.visibleItemsCount += extraItems.length;
        }
    }

    _focusItemLink(listItem) {
        const link = listItem.querySelector('[data-featured-locations-link]');
        if (link) {
            link.focus({ preventScroll: true });
        }
    }

    _getExtraItems(amount) {
        return this.items.slice(this.visibleItemsCount, this.visibleItemsCount + amount);
    }

    _showButton() {
        this.showMoreButton.classList.remove(HIDDEN_CLASS);
        this.showMoreButton.addEventListener('click', this._onshowMoreClick);
    }

    _hideButton() {
        this.showMoreButton.classList.add(HIDDEN_CLASS);
        this.showMoreButton.removeEventListener('click', this._onshowMoreClick);
    }
}
