import React from 'react';
import PropTypes from 'prop-types';
import ModalTriggerComponent from '@Components/modal/js/modal-trigger.component.js';

export default class CheckboxPopupDescription extends React.PureComponent {
    constructor(props) {
        super(props);

        this.modalTrigger = null;
    }

    linkRef = (element) => {
        if (element) {
            if (this.modalTrigger === null) {
                this.modalTrigger = new ModalTriggerComponent(element, this.afterOpen);
                this.modalTrigger.init();
            }
        } else if (this.modalTrigger !== null) {
            this.modalTrigger.destroy();
            this.modalTrigger = null;
        }
    };

    afterOpen = () => {
        this.props.afterOpen();
    };

    render() {
        const { url, text } = this.props;
        if (!url || !text) {
            return null;
        }

        return (
            <div className="search-desktop-filters__control-checklist-description">
                <a ref={this.linkRef} href={url}>
                    {text}
                </a>
            </div>
        );
    }
}

CheckboxPopupDescription.propTypes = {
    afterOpen: PropTypes.func.isRequired,
    text: PropTypes.string,
    url: PropTypes.string,
};
