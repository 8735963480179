import React, { useEffect } from 'react';
import device, { DEVICE_TYPE_TABLET } from '../../../general/js/device';
import BudgetCharts from './containers/budget-charts.jsx';
import BudgetDescription from './containers/budget-description.jsx';
import BudgetPeriod from './containers/budget-period.jsx';
import BudgetPropertyType from './containers/budget-property-type.jsx';
import BudgetProductType from './containers/budget-product-type';
import BudgetTooltip from './containers/budget-tooltip.jsx';
import BudgetExtraBenefits from './containers/budget-extra-benefits.jsx';
import BudgetOpenWrapper from './containers/budget-open-wrapper.jsx';
import { useBudgetCalculatorStore } from './store/budget-calculator-store.jsx';
import BudgetServicesWrapper from './containers/budget-services-wrapper.jsx';
import analyticsService from '@General/js/analytics-service.js';
import Filter from './store/filter';

export default function BudgetCalculator() {
    const [state, dispatch] = useBudgetCalculatorStore();

    useEffect(() => {
        const updateViewportState = () => {
            dispatch({
                type: 'UPDATE_VIEWPORT',
                isOnMobileViewport: !device.isViewportTypeGe(DEVICE_TYPE_TABLET),
            });
        };
        const deviceChangeUnsubscribe = device.subscribeOnDeviceTypeChange(
            updateViewportState,
            false
        );
        return deviceChangeUnsubscribe;
    }, []);

    const isLayoutShown = () => {
        if (
            Filter.isFilterValid(state.propertyTypeFilter) &&
            state.propertyTypeFilter.hideCalculations === true
        ) {
            return true;
        }

        return state.isCalcShown;
    };

    const dispatchGaEvent = (event, data) => {
        const subNavEl = document.getElementsByClassName('page-overview__list-item is-selected');
        if(subNavEl.length > 0) {
            const subNavigation = subNavEl[0].innerText;
            analyticsService._send(event,  { subNavigation, ...data });
        }
    }

    const extraBenefitsShown =
        (!state.isGenericCalculator &&
            state.activeServices.length > 0 &&
            state.additionalRLPSectionText) ||
        (state.isGenericCalculator &&
            state.activeServices.length > 0 &&
            state.additionalRLPSectionText &&
            state.rlpSectionTextDisplayed) ? (
            <BudgetExtraBenefits />
        ) : null;

    return (
        <div className="budget-calculator">
            <BudgetDescription />
            <div className="budget-calculator__calculator">
                <div className="budget-calculator__row">
                    <div className="budget-calculator__left-column">
                        <BudgetOpenWrapper dispatchGaEvent={dispatchGaEvent} />
                    </div>
                    <div className="budget-calculator__right-column">
                        <BudgetPeriod />
                    </div>
                </div>
                {isLayoutShown() && (
                    <div className="budget-calculator__row budget-calculator__row--mt-15">
                        <div className="budget-calculator__column">
                            <BudgetServicesWrapper dispatchGaEvent={dispatchGaEvent} />
                        </div>
                    </div>
                )}
                <div className="budget-calculator__row budget-calculator__row--mt-30">
                    <div className="budget-calculator__left-column">
                        <BudgetCharts />
                    </div>
                    <div className="budget-calculator__right-column">
                        {state.isGenericCalculator && <BudgetProductType dispatchGaEvent={dispatchGaEvent} />}
                        <BudgetPropertyType dispatchGaEvent={dispatchGaEvent} />
                    </div>
                </div>
                {extraBenefitsShown}
            </div>

            <BudgetTooltip />
        </div>
    );
}
