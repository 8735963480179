import React from 'react';
import PropTypes from 'prop-types';
import scrollbarHandler from 'general/js/scrollbar-handler';
import constants from 'general/js/constants';
import device, { DEVICE_TYPE_DESKTOP, DEVICE_TYPE_TABLET } from 'general/js/device';
import GoogleMap from '../../../map/js/google-map';
import { withContext } from '../context';

class DevelopmentsSearchMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        this.applyMapWidth();
        const { isInitialRequestFulfilled, items } = this.props;
        // init map straight away if we have zero pin maps
        // otherwise map will be rendered later during renderItemsOnMap call
        const eagerInit = isInitialRequestFulfilled && items.length === 0;
        this.googleMap = new GoogleMap(this.mapRef.current, this.props.mapConfig, {
            eager: eagerInit
        });
        if (items.length > 0) {
            this.renderItemsOnMap();
        }
        this.usubscribeDeviceTypeChange = device.subscribeOnDeviceTypeChange(this.onDeviceTypeChange, true)
    }

    onDeviceTypeChange = (newValue, oldValue) => {
        if (newValue === DEVICE_TYPE_TABLET && oldValue === DEVICE_TYPE_DESKTOP || newValue === DEVICE_TYPE_DESKTOP && oldValue === DEVICE_TYPE_TABLET) {
            this.googleMap.fitCurrentMarkers();
        }
    };

    componentDidUpdate(prevProps) {
        const { items, tabsMode } = this.props;

        if (items !== prevProps.items) {
            this.renderItemsOnMap();
        }

        if (tabsMode !== prevProps.tabsMode) {
            this.applyMapWidth();
        }
    }

    renderItemsOnMap() {
        const { onMarkersUpdate, items } = this.props;
        const locations = items.map(item => ({
            id: item.id,
            lat: item.lat,
            lng: item.lng
        }));
        this.googleMap.updateMarkers(locations).then(() => {
            onMarkersUpdate(this.googleMap.getMarkersById());
        });
    }

    componentWillUnmount() {
        this.usubscribeDeviceTypeChange();
    }

    applyMapWidth() {
        let right;
        if (this.props.tabsMode) {
            right = 'auto';
        } else {
            const scrollbarSize = scrollbarHandler.hasScrollBar() ?
                scrollbarHandler.getScrollbarSize() :
                0;
            right = `calc(-1 * ((100vw - ${constants.CONTAINER_MAX_WIDTH}px) / 2 - ${scrollbarSize}px / 2))`;
        }

        this.mapRef.current.style.right = right;
    }

    render() {
        return (
            <div className="developments-search__map-placeholder">
                <div className="developments-search__map" ref={this.mapRef}>
                </div>
            </div>
        );
    }
}

DevelopmentsSearchMap.propTypes = {
    tabsMode: PropTypes.bool.isRequired,
    onMarkersUpdate: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    mapConfig: PropTypes.object.isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
};

export default withContext(DevelopmentsSearchMap, {
    items: 'items',
    mapConfig: 'mapConfig',
    isInitialRequestFulfilled: 'isInitialRequestFulfilled'
});
