import React from 'react';

export default function withContext(Context, Component, mapToProps) {
    return props => (
        <Context.Consumer>
            {(context) => {
                const propsFromContext = Object.keys(mapToProps).reduce((result, propName) => {
                    const contextKey = mapToProps[propName];
                    result[propName] = context[contextKey];
                    return result;
                }, {});
                return <Component {...props} {...propsFromContext} />;
            }}
        </Context.Consumer>
    );
}
