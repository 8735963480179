import $ from 'jquery';
import 'slick-carousel';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import GalleryVideoStateObserver from 'general/js/video/gallery-video-state-observer';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';

const AUTOPLAY_SPEED = 7000;

export default class HeroCarouselComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.gallery = null;
        this.isAutoplayStopped = false;
        this.videoObserver = null;
        this.videoSlides = this.refs.videos || [];
        this.slidingTime = 'slidingTime' in this.options ? this.options.slidingTime : AUTOPLAY_SPEED;
    }

    static getNamespace() {
        return 'hero-carousel';
    }

    static getRequiredRefs() {
        return ['slides'];
    }

    onInit() {
        const slidesCount = this.refs.slides.children.length;
        if (slidesCount > 0) {
            if (this.videoSlides.length > 0) {
                this.videoObserver = new GalleryVideoStateObserver(this.element);
            }
            if (slidesCount === 1) {
                this.initSlideElement(this.refs.slides.children[0]);
            } else {
                this.initSlider();
            }
        }
    }

    initSlider() {
        const { slides, dots } = this.refs;

        if (!dots) {
            throw new Error('Slider should contain dots');
        }

        const $slides = $(slides);
        const $dots = $(dots);

        this.gallery = new SlickResponsive(
            $slides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                zIndex: 1,
                arrows: true,
                dots: true,
                swipe: true,
                dotsClass: 'hero-carousel__dots',
                appendDots: $dots,
                prevArrow: `<button type="button" class="btn hero-carousel__arrow-button hero-carousel__arrow-button--prev" data-dc-gtm-selector="HeroCarouselPrevButton" aria-label="Previous Slide">
                            <svg width="9" height="17" class="icon hero-carousel__arrow-button-icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-left-thin"></use>
                            </svg>
                        </button>`,
                nextArrow: `<button type="button" class="btn hero-carousel__arrow-button hero-carousel__arrow-button--next" data-dc-gtm-selector="HeroCarouselNextButton" aria-label="Next Slide">
                            <svg width="9" height="17" class="icon hero-carousel__arrow-button-icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-right-thin"></use>
                            </svg>
                        </button>`,
                autoplay: true,
                autoplaySpeed: this.slidingTime,
                rows: 0,
            },
            this._onBreakpointTriggered.bind(this)
        );

        this.gallery.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.onBeforeSlideChange(nextSlide);
        });

        this.gallery.on('afterChange', (event, slick, currentSlide) => {
            this.onAfterSlideChange(slick, currentSlide);
        });

        this.initStartSlide();

        this.element.addEventListener('mouseenter', this.onSliderMouseEnter.bind(this));
        this.element.addEventListener('mouseleave', this.onSliderMouseLeave.bind(this));
        this.element.addEventListener('click', this.onSliderClick.bind(this));
    }

    _onBreakpointTriggered() {
        // for some reason after each breakpoint event
        // previously stopped autoplay (on prev breakpoint options) continue working
        // so we should call it again based on our flag
        if (this.isAutoplayStopped) {
            this._stopAutoPlay();
        }
    }

    onSliderMouseEnter() {
        if (!this.isAutoplayStopped) {
            this.gallery.slick('slickPause');
        }
    }

    onSliderMouseLeave() {
        if (!this.isAutoplayStopped) {
            this.gallery.slick('slickPlay');
        }
    }

    onSliderClick() {
        if (!this.isAutoplayStopped) {
            this.isAutoplayStopped = true;
            this._stopAutoPlay();
        }
    }

    _stopAutoPlay() {
        this.gallery.slick('slickPause');
    }

    initSlideElement(element, slideIndex) {
        if (this.videoSlides.includes(element)) {
            this.videoObserver.addVideo(element, slideIndex);
        }
    }

    initStartSlide() {
        const mainSlick = this.gallery.slick('getSlick');
        const slickCurrentSlide = this.gallery.slick('slickCurrentSlide');
        this.initSlickSlide(mainSlick, slickCurrentSlide);
    }

    onAfterSlideChange(slick, currentSlide) {
        this.initSlickSlide(slick, currentSlide);
    }

    initSlickSlide(slick, slide) {
        this.initSlideElement(slick.$slides.get(slide), slide);
    }

    onBeforeSlideChange(nextSlide) {
        if (!this.videoObserver) {
            return;
        }

        this.videoObserver.pauseVideo();

        if (this.videoObserver.startedSlides.includes(nextSlide)) {
            this.element.classList.add('is-video-started');
        } else {
            this.element.classList.remove('is-video-started');
        }
    }

    onDestroy() {
        if (this.gallery !== null) {
            this.gallery.slick('unslick');
            this.gallery = null;
        }
    }
}
