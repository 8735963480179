import React from 'react';
import PropTypes from 'prop-types';
import SwiperCarousel from 'general/js/swiper-carousel/js/swiper-carousel';

export default class ZeroSearchResalesBanner extends React.PureComponent {
    render() {
        const { images, baseLink, title, text, buttonText, radius } = this.props.reSales;
        const { locationName } = this.props;

		const baseLinkUrl = new URL(baseLink);
		const originalParams = new URLSearchParams(baseLinkUrl.search);
		const queryString = locationName && locationName !== ''
            ? `place=${locationName.toLowerCase()}&radius=${radius}&address_keyword=${locationName.toLowerCase()}`
            : `ajax_border_miles=${radius}`;
		const newParams = new URLSearchParams(queryString);

		originalParams.forEach((value, key) => {
		  newParams.append(key, value);
		});

        const url = baseLinkUrl.toString().split('?')[0] + '?' + newParams.toString();

        return (
            <div className="zero-search-resales__banner resales-banner">
                <div className="resales-banner__swiper-wrapper">
                    <SwiperCarousel autoplay={true}>
                        {images.map((image, index) => (
                            <img role="presentation"
                                aria-hidden="true"
                                alt={title}
                                key={index}
                                src={image}
                                srcSet={image} />
                            ))}
                    </SwiperCarousel>
                </div>
                <div className="resales-banner__content">
                    <div className="re-sales__content">
                        <h3 className="h3">{title}</h3>
                        <p>{text}</p>
                        <div className="resales-banner__button-wrapper">
                            <a
                                className="btn-secondary btn-secondary--invert"
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer">{buttonText}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

ZeroSearchResalesBanner.propTypes = {
    reSales: PropTypes.shape({
        alignImageForSearchPage: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        baseLink: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        colorSetForSearchPage: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        radius: PropTypes.string.isRequired,
    }).isRequired,
    locationName: PropTypes.string
};
