import React from 'react';

function EventSearchPagination(props) {
    const pageLinks = [];
    const truncate = props.pages.length > 4;
    const currentPageIndex = props.pages.findIndex(page => page.active);
    const lastPageIndex = props.pages.length - 1;

    let minimumPageIndex = 0;
    let maximumPageIndex = lastPageIndex;
    let ellipsisIndex = -1;

    if (currentPageIndex < 3) {
        maximumPageIndex = 3;
        ellipsisIndex = maximumPageIndex;
    } else if (currentPageIndex > lastPageIndex - 3) {
        minimumPageIndex = lastPageIndex - 3;
        maximumPageIndex = lastPageIndex;
        ellipsisIndex = minimumPageIndex;
    } else {
        minimumPageIndex = currentPageIndex - 2;
        maximumPageIndex = currentPageIndex + 1;
        ellipsisIndex = maximumPageIndex;
    }

    props.pages.forEach((page, index) => {
        if (!truncate || (
                (index === 0 && maximumPageIndex === lastPageIndex) ||
                (index >= minimumPageIndex && index <= maximumPageIndex) ||
                (index === lastPageIndex)
            )
        ) {
            pageLinks.push(<button
                aria-current={page.active}
                aria-label={page.active ? `Current page, page ${page.pageNumber}` : `Go to page ${page.pageNumber}`}
                className="event-search-pagination__link"
                disabled={page.active}
                key={index}
                {...!page.active && { onClick: () => props.goToPage(page.apiUrl, page.url) }}
                type="button">{index === ellipsisIndex ? <>&hellip;</> : page.pageNumber}</button>);
        }
    });

    const paginationText = props.paginationText.replace(/{StartRange}/g, props.startRange).replace(/{EndRange}/g, props.endRange).replace(/{NumberOfEvents}/g, props.total);

    return (
        <nav aria-label="Pagination navigation" className="event-search-pagination" role="navigation">
            <div dangerouslySetInnerHTML={{ __html: paginationText }}></div>
            <div className="event-search-pagination__controls">
                <button
                    aria-label="Go to first page"
                    className="event-search-pagination__button event-search-pagination__button--first"
                    disabled={!props.first}
                    {...props.first && { onClick: () => props.goToPage(props.first.apiUrl, props.first.url) }}
                    type="button">
                    <span className="event-search-pagination__button-label">First page</span>
                </button>
                <button
                    aria-label="Go to previous page"
                    className="event-search-pagination__button event-search-pagination__button--previous"
                    disabled={!props.previous}
                    {...props.previous && { onClick: () => props.goToPage(props.previous.apiUrl, props.previous.url) }}
                    type="button">
                    <span className="event-search-pagination__button-label">Previous page</span>
                </button>
                {pageLinks}
                <button
                    aria-label="Go to next page"
                    className="event-search-pagination__button event-search-pagination__button--next"
                    disabled={!props.next}
                    {...props.next && { onClick: () => props.goToPage(props.next.apiUrl, props.next.url) }}
                    type="button">
                    <span className="event-search-pagination__button-label">Next page</span>
                </button>
                <button
                    aria-label="Go to last page"
                    className="event-search-pagination__button event-search-pagination__button--last"
                    disabled={!props.last}
                    {...props.last && { onClick: () => props.goToPage(props.last.apiUrl, props.last.url) }}
                    type="button">
                    <span className="event-search-pagination__button-label">Last page</span>
                </button>
            </div>
        </nav>
    )
}

export default EventSearchPagination;
