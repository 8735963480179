const MAX_PROPS_ON_MOBILE = 3;
const MAX_PROPS_ON_DESKTOP = 5;
const MAX_PROPS_ON_PAGE = 5;
const MAX_PAGES_DISPLAYED_DESKTOP = 5;
const MAX_PAGES_DISPLAYED_MOBILE = 2;
const LOADING_MESSAGE = {
    title: "We're sorry to keep you waiting",
    text: "It's taking a while, but this page is still loading. Please wait.",
};
const NO_PROPERTIES_MESSAGE = {
    title: "We're sorry, there's been an error",
    text: "We can't find the properties you're looking for. Please try again later.",
};
const ERROR_MESSAGE = {
    title: "We're sorry, there's a problem with the connection",
    text: 'Please try again later.',
};
const FIELDS = {
    Prices: "Prices",
    Status: "Status"
}
const SORT = {
    Asc: "Asc",
    Desc: "Desc"
}

export default {
    MAX_PROPS_ON_PAGE,
    MAX_PROPS_ON_MOBILE,
    MAX_PAGES_DISPLAYED_DESKTOP,
    MAX_PAGES_DISPLAYED_MOBILE,
    MAX_PROPS_ON_DESKTOP,
    LOADING_MESSAGE,
    ERROR_MESSAGE,
    NO_PROPERTIES_MESSAGE,
    FIELDS,
    SORT
};
