import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import trackerService from '@General/js/tracker-service.js'

import { CampaignMobileFilterContext } from '@Components/campaign-property-search/js/context';

const MobileFiltersPrices = ({
    filtersConfig,
    onChange,
    isDisabled,
    name,
    value
}) => {
    const { filterOptions } = useContext(CampaignMobileFilterContext);
    const config = filtersConfig.find((configItem) => configItem.name === name);
    const label = config.label;
    const optionName = config.name;
    const clickTrigger = config.clickTrigger;

    const [priceLabelValue, setPriceLabelValue] = useState(label);
    const [priceSelectValue, setPriceSelectValue] = useState('');

    const handlePriceChange = (priceValue) => {
        onChange(optionName, priceValue);
        const chosenOptionConfig = config.options.find((option) => option.value === priceValue);
        setPriceLabelValue(chosenOptionConfig.mobileText);
        setPriceSelectValue(priceValue);
    };

    useEffect(() => {
        if (filterOptions.maxPrice === '' && filterOptions.maxRent === '' && filterOptions.maxOpsoPrice === '') {
            setPriceLabelValue(label);
            setPriceSelectValue('');
        }
    }, [filterOptions.maxPrice, filterOptions.maxRent, filterOptions.maxOpsoPrice]);

    return (
        <div
            className={classNames({
                'campaign-mobile-filters__prices': true,
                'campaign-mobile-filters__prices--disabled': isDisabled,
            })}
        >
            <div className="mobile-filters-prices__label">{label}</div>
            <div className="mobile-filters-prices__select">
                <div
                    className="mobile-filters-prices__select-value"
                    dangerouslySetInnerHTML={{ __html: isDisabled ? 'No max' : priceLabelValue }} />
                <select
                    id={label}
                    disabled={isDisabled}
                    value={priceSelectValue}
                    onChange={(e) => {                        
                        handlePriceChange(e.target.value);
                        trackerService.track(clickTrigger);
                    }}
                >
                    {config.options.map((option, index) => {
                        return (
                            <option
                                key={index}
                                value={option.value}
                            >
                                {option.mobileText}
                            </option>
                        );
                    })}
                </select>
                <svg
                    className="icon"
                    width="14"
                    height="7"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                >
                    <use xlinkHref="#icon-arrow-down" />
                </svg>
            </div>
        </div>
    );
}

MobileFiltersPrices.propTypes = {
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    filtersConfig: PropTypes.filtersConfig.isRequired,
};

export default MobileFiltersPrices;
