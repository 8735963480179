import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../spinner/js/spinner';
import PropertiesSearchResults from './properties-search-results';
import PropertiesSearchZeroResults from './properties-search-zero-results';

class PropertiesSearchContent extends React.PureComponent {
    isResultEmpty() {
        const { totalCount, isInitialRequestFulfilled } = this.props;
        return isInitialRequestFulfilled && totalCount === 0;
    }

    render() {
        const { isLoading, animatingContentRef } = this.props;
        return (
            <div className="properties-search__content" ref={animatingContentRef}>
                {!this.isResultEmpty() ?
                    <PropertiesSearchResults/> :
                    <PropertiesSearchZeroResults/>}
                <Spinner isActive={isLoading}/>
            </div>
        );
    }
}

PropertiesSearchContent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    animatingContentRef: PropTypes.func.isRequired,
};

export default PropertiesSearchContent;
