const IF_ID_ATTRIBUTE = 'data-if-id';
const OPERATOR_OR = "OR";
const OPERATOR_AND = "AND";

export default class ControlsCondition {
    /**
     * @param {string} condition - { "expressions": [["EnquiryType", "Unsubscribe"], ["EnquiryType", false]], "operator": "OR" }
     * @param {Function} onStateChange
     * @param {bool} inverse
     */
    constructor({ expressions, operator }, onStateChange, inverse = false) {
        this._expressions = expressions;
        this._operator = operator || OPERATOR_AND;
        this._onStateChange = onStateChange;
        this._inverse = inverse;

        this._ids = [];

        /**
         * Cache condition result
         * @type {bool}
         * @private
         */
        this._lastResult = undefined;

        /**
         * Stores expressions with values based on elements types
         * @type {{string: {string|bool}}}
         * @private
         */
        this._preparedExpressions = [];

        /**
         * Stores controls values indexed by ids
         * @type {{string: {string|bool}}}
         * @private
         */
        this._valuesById = {};

        /**
         * Stores controls (single or multiple) indexed by ids
         * @type {{string: {HTMLElement[]}}}
         * @private
         */
        this._controlsById = {};

        /**
         * All elements (including multiple like option) which the state depends on
         * @type {Array}
         * @private
         */
        this._elements = [];

        this._initConditions();
        this._init();
    }

    _initConditions() {
        this._expressions.forEach((elementCondition) => {
            const [id, elementValue] = elementCondition;
            if (!id) {
                throw new Error('You expression doesn\'t have a valid input Id that it depends on');
            }

            const elementsOfId = [...document.querySelectorAll(`[${IF_ID_ATTRIBUTE}="${id}"]`)];
            const elementsCount = elementsOfId.length;
            if (elementsCount > 0) {
                // take an account in conditions
                const element = elementsOfId[0];
                if (this._isValidElement(element)) {
                    // proceed falsy values
                    let preparedValue = null;
                    if ([undefined, 'true', 'false'].includes(elementValue)) {
                        preparedValue = !!(
                            elementValue === undefined || elementValue === 'true'
                        );
                    } else {
                        preparedValue = elementValue;
                    }
                    this._preparedExpressions.push([id, preparedValue]);
                }
                // register for event listening
                this._elements = [...this._elements, ...elementsOfId];
                this._controlsById[id] = elementsOfId;
                this._ids.push(id);
            }
        });
    }

    _init() {
        this._addListeners();
        this._initValues();
        this._lastResult = this._getConditionValue();
        this._onStateChange(this._lastResult);
    }

    _addListeners() {
        this._elements.forEach((element) => {
            if (this._isTextControl(element)) {
                element.addEventListener('input', this._onElementChange);
            } else {
                element.addEventListener('change', this._onElementChange);
            }
        });
    }

    _initValues() {
        this._ids.forEach(id => {
            this._valuesById[id] = this._getControlValueById(id);
        });
    }

    _onElementChange = (e) => {
        const element = e.target;
        const id = this._getElementId(element);
        this._valuesById[id] = this._getControlValueById(id);
        this._check();
    };

    _getControlValueById(id) {
        const controls = this._controlsById[id];
        return controls.length === 1 ? this._getSingleControlValue(controls[0]) : this._getMultipleControlValue(controls, controls[0].type);
    }

    _getSingleControlValue(control) {
        switch (control.type) {
            case 'checkbox':
            case 'radio':
                return control.checked ? control.value : false;
            case 'select-multiple':
                return [...control.selectedOptions].map(x => x.value);
            default:
                return control.value;
        }
    }

    _getMultipleControlValue(controls, type) {
        switch (type) {
            case 'radio':
                const checkedRadio = controls.find(c => c.checked);
                return checkedRadio ? this._getSingleControlValue(checkedRadio) : false;
            case 'checkbox':
                const checkedCheckboxes = controls.filter(c => c.checked);
                return checkedCheckboxes.map(c => c.value);
            default:
                throw new Error('Unexpected multiple control');
        }
    }

    _getConditionValue() {
        const result = this._operator === OPERATOR_AND ?
            this._preparedExpressions.every(this._checkIdFunction) :
            this._preparedExpressions.some(this._checkIdFunction);
        return this._applyInverse(result);
    }

    _applyInverse(value) {
        return this._inverse ? !value : value;
    }

    _checkIdFunction = ([id, requiredValue]) => {
        const controls = this._controlsById[id];
        const value = this._valuesById[id];

        // for example value - is list of checked checkboxes
        if (Array.isArray(value)) {
            // required value - is true - so the value should contain at least one element
            if (requiredValue === true) {
                return value.length > 0;
            }
            // required value - is the one which is has to be checked
            return value.includes(requiredValue);
        } else if (Array.isArray(requiredValue)) {
            // required value - is array and current value is plain
            // so we have to check current value is one from the required list
            // for example radio list
            return requiredValue.includes(value);
        } else {
            const element = controls[0];
            const type = element.type;

            if ((this._isTextControl(element) || element.tagName === 'SELECT') && (typeof requiredValue === "boolean")) {
                return (value !== '') === requiredValue;
            } else if ((type === 'checkbox' || type === 'radio') && (typeof requiredValue === "boolean")) {
                return (value !== false) === requiredValue;
            }
        }

        return value === requiredValue;
    };

    _check() {
        const result = this._getConditionValue();
        if (result !== this._lastResult) {
            this._handleStateUpdate(result);
        }
    }

    _handleStateUpdate(result) {
        this._lastResult = result;
        this._onStateChange(result);
    }

    _isValidElement(element) {
        return ['INPUT', 'SELECT', 'TEXTAREA'].includes(element.tagName);
    }

    _isTextControl(element) {
        return (
            element.tagName === 'TEXTAREA' ||
            (element.tagName === 'INPUT' &&
                (element.type === 'text' ||
                    element.type === 'email' ||
                    element.type === 'number' ||
                    element.type === 'password'))
        );
    }

    _getElementId(element) {
        return element.getAttribute(IF_ID_ATTRIBUTE);
    }
}
