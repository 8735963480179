import GoogleMarker from '../google-marker';
import pinGrey from '../../img/pin-library-grey.svg';
import pinGreen from '../../img/pin-library-green.svg';

export default class GoogleMarkerLibrary extends GoogleMarker {
    getIconGrey() {
        return pinGrey;
    }

    getIconGreen() {
        return pinGreen;
    }
}
