import React from 'react';
import PropTypes from 'prop-types';
import FiltersList from './filters.jsx';
import SortingRow from './sorting-row.jsx';
import PropertiesDisplay from './properties-display.jsx';
import Pagination from './pagination.jsx';
import Spinner from '../../../spinner/js/spinner.jsx';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class PropertiesTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterValues: null,
           filterCurrentPropertiesTotal: 0,
           filteredProperties: 0,
            currentPage: 1
        };
    }
  
    componentDidUpdate(prevProps) {
        if (this.props.isOnMobileViewport !== prevProps.isOnMobileViewport) {
            this.setNewPageSizeData();
        }
        this.setState({filterCurrentPropertiesTotal: this.child?.state.allProperties.length, filteredProperties: this.child?.state.filterCurrentProperties.length })
       
        
    }
 
 
    onFilterChange = (filterValues) => {
         
        this.setState({
            filterValues: filterValues,
            currentPage: 1
        });
        this.props.resetPagination();
         
    }

    changeActiveTab = (tab) => {
        const partialState = {
            activeTab: tab
        };
        const params = {
            pageSize: this.props.currentPageSize,
            offset: 0,
            channel: partialState.activeTab.id,
            orderBy: constants.HEADINGS[constants.DEFAULT_ACTIVE_HEAD].id + constants.SORT_TYPES[constants.DEFAULT_ACTIVE_SORT].id
        };
        this.props.getData(params, partialState);
    };

    changeActiveSort = (headIndex, sortIndex) => {
        const partialState = {
            activeHead: headIndex,
            activeSortType: sortIndex,
            activeTab: this.props.activeTab
        };
        const params = {
            pageSize: this.props.currentPageSize,
            offset: 0,
            channel: this.props.activeTab.id,
            orderBy: constants.HEADINGS[partialState.activeHead].id + constants.SORT_TYPES[partialState.activeSortType].id
        };
        this.props.getData(params, partialState);
    };

    changeActivePage = (offset, currentDisplayedPages) => {
        this.setState({
            currentPage: (offset / 6) + 1
        });
        const partialState = {
            pagination: {
                ...this.props.pagination,
                pageSize: 6,
                offset: offset
            },
            currentDisplayedPages: currentDisplayedPages,
            activeHead: this.props.activeHead,
            activeSortType: this.props.activeSortType,
            activeTab: this.props.activeTab
        };
        const params = {
            pageSize: (this.state.filterCurrentPropertiesTotal < 6) ? this.props.pagination.totalCount: 6,
            offset: partialState.pagination.offset,
            channel: this.props.activeTab.id,
            orderBy: constants.HEADINGS[partialState.activeHead].id + constants.SORT_TYPES[partialState.activeSortType].id
        };
        this.props.getData(params, partialState);
    };

    setNewPageSizeData = () => {
        const offset = 0;
        const pageSize = 6;

        this.setState({
            currentPage: (offset / 6) + 1
        });

        const partialState = {
            pagination: {
                ...this.props.pagination,
                pageSize,
                offset
            },
        };
        const params = {
            pageSize: (this.state.filterCurrentPropertiesTotal < pageSize) ? this.props.pagination.totalCount: pageSize,
            offset,
            channel: this.props.activeTab.id,
            orderBy: constants.HEADINGS[this.props.activeHead].id + constants.SORT_TYPES[this.props.activeSortType].id
        };
        this.props.getData(params, partialState);
    };

    getCurrentPropertiesPosition = () => {
        let { offset, pageSize, totalCount } = {offset:this.props.pagination.offset, pageSize: (this.state.filterCurrentPropertiesTotal < 6) ? this.state.filterCurrentPropertiesTotal: 6, totalCount: this.state.filterCurrentPropertiesTotal}
        if (totalCount === 0) {
            return '0-0';
        }
        let start = 0;
        let end = 0

        if(offset > this.state.filterCurrentPropertiesTotal) {
            start = 1
        }else {
            start = offset + 1
        }

        end = start + pageSize - 1;
      
        if (end > totalCount) {
            return start + '-' + totalCount;
        }

        return start + '-' + end;
    };

    render() {
       
        const propertyAmountText = `Showing ${this.getCurrentPropertiesPosition()} of ${this.state.filterCurrentPropertiesTotal} properties matching your filters 
       `;
        return (
            <div className="properties-table v2">
                <FiltersList onApply={this.onFilterChange} filterValues={this.state.filterValues} />
                <div className="properties-table__container-row properties-table__container-row--border-top">
                    <div className="properties-table__tab-section">
                        <p className="property-table__amount-text">{propertyAmountText}</p>
                            
                        {!this.props.isTextShowing && (
                            <> 
                                    <PropertiesDisplay childRef={ref => (this.child = ref)}  filterValues={this.state.filterValues} currentPage={this.state.currentPage} />
                               
                                <Pagination filterCurrentProperties={this.state.filterCurrentPropertiesTotal}
                                    onPageClick={this.changeActivePage}
                                />
                            </>
                        )}
                        {this.props.isTextShowing && (
                            <div className="property-table__message">
                                <h4 className="property-table__message-title">{this.props.message.title}</h4>
                                <p className="property-table__message-text">{this.props.message.text}</p>
                            </div>
                        )}
                    </div>
                </div>
                {this.props.isGettingData &&
                    <div className='property-table__spinner-container'>
                        <Spinner isActive={true} />
                    </div>
                }
            </div>
        );
    }
}

PropertiesTable.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    })),
    currentPageSize: PropTypes.number,
    getData: PropTypes.func,
    resetPagination: PropTypes.func,
    isOnMobileViewport: PropTypes.bool,
    isTextShowing: PropTypes.bool,
    message: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string
    }),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    }),
    activeHead: PropTypes.number,
    activeSortType: PropTypes.number,
    pagination: PropTypes.shape({
        offset: PropTypes.number,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number
    })
};

export default withContext(PropertiesTable, {
    tabs: 'tabs',
    currentPageSize: 'currentPageSize',
    getData: 'getData',
    resetPagination: 'resetPagination',
    isOnMobileViewport: 'isOnMobileViewport',
    isTextShowing: 'isTextShowing',
    isGettingData: 'isGettingData',
    message: 'message',
    activeTab: 'activeTab',
    activeHead: 'activeHead',
    activeSortType: 'activeSortType',
    pagination: 'pagination'
});
