import DcBaseComponent from 'general/js/dc/dc-base-component';

const HAS_PLACEHOLDER = 'has-placeholder';

export default class FormSelectComponent extends DcBaseComponent {
    static getNamespace() {
        return 'form-select';
    }

    onInit() {
        this.element.addEventListener('change', this.onChange);
    }

    onDestroy() {
        this.element.removeEventListener('change', this.onChange);
    }

    onChange = () => {
        this.element.classList.remove(HAS_PLACEHOLDER);
    };
}
