import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class ChatbotComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        // elements
        this.chatbot = this.element;
        this.buttonOpenChat = this.chatbot.querySelector('[data-chatbot-button-open-chat]');
        this.buttonCloseNudge = this.chatbot.querySelector('[data-chatbot-button-nudge-close]');

        // variables
        this.configId = this.chatbot.dataset.chatbotConfigId;
        this.customData = JSON.parse(this.chatbot.dataset.chatbotCustomData);
        this.nudgeActive = this.chatbot.dataset.chatbotNudgeActive === 'true';
        this.nudgeTimerDelay = this.chatbot.dataset.chatbotNudgeTimerDelay;
        this.nudgeTimer;

        // functions
        this.initLandbot = this.initLandbot.bind(this);
        this.chatbotLoad = this.chatbotLoad.bind(this);
        this.openChat = this.openChat.bind(this);
        this.openNudge = this.openNudge.bind(this);
        this.closeNudge = this.closeNudge.bind(this);
        this.clickOffNudge = this.clickOffNudge.bind(this);
        this.cancelNudge = this.cancelNudge.bind(this);
    }

    static getNamespace() {
        return 'chatbot';
    }

    onInit() {
        window.addEventListener('mouseover', this.initLandbot, { once: true });
        window.addEventListener('touchstart', this.initLandbot, { once: true });
    }

    initLandbot() {
        if (!window.landbot) {
            const chatbotScript = document.createElement('script');
            chatbotScript.type = 'text/javascript';
            chatbotScript.async = true;
            chatbotScript.addEventListener('load', this.chatbotLoad);
            chatbotScript.src = '/dist/lib/landbot-3.0.0.custom.js';

            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(chatbotScript, firstScript);
        }
    }

    chatbotLoad() {
        window.landbot = new Landbot.Livechat({
            configUrl: `https://storage.googleapis.com/landbot.pro/v3/${this.configId}/index.json`,
            customData: this.customData,
        });

        window.landbot.core.events.on('load', function() {
            window.landbot.config.brand_name = window.landbot.config.brand_name.replace('@dev_name', window.landbot.config.customData.dev_name);

        });

        if (this.nudgeActive) {
            this.nudgeTimer = setTimeout(this.openNudge, this.nudgeTimerDelay * 1000);

            this.chatbot.addEventListener('click', this.clickOffNudge);
            this.buttonOpenChat.addEventListener('click', this.openChat);
            this.buttonCloseNudge.addEventListener('click', this.closeNudge);

            window.landbot.core.events.on('widget_open', this.cancelNudge);
        }
    }

    openChat() {
        this.closeNudge();
        window.landbot.open();
    }

    openNudge() {
        this.chatbot.classList.add('chatbot--nudge-active');
    }

    closeNudge() {
        this.chatbot.classList.remove('chatbot--nudge-active');
    }

    clickOffNudge(event) {
        if (event.target === this.chatbot) {
            this.closeNudge();
        }
    }

    cancelNudge() {
        clearTimeout(this.nudgeTimer);
    }
}
