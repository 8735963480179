/**
 * DEFINE_VIEWPORT_WIDTH_DESKTOP_WIDE,
 * DEFINE_VIEWPORT_WIDTH_DESKTOP,
 * DEFINE_VIEWPORT_WIDTH_TABLET,
 * DEFINE_VIEWPORT_WIDTH_MOBILE_WIDE
 * DEFINE_CONTAINER_MAX_WIDTH
 * are filled via webpack.DefinePlugin
 * @type {*[]}
 */

const CONTAINER_MAX_WIDTH = DEFINE_CONTAINER_MAX_WIDTH;
const DEFAULT_TRANSITION_DURATION = DEFINE_DEFAULT_TRANSITION_DURATION;
const VIEWPORT_WIDTH_DESKTOP_WIDE = DEFINE_VIEWPORT_WIDTH_DESKTOP_WIDE;
const VIEWPORT_WIDTH_DESKTOP = DEFINE_VIEWPORT_WIDTH_DESKTOP;
const VIEWPORT_WIDTH_TABLET = DEFINE_VIEWPORT_WIDTH_TABLET;
const VIEWPORT_WIDTH_MOBILE_WIDE = DEFINE_VIEWPORT_WIDTH_MOBILE_WIDE;
const FIXED_CTA_HEIGHT_MOBILE = 68;
const FIXED_CTA_HEIGHT_TABLET = 75;
const FIXED_CTA_HEIGHT_DESKTOP = 75;
const SCROLLING_TOP_OFFSET = 180;

const DEVELOPMENT_PROPERTY_TYPE_RL = 'rl';
const DEVELOPMENT_PROPERTY_TYPE_RL_PLUS = 'rlp';
const DEVELOPMENT_PROPERTY_TYPE_LL = 'll';

const EVENT_FIELD_VALIDATION_FAILED = 'field-validation-failed';
const EVENT_FORM_VALIDATION_FAILED = 'form-validation-failed';
const ATTRIBUTE_VALIDATE_ALWAYS = 'data-validate-always';

export default {
    CONTAINER_MAX_WIDTH,
    DEFAULT_TRANSITION_DURATION,
    VIEWPORT_WIDTH_DESKTOP_WIDE,
    VIEWPORT_WIDTH_DESKTOP,
    VIEWPORT_WIDTH_TABLET,
    VIEWPORT_WIDTH_MOBILE_WIDE,
    DEVELOPMENT_PROPERTY_TYPE_RL,
    DEVELOPMENT_PROPERTY_TYPE_RL_PLUS,
    DEVELOPMENT_PROPERTY_TYPE_LL,
    EVENT_FIELD_VALIDATION_FAILED,
    EVENT_FORM_VALIDATION_FAILED,
    FIXED_CTA_HEIGHT_MOBILE,
    FIXED_CTA_HEIGHT_TABLET,
    FIXED_CTA_HEIGHT_DESKTOP,
    ATTRIBUTE_VALIDATE_ALWAYS,
    SCROLLING_TOP_OFFSET,
};
