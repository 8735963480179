import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import { CampaignMobileFilterContext } from '@Components/campaign-property-search/js/context';
import MobileFiltersRadiobutton from './mobile-filters-radiobutton.jsx';
import MobileFiltersPrices from './mobile-filters-prices.jsx';
import trackerService from '@General/js/tracker-service.js'

const ALL_TYPES_TEXT = 'Show all occupancy options';

const ownershipText = 'You must select an occupancy option to be able to filter by a sale or rental price.';

const MobileFiltersOwnership = ({ config, allFilters, onChange }) => {
    const fieldId = uniqueId('filter-field-');
    const { filterOptions, setRadiobuttonValue, radiobuttonValue } = useContext(CampaignMobileFilterContext);

    return (
        <>
            <div className='campaign-mobile-filters-ownership__description'>{ownershipText}</div>
            {config.options.map((option, index) => {
                const isChecked = filterOptions.ownershipType !== '' && option.value === radiobuttonValue;
                return (
                    <div
                        className="campaign-mobile-filters-ownership"
                        role="option"
                        aria-selected={isChecked}
                        key={index}
                    >
                        <div className="campaign-mobile-filters-ownership__item">
                            <MobileFiltersRadiobutton
                                isDisabled={option.isDisabled}
                                isChecked={isChecked}
                                id={fieldId}
                                text={option.value === '' ? ALL_TYPES_TEXT : option.mobileText}
                                value={option.value}
                                onChange={(name, value) => {
                                    onChange(name, value);
                                    trackerService.track(config.clickTrigger);                                    
                                }}
                                setRadiobuttonValue={setRadiobuttonValue}
                                radiobuttonValue={radiobuttonValue}
                                filterOptions={filterOptions}
                            />
                            {(option.value === 'sale') &&
                                <MobileFiltersPrices
                                    name='maxPrice'
                                    value={option.value}
                                    isDisabled={filterOptions.ownershipType !== 'sale'}
                                    filtersConfig={allFilters}
                                    onChange={onChange}
                                />
                            }
                            {(option.value === 'rent') &&
                                <MobileFiltersPrices
                                    name='maxRent'
                                    value={option.value}
                                    isDisabled={filterOptions.ownershipType !== 'rent'}
                                    filtersConfig={allFilters}
                                    onChange={onChange}
                                />
                            }
							{(option.value === 'opso') &&
                                <MobileFiltersPrices
                                    name='maxOpsoPrice'
                                    value={option.value}
                                    isDisabled={filterOptions.ownershipType !== 'opso'}
                                    filtersConfig={allFilters}
                                    onChange={onChange}
                                />
                            }
                        </div>
                    </div>
                );
            })}
        </>
    );
};

MobileFiltersOwnership.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    allFilters: PropTypes.array.isRequired,
};

export default MobileFiltersOwnership;
