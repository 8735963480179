import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentCard from '../../development-card/js/development-card';
import { BACKGROUND_GREY } from '../../dev-plot-card/js/dev-plot-card';

class ParticipatingDevelopmentsCard extends React.PureComponent {
    render() {
        const { list } = this.props;

        return (
            <DevelopmentCard
                {...this.props}
                alwaysVertical={!list}
                background={BACKGROUND_GREY}
                showButton={true}
            />
        );
    }
}

ParticipatingDevelopmentsCard.propTypes = {
    list: PropTypes.bool.isRequired
};

export default ParticipatingDevelopmentsCard;
