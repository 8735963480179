export default class Filter {
    static isFilterValid(object) {
        if (
            object != null &&
            typeof object === 'object' &&
            Filter.hasValidOptions(object.options)
        ) {
            return true;
        }

        return false;
    }

    static hasValidOptions(options) {
        if (
            Array.isArray(options) &&
            options.length > 0 &&
            options[0] != null &&
            typeof options[0] === 'object'
        ) {
            return true;
        }

        return false;
    }

    constructor({ title }) {
        this.title = title;
    }

    get activeOption() {
        return this._activeOption;
    }

    get activeValue() {
        if (this._activeOption != null && this._activeOption.value !== null) {
            return this._activeOption.value;
        }

        return null;
    }

    setInitialActiveOption = () => {
        if (Filter.hasValidOptions(this.options)) {
            this._activeOption = this.options[0];
        } else {
            this._activeOption = null;
        }
    };

    setOptions = ({ ClassType }, options) => {
        if (Array.isArray(options) && options.length > 0) {
            return options.reduce((acc, option) => {
                if (typeof option === 'object' && option !== null) {
                    acc.push(new ClassType(option));
                }
                return acc;
            }, []);
        }
        return null;
    };

    updateActiveOption = (value) => {
        if (Filter.hasValidOptions(this.options)) {
            const newActiveOption = this.options.find((option) => option.value === value);
            this._activeOption = newActiveOption !== undefined ? newActiveOption : null;
        }
        return this;
    };

    updateActiveOptionByLabel = (label) => {
        if (Filter.hasValidOptions(this.options)) {
            const newActiveOption = this.options.find((option) => option.label === label);
            this._activeOption = newActiveOption !== undefined ? newActiveOption : null;
        }
        return this;
    };
}
