import React from 'react';
import classNames from 'classnames';
import BudgetService from './budget-service.jsx';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import PropTypes from 'prop-types';

const isEven = (n) => n % 2 === 0;

export default function BudgetServiceLayout({dispatchGaEvent}) {
    const [state] = useBudgetCalculatorStore();

    return (
        <>
            <div className="budget-service-layout">
                {Array.isArray(state.activeServices) &&
                    state.activeServices.map((service, i) => (
                        <div
                            className={classNames({
                                'budget-service-layout__column': true,
                                'budget-service-layout__column--left': isEven(i),
                                'budget-service-layout__column--right': !isEven(i),
                            })}
                            key={i}
                        >
                            <BudgetService {...service} serviceIndex={i} dispatchGaEvent={dispatchGaEvent} />
                        </div>
                    ))}
            </div>
        </>
    );
}
BudgetServiceLayout.propTypes = {
    dispatchGaEvent: PropTypes.func
};

