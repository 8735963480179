import { createFocusTrap } from 'focus-trap';
import HtmlHelper from './html-helper';
import dcFactory from './dc/dc-factory';
import modalService, { CustomModalService } from './modal-service';
import template from '../html/default-modal.hbs';
import device, { DEVICE_TYPE_DESKTOP } from './device';

export default class Modal {
    constructor(content, options = {}) {
        this.content = content;
        this.isOpened = false;

        this.element = null;
        this.container = null;
        this.isInited = false;

        this.defaultOptions = {
            destroyOnClose: false,
            onClose: null,
            onOpen: null,
        };

        this.options = { ...this.defaultOptions, ...options };

        this.service = options.useCookieOverlay ? new CustomModalService({ useCookieOverlay: options.useCookieOverlay }) : modalService;

        this.focusTrap = null;
        this.focusTrapTimeoutId = null;

        this.close = this.close.bind(this);
        this.onKeydown = this.onKeydown.bind(this);
    }

    init() {
        this.element = this.createElement(this.content);
        dcFactory.init(this.element);
        this.closeElements = [...this.element.querySelectorAll('[data-modal-close]')];
        this.wrapperElement = this.element.querySelector('[data-modal-wrapper]');
        device.subscribeOnDeviceTypeChange(this.onDeviceTypeChanged, true);
    }

    open() {
        if (!this.isInited) {
            this.init();
            this.isInited = true;
        }

        this.service.open(this);
    }

    close() {
        this.service.close(this);
    }

    onOpen() {
        this.addListeners();
        if (this.options.onOpen) {
            this.options.onOpen();
        }
        this.isOpened = true;
        // first animation workaround
        setTimeout(() => {
            // check because might be destroyed
            if (this.isOpened && this.element) {
                this.element.classList.add('is-opened');
                this.element.style['-webkit-overflow-scrolling'] = 'touch';
                // we need 1sec timeout because plugin thinks that nodes are invisible
                this.focusTrapTimeoutId = setTimeout(() => {
                    this.focusTrap = createFocusTrap(this.element, {
                        escapeDeactivates: false,
                        clickOutsideDeactivates: true,
                    });
                    this.focusTrap.activate();
                }, 1000);
            }
        }, 0);
    }

    onClose() {
        this.element.style['-webkit-overflow-scrolling'] = 'auto';
        this.element.classList.remove('is-opened');
        this.element.classList.add('is-closing');
        this.removeListeners();

        if (this.focusTrapTimeoutId) {
            clearTimeout(this.focusTrapTimeoutId);
        }

        if (this.options.onClose) {
            this.options.onClose();
        }
        this.isOpened = false;

        return new Promise((resolve) => {
            setTimeout(() => {
                // check because might be destroyed
                if (this.element) {
                    this.element.classList.remove('is-closing');

                    if (this.focusTrap) {
                        this.focusTrap.deactivate();
                        this.focusTrap = null;
                    }

                    if (this.options.destroyOnClose) {
                        this.destroy();
                    }
                }
                resolve();
            }, 300);
        });
    }

    addListeners() {
        if (this.closeElements) {
            this.closeElements.forEach((el) => el.addEventListener('click', this.close));
        }

        document.addEventListener('keydown', this.onKeydown);
    }

    removeListeners() {
        if (this.closeElements) {
            this.closeElements.forEach((el) => el.removeEventListener('click', this.close));
        }

        document.removeEventListener('keydown', this.onKeydown);
    }

    onKeydown(e) {
        if (this.options.useCookieOverlay) {
            return;
        }

        if (e.keyCode === 27) {
            this.close();
        }
    }

    /**
     * @param {String|HtmlElement|Function} content
     * @return {HtmlElement}
     */
    createElement(content) {
        content = typeof content === 'function' ? content() : content;
        let element = typeof content === 'string' ? HtmlHelper.createElement(content) : content;

        // if we have already created modal element previously we want to find data-modal
        // instead of modal content
        const actualModal = HtmlHelper.getParent(element, '[data-modal]');
        if (actualModal) {
            element = actualModal;
        } else {
            // if current element in not a actual modal node but its content
            const modalElement = HtmlHelper.createElement(template(this.options));
            const modalContentHolder = modalElement.querySelector('[data-modal-content]');
            modalContentHolder.insertAdjacentElement('beforeend', this.getElementToInsert(element));
            element = modalElement;
        }

        if (element.parentNode === null || element.ownerDocument !== document) {
            this.getContainer().insertAdjacentElement('beforeend', element);
        }

        return element;
    }

    /**
     * may be overrided in children classes
     * for example in lightbox
     *
     * @param {*} element
     * @returns element
     * @memberof Modal
     */
    getElementToInsert(element) {
        return element;
    }

    destroy() {
        this.service.close(this);
        if (this.element) {
            dcFactory.destroy(this.element);
            this.element.parentNode.removeChild(this.element);
            this.element = null;
        }
        this.isInited = false;
    }

    getContainer() {
        if (this.container === null) {
            this.container = document.body;
        }

        return this.container;
    }

    getWrapperElement() {
        return this.wrapperElement;
    }

    onDeviceTypeChanged = () => {
        this.setFixedSize();
    };

    setFixedSize() {
        if (this.options.useCookieOverlay) {
            return;
        }

        const { width, height } = this.options;
        let widthFinal = null;
        let heightFinal = null;
        const aspectRatio = height / width;

        if (isNaN(aspectRatio) && typeof width !== 'string') {
            console.error('Width and height should be a numbers.');
            return;
        }
        const closeElement = this.element.querySelector('[data-modal-close]');
        const containerElement = this.element.querySelector('[data-modal-container]');
        let closeWidth = 0;
        let containerPadding = 0;

        if (device.isViewportTypeGe(DEVICE_TYPE_DESKTOP)) {
            closeWidth = window.getComputedStyle(closeElement).width.replace(/px/gi, '') || 30;
            containerPadding =
                window.getComputedStyle(containerElement).paddingLeft.replace(/px/gi, '') || 20;
        } else {
            containerPadding =
                window.getComputedStyle(containerElement).paddingLeft.replace(/px/gi, '') || 10;
        }

        const freeSpace = window.innerWidth - closeWidth - containerPadding * 2;
        if (freeSpace < width) {
            widthFinal = freeSpace;
        } else {
            widthFinal = width;
        }
        heightFinal = Math.floor(widthFinal * aspectRatio);

        const contentElement = this.element.querySelector('[data-modal-content]');
        if (contentElement) {
            contentElement.style.width = widthFinal + 'px';
            contentElement.style.height = heightFinal + 'px';
        } else {
            console.error('There is no content element presented');
        }
    }
}
