import React, { useState } from 'react';
import DevSectionTooltip from './dev-section-tooltip.jsx';

export default function DevSectionIcon({
    children,
    tooltipText,
    onMouseOver,
    onMouseOut,
    ...props
}) {
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [tooltipReference, setTooltipReference] = useState(null);

    return (
        <>
            <DevSectionTooltip
                isVisible={isMouseOver}
                tooltipReference={tooltipReference}
                tooltipText={tooltipText}
            />
            <a
                {...props}
                ref={setTooltipReference}
                onMouseOver={(event) => {
                    if (typeof onMouseOver === 'function') {
                        onMouseOver(event);
                    }
                    setIsMouseOver(true);
                }}
                onMouseOut={(event) => {
                    if (typeof onMouseOut === 'function') {
                        onMouseOut(event);
                    }
                    setIsMouseOver(false);
                }}
            >
                {children}
            </a>
        </>
    );
}
