import React from 'react';
import { withContext } from '../context.jsx';
import SearchDesktopFilters from '../../../listing/js/components/search-desktop-filters';

export default withContext(SearchDesktopFilters, {
    filters: 'filters',
    appliedFilters: 'appliedFilters',
    onFiltersChange: 'onFiltersChange',
    onFiltersReset: 'onFiltersReset',
    onSearch: 'onSearch',
});
