import { createDomain, createApi } from 'effector/compat';
import { AREA_STATUS_COMINGSOON } from './constants.js';
import analyticsService from '@General/js/analytics-service.js';

const GA_EVENT_SITEMAP = 'siteMap';

export const createInteractiveSitePlanApi = (initialState) => {
    const domain = createDomain();
    const filteredFloors = initialState.floors
        ? initialState.floors.map((floor) => {
              const filteredAreas = floor.map.areas.filter(
                  (area) => area.mapPlotStatus && area.mapPlotStatus !== AREA_STATUS_COMINGSOON
              );
              floor.map.areas = filteredAreas;
              return floor;
          })
        : [];
    const $plans = domain.createStore({
        isPopupOpen: false,
        popupArea: null,
        currentFloorIndex: 0,
        ...initialState,
        floors: filteredFloors,
        floor: filteredFloors[0] || null,
    });
    const api = createApi($plans, {
        openPopupEvt: (state, payload) => {
            const subNavEl = document.getElementsByClassName('page-overview__list-item is-selected');
            if(subNavEl.length > 0) {
                const subNavigation = subNavEl[0].innerText;
                analyticsService._send(GA_EVENT_SITEMAP, { subNavigation });
            }

            return {
                ...state,
                isPopupOpen: true,
                popupArea: payload.area,
            };
        },
        closePopupEvt: (state) => {
            return { ...state, isPopupOpen: false, popupArea: null };
        },
        imageLoadedEvt: (state) => {
            return { ...state, isImageLoaded: true };
        },
        resizeEvt: (state) => {
            return { ...state, resizeAmount: state.resizeAmount + 1 };
        },
        changeFloorEvt: (state, payload) => {
            return {
                ...state,
                floor: payload.floor,
                isImageLoaded: false,
                currentFloorIndex: payload.currentFloorIndex,
                isPopupOpen: false,
                popupArea: null,
            };
        },
    });

    return [$plans, api];
};
