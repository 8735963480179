import constants from './constants';

export default class GoogleMarker {
    constructor(id, map, apiMap, position, popupElement = null) {
        this.id = id;
        this.map = map;
        this.apiMap = apiMap;
        this.position = position;
        this.popupElement = popupElement;
        this.onStateChangeCallback = null;
        this.isActive = false;

        this.marker = new google.maps.Marker({
            position: this.position,
            icon: this._getGoogleIcon(),
            map: this.apiMap,
        });

        this.clickListener = this.marker.addListener('click', this._onClick);
    }

    _getGoogleIcon() {
        return {
            url: this.isActive ? this.getIconGreen() : this.getIconGrey(),
            scaledSize: new google.maps.Size(this.getIconWidth(), this.getIconHeight()),
        };
    }

    getPosition() {
        return this.position;
    }

    getApiMarker() {
        return this.marker;
    }

    setPopupElement(element) {
        this.popupElement = element;
    }

    _onClick = async () => {
        await this.activate();
    };

    async activate() {
        await this.map.onMarkerActivate(this.id);
    }

    async handleActivate() {
        if (!this.isActive) {
            await this._handleActivate();
        }
    }

    async _handleActivate() {
        this.isActive = true;
        await this._showPopup();
        this._renderIcon();
        this.invokeOnStateChange();
    }

    reset() {
        if (this.isActive) {
            this.deactivate();
        }
    }

    deactivate() {
        this.map.onMarkerDeactivate(this.id);
    }

    handleDeactivate() {
        if (this.isActive) {
            this._handleDeactivate();
        }
    }

    _handleDeactivate() {
        this.isActive = false;
        this._hidePopup();
        this._renderIcon();
        this.invokeOnStateChange();
    }

    _renderIcon() {
        this.marker.setIcon(this._getGoogleIcon());
    }

    onStateChange(cb) {
        this.onStateChangeCallback = cb;
        return () => {
            this.onStateChangeCallback = null;
        };
    }

    destroy() {
        this.clickListener.remove();
        this.marker.setMap(null);
        this._hidePopup();
        this.marker = null;
        this.onStateChangeCallback = null;
    }

    invokeOnStateChange() {
        if (this.onStateChangeCallback) {
            this.onStateChangeCallback(this.isActive, this);
        }
    }

    getIconWidth() {
        return 33;
    }

    getIconHeight() {
        return 40;
    }

    getIconGreen() {
        throw new Error('Redefine GoogleMarker::getIconGreen method');
    }

    getIconGrey() {
        throw new Error('Redefine GoogleMarker::getIconGrey method');
    }

    async _showPopup() {
        if (this.popupElement) {
            const PopupClass = await this.getPopupClass();
            this.popup = new PopupClass(this.marker.getPosition(), this.popupElement);
            this.popup.setMap(this.apiMap);
            this._focusMarker();
        }
    }

    _focusMarker() {
        setTimeout(() => {
            const targetElement =
                this.popupElement.querySelector('[data-map-content]') || this.popupElement;
            const bounds = new google.maps.LatLngBounds();
            bounds.extend(this.marker.getPosition());

            const additionalPadding = 10;
            const halfWidth = parseInt(targetElement.clientWidth / 2);
            const sidePadding = halfWidth + additionalPadding;
            const elementHeight = targetElement.clientHeight;
            this.apiMap.panToBounds(bounds, {
                bottom: 0,
                left: sidePadding,
                right: sidePadding,
                top: elementHeight + constants.DEFAULT_POPUP_VERTICAL_OFFSET,
            });
        }, 100);
    }

    async _hidePopup() {
        if (this.popup) {
            this.popup.remove();
        }
    }

    async getPopupClass() {
        return await import(/* webpackMode: "eager" */ './google-popup').then(
            ({ default: PopupClass }) => PopupClass
        );
    }
}
