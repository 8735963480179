import AbstractVideo from './abstract-video';
import onScriptLoaded from './loadYoutubeScript';

const STATE_VIDEO_ISNT_STARTED = -1;

export default class YoutubeVideo extends AbstractVideo {
    constructor(options) {
        super(options);

        this.id = options.id;

        /**
         * @type {Promise} - promise of YT.Player instance
         */
        this._playerPromise = null;
        this.checkEagerInit();
    }

    get player() {
        if (this._playerPromise === null) {
            this._playerPromise = new Promise((resolve, reject) => {
                onScriptLoaded(() => {
                    this._createPlayer(resolve);
                });
            });
        }

        return this._playerPromise;
    }

    set player(newPlayerPromise) {
        this._playerPromise = newPlayerPromise;
    }

    _createPlayer(resolve) {
        const targetElement = document.createElement('div');
        this.getPlayerElement().insertAdjacentElement('beforeend', targetElement);
        const player = new YT.Player(targetElement, {
            height: '100%',
            width: '100%',
            videoId: this.id,
            events: {
                onReady: () => {
                    resolve(player);
                    this.emitInit();
                },
                onStateChange: this._onStateChange,
            },
            playerVars: {
                // show related videos only from the same channel
                // https://delete.atlassian.net/browse/MSW-410
                rel: 0,
            }
        });
    }

    checkEagerInit() {
        if (this.isEagerInit) {
            this.init();
        }
    }

    init() {
        this.player.then(() => {
            // just trigger player, do not do anything
        });
    }

    _onStateChange = (e) => {
        const data = e.data;
        switch (data) {
            case STATE_VIDEO_ISNT_STARTED:
                this.emitStart();
                break;
            case YT.PlayerState.PLAYING:
                this.emitPlay();
                break;
            case YT.PlayerState.ENDED:
            case YT.PlayerState.PAUSED:
                this.emitPause();
        }
    };

    async handlePlay() {
        return this.player.then((player) => {
            player.playVideo();
        });
    }

    async handlePause() {
        return this.player.then((player) => {
            player.pauseVideo();
        });
    }

    async getThumbnailUrl() {
        return `https://img.youtube.com/vi/${this.id}/maxresdefault.jpg`;
    }

    getDefaultImageTitle() {
        return 'Youtube video preview';
    }

    getThumbnailOnLoadFunction() {
        return (e) => {
            const currentUrl = e.target.src;
            const isItDefaultYoutubeImage = e.target.naturalWidth <= 120 && e.target.naturalHeight <= 90;
    
            if ( isItDefaultYoutubeImage && currentUrl.includes('maxresdefault')) {
                e.target.src = currentUrl.replace('maxresdefault', '0');
            }
        };
    }
}
