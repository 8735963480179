import 'slick-carousel';
import device from 'general/js/device';

/**
 * This class was introduced because of a bug in slick.
 * Slick relies on window.innerWidth which works strange on Android mobile devices
 * and returns bigger values that it should (for example 1400px in case of 400px width smartphone) (dated 04.03.2019)
 * As a result slick can apply wrong settings if you use «responsive» section in its config because it tries to apply wrong breakpoint
 * This bug can be reproduced if you defer you styles this way: media="print" onload="this.onload = null; this.media = 'all';"
 *
 * This class works as an abstraction on the top of the Slick and provide similar responsiveness
 * but in terms of device types ({@see Device}) instead of pixels
 * Constructor returns the same instance as Slick does, so you can use the same API as if you work with the result of .slick() method
 */
export default class SlickResponsive {
    constructor($element, initialOptions, responsiveOptions, onBreakpointTriggered = null) {
        if (!responsiveOptions) {
            throw new Error('ResponsiveOptions argument is mandatory. Please use .slick() if you don\'t need responsive settings');
        }

        this.initialOptions = initialOptions;
        this.responsiveOptions = responsiveOptions;
        this.onBreakpointTriggered = onBreakpointTriggered;
        this.appliedDeviceType = undefined;

        device.subscribeOnDeviceTypeChange(this._onDeviceTypeChange);
        return this._createInitial($element);
    }

    _createInitial($element) {
        this.appliedDeviceType = this._getMatchedDeviceType();
        const initialOptions = this._getDeviceTypeOptions(this.appliedDeviceType);
        this.carousel = $element.slick(initialOptions);
        return this.carousel;
    }

    _getMatchedDeviceType() {
        let matchedDeviceType = null;
        const deviceTypes = Object.keys(this.responsiveOptions);
        deviceTypes.forEach(type => {
            if (device.isViewportTypeGe(type)) {
                matchedDeviceType = type;
            }
        });

        return matchedDeviceType;
    }

    _getDeviceTypeOptions(deviceType) {
        let options = this.initialOptions;
        if (deviceType !== null) {
            for (let dt in this.responsiveOptions) {
                options = { ...options, ...this.responsiveOptions[dt] };
                if (deviceType === dt) {
                    break;
                }
            }
        }
        return options;
    }

    _onDeviceTypeChange = () => {
        const matchedDeviceType = this._getMatchedDeviceType();
        if (matchedDeviceType !== this.appliedDeviceType) {
            this._applyDeviceType(matchedDeviceType);
        }
    };


    _applyDeviceType(deviceType) {
        this.appliedDeviceType = deviceType;
        let newOptions = this._getDeviceTypeOptions(deviceType);
        this.carousel.slick("setOption", newOptions, true);
        if (this.onBreakpointTriggered) {
            this.onBreakpointTriggered();
        }
    }
}
