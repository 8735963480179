import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import QuickSearch from '@Assets/components/quick-search/js/quick-search';
import recentSearchesService from 'general/js/recent-searches-service';
import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import CampaignSearchDesktopSorting from '@Components/campaign-property-search-sorting/js/desktop/campaign-search-desktop-sorting';

import CampaignDevelopmentResults from './components/campaign-development-search-results';
import CampaignSearchFilters from './containers/campaign-filters';
import CampaignSearchPagination from './components/campaign-search-pagination';
import NoResultsCampaignSearch from './components/no-results-campaign-search';

import { CampaignSearchResultsContext } from './context';
import { initialPayloadState } from './constants';

function CampaignPropertySearch(props) {
    const [isMobile, setIsMobile] = useState(device.isViewportTypeLt(DEVICE_TYPE_TABLET));
    const [propertyPaginationThreshold, setPropertyPaginationThreshold] =
        useState(desktopAndTabletPropertyResultsPagination);
    const [scrollElementRef, setScrollElementRef] = useState(null);
    const [text, setText] = useState('');
    const [searchOffset, setSearchOffset] = useState(0);
    const [filterOptions, setFilterOptions] = useState(initialPayloadState);
    const [responseData, setResponseData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [locationSearchId, setLocationSearchId] = useState(null);
    const [placeId, setPlaceId] = useState(null);
    const scrollRef = React.createRef();
    const [hideRentPrices, setHideRentPrices] = useState(false);
    const [hideBuyPrices, setHideBuyPrices] = useState(false);

    const {
        id,
        filters,
        ctaText,
        searchUrl,
        placeMatchUrl,
        searchSummary,
        placeholderText,
        zeroResultsMessage,
        developmentDescription,
        locationAutocompleteUrl,
        mobilePropertyResultsPagination,
        noPropertiesText,
        paginationSummary,
        noExactMatchesMessage,
        searchBarNoResultsFoundMessage,
        mobileDevelopmentResultsPagination,
        desktopAndTabletPropertyResultsPagination,
        desktopAndTabletDevelopmentResultsPagination,
        loadingResultsText,
        sendMeUpdatesCTAText,
        requestAVisitCTAText,
        waitingListCTAText,
        availableWithUnpublishedPropertiesDevelopmentText,
        comingSoonDevelopmentText,
        fullyReservedDevelopmentText
    } = props.options;

    const predictiveSearchOptions = {
        predictiveSearchUrl: locationAutocompleteUrl,
        placeholderMessage: placeholderText,
        noResultsSuggestionText: searchBarNoResultsFoundMessage,
        noResultsMessage: noExactMatchesMessage,
    };

    const handleResize = () => {
        setTimeout(() => {
            setIsMobile(device.isViewportTypeLt(DEVICE_TYPE_TABLET));
        }, 500);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        setScrollElementRef(scrollRef.current);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) {
            setPropertyPaginationThreshold(mobilePropertyResultsPagination);
        } else {
            setPropertyPaginationThreshold(desktopAndTabletPropertyResultsPagination);
        }
    }, [isMobile]);

    const onTextChange = (newText) => {
        setText(newText);
    };

    const onSuggestionSelect = (suggestion) => {
        try {
            recentSearchesService.add(suggestion.text, suggestion.url);
        } catch (e) {
            console.error('Unable to save selected suggestion', e);
        }

        window.location.href = suggestion.url;
    };

    const getCampaignSearchUrl = (page = searchOffset) => {
        const baseUrl = `${searchUrl}?campaignId=${id}`;
        if (isMobile) {
            return `${baseUrl}&limit=${mobileDevelopmentResultsPagination}&offset=${page}`;
        }
        return `${baseUrl}&limit=${desktopAndTabletDevelopmentResultsPagination}&offset=${page}`;
    };

    const renderButton = () => (
        <button classtype="submit" aria-label={ctaText}>
            <span>{ctaText}</span>
            <svg
                className={'icon campaign-property-search__button-icon'}
                width="21"
                height="20"
                focusable="false"
                role="img"
                aria-hidden="true"
            >
                <use xlinkHref="#icon-search-long" />
            </svg>
        </button>
    );

    const resultsLoader = () => {
        return <div className='campaign-property-search__loading-indicator'>{loadingResultsText}</div>;
    };

    const contextObject = {
        filters,
        isError,
        setIsError,
        responseData,
        setResponseData,
        isLoading,
        setIsLoading,
        desktopAndTabletDevelopmentResultsPagination,
        mobileDevelopmentResultsPagination,
        filterOptions,
        setFilterOptions,
        searchOffset,
        setSearchOffset,
        locationSearchId,
        setLocationSearchId,
        placeId,
        setPlaceId
    };

    const onSearchFiltersCallback = () => {
        setHideBuyPrices(filterOptions.ownershipType === 'rent');
        setHideRentPrices(filterOptions.ownershipType === 'sale');
    };

    return (
        <div id='campaign-property-search__input' ref={scrollRef}>
            <CampaignSearchResultsContext.Provider value={contextObject}>
                <div className='campaign-property-search__input-wrapper'>
                    <QuickSearch
                        text={text}
                        defaultSearchUrl={getCampaignSearchUrl()}
                        campaignSearchUrl={getCampaignSearchUrl()}
                        placeMatchUrl={placeMatchUrl}
                        predictiveSearchOptions={predictiveSearchOptions}
                        placeholderMessage={placeholderText}
                        predictiveSearchUrl={locationAutocompleteUrl}
                        onTextChange={onTextChange}
                        onSuggestionSelect={onSuggestionSelect}
                        renderButton={renderButton}
                        {...props}
                    />
                </div>

                {
                    responseData && <CampaignSearchFilters
                        filtersConfig={filters}
                        isFiltersMobile={isMobile}
                        campaignSearchUrl={getCampaignSearchUrl()}
                        onSearchCallback={onSearchFiltersCallback}
                        endOfStickyRef={scrollRef}
                    />
                }

                {isLoading && <>{resultsLoader()}</>}

                {isError && <NoResultsCampaignSearch zeroResultsMessage={zeroResultsMessage} />}

                {
                    !isError && responseData && (responseData.items?.length > 0
                        ? (<div
                            className={classNames({
                                'campaign-property-search__results': true,
                                'hide-rent-prices': hideRentPrices,
                                'hide-sale-prices': hideBuyPrices
                            })}>
                            <div className='campaign-property-search__top-panel'>
                                <CampaignSearchDesktopSorting
                                    filtersConfig={filters}
                                    campaignSearchUrl={getCampaignSearchUrl()} />
                                <MobilePlotsSummary
                                    searchSummary={searchSummary}
                                    totalPlotsCount={responseData.totalPlotsCount}
                                    totalDevelopmentsCount={responseData.totalDevelopmentsCount}
                                />
                            </div>
                            <CampaignDevelopmentResults
                                developmentDescription={developmentDescription}
                                isLoading={isLoading}
                                zeroResultsMessage={zeroResultsMessage}
                                noPropertiesText={noPropertiesText}
                                developments={responseData.items}
                                sendMeUpdatesCTAText={sendMeUpdatesCTAText}
                                requestAVisitCTAText={requestAVisitCTAText}
                                waitingListCTAText={waitingListCTAText}
                                paginationConfig={responseData.pagination}
                                isMobile={isMobile}
                                paginationThreshold={propertyPaginationThreshold}
                                availableWithUnpublishedPropertiesDevelopmentText={availableWithUnpublishedPropertiesDevelopmentText}
                                comingSoonDevelopmentText={comingSoonDevelopmentText}
                                fullyReservedDevelopmentText={fullyReservedDevelopmentText}
                            />
                            <DevelopmentsSummary
                                paginationSummary={paginationSummary}
                                totalDevelopmentsCount={responseData.totalDevelopmentsCount}
                                pagination={responseData.pagination}
                                desktopAndTabletDevelopmentResultsPagination={desktopAndTabletDevelopmentResultsPagination}
                                mobileDevelopmentResultsPagination={mobileDevelopmentResultsPagination}
                                totalDevelopmentsCountOnPage={responseData.items.length}
                            />
                            {
                                responseData.pagination?.pageCount > 1 &&
                                <CampaignSearchPagination
                                    paginationSettings={responseData.pagination}
                                    scrollElementRef={scrollElementRef}
                                    maxResultsAmountOnPage={isMobile ?
                                        mobileDevelopmentResultsPagination : desktopAndTabletDevelopmentResultsPagination}
                                    getCampaignSearchUrl={getCampaignSearchUrl}
                                />
                            }
                        </div>)
                        : <NoResultsCampaignSearch zeroResultsMessage={zeroResultsMessage} />)
                }
            </CampaignSearchResultsContext.Provider>
        </div>
    );
}

const MobilePlotsSummary = ({
    searchSummary,
    totalPlotsCount,
    totalDevelopmentsCount
}) => {
    const formattedSearchSummary = searchSummary
        .replace('{NumberOfProperties}', `<b>${totalPlotsCount}&nbsp;</b> ${totalPlotsCount === 1 ? 'property' : 'properties'}`)
        .replace('{NumberOfDevelopments}', `<b>&nbsp;${totalDevelopmentsCount}&nbsp;</b> ${totalDevelopmentsCount === 1 ? 'development' : 'developments'}`);
    return (
        <div
            className='campaign-property-search__plots-summary'
            dangerouslySetInnerHTML={{ __html: formattedSearchSummary }}></div>
    );
};

const DevelopmentsSummary = ({
    paginationSummary,
    totalDevelopmentsCount,
    desktopAndTabletDevelopmentResultsPagination,
    mobileDevelopmentResultsPagination,
    pagination,
    totalDevelopmentsCountOnPage
}) => {
    const { currentPage, pageCount } = pagination;

    const getfirstValueOfRange = (paginationValue) => {
        const firstValueOfRange = (currentPage * paginationValue) - (paginationValue - 1);

        return firstValueOfRange;
    };

    const firstValueOfRange = getfirstValueOfRange(desktopAndTabletDevelopmentResultsPagination);
    const firstValueOfMobileRange = getfirstValueOfRange(mobileDevelopmentResultsPagination);

    let pageSummaryMobile = `<b>&nbsp;${firstValueOfMobileRange} - ${currentPage * mobileDevelopmentResultsPagination}</b>&nbsp;`;
    let pageSummaryDesktop = `<b>&nbsp;${firstValueOfRange} - ${currentPage * desktopAndTabletDevelopmentResultsPagination}</b>&nbsp;`;

    if (currentPage === pageCount) {
        pageSummaryDesktop = `<b>&nbsp;${firstValueOfRange} - ${totalDevelopmentsCount}</b>&nbsp;`;

        if (((currentPage - 1) * desktopAndTabletDevelopmentResultsPagination + 1) === totalDevelopmentsCount) {
            pageSummaryDesktop = `<b>&nbsp;${totalDevelopmentsCount}</b>&nbsp;`;
        }

        pageSummaryMobile = `<b>&nbsp;${firstValueOfMobileRange} - ${totalDevelopmentsCount}</b>&nbsp;`;

        if (((currentPage - 1) * mobileDevelopmentResultsPagination + 1) === totalDevelopmentsCount) {
            pageSummaryMobile = `<b>&nbsp;${totalDevelopmentsCount}</b>&nbsp;`;
        }
    }

    if (mobileDevelopmentResultsPagination === 1) {
        pageSummaryMobile = `<b>&nbsp;${currentPage}</b>&nbsp;`;
    }

    if (desktopAndTabletDevelopmentResultsPagination === 1) {
        pageSummaryDesktop = `<b>&nbsp;${currentPage}</b>&nbsp;`;
    }

    const formattedPaginationSummary = paginationSummary
        .replace('{PageSummary}', totalDevelopmentsCountOnPage === mobileDevelopmentResultsPagination
            ? pageSummaryMobile : pageSummaryDesktop)
        .replace('{NumberOfDevelopments}', `<b>&nbsp;${totalDevelopmentsCount}&nbsp;</b>`);
    return (
        <div
            className='campaign-search-pagination__developments-summary'
            dangerouslySetInnerHTML={{ __html: formattedPaginationSummary }}></div>
    );
};

MobilePlotsSummary.propTypes = {
    searchSummary: PropTypes.string.isRequired,
    totalPlotsCount: PropTypes.number.isRequired,
    pagination: PropTypes.object.isRequired,
    totalDevelopmentsCount: PropTypes.number.isRequired,
};

DevelopmentsSummary.propTypes = {
    paginationSummary: PropTypes.string.isRequired,
    pagination: PropTypes.object.isRequired,
    totalDevelopmentsCount: PropTypes.number.isRequired,
    desktopAndTabletDevelopmentResultsPagination: PropTypes.number.isRequired,
    mobileDevelopmentResultsPagination: PropTypes.number.isRequired,
    totalDevelopmentsCountOnPage: PropTypes.number.isRequired,
};

CampaignPropertySearch.propTypes = {
    paginationSummary: PropTypes.string.isRequired,
    searchSummary: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    placeholderText: PropTypes.string.isRequired,
    locationAutocompleteUrl: PropTypes.string.isRequired,
    zeroResultsMessage: PropTypes.string.isRequired,
    desktopAndTabletDevelopmentResultsPagination: PropTypes.number.isRequired,
    desktopAndTabletPropertyResultsPagination: PropTypes.number.isRequired,
    mobileDevelopmentResultsPagination: PropTypes.number.isRequired,
    mobilePropertyResultsPagination: PropTypes.number.isRequired,
    filters: PropTypes.array.isRequired,
    noExactMatchesMessage: PropTypes.string,
    searchBarNoResultsFoundMessage: PropTypes.string,
    noPropertiesText: PropTypes.string,
    loadingResultsText: PropTypes.string,
    sendMeUpdatesCTAText: PropTypes.string,
    requestAVisitCTAText: PropTypes.string,
    waitingListCTAText: PropTypes.string,
    developmentDescription: PropTypes.string,
    availableWithUnpublishedPropertiesDevelopmentText: PropTypes.string,
    comingSoonDevelopmentText: PropTypes.string,
    fullyReservedDevelopmentText: PropTypes.string,
};

export default CampaignPropertySearch;
