import React from 'react';
import PropTypes from 'prop-types';
import analyticsService from 'general/js/analytics-service';
import UrlHelper from 'general/js/url-helper';
import { DevelopmentSearchContext } from './context';
import locationFiltersListing from '../../listing/js/location-filters-listing';
import DevelopmentsSearchControlsSection from './containers/developments-search-controls-section';
import DevelopmentsSearchContent from './containers/developments-search-content';

class DevelopmentsSearch extends React.Component {
    getContextValue() {
        const {
            options,
            filtersConfig,
            tabsMode,
            isFiltersMobile,
            overlayPreloader,
            isInitialRequestFulfilled,
            filters,
            appliedFilters,
            items,
            data,
            pagination,
            isLoading,
            onFiltersChange,
            onFiltersApply,
            onFiltersReset,
            onSearch,
        } = this.props;

        const groups = items;
        const flatItems = groups.reduce((res, group) => [...res, ...group.items], []);
        // get from current response, otherwise from initial config
        const hasExactMatches =
            'hasExactMatches' in data ? data.hasExactMatches : options.hasExactMatches;

        return {
            groups,
            items: flatItems,
            isInitialRequestFulfilled,
            hasExactMatches,
            onFiltersChange,
            onFiltersApply,
            onFiltersReset,
            onSearch,
            filters,
            appliedFilters,
            filtersConfig: data.filters || filtersConfig,
            mapConfig: options.mapConfig,
            pagination,
            isLoading,
            tabsMode,
            isFiltersMobile,
            overlayPreloader,
            zeroResults: options.zeroResults,
            noExactMatches: options.noExactMatches,
        };
    }

    handleFiltersApply(appliedFilters) {
        const virtualUrl = this.getVirtualUrl(appliedFilters);
        analyticsService.sendVirtualUrl(virtualUrl);
    }

    getVirtualUrl(filters) {
        const { analyticsOptions, locationName } = this.props;
        const { locationParameter, path, filtersParameters } = analyticsOptions;

        const stringFilters = Object.keys(filters).reduce((result, key) => {
            if (key in filtersParameters) {
                const originalValue = filters[key];
                let resultValue = originalValue;
                if (Array.isArray(originalValue)) {
                    resultValue = originalValue.join(',');
                }
                const searchKey = filtersParameters[key];
                result[searchKey] = resultValue;
            }

            return result;
        }, {});

        // add additional parameter which is passed from server
        const resultFilters = { [locationParameter]: locationName, ...stringFilters };

        return UrlHelper.getUrlByParams(path, resultFilters);
    }

    render() {
        const {
            tabsMode,
            options,
            pagination,
            isInitialRequestFulfilled,
            isLoading,
            overlayPreloader,
        } = this.props;
        return (
            <DevelopmentSearchContext.Provider value={this.getContextValue()}>
                <div className="developments-search">
                    <DevelopmentsSearchControlsSection />
                    <DevelopmentsSearchContent
                        tabsMode={tabsMode}
                        mapConfig={options.mapConfig}
                        totalCount={pagination.totalCount}
                        isInitialRequestFulfilled={isInitialRequestFulfilled}
                        isLoading={isLoading}
                        overlayPreloader={overlayPreloader}
                    />
                </div>
            </DevelopmentSearchContext.Provider>
        );
    }
}

DevelopmentsSearch.defaultProps = {
    data: {},
};

DevelopmentsSearch.propTypes = {
    options: PropTypes.shape({
        mapConfig: PropTypes.object.isRequired,
        zeroResults: PropTypes.object.isRequired,
        hasExactMatches: PropTypes.bool,
        noExactMatches: PropTypes.object.isRequired,
    }),
    filtersConfig: PropTypes.array.isRequired,
    tabsMode: PropTypes.bool.isRequired,
    isFiltersMobile: PropTypes.bool.isRequired,
    overlayPreloader: PropTypes.bool.isRequired,
    locationName: PropTypes.string.isRequired,
    analyticsOptions: PropTypes.shape({
        locationParameter: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        filtersParameters: PropTypes.object.isRequired,
    }).isRequired,
    // HOC
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    onFiltersReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default locationFiltersListing(DevelopmentsSearch, { minLoadingTime: 400 });
