import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import Checkbox from '@Components/checkbox-v2/js/checkbox-v2.jsx';
import Radiobutton from '@Components/radiobutton-v2/js/radiobutton-v2.jsx';
import { CONTROL_TYPE_CHECKLIST, CONTROL_TYPE_RADIOGROUP } from '@Components/campaign-property-search/js/constants.js';
import trackerService from '@General/js/tracker-service.js'

const DropdownChecklist = (props) => {
    const triggerRef = React.createRef();
    const fieldId = uniqueId('filter-field-');

    const onCheckboxChange = (optionName, newValue) => {
        props.onChange(optionName, newValue);
    };

    const onRadioChange = (changedCheckboxValue) => {
        const newValue = changedCheckboxValue;
        props.onChange(newValue);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        props.onApply();
    };

    const onKeyDown = (e) => {
        if (e.which === 27) {
            props.onClose();
            if (props.isActive) {
                triggerRef.current.focus();
            }
        }
    };

    const onButtonClick = () => {
        props.onClick();
    };

    const {
        label,
        options,
        type,
        value,
        isActive,
        isHidden,
        isDisabled,
        config,
    } = props;

    const getChecklistValues = () => {
        if (config.type === CONTROL_TYPE_CHECKLIST) {
            const checkedValuesCount = options.filter((option) => {
                return value.includes(option.value) && option.value !== '';
            }).length;
            return checkedValuesCount;
        }
    };

    const getRadioValue = () => {
        if (config.type === CONTROL_TYPE_RADIOGROUP) {
            const checkedRadioValueObject = config.options.find((option) => value === option.value);
            return checkedRadioValueObject;
        }
    };

    const checkedValuesCount = getChecklistValues();
    const checkedValuesCountShow =
        type === CONTROL_TYPE_CHECKLIST ? `(${checkedValuesCount})` : null;

    const checkedValue = getRadioValue();
    const labelText =
        type === CONTROL_TYPE_RADIOGROUP && value ? checkedValue.text : label;

    return (
        <div
            onKeyDown={onKeyDown}
            className={`campaign-search-filters__control ${isActive ? 'is-active' : ''} ${isHidden ? 'is-hidden' : ''}`}
        >
            <button
                ref={triggerRef}
                className={classNames({
                    'campaign-search-filters__control-main': true,
                    'campaign-search-filters__control-main--disabled': isDisabled,
                })}
                aria-label={`filter ${label}`}
                aria-haspopup="listbox"
                aria-expanded={isActive}
                onClick={() => {
                    if (!isDisabled) {
                        onButtonClick();
                        trackerService.track(config.clickTrigger);
                    }
                }}
            >
                <div className="campaign-search-filters__control-label">
                    {labelText} {checkedValuesCountShow}
                </div>
                <svg
                    width="13"
                    height="8"
                    className="icon campaign-search-filters__control-arrow"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                >
                    <use xlinkHref="#icon-arrow-down" />
                </svg>
            </button>
            <div className="campaign-search-filters__control-dropdown">
                <form onSubmit={onSubmit}>
                    <ul className="campaign-search-filters__control-checklist" role="listbox">
                        {options.map((option, index) => {
                            const isRadioChecked = option.value === value;
                            if (type === CONTROL_TYPE_RADIOGROUP) {
                                return (
                                    <li
                                        className="campaign-search-filters__control-radiolist-item"
                                        role="option"
                                        aria-selected={isRadioChecked}
                                        key={index}
                                    >
                                        <Radiobutton
                                            isDisabled={option.isDisabled}
                                            isChecked={isRadioChecked}
                                            id={fieldId}
                                            text={option.text}
                                            value={option.value}
                                            onChange={(e) =>
                                                onRadioChange(
                                                    option.value,
                                                    e.target.checked
                                                )
                                            }
                                        />
                                    </li>
                                );
                            }

                            return (
                                <li
                                    className="campaign-search-filters__control-checklist-item"
                                    role="option"
                                    key={index}
                                >
                                    <Checkbox
                                        isDisabled={option.isDisabled}
                                        text={option.text}
                                        value={option.value}
                                        onChange={(e) => onCheckboxChange(e.target.value, option.value)}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </form>
            </div>
        </div>
    );
};

DropdownChecklist.propTypes = {
    isClickedInside: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    type: PropTypes.array.isRequired,
    // value: PropTypes.array.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isHidden: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
};

export default DropdownChecklist;
