import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { withSearchMobileFiltersContext } from '../search-mobile-filters-context.js';
import MobileFiltersRadiobutton from './mobile-filters-radiobutton.jsx';
import MobileFiltersPrices from './mobile-filters-prices.jsx';
import trackerService from '@General/js/tracker-service.js'

class MobileFiltersOwnership extends React.Component {
    constructor(props) {
        super(props);

        this.fieldId = uniqueId('filter-field-');
    }

    onRadioChange = (changedCheckboxValue) => {
        const filtersResults = {
            [this.props.config.name]: changedCheckboxValue,
        };

        this.props.config.options
            .filter((option) => option.value !== changedCheckboxValue)
            .reduce((acc, option) => [...acc, ...option.dependentFilters], [])
            .forEach((filterName) => {
                filtersResults[filterName] = '';
            });

        this.props.onFiltersChange(filtersResults);
    };

    render() {
        const { config, value } = this.props;

        return (
            <>
                {config.options.map((option, index) => {
                    const isChecked = option.value === value;

                    return (
                        <div
                            className="mobile-filters-ownership"
                            role="option"
                            aria-selected={isChecked}
                            key={index}
                        >
                            <div className="mobile-filters-ownership__item">
                                <MobileFiltersRadiobutton
                                    isDisabled={option.isDisabled}
                                    isChecked={isChecked}
                                    id={this.fieldId}
                                    text={option.mobileText}
                                    gtmSelector="ExplorePropertiesDropdownCheckbox"
                                    value={option.value}
                                    onChange={() => {
                                        this.onRadioChange(option.value);
                                        trackerService.track(config.clickTrigger);
                                    }}
                                />
                            </div>
                            {option.dependentFilters.map((filter, indexNum) => (
                                <div className="mobile-filters-ownership__item" key={indexNum}>
                                    <MobileFiltersPrices
                                        category={filter}
                                        isDisabled={!isChecked}
                                    />
                                </div>
                            ))}
                        </div>
                    );
                })}
            </>
        );
    }
}

MobileFiltersOwnership.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
};

export default withSearchMobileFiltersContext(MobileFiltersOwnership, {
    onFiltersChange: 'onFiltersChange',
});
