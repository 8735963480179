import React from 'react';
import PropTypes from 'prop-types';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import BudgetListBedroom from '../components/budget-list-bedroom.jsx';

export default function BudgetBedroom({ filter, dispatchGaEvent }) {
    const [, dispatch] = useBudgetCalculatorStore();
    const { options, title, activeOption } = filter;
    const toggleBedroomAmount = (label) => {
        dispatch({
            type: 'TOGGLE_BEDROOM_AMOUNT',
            label,
        });
    };

    return (
        <BudgetListBedroom
            className="budget-list--mt-20"
            radios={options}
            title={title}
            changeCallback={toggleBedroomAmount}
            activeLabel={activeOption.label}
            dispatchGaEvent={dispatchGaEvent}
        />
    );
}

BudgetBedroom.propTypes = {
    filter: PropTypes.any,
    dispatchGaEvent: PropTypes.func
};
