import GoogleMarker from '../google-marker';
import pinGrey from '../../img/pin-development-gray.svg';
import pinGreen from '../../img/pin-development-green.svg';

export default class GoogleMarkerDevelopment extends GoogleMarker {
    getIconGrey() {
        return pinGrey;
    }

    getIconGreen() {
        return pinGreen;
    }

    getIconWidth() {
        return 36;
    }

    getIconHeight() {
        return 60;
    }
}
