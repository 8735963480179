import React from 'react';
import PropertiesSearchList from './properties-search-list';
import PropertiesSearchLoadMoreButton from './properties-search-load-more-button';

export default class PropertiesSearchResults extends React.PureComponent {
    render() {
        return (
            <div className="properties-search__results">
                <PropertiesSearchList/>
                <PropertiesSearchLoadMoreButton/>
            </div>
        );
    }
}
