import React from 'react';
import PropTypes from 'prop-types';
import ParticipatingDevelopmentsSearchCard
    from '../components/participating-developments-search-card';

export default class ParticipatingDevelopmentsSearchList extends React.PureComponent {
    render() {
        const { items } = this.props;

        return (
            <ul className="participating-developments-list">
                {items.map(item => (
                    <li key={item.id} className="participating-developments-list__item">
                        <ParticipatingDevelopmentsSearchCard item={item}/>
                    </li>
                ))}
            </ul>
        );
    }
}

ParticipatingDevelopmentsSearchList.propTypes = {
    items: PropTypes.array.isRequired
};
