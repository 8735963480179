import React from 'react';
import PropTypes from 'prop-types';
import { CONTROL_TYPE_SELECT, CONTROL_TYPE_CHECKLIST, CONTROL_TYPE_RADIOGROUP } from '../../../listing-v3/js/constants';
import SelectedFilterChecklist from './selected-filter-checklist';
import SelectedFilterRadiobutton from './selected-filter-radiobutton';

class SelectedFilters extends React.PureComponent {

    onFiltersChange = (filter) => {
        const { onFiltersApply } = this.props;

        // second argument stands for debounce
        onFiltersApply(filter, true);
    };

    renderFilter(filterConfig) {
        const { filters } = this.props;

        switch (filterConfig.type) {
            case CONTROL_TYPE_CHECKLIST:
                return (
                    <SelectedFilterChecklist
                        key={filterConfig.name}
                        filterValue={filters[filterConfig.name]}
                        filterConfig={filterConfig}
                        onFiltersChange={this.onFiltersChange}/>
                );
            case CONTROL_TYPE_RADIOGROUP:
                return (
                    <SelectedFilterRadiobutton
                        key={filterConfig.name}
                        filterValue={filters[filterConfig.name]}
                        filterConfig={filterConfig}
                        onFiltersChange={this.onFiltersChange}/>
                );
            default:
                throw new Error('unknown control type');
        }
    }

    render() {
        const { selectedFiltersConfigs } = this.props;

        return (
            <React.Fragment>
                {selectedFiltersConfigs.map((filterConfig) => this.renderFilter(filterConfig))}
            </React.Fragment>
        );
    }
}

SelectedFilters.propTypes = {
    selectedFiltersConfigs: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired
};

export default SelectedFilters;
