import React from 'react';
import PropTypes from 'prop-types';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class DevPaginationText extends React.Component {
    getCurrentPropertiesPosition = () => {
        const { offset, totalCount } = this.props.pagination;

        if (totalCount === 0) {
            return '0-0';
        }

        const start = offset + 1;
        const end = start + constants.MAX_PROPS_ON_PAGE - 1;

        if (end > totalCount) {
            return start + '-' + totalCount;
        }

        return start + '-' + end;
    };

    getTotalNumber = () => {
        return this.props.pagination.totalCount;
    };

    getMobileText = () => {
        const { activeTab } = this.props;
        const totalNumber = this.getTotalNumber();
        const propertyWord = totalNumber === 1 ? 'property' : 'properties';

        return `${totalNumber} ${propertyWord} ${activeTab.textName}`;
    };

    getDesktopText = () => {
        const { activeTab, isExpanded } = this.props;
        const showingNumber = this.getCurrentPropertiesPosition();
        const totalNumber = this.getTotalNumber();
        const startingString = isExpanded ? `Showing ${showingNumber} of ` : '';
        const propertyWord = totalNumber === 1 ? 'property' : 'properties';

        return `${startingString}${totalNumber} ${propertyWord} ${activeTab.textName} matching your search criteria`;
    };

    render() {
        return (
            <span className="dev-pagination-text">
                {this.props.isOnMobileViewport ? this.getMobileText() : this.getDesktopText()}
            </span>
        );
    }
}

DevPaginationText.propTypes = {
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        plotsId: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string,
    }),
    currentProperties: PropTypes.arrayOf(
        PropTypes.shape({
            plotNumber: PropTypes.string,
            numberOfBedrooms: PropTypes.number,
            salesPrice: PropTypes.string,
            floor: PropTypes.string,
            status: PropTypes.string,
            area: PropTypes.string,
            buttonUrl: PropTypes.string,
        })
    ),
    pagination: PropTypes.shape({
        offset: PropTypes.number,
        totalCount: PropTypes.number,
    }),
    isExpanded: PropTypes.bool,
    isOnMobileViewport: PropTypes.bool,
    saleTotalCount: PropTypes.number,
    rentTotalCount: PropTypes.number,
};

export default withContext(DevPaginationText, {
    isOnMobileViewport: 'isOnMobileViewport',
    currentProperties: 'currentProperties',
    activeTab: 'activeTab',
    saleTotalCount: 'saleTotalCount',
    rentTotalCount: 'rentTotalCount',
    pagination: 'pagination',
});
