import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Range } from 'react-range';

import { CampaignSearchResultsContext } from '@Components/campaign-property-search/js/context';

const STEP = 1;
const DEFAULT_VALUE = 30;

const MobileFilterRadius = ({
    value,
    config,
    onChange,
}) => {
    const { locationSearchId, filterOptions, setFilterOptions } = useContext(CampaignSearchResultsContext);
    let legalValues = [];
    let selectedValue = DEFAULT_VALUE;
    config.options.forEach(option => {
        legalValues.push(parseInt(option.value));
        if (filterOptions.searchRadius == option.value) {
            selectedValue = parseInt(option.value);
        }
    });

    const minValue = legalValues[0];
    const maxValue = legalValues[legalValues.length - 1];
    const isDisabled = !locationSearchId;
    const [currentValue, setCurrentValue] = useState(selectedValue);

    const handleRadiusChange = (checkedValue) => {
        const chosenOption = config.options.find((option) => option.value === checkedValue);
        setFilterOptions({
            ...filterOptions,
            searchRadius: chosenOption.mobileText
        });
        onChange('searchRadius', checkedValue);
    };

    const changeRangeHandler = ([value]) => {
        // easier for user to click on a point if we check values around the click area
        const possibleValue = [value - 1, value, value + 1].find((elem) =>
            legalValues.includes(elem)
        );

        if (possibleValue !== undefined) {
            setCurrentValue(possibleValue);
            handleRadiusChange(currentValue.toString());
        }
    };

    return (
        <div className="mobile-filter-radius">
            <Range
                step={STEP}
                min={minValue}
                max={maxValue}
                values={legalValues}
                disabled={true}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        className="mobile-filter-radius__range mobile-filter-radius__range--absolute"
                        style={{
                            ...props.style,
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ index, props }) => (
                    <div
                        {...props}
                        className="mobile-filter-radius__mark"
                        style={{
                            ...props.style,
                        }}
                    >
                        <div
                            className={cn({
                                'mobile-filter-radius__label': true,
                                'mobile-filter-radius__label--highlighted':
                                    !isDisabled &&
                                    legalValues[index] === currentValue,
                            })}
                        >
                            {legalValues[index]}
                        </div>
                    </div>
                )}
            />
            <Range
                step={STEP}
                min={minValue}
                max={maxValue}
                disabled={isDisabled}
                values={isDisabled ? [] : [currentValue]}
                onChange={changeRangeHandler}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        className="mobile-filter-radius__range"
                        style={{
                            ...props.style,
                        }}
                    >
                        <div
                            className="mobile-filter-radius__track"
                            style={{
                                ...props.style,
                            }}
                        ></div>
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        className="mobile-filter-radius__dot"
                        style={{
                            ...props.style,
                        }}
                    >
                        <div className="mobile-filter-radius__dot-line"></div>
                        <div className="mobile-filter-radius__dot-line"></div>
                        <div className="mobile-filter-radius__dot-line"></div>
                    </div>
                )}
            />
        </div>
    );
};

MobileFilterRadius.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MobileFilterRadius;
