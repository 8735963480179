import React from 'react';
import PropTypes from 'prop-types';

export default class DevelopmentsSearchSkeleton extends React.PureComponent {
    getStateClasses() {
        const { isVisible } = this.props;
        return `${isVisible ? 'is-visible' : ''}`;
    }

    render() {
        return (
            <div className={`development-search-skeleton ${this.getStateClasses()}`}>
                <div className="development-search-skeleton__group"/>
                <div className="development-search-skeleton__group"/>
                <div className="development-search-skeleton__group"/>
                <div className="development-search-skeleton__items"/>
            </div>
        );
    }
}

DevelopmentsSearchSkeleton.propTypes = {
    isVisible: PropTypes.bool.isRequired
};
