import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import VideoWithRelatedVideos from './video-with-related-videos';

export default class VideoWithRelatedVideosComponent extends DcBaseComponent {
    static getNamespace() {
        return 'video-with-related-videos';
    }

    onInit() {
        ReactDOM.render(<VideoWithRelatedVideos {...this.options} />, this.element);
    }


    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
