import React from 'react';
import PropTypes from 'prop-types';
import FeaturedDevelopments from '../../../featured-developments/featured-developments-all-ownership';
import DevelopmentsSearchResults from './developments-search-results';
import CirclesPreloader from '../../../circles-preloader/js/circles-preloader';
import DevelopmentsSearchMap from './developments-search-map';
import DevelopmentsSearchInfo from './developments-search-info';
import TabTrigger from '../components/tab-trigger';
import TabContent from '../components/tab-content';
import StickyColumn from '../components/sticky-column';
import DevelopmentsSearchZeroResults from './developments-search-zero-results';
import DevelopmentsSearchSkeleton from '../components/developments-search-skeleton';
import DevelopmentsSearchOverlayPreloader from '../components/developments-search-overlay-preloader';
import DevelopmentsSearchSorting from '../components/developments-search-sorting';

const TAB_LIST = 'list';
const TAB_MAP = 'map';

class DevelopmentsSearchContent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentTabId: TAB_LIST,
            markers: {},
            isMapDisabled: false,
            isMapDelayed: true,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ isMapDelayed: false });
        }, 1000);
    }

    onTabLinkClick = (id) => {
        this.setState({ currentTabId: id });
    };

    isTabsVisible() {
        return this.props.tabsMode;
    }

    onCardScroll = (isFinished, cb) => {
        this.setState({ isMapDisabled: !isFinished }, cb);
    };

    onMarkersUpdate = (markers) => {
        this.setState({ markers });
    };

    getTabActiveState = (tabId) => tabId === this.state.currentTabId;

    isResultEmpty() {
        const { totalCount, isInitialRequestFulfilled } = this.props;
        return isInitialRequestFulfilled && totalCount === 0;
    }

    render() {
        const { currentTabId, markers } = this.state;
        const {
            tabsMode,
            isLoading,
            overlayPreloader,
            filtersConfig,
            filters,
            onFiltersApply,
            reSales,
            locationName,
            featuredComponent,
        } = this.props;
        const sortConfig = filtersConfig.find((filter) => filter.isSort);

        return (
            <div className="developments-search__content">
                {overlayPreloader && <DevelopmentsSearchOverlayPreloader isVisible={isLoading} />}
                <div className="section">
                    <div className="container">
                        {this.isTabsVisible() ? (
                            <div className="developments-search__mobile-top-v2">
                                <div className="developments-search__mobile-info-v2">
                                    <DevelopmentsSearchInfo />
                                    {sortConfig ? (
                                        <DevelopmentsSearchSorting
                                            sortConfig={sortConfig}
                                            filters={filters}
                                            onFiltersApply={onFiltersApply}
                                        />
                                    ) : null}
                                </div>
                                <div className="developments-search__tabs-buttons">
                                    <TabTrigger
                                        id={TAB_LIST}
                                        onClick={this.onTabLinkClick}
                                        isActive={this.getTabActiveState(TAB_LIST)}
                                    >
                                        <span>List</span>
                                        <svg
                                            className="icon developments-search__tabs-icon"
                                            width="14"
                                            height="14"
                                            focusable="false"
                                            role="img"
                                            aria-hidden="true"
                                        >
                                            <use xlinkHref="#icon-hamburger" />
                                        </svg>
                                    </TabTrigger>
                                    {this.props.mapConfig.enabled && (
                                        <TabTrigger
                                            id={TAB_MAP}
                                            onClick={this.onTabLinkClick}
                                            isActive={this.getTabActiveState(TAB_MAP)}
                                        >
                                            <span>Map</span>
                                            <svg
                                                className="icon developments-search__tabs-icon"
                                                width="14"
                                                height="14"
                                                focusable="false"
                                                role="img"
                                                aria-hidden="true"
                                            >
                                                <use xlinkHref="#icon-pin" />
                                            </svg>
                                        </TabTrigger>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className="developments-search-layout">
                            <div className="developments-search-layout__content">
                                {!this.isTabsVisible() && <div className="developments-search-layout__intro">
                                    <div className="developments-search-layout__info-v2">
                                        <DevelopmentsSearchInfo />
                                    </div>
                                    <div className="developments-search-layout__sorting">
                                        {sortConfig ? (
                                            <DevelopmentsSearchSorting
                                                sortConfig={sortConfig}
                                                filters={filters}
                                                onFiltersApply={onFiltersApply}
                                            />
                                        ) : null}
                                    </div>
                                </div>}
                                <FeaturedDevelopments />
                                <TabContent
                                    id={TAB_LIST}
                                    mixinClass={'developments-search__results'}
                                    currentId={currentTabId}
                                    tabsMode={tabsMode}
                                >
                                    <DevelopmentsSearchSkeleton isVisible={isLoading} />
                                    {!this.isResultEmpty() ? (
                                        <DevelopmentsSearchResults
                                            markers={markers}
                                            onCardScroll={this.onCardScroll}
                                        />
                                    ) : (
                                        <DevelopmentsSearchZeroResults reSales={reSales} locationName={locationName} filters={filters} />
                                    )}
                                </TabContent>
                            </div>
                            <div className="developments-search-layout__sidebar">
                                {this.props.mapConfig.enabled && (
                                    <StickyColumn>
                                        <TabContent
                                            id={TAB_MAP}
                                            mixinClass={'developments-search__map-wrapper'}
                                            currentId={currentTabId}
                                            tabsMode={tabsMode}
                                        >
                                            {this.state.isMapDelayed ? (
                                                <div className="development-search-overlay-preloader__inner development-search-overlay-preloader__inner--centered">
                                                    <CirclesPreloader
                                                        isVisible={true}
                                                        caption={'Loading map'}
                                                    />
                                                </div>
                                            ) : (
                                                <DevelopmentsSearchMap
                                                    onMarkersUpdate={this.onMarkersUpdate}
                                                    tabsMode={tabsMode}
                                                    isDisabled={this.state.isMapDisabled}
                                                />
                                            )}
                                        </TabContent>
                                    </StickyColumn>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DevelopmentsSearchContent.propTypes = {
    tabsMode: PropTypes.bool.isRequired,
    mapConfig: PropTypes.object.isRequired,
    totalCount: PropTypes.number.isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    overlayPreloader: PropTypes.bool.isRequired,
    filtersConfig: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    reSales: PropTypes.object,
    locationName: PropTypes.string,
    featuredComponent: PropTypes.object,
};

export default DevelopmentsSearchContent;
