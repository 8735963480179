import Area from './area';

export default class Helper {
    constructor(node, x, y, action) {
        this._el = document.createElementNS(Area.SVG_NS, 'rect');

        this._el.classList.add(Helper.CLASS_NAME);
        this._el.setAttribute('height', Helper.SIZE);
        this._el.setAttribute('width', Helper.SIZE);
        this._el.setAttribute('x', x + Helper.OFFSET);
        this._el.setAttribute('y', y + Helper.OFFSET);

        node.appendChild(this._el);

        this._el.action = action;
        this._el.classList.add(Helper.ACTIONS_TO_CURSORS[action]);
    }

    setCoords = (x, y) => {
        this._el.setAttribute('x', x + Helper.OFFSET);
        this._el.setAttribute('y', y + Helper.OFFSET);
        return this;
    };

    setId = (id) => {
        this._el.n = id;
        return this;
    };

    static SIZE = 5;

    static OFFSET = -Math.ceil(Helper.SIZE / 2);

    static CLASS_NAME = 'helper';

    static ACTIONS_TO_CURSORS = {
        move: 'move',
        editLeft: 'e-resize',
        editRight: 'w-resize',
        editTop: 'n-resize',
        editBottom: 's-resize',
        editTopLeft: 'nw-resize',
        editTopRight: 'ne-resize',
        editBottomLeft: 'sw-resize',
        editBottomRight: 'se-resize',
        movePoint: 'pointer'
    };
}
