import React from 'react';
import PropTypes from 'prop-types';
import SelectedFilterValue from './selected-filter-value';

import { FILTER_NAME_OWNERSHIP, FILTER_OWNERSHIP_SALE } from '../../../listing-v3/js/constants';

class SelectedFilterRadiobutton extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    onValueRemove = () => {
        const { filterConfig } = this.props;

        this.props.onFiltersChange({ [filterConfig.name]: filterConfig.defaultValue });
    };

    render() {
        const { filterConfig, filterValue } = this.props;
        const selectedOptions = filterConfig.options.filter(
            (option) => filterValue === option.value
        );

        return (
            <React.Fragment>
                {selectedOptions.map((option) => (
                    <SelectedFilterValue
                        filterLabel={filterConfig.label}
                        key={option.value}
                        selectedText={option.selectedText}
                        text={option.mobileActiveText}
                        value={option.value}
                        onClick={this.onValueRemove}
                    />
                ))}
            </React.Fragment>
        );
    }
}

SelectedFilterRadiobutton.propTypes = {
    filterValue: PropTypes.array.isRequired,
    filterConfig: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
};

export default SelectedFilterRadiobutton;
