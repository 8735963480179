import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RecipeSection from './recipe-ingredients/recipe-section';

const RecipePreparation = ({
    cookTitle,
    cookValue,
    dietaryLabels,
    difficultyIcon,
    difficultyTitle,
    difficultyValue,
    preparationAndCookIcon,
    preparationTitle,
    preparationValue,
    servesIcon,
    servesTitle,
    servesValue,
    ingredientSections,
    methodSections,
}) => {
    const getCroppedLabel = (label) => {
        if (label.length > 2) {
            return label.slice(0, 2);
        }

        return label;
    };

    return (
        <>
            <div className="recipe-preparation">
                <div
                    className={cn('recipe-preparation__top', {
                        'no-diatry-labels': dietaryLabels.length === 0,
                    })}
                >
                    {(preparationValue || cookValue) && (
                        <div className="recipe-preparation__item">
                            {preparationAndCookIcon && (
                                <img
                                    className="recipe-preparation__icon"
                                    src={preparationAndCookIcon.src}
                                    alt={preparationAndCookIcon.alt}
                                />
                            )}
                            <div>
                                {preparationTitle && preparationValue && (
                                    <span className="recipe-preparation__title">
                                        {preparationTitle}
                                    </span>
                                )}
                                {preparationValue && <span>{preparationValue}</span>}
                                <span className="recipe-preparation__item--no-icon">
                                    {cookTitle && cookValue && (
                                        <span className="recipe-preparation__title">
                                            {cookTitle}
                                        </span>
                                    )}
                                    {cookValue && <span>{cookValue}</span>}
                                </span>
                            </div>
                        </div>
                    )}
                    {difficultyValue && (
                        <div className="recipe-preparation__item">
                            {difficultyIcon && (
                                <img
                                    className="recipe-preparation__icon"
                                    src={difficultyIcon.src}
                                    alt={difficultyIcon.alt}
                                />
                            )}
                            {difficultyTitle && (
                                <div className="recipe-preparation__title">{difficultyTitle}</div>
                            )}
                            {difficultyValue && (
                                <div className="recipe-preparation__value">{difficultyValue}</div>
                            )}
                        </div>
                    )}
                    {servesValue > 0 && (
                        <div className="recipe-preparation__item">
                            {servesIcon && (
                                <img
                                    className="recipe-preparation__icon"
                                    src={servesIcon.src}
                                    alt={servesIcon.alt}
                                />
                            )}
                            {servesTitle && (
                                <div className="recipe-preparation__title">{servesTitle}</div>
                            )}
                            {servesValue && (
                                <div className="recipe-preparation__value">{servesValue}</div>
                            )}
                        </div>
                    )}
                </div>
                {dietaryLabels.length > 0 && (
                    <>
                        <div className="recipe-preparation__separator"></div>
                        <div className="recipe-preparation__bottom">
                            {dietaryLabels.map((item, index) => (
                                <div
                                    key={`recipe-preparation-bottom-item${index}`}
                                    className="recipe-preparation__item"
                                >
                                    <div className="recipe-preparation__circle">
                                        {getCroppedLabel(item.iconLabel)}
                                    </div>
                                    <div>{item.title}</div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
            <div className="recipe-preparation__sections">
                <RecipeSection
                    ingredientSections={ingredientSections}
                    className="recipe-section__ingredients"
                />
                <RecipeSection methodSections={methodSections} className="recipe-section__method" />
            </div>
        </>
    );
};

const IconProps = {
    alt: PropTypes.string,
    border: PropTypes.string,
    class: PropTypes.string,
    height: PropTypes.number,
    hSpace: PropTypes.number,
    src: PropTypes.string,
    vSpace: PropTypes.number,
    width: PropTypes.number,
    mediaId: PropTypes.string,
    title: PropTypes.string,
    language: PropTypes.shapeOf({
        Name: PropTypes.string,
    }),
    mediaExists: PropTypes.boolean,
};

RecipePreparation.propTypes = {
    cookTitle: PropTypes.string,
    cookValue: PropTypes.string,
    dietaryLabels: PropTypes.arrayOf({
        title: PropTypes.string,
        icon: PropTypes.shapeOf(IconProps),
    }),
    difficultyIcon: PropTypes.shapeOf(IconProps),
    difficultyTitle: PropTypes.string,
    difficultyValue: PropTypes.string,
    preparationAndCookIcon: PropTypes.shapeOf(IconProps),
    preparationTitle: PropTypes.string,
    preparationValue: PropTypes.string,
    servesIcon: PropTypes.shapeOf(IconProps),
    servesTitle: PropTypes.string,
    servesValue: PropTypes.number,
    tags: PropTypes.array,
    ingredientSections: PropTypes.array,
    methodSections: PropTypes.array,
};

export default RecipePreparation;
