import React, { useEffect, useRef, useState } from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

const SwiperCarousel = (props) => {
    const [swiper, setSwiper] = useState(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const paginationRef = useRef(null);

    useEffect(() => {
        if (swiper) {
            const { navigation, pagination, params } = swiper;
            if (prevRef.current) {
                params.navigation.prevEl = prevRef.current;
            }
            if (nextRef.current) {
                params.navigation.nextEl = nextRef.current;
            }
            if (paginationRef.current) {
                params.pagination.el = paginationRef.current;
            }
            navigation.init();
            navigation.update();
            pagination.init();
            pagination.render();
            pagination.update();
        }
    }, [swiper]);

    const carouselSettings = {
        autoplay: props.autoplay ? {
            delay: 7000,
            disableOnInteraction: true,
        } : false,
        loop: true,
        modules: [Autoplay, Navigation, Pagination],
        onSwiper: setSwiper,
        navigation: {
            nextEl: nextRef?.current,
            prevEl: prevRef?.current,
        },
        pagination: {
            clickable: true,
            el: paginationRef?.current,
            bulletClass: 'swiper-carousel__bullets',
            bulletActiveClass: 'swiper-carousel__bullets--active',
            bulletElement: 'li',
        },
        slidesPerView: 'auto',
    };

    return (
        <>
            <Swiper className="swiper-carousel" {...carouselSettings} data-tooltip-container>
              {props.children?.map((child, index) => (
                  <SwiperSlide key={`swiper-carousel-item${index}`}>{child}</SwiperSlide>
              ))}
              <button className="swiper-carousel__prev" ref={prevRef}></button>
              <button className="swiper-carousel__next" ref={nextRef}></button>
            </Swiper>
            <div
                className="swiper-carousel__pagination-bullets"
                ref={paginationRef}
            ></div>
        </>
    );
};


SwiperCarousel.propTypes = {
    children: PropTypes.node,
};

export default SwiperCarousel;
