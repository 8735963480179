import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import {
    FILTER_NAME_RADIUS,
    FILTER_NAME_OWNERSHIP,
    CONTROL_TYPE_CHECKLIST,
    CONTROL_TYPE_RADIOGROUP
} from '@Components/campaign-property-search/js/constants';
import { CampaignMobileFilterContext } from '@Components/campaign-property-search/js/context';

import MobileFilterRadius from './mobile-filter-radius.jsx';
import MobileSearchOwnership from './mobile-filters-ownership.jsx';
import MobileFiltersCheckboxList from './mobile-filters-checkbox-list';

const MobileFiltersItem = ({ value, config, allFilters }) => {
    const { filterOptions, setFilterOptions } = useContext(CampaignMobileFilterContext);

    const isCheckboxFilter = config.type === CONTROL_TYPE_CHECKLIST;

    const onInputChange = (optionName, changedCheckboxValue) => {       
        if (isCheckboxFilter) {            
            if (filterOptions[optionName].indexOf(changedCheckboxValue) > -1) {
                const newArray = filterOptions[optionName].filter((option) => option !== changedCheckboxValue);
                setFilterOptions({
                    ...filterOptions,
                    [optionName]: [...newArray]
                });
                return;
            }

            setFilterOptions({
                ...filterOptions,
                [optionName]: [...filterOptions[optionName], changedCheckboxValue]
            });
        } else {
            setFilterOptions({
                ...filterOptions,
                [optionName]: changedCheckboxValue
            });
        }
    };
    
    return (
        <div className="campaign-mobile-filters__item">
            <div className="campaign-mobile-filters__item-label">{config.label}</div>
            <div className="campaign-mobile-filters__item-content">
                {config.name === FILTER_NAME_OWNERSHIP && (
                    <MobileSearchOwnership onChange={onInputChange} config={config} value={value} allFilters={allFilters} />
                )}

                {config.name === FILTER_NAME_RADIUS && (
                    <>
                        <div className='campaign-mobile-filters__radius-description'>Filter within a mile radius</div>
                        <MobileFilterRadius onChange={onInputChange} key={value} config={config} value={value} />
                    </>
                )}

                {
                    isCheckboxFilter && (
                        <MobileFiltersCheckboxList onCheckboxChange={onInputChange} config={config} value={value} />
                    )
                }
            </div>
        </div>
    );
};

MobileFiltersItem.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
    allFilters: PropTypes.array.isRequired,
};

export default MobileFiltersItem;
