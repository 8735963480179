import GoogleMarker from '../google-marker';
import pinGrey from '../../img/pin-key-grey.svg';
import pinGreen from '../../img/pin-key-green.svg';

export default class GoogleMarkerKey extends GoogleMarker {
    getIconGrey() {
        return pinGrey;
    }

    getIconGreen() {
        return pinGreen;
    }
}
