import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import VacanciesSearch from './vacancies-search';

export default class VacanciesSearchComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.searchOptions = {
            endpointUrl: this.options.searchUrl,
            items: this.options.items,
            zeroResults: this.options.zeroResults
        };

        this.filtersConfig = this.options.filters;
    }

    static getNamespace() {
        return 'vacancies-search';
    }

    onInit() {
        this.render();
    }

    render() {
        ReactDOM.render(
            <VacanciesSearch filtersConfig={this.filtersConfig} options={this.searchOptions} />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
