import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const InteractiveSitePlanStoreContext = createContext();
export const InteractiveSitePlanStoreProvider = ({ children, store }) => (
    <InteractiveSitePlanStoreContext.Provider value={store}>
        {children}
    </InteractiveSitePlanStoreContext.Provider>
);
export const useInteractiveSitePlanStore = () => useContext(InteractiveSitePlanStoreContext);

InteractiveSitePlanStoreProvider.propTypes = {
    children: PropTypes.any,
    store: PropTypes.shape({
        developmentId: PropTypes.string,
        developmentName: PropTypes.string,
        siteplanId: PropTypes.string,
        floors: PropTypes.arrayOf(
            PropTypes.shape({
                image: PropTypes.string,
                name: PropTypes.string,
                originalWidth: PropTypes.number,
                map: PropTypes.shape({
                    name: PropTypes.string,
                    areas: PropTypes.arrayOf(
                        PropTypes.shape({
                            alt: PropTypes.string,
                            bedroomAmount: PropTypes.number,
                            href: PropTypes.string,
                            number: PropTypes.string,
                            recordId: PropTypes.string,
                            shape: PropTypes.string,
                            sqm: PropTypes.string,
                            title: PropTypes.string,
                            sold: PropTypes.bool,
                            purchasePrice: PropTypes.number,
                            rentalPrice: PropTypes.number,
                            coords: PropTypes.arrayOf(PropTypes.number),
                        })
                    ),
                }),
            })
        ),
    }),
};
