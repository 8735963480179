import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import TimeSlotPicker from './time-slot-picker';
import constants from "../../../general/js/constants";
import { PARSLEY_SERVER_CONSTRAINT_NAME } from "../../form/js/form.component";

export default class TimeSlotPickerComponent extends DcBaseComponent {

    constructor(...args) {
        super(...args);

        this.timeSlotPickerRef = React.createRef();
    }

    static getNamespace() {
        return 'time-slot-picker';
    }

    static getRequiredRefs() {
        return ['input', 'picker'];
    }

    onInit() {
        this.refs.input.addEventListener(constants.EVENT_FIELD_VALIDATION_FAILED, this._onInputValidationFailed);
        this.render();
    }

    _onInputValidationFailed = ({ failedValidators }) => {
        if (failedValidators.includes(PARSLEY_SERVER_CONSTRAINT_NAME)) {
            this.timeSlotPickerRef.current.refresh();
        }
    };

    render() {
        const { initDataUrl, getTimeSlotUrl, getDaysUrl } = this.options;
        ReactDOM.render(
            <TimeSlotPicker
                ref={this.timeSlotPickerRef}
                initDataUrl={initDataUrl}
                getTimeSlotUrl={getTimeSlotUrl}
                getDaysUrl={getDaysUrl}
                onSelect={this.onSelect}
                options={this.options}/>,
            this.refs.picker
        );
    }

    onSelect = (value) => {
        this.refs.input.value = value ? JSON.stringify(value) : '';
    };

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.refs.picker);
    }
}
