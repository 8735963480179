const SUB_NAVIGATION_NAMINGS = [
    {
        shortName: 'visit',
        iconName: 'visit'
    },
    {
        shortName: 'brochure',
        iconName: 'brochure'
    },
    {
        shortName: 'enquiry',
        iconName: 'enquiry'
    },
    {
        shortName: 'list',
        iconName: 'waiting-list'
    },
    {
        shortName: 'waiting',
        iconName: 'waiting-list'
    },
];

export default SUB_NAVIGATION_NAMINGS;
