import React from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends React.Component {
    onChange = e => {
        const { isDisabled, isChecked, onChange } = this.props;
        if (!isDisabled || isChecked) {
            onChange(e);
        }
    };

    render() {
        const { isDisabled, isChecked, text, gtmSelector } = this.props;
        return (
            <div className="checkbox">
                <label className="checkbox__label" data-dc-gtm-selector={gtmSelector}>
                    <input
                        type="checkbox"
                        className={`${isDisabled ? 'is-disabled' : ''}`}
                        checked={isChecked}
                        onChange={this.onChange}
                    />
                    <span className="checkbox__placeholder">
                        <svg
                            width="11"
                            height="9"
                            className="icon checkbox__tick-icon"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                        >
                            <use xlinkHref="#icon-tick" />
                        </svg>
                    </span>
                    <span className="checkbox__text">{text}</span>
                </label>
            </div>
        );
    }
}

Checkbox.defaultProps = {
    isDisabled: false
};

Checkbox.propTypes = {
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    gtmSelector: PropTypes.string.isRequired
};
