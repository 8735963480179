import React from 'react';
import PropTypes from 'prop-types';
import { devSearchTypeDevelopment } from '@Components/developments-search-v2/js/dev-search-types.js';

const priceTag = (ownershipType, id, price, tooltipText, isInSellingStage) => {
    let tagNaming;
    let priceValue;
    let toolTipId;

    if (ownershipType === 'buy') {
        tagNaming = tooltipText ? 'Buy' : 'Buy from';
        priceValue = price;
        toolTipId = `tooltip_campaign-dev-flag_sales-formatted-price_${id}`;
    } else {
        tagNaming = tooltipText ? 'Rent' : 'Rent from';
        priceValue = price.split(' ')[0];
        toolTipId = `tooltip_campaign-dev-flag_rental-formatted-price_${id}`;
    }

    return (
        <div className="campaign-dev-flags__column campaign-dev-flags__column--primary">
            <div className={'campaign-dev-flags__price-wrapper'}>
                <div>
                    {tagNaming}
                    {tooltipText && <>
                        <div className="tooltip" data-tooltip>
                            <button aria-describedby={toolTipId} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                            <div className="tooltip__content" data-tooltip-content id={toolTipId} role="tooltip">
                                <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                    __html: tooltipText,
                                }}></div>
                            </div>
                        </div>
						{(isInSellingStage === false && (
						<>
							<small>(example&nbsp;pricing)</small>
						</>
						))}
                    </>}
                </div>
                {price ? <div>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: priceValue,
                        }}
                    />
                    {ownershipType === 'rent' && <span>&ensp;pcm</span>}
                </div> : <span className='campaign-dev-flags__price-value--unavailable'>N/A</span>}
            </div>
        </div>
    );
};

export default function CampaignDevFlags(props) {
    const showSalePrice = props.item.salesFormattedPrice;
    const showRentalPrice = props.item.rentalFormattedPrice;
    const showOpsoWithPrice = props.item.opsoAvailable && props.item.opsoPercentagePriceLabel && props.item.opsoSalesPrice != 0 && props.item.showPercentagePricesOverride !== false;
    const showOpsoWithoutPrice = props.item.opsoAvailable && (!props.item.opsoPercentagePriceLabel || props.item.opsoSalesPrice == 0 || props.item.showPercentagePricesOverride === false);
    const showOpso = showOpsoWithPrice || showOpsoWithoutPrice;

    return (
        <div>
            {showOpsoWithPrice && (
                <div className="campaign-dev-flags">
                    <div className='campaign-dev-flags__column campaign-dev-flags__column--primary'>
                        <div className="campaign-dev-flags__price-wrapper">
                            <div>
                                {(props.item.opsoSalesFormattedPriceTooltip && (
									<>
										Shared Ownership
										<div className="tooltip" data-tooltip>
											<button aria-describedby={`tooltip_campaign-dev-flag_opso-sales-formatted-price_${props.item.id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
											<div className="tooltip__content" data-tooltip-content id={`tooltip_campaign-dev-flag_opso-sales-formatted-price_${props.item.id}`} role="tooltip">
												<div className="tooltip__content__inner" dangerouslySetInnerHTML={{
													__html: props.item.opsoSalesFormattedPriceTooltip,
												}}></div>
											</div>
										</div>
										{(props.item.isInSellingStage === false && (
										<>
											<small>(example&nbsp;pricing)</small>
										</>
										))}
									</>
								))  ||
									<>
										{props.item.opsoPercentagePriceLabel}
									</>
								}
                            </div>
                            <span dangerouslySetInnerHTML={{ __html: props.item.opsoSalesFormattedPrice }} />
                        </div>
                    </div>
                </div>
            )}

			{showOpsoWithoutPrice && (
                <div className="campaign-dev-flags">
                    <div className='campaign-dev-flags__column campaign-dev-flags__column--primary'>
                        <div className="campaign-dev-flags__price-wrapper">
                            <p>
                                <b
                                    dangerouslySetInnerHTML={{ __html: props.item.opsoAvailableText }}
                                />
                            </p>
                        </div>
                    </div>
                </div>
            )}

            {(showSalePrice || showRentalPrice || (!showOpso && props.isComingSoon)) && (
                <div className="campaign-dev-flags">
                    {(props.item.salesFormattedPrice || props.item.rentalFormattedPrice) && (
                         <>
                            {priceTag('buy', props.item.id, props.item.salesFormattedPrice, props.item.salesFormattedPriceTooltip, props.item.isInSellingStage)}
                            {priceTag('rent', props.item.id, props.item.rentalFormattedPrice, props.item.rentalFormattedPriceTooltip, props.item.isInSellingStage)}
                        </>
                    )}
                    {(!props.item.salesFormattedPrice && !props.item.rentalFormattedPrice && !showOpso && props.isComingSoon) && (
                        <div className="campaign-dev-flags__column campaign-dev-flags__column--primary">
                            <div className="campaign-dev-flags__price-wrapper">
                                <span className="campaign-dev-flags__coming-soon"
                                    dangerouslySetInnerHTML={{
                                        __html: 'Properties not released',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

CampaignDevFlags.propTypes = {
    item: devSearchTypeDevelopment,
    isComingSoon: PropTypes.bool,
    isMobile: PropTypes.bool,
};
