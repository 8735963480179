import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class BrochureRequestMethodsComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.links = this.refs.links || [];
    }

    static getNamespace() {
        return 'brochure-request-methods';
    }

    static getRequiredRefs() {
        return ['links'];
    }

    onInit() {
        this.links.forEach((link) => {
            link.addEventListener('click', this._onLinkClick);
        });
    }

    onDestroy() {
        this.links.forEach((link) => {
            link.removeEventListener('click', this._onLinkClick);
        });
    }

    _onLinkClick = (e) => {
        e.preventDefault();

        const type = e.currentTarget;
        var form = type.closest('form');
        var href = type.getAttribute('href');
        form.setAttribute('action', href);
        form.submit();
    }
}
