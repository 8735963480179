import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_TABLET } from '../../../general/js/device';
import PropertyTableDataContainer from './property-table-data-container.jsx';

export default class PropertyTableComponent extends DcBaseComponent {
    static getNamespace() {
        return 'property-table';
    }

    onInit() {
        this.deviceChangeUnsubscribe = device.subscribeOnDeviceTypeChange(this.renderTable, true); // runs callback first time at init to set initial state of table
    }

    onDestroy() {
        this.deviceChangeUnsubscribe();
        ReactDOM.unmountComponentAtNode(this.element);
    }

    renderTable = () => {
        ReactDOM.render(<PropertyTableDataContainer
            {...this.options}
            isOnMobileViewport={!device.isViewportTypeGe(DEVICE_TYPE_TABLET)}
            parentSectionRef={this.element.parentNode}
        />, this.element);
    }
}
