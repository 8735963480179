import React from 'react';
import DevelopmentCard from '../../development-card/js/development-card';
import { BACKGROUND_GREY, BADGE_WHITE } from '../../dev-plot-card/js/dev-plot-card';

class LatestDevelopmentsCard extends React.PureComponent {
    render() {
        return (
            <DevelopmentCard
                {...this.props}
                alwaysVertical={true}
                background={BACKGROUND_GREY}
                badgeColour={BADGE_WHITE}
            />
        );
    }
}

export default LatestDevelopmentsCard;
