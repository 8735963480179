import React from 'react';
import PropTypes from 'prop-types';
import { PropertiesSearchContext } from './context';
import PropertiesSearchControlsSection from './containers/properties-search-controls-section';
import PropertiesSearchContent from './containers/properties-search-content';
import inMemoryFiltersListing from '../../listing/js/in-memory-filters-listing';

class PropertiesSearch extends React.Component {
    getContextValue() {
        const {
            options,
            filtersConfig,
            isFiltersMobile,
            isInitialRequestFulfilled,
            filters,
            appliedFilters,
            items,
            data,
            pagination,
            isLoadingMore,
            onFiltersChange,
            onFiltersApply,
            onFiltersReset,
            onSearch,
            onLoadMore
        } = this.props;

        return {
            items,
            isInitialRequestFulfilled,
            onFiltersChange,
            onFiltersApply,
            onFiltersReset,
            onSearch,
            filters,
            appliedFilters,
            filtersConfig: data.filters || filtersConfig,
            pagination,
            isLoadingMore,
            isFiltersMobile,
            zeroResults: options.zeroResults,
            onLoadMore
        };
    }

    render() {
        const {
            isLoading, pagination, isInitialRequestFulfilled, animatingContentRef
        } = this.props;
        return (
            <PropertiesSearchContext.Provider value={this.getContextValue()}>
                <div className="properties-search">
                    <PropertiesSearchControlsSection/>
                    <PropertiesSearchContent
                        isLoading={isLoading}
                        totalCount={pagination.totalCount}
                        isInitialRequestFulfilled={isInitialRequestFulfilled}
                        animatingContentRef={animatingContentRef}
                    />
                </div>
            </PropertiesSearchContext.Provider>
        );
    }
}

PropertiesSearch.defaultProps = {
    data: {}
};

PropertiesSearch.propTypes = {
    options: PropTypes.shape({
        zeroResults: PropTypes.object.isRequired,
    }),
    filtersConfig: PropTypes.array.isRequired,
    isFiltersMobile: PropTypes.bool.isRequired,
    // HOC
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    onFiltersReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
    animatingContentRef: PropTypes.func.isRequired,
};

export default inMemoryFiltersListing(PropertiesSearch);
