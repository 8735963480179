import React, { useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

const MoreArticlesCarousel = ({ children }) => {
    const [swiper, setSwiper] = useState(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const carouselSettings = {
        loop: false,
        modules: [Navigation],
        onSwiper: setSwiper,
        navigation: {
            nextEl: nextRef?.current,
            prevEl: prevRef?.current,
        },
        slidesPerView: 1,
    };

    return (
        <>
            <Swiper className="swiper-carousel" {...carouselSettings}>
                {children?.map((child, index) => (
                    <SwiperSlide key={`swiper-carousel-item${index}`}>{child}</SwiperSlide>
                ))}
                <button className="swiper-carousel__prev" ref={prevRef}></button>
                <button className="swiper-carousel__next" ref={nextRef}></button>
            </Swiper>
        </>
    );
};

MoreArticlesCarousel.propTypes = {
    children: PropTypes.node,
};

export default MoreArticlesCarousel;
