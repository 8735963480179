import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { devSearchTypeItem } from '@Components/developments-search-v2/js/dev-search-types.js';
import CampaignPropertyTableDataContainer from '@Components/campaign-property-table/js/campaign-property-table-data-container.jsx';
import CampaignDevFlags from './campaign-dev-flags.jsx';
import CampaignDevPlot from './campaign-dev-plot.jsx';
import CampaignDevGallery from './campaign-dev-gallery.jsx';

export default function CampaignDevSectionCard(props) {
    const { plots, development } = props.item;

    const {
        availableWithUnpublishedPropertiesDevelopmentText,
        comingSoonDevelopmentText,
        fullyReservedDevelopmentText,
    } = props;

    const isPropertyTableWithPlots = () => {
        return (
            (Array.isArray(plots) && plots.length !== 0)
        );
    };

    const isComingSoon = development.statusText?.toLowerCase().includes('coming');

    const developmentDescriptionText = () => {
        let text;
        if (props.developmentDescription === undefined) {
            switch (development.statusText) {
                case 'Available Now':
                    text = availableWithUnpublishedPropertiesDevelopmentText;
                    break;
                case 'Coming Soon':
                    text = comingSoonDevelopmentText;
                    break;
                case 'Fully Reserved':
                    text = fullyReservedDevelopmentText;
                    break;
                default:
                    text = props.developmentDescription;
                    break;
            }
        } else {
            text = props.developmentDescription;
        }
        return text;
    };

    return (
        <div
            className={classNames({
                'campaign-dev-card': true,
                'campaign-dev-card--layout': true,
            })}
        >
            {development.statusText && (
                <div className="campaign-dev-card__status-label">
                    {development.statusText}
                </div>
            )}
            <div className="campaign-dev-card__column-one" data-tooltip-container>
                {development.productType && (
                    <div className="campaign-dev-card__development-type">
                        {development.productType}
                    </div>
                )}
                <div className="campaign-dev-card__row-one">
                    <CampaignDevGallery item={development} />
                    <CampaignDevFlags
                        item={development}
                        isComingSoon={isComingSoon}
                        isMobile={props.isMobile} />
                </div>
                <div className="campaign-dev-card__row-two">
                    <CampaignDevPlot
                        item={development}
                        requestAVisitCTAText={props.requestAVisitCTAText}
                        sendMeUpdatesCTAText={props.sendMeUpdatesCTAText}
                        waitingListCTAText={props.waitingListCTAText}
                        isComingSoon={isComingSoon}
                    />
                </div>
            </div>

            <div className="campaign-dev-card__column-two">
                {isPropertyTableWithPlots()
                    ? <CampaignPropertyTableDataContainer
                        isComingSoon={isComingSoon}
                        item={props.item}
                        paginationConfig={props.paginationConfig}
                        paginationThreshold={props.paginationThreshold}
                        noPropertiesText={props.noPropertiesText} />
                    : <div
                        className='campaign-dev-card__no-properties'
                        dangerouslySetInnerHTML={{ __html: developmentDescriptionText() }} />
                }
            </div>
        </div>
    );
}

CampaignDevSectionCard.propTypes = {
    item: devSearchTypeItem,
    paginationThreshold: PropTypes.number,
    noPropertiesText: PropTypes.string,
    paginationConfig: PropTypes.shape({
        currentPage: PropTypes.number,
        pageCount: PropTypes.number,
        perPage: PropTypes.number,
        totalCount: PropTypes.number,
    }),
    availableWithUnpublishedPropertiesDevelopmentText: PropTypes.string,
    comingSoonDevelopmentText: PropTypes.string,
    fullyReservedDevelopmentText: PropTypes.string,
    sendMeUpdatesCTAText: PropTypes.string,
    requestAVisitCTAText: PropTypes.string,
    waitingListCTAText: PropTypes.string,
    developmentDescription: PropTypes.string,
    isMobile: PropTypes.bool,
};
