import React from 'react';
import PropTypes from 'prop-types';

const ArticlePageHeader = ({ title, description, tag, dateTitle, date, image, socialShareTitle }) => (
    <div className='article-page-header'>
        <h1 className='article-page-header__title'>{title}</h1>
        {description && <p className='article-page-header__description'>{description}</p>}
        {image && <img
            key={image.mediaId}
            className="lazyload article-page-header__image"
            data-srcset={image.src}
            data-sizes="auto"
			src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            alt={image.alt}
        />}
        <div className='article-page-header__bottom'>
            <div className='article-page-header__bottom--left'>
                {tag && <div className='article-page-header__tag'>{tag}</div>}
                {dateTitle && date && <div className='article-page-header__date'>{`${dateTitle} ${date}`}</div>}
            </div>
            <div className="sharethis-wrapper">
                { socialShareTitle }
                <div className="sharethis-inline-share-buttons"></div>
            </div>
        </div>
    </div>
);

const ImageProps = {
    alt: PropTypes.string,
    border: PropTypes.string,
    class: PropTypes.string,
    height: PropTypes.number,
    hSpace: PropTypes.number,
    src: PropTypes.string,
    vSpace: PropTypes.number,
    width: PropTypes.number,
    mediaId: PropTypes.string,
    title: PropTypes.string,
    language: PropTypes.shapeOf({
        Name: PropTypes.string,
    }),
    mediaExists: PropTypes.boolean,
};

ArticlePageHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    dateTitle: PropTypes.string,
    date: PropTypes.string,
    socialShareTitle: PropTypes.string,
    image: PropTypes.shapeOf(ImageProps)
};

export default ArticlePageHeader;
