import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import recentSearchesService from 'general/js/recent-searches-service';
import RecentSearches from './recent-searches';

export default class RecentSearchesComponent extends DcBaseComponent {
    static getNamespace() {
        return 'recent-searches';
    }

    onInit() {
        const recentSearches = recentSearchesService.getRecentSearches();

        ReactDOM.render(
            <RecentSearches items={recentSearches}/>,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
