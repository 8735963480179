import React, { useRef, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

const RecentlyViewedArticlesCarousel = ({ children }) => {
    const [swiper, setSwiper] = useState(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const carouselSettings = {
        loop: false,
        modules: [Navigation],
        onSwiper: setSwiper,
        navigation: {
            nextEl: nextRef?.current,
            prevEl: prevRef?.current,
        },
        slidesPerView: 1,
    };

    return (
        <Swiper key={children.length} className="swiper-carousel" {...carouselSettings}>
            {children?.map((child, index) => (
                <SwiperSlide key={index}>{child}</SwiperSlide>
            ))}
            <button className="swiper-carousel__prev" ref={prevRef}></button>
            <button className="swiper-carousel__next" ref={nextRef}></button>
        </Swiper>
    );
};

RecentlyViewedArticlesCarousel.propTypes = {
    children: PropTypes.node,
};

export default RecentlyViewedArticlesCarousel;
