export const SERVICE_ICONS = {
    building: { name: 'building', width: 16, height: 18 },
    hammer: { name: 'hammer', width: 16, height: 14 },
    flower: { name: 'flower', width: 16, height: 18 },
    faucet: { name: 'faucet', width: 16, height: 16 },
    spray: { name: 'spray', width: 16, height: 16 },
    pencil: { name: 'pencil', width: 16, height: 18 },
    default: { name: 'mcs', width: 16, height: 16 },
    tick: { name: 'tick', width: 20, height: 16 },
    cart: { name: 'cart', width: 18, height: 18 },
    phone: { name: 'phone', width: 18, height: 18 },
};

export const PROPERTY_ICONS = {
    apartment: { name: 'apartment', width: 20, height: 17 },
    cottage: { name: 'cottage', width: 19, height: 20 },
    bungalow: { name: 'bungalow', width: 20, height: 20 },
    bedroom: { name: 'bedroom', width: 20, height: 15 },
};

export const TOOLTIP_ICONS = {
    question: { name: '?' },
    default: { name: 'i' },
};

export const DEFAULT_SERVICE_COLOR = '#000000';
export const DEFAULT_TRACK_COLOR = '#D8D8D8';
export const DEFAULT_TOOLTIP_COLOR = '#363636';
export const DEFAULT_SVG_TEXT_RATIO = 0.4;
export const PRICE_MODE_WEEK = 'week';
export const PRICE_MODE_MONTH = 'month';
export const PRICE_MODE_YEAR = 'year';
export const DEFAULT_STEP = 0.01;
export const SERVICE_MODE = 'service';
export const CHART_MODE = 'chart';
export const TOOLTIP_ID = 'budget-tooltip';
export const POINT_SIZE = 8;
export const PERIOD_TITLE = 'I pay my bills';

export const PERIOD_SETTINGS = [
    {
        value: PRICE_MODE_WEEK,
        label: 'Weekly',
        visibilityField: 'showWeeklyButton'
    },
    {
        value: PRICE_MODE_MONTH,
        label: 'Monthly',
        visibilityField: 'showMonthlyButton'
    },
    {
        value: PRICE_MODE_YEAR,
        label: 'Yearly',
        visibilityField: 'showYearlyButton'
    },
];

export const LOADING_MESSAGE = {
    title: "We're sorry to keep you waiting",
    text: "It's taking a while, but this page is still loading. Please wait.",
};
export const ERROR_MESSAGE = {
    title: "We're sorry, there's a problem with the connection",
    text: 'Please try again later.',
};
