import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentsSearchMobileFilters from '../containers/developments-search-mobile-filters';
import DevelopmentsSearchDesktopFilters from '../containers/developments-search-desktop-filters';

class DevelopmentsSearchFilters extends React.PureComponent {
    render() {
        const { filtersConfig, isFiltersMobile } = this.props;

        return (
            <div className="developments-search__filters">
                {isFiltersMobile ?
                    <DevelopmentsSearchMobileFilters filtersConfig={filtersConfig}/> :
                    <DevelopmentsSearchDesktopFilters filtersConfig={filtersConfig}/>
                }
            </div>
        );
    }
}

DevelopmentsSearchFilters.propTypes = {
    isFiltersMobile: PropTypes.bool.isRequired,
    filtersConfig: PropTypes.array.isRequired,
};

export default DevelopmentsSearchFilters;
