import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import DevelopmentsSearchGroup from './developments-search-group';

class DevelopmentsSearchList extends React.PureComponent {
    render() {
        const { groups, markers } = this.props;

        return (
            <div className="developments-search__list">
                {groups.map((group) => (
                    <DevelopmentsSearchGroup key={group.id} group={group} markers={markers} />
                ))}
            </div>
        );
    }
}

DevelopmentsSearchList.defaultProps = {
    markers: {},
};

DevelopmentsSearchList.propTypes = {
    markers: PropTypes.object,
    groups: PropTypes.array.isRequired,
};

export default withContext(DevelopmentsSearchList, {
    groups: 'groups',
});
