import React from 'react';
import PropTypes from 'prop-types';
import SlickCarousel from 'general/js/slick-carousel/js/slick-carousel';

class MonthsCarousel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.carouselOptions = {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            zIndex: 1,
            arrows: true,
            dots: false,
            swipe: true,
            prevArrow: `<button type="button" class="btn time-slot-picker__arrow-button time-slot-picker__arrow-button--prev" aria-label="Previous Slide">
                            <svg width="8" height="14" class="icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-left-thin"></use>
                            </svg>
                        </button>`,
            nextArrow: `<button type="button" class="btn time-slot-picker__arrow-button time-slot-picker__arrow-button--next" aria-label="Next Slide">
                            <svg width="8" height="14" class="icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-right-thin"></use>
                            </svg>
                        </button>`
        };
    }

    afterChange = (event, slick, currentSlide) => {
        this.props.onMonthChange(currentSlide);
    };

    render() {
        const { months } = this.props;

        return (
            <SlickCarousel
                slides={months}
                rootClass="time-slot-picker__months-carousel"
                options={this.carouselOptions}
                afterChange={this.afterChange}
            >
                {months.map(month => (
                    <div className="time-slot-picker__months-slide" key={month.number}>
                        {month.name}
                    </div>
                ))}
            </SlickCarousel>
        );
    }
}

MonthsCarousel.propTypes = {
    months: PropTypes.array.isRequired,
    onMonthChange: PropTypes.func.isRequired
};

export default MonthsCarousel;
