import DcBaseComponent from 'general/js/dc/dc-base-component';
import ControlsCondition from "../../../general/js/controls-condition";

const ACTION_SHOW = 'show';
const ACTION_HIDE = 'hide';

export default class ShowIfComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.action = this.options.action || ACTION_SHOW;
    }

    static getNamespace() {
        return 'show-if';
    }

    onInit() {
        this.condition = new ControlsCondition(this.options.condition, this.onConditionStateChange, this.action === ACTION_HIDE);
    }

    onConditionStateChange = (value) => {
        if (value) {
            this.show();
        } else {
            this.hide();
        }
    };

    show() {
        this.element.style.display = 'block';
    }

    hide() {
        this.element.style.display = 'none';
    }
}
