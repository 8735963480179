import React from 'react';
import PropTypes from 'prop-types';
import Overlay from './overlay';
import contentBlocker from '../../content-blocker';

class OverlayWrapper extends React.Component {
    componentDidUpdate(prevProps) {
        const { isActive, overlay, retainerId, blockContent } = this.props;
        if (isActive !== prevProps.isActive) {
            if (isActive) {
                overlay.show(retainerId);
                if (blockContent) {
                    contentBlocker.block(retainerId);
                }
            } else {
                overlay.hide(retainerId);
                if (blockContent) {
                    contentBlocker.unblock(retainerId);
                }
            }
        }
    }

    componentWillUnmount() {
        const { isActive, overlay, retainerId } = this.props;
        if (isActive) {
            overlay.hide(retainerId);
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}

OverlayWrapper.defaultProps = {
    blockContent: false
};

OverlayWrapper.propTypes = {
    isActive: PropTypes.bool.isRequired,
    overlay: PropTypes.instanceOf(Overlay).isRequired,
    retainerId: PropTypes.string.isRequired,
    blockContent: PropTypes.bool,
};

export default OverlayWrapper;
