import React from 'react';
import PropTypes from 'prop-types';
import nanoId from 'nano-id';
import classNames from 'classnames';

const PropertyDisplay = ({
    plots,
    currentProperties,
	opsoAvailableText,
	showPercentagePricesOverride
}) => {
    const renderId = nanoId();

    return (
        <div className="campaign-property-table__table-body">
            {currentProperties.map((property, index) => (
                <div className="campaign-property-table__table-row" key={`${renderId}_${index}`}>
                    <div className='campaign-property-table__properties'>
                        <div className='campaign-property-table__property-row'>
                            <div className='property-row__property-info'>
                                <div className='property-row__property-name'><strong><a href={`${property.buttonUrl}`}>Property {property.plotNumber}</a></strong></div>
                                <div className='property-row__bedroom-info'>
                                    <svg className="icon" width="20" height="20">
                                        <use xlinkHref="#icon-bedroom"></use>
                                    </svg>
                                    <strong>{property.numberOfBedrooms}</strong>
                                </div>
                                {(property.opsoAvailable && property.showOpsoPricingOnWebsite && property.opsoSalesPrice != 0 && showPercentagePricesOverride !== false) && (
                                    <div className='property-row__price'>
                                        <div className={classNames({
                                            'property-row__opso': true,
                                            'property-row__opso-price': property.opsoSalesFormattedPrice,
                                        })}>
                                            <span>Shared Ownership</span>{property.opsoPercentagePriceLabel && (
                                                <div className="tooltip tooltip--dark" data-tooltip>
                                                    <button aria-describedby={`tooltip_${renderId}_${index}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                                    <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}_${index}`} role="tooltip">
                                                        <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                            __html: property.opsoSalesFormattedPriceTooltip,
                                                        }}></div>
                                                    </div>
                                                </div>
                                            )}:<br/>
                                            <b dangerouslySetInnerHTML={{
                                                __html: property.opsoSalesFormattedPrice,
                                            }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {(property.opsoAvailable && (!property.showOpsoPricingOnWebsite || property.opsoSalesPrice == 0 || showPercentagePricesOverride === false)) && (
                                    <div className='property-row__price'>
                                        <div className='property-row__buy-price'>
                                            <b dangerouslySetInnerHTML={{
                                                __html: opsoAvailableText,
                                            }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {(!property.opsoAvailable) && (
                                    <div className='property-row__price'>
                                        <div className='property-row__buy-price'>
                                            <span>Buy price:</span>
                                            <span dangerouslySetInnerHTML={{
                                                __html: property.salesFormattedPrice ? property.salesFormattedPrice : 'N/A',
                                            }}
                                            />
                                        </div>
                                        {!property.hideRentPrices && (property.hideRentPrices !== undefined) &&(
                                            <div className='property-row__rent-price'>
                                            <span>Rent price:</span>
                                            <span dangerouslySetInnerHTML={{
                                                __html: property.rentalFormattedPrice ? property.rentalFormattedPrice : 'N/A',
                                            }}
                                            />
                                        </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <a className='property-row__arrow-icon' href={`${property.buttonUrl}`}>
                                <svg className="icon" width="6" height="7">
                                    <use xlinkHref="#icon-arrow-right-thin"></use>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

PropertyDisplay.propTypes = {
    plots: PropTypes.array,
    paginationThreshold: PropTypes.number,
    currentProperties: PropTypes.array,
};

export default PropertyDisplay;
