import React from 'react';
import PropTypes from 'prop-types';
import VacanciesSearchFilters from '../components/vacancies-search-filters';
import { withContext } from '../context';

class VacanciesSearchControlsSection extends React.PureComponent {
    render() {
        const { filtersConfig, filters, onFiltersApply } = this.props;

        return (
            <div className="vacancies-search__controls-section">
                <div className="vacancies-search__filters-label">
                    Find the jobs that interest you:
                </div>
                <VacanciesSearchFilters
                    filtersConfig={filtersConfig}
                    filters={filters}
                    onFiltersApply={onFiltersApply}
                />
            </div>
        );
    }
}

VacanciesSearchControlsSection.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired
};

export default withContext(VacanciesSearchControlsSection, {
    filtersConfig: 'filtersConfig',
    filters: 'filters',
    onFiltersApply: 'onFiltersApply'
});
