import $ from 'jquery';
import 'slick-carousel';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import SlickResponsive from '../../../general/js/slick-carousel/js/slick-responsive';
import { DEVICE_TYPE_TABLET, DEVICE_TYPE_DESKTOP } from '../../../general/js/device';

export default class TestimonialCarouselV2Component extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.testimonialCarousel = null;
        this.amountOfDesktopSlides = this.options.isSectionWithSidebar ? 2 : 4;
    }

    static getNamespace() {
        return 'testimonial-carousel-v2';
    }

    static getRequiredRefs() {
        return ['testimonialSlides', 'dots'];
    }

    onInit() {
        const testimonialSlidesCount = this.refs.testimonialSlides.children.length;
        if (testimonialSlidesCount > 0) {
            this.initSlider();
        }
    }

    initSlider() {
        const { testimonialSlides, dots } = this.refs;

        if (!testimonialSlides || !dots) {
            throw new Error('Slider should contain at least 2 sliders and navs/dots');
        }

        const $testimonialSlides = $(testimonialSlides);
        const $dots = $(dots);
        const $arrows = $(this.element);
        const amountOfDesktopSlides = this.amountOfDesktopSlides;

        this.testimonialCarousel = new SlickResponsive(
            $testimonialSlides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                appendArrows: $arrows,
                dots: true,
                dotsClass: 'testimonial-carousel-v2__dots',
                appendDots: $dots,
                fade: false,
                accessibility: true,
                draggable: true,
                infinite: false,
                swipe: true,
                touchMove: true,
                rows: 0,
            },
            {
                [DEVICE_TYPE_TABLET]: {
                    slidesToShow: 2,
                },
                [DEVICE_TYPE_DESKTOP]: {
                    slidesToShow: amountOfDesktopSlides,
                },
            }
        );
    }

    onDestroy() {
        if (this.testimonialCarousel !== null) {
            this.testimonialCarousel.slick('unslick');
            this.testimonialCarousel = null;
        }
    }
}
