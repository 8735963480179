import constants from 'general/js/constants';
import Stickyfill from 'stickyfilljs';
import throttle from 'lodash/throttle';
import {
    addPassiveEventListener,
    removePassiveEventListener
} from 'general/js/passive-event-listener-polyfill';
import HtmlHelper from 'general/js/html-helper';
import AbstractPageOverview, { PAGE_OVERVIEW_LINK_SELECTOR } from './abstract-page-overview';

const SELECTED_CLASS = 'is-selected';
export default class PageOverviewDesktop extends AbstractPageOverview {
    constructor(...args) {
        super(...args);
        this.anchors = new Map();
        this._onWindowThrottledScroll = throttle(this._onWindowScroll, 32);
        this.mainContentContainer = document.querySelector('.development-page-main__content');
        if (this.links.length) {
            this._init();
        }
    }

    _init() {
        super._init();
        Stickyfill.add(this.refs.menu);
        this._toggleBorder(this.links[0]);
        this._initAnchors();
        addPassiveEventListener(window, 'scroll', this._onWindowThrottledScroll);
    }

    _initAnchors() {
        for (let link of this.links) {
            const anchor = HtmlHelper.getElementByHash(link.hash);
            if (link && anchor && !this.anchors.has(link)) {
                this.anchors.set(link, anchor);
            }
        }
    }

    _onWindowScroll = (e) => {
        const lastAnchor = this._getLastValueInMap(this.anchors);
        const firstAnchor = this._getFirstValueInMap(this.anchors);
        if (this._isScrolledToLastAnchor(lastAnchor[1])) {
            this._toggleBorder(lastAnchor[0]);
            return;
        }

        const firstAnchorPosition = firstAnchor[1].getBoundingClientRect().top;

        this.anchors.forEach((anchor, link) => {
            const anchorTop = (anchor.getBoundingClientRect().top - constants.FIXED_CTA_HEIGHT_DESKTOP - 2); // 2px border discrepancy
            const mainContentContainerTopPosition = this.mainContentContainer.getBoundingClientRect()?.top;
            if (anchorTop <= Math.abs(mainContentContainerTopPosition) - Math.abs(firstAnchorPosition)) {
                this._toggleBorder(link);
            }
        });
    };

    _getLastValueInMap(map) {
        return Array.from(map)[map.size - 1];
    }

    _getFirstValueInMap(map) {
        return Array.from(map)[0];
    }

    _isScrolledToLastAnchor(lastAnchor) {
        const lastAnchorBottom = Math.round(lastAnchor.getBoundingClientRect().bottom);
        return window.innerHeight >= lastAnchorBottom;
    }

    _toHighlightLastAnchor() {
        return window.innerHeight + window.scrollY >= document.body.scrollHeight;
    }

    beforeLinkClickHandle(e) {
        removePassiveEventListener(window, 'scroll', this._onWindowThrottledScroll);
    }

    afterLinkClickHandle(e) {
        addPassiveEventListener(window, 'scroll', this._onWindowThrottledScroll);
    }

    _toggleBorder(selectedLink) {
        this.links.forEach((link) => {
            if (link !== selectedLink) {
                link.parentElement.classList.remove(SELECTED_CLASS);
            } else {
                selectedLink.parentElement.classList.add(SELECTED_CLASS);
            }
        });
    }

    _getLinkById(id) {
        return document.querySelector(`[href="#${id}"]`);
    }
}
