import React from 'react';
import PropTypes from 'prop-types';

function FeaturedDevelopmentsCard(card) {
	const {
		id,
		image,
		ribbonLabel,
		ribbonTextColor,
		title,
		salesFormattedPrice,
		salesFormattedPriceTooltip,
		rentalFormattedPrice,
		rentalFormattedPriceTooltip,
		description,
		buttonText,
		buttonUrl,
		buttonTarget,
		fullyReserved,
		isInSellingStage,
		allOpsoAvailable,
		opsoAvailable,
		opsoAvailableText,
		priceOnApplication,
		opsoSalesFormattedPrice,
		opsoSalesFormattedPriceTooltip,
		opsoPercentagePriceLabel,
	} = card;

	const showSalePrice = salesFormattedPrice !== undefined && salesFormattedPrice.length > 0;
	const showRentPrice = rentalFormattedPrice !== undefined && rentalFormattedPrice.length > 0;
	const showOpsoPrice = opsoAvailable;

	const noImageMargin = () => {
		let marginValue = null;

		if (image && !image.srcset && ribbonLabel) {
			marginValue = '60px 20px 0';
		}

		if (image && !image.srcset && !ribbonLabel) {
			marginValue = '0 20px';
		}

		return marginValue;
	};

	const getStatusTag = () => {
		if (fullyReserved) {
			return (<div className="featured-developments-cards__fully-reserved">
				<p>Fully reserved</p>
			</div>);
		}

		if (isInSellingStage === false) {
			return (
				<div className="featured-developments-cards__not-released">
					<p>Properties not released</p>
				</div>
			);
		}

		return null;
	};

	return (
		<div className="featured-developments-cards__card">
			{ribbonLabel && (
				<div className="featured-developments-cards__ribbon">
					<span
						className="featured-developments-cards__ribbon-label"
						style={{ color: ribbonTextColor }}
					>
						{ribbonLabel}
					</span>
				</div>
			)}
			{image && image.srcset && (
				buttonUrl ?
					<a href={buttonUrl} target={buttonTarget}>
						<img
							key={image.srcset}
							className="featured-developments-cards__card-image lazyload"
							data-srcset={image.srcset}
							data-sizes="auto"
							src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
							alt={image.alt}
						/>
					</a> :
					<img
						key={image.srcset}
						className="featured-developments-cards__card-image lazyload"
						data-srcset={image.srcset}
						data-sizes="auto"
						src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
						alt={image.alt}
					/>
			)}
			{
				showOpsoPrice && (
					<div className='featured-developments-cards__prices-wrapper'>
					<div className="featured-developments-cards__sales">
						<div>
							{(opsoSalesFormattedPriceTooltip && (
								<>
									Shared Ownership
									<div className="tooltip tooltip--force-below" data-tooltip>
										<button aria-describedby={`tooltip_dev-feature_opso-sales-formatted-price_${id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
										<div className="tooltip__content" data-tooltip-content id={`tooltip_dev-feature_opso-sales-formatted-price_${id}`} role="tooltip">
											<div className="tooltip__content__inner" dangerouslySetInnerHTML={{
												__html: opsoSalesFormattedPriceTooltip,
											}}></div>
										</div>
									</div>
									{(isInSellingStage === false && (
									<>
										<small>(example pricing)</small>
									</>
									))}
								</>
							)) ||
								<>
									{(opsoPercentagePriceLabel && opsoSalesFormattedPrice) ? opsoPercentagePriceLabel : opsoAvailableText}
								</>
							}
						</div>
						{(opsoPercentagePriceLabel && opsoSalesFormattedPrice) && (
							<p
							className="featured-developments-cards__sales-price"
							dangerouslySetInnerHTML={{ __html: opsoSalesFormattedPrice }}
						/>
						)}

					</div>
				</div>
				)
			}

			<div className="featured-developments-cards__prices-wrapper">

				{showSalePrice && (
					<div className="featured-developments-cards__sales">
						<div>
							{(salesFormattedPriceTooltip && (
								<>
									Buy
									<div className="tooltip tooltip--force-below" data-tooltip>
										<button aria-describedby={`tooltip_dev-feature_sales-formatted-price_${id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
										<div className="tooltip__content" data-tooltip-content id={`tooltip_dev-feature_sales-formatted-price_${id}`} role="tooltip">
											<div className="tooltip__content__inner" dangerouslySetInnerHTML={{
												__html: salesFormattedPriceTooltip,
											}}></div>
										</div>
									</div>
									{(isInSellingStage === false && (
									<>
										<small>(example pricing)</small>
									</>
									))}
								</>
							)) ||
								<>
									Buy from
								</>
							}
						</div>
						<p
							className="featured-developments-cards__sales-price"
							dangerouslySetInnerHTML={{ __html: salesFormattedPrice }}
						/>
					</div>
				)}
				{showRentPrice && (
					<div className="featured-developments-cards__rental">
						<div>

							{(rentalFormattedPriceTooltip && (
								<>
									Rent
									<div className="tooltip tooltip--force-below" data-tooltip>
										<button aria-describedby={`tooltip_dev-feature_rental-formatted-price_${id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
										<div className="tooltip__content" data-tooltip-content id={`tooltip_dev-feature_rental-formatted-price_${id}`} role="tooltip">
											<div className="tooltip__content__inner" dangerouslySetInnerHTML={{
												__html: rentalFormattedPriceTooltip,
											}}></div>
										</div>
									</div>
									{(isInSellingStage === false && (
									<>
										<small>(example pricing)</small>
									</>
									))}
								</>
							)) ||
								<>
									Rent from
								</>
							}
						</div>
						<p
							className="featured-developments-cards__rental-price"
							dangerouslySetInnerHTML={{ __html: rentalFormattedPrice }}
						/>
					</div>
				)}



				{!showSalePrice && !showRentPrice && !allOpsoAvailable && !showOpsoPrice && getStatusTag()}
			</div>
			<div
				className="featured-developments-cards__development-info"
				style={{ margin: noImageMargin() }}
			>
				<div>
					<div className="featured-developments-cards__title">
						{buttonUrl ?
							<a className="featured-developments-cards__title__link" href={buttonUrl}>{title}</a> :
							{title}
						}
					</div>
					<div className="featured-developments-cards__description">{description}</div>
				</div>
				{buttonUrl && buttonText && (
					<a href={buttonUrl} target={buttonTarget}
						className="featured-developments-cards__button-link"
					>
						{buttonText}
						<svg className="icon" width="8" height="14">
							<use xlinkHref="#icon-arrow-right-thin"></use>
						</svg>
					</a>
				)}
			</div>
		</div>
	);
}

FeaturedDevelopmentsCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.object,
	salesFormattedPrice: PropTypes.string,
	rentalFormattedPrice: PropTypes.string,
	fullyReserved: PropTypes.bool,
	isInSellingStage: PropTypes.bool,
	priceOnApplication: PropTypes.bool,
	buttonText: PropTypes.string,
	buttonUrl: PropTypes.string,
	buttonTarget: PropTypes.string,
	allOpsoAvailable: PropTypes.bool,
	opsoAvailable: PropTypes.bool,
	opsoAvailableText: PropTypes.string,
};

export default FeaturedDevelopmentsCard;
