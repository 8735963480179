import React from 'react';
import PropTypes from 'prop-types';

class TabContent extends React.PureComponent {
    render() {
        const { tabsMode, id, currentId, children, mixinClass } = this.props;

        const isActive = id === currentId;
        return (
            <div
                className={`developments-search__tabs-content ${mixinClass} ${tabsMode ? 'tabs-mode' : ''} ${isActive ? 'is-active' : ''}`}>
                {children}
            </div>
        );
    }
}

TabContent.defaultProps = {
    mixinClass: ''
};

TabContent.propTypes = {
    mixinClass: PropTypes.string,
    tabsMode: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    currentId: PropTypes.string.isRequired,
};

export default TabContent;
