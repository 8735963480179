import PropTypes from 'prop-types';
import React from 'react';
import MoreArticlesCard from './more-articles-card';
import MoreArticlesCarousel from './more-articles-carousel';
import analyticsService from '@General/js/analytics-service.js';

function MoreArticles(props) {
    const { title, description, articles, moreUrl, showMore } = props;

    const renderArticles = () =>
        articles.map((article, index) => (
            <MoreArticlesCard key={index} article={article} />
        ));

    const renderCarousel = () => (
        <MoreArticlesCarousel>
            {articles.map((article, index) => (
                <MoreArticlesCard key={index} article={article} />
            ))}
        </MoreArticlesCarousel>
    );

	const renderMoreCta = () => (
		<a
            className='btn-secondary btn-secondary--invert'
            onClick={() => {analyticsService._send('moreArticlesReadAll', {title: 'Read all articles'});}}
            href={moreUrl}>Read all articles</a>
	);

    let header;

    if (title && title.includes('<em>')) {
        header = <div className="container__header container__header--vertical">
            {title && (
                <h3
                    className="container__header-title"
                    dangerouslySetInnerHTML={{ __html: title }}></h3>
            )}
            {description && (
                <div className="container__header-description">
                    <p>{description}</p>
                </div>
            )}
        </div>
    } else {
        header = <>
            {title && (
                <h3
                    className="more-articles__title"
                    dangerouslySetInnerHTML={{ __html: title }}></h3>
            )}
            {description && (
                <p>{description}</p>
            )}
        </>
    }

    return (
        <div className="more-articles__container">
            {header}
            <div className="more-articles-card__wrapper">{renderArticles()}</div>
            <div className="more-articles-card__carousel">{renderCarousel()}</div>
            {showMore && (
                <div className="more-articles__cta">{renderMoreCta()}</div>
            )}
        </div>
    );
}

MoreArticles.propTypes = {
    MoreArticles: PropTypes.string,
};

export default MoreArticles;
