import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import BottomDrawer from './bottom-drawer';
import DrawerSvg from '../svgs/drawer.svg';

const VideoWithRelatedVideos = ({
    defaultVideo,
    viewAllText,
    relatedVideosHeading,
    relatedVideos,
}) => {
    // Need it as a workaround for line-clamp.
    // It doesn't work with elements that were hidden at first
    const LINES_CLAMP_STYLES = {
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        visibility: 'visible',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    };

    const [isDescriptionDrawerOpen, setIsDescriptionDrawerOpen] = useState(false);
    const [isVideosDrawerOpen, setIsVideosDrawerOpen] = useState(false);
    const [autoplay, setAutoplay] = useState(0);
    const [currentVideo, setCurrentVideo] = useState(defaultVideo);
    const [videos, setVideos] = useState(
        relatedVideos.filter((video) => video.videoId !== defaultVideo?.videoId)
    );
    const switchCurrentVideoOnMobile = (newId) => {
        switchCurrentVideo(newId);
        setIsVideosDrawerOpen(false);
    };

    const switchCurrentVideo = (newId) => {
        let newCurrentVideo = {};

        const updatedRelatedVideos = videos.map((item) => {
            if (item.videoId === newId) {
                newCurrentVideo = item;
                return currentVideo;
            } else return item;
        });

        setAutoplay(0);
        setVideos([...updatedRelatedVideos]);
        setCurrentVideo(newCurrentVideo);
    };

    const onOpenDescriptionDrawer = () => {
        setIsDescriptionDrawerOpen(true);
    };

    const onOpenVideoDrawer = () => {
        setIsVideosDrawerOpen(true);
    };

    const onDefaultImageLoad = (e) => {
        const currentUrl = e.target.src;
        const isItDefaultYoutubeImage =
            e.target.naturalWidth <= 120 && e.target.naturalHeight <= 90;

        if (isItDefaultYoutubeImage && currentUrl.includes('maxresdefault')) {
            e.target.src = currentUrl.replace('maxresdefault', '0');
        }
    };

    if (!currentVideo) {
        return null;
    }

    return (
        <div>
            <div className="video-container">
                <TransitionGroup component="div">
                    {!autoplay && (
                        <CSSTransition timeout={400} classNames="video-container__preview-image">
                            <>
                                <div className="video-with-related-videos__preview">
                                    <img
                                        alt={currentVideo.title}
                                        loading="lazy"
                                        src={`https://img.youtube.com/vi/${currentVideo.videoId}/maxresdefault.jpg`}
                                        onLoad={(e) => onDefaultImageLoad(e, currentVideo.videoId)}
                                    />
                                </div>
                                <button
                                    className="video-with-related-videos__play-button"
                                    aria-label={`Play video ${currentVideo.title}`}
                                    onClick={() => setAutoplay(1)}
                                >
                                    <svg
                                        className="icon video-with-related-videos__play-button-icon"
                                        width="17"
                                        height="21"
                                        focusable="false"
                                        role="img"
                                        aria-hidden="true"
                                    >
                                        <use xlinkHref="#icon-play"></use>
                                    </svg>
                                </button>
                            </>
                        </CSSTransition>
                    )}
                </TransitionGroup>
                <iframe
                    loading="lazy"
                    allow="autoplay; fullscreen;"
                    src={
                        `https://www.youtube.com/embed/${currentVideo.videoId}` +
                        `?enablejsapi=1&mute=1&autoplay=${autoplay}&rel=0&modestbranding=1&cc_load_policy=0&iv_load_policy=3&playsinline=1&origin=${location.origin}`
                    }
                ></iframe>
            </div>
            <div className="mobile-hidden">
                <div className="video-with-related-videos__title mobile-hidden">
                    {currentVideo.title}
                </div>
                <div
                    className="video-with-related-videos__description"
                    dangerouslySetInnerHTML={{ __html: currentVideo.description }}
                ></div>
            </div>
            {currentVideo.title && (
                <div className="desktop-hidden">
                    <button
                        className="video-with-related-videos__btn-description"
                        onClick={onOpenDescriptionDrawer}
                    >
                        <div className="video-with-related-videos__title">{currentVideo.title}</div>
                        <svg width={16} height={16}>
                            <use xlinkHref={`${DrawerSvg}#icon-drawer`} />
                        </svg>
                    </button>
                    <BottomDrawer
                        title="Description"
                        isOpen={isDescriptionDrawerOpen}
                        onClose={() => setIsDescriptionDrawerOpen(false)}
                    >
                        <div
                            className="video-with-related-videos__description"
                            dangerouslySetInnerHTML={{ __html: currentVideo.description }}
                        ></div>
                    </BottomDrawer>
                </div>
            )}

            <div className="separator"></div>

            {videos && videos.length > 0 ? (
                <div className="related-videos mobile-hidden">
                    <div className="related-videos__title">{relatedVideosHeading}</div>
                    <div className="related-videos__items">
                        {videos?.map((item, index) => (
                            <button
                                className="related-videos__item"
                                key={`related-video-${index}`}
                                type="button"
                                onClick={() => switchCurrentVideo(item.videoId)}
                            >
                                <div className="related-videos__image-container">
                                    <img
                                        alt={item.title}
                                        className="related-videos__image"
                                        loading="lazy"
                                        src={`https://img.youtube.com/vi/${item.videoId}/0.jpg`}
                                    />
                                </div>
                                <div className="related-videos__info">
                                    <div
                                        className="related-videos__info-title"
                                        style={LINES_CLAMP_STYLES}
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        className="related-videos__info-description"
                                        style={LINES_CLAMP_STYLES}
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                    ></div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            ) : null}

            {videos && videos.length > 0 ? (
                <div className="related-videos desktop-hidden">
                    <div className="related-videos__title">{relatedVideosHeading}</div>
                    <div className="related-videos__items">
                        <button
                            className="related-videos__item"
                            type="button"
                            onClick={() => switchCurrentVideo(videos[0].videoId)}
                        >
                            <div className="related-videos__image-container">
                                <img
                                    alt={videos[0].title}
                                    key={`related-video-${videos[0].videoId}`}
                                    className="related-videos__image"
                                    loading="lazy"
                                    src={`https://img.youtube.com/vi/${videos[0].videoId}/0.jpg`}
                                />
                            </div>
                            <div className="related-videos__info">
                                <div
                                    className="related-videos__info-title"
                                    style={LINES_CLAMP_STYLES}
                                >
                                    {videos[0].title}
                                </div>
                                <div
                                    className="related-videos__info-description"
                                    style={LINES_CLAMP_STYLES}
                                    dangerouslySetInnerHTML={{ __html: videos[0].description }}
                                ></div>
                            </div>
                        </button>
                    </div>

                    <BottomDrawer
                        title={relatedVideosHeading}
                        isOpen={isVideosDrawerOpen}
                        onClose={() => setIsVideosDrawerOpen(false)}
                    >
                        {videos.map((item, index) => (
                            <button
                                className="related-videos__item"
                                key={`related-video-${index}`}
                                type="button"
                                onClick={() => switchCurrentVideoOnMobile(item.videoId)}
                            >
                                <div className="related-videos__image-container">
                                    <img
                                        alt={item.title}
                                        className="related-videos__image"
                                        loading="lazy"
                                        src={`https://img.youtube.com/vi/${item.videoId}/0.jpg`}
                                    />
                                </div>
                                <div className="related-videos__info">
                                    <div
                                        className="related-videos__info-title"
                                        style={LINES_CLAMP_STYLES}
                                    >
                                        {item.title}
                                    </div>
                                    <div
                                        className="related-videos__info-description"
                                        style={LINES_CLAMP_STYLES}
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                    ></div>
                                </div>
                            </button>
                        ))}
                    </BottomDrawer>
                    <div className="separator"></div>
                    <button
                        className="video-with-related-videos__btn-view-all"
                        onClick={onOpenVideoDrawer}
                    >
                        {viewAllText}
                        <svg width={16} height={16}>
                            <use xlinkHref={`${DrawerSvg}#icon-drawer`} />
                        </svg>
                    </button>
                </div>
            ) : null}
            <div className="separator"></div>
        </div>
    );
};

VideoWithRelatedVideos.propTypes = {
    defaultVideo: PropTypes.shape({
        title: PropTypes.string,
        videoId: PropTypes.string,
        description: PropTypes.string,
    }),
    relatedVideos: PropTypes.arrayOf({
        title: PropTypes.string,
        videoId: PropTypes.string,
        description: PropTypes.string,
    }),
    relatedVideosHeading: PropTypes.string,
    viewAllText: PropTypes.string,
};

export default VideoWithRelatedVideos;
