import PropTypes from 'prop-types';

export const devSearchTypeImage = PropTypes.shape({
    srcset: PropTypes.string,
    alt: PropTypes.string,
});

export const devSearchTypeImages = PropTypes.shape({
    items: PropTypes.arrayOf(devSearchTypeImage),
    totalCount: PropTypes.number,
});

export const devSearchTypeDevelopment = PropTypes.shape({
    developmentName: PropTypes.string,
    address: PropTypes.string,
    desc: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
    images: devSearchTypeImages,
    sitePlanUrl: PropTypes.string,
    virtualTourUrl: PropTypes.string,
    salesFormattedPrice: PropTypes.string,
    rentalFormattedPrice: PropTypes.string,
    promo: PropTypes.string,
    statusText: PropTypes.string,
    id: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    fullyReserved: PropTypes.bool,
    showRequestVisitForm: PropTypes.bool
});

export const devSearchTypeProperty = PropTypes.shape({
    plotNumber: PropTypes.string,
    numberOfBedrooms: PropTypes.number,
    salesFormattedPrice: PropTypes.string,
    rentalFormattedPrice: PropTypes.string,
    floor: PropTypes.string,
    status: PropTypes.string,
    area: PropTypes.string,
    buttonUrl: PropTypes.string,
});

export const devSearchTypeItem = PropTypes.shape({
    development: devSearchTypeDevelopment,
    groupId: PropTypes.string,
    plots: PropTypes.arrayOf(devSearchTypeProperty),
    totalPlotsCount: PropTypes.number,
});
