import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '@General/js/dc/dc-base-component';
import DevelopmentSubNavigation from './sub-navigation';

export default class SubNavigation extends DcBaseComponent {
    static getNamespace() {
        return 'sub-navigation';
    }

    static getRequiredRefs() {
        return [
            'navigationWrapper',
            'navigationPreview',
            'navigationOpened',
            'navigationPreviewWrapper',
            'navigationLinks',
            'closeButton'
        ];
    }

    onInit() {
        this.render();
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    render() {
        ReactDOM.render(
            <DevelopmentSubNavigation
                refs={this.refs}
                ctaButtons={this.options.ctaButtonsInfo}
            />,
            this.refs.navigationLinks
        );
    }
}
