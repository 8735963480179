import React from 'react';
import PropTypes from 'prop-types';
import nanoId from 'nano-id';
import SvgIcon, { SvgIconType } from './svg-icon';

const GA_EVENT_PROPERTY_TYPE = 'calculatorPropertyType';
export default function BudgetRadio({
    value = '',
    isChecked = false,
    changeCallback = () => {
        throw new Error('BudgetRadio Component must have valid onChange handler');
    },
    label,
    svgIcon = {},
    dispatchGaEvent
}) {
    const id = value.toString() + nanoId().toString();
    const changeHandler = (event) => {
        if (!isChecked) {
            const value = event.target.value;
            if(dispatchGaEvent) {
                dispatchGaEvent(GA_EVENT_PROPERTY_TYPE, { propertyTypes: value })
            }
            changeCallback(value);
        }
    };

    return (
        <li className="budget-list__item">
            <input
                className="budget-list__input"
                type="radio"
                id={id}
                value={value.toString()}
                checked={isChecked}
                onChange={changeHandler}
            />
            <label className="budget-list__label" htmlFor={id}>
                {svgIcon != null && <SvgIcon {...svgIcon} />}
                {label}
            </label>
        </li>
    );
}

export const BudgetRadioType = {
    value: PropTypes.string,
    isChecked: PropTypes.bool,
    changeCallback: PropTypes.func,
    label: PropTypes.string,
    svgIcon: SvgIconType,
    dispatchGaEvent: PropTypes.func
};

BudgetRadio.propTypes = BudgetRadioType;
