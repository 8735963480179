import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../spinner/js/spinner';

class LoadMoreButton extends React.PureComponent {
    render() {
        const {
            onClick, isVisible, caption, isLoading
        } = this.props;
        return isVisible ? (
            <button onClick={onClick} className="load-more-button btn" type="button">
                {caption}
                <svg
                    className="load-more-button__icon icon"
                    width="14"
                    height="8"
                    focusable="false"
                    role="img" aria-hidden="true"
                >
                    <use xlinkHref="#icon-arrow-down" />
                </svg>
                <Spinner isActive={isLoading} mobileSmall={true} isFaded={true} />
            </button>
        ) : null;
    }
}

LoadMoreButton.defaultProps = {
    isVisible: true,
    isLoading: false
};

LoadMoreButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    caption: PropTypes.string.isRequired
};

export default LoadMoreButton;
