import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import QuickSearch from './quick-search';

export default class QuickSearchComponent extends DcBaseComponent {
    static getNamespace() {
        return 'quick-search';
    }

    onInit() {
        const {
            type,
            defaultSearchUrl,
            searchByLocation,
            predictiveSearchOptions,
            buttonTrackingClass
        } = this.options;

        ReactDOM.render(
            <QuickSearch
                type={type}
                defaultSearchUrl={defaultSearchUrl}
                predictiveSearchOptions={predictiveSearchOptions}
                searchByLocation={searchByLocation}
                buttonTrackingClass={buttonTrackingClass}
            />,
            this.element
        );
    }
}
