import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import EventSearchCard from './event-search-card';
import EventSearchPagination from './event-search-pagination';
import EventSearchSort from './event-search-sort';
import EventSearchSummary from './event-search-summary';
import analyticsService from '@General/js/analytics-service.js';
import GenericLocationSearch from '@Assets/components/generic-location-search/js/generic-location-search';

function EventSearch(props) {
    const [eventsData, setEventsData] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [gettingEventsData, setGettingEventsData] = useState(true);
    const eventSearchResultsRef = useRef();

    useEffect(() => {
        getData(props.initialApiUrl);

        window.addEventListener('popstate', onPopState);

        return () => {
            window.removeEventListener('popstate', onPopState);
        };
      }, []);

    const onSearchAutocomplete = (id, isDevelopment) => {
        getData(`${props.baseApiUrl}?${isDevelopment ? 'developmentId' : 'q'}=${id}`, `?${isDevelopment ? 'developmentId' : 'q'}=${id}`);
    }

    const onSearchSubmit = () => {
        getData(`${props.baseApiUrl}?q=${searchTerm}`, `?q=${searchTerm}`);
    }

    const onFormSubmit = (event) => {
        event.preventDefault();

        onSearchSubmit();
    }

    const onPopState = () => {
        getData(`${props.baseApiUrl}${window.history.state && window.history.state.eventSearchApiUrl ? window.history.state.eventSearchApiUrl : ''}`);
    };

    const getData = (apiUrl, url) => {
        setGettingEventsData(true);

        if (url) {
            window.history.pushState({eventSearchApiUrl: url}, '',  url);
        }

        axios({
            method: 'get',
            url: apiUrl,
        }).then((res) => {
            if (!res.data) {
                console.error('Failed to fetch data');
            } else {

                if (searchTerm) {
                    if (res.data && res.data.events === 0) {
                        tracking('searchResultsNull', {searchTerm});
                    } else {
                        tracking('eventSearch', {searchTerm: res.data.location});
                    }
                }

                setEventsData(res.data);
                setSearchTerm(res.data.location ? res.data.location : '');

                if (eventSearchResultsRef.current && eventSearchResultsRef.current.getBoundingClientRect().top < 0) {
                    eventSearchResultsRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                }
            }

            setGettingEventsData(false);
        }).catch(() => {
            setGettingEventsData(false);
            console.error('Failed to fetch data');
        });
    };

    const tracking = (eventName, eventData) => {
        analyticsService._send(eventName, eventData);
    }

    return (
        <>
            <div className="event-search__bar">
                <form aria-label="Events search" className="container event-search__form" onSubmit={onFormSubmit} role="search">
                    <label className="event-search__label" htmlFor={`${props.renderId}-search`}>{props.title}</label>
                    <GenericLocationSearch
                        autocompleteApi={props.predictiveSearchOptions.predictiveSearchUrl}
                        buttonText={props.buttonText}
                        onSearchAutocomplete={onSearchAutocomplete}
                        onSearchSubmit={onSearchSubmit}
                        placeholder={props.predictiveSearchOptions.placeholderMessage}
                        renderId={props.renderId}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        theme="events"
                    />
                </form>
            </div>
            <div className="event-search__inner">
                <div className="event-search__container container">
                    {eventsData && <>
                        {eventsData.events === 0 ? <div className={`event-search__no-results ${gettingEventsData ? 'event-search__no-results--loading' : ''}`} dangerouslySetInnerHTML={{ __html: props.noResultsText }}></div> : <>
                            <div className="event-search__header">
                                <EventSearchSummary {...eventsData} />
                                <EventSearchSort goToPage={getData} renderId={props.renderId} sortOptions={eventsData.sortOptions} />
                            </div>
                            <div aria-live="polite" className={`event-search__result-grid ${gettingEventsData ? 'event-search__result-grid--loading' : ''}`} ref={eventSearchResultsRef}>
                                {eventsData.results && eventsData.results.map(event => <EventSearchCard {...event} key={event.id} tracking={tracking} />)}
                            </div>
                            {eventsData.pagination.pages.length > 1 &&
                                <EventSearchPagination {...eventsData.pagination} goToPage={getData} paginationText={props.paginationText} />
                            }
                        </>}
                    </>}
                    {!eventsData && <div className="event-search__preloader">Loading events...</div>}
                </div>
            </div>
        </>
    )
}

export default EventSearch;
