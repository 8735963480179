import $ from 'jquery';
import 'slick-carousel';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';
import { DEVICE_TYPE_DESKTOP } from 'general/js/device';

const AUTOPLAY_SPEED = 6500;

export default class TestimonialCarouselComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.imageCarousel = null;
        this.testimonialCarousel = null;
        this.isAutoplayStopped = false;
        this.slidingTime = 'slidingTime' in this.options ? this.options.slidingTime : AUTOPLAY_SPEED;
    }

    static getNamespace() {
        return 'testimonial-carousel';
    }

    static getRequiredRefs() {
        return ['imageSlides', 'testimonialSlides'];
    }

    onInit() {
        const imageSlidesCount = this.refs.imageSlides.children.length;
        const testimonialSlidesCount = this.refs.testimonialSlides.children.length;
        if (imageSlidesCount > 0 && testimonialSlidesCount > 0) {
            this.initSlider();
        }
    }

    initSlider() {
        const { imageSlides, testimonialSlides, dots } = this.refs;

        if (!testimonialSlides || !dots) {
            throw new Error('Slider should contain at least 2 sliders and navs/dots');
        }

        const $imageSlides = $(imageSlides);
        const $testimonialSlides = $(testimonialSlides);
        const $dots = $(dots);

        this.imageCarousel = new SlickResponsive(
            $imageSlides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                swipe: true,
                arrows: false,
                dots: false,
                asNavFor: $testimonialSlides,
                autoplay: true,
                accessibility: true,
                rows: 0,
            },
            {
                [DEVICE_TYPE_DESKTOP]: {
                    swipe: true
                }
            }
        );

        this.testimonialCarousel = new SlickResponsive(
            $testimonialSlides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                swipe: true,
                fade: true,
                asNavFor: $imageSlides,
                infinite: true,
                dots: true,
                dotsClass: 'testimonial-x3__dots',
                appendDots: $dots,
                autoplay: true,
                autoplaySpeed: this.slidingTime,
                accessibility: true,
                arrows: false,
                rows: 0,
                prevArrow: '.testimonial-x3__arrow-button--prev',
                nextArrow: '.testimonial-x3__arrow-button--next'
            },
            {
                [DEVICE_TYPE_DESKTOP]: {
                    swipe: true,
                }
            }
        );

        this.element.addEventListener('mouseenter', this.onSliderMouseEnter.bind(this));
        this.element.addEventListener('mouseleave', this.onSliderMouseLeave.bind(this));
        this.element.addEventListener('click', this.onSliderClick.bind(this));
    }

    onSliderMouseEnter() {
        if (!this.isAutoplayStopped) {
            this.testimonialCarousel.slick('slickPause');
        }
    }

    onSliderMouseLeave() {
        if (!this.isAutoplayStopped) {
            this.testimonialCarousel.slick('slickPlay');
        }
    }

    onSliderClick() {
        if (!this.isAutoplayStopped) {
            this.isAutoplayStopped = true;
            this._stopAutoPlay();
        }
    }

    _stopAutoPlay() {
        this.testimonialCarousel.slick('slickPause');
    }

    onDestroy() {
        if (this.imageCarousel !== null) {
            this.imageCarousel.slick('unslick');
            this.imageCarousel = null;
        }
        if (this.testimonialCarousel !== null) {
            this.testimonialCarousel.slick('unslick');
            this.testimonialCarousel = null;
        }
    }
}
