import Area from './area';
import Helper from './helper';
import utils from './utils';

export default class Rectangle extends Area {
    constructor(coords, attributes, app) {
        super('rectangle', coords, attributes, app);

        this._coords = {
            x: coords.x || 0,
            y: coords.y || 0,
            width: coords.width || 0,
            height: coords.height || 0
        };

        this._el = document.createElementNS(Area.SVG_NS, 'rect');
        this._groupEl.appendChild(this._el);

        const x = coords.x - this._coords.width / 2;
        const y = coords.y - this._coords.height / 2;

        this._helpers = {
            center: new Helper(this._groupEl, x, y, 'move'),
            top: new Helper(this._groupEl, x, y, 'editTop'),
            bottom: new Helper(this._groupEl, x, y, 'editBottom'),
            left: new Helper(this._groupEl, x, y, 'editLeft'),
            right: new Helper(this._groupEl, x, y, 'editRight'),
            topLeft: new Helper(this._groupEl, x, y, 'editTopLeft'),
            topRight: new Helper(this._groupEl, x, y, 'editTopRight'),
            bottomLeft: new Helper(this._groupEl, x, y, 'editBottomLeft'),
            bottomRight: new Helper(this._groupEl, x, y, 'editBottomRight')
        };

        this.redraw();
    }

    setSVGCoords = (coords) => {
        this._el.setAttribute('x', coords.x);
        this._el.setAttribute('y', coords.y);
        this._el.setAttribute('width', coords.width);
        this._el.setAttribute('height', coords.height);
        const top = coords.y;
        const centerY = coords.y + coords.height / 2;
        const bottom = coords.y + coords.height;
        const left = coords.x;
        const centerX = coords.x + coords.width / 2;
        const right = coords.x + coords.width;
        this._helpers.center.setCoords(centerX, centerY);
        this._helpers.top.setCoords(centerX, top);
        this._helpers.bottom.setCoords(centerX, bottom);
        this._helpers.left.setCoords(left, centerY);
        this._helpers.right.setCoords(right, centerY);
        this._helpers.topLeft.setCoords(left, top);
        this._helpers.topRight.setCoords(right, top);
        this._helpers.bottomLeft.setCoords(left, bottom);
        this._helpers.bottomRight.setCoords(right, bottom);
        return this;
    };

    setCoords = (coords) => {
        this._coords.x = coords.x;
        this._coords.y = coords.y;
        this._coords.width = coords.width;
        this._coords.height = coords.height;
        return this;
    };

    dynamicDraw = (x, y, isSquare) => {
        let newCoords = {
            x: this._coords.x,
            y: this._coords.y,
            width: x - this._coords.x,
            height: y - this._coords.y
        };

        if (isSquare) {
            newCoords = Rectangle.getSquareCoords(newCoords);
        }

        newCoords = Rectangle.getNormalizedCoords(newCoords);
        this.redraw(newCoords);
        return newCoords;
    };

    onProcessDrawing = (e) => {
        const coords = utils.getRightCoords(e.pageX, e.pageY, this._app);
        this.dynamicDraw(coords.x, coords.y, e.shiftKey);
    };

    onStopDrawing = (e) => {
        const coords = utils.getRightCoords(e.pageX, e.pageY, this._app);
        this.setCoords(this.dynamicDraw(coords.x, coords.y, e.shiftKey)).deselect();
        this._app.removeAllEvents()
            .setIsDraw(false)
            .resetNewArea();
    };

    edit = (editingType, dx, dy) => {
        const tempParams = Object.create(this._coords);

        switch (editingType) {
            case 'move':
                tempParams.x += dx;
                tempParams.y += dy;
                break;

            case 'editLeft':
                tempParams.x += dx;
                tempParams.width -= dx;
                break;

            case 'editRight':
                tempParams.width += dx;
                break;

            case 'editTop':
                tempParams.y += dy;
                tempParams.height -= dy;
                break;

            case 'editBottom':
                tempParams.height += dy;
                break;

            case 'editTopLeft':
                tempParams.x += dx;
                tempParams.y += dy;
                tempParams.width -= dx;
                tempParams.height -= dy;
                break;

            case 'editTopRight':
                tempParams.y += dy;
                tempParams.width += dx;
                tempParams.height -= dy;
                break;

            case 'editBottomLeft':
                tempParams.x += dx;
                tempParams.width -= dx;
                tempParams.height += dy;
                break;

            case 'editBottomRight':
                tempParams.width += dx;
                tempParams.height += dy;
                break;

            default: throw new Error('Canot perform edit');
        }

        return tempParams;
    };

    dynamicEdit = (coords) => {
        coords = Rectangle.getNormalizedCoords(coords);
        this.redraw(coords);
        return coords;
    };

    onProcessEditing = (e) => {
        return this.dynamicEdit(
            this.edit(
                this._app.getEditType(),
                e.pageX - this.editingStartPoint.x,
                e.pageY - this.editingStartPoint.y
            ),
        );
    };

    onStopEditing = (e) => {
        this.setCoords(this.onProcessEditing(e));
        this._app.removeAllEvents();
    };

    toString = () => {
        return 'Rectangle {x: ' + this._coords.x +
                ', y: ' + this._coords.y +
                ', width: ' + this._coords.width +
                ', height: ' + this._coords.height + '}';
    };

    toMapElement = () => {
        const x2 = this._coords.x + this._coords.width;
        const y2 = this._coords.y + this._coords.height;
        const obj = {
            shape: 'rect',
            coords: [this._coords.x, this._coords.y, x2, y2]
        };

        obj.alt = this._attributes.alt || '';
        obj.floor = this._attributes.floor || '';
        obj.href = this._attributes.href || '';
        obj.recordId = this._attributes.recordId || '';
        obj.sold = !!this._attributes.sold;
        obj.title = this._attributes.title || '';

        return obj;
    };

    static transformCoordsForInit = (coords) => {
        return {
            x: coords[0],
            y: coords[1],
            width: coords[2] - coords[0],
            height: coords[3] - coords[1]
        };
    };

    getCoordsForDisplayingInfo() {
        return {
            x: this._coords.x,
            y: this._coords.y
        };
    }

    static testCoords = coords => coords.x && coords.y && coords.width && coords.height;

    static testHTMLCoords = coords => coords.length === 4;

    static getCoordsFromHTMLArray = (htmlCoordsArray) => {
        if (!Rectangle.testHTMLCoords(htmlCoordsArray)) {
            throw new Error('This html-coordinates is not valid for rectangle');
        }

        return {
            x: htmlCoordsArray[0],
            y: htmlCoordsArray[1],
            width: htmlCoordsArray[2] - htmlCoordsArray[0],
            height: htmlCoordsArray[3] - htmlCoordsArray[1]
        };
    };

    static getNormalizedCoords = (coords) => {
        if (coords.width < 0) {
            coords.x += coords.width;
            coords.width = Math.abs(coords.width);
        }

        if (coords.height < 0) {
            coords.y += coords.height;
            coords.height = Math.abs(coords.height);
        }

        return coords;
    };

    static getSquareCoords = (coords) => {
        const width = Math.abs(coords.width);
        const height = Math.abs(coords.height);

        if (width > height) {
            coords.width = coords.width > 0 ? height : -height;
        } else {
            coords.height = coords.height > 0 ? width : -width;
        }

        return coords;
    };

    static createAndStartDrawing = (firstPointCoords, app) => {
        const newArea = new Rectangle({
            x: firstPointCoords.x,
            y: firstPointCoords.y,
            width: 0,
            height: 0,
        }, {}, app);

        app.addEvent(app.refs.container, 'mousemove', newArea.onProcessDrawing.bind(newArea))
            .addEvent(app.refs.container, 'click', newArea.onStopDrawing.bind(newArea));

        return newArea;
    };
}
