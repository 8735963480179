import overlay from 'general/js/overlay/js/default-overlay';
import contentBlocker from 'general/js/content-blocker';
import AbstractPageOverview from './abstract-page-overview';

const PAGE_OVERVIEW_RETAINER_ID = 'sub-navigation__full-view';

export default class PageOverviewMobile extends AbstractPageOverview {
    constructor(...args) {
        super(...args);
        this.usubscribeDeviceTypeChange = null;
        this._init();
        this.subNavigationList = document.getElementsByClassName(PAGE_OVERVIEW_RETAINER_ID)[0];
    }

    destroy() {
        super.destroy();
        this._closeList();
    }

    _addListeners() {
        super._addListeners();
        this.refs.close.addEventListener('click', this._onCloseClick);
    }

    _removeListeners() {
        super._removeListeners();
        this.refs.close.removeEventListener('click', this._onCloseClick);
        this.beforeLinkClickHandle();
    }

    beforeLinkClickHandle() {
        this._closeList();
    }

    _openList() {
        this.subNavigationList.classList.add('is-opened');
        contentBlocker.block(PAGE_OVERVIEW_RETAINER_ID);
        overlay.show(PAGE_OVERVIEW_RETAINER_ID);
        this.usubscribeDeviceTypeChange = overlay.subscribeOnOverlayClick(this._onOverlayClick);
    }

    _onOverlayClick = () => {
        this._closeList();
    };

    _onCloseClick = () => {
        this._closeList();
    };

    _closeList() {
        document.body.classList.remove('prevent-scroll');
        this.subNavigationList.classList.remove('is-opened');
        contentBlocker.unblock(PAGE_OVERVIEW_RETAINER_ID);
        overlay.hide(PAGE_OVERVIEW_RETAINER_ID);
        if (this.usubscribeDeviceTypeChange) {
            this.usubscribeDeviceTypeChange();
        }
    }
}
