import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { CampaignSearchResultsContext } from '@Components/campaign-property-search/js/context';
import CampaignSearchDesktopBadge from './campaign-desktop-sorting-badge';

const CampaignSearchDesktopSorting = ({
    filtersConfig,
    campaignSearchUrl,
}) => {
    const {
        filterOptions,
        setFilterOptions,
        setIsError,
        setResponseData,
        setIsLoading,
        isLoading,
        locationSearchId,
        placeId
    } = useContext(CampaignSearchResultsContext);
    const [activeItem, setActiveItem] = useState(locationSearchId ? 'Distance' : 'Price');

    useEffect(() => {
        if (locationSearchId !== null) {
            setActiveItem('Distance');
        } else setActiveItem('Price');
    }, [locationSearchId]);

    const sortConfig = filtersConfig.find((item) => item.name === 'sort');

    const sortData = (sortOption) => {
        const sortingUrl = locationSearchId 
            ? `${campaignSearchUrl}&q=${locationSearchId}` 
            : placeId ? 
                `${campaignSearchUrl}&id=${placeId}` 
                : campaignSearchUrl;  

        const sortingState = {
            ...filterOptions,
            offset: 0,
            sort: sortOption
        };

        setIsLoading(true);
        setFilterOptions(sortingState);

        axios({
            method: 'post',
            url: sortingUrl,
            data: sortingState
        }).then((res) => {
            if (!res.data) {
                if (setIsError) {
                    setIsError(true);
                }
                console.log('failed to get data');
                return;
            }

            if (setResponseData) {
                setResponseData(res.data);
                setIsLoading(false);
            }
        }).catch((error) => {
            console.log('Could not perform request', error);
        });
    };

    return (
        <div className='campaign-search-desktop-sorting'>
            <div className='campaign-search-desktop-sorting__text'>Sort by:</div>
            <ul className='campaign-search-desktop-sorting__options-list'>
                {
                    sortConfig.options.map((option) => (
                        <CampaignSearchDesktopBadge
                            filterOptions={filterOptions}
                            option={option}
                            key={option.text}
                            optionValue={option.value}
                            text={option.text}
                            activeItem={activeItem}
                            sortData={sortData}
                            isDisabled={option.value === 'Distance' && !locationSearchId}
                            isLoading={isLoading}
                            locationId={locationSearchId}
                            setActiveItem={setActiveItem}
                        />
                    ))
                }
            </ul>
        </div>
    );
};

CampaignSearchDesktopSorting.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
};

export default CampaignSearchDesktopSorting;
