import DcBaseComponent from 'general/js/dc/dc-base-component';
import viewport from '../../../general/js/viewport';
import { isBoolean, isInteger } from 'lodash';

const DEFAULT_STEP = 4;
const DEFAULT_MIN_FONT = 14;

export default class ShrinkTextComponent extends DcBaseComponent {
    static getNamespace() {
        return 'shrink-text';
    }

    onInit() {
        this.minFontSize = isInteger(this.options.minFontSize)
            ? this.options.minFontSize
            : DEFAULT_MIN_FONT;
        this.step = isInteger(this.options.step)
            ? this.options.step
            : DEFAULT_STEP;
        this.widthOnly = isBoolean(this.options.widthOnly)
            ? this.options.widthOnly
            : true;
        this.shrinkText();
        this.unsubscribeFromViewport = viewport.subscribeOnResize(
            this.shrinkText
        );
    }

    onDestroy() {
        this.unsubscribeFromViewport();
    }

    shrinkText = () => {
        this.resetComponentInlineStyles();
        this.setWordBreakNormal();
        const fontSize = this.getFontSizeFromCss();

        if (this.isOverflown()) {
            this.disappear();
            this.setFontSize(fontSize - this.step, true);
        } else {
            this.resetComponentInlineStyles();
        }
    };

    resetComponentInlineStyles = () => {
        this.element.style.fontSize = '';
        this.element.style.wordBreak = '';
        this.element.style.opacity = '';
        this.element.style.transition = '';
    };

    setWordBreakNormal = () => {
        this.element.style.wordBreak = 'normal';
    };

    disappear = () => {
        this.element.style.opacity = '0';
        this.element.style.transition = '';
    };

    reveal = () => {
        this.element.style.opacity = '1';
        this.element.style.transition = 'opacity .7s ease';
    };

    getFontSizeFromCss = () => {
        return parseFloat(
            window
                .getComputedStyle(this.element, null)
                .getPropertyValue('font-size'),
            10
        );
    };

    isOverflown = () => {
        const overflowWidth =
            this.element.clientWidth < this.element.scrollWidth;
        const overflowHeight =
            this.element.clientHeight < this.element.scrollHeight;

        switch (true) {
            case !this.widthOnly && (overflowWidth || overflowHeight):
            case this.widthOnly && overflowWidth:
                return true;
            default:
                return false;
        }
    };

    setFontSize = (fontSize) => {
        if (fontSize <= this.minFontSize) {
            this.element.style.fontSize = `${this.minFontSize}px`;
            return;
        }

        this.element.style.fontSize = `${fontSize}px`;

        requestIdleCallback(() => {
            if (this.isOverflown()) {
                this.setFontSize(fontSize - this.step);
            } else {
                this.reveal();
            }
        });
    };
}
