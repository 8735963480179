import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from '@General/js/lazy-image';
import {
    devSearchTypeDevelopment,
    devSearchTypeImage,
} from '@Components/developments-search-v2/js/dev-search-types.js';

export default function CampaignDevGallery(props) {
    return (
        <div className="campaign-dev-gallery">
            {props.item.images && props.item.images.items[0] && (
                <div className="campaign-dev-gallery__img">
                    <a
                        className="dev-section-plot__btn dev-section-plot__btn--arrow link"
                        href={props.item.buttonUrl}
                    >
                        <LazyImage image={props.item.images.items[0]} />
                    </a>
                </div>
            )}
            
            {!props.item.images && (
                <div className="campaign-dev-gallery__img">
                    <LazyImage image={props.defaultImage} />
                </div>
            )}
        </div>
    );
}

CampaignDevGallery.propTypes = {
    item: devSearchTypeDevelopment,
    defaultImage: devSearchTypeImage,
};
