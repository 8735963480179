import './scss/index.scss';
import './js/mobile-form.js';

import dcFactory from '../../general/js/dc/dc-factory';
import FormComponent from './js/form.component';
import FormWizardComponent from '../form-wizard/js/form-wizard.component';
import FormSelectComponent from './js/form-select.component';

dcFactory.register(FormComponent);
dcFactory.register(FormWizardComponent);
dcFactory.register(FormSelectComponent);

const files = require.context('./svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);
