import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context.jsx';
import shortid from 'shortid';

class SuggestionItem extends React.Component {
    constructor(props) {
        super(props);
        this.elementRef = React.createRef();

        this.suggestionId = shortid.generate();
    }

    onClick = () => {
        const { item, onSelect } = this.props;
        onSelect(item.id);
    };

    componentDidUpdate(prevProps) {
        if (this.props.isHighlighted) {
            if (
                this.props.isHighlightedFromKeyboard &&
                this.props.highlightedSuggestionIndex !== prevProps.highlightedSuggestionIndex
            ) {
                this.props.onHighlightedElementUpdate(this.elementRef.current);
            }
        }
    }

    render() {
        const {
            item,
            onSuggestionHover,
            isHighlighted,
            renderSuggestion,
            isLast,
            isFirst
        } = this.props;
        const itemId = this.suggestionId;
        return (
            <div
                role="option"
                aria-selected={isHighlighted}
                className="predictive-search__suggestion"
                onClick={this.onClick}
                ref={this.elementRef}
                id={this.suggestionId}
                onMouseEnter={() => onSuggestionHover(item.id)}
            >
                {renderSuggestion(item, { isHighlighted, isLast, isFirst, itemId })}
            </div>
        );
    }
}

SuggestionItem.propTypes = {
    isHighlighted: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    isFirst: PropTypes.bool.isRequired,
    isLast: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired,
    onHighlightedElementUpdate: PropTypes.func.isRequired,
    // context
    highlightedSuggestionIndex: PropTypes.number.isRequired,
    isHighlightedFromKeyboard: PropTypes.bool.isRequired,
    onSuggestionHover: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired
};

export default withContext(SuggestionItem, {
    onSelect: 'onSuggestionSelect',
    highlightedSuggestionIndex: 'highlightedSuggestionIndex',
    isHighlightedFromKeyboard: 'isHighlightedFromKeyboard',
    onSuggestionHover: 'suggestionHover',
    renderSuggestion: 'renderSuggestion'
});
