import React from 'react';
import PropTypes from 'prop-types';
import { devSearchTypeItem } from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import DevSectionFlags from './dev-section-flags.jsx';
import DevSectionPlot from './dev-section-plot.jsx';
import DevSectionGallery from './dev-section-gallery.jsx';

export default function DevSectionCardPopup(props) {
    return (
        <div className="dev-section-card">
            <div className="dev-section-card__row-one">
                <DevSectionGallery gtmSelector={props.gtmSelector} item={props.item.development} />
                <DevSectionFlags item={props.item.development} />
            </div>
            <div className="dev-section-card__row-two">
                <DevSectionPlot gtmSelector={props.gtmSelector} item={props.item.development} />
            </div>
        </div>
    );
}

DevSectionCardPopup.propTypes = {
    item: devSearchTypeItem,
    gtmSelector: PropTypes.string,
};
