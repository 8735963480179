import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';

export default function DevSectionTooltip({ isVisible, tooltipReference, tooltipText }) {
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(tooltipReference, popperElement, {
        placement: 'top',
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            { name: 'offset', options: { offset: [0, 10] } },
        ],
    });

    return (
        <div
            className="dev-section-tooltip"
            ref={setPopperElement}
            style={{ ...styles.popper }}
            {...attributes.popper}
            role="tooltip"
            data-show={isVisible}
        >
            <p className="dev-section-tooltip__text">{tooltipText}</p>
            <div
                className="dev-section-tooltip__arrow"
                ref={setArrowElement}
                style={styles.arrow}
            />
        </div>
    );
}

DevSectionTooltip.propTypes = {
    isVisible: PropTypes.bool,
    tooltipReference: PropTypes.node,
    tooltipText: PropTypes.string,
};
