import React, { useEffect, useRef, useState } from 'react';
import analyticsService from '@General/js/analytics-service.js';
import GenericLocationSearch from '@Assets/components/generic-location-search/js/generic-location-search';

function AdvancedSearchBar(props) {
    const [activeOwnershipOption, setActiveOwnershipOption] = useState(props.ownershipOptions.find((ownershipOption) => ownershipOption.active));
    const [isActive, setIsActive] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [offset, setOffset] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const advancedSearchBarRef = useRef();
    const advancedSearchBarSearchRef = useRef();
    const advancedSearchBarWrapperRef = useRef();

    useEffect(() => {
        sizeWrapper();
        onScroll();

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [isActive, isSticky, offset]);

    useEffect(() => {
        document.addEventListener('click', offClick);

        return () => {
          document.removeEventListener('click', offClick);
        };
    }, []);

    const sizeWrapper = () => {
        advancedSearchBarWrapperRef.current.style.height = `${advancedSearchBarRef.current.getBoundingClientRect().height}px`;

        if (props.overlapAbove) {
            const currentOffset = advancedSearchBarWrapperRef.current.getBoundingClientRect().top - advancedSearchBarRef.current.getBoundingClientRect().top;

            advancedSearchBarWrapperRef.current.style.marginBottom = `-${currentOffset}px`;
            setOffset(currentOffset);
        }
    }

    const onResize = () => {
        setIsSticky(false);

        setTimeout(() => { // wait for sticky style changes to be rendered
            sizeWrapper();
            onScroll();
        }, 0);
    }

    const onScroll = () => {
        if (advancedSearchBarWrapperRef.current.getBoundingClientRect().top < offset) {
            if (!isSticky) {
                setIsSticky(true);
            }

            if (isActive && advancedSearchBarRef.current.getBoundingClientRect().top < -1) {
                document.activeElement.blur();
            }
        } else if (isSticky) {
            setIsSticky(false);
        }
    }

    const offClick = (event) => {
        if (advancedSearchBarRef.current && !(advancedSearchBarRef.current === event.target || advancedSearchBarRef.current.contains(event.target))) {
            setIsActive(false);
        }
    }

    const onSearchAutocomplete = (id, isDevelopment, text, url) => {
        setSearchTerm(text);

        if (isDevelopment) {
            tracking(isSticky ? 'locationSearchSticky' : 'locationSearchStatic', {searchTerm: text});
            window.document.location = url;
        } else {
            goToResults(`q=${id}`, false);
        }
    }

    const onGetLocation = (id, query) => {
        goToResults(id ? `Id=${id}` : `q=${query}`, true);
    }

    const onSearchSubmit = () => {
        goToResults(`q=${searchTerm}`, false);
    }

    const onFormSubmit = (event) => {
        event.preventDefault();

        onSearchSubmit();
    }

    const goToResults = (query, isGeolocation) => {
        const location = `${activeOwnershipOption && activeOwnershipOption.url ? activeOwnershipOption.url : props.formUrl}`;

        tracking(isSticky ? 'locationSearchSticky' : 'locationSearchStatic', {searchTerm: isGeolocation ? 'Geolocation' : query.substring(2)});
        window.document.location = `${location}${location.includes('?') ? '&' : '?'}${query}`;
    }

    const tracking = (eventName, eventData) => {
        analyticsService._send(eventName, eventData);
    }

    return (
        <div className="advanced-search-bar-wrapper" ref={advancedSearchBarWrapperRef}>
            <form
                className={`advanced-search-bar container ${isActive ? 'advanced-search-bar--active' : ''} ${!isSticky && props.overlapAbove ? 'advanced-search-bar--overlap-above' : isSticky ? 'advanced-search-bar--sticky' : ''}`}
                onClick={() => setIsActive(true)}
                onSubmit={onFormSubmit}
                ref={advancedSearchBarRef}>
                <fieldset className="advanced-search-bar__fieldset">
                    <span className="advanced-search-bar__legend">
                        {props.title}
                    </span>
                    <div className="advanced-search-bar__form-group">
                        {props.ownershipOptions.length > 1 &&
                            <div className="advanced-search-bar__radio-group">
                                {props.ownershipOptions.map((ownershipOption, optionIndex) =>
                                    <label className="advanced-search-bar__radio-label" key={optionIndex}>
                                        <input
                                            checked={activeOwnershipOption && activeOwnershipOption.value === ownershipOption.value}
                                            className="advanced-search-bar__radio"
                                            onChange={() => setActiveOwnershipOption(ownershipOption)}
                                            type="radio"
                                            value={ownershipOption.value}
                                        />
                                        <span className="advanced-search-bar__radio-text">{ownershipOption.title}</span>
                                    </label>
                                )}
                            </div>
                        }
                        <div className="advanced-search-bar__search" ref={advancedSearchBarSearchRef}>
                            <GenericLocationSearch
                                autocompleteApi={props.predictiveSearchOptions.predictiveSearchUrl}
                                buttonText="Search"
                                locationApi={props.reverseGeocodeApiUrl}
                                onGetLocation={onGetLocation}
                                onSearchAutocomplete={onSearchAutocomplete}
                                onSearchSubmit={onSearchSubmit}
                                placeholder={props.predictiveSearchOptions.placeholderMessage}
                                renderId={props.renderId}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            />
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}

export default AdvancedSearchBar;
