import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class SortingRow extends React.Component {
    isSortActive = (headIndex, sortIndex) => {
        return headIndex === this.props.activeHead && sortIndex === this.props.activeSortType;
    };

    isHeadActive = (headIndex) => {
        return headIndex === this.props.activeHead;
    };

    getToggledSortIndex = (headIndex) => {
        if (!this.isHeadActive(headIndex)) {
            return constants.ASC_SORT_INDEX;
        }

        return this.props.activeSortType === constants.ASC_SORT_INDEX
            ? constants.DESC_SORT_INDEX
            : constants.ASC_SORT_INDEX;
    };

    render() {
        const {
            activeTab,
            headings,
            onSortClick,
            tabDisplayOption,
            sortTypes
        } = this.props;

        return (
            <thead className="dev-property-table__table-head">
                <tr className="dev-property-table__table-row">
                    {headings.map((head, headIndex) => {
                        let isHeadingVisible;
                        const activeVisibleColumns = `${activeTab.shortName}TabVisibleColumns`;
                        isHeadingVisible = tabDisplayOption[activeVisibleColumns]
                            && tabDisplayOption[activeVisibleColumns][head.camelCaseId];
                        if (head.camelCaseId === null || head.id === null) isHeadingVisible = true;

                        return (isHeadingVisible && <th
                            className={classNames({
                                'dev-property-table__table-cell': true,
                                'dev-property-table__table-cell--heading': true,
                                'dev-property-table__table-cell--none-tablet': !head.isOnMobile,
                            })}
                            scope="col"
                            key={head.id + headIndex}
                        >
                            {head.id && (
                                <a
                                    className="dev-property-table__sort-container-link"
                                    aria-label={`Toggle sorting order by ${head.displayName}`}
                                    onClick={() =>
                                        onSortClick(
                                            headIndex,
                                            this.getToggledSortIndex(headIndex)
                                        )
                                    }
                                >
                                    {head.displayName}
                                    <ul className="dev-property-table__sort-list">
                                        {sortTypes.map((type, sortIndex) => (
                                            <li
                                                className="dev-property-table__sort-item"
                                                key={head.id + type.id + sortIndex}
                                            >
                                                <div
                                                    className={classNames({
                                                        'dev-property-table__sort-arrow': true,
                                                        [`dev-property-table__sort-arrow--${type.mod}`]: true,
                                                        'dev-property-table__sort-arrow--active': this.isSortActive(
                                                            headIndex,
                                                            sortIndex
                                                        ),
                                                    })}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </a>
                            )}
                            {!head.id && (
                                <span className="visually-hidden">{head.displayName}</span>
                            )}
                        </th>
                        );
                    })}
                </tr>
            </thead>
        );
    }
}

SortingRow.propTypes = {
    headings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            camelCaseId: PropTypes.string,
            displayName: PropTypes.string,
            isOnMobile: PropTypes.bool,
            visibleByDefault: PropTypes.bool,
        })
    ),
    sortTypes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            mod: PropTypes.string,
        })
    ),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        plotsId: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string,
        shortName: PropTypes.string,
    }),
    tabDisplayOption: PropTypes.shape({
        rentTabVisibleColumns: PropTypes.object,
        salesTabVisibleColumns: PropTypes.object,
    }),
    activeHead: PropTypes.number,
    activeSortType: PropTypes.number,
    onSortClick: PropTypes.func,
};

export default withContext(SortingRow, {
    activeHead: 'activeHead',
    activeSortType: 'activeSortType',
    onSortClick: 'onSortClick',
});
