import analyticsService from '@General/js/analytics-service.js';

const trackEvent = (event) => {
    if (event.target.closest('a, button')) {
        try {
            const targetData = JSON.parse(event.currentTarget.dataset.trackEvent);

            if (!targetData.eventData) {
                targetData.eventData = {title: event.target.closest('a, button').innerText};
            }

            analyticsService._send(targetData.eventName, targetData.eventData);
        } catch (error) {
            console.error(error.message);
        }
    }
}

const trackEventReady = () => {
    document.querySelectorAll('[data-track-event]').forEach(eventTracker => {
        eventTracker.addEventListener('click', trackEvent);
    })
}

document.addEventListener('DOMContentLoaded', trackEventReady);
