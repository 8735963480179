import { DURATION } from 'general/js/animations/accordion';
import constants from 'general/js/constants';
import Scroller from 'general/js/scroller';
import AbstractManager from './abstract-manager';
import Accordion from '../../accordion/js/accordion.component';

export default class AccordionManager extends AbstractManager {
    constructor(allowMultiple, ...args) {
        super(...args);
        this.instances = [];
        this.scrollers = new Map();
        this.allowMultiple = allowMultiple;
        this._init();
    }

    _init() {
        this.refs.accordionInstances.forEach((element, index) => {
            const instance = new Accordion(element, {
                isDelegatedControl: true,
                onClickCb: this.onClickCb.bind(this, index),
            });
            this._createScroller(element);
            instance.init();
            this.instances.push(instance);
        });
    }

    onClickCb(index, instance) {
        this.beforeNext(this.instances.indexOf(instance));

        if (!this.allowMultiple) {
            this._collapseOthers(index);
        }

        if (!instance._isExpanded) {
            instance.expand();
            setTimeout(() => {
                this.scrollers.get(instance.element).scrollToTop();
            }, DURATION);
        } else {
            instance.collapse();
        }
    }

    _createScroller(element) {
        if (!this.scrollers.has(element)) {
            const scroller = new Scroller(element, {
                duration: 300,
                scrollOffset: constants.SCROLLING_TOP_OFFSET,
            });
            this.scrollers.set(element, scroller);
        }
    }

    _collapseOthers(index) {
        this.instances.forEach((x, i) => {
            if (x._isExpanded && i !== index) {
                x.collapse();
            }
        });
    }

    destroy() {
        this.instances.forEach((instance) => {
            instance.destroy();
        });
        this.instances = [];
    }
}
