const DEBOUNCE_TIME = 300;
export const SUGGESTION_TYPE_DEVELOPMENT = 'development';
export const SUGGESTION_TYPE_LOCATION = 'osgbLocation';
export const PATHNAME_SALE = '/retirement-properties-for-sale/';
export const PATHNAME_RENT = '/retirement-properties-to-rent/';
export const PATHNAME_OPSO = '/shared-ownership-retirement-properties/';

export default {
    options: {
        DEBOUNCE_TIME,
    },
    keys: {
        ESC: 27,
        TAB: 9,
        RETURN: 13,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40
    }
};
