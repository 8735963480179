import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Scroller from 'general/js/scroller';
import ArticleCard from './article-card';
import ArticlePagination from './article-pagination';
import device, { DEVICE_TYPE_DESKTOP } from '../../../general/js/device';
import FeaturedArticleCard from './featured-article-card';

const LoadingIndicator = () => (
    <div className="article-search-listing__loading-indicator-wrapper">
        <div className="article-search-listing__loading-indicator-content">Please wait, results are loading...</div>
    </div>
);
const ArticleSearchListing = ({
    apiSearchUrl,
    desktopItemsPerPage,
    mobileItemsPerPage,
    datasourceId,
    currentCategory,
    currentCategoryId
}) => {
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, _setKeyword] = React.useState('');

    const keywordRef = React.useRef(keyword);
    const setKeyword = (value) => {
        keywordRef.current = value;
        _setKeyword(value);
    };

    const scrollRef = React.createRef();

    const getSearchTerm = () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const value = params.searchTerm;

        return value;
    };

    const getCategory = (category) => {
        if (category.includes('/')) {
            const categories = category.split('/');

            return categories[1];
        }

        return category;
    };

    useEffect(() => {
        const onPopstate = () => {
            setIsLoading(true);
            const searchTermValue = getSearchTerm(window.location.href);
            setKeyword(searchTermValue);

            axios({
                method: 'post',
                url: `/${apiSearchUrl}?searchTerm=${searchTermValue || ''}`,
                data: {
                    limit: device.isViewportTypeGe(DEVICE_TYPE_DESKTOP) ? desktopItemsPerPage : mobileItemsPerPage,
                    offset: 0,
                    contentSearchConfigId: datasourceId,
                    category: getCategory(currentCategory),
                    categoryId: currentCategoryId
                }
            }).then((res) => {
                setIsLoading(false);

                if (!res.data) {
                    console.error('Failed to fetch data');
                }
                setCurrentPage(1);
                setData(res.data);
            }).catch(() => {
                setIsLoading(false);
                console.error('Failed to fetch data');
            });
        };

        onPopstate();
        window.addEventListener('popstate', onPopstate);
        return () => {
            window.removeEventListener('popstate', onPopstate);
        };
    }, []);

    useEffect(() => {
        if (data) {
            const itemsPerPage = device.isViewportTypeGe(DEVICE_TYPE_DESKTOP) ? desktopItemsPerPage : mobileItemsPerPage;
            const totalPagesNumber = Math.ceil(data.totalNumberOfArticles / itemsPerPage);
            setTotalPages(totalPagesNumber);
        }
    }, [data, desktopItemsPerPage, mobileItemsPerPage]);

    const _getScroller = (element) => {
        const scroller = new Scroller(element, {
            duration: 400,
            scrollOffset: +100
        });

        return scroller;
    };

    const scrollToAnchor = (element) => {
        if (element) {
            const scroller = _getScroller(element);
            return scroller.scrollToTop(false);
        }
    };

    const onPaginate = (page) => {
        setIsLoading(true);
        scrollToAnchor(scrollRef.current);

        const itemsPerPage = device.isViewportTypeGe(DEVICE_TYPE_DESKTOP) ? desktopItemsPerPage : mobileItemsPerPage;

        axios({
            method: 'post',
            url: `/${apiSearchUrl}?searchTerm=${keywordRef.current || ''}`,
            data: {
                limit: itemsPerPage,
                offset: itemsPerPage * (page - 1),
                contentSearchConfigId: datasourceId,
                category: getCategory(currentCategory),
                categoryId: currentCategoryId
            }
        }).then((res) => {
            setIsLoading(false);

            if (!res.data) {
                console.error('Failed to fetch data');
            }

            setCurrentPage(page);
            setData(res.data);
        }).catch(() => {
            console.error('Failed to fetch data');
        });
    };

    const renderShowedArticlesNumber = (className = '') => (
        <div className={`article-search-listing__display-number ${className}`}>{data.searchSummaryMessage}</div>
    );

    const renderTitle = () => {
        if (data.noResults) {
            return data.articles.length > 0 ?
                <h2 className='article-search-listing__title no-results'>{data.pageSubtitle}</h2> :
                null;
        }

        return (keyword ? <h2 className='article-search-listing__title'>{data.resultsMessage}</h2> : null);
    };

    return (
        <div className='article-search-listing container'>
            {isLoading ? <LoadingIndicator /> : <>
            {data.noResults && <div
                className="article-search-listing__no-data richtext"
                dangerouslySetInnerHTML={{ __html: data.noResultsMessage }}
            ></div>
            }
            {data.articles && data.articles.length > 0 && (
                <div>
                    <div className='article-search-listing__top'>
                        {renderTitle()}
                        {renderShowedArticlesNumber()}
                    </div>
                    {data.featuredArticleViewModel && <FeaturedArticleCard { ...data.featuredArticleViewModel } />}
                    <div className="post-preview-container" ref={scrollRef}>
                        {data.articles.map((article) =>
                            <ArticleCard item={article} category={article.category} key={article.url} />)}
                    </div>
                    {renderShowedArticlesNumber('article-search-listing__display-number--bottom')}
                    <ArticlePagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPaginate={onPaginate}
                    />
                </div>)
            }
            </>
            }
        </div>
    );
};

ArticleSearchListing.propTypes = {
    apiSearchUrl: PropTypes.array.isRequired,
    desktopItemsPerPage: PropTypes.number.isRequired,
    mobileItemsPerPage: PropTypes.number.isRequired,
    datasourceId: PropTypes.string,
    currentCategory: PropTypes.string,
    currentCategoryId: PropTypes.string,
    data: PropTypes.shape({
        articles: PropTypes.array.isRequired,
        searchSummaryMessage: PropTypes.string,
        resultsMessage: PropTypes.string,
        noResultsMessage: PropTypes.string,
        totalNumberOfArticles: PropTypes.number,
        noResults: PropTypes.boolean,
        pageSubtitle: PropTypes.string,
    })
};

export default ArticleSearchListing;
