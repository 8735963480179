import { createFocusTrap } from 'focus-trap';
import constants from 'general/js/constants';

export default class AbstractMenu {
    constructor(id) {
        this.id = id;
        this.navigationManager = null;
        this._isOpened = false;
    }

    isOpened() {
        return this._isOpened;
    }

    getId() {
        return this.id;
    }

    bindTo(navigationManager) {
        if (this.canBeRegistered()) {
            this.init();
            this.navigationManager = navigationManager;
            navigationManager.onMenuRegister(this);
        }
    }

    open() {
        this.navigationManager.handleMenuOpening(this.getId()).then(() => {
            this._isOpened = true;
            this.handleOpen();
            this.setFocusTrap();
        });
    }

    close() {
        this._isOpened = false;

        if (this.focusTrapTimeoutId) {
            clearTimeout(this.focusTrapTimeoutId);
        }

        this.navigationManager.onMenuClosed(this.getId());

        if (this.focusTrap) {
            this.focusTrap.deactivate();
            this.focusTrap = null;
        }

        return new Promise((resolve, reject) => {
            resolve(this.handleClose());
        });
    }

    addTransitionClass() {
        this.content.classList.add('is-transitioned');
    }

    removeTransitionClass() {
        this.content.classList.remove('is-transitioned');
    }

    showContent() {
        this.content.classList.add('is-visible');
        setTimeout(() => {
            this.content.classList.add('is-opaque');
        }, 0);
    }

    hideContent() {
        this.content.classList.remove('is-opaque');
        setTimeout(() => {
            this.content.classList.remove('is-visible');
        }, constants.DEFAULT_TRANSITION_DURATION);
    }

    setFocusTrap() {
        this.focusTrapTimeoutId = setTimeout(() => {
            this.focusTrap = createFocusTrap(this.getContentElement(), {
                initialFocus: this.getInitialFocusElement(),
                escapeDeactivates: false,
                clickOutsideDeactivates: true
            });
            try {
                this.focusTrap.activate();
            } catch (error) {
                console.warn(
                    'Focus trap won\'t be made. Because there is no focusable DOM elements within the content:'
                );
                console.warn(this.getContentElement());
            }
        }, 500);
    }

    getContentElement() {
        throw new Error('method Menu::getContent isn`t implemented');
    }

    canBeRegistered() {
        throw new Error('method Menu::canBeRegistered isn`t implemented');
    }

    init() {
        throw new Error('method Menu::init isn`t implemented');
    }

    handleOpen() {
        throw new Error('method Menu::handleOpen isn`t implemented');
    }

    handleClose() {
        throw new Error('method Menu::handleClose isn`t implemented');
    }

    getInitialFocusElement() {
        return null;
    }
}
