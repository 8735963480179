import Scroller from 'general/js/scroller';
import HtmlHelper from 'general/js/html-helper';
import supportsPreventScrollOption from 'general/js/detect-focus-options';

const UNDERLINE_CLASS = 'underline';
export const PAGE_OVERVIEW_LINK_SELECTOR = '.page-overview__link';

export default class AbstractPageOverview {
    constructor(element, links, refs, scrollOffset) {
        this.element = element;
        this.links = links;
        this.refs = refs;
        this.scrollOffset = scrollOffset;
        this.scrollers = new Map();
        this.navigation = document.querySelector('.sub-navigation__cta');
    }

    _init() {
        this._addListeners();
    }

    _addListeners() {
        this.links.forEach((link) =>
            link.addEventListener('click', (e) => this._onLinkClick(e, link)));
    }

    _removeListeners() {
        this.links.forEach((link) =>
            link.removeEventListener('click', (e) => this._onLinkClick(e, link)));
    }

    destroy() {
        this._removeListeners();
    }

    _onLinkClick = (e, link) => {
        this.beforeLinkClickHandle(e);
        this.afterLinkClickHandle(e);

        this.links.forEach((l) => {
            if (l.classList.contains(UNDERLINE_CLASS)) {
                l.classList.remove(UNDERLINE_CLASS);
            }
        });
        this.navigation.classList.remove('is-opened');
        e.preventDefault();
        const targetHash = e.target.closest(PAGE_OVERVIEW_LINK_SELECTOR).hash;
        const element = HtmlHelper.getElementByHash(targetHash);
        this._scrollToAnchor(targetHash, element).then(() => {
            link.classList.add(UNDERLINE_CLASS);
            if (supportsPreventScrollOption) {
                this._setFocus(element);
            }

            const topButton = document.querySelector('.cta-block-buttons__grid-item.scroll-button');
            if (window.pageYOffset > 800) {
                topButton.classList.add('is-button-visible');
            } else {
                topButton.classList.remove('is-button-visible');
            }
        });
    };

    beforeLinkClickHandle(e) {
        // hook for descendants
    }

    afterLinkClickHandle(e) {
        // hook for descendants
    }

    _scrollToAnchor(hash, element) {
        if (element) {
            const scroller = this._getScroller(hash, element);
            return scroller.scrollToTop(false);
        }
    }

    _setFocus(element) {
        element.setAttribute('tabindex', '-1');
        element.focus({ preventScroll: true });
    }

    _getScroller(hash, element) {
        let scroller = null;

        if (this.scrollers.has(hash)) {
            scroller = this.scrollers.get(hash);
        } else {
            scroller = new Scroller(element, {
                duration: 400,
                scrollOffset: this.scrollOffset
            });
            this.scrollers.set(hash, scroller);
        }
        return scroller;
    }
}
