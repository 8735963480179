import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';

class DevelopmentsSearchInfo extends React.PureComponent {
    render() {
        const { totalDevelopmentsCount, totalPlotsCount, locationName } = this.props;
        const propertyWord = totalPlotsCount === 1 ? 'property' : 'properties';
        const developmentWord = totalDevelopmentsCount === 1 ? 'development' : 'developments';

        return (
            <div className="developments-search__results-count-v2">
                <div>
                    {totalPlotsCount > 0 && (
                        <>
                            <strong>{totalPlotsCount}</strong> {propertyWord} across{' '}
                        </>
                    )}
                    {totalDevelopmentsCount} {developmentWord} found{' '}
                    {locationName ? `near ${locationName}` : ''}
                </div>
            </div>
        );
    }
}

DevelopmentsSearchInfo.propTypes = {
    totalDevelopmentsCount: PropTypes.number.isRequired,
    totalPlotsCount: PropTypes.number.isRequired,
    locationName: PropTypes.string,
};

export default withContext(DevelopmentsSearchInfo, {
    totalDevelopmentsCount: 'totalDevelopmentsCount',
    totalPlotsCount: 'totalPlotsCount',
    locationName: 'locationName',
});
