import DcBaseComponent from 'general/js/dc/dc-base-component';
import FormComponent from '../../form/js/form.component';

export default class FormExtendedSubmitComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this.form = null;
    }

    static getNamespace() {
        return 'form-extended-submit';
    }

    onInit() {
        this.form = new FormComponent(this.refs.form);
        this.form.init();
        this.form.getParsleyForm().on('form:submit', this.onSubmit);
    }

    onSubmit = () => {
        this.openNewTab();
    };

    openNewTab() {
        if (this.options.url && typeof this.options.url === 'string')
            window.open(this.options.url, '_blank');
    }
}
