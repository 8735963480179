import Modal from './modal';
import HtmlHelper from './html-helper';
import template from '../html/img-element.hbs';

const CSS_MODIFIER = 'modal--lightbox';

export default class Lightbox {
    constructor(src, width, height) {
        this.src = src;
        this.width = width;
        this.height = height;
        this.element = null;
        this._init();
    }

    open() {
        this.modalInstance.open();
    }

    _init() {
        this._createModal();
    }

    _createModal() {
        const content = this._createImgElement(this.src);
        this.modalInstance = new Modal(content, {
            cssModifier: CSS_MODIFIER,
            width: this.width,
            height: this.height
        });
    }

    _createImgElement() {
        return HtmlHelper.createElement(
            template({
                src: this.src
            })
        );
    }
}
