import React from 'react';
import PropTypes from 'prop-types';
import LazyImage from '@General/js/lazy-image';
import {
    devSearchTypeDevelopment,
    devSearchTypeImage,
} from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import { withContext } from '@Components/developments-search-all-ownership-types/js/context.jsx';

function DevSectionGallery(props) {
    return (
        <div className="dev-section-gallery">
            {props.item.images && props.item.images.items[0] && (
                <div className="dev-section-gallery__img">
                    <a
                        className="dev-section-plot__btn dev-section-plot__btn--arrow link"
                        href={props.item.buttonUrl}
                        data-dc-gtm-selector={props.gtmSelector}
                    >
                        <LazyImage image={props.item.images.items[0]} />
                    </a>
                </div>
            )}

            {props.item.images && (
                <div className="dev-section-gallery__side">
                    {props.item.images.items[1] && (
                        <div className="dev-section-gallery__img dev-section-gallery__img--side">
                            <a
                                className="dev-section-plot__btn dev-section-plot__btn--arrow link"
                                href={props.item.buttonUrl}
                                data-dc-gtm-selector={props.gtmSelector}
                            >
                                <LazyImage image={props.item.images.items[1]} />
                            </a>
                        </div>
                    )}
                    {props.item.images.items[2] && (
                        <div className="dev-section-gallery__img dev-section-gallery__img--side">
                            <a
                                className="dev-section-plot__btn dev-section-plot__btn--arrow link"
                                href={props.item.buttonUrl}
                                data-dc-gtm-selector={props.gtmSelector}
                            >
                                <LazyImage image={props.item.images.items[2]} />
                            </a>
                        </div>
                    )}
                </div>
            )}
            {!props.item.images && (
                <div className="dev-section-gallery__img">
                    <LazyImage image={props.defaultImage} />
                </div>
            )}
        </div>
    );
}

DevSectionGallery.propTypes = {
    item: devSearchTypeDevelopment,
    gtmSelector: PropTypes.string,
    defaultImage: devSearchTypeImage,
};

export default withContext(DevSectionGallery, {
    defaultImage: 'defaultImage',
});
