import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '../../pagination/js/pagination';

const ArticlePagination = (props) => (
    <Pagination
        currentPage={props.currentPage}
        totalPages={props.totalPages}
        onPaginate={props.onPaginate}
    />
);

ArticlePagination.propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPaginate: PropTypes.func
};

export default ArticlePagination;
