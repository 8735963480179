import React from 'react';
import PropTypes from 'prop-types';
import CampaignDevSectionCard from '@Components/campaign-dev-card/js/campaign-dev-card';

const CampaignDevelopmentResults = ({
    developments,
    paginationThreshold,
    noPropertiesText,
    paginationConfig,
    sendMeUpdatesCTAText,
    requestAVisitCTAText,
    waitingListCTAText,
    developmentDescription,
    isMobile,
    availableWithUnpublishedPropertiesDevelopmentText,
    comingSoonDevelopmentText,
    fullyReservedDevelopmentText
}) => {
    return (
        <div className='campaign-search-results'>
            {
                developments?.map((responseItem) => (
                    <div key={responseItem.development.developmentId}>
                        <CampaignDevSectionCard
                            isMobile={isMobile}
                            developmentDescription={developmentDescription}
                            item={responseItem}
                            paginationConfig={paginationConfig}
                            paginationThreshold={paginationThreshold}
                            sendMeUpdatesCTAText={sendMeUpdatesCTAText}
                            requestAVisitCTAText={requestAVisitCTAText}
                            waitingListCTAText= {waitingListCTAText}
                            noPropertiesText={noPropertiesText}
                            availableWithUnpublishedPropertiesDevelopmentText={availableWithUnpublishedPropertiesDevelopmentText}
                            comingSoonDevelopmentText={comingSoonDevelopmentText}
                            fullyReservedDevelopmentText={fullyReservedDevelopmentText}
                            />
                    </div>
                ))
            }
        </div>
    );
};

CampaignDevelopmentResults.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    developments: PropTypes.array.isRequired,
    paginationThreshold: PropTypes.number,
    zeroResultsMessage: PropTypes.string,
    noPropertiesText: PropTypes.string,
    paginationConfig: PropTypes.shape({
        currentPage: PropTypes.number,
        pageCount: PropTypes.number,
        perPage: PropTypes.number,
        totalCount: PropTypes.number,
    }),
    availableWithUnpublishedPropertiesDevelopmentText: PropTypes.string,
    comingSoonDevelopmentText: PropTypes.string,
    fullyReservedDevelopmentText: PropTypes.string,
    sendMeUpdatesCTAText: PropTypes.string,
    requestAVisitCTAText: PropTypes.string,
    waitingListCTAText: PropTypes.string,
    developmentDescription: PropTypes.string,
    isMobile: PropTypes.bool,
};

export default CampaignDevelopmentResults;
