import { clearNode, createDomain } from 'effector/compat';
import analyticsService from 'general/js/analytics-service';
import api from 'general/js/api';
import { TRACKING_LVL_COOKIE_NAME } from 'general/js/cookies.js';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ElementSpinner from 'general/js/element-spinner';
import eventBus from 'general/js/event-bus';
import {
    removeItemFromLocalStorage,
    removeItemFromLocalStorageBySubstring,
    removeItemFromSessionStorage,
    removeItemFromSessionStorageBySubstring,
    saveItemInLocalStorage,
} from 'general/js/helpers/browser-storage';
import NotificationsService from 'general/js/notifications-service';
import { capitalizeFirstLetter } from 'general/js/utils.js';
import Cookies from 'js-cookie';

const RELOAD_MESSAGE = 'Cookie settings are set! Page will reload to proceed.';
const REDIRECT_MESSAGE = "Cookie settings are set! You'll be redirected to the referrer page.";
const DEFAULT_TRACKING_COOKIE_VALUE = 'Preferences';
const DEFAULT_TRACKING_COOKIE_TIME = 365;
const DEFAULT_SETTINGS = {
    statistics: true,
    marketing: true,
};
const BUTTON_EVENT_CATEGORY = 'All Cookies Consent';
const BUTTON_EVENT_ACTION = 'Click';
const REMOVE_TRACKING_KEY = 'mcs_rm_tracking';

const DEFAULT_NECESSARY_SETTINGS = {
    statistics: false,
    marketing: false,
};
export const ALLOW_ALL_COOKIES = {
    statistics: true,
    marketing: true,
};
export const CLOSE_MODAL_EVENT = 'closeModalEvent';
export const CLOSE_COOKIE_BANNER_EVENT = 'closeCookieBannerEvent';

export const sendGtmEvents = (settings) => {
    Object.entries(settings).forEach(([key, value]) => {
        if (value === true) {
            switch (key) {
                case 'statistics':
                    window.dataLayer.push({
                        category: 'Analytical Cookies',
                        action: 'Trigger',
                        label: 'Analytics Success',
                        event: 'AnalyticalCookies',
                    });
                    break;
                case 'marketing':
                    window.dataLayer.push({
                        category: 'Marketing Cookies',
                        action: 'Trigger',
                        label: 'Marketing Success',
                        event: 'MarketingCookies',
                    });
                    break;
                default:
                    break;
            }
        }
    });
};

export default class CookieSettingsComponent extends DcBaseComponent {
    static getNamespace() {
        return 'cookie-settings';
    }

    onInit() {
        const valuesToRemove = window.localStorage.getItem(REMOVE_TRACKING_KEY);

        this._removeStorageValues(valuesToRemove);
        this.consentUrl = this.options.consentUrl;
        this.refererUrl = this.options.refererUrl;
        this.initialSettings = this.options.initialSettings || DEFAULT_SETTINGS;

        if (!this.consentUrl) {
            throw new Error('consentUrl was not specified!');
        }

        this.spinner = new ElementSpinner(this.element);
        this.domain = createDomain('cookie-settings-domain');
        this.$store = this.domain.createStore(this.initialSettings);
        this.changeSettingsEvt = this.domain.createEvent();
        this.setupCookiesEvt = this.domain.createEvent();

        this.$store
            .on(this.changeSettingsEvt, (state, [key, value]) => {
                if (Object.keys(state).includes(key)) {
                    const nextState = { ...state };
                    nextState[key] = value;
                    return nextState;
                }
                return state;
            })
            .on(this.setupCookiesEvt, (state) => {
                this.setupCookies(state, { isRedirecting: true });
            });

        this.element.addEventListener('input', this.onControlsInput);
        if (this.refs.acceptSettingsButton) {
            this.refs.acceptSettingsButton.addEventListener('click', this.onSettingsButtonClick);
        }
        if (this.refs.acceptAllButton) {
            this.refs.acceptAllButton.addEventListener('click', this.onAllButtonClick);
        }
        if (this.refs.acceptNecessary) {
            this.refs.acceptNecessary.addEventListener('click', this.onAllNecessaryButtonClick);
        }
    }

    onDestroy() {
        clearNode(this.domain, { deep: true });
        this.element.removeEventListener('change', this.onControlsInput);
        this.refs.acceptSettingsButton.removeEventListener('click', this.onSettingsButtonClick);
        this.refs.acceptAllButton.removeEventListener('click', this.onAllButtonClick);
        this.refs.acceptNecessary.removeEventListener('click', this.onAllNecessaryButtonClick);
    }

    onControlsInput = (e) => {
        if (e.target && e.target.value && typeof e.target.checked === 'boolean') {
            this.changeSettingsEvt([e.target.value.toString(), e.target.checked]);
        }
    };

    disableComponent = () => {
        this.element.style.opacity = 0.5;
        this.element.style.pointerEvents = 'none';
        this.element.style.transition = 'opacity .7s ease';
        this.spinner.show();
    };

    enableComponent = () => {
        this.element.style.opacity = '';
        this.element.style.pointerEvents = '';
        this.element.style.transition = 'opacity .7s ease';
        this.spinner.hide();
    };

    setupCookies = (cookieSettings, options = { isRedirecting: false }) => {
        this.disableComponent();
        api.post(this.consentUrl, cookieSettings)
            .then((response) => {
                const CookiesInstance = Cookies.withConverter({
                    write: (value) => value, // disable encoding to match backend cookie creation process
                });
                if (!CookiesInstance.get(TRACKING_LVL_COOKIE_NAME)) {
                    const cookieValue = Object.entries(cookieSettings).reduce(
                        (acc, [key, value]) => {
                            if (value) {
                                const pascalCaseValue = capitalizeFirstLetter(key);
                                acc += `, ${pascalCaseValue}`;
                            }
                            return acc;
                        },
                        DEFAULT_TRACKING_COOKIE_VALUE
                    );
                    CookiesInstance.set(TRACKING_LVL_COOKIE_NAME, cookieValue, {
                        secure: true,
                        expires: DEFAULT_TRACKING_COOKIE_TIME,
                        sameSite: 'None',
                    });
                }

                if (response.data) {
                    saveItemInLocalStorage(REMOVE_TRACKING_KEY, JSON.stringify(response.data));
                }

                try {
                    const isReloading = !(options.isRedirecting && this.refererUrl);
                    const notificationMessage = isReloading ? RELOAD_MESSAGE : REDIRECT_MESSAGE;
                    NotificationsService.success(notificationMessage);

                    if (!isReloading) {
                        window.location.replace(this.refererUrl);
                    } else {
                        window.location.reload();
                    }
                } catch (error) {
                    console.error(error);
                    this.enableComponent();
                    eventBus.emit(CLOSE_MODAL_EVENT);
                    sendGtmEvents(cookieSettings);
                }
            })
            .catch((error) => {
                console.error(error);
                this.enableComponent();
                NotificationsService.error(
                    'There was trouble with setting up your cookies. Please try again.'
                );
            });
    };

    onSettingsButtonClick = () => {
        analyticsService.sendEvent(
            BUTTON_EVENT_CATEGORY,
            BUTTON_EVENT_ACTION,
            this.getButtonValue(this.refs.acceptSettingsButton),
            this.getClientId()
        );
        this.setupCookiesEvt();
    };

    onAllButtonClick = () => {
        analyticsService.sendEvent(
            BUTTON_EVENT_CATEGORY,
            BUTTON_EVENT_ACTION,
            this.getButtonValue(this.refs.acceptAllButton),
            this.getClientId()
        );
        this.setupCookies(ALLOW_ALL_COOKIES);
    };

    onAllNecessaryButtonClick = () => {
        analyticsService.sendEvent(
            BUTTON_EVENT_CATEGORY,
            BUTTON_EVENT_ACTION,
            this.getButtonValue(this.refs.acceptNecessary),
            this.getClientId()
        );
        this.setupCookies(DEFAULT_NECESSARY_SETTINGS);
    };

    getButtonValue = (buttonName) => buttonName.value;

    getClientId = () => window.appConfig.clientId;

    _removeStorageValues = (valuesToRemove) => {
        if (!valuesToRemove) return;

        try {
            const keys = JSON.parse(valuesToRemove);

            if (!keys) return;

            keys.forEach((key) => {
                const keyWithWildcardIndex = key.indexOf('*');

                if (keyWithWildcardIndex > -1) {
                    const keySubstring = key.replace('*', '');

                    removeItemFromSessionStorageBySubstring(keySubstring);
                    removeItemFromLocalStorageBySubstring(keySubstring);
                } else {
                    removeItemFromSessionStorage(key);
                    removeItemFromLocalStorage(key);
                }
            });

            window.localStorage.removeItem(REMOVE_TRACKING_KEY);
        } catch (e) {
            console.error(error);
        }
    };
}
