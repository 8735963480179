import React from 'react';
import PropTypes from 'prop-types';
import BudgetRadioBedroom from './budget-radio-bedroom.jsx';

export default function BudgetListBedroom({ radios, title, className, changeCallback, activeLabel, dispatchGaEvent }) {
    const activeValueHandler = (label) => {
        if (typeof changeCallback === 'function') {
            changeCallback(label);
        }
    };

    return (
        <div className={`budget-list ${className}`}>
            <h3 className="budget-subtitle">{`${title}:`}</h3>
            <ul className="budget-list__list">
                {radios.map((radio, index) => (
                    <BudgetRadioBedroom
                        key={index}
                        changeCallback={activeValueHandler}
                        isChecked={radio.label === activeLabel}
                        {...radio}
                        dispatchGaEvent={dispatchGaEvent}
                    />
                ))}
            </ul>
        </div>
    );
}

BudgetListBedroom.propTypes = {
    radios: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    className: PropTypes.string,
    changeCallback: PropTypes.func,
    activeValue: PropTypes.string,
    dispatchGaEvent: PropTypes.func
};
