import DcBaseComponent from 'general/js/dc/dc-base-component';
import $ from 'jquery';
import trunk8 from 'trunk8';
import debounce from 'lodash/debounce';

const ELEMENT_VERTICAL_PADDING = 32;
const SEPARATOR_HEIGHT = 33;
const CTA_MARGIN = 16;
const MIN_RICHTEXT_HEIGHT = 20;
const LINE_HEIGHT = 20;

export default class InfoBoxComponent extends DcBaseComponent {
    constructor(args) {
        super(args);

        this._richtextContent = null;
    }

    static getNamespace() {
        return 'info-box';
    }

    static getRequiredRefs() {
        return [
            'header',
            'description'
        ];
    }

    onInit() {
        this._richtextContent = this.refs.description.cloneNode(true);
        this._richtextContent.id = 'replaced';
        this._truncateDescriptionText();
        window.addEventListener('resize', this._updateDescriptionText);
    }

    onDestroy() {
        window.removeEventListener('resize', this._updateDescriptionText);
    }

    _updateDescriptionText = debounce(() => {
        this.refs.description.replaceWith(this._richtextContent);
        this.refs.description = this._richtextContent;
        setTimeout(() => {
            this._truncateDescriptionText();
        }, 0);
    }, 250).bind(this)

    _truncateDescriptionText() {
        const elementHeight = this.element.offsetHeight - ELEMENT_VERTICAL_PADDING;
        const ctaElement = this.refs.cta;
        const ctaHeight = ctaElement ? ctaElement.offsetHeight + CTA_MARGIN : 0;
        const headerHeight = this.refs.header.offsetHeight;
        const remainingHeight = elementHeight - ctaHeight - headerHeight - SEPARATOR_HEIGHT;

        const lines = Math.trunc(remainingHeight / LINE_HEIGHT);

        if (remainingHeight < MIN_RICHTEXT_HEIGHT || lines <= 1) {
            this.refs.description.classList.add('is-hidden');
            return;
        }
        this.refs.description.classList.remove('is-hidden');
        $(this.refs.description).trunk8({
            lines,
        });
    }
}
