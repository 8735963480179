import React, { useEffect, useRef, useState } from 'react';

function EventSearchSort(props) {
    const [expanded, setExpanded] = useState(false);
    const eventSearchSortRef = useRef();
    const currentSort = props.sortOptions.find(sortOption => sortOption.active) ? props.sortOptions.find(sortOption => sortOption.active).name : props.sortOptions[0].name;

    useEffect(() => {
        document.addEventListener('click', offClick);

        return () => {
          document.removeEventListener('click', offClick);
        };
    }, []);

    const offClick = (event) => {
        if (eventSearchSortRef.current && !(eventSearchSortRef.current === event.target || eventSearchSortRef.current.contains(event.target))) {
            setExpanded(false);
        }
    }

    const updateSort = (apiUrl, url, active) => {
        setExpanded(false);

        if (!active) {
            props.goToPage(apiUrl, url);
        }
    }

    return (
        <div className={`event-search-sort ${expanded ? 'event-search-sort--expanded' : ''}`} ref={eventSearchSortRef}>
            <button
                aria-controls={`${props.renderId}-sort-by`}
                aria-expanded={expanded}
                className="event-search-sort__trigger"
                onClick={() => setExpanded(!expanded)} type="button">
                Sort by: <span className="event-search-sort__trigger-name">{currentSort}</span>
            </button>
            {expanded && <div className="event-search-sort__options" id={`${props.renderId}-sort-by`} role="listbox">
                {props.sortOptions.map((sortOption, index) => <button className={`event-search-sort__option ${sortOption.active ? 'event-search-sort__option--active' : ''}`} disabled={!sortOption.enabled} key={index} onClick={() => updateSort(sortOption.apiUrl, sortOption.url,sortOption.active)} role="option" type="button">{sortOption.name}</button>)}
            </div>}
        </div>
    )
}

export default EventSearchSort;
