import { PRICE_MODE_WEEK, PRICE_MODE_MONTH, PRICE_MODE_YEAR } from './constants.js';

export function applyMode(mode, price) {
    price = parseFloat(price); // price is yearly
    switch (mode) {
        case PRICE_MODE_WEEK:
            return parseFloat(price / 52);
        case PRICE_MODE_MONTH:
            return parseFloat(price / 12);
        case PRICE_MODE_YEAR:
            return parseFloat(price); // already yearly price
        default:
            throw new Error('There is no such priceMode');
    }
}

export function extractMode(mode, price) {
    price = parseFloat(price); // can be weekly/monthly/yearly so need to convert back to yearly
    switch (mode) {
        case PRICE_MODE_WEEK:
            return parseFloat(price * 52);
        case PRICE_MODE_MONTH:
            return parseFloat(price * 12);
        case PRICE_MODE_YEAR:
            return parseFloat(price); // already yearly price
        default:
            throw new Error('There is no such priceMode');
    }
}

export function calculateBudget(services) {
    return services.reduce((acc, val) => acc + val.currentPrice, 0);
}

export function formatForView(price) {
    return addPoundSign(price.toFixed(2));
}

export function addPoundSign(price) {
    return '£' + price;
}

export function extractPoundSign(string) {
    return string.replace(/£/g, '');
}

export function getNumberFromString(string) {
    return parseFloat(string.replace(/[^0-9\.]/g, ''));
}

export function mapData(mapping, object) {
    if (
        typeof mapping !== 'object' &&
        mapping == null &&
        typeof mapping !== 'object' &&
        mapping == null
    ) {
        console.error('Invalid arguments for mapData function');
        return null;
    }

    return Object.entries(mapping).reduce((acc, [mapKey, mapValue]) => {
        let newKey = mapKey;

        if (typeof mapValue === 'string' && mapValue.length > 0) {
            newKey = mapValue;
        } else if (typeof mapValue.newName === 'string' && mapValue.newName.length > 0) {
            newKey = mapValue.newName;
        }

        let newValue = null;

        if (typeof mapValue.setDefaultValue === 'function') {
            newValue = mapValue.setDefaultValue(object);
        }

        if (typeof mapValue.setValue === 'function') {
            newValue = mapValue.setValue(object);
        } else if (Object.prototype.hasOwnProperty.call(object, mapKey)) {
            newValue = object[mapKey];
        }

        acc[newKey] = newValue;
        return acc;
    }, {});
}
