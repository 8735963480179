import React from 'react';
import PropTypes from 'prop-types';
import ZeroSearchResults from '../../../zero-search-results/js/zero-search-results'
import { withContext } from '../context';


class PropertiesSearchZeroResults extends React.PureComponent {
    render() {
        const { zeroResults } = this.props;

        return (
            <ZeroSearchResults html={zeroResults.message}/>
        );
    }
}

PropertiesSearchZeroResults.propTypes = {
    zeroResults: PropTypes.shape({
        message: PropTypes.string.isRequired
    }).isRequired
};

export default withContext(PropertiesSearchZeroResults, {
    zeroResults: 'zeroResults'
});
