import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withSearchMobileFiltersContext } from '../search-mobile-filters-context.js';
import trackerService from '@General/js/tracker-service.js'

class MobileFiltersPrices extends React.PureComponent {
    render() {
        const { filters, filtersConfig, onChange, category, isDisabled } = this.props;
        const config = filtersConfig.find((configItem) => configItem.name === category);
        const name = config.name;
        const value = filters[name];
        const label = config.label;
        const currentOption = config.options.find((option) => option.value === value);
        const currentOptionLabel = currentOption && currentOption.text;
        const clickTrigger = config.clickTrigger;

        return (
            <div
                className={classNames({
                    'mobile-filters-prices': true,
                    'mobile-filters-prices--disabled': isDisabled,
                })}
            >
                <label className="mobile-filters-prices__label" htmlFor={label}>
                    {label}
                </label>
                <div className="mobile-filters-prices__select">
                    <div className="mobile-filters-prices__select-value">{currentOptionLabel}</div>
                    <svg
                        className="icon"
                        width="14"
                        height="7"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                    <select
                        id={label}
                        value={value}
                        disabled={isDisabled}
                        onChange={(e) => {                             
                            onChange(name, e.target.value);
                            trackerService.track(clickTrigger);
                        }}
                    >
                        {config.options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.mobileText}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }
}

MobileFiltersPrices.propTypes = {
    isDisabled: PropTypes.bool,
    category: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    filtersConfig: PropTypes.filtersConfig.isRequired,
};

export default withSearchMobileFiltersContext(MobileFiltersPrices, {
    onChange: 'onChange',
    filters: 'filters',
    filtersConfig: 'filtersConfig',
});
