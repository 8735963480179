import React, { useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import CampaignSearchMobileFilters from '@Components/campaign-property-search-filters/js/mobile/campaign-search-mobile-filters';
import CampaignSearchDesktopFilters from '@Components/campaign-property-search-filters/js/desktop/campaign-search-desktop-filters';
import { filters } from '../constants';

import { CampaignSearchResultsContext } from '../context';
import analyticsService from '@General/js/analytics-service.js';
import {
    GA_EVENT_SEARCH_FILTER
} from '../../../../components/listing-v2/js/constants.js';
 

const CampaignSearchFilters = ({ filtersConfig, isFiltersMobile, campaignSearchUrl, onSearchCallback, endOfStickyRef }) => {
    const newFiltersConfig = filtersConfig.slice().sort((a, b) => filters.indexOf(a.name) - filters.indexOf(b.name));

    const {
        setResponseData,
        setIsLoading,
        setIsError,
        filterOptions,
        setFilterOptions,
        locationSearchId,
        placeId
    } = useContext(CampaignSearchResultsContext) || { responseData: [], isLoading: false };

    const onSearch = (searchUrl, payload) => {

        const urlSplit = window.location.href.split('/');
        let developmentProduct = null;
        if(urlSplit && urlSplit.length > 0){
            developmentProduct = (urlSplit[urlSplit.length - 2]).replaceAll('-', ' ')
        }
        analyticsService._send(
            GA_EVENT_SEARCH_FILTER,
            {
                ...analyticsService.getSearchFilterData(payload),
                developmentProduct
            }
        );
        setIsLoading(true);
        axios({
            method: 'post',
            url: locationSearchId ? `${searchUrl}&q=${locationSearchId}` : placeId ? `${searchUrl}&id=${placeId}` : searchUrl,
            data: {
                ...filterOptions,
                ...payload,
            }
        }).then((res) => {
            if (!res.data) {
                if (setIsError) {
                    setIsError(true);
                }
                console.log('failed to get data');
                return;
            }

            if (setResponseData) {
                setResponseData(res.data);
                setIsLoading(false);
            }

            if (onSearchCallback) { onSearchCallback(); }
        }).catch((error) => {
            console.log('Could not perform request', error);
        });
    };

    return (
        <div className="campaign-search-filters">
            <CampaignSearchMobileFilters
                filtersConfig={newFiltersConfig}
                campaignSearchUrl={campaignSearchUrl}
                onSearch={onSearch}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                endOfStickyRef={endOfStickyRef} />
            <CampaignSearchDesktopFilters
                filtersConfig={newFiltersConfig}
                campaignSearchUrl={campaignSearchUrl}
                onSearch={onSearch}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                locationSearchId={locationSearchId} />
        </div>
    );
};

CampaignSearchFilters.propTypes = {
    isFiltersMobile: PropTypes.bool.isRequired,
    filtersConfig: PropTypes.array.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
    onSearchCallback: PropTypes.func,
    endOfStickyRef: PropTypes.elementType
};

export default CampaignSearchFilters;
