import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { devSearchTypeItem } from '../dev-search-types.js';
import DevSectionCardPopup from '../../../dev-section-card-ownership-types/js/dev-section-card-popup.jsx';

class DevelopmentsSearchItemPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.renderElement = document.createElement('div');
        this.markerStateChangeUnsubscribe = null;
    }

    componentDidMount() {
        this.applyMarker();
    }

    componentDidUpdate(prevProps) {
        const { marker } = this.props;

        if (marker !== prevProps.marker) {
            this.applyMarker();
        }
    }

    componentWillUnmount() {
        const { marker } = this.props;
        if (marker) {
            marker.reset();
        }
        if (this.markerStateChangeUnsubscribe) {
            this.markerStateChangeUnsubscribe();
        }
    }

    applyMarker() {
        const { marker, onMarkerStateChange } = this.props;
        if (marker) {
            marker.setPopupElement(this.renderElement);
            this.markerStateChangeUnsubscribe = marker.onStateChange(onMarkerStateChange);
        }
    }

    renderPopup() {
        const { item, gtmSelector } = this.props;
        return (
            <div className="map-popup__bubble-anchor">
                <div className="map-popup__bubble-content" data-map-content>
                    <DevSectionCardPopup item={item} gtmSelector={gtmSelector} />
                </div>
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(this.renderPopup(), this.renderElement);
    }
}

DevelopmentsSearchItemPopup.defaultProps = {
    gtmSelector: 'ViewPropertiesPopupButton',
};

DevelopmentsSearchItemPopup.propTypes = {
    item: devSearchTypeItem,
    marker: PropTypes.object,
    onMarkerStateChange: PropTypes.func.isRequired,
    gtmSelector: PropTypes.string,
};

export default DevelopmentsSearchItemPopup;
