import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ArrowRightSvg =
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 16">
        <g id="Group_925" data-name="Group 925" transform="translate(-16 0.75)">
            <line id="Line_126" data-name="Line 126" y2="13" transform="translate(26)"
                fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
            <g id="icon-dropdown-arrow" transform="translate(16 26.5) rotate(-90)">
                <path id="Path_367" data-name="Path 367"
                    d="M6.582,8,.322,1.909A1.217,1.217,0,0,1,.219.386,1.053,1.053,0,0,1,1.656.277L6.479,5.063,11.3.277a.959.959,0,0,1,1.437.109,1.1,1.1,0,0,1-.1,1.523Z"
                    transform="translate(13.5 0)" fill="#fff" />
            </g>
        </g>
    </svg>;

const Pagination = (props) => {

    const {
        totalPages,
        currentPage,
        onPaginate
    } = props;

    if (totalPages <= 1) { return null; }

    const getCurrentPages = () => {
        if (totalPages >= 3) {
            if (currentPage === 1) {
                return [
                    currentPage,
                    currentPage + 1,
                    currentPage + 2
                ];
            }

            if (currentPage === totalPages) {
                return [
                    currentPage - 2,
                    currentPage - 1,
                    currentPage,
                ];
            }

            if (currentPage === (totalPages - 2)) {
                return [
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    currentPage + 2
                ];
            }

            if (totalPages === 4) {
                const currentPages = [1, 2, 3, 4];
                return currentPages;
            }

            return [
                currentPage - 1,
                currentPage,
                currentPage + 1
            ];
        }

        if (totalPages === 5 && currentPage === (totalPages - 2)) {
            const currentPages = [1, 2, 3, 4, 5];
            return currentPages;
        }

        return [1, 2];
    };

    const shouldDisplayPageOneButton = (page, total) => {
        return (
            (page >= (total - 2) &&
                !(total === 5 &&
                    page === (total - 2))
            ) &&
            total > 4);
    };

    const shouldDisplayPageLastButton = (page, total) => {
        return (
            (page < (total - 1) &&
                (page !== (total - 2))
            ) &&
            total > 4)
    };

    return (
        <nav className="pagination" role="navigation" aria-label="pagination">
            <ol className="pagination__list">
                {/* First page */}
                <li className="pagination__list-item">
                    <button
                        className='pagination__button pagination__button--arrow pagination__button--arrow-reverse pagination__button--arrow-end'
                        disabled={currentPage === 1}
                        type='button'
                        onClick={() => onPaginate(1)}
                    >
                        <span className='visually-hidden'>Go to first page</span>
                        <div>{ArrowRightSvg}</div>
                    </button>
                </li>

                {/* Previous page */}
                <li className="pagination__list-item">
                    <button
                        className='pagination__button pagination__button--arrow'
                        disabled={currentPage === 1}
                        type='button'
                        onClick={() => onPaginate(currentPage - 1)}
                    >
                        <span className='visually-hidden'>Go to previous page</span>
                        <svg className="icon" width="8" height="13">
                            <use xlinkHref="#icon-arrow-left-thin"></use>
                        </svg>
                    </button>
                </li>

                {
                    shouldDisplayPageOneButton(currentPage, totalPages) &&
                    <>
                        {/* First page */}
                        <li className="pagination__list-item">
                            <button
                                className='pagination__button pagination__button--number'
                                type='button'
                                onClick={() => onPaginate(1)}
                            >
                                <span className='visually-hidden'>Go to page </span><span>1</span>
                            </button>
                        </li>
                        {/* Ellipsis */}
                        <li className="pagination__list-item">
                            <span className='pagination__ellipsis'>&hellip;</span>
                        </li>
                    </>
                }

                {/* Current and surrounding pages */}
                {getCurrentPages().map((page) => (
                    <li className="pagination__list-item" key={Math.random()}>
                        <button
                            className={classNames({
                                'pagination__button': true,
                                'pagination__button--number': true,
                                'pagination__button--active': page === currentPage
                            })}
                            aria-current={page === currentPage}
                            type='button'
                            onClick={() => onPaginate(page)}
                        >
                            <span className='visually-hidden'>Go to page </span><span>{page}</span>
                        </button>
                    </li>
                ))}

                {
                    shouldDisplayPageLastButton(currentPage, totalPages) &&
                    <>
                        {/* Ellipsis */}
                        <li className="pagination__list-item">
                            <span className='pagination__ellipsis'>&hellip;</span>
                        </li>
                        {/* Last page */}
                        <li className="pagination__list-item">
                            <button
                                className='pagination__button pagination__button--number'
                                type='button'
                                onClick={() => onPaginate(totalPages)}
                            >
                                <span className='visually-hidden'>Go to page </span><span>{totalPages}</span>
                            </button>
                        </li>
                    </>
                }

                {/* Next page */}
                <li className="pagination__list-item">
                    <button
                        className='pagination__button pagination__button--arrow'
                        disabled={currentPage === totalPages}
                        type='button'
                        onClick={() => onPaginate(currentPage + 1)}
                    >
                        <span className='visually-hidden'>Go to next page</span>
                        <svg className="icon" width="8" height="13">
                            <use xlinkHref="#icon-arrow-right-thin"></use>
                        </svg>
                    </button>
                </li>

                {/* Last page */}
                <li className="pagination__list-item">
                    <button
                        className='pagination__button pagination__button--arrow pagination__button--arrow-end'
                        disabled={currentPage === totalPages}
                        type='button'
                        onClick={() => onPaginate(totalPages)}
                    >
                        <span className='visually-hidden'>Go to last page</span>
                        <div className='pad-top-1'>{ArrowRightSvg}</div>
                    </button>
                </li>

            </ol>
        </nav>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPaginate: PropTypes.func.isRequired
};

export default Pagination;