import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '@General/js/dc/dc-base-component';
import SubNavigationBottom from './sub-navigation-bottom';

export default class SubNavigationBottomComponent extends DcBaseComponent {
    static getNamespace() {
        return 'sub-navigation-bottom';
    }

    static getRequiredRefs() {
        return ['bottomNavigationWrapper'];
    }

    onInit() {
        this.render();
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    render() {
        ReactDOM.render(
            <SubNavigationBottom
                ctaButtons={this.options.ctaButtonsInfo}
            />,
            this.refs.bottomNavigationWrapper
        );
    }
}
