import React from 'react';
import DevelopmentCard from '../../development-card/js/development-card';

class DevelopmentCarouselCard extends React.PureComponent {
    render() {
        return <DevelopmentCard
            {...this.props}
            alwaysVertical={true}/>;
    }
}

export default DevelopmentCarouselCard;
