import React from 'react';
import PropTypes from 'prop-types';
import DevPlotCard from '../../dev-plot-card/js/dev-plot-card';
import nanoId from 'nano-id';

class DevelopmentCard extends React.PureComponent {
    getBorderClass() {
        const { hasBorder } = this.props;
        return hasBorder ? '' : 'development-card--no-border';
    }

    renderHeader = () => {
        const { item } = this.props;

        return (
            <h3 className="development-card__title">
                <a
                    aria-label={`Go to ${item.title}`}
                    className="development-card__title__link"
                    data-dc-gtm-selector={item.gtmSelector}
                    href={item.buttonUrl ? item.buttonUrl : item.url}
                >
                    {item.title}
                    {item.county && <b> | {item.county}</b>}
                </a>
            </h3>
        );
    };

    renderContent = () => {
        const { item } = this.props;
        const renderId = nanoId();

        return (
            <React.Fragment>
                {/* item.address && (
                    <address className="development-card__address">{item.address}</address>
                )*/}
                {item.ownershipTypesList && item.ownershipTypesList.length > 0 && (
                    <div className="development-card__info">
                        <div className="development-card__info-title">Ownership types:</div>
                        <ul className="development-card__info-list">
                            {item.ownershipTypesList.map((text) => (
                                <li key={text} className="development-card__info-list-item">
                                    <svg
                                        className="icon development-card__info-icon"
                                        width="14"
                                        height="14"
                                        focusable="false"
                                        role="img"
                                        aria-hidden="true"
                                    >
                                        <use xlinkHref="#icon-tick" />
                                    </svg>
                                    <span dangerouslySetInnerHTML={{ __html: text }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {item.prices.map((price, index) => (
                    <div className="development-card__summary dev-plot-card__footer" key={index}>
                        {price.label && <span dangerouslySetInnerHTML={{ __html: price.label }} />}{' '}
                        {price.tooltip && <>
                                <div className="tooltip tooltip--force-above tooltip--dark" data-tooltip>
                                <button aria-describedby={`tooltip_dev-card_${renderId}_${index}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                <div className="tooltip__content" data-tooltip-content id={`tooltip_dev-card_${renderId}_${index}`} role="tooltip">
                                    <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                        __html: price.tooltip,
                                    }}></div>
                                </div>
                            </div>
							{(item.isPreSale && (
							<>
								<small>(example pricing)</small>{' '}
							</>
							))}
                        </>}
                        {price.value && (
                            <strong dangerouslySetInnerHTML={{ __html: price.value }} />
                        )}
                    </div>
                ))}
            </React.Fragment>
        );
    };

    render() {
        const { item, gtmSelector } = this.props;
        return (
            <DevPlotCard
                {...this.props}
                img={item.img}
                url={item.url}
                title={item.title}
                productType={item.productType}
                renderHeader={this.renderHeader}
                renderContent={this.renderContent}
                servicesText={item.servicesText}
                statusText={item.statusText}
                specificClass={`development-card ${this.getBorderClass()}`}
                buttonText={item.buttonText}
                buttonUrl={item.buttonUrl}
                gtmSelector={gtmSelector}
            />
        );
    }
}

DevelopmentCard.defaultProps = {
    hasBorder: true,
};

DevelopmentCard.propTypes = {
    item: PropTypes.object.isRequired,
    hasBorder: PropTypes.bool,
    gtmSelector: PropTypes.string,
};

export default DevelopmentCard;
