import React from 'react';
import PropTypes from 'prop-types';
import VacanciesSearchSelect from '../components/vacancies-search-select';

class VacanciesSearchFilters extends React.PureComponent {
    render() {
        const { filters, filtersConfig, onFiltersApply } = this.props;
        return (
            <div className="vacancies-search__filters">
                {filtersConfig.map(config => (
                    <VacanciesSearchSelect
                        key={config.name}
                        options={config.options}
                        value={filters[config.name]}
                        name={config.name}
                        label={config.label}
                        filtersConfig={filtersConfig}
                        onFiltersApply={onFiltersApply}
                    />
                ))}
            </div>
        );
    }
}

VacanciesSearchFilters.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired
};

export default VacanciesSearchFilters;
