import React from 'react';
import PropTypes from 'prop-types';
import PredictiveSearch from '../../predictive-search/js/predictive-search';

export default class FaqPredictiveSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            suggestions: [],
            areSuggestionsDefined: false,
            viewAll: false
        };

        this.predictiveSearchRef = React.createRef();
    }

    onSuggestionSelect = suggestion => {
        this.setState({ text: suggestion.question });
        this.props.onSuggestionSelect(suggestion);
    };

    handleSuccessReceive = response => {
        this.setState({
            suggestions: response.data.items,
            areSuggestionsDefined: true
        });
    };

    onTextChange = text => {
        this.setState({ text });
    };

    renderSuggestion = (suggestion, { isHighlighted, isLast, isFirst }) => {
        return (
            <div
                className={`faq-predictive__suggestion ${isHighlighted ? 'is-highlighted' : ''} ${
                    !isFirst ? 'faq-predictive__suggestion--not-first' : ''
                }`}
            >
                {suggestion.question}
            </div>
        );
    };

    renderFooter = () => {
        const { viewAll, suggestions } = this.state;
        const { defaultItemsToShow } = this.props;
        if (!viewAll && suggestions.length > defaultItemsToShow) {
            return (
                <button
                    onClick={this.onViewAllButtonClick}
                    type="button"
                    className="btn faq-predictive__view-all-button"
                >
                    View all
                </button>
            );
        }

        return null;
    };

    onViewAllButtonClick = e => {
        e.stopPropagation();
        this.setState({ viewAll: true }, () => {
            this.getPredictiveSearch().focus();
        });
    };

    onClearButtonClick = e => {
        this.setState({ text: '', hasMore: false }, () => {
            this.props.onClearButtonClick();
            this.getPredictiveSearch().focus();
        });
    };

    getPredictiveSearch() {
        return this.predictiveSearchRef.current;
    }

    getSuggestionsToShow() {
        const { suggestions, viewAll } = this.state;
        const { defaultItemsToShow } = this.props;
        return viewAll ? suggestions : suggestions.slice(0, defaultItemsToShow);
    }

    render() {
        const { text, areSuggestionsDefined } = this.state;
        const {
            predictiveSearchUrl,
            placeholderMessage,
            noResultsSuggestionText,
            maxItemsToShow
        } = this.props;

        return (
            <div className="faq-predictive">
                <div className="faq-predictive__placeholder">
                    <PredictiveSearch
                        ref={this.predictiveSearchRef}
                        areSuggestionsDefined={areSuggestionsDefined}
                        suggestions={this.getSuggestionsToShow()}
                        handleSuccessReceive={this.handleSuccessReceive}
                        renderSuggestion={this.renderSuggestion}
                        renderFooter={this.renderFooter}
                        text={text}
                        predictiveSearchUrl={predictiveSearchUrl}
                        onSuggestionSelect={this.onSuggestionSelect}
                        onTextChange={this.onTextChange}
                        placeholderText={placeholderMessage}
                        noResultsSuggestionText={noResultsSuggestionText}
                        inputClass="faq-predictive__input"
                        dropdownClass="faq-predictive__dropdown"
                        spinnerClass="faq-predictive__spinner"
                        predictiveSearchParams={{ limit: maxItemsToShow }}
                    />
                    {text.length > 0 ? (
                        <button
                            type="button"
                            onClick={this.onClearButtonClick}
                            className="btn faq-predictive__clear-button"
                            aria-label="Clear search"
                        >
                            <svg
                                width="12"
                                height="12"
                                className="icon faq-predictive__clear-button-icon"
                                focusable="false"
                                role="img" aria-hidden="true"
                            >
                                <use xlinkHref="#icon-cross" />
                            </svg>
                        </button>
                    ) : (
                        <svg
                            width="17"
                            height="17"
                            className="icon faq-predictive__search-icon"
                            focusable="false"
                            role="img" aria-hidden="true"
                        >
                            <use xlinkHref="#icon-search-long" />
                        </svg>
                    )}
                </div>
            </div>
        );
    }
}

FaqPredictiveSearch.propTypes = {
    defaultItemsToShow: PropTypes.number.isRequired,
    maxItemsToShow: PropTypes.number.isRequired,
    predictiveSearchUrl: PropTypes.string.isRequired,
    noResultsSuggestionText: PropTypes.string.isRequired,
    placeholderMessage: PropTypes.string.isRequired,
    onSuggestionSelect: PropTypes.func.isRequired,
    onClearButtonClick: PropTypes.func.isRequired
};
