import React from 'react';
import PropTypes from 'prop-types';
import pageSpinner from 'general/js/page-spinner';

class PageSpinner extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.isActive !== prevProps.isActive) {
            if (this.props.isActive) {
                pageSpinner.show();
            } else {
                pageSpinner.hide();
            }
        }
    }

    componentWillUnmount() {
        if (this.props.isActive) {
            pageSpinner.hide();
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}

PageSpinner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFaded: PropTypes.bool,
};

export default PageSpinner;
