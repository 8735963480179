import React from 'react';

export default class Accordion extends React.Component {
    render() {
        return (
            <button
                className="dev-property-table__toggle-btn link"
                type="button"
                data-dc-accordion-ref="trigger"
            >
                <span className="dev-property-table__toggle-btn--text to-open">Show</span>
                <span className="dev-property-table__toggle-btn--text to-close">Close</span>
                <svg
                    className="dev-property-table__toggle-btn--icon icon to-open"
                    width="14"
                    height="8"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                    data-dc-accordion-ref="icon"
                >
                    <use xlinkHref="#icon-arrow-down"></use>
                </svg>
                <svg
                    className="dev-property-table__toggle-btn--icon icon to-close"
                    width="14"
                    height="14"
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                    data-dc-accordion-ref="icon"
                >
                    <use xlinkHref="#icon-cross"></use>
                </svg>
            </button>
        );
    }
}
