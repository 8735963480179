import isEqual from 'lodash/isEqual';

export default class ObjectHelper {
    static clone(object) {
        return JSON.parse(JSON.stringify(object));
    }

    static isEqual(arg1, arg2) {
        return isEqual(arg1, arg2);
    }

    static isObject(value) {
        return typeof value === 'object';
    }

    static isString(value) {
        return (typeof value === 'string' || value instanceof String)
    }

    /**
     * This method parse names of the every collection entry and if the name is like "name[]"
     * we will build an array in the collection, if the name is "name[test]" we will add name as object
     *
     * @param collection
     * @param name
     * @param value
     */
    static addToAssociativeCollection(collection, name, value) {
        const arrayRegexp = /(.+)\[(.*)\]/;
        const arrayParseResult = name.match(arrayRegexp);
        if (arrayParseResult !== null) {
            name = arrayParseResult[1];
            const key = arrayParseResult[2];
            const isObject = (key !== '') && (!/^\d+$/.test(key));
            if (!(name in collection)) {
                collection[name] = isObject ? {} : [];
            }
            if (isObject) {
                collection[name][key] = value;
            } else {
                collection[name].push(value);
            }
        } else {
            collection[name] = value;
        }
    }
}

