import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PredictiveSearch from '@Components/predictive-search/js/predictive-search';
import axios from 'axios';
import Dropdown from './dropdown';
import SearchIcon from '../img/search.svg';
import device, { DEVICE_TYPE_DESKTOP } from '../../../general/js/device';

const SUGGESTION_LINE_HEIGHT = 53;

const ArticlesSearch = ({ contentSearchConfig, contentAreaSubMenu, datasourceId }) => {
    const predictiveSearchRef = useRef(null);
    const [suggestions, setSuggestions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [currentCategory, setCurrentCategory] = useState({});
    const [currentSubCategory, setCurrentSubCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [categories, setCategories] = useState([]);

    const dropdownHeightDesktop = contentSearchConfig.suggestedSearch.desktopItemsToDisplay * SUGGESTION_LINE_HEIGHT;
    const dropdownHeightMobile = contentSearchConfig.suggestedSearch.mobileItemsToDisplay * SUGGESTION_LINE_HEIGHT;
    const dropdownMaxHeight = `${(device.isViewportTypeGe(DEVICE_TYPE_DESKTOP) ? dropdownHeightDesktop : dropdownHeightMobile) + 10}px`;

    useEffect(() => {
        const categoryLabel = '';

        const mappedCategories = contentAreaSubMenu.categories.map(((category) => {
            if (category.title === contentAreaSubMenu.selectedCategory) {
                setCurrentCategory({
                    label: category.title,
                    value: category.url,
                });

                if (category.childCategories.length > 0) {
                    const selectedSubCategory = category.childCategories.find((item) => item.title === contentAreaSubMenu.selectedCategory);
                    setCurrentSubCategory(selectedSubCategory);
                }
            }
            return {
                label: category.title,
                value: category.url,
            };
        }));
        setCategories(mappedCategories);

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        const value = params.searchTerm;
        if (value) {
            setSearchText(value);
            fetchSuggestions({ categoryLabel, searchTerm: value });
        }
    }, []);

    const onTextChange = (newText) => {
        setSearchText(newText);
    };

    const fetchSuggestions = ({ categoryLabel, searchTerm }) => {
        axios({
            method: 'get',
            url:
            `/${contentSearchConfig.apiSuggestedSearchUrl}?` +
            `searchTerm=${searchTerm || searchText}&category=${categoryLabel || currentCategory.label}&contentSearchConfigId=${datasourceId}`,
        }).then((res) => {
            if (!res.data) {
                console.error('Failed to fetch data');
            }
            setSuggestions(res.data);
        }).catch(() => {
            console.error('Failed to fetch data');
        });
    };

    const onCategorySelect = (value, label) => {
        setCurrentSubCategory(null);
        setSelectedCategory({
            value,
            label
        });

        fetchSuggestions({ categoryLabel: label });
    };

    const handleSuccessReceive = (response) => {
        setSuggestions(response.data);
    };

    const renderSuggestion = (suggestion, { isHighlighted, isLast }) => (
        <div className='articles-search__suggestion'>
            <a href={suggestion.url} className={`articles-search__suggestion-link ${isHighlighted && 'is-highlighted'}`}>
                {suggestion.title}
            </a>
            {!isLast && <div className='articles-search__suggestion-separator'></div>}
        </div>
    );

    const fireUrlChangesEventWithoutReload = (url) => {
        const historyState = {
            ...window.history.state,
            as: url,
            url,
        };

        window.history.replaceState(historyState, '', url);
        const popStateEvent = new PopStateEvent('popstate', historyState);
        dispatchEvent(popStateEvent);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (contentAreaSubMenu.isOnLandingPage) {
            // If use is in a subcategory landing page,
            // on search - navigate him back to top level category landing page
            if (currentSubCategory) {
                window.location.replace(currentCategory.value + '?searchTerm=' + searchText);
            }

            const isDifferentCategorySelected = selectedCategory.label && (selectedCategory.label !== currentCategory.label);

            if (isDifferentCategorySelected) {
                window.location.replace(selectedCategory.value + '?searchTerm=' + searchText);
            }

            const url = `${window.location.pathname}?searchTerm=${searchText}`;
            fireUrlChangesEventWithoutReload(url);
        } else {
            // Fallback always to redirect
            const categoryValue = selectedCategory.value || currentCategory.value;
            window.location.replace(categoryValue + '?searchTerm=' + searchText);
        }
    };

    return (
        <div className='articles-search'>
            <form onSubmit={onSubmit} role="search" className='articles-search__main container'>
                <div className='articles-search__input-container'>
                    <Dropdown className="articles-search__dropdown" name="selectedCategory" onSelect={onCategorySelect} options={categories} initialValue={currentCategory} key="articles-search" />
                    <div className='articles-search__separator'></div>
                    <div className="articles-search__input-wrapper" >
                        <PredictiveSearch
                            minCharsCount={contentSearchConfig.suggestedSearch.numberOfCharacters}
                            ref={predictiveSearchRef}
                            areSuggestionsDefined={true}
                            suggestions={suggestions}
                            handleSuccessReceive={handleSuccessReceive}
                            renderSuggestion={renderSuggestion}
                            text={searchText}
                            predictiveSearchUrl={
                                '/' +
                                contentSearchConfig.apiSuggestedSearchUrl +
                                `?searchTerm=${searchText}&category=${selectedCategory?.label || currentCategory?.label}&contentSearchConfigId=${datasourceId}`}
                            onSuggestionSelect={() => {}}
                            onTextChange={onTextChange}
                            placeholderText={contentSearchConfig.searchPlaceholderText}
                            noResultsSuggestionText={contentSearchConfig.noSuggestedResultsMessage}
                            inputClass="articles-search__input"
                            dropdownClass="articles-search__suggestions-dropdown"
                            dropdownInlineStyle={{ maxHeight: dropdownMaxHeight }}
                            spinnerClass="articles-search__spinner"
                        />
                    </div>
                </div>
                <button className='btn articles-search__button' title={contentSearchConfig.searchButtonAltText} type='submit'>
                    <img width="24" height="24" src={SearchIcon} alt={contentSearchConfig.searchButtonAltText}/></button>
            </form>
        </div>
    );
};

ArticlesSearch.propTypes = {
    datasourceId: PropTypes.string.isRequired,
    categories: PropTypes.array,
    contentSearchConfig: PropTypes.shape({
        searchPlaceholderText: PropTypes.string,
        noSuggestedResultsMessage: PropTypes.string,
        apiSuggestedSearchUrl: PropTypes.string,
        searchButtonAltText: PropTypes.string,
        suggestedSearch: PropTypes.shape({
            numberOfCharacters: PropTypes.number,
            desktopItemsToDisplay: PropTypes.number,
            mobileItemsToDisplay: PropTypes.number,
        }),
    }).isRequired,
    contentAreaSubMenu: PropTypes.shape({
        categories: PropTypes.array,
        selectedCategory: PropTypes.string,
        isOnLandingPage: PropTypes.bool,
    }),
};

export default ArticlesSearch;
