export const removeItemFromSessionStorage = (key) => window.sessionStorage.removeItem(key);

export const removeItemFromSessionStorageBySubstring = (key) => {
    removeStorageKeyBySubstring(window.sessionStorage, key);
};


export const saveItemInLocalStorage = (key, value) => {
    if (!key) { return; }
    window.localStorage.setItem(key, value);
};

export const removeItemFromLocalStorage = (key) => window.localStorage.removeItem(key);

export const removeItemFromLocalStorageBySubstring = (key) => {
    removeStorageKeyBySubstring(window.localStorage, key);
};


const removeStorageKeyBySubstring = (storage, key) => {
    const storageKeys = Object.keys(storage);

    storageKeys.forEach((storageKey) => {
        if (storageKey.indexOf(key) > -1) {
            storage.removeItem(storageKey);
        }
    });
};
