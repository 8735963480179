import React, { useState, useContext } from 'react';
import { DevelopmentSearchContext } from 'components/developments-search-all-ownership-types/js/context';
import SwiperCarousel from 'general/js/swiper-carousel/js/swiper-carousel';
import FeaturedDevelopmentsCard from './components/featured-developments-card';

const FeaturedDevelopments = () => {
    const contextData = useContext(DevelopmentSearchContext);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { featuredComponent: { backgroundColor, featuredCards, showComponent, tagColour, tagText } } = contextData;

    const toggleAccordion = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            {showComponent && featuredCards.length > 0 && (
                <div className="featured-developments__wrapper" style={{ backgroundColor }}>
                    <div className="featured-developments__accordion" onClick={toggleAccordion}>
                        <div
                            className="featured-developments__label"
                            style={{ backgroundColor: tagColour }}
                        >
                            {tagText}
                        </div>
                        <div
                            className={`featured-developments__accordion-chevron${
                                isCollapsed ? '--collapsed' : ''
                            }`}
                        ></div>
                    </div>
                    <div
                        className={`featured-developments__accordion-content${
                            isCollapsed ? '--collapsed' : ''
                        }`}
                    >
                        <div className="featured-developments__accordion-cards">
                            {featuredCards.map((card, index) => (
                                <FeaturedDevelopmentsCard key={index} {...card} />
                            ))}
                        </div>

                        <div className="featured-developments__carousel-wrapper">
                            <SwiperCarousel>
                                {featuredCards.map((card, index) => (
                                    <FeaturedDevelopmentsCard key={index} {...card} />
                                ))}
                            </SwiperCarousel>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default FeaturedDevelopments;
