import throttle from 'lodash/throttle';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import loquateService from 'general/js/loquate-service';
import notificationService from 'general/js/notifications-service';
import ElementSpinner from 'general/js/element-spinner';
import searchResultsContent from '../html/search-results-content.hbs';
import addressBoxContent from '../html/address-box-content.hbs';
import FormValidityExpander from '../../accordion/js/form-validity-expander';
import dcFactoryInstance from '../../../general/js/dc/dc-factory';

// from https://stackoverflow.com/a/164994/7226643
const POSTCODE_REGEXP = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/g;
const LOQATE_SEARCH_RESULT_TYPE_ADDRESS = 'Address';
const ADDRESS_FIELDSET_SEARCH_RESULT_SELECTOR = '.address-fieldset__result'
const CLASS_NAME_IS_ACTIVE = 'is-active'
const KEY_CODE_ENTER = 13;
const KEY_CODE_UP = 40;
const KEY_CODE_DOWN = 38;
const KEY_CODE_ESC = 27;
const KEY_CODE_TAB = 9;
export default class AddressFieldsetComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.isInProgress = false;
        this._buttonSpinner = null;
        this._mainSpinner = null;
        this._isMainShown = false;
        this._isAddressBoxShown = false;
        this._activeSearchResult = null;
        this._parentForm = null;
        this._fieldsRequiredStateObserver = null
    }

    static getRequiredRefs() {
        return [
            'searchCombobox',
            'searchInput',
            'searchResults',
            'addressBox',
            'addressBoxButton',
            'manualInputButton',
            'main',
            'address1',
            'address2',
            'town',
            'county',
            'country',
            'postcode',
            'addressId'
        ];
    }

    static getNamespace() {
        return 'address-fieldset';
    }

    onInit() {
        this.formValidityExpander = new FormValidityExpander(this.element, this._showMain);
        this.refs.searchInput.addEventListener('keydown', this._onSearchInputKeydown);
        this.refs.searchInput.addEventListener('input', this._onSearchInputChange);
        this.refs.searchResults.addEventListener('click', this._onSearchResultClick);
        this.refs.addressBoxButton.addEventListener('click', this._onManualInputButtonClick);
        this.refs.manualInputButton.addEventListener('click', this._onManualInputButtonClick);
        this._setSearchInputRequiredState(this.refs.address1)
        this.getFieldsRequiredStateObserver().observe(this.refs.address1, {
            attributes: true,
            attributeFilter: [
                'data-parsley-required',
                'data-parsley-mincheck'
            ]
        });
        
        const { address1, address2, town, county, country, postcode } = this.refs;
        [address1, address2, town, county, country, postcode].forEach(a => a.addEventListener('input', this._onInputChange));
		
        if (postcode.value.length > 0) {
			this._onManualInputButtonClick();
		}
    }

    _getMainSpinner() {
        if (this._mainSpinner === null) {
            this._mainSpinner = new ElementSpinner(this.refs.main, { isFaded: true });
        }
        return this._mainSpinner;
    }

    _onInputChange = e => {
        // Reset AddressID due to manual edit
        this.refs.addressId.value = "";
    }

    _onSearchInputKeydown = e => {
        switch (e.which) {
            case KEY_CODE_ENTER: {
                // Handle "ENTER"
                e.preventDefault();
                if (!this._activeSearchResult) {
                    return
                }
                this._onSearchResultAction(this._activeSearchResult);
                break;
            };
            case KEY_CODE_UP: {
                if (!this._activeSearchResult) {
                    return;
                }
                const nextSearchResult = (this._activeSearchResult.nextElementSibling || this._activeSearchResult.parentElement.firstElementChild)
                this._highlightSearchResult(nextSearchResult);
                break;
            };
            case KEY_CODE_DOWN: {
                if (!this._activeSearchResult) {
                    return;
                }
                const previousSearchResult = (this._activeSearchResult.previousElementSibling || this._activeSearchResult.parentElement.lastElementChild)
                this._highlightSearchResult(previousSearchResult);
                break;
            };
            case KEY_CODE_ESC: {
                this._throttledLookup.cancel();
                this._hideSuggestions();
                this.refs['searchInput'].value = '';
            }
            case KEY_CODE_TAB: {
                this._throttledLookup.cancel();
                this._hideSuggestions();
            }
        }
    };

    _onSearchInputChange = e => {
        this._throttledLookup()
    }

    _onSearchResultClick = async e => {
        e.preventDefault();
        const target = (e.target || e.srcElement).closest(ADDRESS_FIELDSET_SEARCH_RESULT_SELECTOR);
        this._onSearchResultAction(target)
    }

    _onSearchResultAction = element => {
        this._throttledLookup.cancel();

        if (element.dataset.loquateType === LOQATE_SEARCH_RESULT_TYPE_ADDRESS) {
            this._fillFieldsetBySuggestionId(element.dataset.loquateId)
        } else {
            loquateService._findAddresses(element.dataset.loquateId).then(suggestions => this._renderSuggestions(suggestions))
        }
    }

    _onManualInputButtonClick = e => {
        if (this._isMainShown) {
            this._hideMain();
        } else {
            this._showMain();
        }
    };

    _throttledLookup = throttle(this._lookup, 500)

    async _lookup() {
        if (!this.isInProgress) {
            this.isInProgress = true;
            const text = this.refs.searchInput.value;

            try {
                const suggestions = await this._getSuggestions(text);
                if (suggestions.length) {
                    this._renderSuggestions(suggestions);
                }
            } catch (e) {
                notificationService.error(e.message);
            }
            this.isInProgress = false;
        }
    }

    async _fillFieldsetBySuggestionId(id) {
        if (!this.isInProgress) {
            this.isInProgress = true;
            this._block({ main: true });

            try {
                const details = await this._getSuggestionDetails(id);
                this._fillAddressBoxWithSuggestionDetails(details);
                this._fillFieldsetFromSuggestionDetails(details)
            } catch (e) {
                notificationService.error(e.message);
            }

            this._showAddressBox();
            this._unblock();
            this.isInProgress = false;
        }
    }

    _fillAddressBoxWithSuggestionDetails(details) {
        const { addressBox } = this.refs;
        const newContent = addressBoxContent({ details: details });

        while(addressBox.firstChild){
            addressBox.removeChild(addressBox.firstChild);
        }
        addressBox.insertAdjacentHTML('beforeend', newContent);
    }

    _fillFieldsetFromSuggestionDetails(details) {
        const { address1, address2, town, county, country, postcode, addressId, searchInput } = this.refs;
        address1.value = details.address1;
        address2.value = details.address2;
        town.value = details.town;
        county.value = details.county;
        country.value = details.country;
        country.classList.remove('has-placeholder');
        postcode.value = details.postcode;
        addressId.value = details.id;
		
		const addressArray = [details.address1, details.address2, details.town, details.county, details.postcode];
		const addressFiltered = addressArray.filter(addressPart => {
		  return addressPart !== null && addressPart !== undefined && addressPart !== '';
		});
        searchInput.value = addressFiltered.join(', ');
		
        const formComponentInstance = dcFactoryInstance._instances.find(component => component.element.getAttribute('data-dc-component-form') === '');

        if (!formComponentInstance) {
            return
        }
        const fields = [address1, address2, town, county, country, postcode]
        formComponentInstance
            .getParsleyForm()
            .fields
            .filter(field => {
                return fields.includes(field.element);
            })
            .forEach(field => {
                field.validate();
            })
    }

    _showSuggestions = () => {
        console.log('_showSuggestions')
        this.refs.searchCombobox.classList.add('is-suggestions-shown');
        this.refs.searchCombobox.setAttribute('aria-expanded', 'true');
        document.addEventListener('click', this._onClickOutside);
    }

    _hideSuggestions = () => {
        this.refs.searchCombobox.classList.remove('is-suggestions-shown');
        this.refs.searchCombobox.setAttribute('aria-expanded', 'false');
        this._clearSelectedSearchResult();
        document.removeEventListener('click', this._onClickOutside);
    }

    _showAddressBox = () => {
        this._hideSuggestions();
        this.refs.searchCombobox.classList.add('is-address-box-shown');
        this._isAddressBoxShown = true;
    }

    _hideAddressBox = () => {
        this.refs.searchCombobox.classList.remove('is-address-box-shown');
        this._isAddressBoxShown = false;
    }

    _showMain = () => {
        this._hideSuggestions();
        this._hideAddressBox();
        this.element.classList.add('is-main-shown');
        this.refs.manualInputButton.childNodes[0].nodeValue = 'Lookup address';
        this._isMainShown = true;
    };

    _hideMain = () => {
        this.element.classList.remove('is-main-shown');
        this.refs.manualInputButton.childNodes[0].nodeValue = 'Enter address manually';
        this._isMainShown = false;
    };

    async _getSuggestions(text) {
        const addresses = await loquateService.find(text);

        return addresses;
    }

    async _getSuggestionDetails(id) {
        const details = await loquateService.getAddressDetails(id);

        return {
            id,
            address1: details.Line1,
            address2: details.Line2,
            town: details.City,
            county: details.ProvinceName || details.Province,
            country: details.CountryIso2,
            countryName: details.CountryName,
            postcode: details.PostalCode
        };
    }

    _showPostcodeValidationError() {
        this.element.classList.add('is-postcode-invalid');
    }

    _hidePostcodeValidationError() {
        this.element.classList.remove('is-postcode-invalid');
    }

    _renderSuggestions(suggestions) {
        const { searchResults } = this.refs;
        const newSuggestionsContent = searchResultsContent({ items: suggestions });

        while(searchResults.firstChild){
            searchResults.removeChild(searchResults.firstChild);
        }
        searchResults.insertAdjacentHTML('beforeend', newSuggestionsContent);
        this._activeSearchResult = searchResults.querySelector(ADDRESS_FIELDSET_SEARCH_RESULT_SELECTOR);

        if (this._activeSearchResult) {
            this._showSuggestions();
            this._highlightSearchResult(this._activeSearchResult)
        }
    }

    _block(options = { main: false }) {
        if (options.main) {
            this._getMainSpinner().show();
        }
    }

    _unblock() {
        this._getMainSpinner().hide();
    }

    onDestroy() {
        this._throttledLookup.cancel();
        this._hideSuggestions()
        this.formValidityExpander.destroy();
    }

    _onClickOutside = e => {
        const isClickInside = this.element.contains(e.target);

        if (!isClickInside) {
            this._hideSuggestions();
        }
    }

    _highlightSearchResult(element) {
        if (this._activeSearchResult) {
            this._activeSearchResult.classList.remove(CLASS_NAME_IS_ACTIVE);
            this._activeSearchResult.setAttribute('aria-selected', 'false');
        }
        this._activeSearchResult = element;
        this._activeSearchResult.classList.add(CLASS_NAME_IS_ACTIVE);
        this.refs['searchInput'].setAttribute('aria-activedescendant', element.id)
        this.refs.searchCombobox.setAttribute('aria-selected', 'true')
        this._adjustSearchResultScroll()
    }

    _clearSelectedSearchResult() {
        if (this._activeSearchResult) {
            this._activeSearchResult.classList.remove(CLASS_NAME_IS_ACTIVE);
            this._activeSearchResult.setAttribute('aria-selected', 'false');
        }
        this._activeSearchResult = null;
        this.refs['searchInput'].removeAttribute('aria-activedescendant')

    }

    _adjustSearchResultScroll() {
        const searchResultTooLow = this.refs['searchResults'].parentElement.getBoundingClientRect().bottom - this._activeSearchResult.getBoundingClientRect().top < 20;
        const searchResultTooHigh = this._activeSearchResult.getBoundingClientRect().bottom - this.refs['searchResults'].parentElement.getBoundingClientRect().top < 20;
        if (searchResultTooLow || searchResultTooHigh) {
            this.refs['searchResults'].parentElement.scrollTop = this._activeSearchResult.offsetTop;
        }
    }

    _setSearchInputRequiredState(field) {
        const isFieldRequired =
            ((field.dataset.parsleyRequired || '').toLowerCase() === 'true')
            || (Number.parseInt(field.dataset.parsleyMincheck || '0') > 0);
        const _searchInput = this.refs.searchInput;

        if (isFieldRequired) {
            _searchInput.placeholder += '*'
        } else {
            _searchInput.placeholder = (_searchInput.placeholder || '').replace('*', '')
        }
    }


    getFieldsRequiredStateObserver() {
        if (this._fieldsRequiredStateObserver === null) {
            this._fieldsRequiredStateObserver = new MutationObserver((mutationRecord, mutationObserver) => {
                mutationRecord.forEach(mutation => {
                    switch (mutation.type) {
                        case 'attributes': this._setSearchInputRequiredState(mutation.target);
                    }
                });
            });
        }

        return this._fieldsRequiredStateObserver;
    }
}
