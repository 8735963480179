class StyleObserver {
    constructor() {
        this._isLoaded = false;
        this._subscribers = [];

        // todo refactor this once critical approach is implemented and we don't need to fallback to load event
        window.addEventListener('load', this._onWindowLoad);
    }

    _onWindowLoad = () => {
        this._handleLoad();
    };


    onLoad(cb) {
        if (this._isLoaded) {
            cb();
        } else {
            this._subscribers.push(cb);
        }
    }

    _handleLoad() {
        if (!this._isLoaded) {
            this._isLoaded = true;
            this._subscribers.forEach(cb => cb());
        }
    }
}

const instance = new StyleObserver();
export default instance;
