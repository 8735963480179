import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class TabsComponent extends DcBaseComponent {
    constructor(element, extendedOptions) {
        super(element);
        this.options = { ...this.options, ...extendedOptions}

        this.activeNavLinkNode = null;
        this.currentContentNode = null;

        this.links = this.refs.links;
        this.content = this.refs.content;
        this.activeClass = 'is-active';
    }

    static getNamespace() {
        return 'tabs';
    }

    static getRequiredRefs() {
        return ['links', 'content'];
    }

    onInit() {
        Object.keys(this.links).forEach((key) => {
            const link = this.links[key];
            link.addEventListener('click', (e) => this.onLinkClick(e, key));

            if (link.classList.contains(this.activeClass)) {
                if (!this.activeNavLinkNode) {
                    this.activeNavLinkNode = link;

                    if (this.content[key]) {
                        this.content[key].classList.add(this.activeClass);
                        this.content[key].setAttribute('aria-hidden', false);
                        this.currentContentNode = this.content[key];
                    }
                } else {
                    link.classList.remove(this.activeClass);
                }
            }
        });
    }

    onLinkClick = (e, key) => {
        this._beforeTabChange(e, key)
        if (this.activeNavLinkNode) {
            this.activeNavLinkNode.classList.remove(this.activeClass);
            this.activeNavLinkNode.setAttribute('aria-selected', false);
        }

        this.activeNavLinkNode = e.target;
        this.activeNavLinkNode.classList.add(this.activeClass);
        this.activeNavLinkNode.setAttribute('aria-selected', true);
        this.showContent(this.content[key]);
    };

    showContent(contentNode) {
        if (this.currentContentNode) {
            if (this.currentContentNode === contentNode) {
                return;
            }
            this.currentContentNode.classList.remove(this.activeClass);
            this.currentContentNode.setAttribute('aria-hidden', true);
        }

        contentNode.classList.add(this.activeClass);
        this.currentContentNode = contentNode;
        this.currentContentNode.setAttribute('aria-hidden', false);
    }

    _beforeTabChange(event, key) {
        if (!this.options.beforeTabChange) return
        if (typeof this.options.beforeTabChange !== 'function') return

        this.options.beforeTabChange(Object.keys(this.links).indexOf(key))
    }
}
