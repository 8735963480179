import React from 'react';
import PropTypes from 'prop-types';
import OverlayWrapper from 'general/js/overlay/js/overlay-wrapper';
import FocusTrapWrapper from 'general/js/focus-trap/focus-trap-wrapper';
import defaultOverlay from 'general/js/overlay/js/default-overlay';
import MobileFiltersChecklist from './mobile-filters-checklist';

export default class MobileFiltersModal extends React.Component {
    constructor(props) {
        super(props);

        this.rootRef = React.createRef();
        this.contentRef = React.createRef();
        this.footerRef = React.createRef();
    }

    componentDidUpdate() {
        ['click', 'touchstart'].forEach(event => document.addEventListener(event, this.outerClick));
    }

    componentWillUnmount() {
        ['click', 'touchstart'].forEach(event =>
            document.removeEventListener(event, this.outerClick)
        );
    }

    outerClick = e => {
        const { isOpened, onOuterClick } = this.props;

        if (isOpened) {
            if (
                this.rootRef.current.contains(e.target) &&
                !this.contentRef.current.contains(e.target) &&
                !this.footerRef.current.contains(e.target)
            ) {
                onOuterClick();
            }
        }
    };

    onSubmit = e => {
        e.preventDefault();
        this.props.onApply();
    };

    getModalClass() {
        const { isOpened } = this.props;
        return isOpened ? 'is-opened' : '';
    }

    render() {
        const {
            isOpened,
            filtersConfig,
            filters,
            clearEnabled,
            onClear,
            onChange,
            onCloseClick
        } = this.props;

        return (
            <OverlayWrapper
                overlay={defaultOverlay}
                isActive={isOpened}
                blockContent={true}
                retainerId="mobile-filters"
            >
                <FocusTrapWrapper targetElement={this.rootRef.current} isActive={isOpened}>
                    <div
                        className={`search-mobile-filters__modal ${this.getModalClass()}`}
                        ref={this.rootRef}
                    >
                        <form
                            onSubmit={this.onSubmit}
                            className="search-mobile-filters__modal-inner"
                        >
                            <div className="search-mobile-filters__content" ref={this.contentRef}>
                                <button
                                    onClick={onCloseClick}
                                    type="button"
                                    aria-label="Close filters"
                                    className="btn search-mobile-filters__close-button"
                                >
                                    <svg
                                        width="9"
                                        height="9"
                                        className="icon"
                                        focusable="false"
                                        role="img" aria-hidden="true"
                                    >
                                        <use xlinkHref="#icon-cross" />
                                    </svg>
                                </button>
                                {filtersConfig.map(config => (
                                    <MobileFiltersChecklist
                                        key={config.name}
                                        label={config.label}
                                        options={config.options}
                                        value={filters[config.name]}
                                        onChange={newValue => onChange(config.name, newValue)}
                                    />
                                ))}
                            </div>
                            <div className="search-mobile-filters__footer" ref={this.footerRef}>
                                <button
                                    type="submit"
                                    aria-label="Apply selected filters"
                                    className="btn search-filters-apply-button"
                                >
                                    Apply
                                </button>
                                <button
                                    onClick={onClear}
                                    disabled={!clearEnabled}
                                    type="button"
                                    aria-label="Clear selected filters"
                                    className="btn search-filters-clear-button"
                                >
                                    Clear selection
                                </button>
                            </div>
                        </form>
                    </div>
                </FocusTrapWrapper>
            </OverlayWrapper>
        );
    }
}

MobileFiltersModal.defaultProps = {};

MobileFiltersModal.propTypes = {
    filters: PropTypes.object.isRequired,
    filtersConfig: PropTypes.array.isRequired,
    isOpened: PropTypes.bool.isRequired,
    clearEnabled: PropTypes.bool.isRequired,
    onClear: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onOuterClick: PropTypes.func.isRequired
};
