import React from 'react';
import BudgetServiceLayout from './budget-service-layout.jsx';
import BudgetDivider from '../components/budget-divider.jsx';
import BudgetText from '../components/budget-text.jsx';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import Filter from '../store/filter';
import PropTypes from 'prop-types';

export default function BudgetServicesWrapper({ dispatchGaEvent }) {
    const [state, dispatch] = useBudgetCalculatorStore();

    if (
        Filter.isFilterValid(state.propertyTypeFilter) &&
        state.propertyTypeFilter.hideCalculations === true
    ) {
        const { replacementText } = state.propertyTypeFilter.activeOption.replacementDescription;

        return (
            <>
                <BudgetText text={replacementText} />
                <BudgetDivider />
            </>
        );
    }

    return (
        <>
            <BudgetServiceLayout dispatchGaEvent={dispatchGaEvent} />
            <BudgetDivider>
                <button
                    className="budget-divider__button"
                    type="button"
                    onClick={() => {
                        dispatch({ type: 'TOGGLE_CALC' });
                    }}
                >
                    <svg
                        className="icon"
                        width="12"
                        height="12"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-cross"></use>
                    </svg>
                </button>
            </BudgetDivider>
        </>
    );
}
BudgetServicesWrapper.propTypes = {
    dispatchGaEvent: PropTypes.func
};

