import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_LABEL_STYLES, DISABLED_LABEL_STYLES }
    from '../../../developments-search-v2/js/components/developments-search-sorting';

class SearchMobileSorting extends React.PureComponent {
    constructor() {
        super();

        this.state = {
            isOpened: false,
            label: 'Sort results',
        };
    }

    componentDidMount() {
        document.querySelector('.main-wrapper__inner').addEventListener('click', ({ target }) => {
            if (!target.closest('.developments-search__sorting-label-v2')) {
                this.close();
            }
        });
    }

    onListClick = () => {
        this.setState((prevState) => {
            return {
                isOpened: !prevState.isOpened
            };
        });
    }

    open() {
        this.setState({ isOpened: true });
    }

    close() {
        this.setState({ isOpened: false });
    }

    render() {
        const { isOpened, label } = this.state;
        const {
            sortOptions,
            value,
            currentOptionLabel,
            currentLabelStyles,
            onFiltersApply,
            name
        } = this.props.searchProps;
        return (
            <div onClick={this.onListClick} className='search-mobile-sorting'>
                <div className="developments-search__sorting-label-v2" htmlFor={label}>
                    <span>{label}</span>
                    <svg
                        width="13"
                        height="8"
                        className="icon search-mobile-filters__control-arrow"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </div>
                <div
                    style={{ display: isOpened ? 'flex' : 'none' }}
                    className='search-mobile-sorting__list-wrapper'>
                    <div className='menu-triangle'></div>
                    <div className="search-mobile-sorting__list">
                        <form
                            id={'sort-by'}
                            value={value}
                            className="js-drop"
                            onChange={(e) => onFiltersApply({ [name]: e.target.value })}>
                            {sortOptions.map((option, index) => {
                                const isDistanceDisabled = option.text.toLowerCase().trim() === 'distance'
                                    && option.isDisabled;
                                const labelStyles = isDistanceDisabled
                                    ? DISABLED_LABEL_STYLES
                                    : DEFAULT_LABEL_STYLES;

                                return (
                                    <label
                                        key={index}
                                        className='search-mobile-sorting__list-option'
                                        style={option.text === currentOptionLabel ? currentLabelStyles : labelStyles}
                                    >
                                        {isDistanceDisabled ?
                                            <input
                                                disabled
                                                type='radio'
                                                id={`item-${index}`}
                                                name='item'
                                                value={option.value}>
                                            </input>
                                            : <input
                                                type='radio'
                                                id={`item-${index}`}
                                                name='item'
                                                value={option.value}>
                                            </input>
                                        }
                                        <span>{option.text}</span>
                                    </label>
                                );
                            })}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

SearchMobileSorting.propTypes = {
    searchProps: PropTypes.shape({
        label: PropTypes.string.isRequired,
        sortOptions: PropTypes.array.isRequired,
        value: PropTypes.string.isRequired,
        onFiltersApply: PropTypes.func.isRequired,
        currentOptionLabel: PropTypes.string.isRequired,
        currentLabelStyles: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
    }),
};

export default SearchMobileSorting;
