import React from 'react';
import PropTypes from 'prop-types';
import PropertiesSearchFilters from '../components/properties-search-filters';
import { withContext } from '../context';

class PropertiesSearchControlsSection extends React.PureComponent {
    render() {
        const { filtersConfig, isFiltersMobile } = this.props;

        return (
            <div className="properties-search__controls-section">
                <div className="properties-search__controls-section-inner">
                    <PropertiesSearchFilters filtersConfig={filtersConfig}
                        isFiltersMobile={isFiltersMobile}/>
                </div>
            </div>
        );
    }
}

PropertiesSearchControlsSection.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    isFiltersMobile: PropTypes.bool.isRequired
};

export default withContext(PropertiesSearchControlsSection, {
    filtersConfig: 'filtersConfig',
    isFiltersMobile: 'isFiltersMobile'
});
