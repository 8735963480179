import React from 'react';
import BudgetPiechart from './budget-piechart.jsx';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import { applyMode, calculateBudget } from '../pure-functions.js';
import Filter from '../store/filter';

export default function BudgetCalculator() {
    const [state] = useBudgetCalculatorStore();

    const getIsActiveChartShown = () => {
        if (
            Filter.isFilterValid(state.propertyTypeFilter) &&
            state.propertyTypeFilter.hideCalculations === true
        ) {
            return false;
        }
        if (!(Array.isArray(state.activeServices) && state.activeServices.length > 0)) {
            return false;
        }

        return true;
    };

    const getIsStaticChartShown = () => {
        if (state.propertyTypeFilter != null && state.propertyTypeFilter.activePrice > 0) {
            return true;
        }

        return false;
    };

    const charts = [];

    if (getIsActiveChartShown()) {
        charts.push({
            title: 'What you pay now',
            sectors: state.activeServices,
            budget: applyMode(state.priceMode, calculateBudget(state.activeServices)),
            point: true,
            tip: null,
        });
    }

    if (getIsStaticChartShown()) {
        charts.push({
            title: 'When you move',
            sectors: [],
            budget: applyMode(state.priceMode, state.propertyTypeFilter.activePrice),
            point: false,
            tip: 'Typical cost based on filters',
        });
    }

    const showCharts = () => (
        <React.Fragment>
            <div className="budget-charts__row">
                {charts.map((chart, index) => (
                    <div className="budget-charts__column" key={index}>
                        <h3 className="budget-subtitle budget-subtitle--center">
                            {chart.title}
                            {chart.tip != null && '*'}
                        </h3>
                    </div>
                ))}
            </div>
            <div className="budget-charts__row">
                {charts.map((chart, index) => (
                    <div className="budget-charts__column" key={index}>
                        <BudgetPiechart
                            sectors={chart.sectors}
                            budget={chart.budget}
                            point={chart.point}
                        />
                    </div>
                ))}
            </div>
            <div className="budget-charts__row">
                {charts.map((chart, index) => (
                    <div className="budget-charts__column" key={index}>
                        {chart.tip != null && (
                            <p className="budget-tip budget-tip--center">*{chart.tip}</p>
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    )

    return (
        <div className="budget-charts">
            {
                getIsActiveChartShown() || getIsStaticChartShown() 
                ? showCharts() 
                : <div className="richtext" dangerouslySetInnerHTML={{ __html: state.propertyTypeFilter.zeroPriceText }}></div>
            }
        </div>
    );
}