import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withContext } from '@Components/developments-search-all-ownership-types/js/context.jsx';
import { devSearchTypeDevelopment } from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import {
    OWNERSHIP_TYPE_SALE,
    OWNERSHIP_TYPE_RENT,
	OWNERSHIP_TYPE_OPSO,
    POA_FULL_TAG,
    POA_SMALL_TAG,
    FR_TAG,
	AN_TAG
} from '@Components/developments-search-all-ownership-types/js/constants.js';

function DevSectionFlags(props) {
    const showSalePrice =
        props.item.salesFormattedPrice &&
        !(props.appliedFilters.ownershipType === OWNERSHIP_TYPE_RENT || props.appliedFilters.ownershipType === OWNERSHIP_TYPE_OPSO) &&
        !props.item.isAllOpso;
    const showRentalPrice =
        props.item.rentalFormattedPrice &&
        !(props.appliedFilters.ownershipType === OWNERSHIP_TYPE_SALE || props.appliedFilters.ownershipType === OWNERSHIP_TYPE_OPSO) &&
        !props.item.isAllOpso;
    const allOpsoProperties = props.item.isAllOpso;
    const showOpsoWithPrice =
        props.item.hasOpsoProperties &&
        props.item.opsoPercentagePriceLabel &&
        props.item.opsoSalesPrice != 0 &&
        props.item.showPercentagePricesOverride !== false;
    const showOpsoWithoutPrice =
        props.item.hasOpsoProperties &&
        (!props.item.opsoPercentagePriceLabel || props.item.opsoSalesPrice == 0 || props.item.showPercentagePricesOverride === false);
    const showOpso = showOpsoWithPrice || showOpsoWithoutPrice;

    const getStatusTag = (isSmall) => {
        if (props.item.fullyReserved) {
            return FR_TAG;
        }
        if (props.item.isInSellingStage) {
            return AN_TAG;
        }

        return isSmall ? POA_SMALL_TAG : POA_FULL_TAG;
    };

    return (
        <>
			{showOpsoWithPrice && (
                <div className="dev-section-flags">
                    <div className="dev-section-flags__column dev-section-flags__column--primary">
                        {(props.item.opsoSalesFormattedPriceTooltip && (
                            <>
								Shared Ownership
                                <div className="tooltip tooltip--right" data-tooltip>
                                    <button aria-describedby={`tooltip_dev-flag_opso-sales-formatted-price_${props.item.id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                    <div className="tooltip__content" data-tooltip-content id={`tooltip_dev-flag_opso-sales-formatted-price_${props.item.id}`} role="tooltip">
                                        <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                            __html: props.item.opsoSalesFormattedPriceTooltip,
                                        }}></div>
                                    </div>
                                </div>
								{(props.item.isInSellingStage === false && (
								<>
									<small>(example pricing)</small>
								</>
								))}
                            </>
						))  ||
							<>
								{props.item.opsoPercentagePriceLabel}
							</>
						}
                        &ensp;
						<strong
							dangerouslySetInnerHTML={{ __html: props.item.opsoSalesFormattedPrice }}
						/>
                    </div>
                </div>
            )}

			{showOpsoWithoutPrice && (
                <div className="dev-section-flags">
                    <div className="dev-section-flags__column dev-section-flags__column--primary">
                        <strong
                            dangerouslySetInnerHTML={{ __html: props.item.opsoAvailableText }}
                        />
                    </div>
                </div>
            )}

            <div className="dev-section-flags">
                {(showSalePrice && (
                    <div className="dev-section-flags__column dev-section-flags__column--primary">
                        {(props.item.salesFormattedPriceTooltip && (
                            <>
                                Buy
                                <div className="tooltip tooltip--right" data-tooltip>
                                    <button aria-describedby={`tooltip_dev-flag_sales-formatted-price_${props.item.id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                    <div className="tooltip__content" data-tooltip-content id={`tooltip_dev-flag_sales-formatted-price_${props.item.id}`} role="tooltip">
                                        <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                            __html: props.item.salesFormattedPriceTooltip,
                                        }}></div>
                                    </div>
                                </div>
                                {(props.item.isInSellingStage === false && (
								<>
									<small>(example pricing)</small>
								</>
								))}
                            </>
                        )) ||
                            <>
                                Buy from
                            </>
                        }
                        &ensp;
                        <strong
                            dangerouslySetInnerHTML={{
                                __html: props.item.salesFormattedPrice,
                            }}
                        />
                    </div>
                )) ||
                    null}

                {(showRentalPrice && (
                    <div className="dev-section-flags__column dev-section-flags__column--primary">
                        {(props.item.rentalFormattedPriceTooltip && (
                            <>
                                Rent
                                <div className="tooltip tooltip--right" data-tooltip>
                                    <button aria-describedby={`tooltip_dev-flag_rental-formatted-price_${props.item.id}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                    <div className="tooltip__content" data-tooltip-content id={`tooltip_dev-flag_rental-formatted-price_${props.item.id}`} role="tooltip">
                                        <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                            __html: props.item.rentalFormattedPriceTooltip,
                                        }}></div>
                                    </div>
                                </div>
                                {(props.item.isInSellingStage === false && (
								<>
									<small>(example pricing)</small>
								</>
								))}
                            </>
                        )) ||
                            <>
                                Rent from
                            </>
                        }
                        &ensp;
                        <strong
                            dangerouslySetInnerHTML={{
                                __html: props.item.rentalFormattedPrice,
                            }}
                        />
                    </div>
                )) || null}

                {!showSalePrice && !showRentalPrice && !allOpsoProperties && !showOpso && (
                    <div
                        className={classNames({
                            'dev-section-flags__column': true,
                            'dev-section-flags__column--primary': !props.item.promo,
                            'dev-section-flags__column--accent': props.item.promo,
                        })}
                    >
                        {props.item.promo ? props.item.promo : getStatusTag(false)}
                    </div>
                )}

                {props.item.promo && (
                    <div
                        className={classNames({
                            'dev-section-flags__column': true,
                            'dev-section-flags__column--promo': true,
                            'dev-section-flags__column--promo-grow' : showOpso && !(showSalePrice || showRentalPrice),
                            'dev-section-flags__column--primary': !(
                                showSalePrice || showRentalPrice || allOpsoProperties || showOpso
                            ),
                            'dev-section-flags__column--accent':
                                showSalePrice || showRentalPrice || allOpsoProperties || showOpso,
                        })}
                    >
                        <strong>
                            {showSalePrice || showRentalPrice || allOpsoProperties || showOpso
                                ? props.item.promo
                                : getStatusTag(true)}
                        </strong>
                    </div>
                )}
            </div>
        </>
    );
}

DevSectionFlags.propTypes = {
    item: devSearchTypeDevelopment,
    appliedFilters: PropTypes.object,
};

export default withContext(DevSectionFlags, {
    appliedFilters: 'appliedFilters',
});
