import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import ZeroSearchResults from '../../../zero-search-results/js/zero-search-results';

class DevelopmentsSearchZeroResults extends React.PureComponent {
    render() {
        const { zeroResults, reSales, filters, locationName } = this.props;

        return (
            <div className="developments-search__zero-results">
                <ZeroSearchResults
                    html={zeroResults.message}
                    reSales={reSales}
                    filters={filters}
                    locationName={locationName} />
            </div>
        );
    }
}

DevelopmentsSearchZeroResults.propTypes = {
    zeroResults: PropTypes.shape({
        message: PropTypes.string.isRequired
    }).isRequired,
    reSales: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    locationName: PropTypes.string
};

export default withContext(DevelopmentsSearchZeroResults, {
    zeroResults: 'zeroResults',
    reSales: 'reSales',
    filters: 'filters',
    locationName: 'locationName'
});
