import anime from 'animejs';
import viewport from './viewport';

class Scroller {
    constructor(element, options = {}) {
        this.element = element;

        this.defaultOptions = {
            scrollOffset: 0,
            duration: 1000
        };

        this.options = { ...this.defaultOptions, ...options };
    }

    getElementDocumentPosition() {
        const box = this.element.getBoundingClientRect();

        return {
            top: box.top + window.pageYOffset,
            bottom: box.bottom + window.pageYOffset
        };
    }

    _scrollTo(coordinate) {
        const topCoordinate = coordinate - this.options.scrollOffset;

        const documentAnimation = anime({
            targets: document.documentElement,
            scrollTop: topCoordinate,
            duration: this.options.duration,
            easing: 'easeInOutSine'
        });

        const bodyAnimation = anime({
            targets: document.body,
            scrollTop: topCoordinate,
            duration: this.options.duration,
            easing: 'easeInOutSine'
        });

        return Promise.all([documentAnimation.finished, bodyAnimation.finished]).then(
            result =>
                new Promise((resolve) => {
                    // scroller resolve end of animation before
                    // it actually ends in Safari, IE and Edge
                    setTimeout(() => {
                        resolve(result);
                    }, 100);
                })
        );
    }

    scrollToTop(safe = false) {
        const topCoordinate = this.getElementDocumentPosition().top;
        if (safe && viewport.isCoordinateWithin(topCoordinate)) {
            return Promise.resolve();
        }
        return this._scrollTo(topCoordinate);
    }

    scrollToBottom() {
        const bottomCoordinate = this.getElementDocumentPosition(this.element).bottom;
        return this._scrollTo(bottomCoordinate);
    }

    shortestScrollTo() {
        const { top, bottom } = this.getElementDocumentPosition();

        if (viewport.isCoordinateWithin(top) && viewport.isCoordinateWithin(bottom)) {
            // if both top and bottom are visible - do not do anything
            return Promise.resolve();
        }

        if (viewport.isCoordinateAbove(top)) {
            // scroll up to top edge
            return this._scrollTo(top);
        }
        // scroll down to bottom edge
        return this._scrollTo(viewport.getViewportTop() + (bottom - viewport.getViewportBottom()));
    }
}

export default Scroller;
