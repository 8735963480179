import DcBaseComponent from 'general/js/dc/dc-base-component';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';
import $ from 'jquery';
import 'slick-carousel';
import device, {
    DEVICE_TYPE_DESKTOP,
    DEVICE_TYPE_DESKTOP_WIDE,
    DEVICE_TYPE_MOBILE,
    DEVICE_TYPE_TABLET,
} from '../../../general/js/device';
import viewport from '../../../general/js/viewport';

export default class TabbedCardComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.slideCarousel = null;
    }

    static getNamespace() {
        return 'tabbed-card';
    }

    onViewportResize = () => {
        const tabContents = document.querySelectorAll('[id*=tab-panel-]');
        Array.from(tabContents).forEach((el) => {
            const tabId = el.getAttribute('id');
            if (document.querySelectorAll(`#${tabId} .product-boxes__content`).length > 0) {
                this.equalHeight(`#${tabId} .product-boxes__item-title`);
                this.equalHeight(`#${tabId} .product-boxes__link-wrapper`);
            }
        });
    };

    onViewportResizeSignpost = () => {
        const tabContents = document.querySelectorAll('[id*=tab-panel-]');
        Array.from(tabContents).forEach((el) => {
            const tabId = el.getAttribute('id');
            if (document.querySelectorAll(`#${tabId} .content-signpost__text-wrapper`).length > 0) {
                this.equalHeight(`#${tabId} .content-signpost__title`);
                this.equalHeight(`#${tabId} .content-signpost__description`);
            }
        });
    };

    onInit() {
        this.toggleContent();

        // Load slick on first tab
        const tabsContentItemFluidClass = '.tabs__content-item__content .fluid-container';
        const tabsContentItemFluid = $(tabsContentItemFluidClass);
        if (tabsContentItemFluid.length) {
            Array.from(tabsContentItemFluid).forEach((el) => {
                // get grid/stacked option value
                const stackedOption = el.closest('.tabs__content').getAttribute('data-grid-option');

                // If slider not initialized and grid/stacked option is false
                if (!$(el).hasClass('slick-initialized') && stackedOption !== 'True') {
                    this.initSlider($(el));
                }

                const elTabPanelId = el.closest('.tabs__content').getAttribute('id');

                // Handle equalHeight for title+link in product boxes
                if (
                    document.querySelectorAll(`#${elTabPanelId} .product-boxes__content`).length > 0
                ) {
                    this.equalHeight(`#${elTabPanelId} .product-boxes__item-title`);
                    this.equalHeight(`#${elTabPanelId} .product-boxes__link-wrapper`);
                    viewport.subscribeOnResize(this.onViewportResize);
                }

                // Handle equalHeight for title+desc in signpost boxes
                if (
                    document.querySelectorAll(`#${elTabPanelId} .content-signpost__text-wrapper`)
                        .length > 0
                ) {
                    this.equalHeight(`#${elTabPanelId} .content-signpost__title`);
                    this.equalHeight(`#${elTabPanelId} .content-signpost__description`);
                    viewport.subscribeOnResize(this.onViewportResizeSignpost);
                }
            });
        }

        // Load slick only when the tab don't have slick yet
        $('.tabbed-card .tabs__nav-link, .tabbed-card__nav-link').on('click', (tabLink) => {
            //Remove is-active class from all tabs links and content
            Array.from(
                tabLink.target.closest('.tabbed-card').querySelectorAll('.tabs__nav-link, .tabbed-card__nav-link')
            ).forEach((el) => el.classList.remove('is-active'));

            Array.from(
                tabLink.target.closest('.tabbed-card').querySelectorAll('.tabs__content')
            ).forEach((el) => el.classList.remove('is-active'));

            const tabId = tabLink.target.getAttribute('id').replaceAll('link', 'panel');

            const tabPanel = $(`#${tabId}`);
            tabPanel.addClass('is-active');

            //$(this).addClass('is-active');
            tabLink.target.classList.add('is-active');

            const tabContents = $(tabPanel).find(tabsContentItemFluidClass);
            const hasSlick = tabContents.hasClass('slick-initialized');

            //This to refresh Slick Slider after tab change.
            if (hasSlick) {
                tabContents.slick('setPosition');
            }

            // get grid/stacked option value
            const stackedOption = tabPanel[0].getAttribute('data-grid-option');

            // If slider not initialized and grid/stacked option is false
            if (!hasSlick && tabContents.length && stackedOption !== 'True') {
                this.initSlider(tabContents);
            }

            // Handle equalHeight for title+link in product boxes
            if (document.querySelectorAll(`#${tabId} .product-boxes__content`).length > 0) {
                this.equalHeight(`#${tabId} .product-boxes__item-title`);
                this.equalHeight(`#${tabId} .product-boxes__link-wrapper`);
            }

            // Handle equalHeight for title+desc in signpost boxes
            if (document.querySelectorAll(`#${tabId} .content-signpost__text-wrapper`).length > 0) {
                this.equalHeight(`#${tabId} .content-signpost__title`);
                this.equalHeight(`#${tabId} .content-signpost__description`);
            }

            // if tabbed card nav
            if (tabLink.target.classList.contains('tabbed-card__nav-link')) {
                // move the dynamic nav
                const nav = tabLink.target.closest('.tabbed-card__nav');

                if (nav) {
                    const dynamicNav = nav.querySelector('.tabbed-card__nav-dynamic');
                    dynamicNav.style.left = `${tabLink.target.offsetLeft}px`;
                    dynamicNav.style.width = `${tabLink.target.clientWidth}px`;
                }

                // make sure selected tab link is fully in view
                const navWrapper = tabLink.target.closest('.tabbed-card__nav-wrapper');

                if (navWrapper && tabLink.target.offsetLeft + tabLink.target.clientWidth > navWrapper.scrollLeft + navWrapper.clientWidth) {
                    navWrapper.scrollLeft += tabLink.target.offsetLeft + tabLink.target.clientWidth - navWrapper.scrollLeft + navWrapper.clientWidth;
                } else if (tabLink.target.offsetLeft < navWrapper.scrollLeft) {
                    navWrapper.scrollLeft = tabLink.target.offsetLeft;
                }
            }
        });

        // add dynamic nav
        document.querySelectorAll('.tabbed-card__nav:not(.tabbed-card__nav--active)').forEach((nav) => {
            nav.classList.add('tabbed-card__nav--active');

            // get active tab button
            const activeTabButton = nav.querySelector('.tabbed-card__nav-link.is-active');

            // create dynamic nav
            const dynamicNav = document.createElement('div');
            dynamicNav.classList.add('tabbed-card__nav-dynamic');

            // set position
            if (activeTabButton) {
                dynamicNav.style.left = `${activeTabButton.offsetLeft}px`;
                dynamicNav.style.width = `${activeTabButton.clientWidth}px`;
            }

            // add to nav
            nav.append(dynamicNav);
        });
    }

    toggleContent() {
        $('.tabs-list__parent').on('click', function (e) {
            e.stopPropagation();
            $(this).toggleClass('collapsed');
            $(this).find('.tabs-list__child').toggleClass('show');
        });
    }

    initSlider(cardSlides) {
        if (!cardSlides) {
            throw new Error('Slider should contain at least 2 sliders and navs/dots');
        }

        const $cardSlides = $(cardSlides);

        const slideClasses = $cardSlides[0].children[0].classList;

        this.slideCarousel = new SlickResponsive(
            $cardSlides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                fade: false,
                accessibility: true,
                draggable: false,
                infinite: false,
                swipe: true,
                touchMove: false,
                arrows: false,
                mobileFirst: true,
                rows: 0,
            },
            {
                [DEVICE_TYPE_MOBILE]: {
                    slidesToShow: 1.18,
                },
                [DEVICE_TYPE_TABLET]: {
                    slidesToShow: this.setTabletSlidesToShow(slideClasses),
                },
                [DEVICE_TYPE_DESKTOP]: {
                    slidesToShow: this.setDesktopSlidesToShow(slideClasses),
                },
                [DEVICE_TYPE_DESKTOP_WIDE]: {
                    slidesToShow: this.setDesktopWideSlidesToShow(slideClasses),
                },
                /*[DEVICE_TYPE_DESKTOP_WIDE]: {
                    settings: 'unslick',
                },*/
            }
        );
    }

    onDestroy() {
        if (this.slideCarousel !== null) {
            this.slideCarousel.slick('unslick');
            this.slideCarousel = null;
        }
    }

    setTabletSlidesToShow(classList) {
        if (classList.contains('fluid-container__col--25')) {
            return 2.25;
        } else if (classList.contains('fluid-container__col--33')) {
            return 2;
        }

        return 2;
    }

    setDesktopSlidesToShow(classList) {
        if (classList.contains('fluid-container__col--25')) {
            return 3.18;
        } else if (classList.contains('fluid-container__col--33')) {
            return 2.25;
        }

        return 2;
    }

    setDesktopWideSlidesToShow(classList) {
        if (classList.contains('fluid-container__col--25')) {
            return 4;
        } else if (classList.contains('fluid-container__col--33')) {
            return 3;
        }

        return 2;
    }

    equalHeight(element) {
        const elements = Array.from(document.querySelectorAll(element));
        const allHeights = [];

        elements.forEach((el, i) => {
            el.style.height = 'auto';
            allHeights.push(el.clientHeight);
        });

        elements.forEach((el, i) => {
            el.style.height = Math.max.apply(Math, allHeights) + 'px';
        });
    }
}
