import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import GenericLocationSearchAutocomplete from './generic-location-search-autocomplete';

/* PROPS:
autocompleteApi:
    url for autocomplete api
buttonText:
    text for submit button
locationApi [optional]:
    url for looking up location from lat/lon
onGetLocation(id, query):
    location id or query returned from location loop up
onSearchAutocomplete(id, isDevelopment, url):
    location id and isDevelopment sent on selection from autocomplete
onSearchSubmit:
    submit searchTerm
placeholder:
    text for search input placeholder
renderId:
    id to link elements
searchTerm and setSearchTerm:
    read/write serch term text
theme [optional]:
    adds modifier css class
*/

function GenericLocationSearch(props) {
    const [searchAutocomplete, setSearchAutocomplete] = useState({});
    const [searchAutocompleteActive, setSearchAutocompleteActive] = useState(false);
    const [searchAutocompleteDisabled, setSearchAutocompleteDisabled] = useState(true);
    const [searchAutocompleteList, setSearchAutocompleteList] = useState([]);
    const [searchAutocompleteListIndex, setSearchAutocompleteListIndex] = useState(0);
    const [searchAutocompleteShowOver, setSearchAutocompleteShowOver] = useState(false);
    const [searchAutocompleteTerm, setSearchAutocompleteTerm] = useState('');
    const [gettingLocation, setGettingLocation] = useState(false);
    const [gettingLocationError, setGettingLocationError] = useState();
    const locationSearchRef = useRef();
    const locationSearchInputRef = useRef();

    useEffect(() => {
        const onScroll = () => {
            if (locationSearchInputRef.current) {
                setSearchAutocompleteShowOver(locationSearchInputRef.current.getBoundingClientRect().top > window.innerHeight / 2);
            }
        }

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        const debounce = setTimeout(() => {
            getAutocomplete();
        }, 500);

        return () => clearTimeout(debounce);
    }, [props.searchTerm]);

    useEffect(() => {
        if (props.searchTerm !== searchAutocompleteTerm) {
            getAutocomplete();
        }
    }, [searchAutocompleteTerm]);

    useEffect(() => {
        document.addEventListener('click', offClick);

        return () => {
          document.removeEventListener('click', offClick);
        };
    }, []);

    const getAutocomplete = () => {
        if (props.autocompleteApi && props.searchTerm.length > 2) {
            if (!searchAutocompleteActive) {
                setSearchAutocompleteActive(true);

                axios({
                    method: 'get',
                    url: `${props.autocompleteApi}?input=${props.searchTerm}`,
                }).then((res) => {
                    if (!res.data) {
                        console.error('Failed to fetch data');
                    } else {
                        setSearchAutocomplete(res.data);
                        setSearchAutocompleteList(res.data.groups.filter(group => group.suggestions).flatMap(group => group.suggestions && [...group.suggestions]));
                        setSearchAutocompleteListIndex(0);
                        setSearchAutocompleteTerm(res.data.input);
                    }

                    setSearchAutocompleteActive(false);
                }).catch(() => {
                    console.error('Failed to fetch data');
                    setSearchAutocompleteActive(false);
                });
            }
        } else {
            setSearchAutocomplete({});
        }
    }

    const onSearchAutocomplete = (id, isDevelopment, text, url) => {
        setSearchAutocompleteDisabled(true);

        props.onSearchAutocomplete(id, isDevelopment, text, url);
    }

    const onSearchAutocompleteFocusHover = (id) => {
        setSearchAutocompleteListIndex(searchAutocompleteList.findIndex(item => item.id === id));
    }

    const onSearchSubmit = (event) => {
        event.preventDefault();

        setSearchAutocompleteDisabled(true);
        setGettingLocationError();

        if (searchAutocompleteList[searchAutocompleteListIndex]) {
            const selectedSuggestion = searchAutocompleteList[searchAutocompleteListIndex];

            onSearchAutocomplete(selectedSuggestion.query ? selectedSuggestion.query : selectedSuggestion.id, selectedSuggestion.query ? false : true, selectedSuggestion.text, selectedSuggestion.url);
        } else {
            props.onSearchSubmit();
        }
    }

    const onFocus = () => {
        setGettingLocationError();
        setSearchAutocompleteDisabled(false);
    }

    const onKeyDown = (event) => {
        setGettingLocationError();

        if (event.key === 'Enter' && searchAutocomplete.groups && searchAutocomplete.groups.length > 0 && searchAutocomplete.groups[0].suggestions && searchAutocomplete.groups[0].suggestions.length > 0) {
            event.preventDefault();

            const selectedSuggestion = searchAutocompleteList[searchAutocompleteListIndex];

            onSearchAutocomplete(selectedSuggestion.query ? selectedSuggestion.query : selectedSuggestion.id, selectedSuggestion.query ? false : true, selectedSuggestion.text, selectedSuggestion.url);
        } else if (event.key === 'ArrowDown' && searchAutocompleteList.length > 1) {
            event.preventDefault();

            setSearchAutocompleteListIndex(searchAutocompleteList[searchAutocompleteListIndex + 1] ? searchAutocompleteListIndex + 1 : 0);
        } else if (event.key === 'ArrowUp' && searchAutocompleteList.length > 1) {
            event.preventDefault();

            setSearchAutocompleteListIndex(searchAutocompleteList[searchAutocompleteListIndex - 1] ? searchAutocompleteListIndex - 1 : searchAutocompleteList.length - 1);
        }
    }

    const getLocationSuccess = (event) => {
        axios({
            method: 'post',
            url: `${props.locationApi}?latitude=${event.coords.latitude}&longitude=${event.coords.longitude}`,
        }).then((res) => {
            if (!res.data) {
                setGettingLocation(false);
                setGettingLocationError('We could not match your location with any McCarthy Stone developments.');

                console.error('Failed to fetch data');
            } else {
                props.onGetLocation(res.data.placeId, res.data.query);
            }
        }).catch(() => {
            setGettingLocation(false);
            setGettingLocationError('We could not match your location with any McCarthy Stone developments at this time.');

            console.error('Failed to fetch data');
        });
    }

    const getLocationError = (error) => {
        setGettingLocation(false);

        switch (error.code) {
            case 1: // PERMISSION_DENIED
                setGettingLocationError('Your settings do not allow McCarthy Stone to use your location. Please update your browser settings to use this feature.');
                break;
            case 2: // POSITION_UNAVAILABLE
                setGettingLocationError('We were unable to obtain your location due to an error in your position data.');
                break;
            case 2: // TIMEOUT
                setGettingLocationError('We were unable to obtain your location in a timely manner.');
                break;
            default: // UNKNOWN
                setGettingLocationError('We were unable to obtain your location due to an unknown error');
                break;
        }
    }

    const getLocation = () => {
        setGettingLocation(true);
        setGettingLocationError();

        navigator.geolocation.getCurrentPosition(getLocationSuccess, getLocationError);
    }

    const offClick = (event) => {
        if (locationSearchRef.current && !(locationSearchRef.current === event.target || locationSearchRef.current.contains(event.target))) {
            setSearchAutocompleteDisabled(true);
        }
    }

    return (
        <div className={`generic-location-search ${props.theme ? `generic-location-search--theme-${props.theme}` : ''}`} ref={locationSearchRef}>
            <div className={`generic-location-search__input-wrapper ${searchAutocompleteShowOver ? 'generic-location-search__input-wrapper--show-over' : ''}`}>
                <input
                    aria-autocomplete="list"
                    aria-controls={`${props.renderId}-autocomplete`}
                    className="generic-location-search__input"
                    disabled={gettingLocation}
                    id={`${props.renderId}-search`}
                    name={`${props.renderId}-search`}
                    onChange={(event) => props.setSearchTerm(event.target.value)}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    placeholder={props.placeholder}
                    ref={locationSearchInputRef}
                    role="searchbox"
                    type="search"
                    value={props.searchTerm}
                />
                {!searchAutocompleteDisabled && !gettingLocation && !gettingLocationError && <GenericLocationSearchAutocomplete
                    onSearchAutocomplete={onSearchAutocomplete}
                    onSearchAutocompleteFocusHover={onSearchAutocompleteFocusHover}
                    renderId={props.renderId}
                    searchAutocomplete={searchAutocomplete}
                    searchAutocompleteActive={searchAutocompleteActive}
                    searchAutocompleteSelectedId={searchAutocompleteList[searchAutocompleteListIndex] ? searchAutocompleteList[searchAutocompleteListIndex].id : null}
                    wide={props.onGetLocation && navigator.geolocation}
                />}
                {gettingLocation && <div className="generic-location-search__input-message generic-location-search__preloader">Getting your location...</div>}
                {gettingLocationError && <div className="generic-location-search__input-message generic-location-search__input-message--error">{gettingLocationError}</div>}
            </div>
            {props.onGetLocation && navigator.geolocation &&
                <button
                    aria-label="Use my current location"
                    className="generic-location-search__location-button"
                    disabled={gettingLocation}
                    onClick={getLocation} title="Use my current location"
                    type="button">
                        <span className="generic-location-search__location-button-label">Use my current location</span>
                </button>
            }
            <button
                aria-label={props.buttonText}
                className="btn-secondary btn-secondary--green generic-location-search__submit-button"
                disabled={gettingLocation}
                onClick={onSearchSubmit} title={props.buttonText} type="submit">
                    <span className="generic-location-search__submit-button-label">{props.buttonText}</span>
            </button>
        </div>
    )
}

export default GenericLocationSearch;
