import React from 'react';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';

const svg = `<li><div><svg width='20' height='16' 
    focusable="false" aria-hidden="true" role="img"
    viewBox="0 0 20 16">
    <path d="M20.1,0.5L20.1,0.5c-0.7-0.7-1.7-0.7-2.3-0.1L6.7,11.7l-4-4c-0.6-0.6-1.6-0.6-2.2,0L0.5,7.8c-0.6,0.6-0.6,1.6,0,2.2l4,4l0,0
    l2.3,2.3L20.1,2.7C20.7,2.1,20.7,1.1,20.1,0.5z" fill='#f6a200'/>
    </svg></div><span>`;

const BudgetExtraBenefits = () => {
    const [state, dispatch] = useBudgetCalculatorStore();

    let formattedRlpText;
    const additionalRlpText = state.additionalRLPSectionText;

    formattedRlpText = additionalRlpText.replace(/(\<li\>)/g, svg);
    formattedRlpText = formattedRlpText.replace(/(\<\/li\>)/g, '</span></li>');

    return (
        <div
            className="budget__extra-benefits"
            dangerouslySetInnerHTML={{ __html: formattedRlpText }}
        ></div>
    );
};

export default BudgetExtraBenefits;
