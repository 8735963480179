import React from 'react';
import PropTypes from 'prop-types';
import { createFocusTrap } from 'focus-trap';

class FocusTrapWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this._focusWrap = null;
    }

    getFocusTrap() {
        if (this._focusWrap === null) {
            const { targetElement } = this.props;
            this._focusWrap = createFocusTrap(targetElement, {
                escapeDeactivates: false
            });
        }

        return this._focusWrap;
    }

    componentDidUpdate(prevProps) {
        const { isActive, targetElement } = this.props;


        if (isActive !== prevProps.isActive || targetElement !== prevProps.targetElement) {
            if (targetElement) {
                if (isActive) {
                    this.getFocusTrap().activate();
                } else {
                    this.getFocusTrap().deactivate();
                }
            }
        }
    }

    componentWillUnmount() {
        const { isActive, targetElement } = this.props;
        if (isActive && targetElement) {
            this.getFocusTrap().deactivate();
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}

FocusTrapWrapper.propTypes = {
    targetElement: PropTypes.object,
    isActive: PropTypes.bool.isRequired,
};

export default FocusTrapWrapper;
