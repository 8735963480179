import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import RecipePreparation from './recipe-preparation';

export default class RecipePreparationComponent extends DcBaseComponent {
    static getNamespace() {
        return 'recipe-preparation';
    }

    onInit() {
        ReactDOM.render(
            <RecipePreparation {...this.options} />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
