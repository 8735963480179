import React from 'react';
import nanoId from 'nano-id';
import { createRoot } from 'react-dom/client';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import AdvancedSearchBar from './advanced-search-bar';

export default class AdvancedSearchBarComponent extends DcBaseComponent {
    static getNamespace() {
        return 'advanced-search-bar';
    }

    onInit() {
        const root = createRoot(this.element);
        root.render(<AdvancedSearchBar {...this.options} renderId={nanoId()} />);
    }

    onDestroy() {
        root.unmount();
    }
}
