import DcBaseComponent from 'general/js/dc/dc-base-component';

const DEFAULT_ASPECT_RATIO = 56.25;

/**
 *  Calculates original aspect ration of image rely on its width and height
 *  and set it as a padding to wrapper
 *  needed in cases when we can't crop image using default 16/9 aspect ratio
 * @export
 * @class OriginalAspectRatioComponent
 * @extends {DcBaseComponent}
 */
export default class OriginalAspectRatioComponent extends DcBaseComponent {
    static getNamespace() {
        return 'original-aspect-ratio';
    }

    onInit() {
        this.setPadding();
    }

    setPadding() {
        this.element.style.paddingTop = `${this.getAspectRatio()}%`;
    }

    getAspectRatio() {
        let aspectRatio = DEFAULT_ASPECT_RATIO;
        if (this.options.width && this.options.height) {
            aspectRatio = (this.options.height / this.options.width) * 100;
        }
        return aspectRatio;
    }
}
