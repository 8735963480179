import $ from 'jquery';
import 'slick-carousel';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_DESKTOP } from 'general/js/device';
import GalleryVideoStateObserver from 'general/js/video/gallery-video-state-observer';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';

const AUTOPLAY_SPEED = 7000;

export default class MultimediaGalleryComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.gallery = null;
        this.navs = null;
        this.isAutoplayStopped = false;
        this.videoObserver = null;
        this.videoSlides = this.refs.videos || [];
        this.slidingTime = 'slidingTime' in this.options ? this.options.slidingTime : AUTOPLAY_SPEED;
    }

    static getNamespace() {
        return 'multimedia-gallery';
    }

    static getRequiredRefs() {
        return ['slides'];
    }

    onInit() {
        const slidesCount = this.refs.slides.children.length;
        if (slidesCount > 0) {
            if (this.videoSlides.length > 0) {
                this.videoObserver = new GalleryVideoStateObserver(this.element);
            }
            if (slidesCount === 1) {
                this.initSlideElement(this.refs.slides.children[0]);
            } else {
                this.initSlider();
            }
        }
    }

    initSlider() {
        const { slides, navs, dots } = this.refs;

        if (!navs || !dots) {
            throw new Error('Slider should contain at least 2 sliders and navs/dots');
        }

        const $slides = $(slides);
        const $navs = $(navs);
        const $dots = $(dots);

        this.gallery = new SlickResponsive(
            $slides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                zIndex: 1,
                arrows: true,
                dots: true,
                swipe: true,
                dotsClass: 'multimedia-gallery__dots',
                appendDots: $dots,
                prevArrow: `<button type="button" class="btn multimedia-gallery__arrow-button multimedia-gallery__arrow-button--prev" data-dc-gtm-selector="MultimediaGalleryPrevButton" aria-label="Previous Slide">
                            <svg width="9" height="17" class="icon multimedia-gallery__arrow-button-icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-left-thin"></use>
                            </svg>
                        </button>`,
                nextArrow: `<button type="button" class="btn multimedia-gallery__arrow-button multimedia-gallery__arrow-button--next" data-dc-gtm-selector="MultimediaGalleryNextButton" aria-label="Next Slide">
                            <svg width="9" height="17" class="icon multimedia-gallery__arrow-button-icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-right-thin"></use>
                            </svg>
                        </button>`,
                autoplay: true,
                autoplaySpeed: this.slidingTime,
                rows: 0,
            },
            {
                [DEVICE_TYPE_DESKTOP]: {
                    dots: false,
                    asNavFor: $navs
                }
            },
            this.onBreakpointTriggered
        );

        device.subscribeOnDeviceTypeChange(() => {
            this.checkNavsSlider();
        }, true);

        this.gallery.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.onBeforeSlideChange(nextSlide);
        });

        this.gallery.on('afterChange', (event, slick, currentSlide) => {
            this.onAfterSlideChange(slick, currentSlide);
        });

        this.initStartSlide();

        this.element.addEventListener('mouseenter', this.onSliderMouseEnter);
        this.element.addEventListener('mouseleave', this.onSliderMouseLeave);
        this.element.addEventListener('click', this.onSliderClick);
    }

    onBreakpointTriggered = () => {
        // for some reason after each breakpoint event
        // previously stopped autoplay (on prev breakpoint options) continue working
        // so we should call it again based on our flag
        if (this.isAutoplayStopped) {
            this._stopAutoPlay();
        }
    };

    onSliderMouseEnter = () => {
        if (!this.isAutoplayStopped) {
            this.gallery.slick('slickPause');
        }
    };

    onSliderMouseLeave = () => {
        if (!this.isAutoplayStopped) {
            this.gallery.slick('slickPlay');
        }
    };

    onSliderClick = () => {
        if (!this.isAutoplayStopped) {
            this.isAutoplayStopped = true;
            this._stopAutoPlay();
        }
    };

    _stopAutoPlay() {
        this.gallery.slick('slickPause');
    }

    checkNavsSlider() {
        const { slides, navs } = this.refs;

        if (device.isViewportTypeGe(DEVICE_TYPE_DESKTOP)) {
            if (this.navs === null) {
                this.navs = $(navs).slick({
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    swipeToSlide: true,
                    focusOnSelect: true,
                    asNavFor: slides,
                    infinite: true,
                    arrows: true,
                    rows: 0,
                    prevArrow:
                        '<div class="multimedia-gallery__navs-overflow multimedia-gallery__navs-overflow--prev"></div>',
                    nextArrow:
                        '<div class="multimedia-gallery__navs-overflow multimedia-gallery__navs-overflow--next"></div>'
                });
            }
        } else if (this.navs !== null) {
            this.navs.slick('unslick');
            this.navs = null;
        }
    }

    initSlideElement(element, slideIndex) {
        if (this.videoSlides.includes(element)) {
            this.videoObserver.addVideo(element, slideIndex);
        }
    }

    initStartSlide() {
        const mainSlick = this.gallery.slick('getSlick');
        const slickCurrentSlide = this.gallery.slick('slickCurrentSlide');
        this.initSlickSlide(mainSlick, slickCurrentSlide);
    }

    onAfterSlideChange(slick, currentSlide) {
        this.initSlickSlide(slick, currentSlide);
    }

    initSlickSlide(slick, slide) {
        this.initSlideElement(slick.$slides.get(slide), slide);
    }

    onBeforeSlideChange(nextSlide) {
        if (!this.videoObserver) {
            return;
        }
        this.videoObserver.pauseVideo();

        if (this.videoObserver.startedSlides.includes(nextSlide)) {
            this.element.classList.add('is-video-started');
        } else {
            this.element.classList.remove('is-video-started');
        }
    }

    onDestroy() {
        if (this.gallery !== null) {
            this.gallery.slick('unslick');
            this.gallery = null;
        }
        if (this.navs !== null) {
            this.navs.slick('unslick');
            this.navs = null;
        }
    }
}
