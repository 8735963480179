import device from './device';
import scrollbarHandler from './scrollbar-handler';

class ContentBlocker {
    constructor() {
        this.container = document.documentElement;
        this.pageOffset = 0;
        this.sources = {};
        this.scrollbarSize = null;

        this._isBlocked = false;
    }

    block(source) {
        if (typeof source === undefined) {
            throw new Error('you must specify the retainer which cause the block');
        }

        this.sources[source] = true;
        if (!this._isBlocked) {
            if (device.isTouch) {
                this.pageOffset = window.pageYOffset;
                this.container.classList.add('is-blocked-touch');
                this.container.style.top = -this.pageOffset + 'px';
            } else {
                this.container.classList.add('is-blocked');
                if (scrollbarHandler.hasScrollBar()) {
                    this.container.style.marginRight = scrollbarHandler.getScrollbarSize() + 'px';
                }
            }
            this._isBlocked = true;
        }
    }

    unblock(source) {
        if (typeof source === undefined) {
            throw new Error('you must specify the retainer which stop the block');
        }

        this.sources[source] = false;
        if (this.isFree() && this._isBlocked) {
            if (device.isTouch) {
                this.container.style.top = 'initial';
                this.container.classList.remove('is-blocked-touch');
                window.scrollTo(0, this.pageOffset);
            } else {
                this.container.classList.remove('is-blocked');
                this.container.style.marginRight = '0';
            }
            this._isBlocked = false;
        }
    }

    isBlocked(source) {
        return this.sources[source] === true;
    }

    isFree() {
        return Object.keys(this.sources).every(key => this.sources[key] === false);
    }
}

const instance = new ContentBlocker();

export default instance;
