import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import DevelopmentsSearchList from './developments-search-list';
import SearchResultsMessage from '../../../zero-search-results/js/search-results-message';

class DevelopmentsSearchResults extends React.PureComponent {
    hasNoExactMatches() {
        const { isInitialRequestFulfilled, hasExactMatches } = this.props;

        return isInitialRequestFulfilled && !hasExactMatches;
    }

    render() {
        const { markers, noExactMatches } = this.props;

        return (
            <div className="developments-search__list-wrapper">
                {this.hasNoExactMatches() ?
                    <div className="developments-search__no-exact-matches">
                        <SearchResultsMessage html={noExactMatches.message}/>
                    </div> : null}
                <DevelopmentsSearchList markers={markers}/>
            </div>
        );
    }
}

DevelopmentsSearchResults.defaultProps = {
    hasExactMatches: true,
};

DevelopmentsSearchResults.propTypes = {
    markers: PropTypes.object,
    hasExactMatches: PropTypes.bool,
    noExactMatches: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }).isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
};

export default withContext(DevelopmentsSearchResults, {
    hasExactMatches: 'hasExactMatches',
    noExactMatches: 'noExactMatches',
    isInitialRequestFulfilled: 'isInitialRequestFulfilled',
});
