document.addEventListener('sc-forms-submit-success', function (e) {
    var form = document.getElementById(e.detail);
    if (form) {
        try {
            trackFormSubmit(form);
        } catch (error) {
            console.error(error);
        }
    } else {
        console.warn("No form to process");
    }
});

function trackFormSubmit(form) {

    window.dataLayer = window.dataLayer || [];
    window.formData = window.formData || [];

    const trackingFields = form.querySelectorAll('[data-mcs-analytics]');
    trackingFields.forEach(trackingField => {
        var trackingFieldJson = JSON.parse(trackingField.value);

        var dataObj = {
            'event': trackingFieldJson.eventName,
            'formName': trackingFieldJson.formName
        };

        trackingFieldJson.fieldMappings.forEach(field => {
            dataObj[field.key] = window.formData[field.fieldName].toLowerCase();
        });

        window.dataLayer.push(dataObj);
    });
}
