import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import recentSearchesService from 'general/js/recent-searches-service';
import DevelopmentsPredictiveSearch from './developments-predictive-search';
import { CampaignSearchResultsContext } from '../campaign-property-search/js/context';
import { SUGGESTION_TYPE_DEVELOPMENT, PATHNAME_SALE, PATHNAME_RENT, PATHNAME_OPSO } from '@Components/predictive-search/js/constants.js';

const StandaloneDevelopmentsPredictiveSearch = (props) => {
    const [text, setText] = useState(props.defaultText);
    const {
        setResponseData,
        setIsLoading,
        setIsError,
        filterOptions,
        setFilterOptions,
        locationSearchId,
        placeId,
        setLocationSearchId,
        setPlaceId,
    } = useContext(CampaignSearchResultsContext) || { responseData: [], isLoading: false };

    const onTextChange = (newText) => {
        if (props.campaignSearchUrl)
        {
            setLocationSearchId(undefined);
            setPlaceId(undefined);
        }
        setText(newText);
    };

    const requestSuggestedLocationDevelopments = (suggestion) => {
        if (suggestion)
        {
            setText(suggestion.text);
            setLocationSearchId(suggestion.id);
            setPlaceId(undefined);
        }

        setIsError(false);
        setIsLoading(true);

        requestDevelopments(suggestion.id, undefined);
    };

    const requestPlaceDevelopments = (text, placeId) => {
        setText(text);
        setLocationSearchId(undefined);
        setPlaceId(placeId);
        setIsError(false);
        setIsLoading(true);

        requestDevelopments(undefined, placeId);
    }

    const requestDevelopments = (locationSearchId, placeId) => {
        const suggestionSearchState = {
            ...filterOptions,
            searchRadius: '30',
            sort: 'Distance',
        };
        setFilterOptions(suggestionSearchState);

        axios({
            method: 'post',
            url: locationSearchId ? `${props.campaignSearchUrl}&q=${locationSearchId}` : `${props.campaignSearchUrl}&id=${placeId}`,
            data: suggestionSearchState,
        })
            .then((res) => {
                if (!res.data) {
                    if (setIsError) {
                        setIsError(true);
                    }
                    console.log('failed to get data');
                    return;
                }

                if (setResponseData) {
                    setResponseData(res.data);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log('Could not perform request', error);
            });
    }

    const matchPlaceAndRequestDevelopments = (query) => {
        
        axios({
            method: 'get',
            url: `${props.placeMatchUrl}?q=${query}`
        },)
            .then((res) => {
                if (!res.data) {
                    if (setIsError) {
                        setIsError(true);
                    }
                    console.log('failed to get data');
                    return;
                }

                const { displayName, placeId } = res.data;
                requestPlaceDevelopments(displayName, placeId);
            })
            .catch((error) => {
                setIsLoading(false);
                setIsError(true);
                if (error.response?.status !== 404)
                    console.log('Could not perform request', error);
            });

    }

    const applyExistingQueryParams = () => {
        const supportedParams = [
            'lat',
            'lng',
            'ownershipType',
            'maxPrice',
			'maxRent',
			'maxOpsoPrice',
            'searchRadius',
            'propertyTypes',
            'numberOfBedrooms',
            'availability',
            'sort',
            'propertiesResultLimit',
            'campaignId',
            'filterId',
            'offset',
            'limit',
        ];

        if (window.location.search) {
            const params = window.location.search.split('?')[1].split('&');
            return params.filter((item) => supportedParams.includes(item.split('=')[0])).join('&');
        }

        return null;
    };
	
	const isSearchResultsPage = () => {
		if (window.location.pathname === PATHNAME_SALE || window.location.pathname === PATHNAME_RENT || window.location.pathname === PATHNAME_OPSO) {
			return true;
		}
		
		return false;
	};
	
	const getSuggestionUrl = (suggestion) => {
        let suggestionUrl = suggestion.url;

		const queryString = applyExistingQueryParams();
        if (SUGGESTION_TYPE_DEVELOPMENT !== suggestion.type && queryString) {
            const sign = suggestion.url.includes('?') ? '&' : '?';
            suggestionUrl = `${suggestion.url}${sign}${queryString}`;
        }
		
		const isSearchPage = isSearchResultsPage();
		if (SUGGESTION_TYPE_DEVELOPMENT !== suggestion.type && isSearchPage) {
			suggestionUrl = suggestionUrl.replace(PATHNAME_SALE, window.location.pathname);
		}
		
		return suggestionUrl;
	};

    const onSuggestionSelect = (suggestion) => {
        const suggestionUrl = getSuggestionUrl(suggestion);

        try {
            recentSearchesService.add(suggestion.text, suggestionUrl);
        } catch (e) {
            console.error('Unable to save selected suggestion', e);
        }

        if (!props.campaignSearchUrl) {
            window.location.href = suggestionUrl;
        } else {
            requestSuggestedLocationDevelopments({ ...suggestion, url: suggestionUrl });
        }
    };

    const onEmptyTextSubmit = () => {
        if (props.defaultSearchUrl && !props.campaignSearchUrl) {
            window.location.href = props.defaultSearchUrl;
        }
    };

    const onFallbackQuerySubmit = (text) => {
        if (props.campaignSearchUrl)
        {            
            if (locationSearchId)
                requestSuggestedLocationDevelopments();
            else if (placeId)
                requestPlaceDevelopments(text, placeId);
            else
                matchPlaceAndRequestDevelopments(text);
        }
        else
            window.location.href = `${props.defaultSearchUrl}?q=${text}`;
    }

    const {
        noResultsSuggestionText,
        noResultsMessage,
        placeholderMessage,
        predictiveSearchUrl,
        campaignSearchUrl,
    } = props.predictiveSearchOptions;

    return (
        <DevelopmentsPredictiveSearch
            text={text}
            campaignSearchUrl={campaignSearchUrl}
            noResultsSuggestionText={noResultsSuggestionText}
            noResultsMessage={noResultsMessage}
            placeholderMessage={placeholderMessage}
            predictiveSearchUrl={predictiveSearchUrl}
            defaultSearchUrl={props.defaultSearchUrl}
            onTextChange={onTextChange}
            onSuggestionSelect={onSuggestionSelect}
            onFallbackQuerySubmit={onFallbackQuerySubmit}
            onEmptyTextSubmit={onEmptyTextSubmit}
            {...props}
        />
    );
};

StandaloneDevelopmentsPredictiveSearch.defaultProps = {
    defaultText: '',
};

StandaloneDevelopmentsPredictiveSearch.propTypes = {
    defaultSearchUrl: PropTypes.string.isRequired,
    predictiveSearchOptions: PropTypes.object.isRequired,
    defaultText: PropTypes.string,
    campaignSearchUrl: PropTypes.string,
    placeMatchUrl: PropTypes.string
};

export default StandaloneDevelopmentsPredictiveSearch;
