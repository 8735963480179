import UrlHelper from '@General/js/url-helper.js';
import api from './api';

class TrackerService {
    constructor(apiInstance) {
        this._api = apiInstance;
    }

    _getApiUrl(trigger) {
        const config = window.marketingTracker;
        return UrlHelper.getUrlByParams(config.endpoint, {
            contextItem : config.context,
            referrer: `${window.location.pathname}${window.location.search}`,
            trigger
        });
    }

    async track(trigger) {
        if (!trigger) return;
        return this._api.post(this._getApiUrl(trigger));
    }

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            const trackerElements = document.querySelectorAll('[data-tracker]');

            trackerElements.forEach((element) => {
                const trigger = element.getAttribute('data-tracker');
                if (trigger) {
                    element.addEventListener('click', () => this.track(trigger));
                }
            });
        });
    }
}   

export default new TrackerService(api);
