import '../scss/index.scss';
import device from 'general/js/device';
import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import GoogleMap from './google-map';
import ElementSpinner from '../../../general/js/element-spinner';
import popupTemplate from '../templates/popup.hbs';
import HtmlHelper from '../../../general/js/html-helper';
import constants from './constants';
import GoogleMapStatic from './google-map-static';

export default class Map extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.map = null;

        if (!this.options.mapSettings) {
            throw new Error('No map settings specified!');
        }

        if (!this.options.mapSettings.mapAPIType) {
            throw new Error('No map API type specified!');
        }
    }

    static getNamespace() {
        return 'map';
    }

    onInit() {
        if (this.options.mapSettings.mapAPIType === constants.MAP_TYPE_STATIC) {
            ReactDOM.render(<GoogleMapStatic parent={this.element} {...this.options } />, this.element);
        } else {
            this.unsubscribeFromDeviceTypeChange = device.subscribeOnDeviceTypeChange(this._initMap.bind(this), true);
        }
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    _initMap() {
        if (this.options.mapSettings.minimumDeviceSize && !device.isViewportTypeGe(this.options.mapSettings.minimumDeviceSize)) {
            return;
        }

        if (this.options.mapSettings.mapAPIType === constants.MAP_TYPE_DYNAMIC) {
            this.spinner = new ElementSpinner(this.element);
            this._createMap();
        } else {
            throw new Error('Map API type should either be \'static\' or \'dynamic\'');
        }

        setTimeout(() => {
            if (this.unsubscribeFromDeviceTypeChange && typeof this.unsubscribeFromDeviceTypeChange === 'function') {
                this.unsubscribeFromDeviceTypeChange.call();
            }
        }, 1);
    }

    async _createMap() {
        this.element.classList.add('amenities__map--is-visible');
        this.showSpinner();
        const map = new GoogleMap(this.element, this.options.map);
        const locations = this.options.locations.map((location) => {
            return { ...location, popup: HtmlHelper.createElement(popupTemplate(location.popup)) };
        });
        await map.updateMarkers(locations);
        this.hideSpinner();
    }

    showSpinner = () => {
        this.spinner.show();
    };

    hideSpinner = () => {
        this.spinner.hide();
    };
}
