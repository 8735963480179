import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import axios from 'axios';

import { CampaignSearchResultsContext } from '@Components/campaign-property-search/js/context';

import CampaignMobileSortingPopup from './campaign-mobile-sorting-popup';

const sortIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17.5" viewBox="0 0 20 17.5">
    <path id="Path_604" data-name="Path 604" d="M9.375,3.75h2.5a.625.625,0,0,0,.625-.625V1.875a.625.625,0,0,0-.625-.625h-2.5a.625.625,0,0,0-.625.625v1.25A.625.625,0,0,0,9.375,3.75Zm0,5h5A.625.625,0,0,0,15,8.125V6.875a.625.625,0,0,0-.625-.625h-5a.625.625,0,0,0-.625.625v1.25A.625.625,0,0,0,9.375,8.75Zm10,7.5h-10a.625.625,0,0,0-.625.625v1.25a.625.625,0,0,0,.625.625h10A.625.625,0,0,0,20,18.125v-1.25A.625.625,0,0,0,19.375,16.25Zm-10-2.5h7.5a.625.625,0,0,0,.625-.625v-1.25a.625.625,0,0,0-.625-.625h-7.5a.625.625,0,0,0-.625.625v1.25A.625.625,0,0,0,9.375,13.75Zm-2.5,0H5V1.875a.625.625,0,0,0-.625-.625H3.125a.625.625,0,0,0-.625.625V13.75H.625a.625.625,0,0,0-.441,1.067l3.125,3.75a.625.625,0,0,0,.884,0l3.125-3.75A.626.626,0,0,0,6.875,13.75Z" transform="translate(0 -1.25)" fill="#fff" />
</svg>;

const CONTROL_CLASS = 'campaign-mobile-sorting__control';

const CampaignSearchMobileFilters = ({
    sortingConfig,
    campaignSearchUrl,
}) => {
    const [controlLabelText, setControlLabelText] = useState(locationSearchId ? 'Distance' : 'Price');
    const [isRotatedIcon, setIsRotatedIcon] = useState(false);
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const controlRef = useRef(null);
    const popupRef = useRef(null);
    const {
        filterOptions,
        setFilterOptions,
        setIsError,
        setResponseData,
        setIsLoading,
        isLoading,
        locationSearchId,
        placeId
    } = useContext(CampaignSearchResultsContext);

    const handleControlClick = () => {
        setIsPopupOpened(true);
    };

    const setLabelText = (text) => {
        setControlLabelText(text);
    };

    useEffect(() => {
        if (filterOptions.sort === 'Distance') {
            setControlLabelText('Distance');
        }
    }, [filterOptions.sort]);

    const sortData = (sortOption) => {
        const sortingUrl = locationSearchId 
            ? `${campaignSearchUrl}&q=${locationSearchId}` 
            : placeId 
                ? `${campaignSearchUrl}&id=${placeId}` 
                : campaignSearchUrl;
        
        const sortingState = {
            ...filterOptions,
            offset: 0,
            sort: sortOption
        };

        setIsLoading(true);
        setFilterOptions(sortingState);

        axios({
            method: 'post',
            url: sortingUrl,
            data: sortingState
        }).then((res) => {
            if (!res.data) {
                if (setIsError) {
                    setIsError(true);
                }
                console.log('failed to get data');
                return;
            }

            if (setResponseData) {
                setResponseData(res.data);
                setIsLoading(false);
            }
        }).catch((error) => {
            console.log('Could not perform request', error);
        });
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            const isNotListClicked = !e.target.closest(`.${CONTROL_CLASS}`) &&
                e.target !== popupRef.current;
            const isMainControlClicked = e.target.closest(`.${CONTROL_CLASS}`) &&
                isPopupOpened;

            if (isMainControlClicked || isNotListClicked) {
                setIsPopupOpened(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => document.removeEventListener('click', handleClickOutside);
    }, [isPopupOpened]);

    const optionFromState = sortingConfig.options.find((option) => option.value === filterOptions.sort);

    const properLabel = optionFromState.value.toLowerCase().includes('price') ? 'Price' : optionFromState.text;

    return (
        <div className='campaign-mobile-sorting'>
            <button
                ref={controlRef}
                className={CONTROL_CLASS}
                disabled={isLoading}
                onClick={handleControlClick}>
                <div className={`${CONTROL_CLASS}--left-side`}>
                    <div className={cn(
                        `${CONTROL_CLASS}-icon`,
                        { 'campaign-icon-rotated': isRotatedIcon }
                    )}>{sortIcon}</div>
                    <div className={`${CONTROL_CLASS}-label`}>Sort: {properLabel}</div>
                </div>
                <div className={`${CONTROL_CLASS}--right-side`}>
                    <svg
                        width="13"
                        height="8"
                        className={cn(
                            'icon',
                            `${CONTROL_CLASS}-arrow`,
                            { 'campaign-icon-rotated': isPopupOpened }
                        )}
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </div>
            </button >
            <CampaignMobileSortingPopup
                ref={popupRef}
                isPopupOpened={isPopupOpened}
                sortingConfig={sortingConfig}
                setLabelText={setLabelText}
                labelText={controlLabelText}
                setIsRotatedIcon={setIsRotatedIcon}
                sortData={sortData} />
        </div >
    );
};

CampaignSearchMobileFilters.propTypes = {
    sortingConfig: PropTypes.object.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
};

export default CampaignSearchMobileFilters;
