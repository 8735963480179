import React from 'react';
import PropTypes from 'prop-types';
import constants from 'general/js/constants';

export default class DevelopmentIcon extends React.Component {
    getPropertyTypeContent() {
        const { productType } = this.props;

        switch (productType) {
            case constants.DEVELOPMENT_PROPERTY_TYPE_RL:
                return 'rl';
            case constants.DEVELOPMENT_PROPERTY_TYPE_RL_PLUS:
                return (
                    <div>
                        <div>rl</div>
                        <div className="developments-predictive-search__suggestion-icon-small-text">
                            plus
                        </div>
                    </div>
                );
            case constants.DEVELOPMENT_PROPERTY_TYPE_LL:
                return 'LL';
            default:
                return '';
        }
    }

    getPropertyTypeClass() {
        const { productType } = this.props;

        switch (productType) {
            case constants.DEVELOPMENT_PROPERTY_TYPE_RL:
                return 'developments-predictive-search__suggestion-icon--rl';
            case constants.DEVELOPMENT_PROPERTY_TYPE_RL_PLUS:
                return 'developments-predictive-search__suggestion-icon--rl-plus';
            case constants.DEVELOPMENT_PROPERTY_TYPE_LL:
                return 'developments-predictive-search__suggestion-icon--ll';
            default:
                return '';
        }
    }


    render() {
        return (
            <div
                className={`developments-predictive-search__suggestion-icon ${this.getPropertyTypeClass()}`}>
                {this.getPropertyTypeContent()}
            </div>
        );
    }
}

DevelopmentIcon.propTypes = {
    productType: PropTypes.string.isRequired,
};
