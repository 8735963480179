import AbstractManager from './abstract-manager';
import TabsComponent from '../../tabs/js/tabs.component';

export default class TabsManager extends AbstractManager {
    constructor(...args) {
        super(...args);
        this.instances = [];
        this._init();
    }

    _init() {
        this.refs.tabsInstances.forEach((element) => {
            const instance = new TabsComponent(
                element,
                {
                    beforeTabChange: this._beforeTabChange.bind(this)
                }
            );
            instance.init();
            this.instances.push(instance);
        });
    }

    destroy() {
        this.instances.forEach((instance) => {
            instance.destroy();
        });
        this.instances = [];
    }

    _beforeTabChange(tabIndex) {
        this.beforeNext(tabIndex);
    }
}
