import DcBaseComponent from 'general/js/dc/dc-base-component';
import $ from 'jquery';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';
import { DEVICE_TYPE_MOBILE_WIDE, DEVICE_TYPE_TABLET } from 'general/js/device';

export default class CardCarouselComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.gallery = null;
    }

    static getNamespace() {
        return 'card-carousel';
    }

    onInit() {
        this.initSlider();
    }

    initSlider() {
        if (!this.refs.slider) {
            throw new Error('Slider should be presented');
        }

        const $slider = $(this.refs.slider);

        this.gallery = new SlickResponsive(
            $slider,
            {
                infinite: false,
                variableWidth: false,
                slidesToScroll: 1,
                touchThreshold: 10,
                slidesToShow: 1.05,
                arrows: true,
                rows: 0,
                prevArrow: `<button type="button" class="btn card-carousel__arrow-button card-carousel__arrow-button--prev" aria-label="Previous Slide">
                            <svg width="9" height="17" class="icon card-carousel__arrow-button-icon" focusable="false">
                                <use xlink:href="#icon-arrow-left-thin"></use>
                            </svg>
                        </button>`,
                nextArrow: `<button type="button" class="btn card-carousel__arrow-button card-carousel__arrow-button--next" aria-label="Next Slide">
                            <svg width="9" height="17" class="icon card-carousel__arrow-button-icon" focusable="false">
                                <use xlink:href="#icon-arrow-right-thin"></use>
                            </svg>
                        </button>`
            },
            {
                [DEVICE_TYPE_MOBILE_WIDE]: {
                    swipe: false,
                    slidesToShow: 1.5
                },
                [DEVICE_TYPE_TABLET]: {
                    slidesToShow: 2.1
                }
            }
        );
    }
}
