import cloneDeep from 'lodash/cloneDeep';

export default class Area {
    constructor(type, coords, attributes, app) {
        if (this.constructor === Area) {
            throw new Error('This is abstract class');
        }

        this._type = type;
        this._attributes = {
            alt: attributes.alt || '',
            floor: attributes.floor || '',
            href: attributes.href || '',
            recordId: attributes.recordId || '',
            sold: !!attributes.sold,
            title: attributes.title || 'Unassigned'
        };

        if (attributes) {
            this.setInfoAttributes(attributes);
        }

        this._coords = coords;
        this._app = app;
        this._groupEl = document.createElementNS(Area.SVG_NS, 'g');
        app.addNodeToSvg(this._groupEl);
        this._groupEl.obj = this;
        this._el = null;
        this._helpers = {};
        app.addObject(this);
    }

    redraw = (coords) => {
        this.setSVGCoords(coords || this._coords);

        return this;
    };

    remove = () => {
        this._app.removeNodeFromSvg(this._groupEl);
    };

    move = (dx, dy) => {
        this.setCoords(this.edit('move', dx, dy)).redraw();
        return this;
    };

    select = () => {
        this._el.classList.add(Area.CLASS_NAMES.SELECTED);

        return this;
    };

    deselect = () => {
        this._el.classList.remove(Area.CLASS_NAMES.SELECTED);

        return this;
    };

    setStyleOfElementWithHref = () => {
        this._el.classList.add(Area.CLASS_NAMES.WITH_HREF);

        return this;
    };

    unsetStyleOfElementWithHref = () => {
        this._el.classList.remove(Area.CLASS_NAMES.WITH_HREF);

        return this;
    };

    setInfoAttributes = (attributes) => {
        this._attributes = { ...this._attributes, ...attributes };
    };

    toJSON = () => {
        return {
            type: this._type,
            coords: cloneDeep(this._coords),
            attributes: this._attributes
        };
    };

    static SVG_NS = 'http://www.w3.org/2000/svg';

    static CLASS_NAMES = {
        SELECTED: 'selected',
        WITH_HREF: 'with_href'
    };

    static REGEXP = {
        AREA: /<area(?=.*? shape="(rect|poly)")(?=.*? coords="([\d ,]+?)")[\s\S]*?>/gmi,
        HREF: / href="([\S\s]+?)"/,
        ALT: / alt="([\S\s]+?)"/,
        TITLE: / title="([\S\s]+?)"/,
        DELIMETER: / ?, ?/
    };

    static HTML_NAMES_TO_AREA_NAMES = {
        rect: 'rectangle',
        poly: 'polygon'
    };

    static ATTRIBUTES_NAMES = ['HREF', 'ALT', 'TITLE'];
}
