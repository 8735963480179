import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_DESKTOP } from 'general/js/device';
import defaultOverlay from 'general/js/overlay/js/default-overlay';

export default class InnerPageNavComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this._isMobileOpened = false;
    }

    static getNamespace() {
        return 'inner-page-nav';
    }

    static getRequiredRefs() {
        return ['mobileTrigger', 'menu'];
    }

    onInit() {
        this.addListener(this.refs.mobileTrigger, 'click', this._onMobileTriggerClick);
        this.deviceChangeUnsubscribe = device.subscribeOnDeviceTypeChange(this._onDeviceTypeChange);
        this.overlayClickUnsubscribe = defaultOverlay.subscribeOnOverlayClick(this._onOverlayClick);
        
        this._changeMobileNavTitleText();
    }

    _onOverlayClick = () => {
        if (this._isMobileOpened) {
            this._close();
        }
    };

    _onDeviceTypeChange = () => {
        if (device.isViewportTypeGe(DEVICE_TYPE_DESKTOP) && this._isMobileOpened) {
            this._close();
        }
    };

    _onMobileTriggerClick = () => {
        if (!this._isMobileOpened) {
            this._open();
        } else {
            this._close();
        }
    };

    _open() {
        this.element.classList.add('is-opened');
        defaultOverlay.show('inner-page-nav');
        this._isMobileOpened = true;
    }

    _close() {
        this.element.classList.remove('is-opened');
        defaultOverlay.hide('inner-page-nav');
        this._isMobileOpened = false;
    }

    _changeMobileNavTitleText() {
        const mobileInnerNav = document.querySelector('.inner-page-nav__mobile-trigger');
        const currentArticleTitle = document.querySelector('.inner-page-nav__item span');

        if (mobileInnerNav && currentArticleTitle) {
            const mobileInnerNavSvg = mobileInnerNav.innerHTML.split(' <')[1];
            mobileInnerNav.innerHTML = `${currentArticleTitle.textContent} <${mobileInnerNavSvg}`;
        }
    }

    onDestroy() {
        this.deviceChangeUnsubscribe();
        this.overlayClickUnsubscribe();
    }
}
