import DcBaseComponent from 'general/js/dc/dc-base-component';
import ControlsCondition from 'general/js/controls-condition';

export default class ValidateIfComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.rules = this.options;
    }

    static getNamespace() {
        return 'validate-if';
    }

    onInit() {
        this.rules.forEach((rule) => {
            const inverse = ('inverse' in rule) ? rule.inverse : false;
            this.condition = new ControlsCondition(rule.condition, this.onConditionStateChange.bind(this, rule.attributes), inverse);
        });
    }

    onConditionStateChange(attributes, value) {
        if (value) {
            setTimeout(() => {
                this.applyAttributes(attributes);
            }, 0);
        } else {
            // in case multiple rules remove first, then apply
            this.removeAttributes(attributes);
        }
    }

    applyAttributes(attributes) {
        attributes.forEach(([attr, value = '']) => {
            this.element.setAttribute(attr, value);
        });
    }

    removeAttributes(attributes) {
        attributes.forEach(([attr]) => {
            this.element.removeAttribute(attr);
        });
    }
}
