import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import SuggestionItem from './suggestion-item';

class HighlightableSuggestionItem extends React.Component {
    isHighlighted() {
        const { item, highlightedSuggestionIndex, suggestions } = this.props;
        return highlightedSuggestionIndex !== -1 && suggestions[highlightedSuggestionIndex].id === item.id;
    }

    render() {
        return <SuggestionItem
            {...this.props}
            isHighlighted={this.isHighlighted()}
        />;
    }
}

HighlightableSuggestionItem.propTypes = {
    // context
    suggestions: PropTypes.array.isRequired,
    highlightedSuggestionIndex: PropTypes.number.isRequired,
};

export default withContext(HighlightableSuggestionItem, {
    suggestions: 'suggestions',
    highlightedSuggestionIndex: 'highlightedSuggestionIndex',
});
