import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CampaignTablePagination = ({
    totalPlotsCount,
    paginationThreshold,
    activePage,
    setActivePage
}) => {
    useEffect(() => {
        if (currentDisplayedPages[0] > totalPages) {
            setActivePage(totalPages);
        }
    }, [paginationThreshold]);

    const getTotalPages = (totalCount) => {
        if (totalCount <= paginationThreshold) {
            return 1;
        }
        return Math.ceil(totalPlotsCount / paginationThreshold);
    };

    const createDisplayedPages = (currentPage, totalPages) => {
        if (totalPages >= 3) {
            if (totalPages === 4) {
                const currentPages = [1, 2, 3, 4];
                return currentPages;
            }

            if (currentPage === 1) {
                return [
                    currentPage,
                    currentPage + 1,
                    currentPage + 2
                ];
            }

            if (currentPage === totalPages) {
                return [
                    currentPage - 2,
                    currentPage - 1,
                    currentPage,
                ];
            }

            if (currentPage === (totalPages - 2)) {
                return [
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    currentPage + 2
                ];
            }

            return [
                currentPage - 1,
                currentPage,
                currentPage + 1
            ];
        }

        if (totalPages === 5 && currentPage === (totalPages - 2)) {
            const currentPages = [1, 2, 3, 4, 5];
            return currentPages;
        }

        return [1, 2];
    };

    const totalPages = getTotalPages(totalPlotsCount);
    const currentDisplayedPages = createDisplayedPages(activePage, totalPages);

    return (
        <div
            className={cn({
                'campaign-table-pagination': true,
            })}
        >
            <ul className="campaign-table-pagination__list">
                <li className="campaign-table-pagination__item  campaign-table-pagination__item--mr">
                    <a
                        className={cn({
                            'campaign-table-pagination__link': true,
                            'campaign-table-pagination__link--arrow': true,
                            'campaign-table-pagination__link--arrow-disabled': activePage === 1,
                            'campaign-table-pagination__link--active': activePage !== 1,
                        })}
                        onClick={
                            activePage !== 1
                                ? () => setActivePage(activePage - 1)
                                : () => { }
                        }
						title="Go to previous page"
                    >
                        <svg className="icon" width="8" height="13">
                            <use xlinkHref="#icon-arrow-left-thin"></use>
                        </svg>
                    </a>
                </li>
                {
                    (activePage >= totalPages - 1 &&
                        totalPages > 4) &&
                    <>
                        <li className="campaign-search-pagination__item">
                            <a
                                className={cn({
                                    'campaign-search-pagination__link': true,
                                    'campaign-search-pagination__link--number': true,
                                })}
                                onClick={() => setActivePage(1)}
                            >
                                {1}
                            </a>
                        </li>
                        <li className="campaign-search-pagination__item">
                            <a className="campaign-search-pagination__link">...</a>
                        </li>
                    </>
                }
                {currentDisplayedPages.map((page, i) => (
                    <li className="campaign-table-pagination__item" key={i}>
                        <a
                            className={cn({
                                'campaign-table-pagination__link': true,
                                'campaign-table-pagination__link--number': true,
                                'campaign-table-pagination__link--active': page === activePage,
                            })}
                            onClick={
                                page !== activePage ? () => setActivePage(page) : () => { }
                            }
                        >
                            {page}
                        </a>
                    </li>
                ))}
                {(activePage < totalPages - 2 && totalPages > 4) &&
                    <>
                        <li className="campaign-table-pagination__item">
                            <a className="campaign-table-pagination__link">...</a>
                        </li>
                        <li className="campaign-table-pagination__item">
                            <a
                                className={cn({
                                    'campaign-table-pagination__link': true,
                                    'campaign-table-pagination__link--number': true,
                                })}
                                onClick={() => setActivePage(totalPages)}
                            >
                                {totalPages}
                            </a>
                        </li>
                    </>
                }
                <li className="campaign-table-pagination__item campaign-table-pagination__item--ml">
                    <a
                        className={cn({
                            'campaign-table-pagination__link': true,
                            'campaign-table-pagination__link--arrow': true,
                            'campaign-table-pagination__link--arrow-disabled': activePage === totalPages,
                            'campaign-table-pagination__link--active': activePage !== totalPages,
                        })}
                        onClick={
                            activePage !== totalPages
                                ? () => setActivePage(activePage + 1)
                                : () => { }
                        }
						title="Go to next page"
                    >
                        <svg className="icon" width="8" height="13">
                            <use xlinkHref="#icon-arrow-right-thin"></use>
                        </svg>
                    </a>
                </li>
            </ul>
        </div>
    );
}

CampaignTablePagination.propTypes = {
    activePage: PropTypes.number,
    setActivePage: PropTypes.func,
    totalPlotsCount: PropTypes.number,
    paginationThreshold: PropTypes.number,
};

export default CampaignTablePagination;
