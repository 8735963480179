import React from 'react';
import PropTypes from 'prop-types';
import ParticipatingDevelopmentsSearchResults from './participating-developments-search-results';
import ParticipatingDevelopmentsSearchZeroResults
    from './participating-developments-search-zero-results';

class ParticipatingDevelopmentsSearchContent extends React.PureComponent {
    isResultEmpty() {
        const { totalCount, isInitialRequestFulfilled } = this.props;
        return isInitialRequestFulfilled && totalCount === 0;
    }

    render() {
        const { animatingContentRef } = this.props;

        return (
            <div className="participating-developments-search__content" ref={animatingContentRef}>
                {!this.isResultEmpty() ? (
                    <ParticipatingDevelopmentsSearchResults/>
                ) : (
                    <ParticipatingDevelopmentsSearchZeroResults/>
                )}
            </div>
        );
    }
}

ParticipatingDevelopmentsSearchContent.propTypes = {
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    animatingContentRef: PropTypes.func.isRequired
};

export default ParticipatingDevelopmentsSearchContent;
