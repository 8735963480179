import React from 'react';
import PropTypes from 'prop-types';
import HighlightableSuggestionItem from '../containers/highlightable-suggestion-item';

const SuggestionsItems = function ({ items, onHighlightedElementUpdate }) {
    const suggestionsLastIndex = items.length - 1;

    return (
        <React.Fragment>
            {items.map((item, index) =>
                <HighlightableSuggestionItem
                    onHighlightedElementUpdate={onHighlightedElementUpdate}
                    key={item.id || index}
                    item={item}
                    isFirst={index === 0}
                    isLast={index === suggestionsLastIndex}
                />)}
        </React.Fragment>
    );
};

SuggestionsItems.propTypes = {
    items: PropTypes.array.isRequired,
    onHighlightedElementUpdate: PropTypes.func.isRequired,
};

export default SuggestionsItems;
