import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import shortid from 'shortid';
import api from '@General/js/api';
import SequentialRequestsProxy from '@General/js/api/sequential-requests-proxy';
import device from '@General/js/device';
import notificationsService from '@General/js/notifications-service';
import constants, { SUGGESTION_TYPE_LOCATION } from './constants.js';
import Spinner from '../../spinner/js/spinner';
import Dropdown from './containers/dropdown';
import Input from './components/input';
import { PredictiveSearchContext } from './context';

const MIN_CHARS_COUNT_DEFAULT = 1;
export const LOOKUP_ERROR_TEXT_DEFAULT =
    'An error has occurred during the request. Please try to re-enter the location later.';

class PredictiveSearch extends React.Component {
    constructor(props) {
        super(props);

        this.api = new SequentialRequestsProxy(api);
        this.listboxId = shortid.generate();
        this.inputId = shortid.generate();
        this.assertId = shortid.generate();
        this.isReady = true;

        this.state = {
            isLoading: false,
            isFocused: false,
            fetchSuggestionTimeout: null,
            isSuggestionsOpened: false,
            highlightedSuggestionIndex: -1,
            ariaActivedescendant: '',
            isHighlightedFromKeyboard: false,
        };

        // sync variable to store real state and not the this.state with has async nature
        this.isSuggestionsOpenedSync = false;

        this.rootRef = React.createRef();
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        // sometimes want to prevent propagation of the event inside the predictive
        // so a workaround applied from the https://github.com/facebook/react/issues/4335
        if (device.isTouch) {
            window.addEventListener('touchstart', this.outerClickHandle.bind(this));
        } else {
            window.addEventListener('click', this.outerClickHandle.bind(this));
        }
    }

    isSuggestionsShown() {
        return (
            this.state.isSuggestionsOpened &&
            this.isMinLengthReached(this.props.text) &&
            // if suggestions are undefined we don't want to show dropdown
            this.props.areSuggestionsDefined
        );
    }

    outerClickHandle = (e) => {
        if (!this.rootRef.current.contains(e.target)) {
            this.closeSuggestions();
            this.handleDeactivate();
        }
    };

    handleDeactivate() {
        if (typeof this.props.onDeactivate === 'function') {
            this.props.onDeactivate();
        }
    }

    getDefaultSuggestion() {
        const firstGoogleLocation = this.props.suggestions.filter(
            (location) => location.type === SUGGESTION_TYPE_LOCATION
        )[0];
        return firstGoogleLocation || this.props.suggestions[0];
    }

    getDropdownClass() {
        return this.isSuggestionsShown() ? 'is-dropdown-visible' : '';
    }

    onSuggestionSelect = (id) => {
        this.select(id);
        this.props.onSuggestionSelect(this.getSuggestionById(id));
    };

    getSuggestionById(id) {
        return this.props.suggestions.find((suggestion) => suggestion.id === id);
    }

    isMinLengthReached(text) {
        return text.length >= this.props.minCharsCount;
    }

    onKeyDown = (e) => {
        const { isSuggestionsOpened, highlightedSuggestionIndex } = this.state;

        const { text, suggestions } = this.props;

        // If suggestions are hidden and user presses arrow down, display suggestions:
        if (
            !isSuggestionsOpened &&
            e.which === constants.keys.DOWN &&
            this.isMinLengthReached(text)
        ) {
            this.openSuggestions();
            return;
        }

        if (!isSuggestionsOpened) {
            return;
        }

        let cancelEvent = true;

        switch (e.which) {
            case constants.keys.ESC:
                this.closeSuggestions();
                break;
            case constants.keys.RIGHT:
            case constants.keys.RETURN:
                if (highlightedSuggestionIndex !== -1) {
                    this.onSuggestionSelect(suggestions[highlightedSuggestionIndex].id);
                } else {
                    // unhandled enter should bubble
                    cancelEvent = false;
                }
                break;
            case constants.keys.UP:
                this.moveUp();
                break;
            case constants.keys.DOWN:
                this.moveDown();
                break;
            default:
                return;
        }

        if (cancelEvent) {
            e.nativeEvent.stopImmediatePropagation();
            e.nativeEvent.preventDefault();
        }
    };

    getContextValue() {
        const { renderSuggestion, renderGroup, suggestions } = this.props;
        const { highlightedSuggestionIndex, isHighlightedFromKeyboard } = this.state;

        return {
            suggestions,
            highlightedSuggestionIndex,
            isHighlightedFromKeyboard,
            renderSuggestion,
            renderGroup,
            onSuggestionSelect: this.onSuggestionSelect,
            suggestionHover: this.suggestionHover,
        };
    }

    onTextChange = (text) => {
        this.props.onTextChange(text);
        if (this.isMinLengthReached(text)) {
            this.handleReadyState(false);
            this.scheduleFetchSuggestions(text);
        } else {
            this.cancelPreviousFetch();
            this.handleReadyState(true);
            this.closeSuggestions();
        }
    };

    scheduleFetchSuggestions = (text) => {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            this.fetchSuggestions(text);
            this.timeoutId = null;
        }, constants.options.DEBOUNCE_TIME);
    };

    cancelPreviousFetch() {
        this.api.cancel();
    }

    focus() {
        this.inputRef.current.focus();
    }

    lookup() {
        this.scheduleFetchSuggestions(this.props.text);
    }

    onFocus = () => {
        this.setState({ isFocused: true });

        if (typeof this.props.onActivate === 'function') {
            this.props.onActivate();
        }
    };

    onInputTouchStart = () => {
        if (device.isTouch) {
            this.openSuggestions();
        }
    };

    onInputClick = () => {
        if (!device.isTouch) {
            this.openSuggestions();
        }
    };

    onBlur = () => {
        this.setState({ isFocused: false });
    };

    openSuggestions = () => {
        if (!this.isSuggestionsOpenedSync) {
            this.isSuggestionsOpenedSync = true;
            this.setState({
                isSuggestionsOpened: true,
            });
        }
    };

    closeSuggestions = () => {
        this.setState(
            {
                isSuggestionsOpened: false,
            },
            () => {
                this.isSuggestionsOpenedSync = false;
            }
        );
    };

    fetchSuggestions = (text) => {
        this.setState({ isLoading: true });
        const { predictiveSearchUrl, predictiveSearchParams } = this.props;

        this.api
            .get(predictiveSearchUrl, { params: { input: text, ...predictiveSearchParams } })
            .then(
                (response) => {
                    this.handleSuccessReceive(response);
                },
                (error) => {
                    if (!axios.isCancel(error)) {
                        this.handleFailedReceive(error);
                    }
                }
            );
    };

    preloadSuggestions = async (text) => {
        const { predictiveSearchUrl, predictiveSearchParams } = this.props;

        return this.api.get(predictiveSearchUrl, {
            params: { input: text, ...predictiveSearchParams },
        });
    };

    handleReadyState(isReady) {
        this.isReady = isReady;
    }

    handleSuccessReceive(response) {
        this.setState({ highlightedSuggestionIndex: -1, isLoading: false });
        this.props.handleSuccessReceive(response);
        this.handleReadyState(true);
        this.openSuggestions();
    }

    handleFailedReceive() {
        this.setState({ isLoading: false });
        this.handleReadyState(true);
        notificationsService.error(this.props.lookupErrorText);
    }

    highlightSuggestion = (index, fromKeyboard) => {
        this.setState({
            highlightedSuggestionIndex: index,
            isHighlightedFromKeyboard: fromKeyboard,
        });
    };

    select = (suggestionId) => {
        this.closeSuggestions();
    };

    moveDown = () => {
        const { highlightedSuggestionIndex } = this.state;
        const { suggestions } = this.props;
        let newHighlightedSuggestionIndex = highlightedSuggestionIndex;
        if (suggestions.length > 0) {
            if (newHighlightedSuggestionIndex === suggestions.length - 1) {
                newHighlightedSuggestionIndex = 0;
            } else {
                newHighlightedSuggestionIndex++;
            }
            this.highlightSuggestion(newHighlightedSuggestionIndex, true);
        }
    };

    moveUp = () => {
        const { highlightedSuggestionIndex } = this.state;
        const { suggestions } = this.props;
        let newHighlightedSuggestionIndex = highlightedSuggestionIndex;
        if (suggestions.length > 0) {
            // if we don't have active or we on the first item of the list
            if (newHighlightedSuggestionIndex === 0 || newHighlightedSuggestionIndex === -1) {
                newHighlightedSuggestionIndex = suggestions.length - 1;
            } else {
                newHighlightedSuggestionIndex--;
            }
            this.highlightSuggestion(newHighlightedSuggestionIndex, true);
        }
    };

    suggestionHover = (id) => {
        const { suggestions } = this.props;
        const suggestionIndex = suggestions.findIndex((suggestion) => suggestion.id === id);
        if (suggestionIndex !== -1) {
            this.highlightSuggestion(suggestionIndex, false);
        }
    };

    getAssertiveString = () => {
        const amount = this.props.suggestions.length;
        if (amount < 1) {
            return '';
        }
        const pluralOrSingleString = amount === 1 ? 'result is' : 'results are';
        return `${amount} ${pluralOrSingleString} available, use up and down arrows to review
            and enter to select.`;
    };

    setActiveSuggestionId = (element) => {
        const ariaActivedescendant = element.id;
        this.setState({ ariaActivedescendant });
    };

    render() {
        const isSuggestionsShown = this.isSuggestionsShown();

        const { isLoading, ariaActivedescendant } = this.state;
        const {
            placeholderText,
            inputClass,
            dropdownClass,
            dropdownInlineStyle,
            text,
            noResultsSuggestionText,
            suggestions,
            groups,
            spinnerClass,
            renderFooter,
        } = this.props;

        return (
            <PredictiveSearchContext.Provider value={this.getContextValue()}>
                <div
                    className={`predictive-search ${this.getDropdownClass()}`}
                    role="combobox"
                    aria-expanded={isSuggestionsShown}
                    aria-owns={this.listboxId}
                    aria-haspopup="listbox"
                    aria-labelledby={this.inputId}
                    ref={this.rootRef}
                >
                    <Input
                        inputId={this.inputId}
                        ariaDescribedby={this.assertId}
                        ariaActivedescendant={ariaActivedescendant}
                        inputRef={this.inputRef}
                        listboxId={this.listboxId}
                        text={text}
                        inputClass={inputClass}
                        placeholderText={placeholderText}
                        onKeyDown={this.onKeyDown}
                        onTextChange={this.onTextChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onTouchStart={this.onInputTouchStart}
                        onClick={this.onInputClick}
                    />
                    <div className={`predictive-search__spinner ${spinnerClass}`}>
                        <Spinner isActive={isLoading} />
                    </div>
                    <Dropdown
                        listboxId={this.listboxId}
                        suggestions={suggestions}
                        groups={groups}
                        zeroSuggestionsMessage={noResultsSuggestionText}
                        isZeroSuggestionsVisible={this.isSuggestionsShown()}
                        dropdownClass={dropdownClass}
                        dropdownInlineStyle={dropdownInlineStyle}
                        renderFooter={renderFooter}
                        setActiveSuggestionId={this.setActiveSuggestionId}
                    />
                    <div
                        id={this.assertId}
                        className="predictive-search__assert"
                        aria-live="assertive"
                    >
                        {this.getAssertiveString()}
                    </div>
                </div>
            </PredictiveSearchContext.Provider>
        );
    }
}

PredictiveSearch.defaultProps = {
    predictiveSearchParams: {},
    minCharsCount: MIN_CHARS_COUNT_DEFAULT,
    lookupErrorText: LOOKUP_ERROR_TEXT_DEFAULT,
    spinnerClass: '',
    areSuggestionsDefined: true,
};

PredictiveSearch.propTypes = {
    areSuggestionsDefined: PropTypes.bool,
    text: PropTypes.string.isRequired,
    suggestions: PropTypes.array.isRequired,
    groups: PropTypes.array,
    renderSuggestion: PropTypes.func.isRequired,
    renderGroup: PropTypes.func,
    handleSuccessReceive: PropTypes.func.isRequired,
    noResultsSuggestionText: PropTypes.string.isRequired,
    predictiveSearchUrl: PropTypes.string.isRequired,
    onSuggestionSelect: PropTypes.func.isRequired,
    onTextChange: PropTypes.func.isRequired,
    minCharsCount: PropTypes.number,
    lookupErrorText: PropTypes.string,
    inputClass: PropTypes.string,
    dropdownClass: PropTypes.string,
    dropdownInlineStyle: PropTypes.string,
    spinnerClass: PropTypes.string,
    placeholderText: PropTypes.string,
    predictiveSearchParams: PropTypes.object,
    renderFooter: PropTypes.func,
};

export default PredictiveSearch;
