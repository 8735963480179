import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentCard from '../../../development-card/js/development-card';

class DevelopmentSearchCard extends React.PureComponent {
    render() {
        return (
            <DevelopmentCard
                {...this.props}
                showButton={true}
                gtmSelector={this.props.gtmSelector}
            />
        );
    }
}
DevelopmentSearchCard.defaultProps = {
    gtmSelector: 'ViewPropertiesCardButton'
};

DevelopmentSearchCard.propTypes = {
    gtmSelector: PropTypes.string
};
export default DevelopmentSearchCard;
