import React from 'react';
import PropTypes from 'prop-types';
import Listing from './listing';
import { getDefaultFilters } from "./listing-helper";

export default function (Component, staticOptions = {}) {
    class InMemoryFiltersListing extends React.Component {
        constructor(props) {
            super(props);
            this.listing = React.createRef();

            this.initialFilters = this.getInitialFilters();
        }

        getInitialFilters() {
            const { filtersConfig } = this.props;
            return getDefaultFilters(filtersConfig)
        }

        getListing() {
            return this.listing.current;
        }

        onFiltersChange = (filterObject) => {
            this._updateFilters(filterObject, false);
        };

        onFiltersApply = (filterObject, debounce = false) => {
            this._updateFilters(filterObject, true, debounce);
        };

        onFiltersReset = (filterNames) => {
            const update = filterNames.reduce((result, filterName) => {
                result[filterName] = this.initialFilters[filterName];
                return result;
            }, {});
            this._updateFilters(update);
        };

        onSearch = () => {
            this._updateFilters();
        };

        _updateFilters(filterObject = {}, apply = true, debounce = false) {
            this.getListing().updateFilters(filterObject, apply, debounce);
        }

        render() {
            const { options } = this.props;

            return (
                <Listing
                    initialFilters={this.initialFilters}
                    staticOptions={staticOptions}
                    options={options}
                    ref={this.listing}
                    render={({ isInitialRequestFulfilled, filters, appliedFilters, items, data, pagination, isLoading, isLoadingMore, onLoadMore, animatingContentRef, innerComponentRef }) => (
                        <Component
                            {...this.props}
                            isInitialRequestFulfilled={isInitialRequestFulfilled}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            items={items}
                            data={data}
                            pagination={pagination}
                            isLoading={isLoading}
                            isLoadingMore={isLoadingMore}
                            onFiltersChange={this.onFiltersChange}
                            onFiltersApply={this.onFiltersApply}
                            onSearch={this.onSearch}
                            onFiltersReset={this.onFiltersReset}
                            onLoadMore={onLoadMore}
                            animatingContentRef={animatingContentRef}
                            ref={innerComponentRef}
                        />
                    )}/>
            );
        }
    }

    InMemoryFiltersListing.propTypes = {
        filtersConfig: PropTypes.array.isRequired,
        options: PropTypes.object.isRequired,
    };
    return InMemoryFiltersListing;
}
