import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import DcBaseComponent from '../../../../general/js/dc/dc-base-component';

const EXPANDED_ACCORDION_CLASS = 'category-area-submenu__accordion--show';
const EXPANDED_SUBCATEGORY_CLASS = 'category-area-submenu__nav-item--show';

export default class CategorySubmenuComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.isTablet = false;
        this.dropdownButtons = this.refs.dropdownButton;
        this.dropdownPopUp = this.refs.dropdownPopUp;
        this.accordionButton = this.refs.accordionButton;
        this.accordionContainer = this.refs.accordionContainer;
    }

    static getNamespace() {
        return 'category-submenu';
    }

    onInit() {
        this.deviceTypeSubscriber = device.subscribeOnDeviceTypeChange(
            () => this._onDeviceTypeChanged(),
            true
        );
    }

    destroy() {
        this.accordionButton.removeEventListener('click', this._onAccordionButtonClick);

        this._removeDropdownListeners();
        this._removeSubmenuDropdownListeners();
    }

    _onDeviceTypeChanged = () => {
        const isTablet = device.isViewportTypeLe(DEVICE_TYPE_TABLET);

        if (isTablet) {
            this._removeDropdownListeners();

            const dropdownButtons = document.querySelectorAll(
                '[data-dc-category-submenu-ref="dropdownTrigger[]"]'
            );

            dropdownButtons.forEach((button) => {
                button.addEventListener('click', this._onExpandButtonClick);
            });

            this.accordionButton.addEventListener('click', this._onAccordionButtonClick);
        } else {
            this._removeSubmenuDropdownListeners();
            this.accordionButton.removeEventListener('click', this._onAccordionButtonClick);

            if (this.dropdownButtons && this.dropdownButtons.length > 0) {
                this.dropdownButtons.forEach((item) => {
                    item.addEventListener('mouseenter', this._onButtonHover);
                    item.addEventListener('mouseleave', this._onButtonHoverLeave);
                });
            }
        }
    };

    _removeDropdownListeners = () => {
        if (this.dropdownButtons && this.dropdownButtons.length > 0) {
            this.dropdownButtons.forEach((item) => {
                item.removeEventListener('mouseenter', this._onButtonHover);
                item.removeEventListener('mouseleave', this._onButtonHoverLeave);
            });
        }
    }

    _removeSubmenuDropdownListeners = () => {
        const dropdownButtons = document.querySelectorAll(
            '[data-dc-category-submenu-ref="dropdownTrigger[]"]'
        );

        dropdownButtons.forEach((button) => {
            button.removeEventListener('click', this._onExpandButtonClick);
        });
    }

    _onButtonHover= (event) => {
        event.target.querySelector('a').setAttribute('aria-expanded', 'true');
    }

    _onButtonHoverLeave= (event) => {
        event.target.querySelector('a').setAttribute('aria-expanded', 'false');
    }

    _onAccordionButtonClick = () => {
        this.accordionContainer.classList.toggle(EXPANDED_ACCORDION_CLASS);

        const isExpanded = this.accordionButton.getAttribute('aria-expanded');
        this.accordionButton.setAttribute('aria-expanded', isExpanded !== 'true');
    }

    _onExpandButtonClick = (e) => {
        const wrapper = e.target.closest('.category-area-submenu__nav-item');
        console.log('wrapper', wrapper);
        if (wrapper) {
            wrapper.classList.toggle(EXPANDED_SUBCATEGORY_CLASS);
        }
    }
}
