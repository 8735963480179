import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import isObjectLike from 'lodash/isObjectLike';
import device, { DEVICE_TYPE_TABLET } from '@General/js/device';
import { PRICE_MODE_WEEK } from '../constants.js';
import PropertyTypeFilter from './property-type-filter';
import ProductTypeFilter from './product-type-filter';

function createActiveServices(services) {
    const totalBudget = services.reduce((acc, val) => acc + val.defaultPrice, 0);
    return services.reduce((acc, val) => {
        const accIndex = acc.length - 1;
        const prevPercentage =
            accIndex === -1 ? 0 : acc[accIndex].prevPercentage + acc[accIndex].percent;
        const percent =
            totalBudget === 0 ? 0 : parseFloat((val.defaultPrice / totalBudget).toFixed(2)) * 100;
        acc.push({
            ...val,
            currentPrice: val.defaultPrice,
            percent,
            prevPercentage,
        });

        return acc;
    }, []);
}

function updateServicePrice(services, serviceIndex, currentPrice) {
    services[serviceIndex].currentPrice = currentPrice;
    const totalBudget = services.reduce((acc, val) => acc + val.currentPrice, 0);
    return services.reduce((acc, val, i, source) => {
        const prevPercentage = i === 0 ? 0 : acc[i - 1].prevPercentage + acc[i - 1].percent;
        const percent =
            totalBudget === 0 ? 0 : parseFloat((val.currentPrice / totalBudget).toFixed(2)) * 100;
        acc.push({
            ...val,
            currentPrice: val.currentPrice,
            percent,
            prevPercentage,
        });
        if (i === source.length - 1 && percent + prevPercentage < 100) {
            const calcError = 100 - percent - prevPercentage;
            // if total is not 100, then add remaining percentage to last not empty sector
            for (let index = acc.length - 1; index > -1; index--) {
                if (acc[index].percent !== 0) {
                    acc[index].percent += calcError;
                    break;
                }
            }
        }
        return acc;
    }, []);
}

const BudgetCalculatorStoreContext = createContext();
const INIT_STATE = {
    isCalcShown: false,
    priceMode: PRICE_MODE_WEEK,
    isTooltipShown: false,
    tooltipText: [],
    tooltipReference: null,
    tooltipMode: null,
    rlpSectionTextDisplayed: false,
    periodButtonsDisplayed: {
        showMonthlyButton: true,
        showWeeklyButton: true,
        showYearlyButton: true,
    },
    isOnMobileViewport: !device.isViewportTypeGe(DEVICE_TYPE_TABLET),
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_PRICE_MODE':
            return { ...state, priceMode: action.priceMode };
        case 'TOGGLE_PROPERTY_TYPE':
            return {
                ...state,
                propertyTypeFilter: state.propertyTypeFilter.updateActiveOption(action.value),
            };
        case 'TOGGLE_PRODUCT_TYPE':
            /* eslint-disable no-case-declarations */

            const options = state.productTypeFilter.options.find((opt) => opt.value === action.value).options;
            const myactiveServices = state.settings.find( (setting)=> setting.productType == action.value).services;

            return {
                ...state,
                productTypeFilter: state.productTypeFilter.updateActiveOption(action.value),
                rlpSectionTextDisplayed: action.value === 'RetirementLivingPlus',
                propertyTypeFilter: new PropertyTypeFilter({ options }),
                activeServices: createActiveServices(myactiveServices),
            };
        /* eslint-enable no-case-declarations */
        case 'TOGGLE_BEDROOM_AMOUNT':
            return {
                ...state,
                propertyTypeFilter: state.propertyTypeFilter.updateBedroomActiveOptionByLabel(
                    action.label
                ),
            };
        case 'SHOW_TOOLTIP':
            return {
                ...state,
                isTooltipShown: true,
                isOverlayShown: true,
                tooltipText: action.text,
                tooltipReference: action.tooltipReference,
                tooltipMode: action.mode,
                tooltipColor: action.tooltipColor,
            };
        case 'HIDE_TOOLTIP':
            return {
                ...state,
                isTooltipShown: false,
                isOverlayShown: false,
                tooltipText: [],
                tooltipReference: null,
                tooltipMode: null,
                tooltipColor: null,
            };
        case 'TOGGLE_CALC':
            return { ...state, isCalcShown: !state.isCalcShown };
        case 'UPDATE_VIEWPORT':
            return { ...state, isOnMobileViewport: action.isOnMobileViewport };
        case 'UPDATE_SERVICE_PRICE':
            return {
                ...state,
                activeServices: updateServicePrice(
                    state.activeServices,
                    action.serviceIndex,
                    action.currentPrice
                ),
            };
        default:
            throw new Error(`Unhandled action type: ${action.type} for BudgetCalculatorStore`);
    }
};

export const BudgetCalculatorStoreProvider = ({ children, options }) => {
    const [state, dispatch] = useReducer(reducer, {
        ...INIT_STATE,
        ...options,
        activeServices: isObjectLike(options.services)
            ? createActiveServices(options.services)
            : null,
        propertyTypeFilter: isObjectLike(options.propertyTypeFilter)
            ? new PropertyTypeFilter(options.propertyTypeFilter)
            : null,
        productTypeFilter: isObjectLike(options.productTypeFilter)
            ? new ProductTypeFilter(options.productTypeFilter)
            : null,
    });

    return (
        <BudgetCalculatorStoreContext.Provider value={[state, dispatch]}>
            {children}
        </BudgetCalculatorStoreContext.Provider>
    );
};

BudgetCalculatorStoreProvider.propTypes = {
    children: PropTypes.any,
    options: PropTypes.shape({
        heading: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        propertyTypesApiUrl: PropTypes.string,
        services: PropTypes.arrayOf(
            PropTypes.shape({
                colorHex: PropTypes.string,
                defaultPrice: PropTypes.number,
                hasTooltip: PropTypes.bool,
                icon: PropTypes.string,
                maxPrice: PropTypes.number,
                minPrice: PropTypes.number,
                title: PropTypes.string,
                tooltip: PropTypes.shape({
                    icon: PropTypes.string,
                    text: PropTypes.string,
                }),
            })
        ),
        propertyTypeFilter: PropTypes.object, // Property type filter interface or null
        productTypeFilter: PropTypes.object, // Product type filter interface or null
    }),
};

export const useBudgetCalculatorStore = () => useContext(BudgetCalculatorStoreContext);
