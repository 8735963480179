import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import LatestDevelopmentsCard from './latest-developments-card';

export default class LatestDevelopmentsCardComponent extends DcBaseComponent {

    static getNamespace() {
        return 'latest-developments-card';
    }

    onInit() {
        this.render();
    }

    render() {
        ReactDOM.render(<LatestDevelopmentsCard item={this.options} />, this.element);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
