import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withContext } from '../context.jsx';

class TabsList extends React.Component {
    render() {
        return (
            <ul className="dev-tabs-list">
                {this.props.tabs.map((tab, i) => (
                    <li className="dev-tabs-list__item" key={tab.id + i}>
                        <button
                            className={classNames({
                                'dev-tabs-list__button': true,
                                'dev-tabs-list__button--active': tab.id === this.props.activeTab.id,
                            })}
                            type="button"
                            onClick={
                                tab.id !== this.props.activeTab.id
                                    ? () => this.props.onTabClick(tab)
                                    : () => {}
                            }
                        >
                            {tab.displayName}
                        </button>
                    </li>
                ))}
            </ul>
        );
    }
}

TabsList.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            displayName: PropTypes.string,
            textName: PropTypes.string,
        })
    ),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        plotsId: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string,
    }),
    onTabClick: PropTypes.func,
};

export default withContext(TabsList, {
    tabs: 'tabs',
    activeTab: 'activeTab',
    onTabClick: 'onTabClick',
});
