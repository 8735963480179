import React from 'react';
import PropTypes from 'prop-types';
import PropertyCard from '../../property-card/js/property-card';

class PropertyCarouselCard extends React.PureComponent {
    render() {
        return <PropertyCard item={this.props.item} alwaysVertical={true} titleSize="h3" />;
    }
}

PropertyCarouselCard.propTypes = {
    item: PropTypes.object.isRequired
};

export default PropertyCarouselCard;
