import React from 'react';
import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import { useStore } from 'effector-react/compat';
import { useInteractiveSitePlanStore } from './use-interactive-site-plan-store.jsx';

const indices = [0];
const FloorTabs = () => {
    const [store, api] = useInteractiveSitePlanStore();
    const state = useStore(store);

    const changeActiveTabView = (floorTabs, floorName) => {
        const lastClickedTab = floorTabs[indices[0]];
        const lastClickedButton = lastClickedTab.querySelector('button');
        lastClickedButton.classList.toggle('active-floor-tab__button');

        if (device.isViewportTypeGe(DEVICE_TYPE_TABLET)) {
            lastClickedButton.innerHTML = floorName;
            lastClickedTab.style = lastClickedButton.classList.contains('active-floor-tab__button')
                ? `flex-basis: ${100 * 3 / state.floors.length}%`
                : `flex-basis: ${100 / state.floors.length}%`;
        }
    };

    const onTabClick = (floor, index) => {
        const floorTabs = document.querySelectorAll('.interactive-site-plan__floor-tab');

        if (indices.length > 0) {
			const stateFloor = state.floors[indices[0]];
            changeActiveTabView(floorTabs, stateFloor.useShortName ? stateFloor.shortName : stateFloor.name);
            indices.pop();
        }

        indices.push(index);
        changeActiveTabView(floorTabs, floor.name);

        api.changeFloorEvt({
            floor: state.floors[index],
            currentFloorIndex: index,
        });
    };

    return (
        <>
            <div className='interactive-site-plan__mobile-preview-tab'>
                <span>{state.floor.map.name}</span>
            </div>
			<div className={`interactive-site-plan__floor-tabs-wrapper ${state.floors.length == 1 ? 'interactive-site-plan__floor-tabs-wrapper--single' : 'interactive-site-plan__floor-tabs-wrapper--multiple'}`}>
                <ul className='interactive-site-plan__floor-tabs'>
                    {
                        state.floors.map((floor, index) => {
                            if (device.isViewportTypeGe(DEVICE_TYPE_TABLET)) {
                                return (
                                    <li
                                        className='interactive-site-plan__floor-tab'
                                        key={state.floor + index}
                                        style={{
                                            flexBasis: index === 0
                                                ? `${100 * 3 / state.floors.length}%`
                                                : `${100 / state.floors.length}%`
                                        }}
                                    >
                                        <button
                                            onClick={() => onTabClick(floor, index)}
                                            className={`${index === 0
                                                ? 'active-floor-tab__button'
                                                : ''} interactive-site-plan__floor-tab-button`
                                            }
                                        >{index === 0 ? floor.name : floor.useShortName ? floor.shortName : floor.name}</button>
                                    </li>
                                );
                            }

                            return (
                                <li
                                    className='interactive-site-plan__floor-tab'
                                    key={state.floor + index}
                                >
                                    <button
                                        onClick={() => onTabClick(floor, index)}
                                        className={`${index === 0
                                            ? 'active-floor-tab__button'
                                            : ''} interactive-site-plan__floor-tab-button`
                                        }
                                    >{floor.shortName}</button>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </>
    );
};

export default FloorTabs;
