import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PropertyDisplay from './property-display.jsx';
import CampaignTablePagination from './campaign-table-pagination.jsx';

const DevPropertyTable = ({ paginationThreshold, item }) => {
    const {
        plots,
        totalPlotsCount,
        development,
    } = item;
    const [activePage, setActivePage] = useState(1);

    const pagination = totalPlotsCount > paginationThreshold &&
        <div className="campaign-property-table__pagination-container">
            <CampaignTablePagination
                plots={plots}
                totalPlotsCount={totalPlotsCount}
                paginationThreshold={paginationThreshold}
                activePage={activePage}
                setActivePage={setActivePage} />
        </div>;

    const currentProperties = plots.slice((activePage - 1) * paginationThreshold, activePage * paginationThreshold);

    return (
        <div className="campaign-property-table" data-tooltip-container>
            <>
                <div className='campaign-property-table__container'>
                    <h4 className='campaign-property-table__header'>{totalPlotsCount} {totalPlotsCount === 1 ? 'property' : 'properties'} at {development.developmentName}:</h4>
                    <PropertyDisplay
                        plots={plots}
                        paginationThreshold={paginationThreshold}
                        totalPlotsCount={totalPlotsCount}
                        currentProperties={currentProperties}
						opsoAvailableText={development.opsoAvailableText}
						showPercentagePricesOverride={development.showPercentagePricesOverride}
                    />
                </div>
            </>

            {pagination}
        </div>
    );
};

DevPropertyTable.propTypes = {
    item: PropTypes.shape({
        development: PropTypes.object,
        plots: PropTypes.array,
        totalPlotsCount: PropTypes.number,
    }),
    paginationThreshold: PropTypes.number,
};

export default DevPropertyTable;
