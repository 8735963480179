import AbstractMenu from './abstract-menu';
import device, { DEVICE_TYPE_TABLET } from '../../../general/js/device';
import QuickSearchComponent from '../../quick-search/js/quick-search.component';
import scrollbarHandler from '../../../general/js/scrollbar-handler';
import constants from 'general/js/constants';

export default class Search extends AbstractMenu {
    constructor(id, { trigger, content, triggerWrapper, closeTrigger, quickSearchElement }) {
        super(id);
        this.trigger = trigger;
        this.triggerWrapper = triggerWrapper;
        this.content = content;
        this.closeTrigger = closeTrigger;
        this.quickSeachElement = quickSearchElement;
        this.wasOpened = false;
    }

    canBeRegistered() {
        return this.trigger && this.content && this.triggerWrapper && this.closeTrigger;
    }

    init() {
        this.trigger.addEventListener('click', this.onTriggerClick);
        this.closeTrigger.addEventListener('click', this.onCloseTriggerClick);
        device.subscribeOnDeviceTypeChange(this.onDeviceTypeChanged, true);
    }

    onDeviceTypeChanged = () => {
        this.addTransitionClass();
    };
    onTriggerClick = e => {
        if (!this.isOpened()) {
            this.open();
        } else {
            this.close();
        }
    };

    onCloseTriggerClick = e => {
        if (this.isOpened()) {
            this.close();
        }
    };

    onKeydown = e => {
        if (e.keyCode === 27) this.close();
    };

    onOuterClick = e => {
        let target = e.target;
        if (
            this.content !== target &&
            this.triggerWrapper !== target &&
            !this.content.contains(target) &&
            !this.triggerWrapper.contains(target)
        ) {
            this.close();
        }
    };

    getContentElement() {
        return this.content;
    }

    getInitialFocusElement() {
        return this.inputElement;
    }

    initInnerComponent() {
        if (!this.wasOpened) {
            this.wasOpened = true;

            this.quickSearch = new QuickSearchComponent(this.quickSeachElement);
            this.quickSearch.init();
        }
    }

    handleOpen() {
        this.initInnerComponent();

        const contentPaddingRight = window.getComputedStyle(this.content).paddingRight.replace(/px/gi, '');
        const scrollbarSize = scrollbarHandler.hasScrollBar() ? scrollbarHandler.getScrollbarSize() : 0;
        this.content.style.paddingRight = parseFloat(contentPaddingRight) + parseFloat(scrollbarSize) + 'px';

        this.content.style.marginTop = -window.pageYOffset + 'px';
        this.showContent();
        this.trigger.setAttribute('aria-expanded', 'true');
        this.triggerWrapper.classList.add('is-active');
        document.addEventListener('keydown', this.onKeydown);
        document.addEventListener('click', this.onOuterClick);
        document.addEventListener('touchstart', this.onOuterClick);
    }

    handleClose() {
        this.content.style.marginTop = null;
        setTimeout(() => {
            this.content.style.paddingRight = null;
        }, constants.DEFAULT_TRANSITION_DURATION);
        this.hideContent();
        this.trigger.setAttribute('aria-expanded', 'false');
        this.triggerWrapper.classList.remove('is-active');
        document.removeEventListener('keydown', this.onKeydown);
        document.removeEventListener('click', this.onOuterClick);
        document.removeEventListener('touchstart', this.onOuterClick);
    }
}
