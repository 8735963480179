import '../../img/pin-bank-green.png';
import '../../img/pin-bank-gray.png';
import '../../img/pin-bell-green.png';
import '../../img/pin-bell-gray.png';
import '../../img/pin-bus-green.png';
import '../../img/pin-bus-gray.png';
import '../../img/pin-cinema-green.png';
import '../../img/pin-cinema-gray.png';
import '../../img/pin-coffee-shop-green.png';
import '../../img/pin-coffee-shop-gray.png';
import '../../img/pin-dentist-green.png';
import '../../img/pin-dentist-gray.png';
import '../../img/pin-development-green.png';
import '../../img/pin-development-gray.png';
import '../../img/pin-doctor-green.png';
import '../../img/pin-doctor-gray.png';
import '../../img/pin-hairdresser-green.png';
import '../../img/pin-hairdresser-gray.png';
import '../../img/pin-key-green.png';
import '../../img/pin-key-gray.png';
import '../../img/pin-leisure-green.png';
import '../../img/pin-leisure-gray.png';
import '../../img/pin-library-green.png';
import '../../img/pin-library-gray.png';
import '../../img/pin-museum-green.png';
import '../../img/pin-museum-gray.png';
import '../../img/pin-open-space-green.png';
import '../../img/pin-open-space-gray.png';
import '../../img/pin-opticians-green.png';
import '../../img/pin-opticians-gray.png';
import '../../img/pin-petrol-station-green.png';
import '../../img/pin-petrol-station-gray.png';
import '../../img/pin-pharmacy-green.png';
import '../../img/pin-pharmacy-gray.png';
import '../../img/pin-place-of-worship-green.png';
import '../../img/pin-place-of-worship-gray.png';
import '../../img/pin-post-green.png';
import '../../img/pin-post-gray.png';
import '../../img/pin-public-house-green.png';
import '../../img/pin-public-house-gray.png';
import '../../img/pin-restaurant-green.png';
import '../../img/pin-restaurant-gray.png';
import '../../img/pin-shopping-green.png';
import '../../img/pin-shopping-gray.png';
import '../../img/pin-spade-green.png';
import '../../img/pin-spade-gray.png';
import '../../img/pin-theatre-green.png';
import '../../img/pin-theatre-gray.png';
import '../../img/pin-train-green.png';
import '../../img/pin-train-gray.png';

function getMarkerURL(type, markerFolderUrl = 'https://www.mccarthyandstone.co.uk/static/images') {
    return `${markerFolderUrl}/pin-${type}-gray.png`;
}

export default function (location) {
    let marker = getMarkerURL(location.type);

    if (marker) {
        marker = 'icon:' + marker + '|';
    }
    marker += `${location.lat},${location.lng}`;
    return '&markers=' + marker;
}
