import React from 'react';
import PropTypes from 'prop-types';

export default class CirclesPreloader extends React.Component {
    render() {
        const { isVisible, caption } = this.props;

        if (!isVisible) return null;

        return (
            <div className="circles-preloader">
                <div className="circles-preloader__spinner">
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <circle cx="3.5" cy="3.5" r="3.5"/>
                    </svg>
                </div>
                {caption && (
                    <div className="circles-preloader__caption">
                        {caption}
                    </div>
                )}
            </div>

        );
    }
}

CirclesPreloader.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    caption: PropTypes.string,
};
