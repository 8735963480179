import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, config } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import CloseSvg from '../svgs/close.svg';

const BottomDrawer = ({ children, isOpen, onClose, title }) => {
    const maxHeight = window.innerHeight;
    const initialHeight = maxHeight / 2;
    const { height } = useSpring({ height: 0, x: 0, y: 0 });
    const handlerRef = useRef(null);

    const open = () => {
        height.start({ to: initialHeight, immediate: false, config: config.stiff });
    };

    const close = () => {
        height.start({ to: 0, config: config.stiff });
        if (onClose) { onClose(); }
    };

    useEffect(() => {
        if (isOpen) {
            open();
            handlerRef.current.focus();
        }
    }, [isOpen]);

    const bind = useDrag(
        ({ last, direction, offset }) => {
            if (last) {
                if (direction[1] < 0) {
                    if (height.get() > initialHeight) {
                        height.start({ to: maxHeight, config: config.gentle });
                    } else {
                        height.start({ to: initialHeight, config: config.stiff });
                    }
                } else if (height.get() > initialHeight) {
                    height.start({ to: initialHeight, config: config.stiff });
                } else {
                    close();
                }
            } else if (height.get() <= maxHeight) {
                height.start({ to: (initialHeight - offset[1]) });
            }
        },
        { axis: 'y',
            filterTaps: true,
            rubberband: true }
    );

    const display = height.to((eventHeight) => (eventHeight > 0 ? 'block' : 'none'));

    return (
        <animated.div className='bottom-drawer' style={{ bottom: '0', display, height }}>
            <div className='bottom-drawer__handler' ref={handlerRef} {...bind()}>
                <div className='bottom-drawer__handler-icon'></div>
            </div>
            <div className='bottom-drawer__header'>
                <div className='bottom-drawer__title'>{title}</div>
                <button className='bottom-drawer__btn-close' onClick={close}>
                    <svg width={16} height={16}>
                        <use xlinkHref={`${CloseSvg}#icon-close`} />
                    </svg>
                </button>
            </div>
            <div className='bottom-drawer__content'>
                {children}
            </div>
        </animated.div>
    );
};

BottomDrawer.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.boolean,
    title: PropTypes.string,
    onClose: PropTypes.func,
};


export default BottomDrawer;
