import React from 'react';
import PropTypes from 'prop-types';
import { getDirtyFiltersConfigs } from '../../../listing/js/listing-helper';
import { withContext } from '../context';
import SelectedFilters from '../components/selected-filters';

class DevelopmentsSearchInfo extends React.PureComponent {
    render() {
        const {
            pagination, appliedFilters, filtersConfig, onFiltersApply
        } = this.props;

        const mainFiltersConfigs = filtersConfig.filter((config) => !config.isSort);
        const dirtyFiltersConfigs = getDirtyFiltersConfigs(appliedFilters, mainFiltersConfigs);

        return (
            <div className="developments-search__info">
                <div className="developments-search__results-count">
                    <div>
                        <strong>{pagination.totalCount}</strong> results found
                    </div>
                </div>
                <div className="developments-search__selected-filters">
                    <SelectedFilters
                        filters={appliedFilters}
                        selectedFiltersConfigs={dirtyFiltersConfigs}
                        onFiltersApply={onFiltersApply}
                    />
                </div>
            </div>
        );
    }
}

DevelopmentsSearchInfo.propTypes = {
    pagination: PropTypes.object.isRequired,
    filtersConfig: PropTypes.array.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
};

export default withContext(DevelopmentsSearchInfo, {
    pagination: 'pagination',
    filtersConfig: 'filtersConfig',
    appliedFilters: 'appliedFilters',
    onFiltersApply: 'onFiltersApply',
});
