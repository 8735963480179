import UrlHelper from 'general/js/url-helper';
import ObjectHelper from 'general/js/object-helper';
import { CONTROL_TYPE_CHECKLIST, CONTROL_TYPE_SELECT } from './constants';

export function getDefaultFilters(filters) {
    return filters.reduce((defaultFilters, filterConfig) => {
        const value = getFilterDefaultValue(filterConfig);
        if (value !== null) {
            defaultFilters[filterConfig.name] = value;
        }
        return defaultFilters;
    }, {});
}

function getFilterDefaultValue(filterConfig) {
    if (!('defaultValue' in filterConfig)) {
        return null;
    }

    return filterConfig.defaultValue;
}

export function getPathWithQueryString(location, exceptParams = []) {
    const baseUrl = location.pathname;
    const searchObject = UrlHelper.getSearchFromLocation(location);
    const paramsKeys = Object.keys(searchObject).filter(key => exceptParams.indexOf(key) === -1);
    const paramsObject = paramsKeys.reduce((result, key) => {
        result[key] = searchObject[key];
        return result;
    }, {});
    return UrlHelper.getUrlByParams(baseUrl, paramsObject);
}

export function isFilterValuesEqual(parameter1, parameter2) {
    if ((ObjectHelper.isObject(parameter1) && ObjectHelper.isObject(parameter2)) || (Array.isArray(parameter1) && Array.isArray(parameter2))) {
        return ObjectHelper.isEqual(parameter1, parameter2);
    }

    return parameter1 === parameter2;
}

export function getFilterConfigByName(filtersConfigs, filterName) {
    return filtersConfigs.find(filtersConfig => filtersConfig.name === filterName);
}

export function getDirtyFiltersConfigs(filters, filtersConfigs) {
    return filtersConfigs.filter(config => isFilterValueDirty(filters[config.name], config));
}

export function isFilterValueDirty(filterValue, filterConfig) {
    return !isFilterValuesEqual(filterValue, getFilterDefaultValue(filterConfig));
}

export function isFiltersConfigsDirty(filtersConfigs, filters) {
    return filtersConfigs.some((filterConfig) => {
        const name = filterConfig.name;
        return isFilterValueDirty(filters[name], filterConfig);
    });
}
