import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OverlayWrapper from '@General/js/overlay/js/overlay-wrapper';
import FocusTrapWrapper from '@General/js/focus-trap/focus-trap-wrapper';
import defaultOverlay from '@General/js/overlay/js/default-overlay';
import MobileFiltersItem from './mobile-filters-item';
import { FILTER_NAME_PRICE, FILTER_NAME_RENT, FILTER_NAME_OPSO } from '../constants.js';
import { withSearchMobileFiltersContext } from '../search-mobile-filters-context.js';

class MobileFiltersModal extends React.Component {
    constructor(props) {
        super(props);

        this.rootRef = React.createRef();
        this.contentRef = React.createRef();
        this.footerRef = React.createRef();
    }

    componentDidUpdate() {
        ['click', 'touchstart'].forEach((event) =>
            document.addEventListener(event, this.outerClick)
        );
    }

    componentWillUnmount() {
        ['click', 'touchstart'].forEach((event) =>
            document.removeEventListener(event, this.outerClick)
        );
    }

    outerClick = (e) => {
        const { isOpened, onOuterClick } = this.props;

        if (isOpened) {
            if (
                this.rootRef.current.contains(e.target) &&
                !this.contentRef.current.contains(e.target) &&
                !this.footerRef.current.contains(e.target)
            ) {
                onOuterClick();
            }
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onApply();
    };

    render() {
        const { isOpened, filtersConfig, filters, onCloseClick } = this.props;

        return (
            <OverlayWrapper
                overlay={defaultOverlay}
                isActive={isOpened}
                blockContent={true}
                retainerId="mobile-filters"
            >
                <FocusTrapWrapper targetElement={this.rootRef.current} isActive={isOpened}>
                    <div
                        className={classNames({
                            'search-mobile-filters__modal': true,
                            'is-opened': isOpened,
                        })}
                        ref={this.rootRef}
                        tabIndex={-1}
                    >
                        <form
                            onSubmit={this.onSubmit}
                            className="search-mobile-filters__modal-inner"
                        >
                            <div className="search-mobile-filters__content" ref={this.contentRef}>
                                <button
                                    onClick={onCloseClick}
                                    type="button"
                                    aria-label="Close filters"
                                    className="btn search-mobile-filters__close-button"
                                >
                                    <svg
                                        width="9"
                                        height="9"
                                        className="icon"
                                        focusable="false"
                                        role="img"
                                        aria-hidden="true"
                                    >
                                        <use xlinkHref="#icon-cross" />
                                    </svg>
                                </button>
                                {filtersConfig.map((config) => {
                                    if (
                                        config.name !== FILTER_NAME_PRICE &&
                                        config.name !== FILTER_NAME_RENT &&
										config.name !== FILTER_NAME_OPSO
                                    ) {
                                        return (
                                            <MobileFiltersItem
                                                key={config.name}
                                                config={config}
                                                value={filters[config.name]}
                                            />
                                        );
                                    }
                                    return false;
                                })}
                            </div>
                            <div className="search-mobile-filters__footer" ref={this.footerRef}>
                                <button
                                    type="submit"
                                    aria-label="Show results"
                                    className="btn search-filters-apply-button"
                                >
                                    Show results
                                </button>
                            </div>
                        </form>
                    </div>
                </FocusTrapWrapper>
            </OverlayWrapper>
        );
    }
}

MobileFiltersModal.defaultProps = {};

MobileFiltersModal.propTypes = {
    filters: PropTypes.object.isRequired,
    filtersConfig: PropTypes.array.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onOuterClick: PropTypes.func.isRequired,
};

export default withSearchMobileFiltersContext(MobileFiltersModal, {
    filters: 'filters',
    filtersConfig: 'filtersConfig',
    onApply: 'onApply',
    onCloseClick: 'onCloseClick',
    onOuterClick: 'onOuterClick',
});
