import React from 'react';
import PropTypes from 'prop-types';
import nanoId from 'nano-id';
import { devSearchTypeProperty } from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import { withContext } from '../context.jsx';
import constants from '../constants.js';

class PropertiesDisplay extends React.PureComponent {

    render() {
        const pageSize = this.props.isOnMobileViewport ? constants.MAX_PROPS_ON_MOBILE : constants.MAX_PROPS_ON_DESKTOP;
        const renderId = nanoId();

        return (
            <div className="properties-table">
                {this.props.currentProperties.map((property, index) => {

                if(index < pageSize) {
				  let switchSaleTooltip;
                  let switchRentTooltip;
                  let switchSaleLabel;
                  let switchRentLabel;
                  let switchSalePrice;
                  let switchRentPrice;

                  let showPercentage;
                  if (this.props.showPercentagePricesOverride == true) {
                    showPercentage = true;
                  } else if (this.props.showPercentagePricesOverride == false) {
                    showPercentage = false;
                  } else {
                    showPercentage = this.props.tableSettings.showOpsoPercentagePrices;
                  }

                  if((property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !this.props.tableSettings.showOpsoPercentagePrices)
                   || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !property.showOpsoPricingOnWebsite)
				   || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !showPercentage)) {
					switchSaleTooltip = '';
                    switchRentTooltip = '';
                    switchSaleLabel = '';
                    switchRentLabel = '';
                    switchSalePrice = this.props.tableSettings.opsoAvailableText;
                    switchRentPrice = null;
                   }else if(property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && property.showOpsoPricingOnWebsite && this.props.tableSettings.showOpsoPercentagePrices && property.opsoSalesPrice == 0) {
					switchSaleTooltip = '';
                    switchRentTooltip = '';
                    switchSaleLabel = '';
                    switchRentLabel = '';
                    switchSalePrice = this.props.tableSettings.opsoAvailableText;
                    switchRentPrice = null;
                   }else if(property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && property.showOpsoPricingOnWebsite && this.props.tableSettings.showOpsoPercentagePrices && property.opsoSalesPrice != 0) {
					switchSaleTooltip = property.opsoSalesFormattedPriceTooltip
                    switchRentTooltip = '';
                    switchSaleLabel = this.props.tableSettings.sharedOwnershipLabel;
                    switchRentLabel = '';
                    switchSalePrice = property.opsoSalesFormattedPrice || this.props.tableSettings.notApplicableLabel;
                    switchRentPrice = null;
                   }
                   else {
					switchSaleTooltip = '';
                    switchRentTooltip = '';
                    switchSaleLabel = this.props.tableSettings.saleLabel;
                    switchRentLabel = this.props.tableSettings.rentLabel;
                    switchSalePrice = property.salesFormattedPrice && property.availableForSale ? property.salesFormattedPrice : this.props.tableSettings.notApplicableLabel;
                    switchRentPrice = property.rentalFormattedPrice && property.availableForRent ?  property.rentalFormattedPrice : this.props.tableSettings.notApplicableLabel;
                   }

                    return (
                        <div className="properties-table__row " key={`${renderId}_${index}`}>
                            <div className="properties-table__info">
                                <div className="properties-table__name">
                                    <h4><a href={property.buttonUrl}>{this.props.tableSettings.propertyLabel} {property.plotNumber}</a></h4>
                                </div>
                                <div className="properties-table__bedroom properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.numberOfBedrooms} {this.props.tableSettings.bedroomTooltip}</div>
                                    <span>{property.numberOfBedrooms}</span>
                                </div>
                                <div className="properties-table__floor properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.friendlyFloorTitle}</div>
                                    <span>{property.friendlyFloorIndex}</span>
                                </div>
                                <div className="properties-table__area properties-table__tooltip">
                                    <div className="properties-table__tooltip__text">{property.area} {this.props.tableSettings.squareMetresTooltip}</div>

                                    {property.area}<span>SQ.M</span>
                                </div>
                            </div>

                            <div className="properties-table__prices">
                            <div className="properties-table__sale-price">
                                    <span className="properties-table__prices--label">{switchSaleLabel}</span>
									{switchSaleTooltip && (
									<>
                                        <div className="tooltip tooltip--dark" data-tooltip>
                                            <button aria-describedby={`tooltip_${renderId}_${index}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                            <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}_${index}`} role="tooltip">
                                                <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                    __html: switchSaleTooltip,
                                                }}></div>
                                            </div>
                                        </div>
									</>
									)}
                                    <span className="properties-table__prices--price" dangerouslySetInnerHTML={{ __html: switchSalePrice }}></span>
                                </div>
                                {!property.hideRentPrices && (property.hideRentPrices !== undefined) &&(
                                    <div className="properties-table__rental-price">
                                        <span className="properties-table__prices--label">{switchRentLabel}</span>
										{switchRentTooltip &&(
										<>
                                            <div className="tooltip tooltip--dark" data-tooltip>
                                            <button aria-describedby={`tooltip_${renderId}_${index}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                            <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}_${index}`} role="tooltip">
                                                <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                                    __html: switchRentTooltip,
                                                }}></div>
                                            </div>
                                        </div>
										</>
										)}
										<span className="properties-table__prices--price" dangerouslySetInnerHTML={{ __html: switchRentPrice} }></span>
                                    </div>
                                )}
                            </div>
                            <a href={property.buttonUrl} className="properties-table__arrow-icon">
                                <span className="properties-table__arrow-icon__label">View property</span>
                                <div className="arrow-icon">
                                    <svg className="property-table__table-cell-btn-icon icon">
                                        <use xlinkHref="#icon-arrow-right-thin"></use>
                                    </svg>
                                </div>
                            </a>
                        </div>
                        )
                    }
                })}
            </div>
        );
    }
}

PropertiesDisplay.propTypes = {
    currentProperties: PropTypes.arrayOf(devSearchTypeProperty),
    tableSettings: PropTypes.objectOf(PropTypes.string),
    isOnMobileViewport: PropTypes.bool,
    visibleColumns: PropTypes.objectOf(PropTypes.bool),
};

export default withContext(PropertiesDisplay, {
    currentProperties: 'currentProperties',
    isOnMobileViewport: 'isOnMobileViewport',
    visibleColumns: 'visibleColumns',
    tableSettings: 'tableSettings',
});
