import React from 'react';
import BudgetBedroom from './budget-bedroom.jsx';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import BudgetList from '../components/budget-list.jsx';
import Filter from '../store/filter';

export default function BudgetPropertyType({dispatchGaEvent}) {
    const [state, dispatch] = useBudgetCalculatorStore();
    if (Filter.isFilterValid(state.propertyTypeFilter)) {
        const { options, title, activeValue, activeOption } = state.propertyTypeFilter;
        const togglePropertyType = (value) => {
            dispatch({
                type: 'TOGGLE_PROPERTY_TYPE',
                value,
            });
        };

        return (
            <>
                <BudgetList
                    className={state.isGenericCalculator ? 'budget-list--mt-20' : ''}
                    radios={options}
                    title={title}
                    changeCallback={togglePropertyType}
                    activeValue={activeValue}
                    dispatchGaEvent={dispatchGaEvent}
                />
                {activeOption.bedroomFilter != null &&
                    Filter.isFilterValid(activeOption.bedroomFilter) && (
                        <BudgetBedroom filter={activeOption.bedroomFilter} dispatchGaEvent={dispatchGaEvent} />
                    )}
            </>
        );
    }

    return null;
}
BudgetPropertyType.propTypes = {
    filter: PropTypes.any,
    dispatchGaEvent: PropTypes.func
};

