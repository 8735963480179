import React, { useContext, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Scroller from 'general/js/scroller';
import { CampaignSearchResultsContext } from '../context';

const arrowRightSvg =
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 16">
        <g id="Group_925" data-name="Group 925" transform="translate(-16 0.75)">
            <line id="Line_126" data-name="Line 126" y2="13" transform="translate(26)"
                fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
            <g id="icon-dropdown-arrow" transform="translate(16 26.5) rotate(-90)">
                <path id="Path_367" data-name="Path 367"
                    d="M6.582,8,.322,1.909A1.217,1.217,0,0,1,.219.386,1.053,1.053,0,0,1,1.656.277L6.479,5.063,11.3.277a.959.959,0,0,1,1.437.109,1.1,1.1,0,0,1-.1,1.523Z"
                    transform="translate(13.5 0)" fill="#fff" />
            </g>
        </g>
    </svg>;

const CampaignSearchPagination = (props) => {
    const [isPaginationLoading, setIsPaginationLoading] = useState(false);
    const {
        setResponseData,
        setIsLoading,
        setError,
        filterOptions,
        setFilterOptions,
        locationSearchId,
        placeId
    } = useContext(CampaignSearchResultsContext);

    const {
        getCampaignSearchUrl,
        maxResultsAmountOnPage,
        paginationSettings,
        scrollElementRef
    } = props;

    const pageOffsets = {
        firstPage: 0,
        lastPage: (paginationSettings.pageCount - 1) * maxResultsAmountOnPage,
        nextPage: (paginationSettings.currentPage) * maxResultsAmountOnPage,
        previousPage: (paginationSettings.currentPage - 2) * maxResultsAmountOnPage,
    };

    const _getScroller = (element) => {
        const scroller = new Scroller(element, {
            duration: 400,
            scrollOffset: +100
        });

        return scroller;
    };

    const _scrollToAnchor = (element) => {
        if (element) {
            const scroller = _getScroller(element);
            return scroller.scrollToTop(false);
        }
    };

    const _requestDevelopments = (requestUrl, searchOffset) => {
        setIsLoading(true);
        setIsPaginationLoading(true);
        _scrollToAnchor(scrollElementRef);
        setFilterOptions({
            ...filterOptions,
            offset: searchOffset,
        });

        axios({
            method: 'post',
            url: locationSearchId ? `${requestUrl}&q=${locationSearchId}` : placeId ? `${requestUrl}&id=${placeId}` : requestUrl,
            data: {
                ...filterOptions,
                offset: searchOffset,
            }
        }).then((res) => {
            if (!res.data) {
                console.log('failed to get data');
                return;
            }

            setIsPaginationLoading(false);

            if (setResponseData) {
                setResponseData(res.data);
                setIsLoading(false);
            }
        }).catch(() => {
            setResponseData([]);
            setError(true);
        });
    };

    const _makeRequestByPageOffset = (pageOffset) => {
        const campaignPaginationUrl = getCampaignSearchUrl(pageOffset);
        _requestDevelopments(campaignPaginationUrl, pageOffset);
    };

    const requestDevelopmentsByPage = (page) => {
        if (page > paginationSettings.pageCount || page < 1) {
            return;
        }

        const pageOffset = (page - 1) * maxResultsAmountOnPage;
        _requestDevelopments(getCampaignSearchUrl(pageOffset), pageOffset);
    };

    const requestLastPageDevelopments = () => {
        if (paginationSettings.currentPage === paginationSettings.pageCount) return;

        _makeRequestByPageOffset(pageOffsets.lastPage);
    };

    const requestFirstPageDevelopments = () => {
        if (paginationSettings.currentPage === 1) {
            return;
        }

        _makeRequestByPageOffset(pageOffsets.firstPage);
    };

    const requestNextPageDevelopments = () => {
        if (paginationSettings.currentPage === paginationSettings.pageCount) return;

        _makeRequestByPageOffset(pageOffsets.nextPage);
    };

    const requestPreviousPageDevelopments = () => {
        if (paginationSettings.currentPage === 1) return;

        _makeRequestByPageOffset(pageOffsets.previousPage);
    };

    const getCurrentPages = () => {
        if (paginationSettings.pageCount >= 3) {
            if (paginationSettings.currentPage === 1) {
                return [
                    paginationSettings.currentPage,
                    paginationSettings.currentPage + 1,
                    paginationSettings.currentPage + 2
                ];
            }

            if (paginationSettings.currentPage === paginationSettings.pageCount) {
                return [
                    paginationSettings.currentPage - 2,
                    paginationSettings.currentPage - 1,
                    paginationSettings.currentPage,
                ];
            }

            if (paginationSettings.currentPage === (paginationSettings.pageCount - 2)) {
                return [
                    paginationSettings.currentPage - 1,
                    paginationSettings.currentPage,
                    paginationSettings.currentPage + 1,
                    paginationSettings.currentPage + 2
                ];
            }

            if (paginationSettings.pageCount === 4) {
                const currentPages = [1, 2, 3, 4];
                return currentPages;
            }

            return [
                paginationSettings.currentPage - 1,
                paginationSettings.currentPage,
                paginationSettings.currentPage + 1
            ];
        }

        if (paginationSettings.pageCount === 5 && paginationSettings.currentPage === (paginationSettings.pageCount - 2)) {
            const currentPages = [1, 2, 3, 4, 5];
            return currentPages;
        }

        return [1, 2];
    };

    const paginationLoaderOverlap = () => (
        <div className='campaign-search-pagination__loader-overlap'></div>
    );

    return (
        <div className='campaign-search-pagination'>
            <div
                className={classNames({
                    'campaign-search-pagination__pagination-wrapper': true,
                })}
            >
                {isPaginationLoading && paginationLoaderOverlap()}
                <ul className="campaign-search-pagination__list">
                    <li className="campaign-search-pagination__item campaign-search-pagination__item--mr">
                        <a href={paginationSettings.currentPage !== 1 ?
                            '#campaign-property-search__input' : null}
                        className={classNames({
                            'campaign-search-pagination__link': true,
                            'campaign-search-pagination__link--arrow': true,
                            'campaign-search-pagination__link--arrow-disabled':
                                paginationSettings.currentPage === 1,
                            'campaign-search-pagination__link--arrow-side': true,
                        })}
                        onClick={requestFirstPageDevelopments}
						title="Go to first page"
                        >
                            <div>{arrowRightSvg}</div>
                        </a>
                    </li>
                    <li className="campaign-search-pagination__item campaign-search-pagination__item--mr">
                        <a href={paginationSettings.currentPage !== 1 ? '#campaign-property-search__input' : null}
                            className={classNames({
                                'campaign-search-pagination__link': true,
                                'campaign-search-pagination__link--arrow': true,
                                'campaign-search-pagination__link--arrow-disabled':
                                    paginationSettings.currentPage === 1,
                                'campaign-search-pagination__link--active':
                                    paginationSettings.currentPage !== 1,
                            })}
                            onClick={requestPreviousPageDevelopments}
							title="Go to previous page"
                        >
                            <svg className="icon" width="8" height="13">
                                <use xlinkHref="#icon-arrow-left-thin"></use>
                            </svg>
                        </a>
                    </li>
                    {
                        ((paginationSettings.currentPage >= (paginationSettings.pageCount - 2) &&
                            !(paginationSettings.pageCount === 5 &&
                                paginationSettings.currentPage === (paginationSettings.pageCount - 2))) &&
                            paginationSettings.pageCount > 4) &&
                        <>
                            <li className="campaign-search-pagination__item">
                                <a href='#campaign-property-search__input'
                                    className={classNames({
                                        'campaign-search-pagination__link': true,
                                        'campaign-search-pagination__link--number': true,
                                    })}
                                    onClick={requestFirstPageDevelopments}
                                >
                                    {1}
                                </a>
                            </li>
                            <li className="campaign-search-pagination__item">
                                <a className="campaign-search-pagination__link">...</a>
                            </li>
                        </>
                    }
                    {
                        getCurrentPages().map((page) => (
                            <li className="campaign-search-pagination__item" key={Math.random()}>
                                <a href='#campaign-property-search__input'
                                    className={classNames({
                                        'campaign-search-pagination__link': true,
                                        'campaign-search-pagination__link--number': true,
                                        'campaign-search-pagination__link--active':
                                            page === paginationSettings.currentPage,
                                    })}
                                    onClick={() => requestDevelopmentsByPage(page)}
                                >
                                    {page}
                                </a>
                            </li>
                        ))
                    }
                    {
                        ((paginationSettings.currentPage < (paginationSettings.pageCount - 1) &&
                            (paginationSettings.currentPage !== (paginationSettings.pageCount - 2))) &&
                            paginationSettings.pageCount > 4) && <>
                            <li className="campaign-search-pagination__item">
                                <a className="campaign-search-pagination__link">...</a>
                            </li>
                            <li className="campaign-search-pagination__item">
                                <a href='#campaign-property-search__input'
                                    className={classNames({
                                        'campaign-search-pagination__link': true,
                                        'campaign-search-pagination__link--number': true,
                                    })}
                                    onClick={requestLastPageDevelopments}
                                >
                                    {paginationSettings.pageCount}
                                </a>
                            </li>
                        </>
                    }
                    <li className="campaign-search-pagination__item campaign-search-pagination__item--ml">
                        <a href={paginationSettings.currentPage !== paginationSettings.pageCount ?
                            '#campaign-property-search__input' : null}
                        className={classNames({
                            'campaign-search-pagination__link': true,
                            'campaign-search-pagination__link--arrow': true,
                            'campaign-search-pagination__link--arrow-disabled':
                                paginationSettings.currentPage === paginationSettings.pageCount,
                            'campaign-search-pagination__link--active':
                                paginationSettings.currentPage !== paginationSettings.pageCount,
                        })}
                        onClick={requestNextPageDevelopments}
						title="Go to next page"
                        >
                            <svg className="icon" width="8" height="13">
                                <use xlinkHref="#icon-arrow-right-thin"></use>
                            </svg>
                        </a>
                    </li>
                    <li className="campaign-search-pagination__item campaign-search-pagination__item--ml">
                        <a href={paginationSettings.currentPage !== paginationSettings.pageCount ?
                            '#campaign-property-search__input' : null}
                        className={classNames({
                            'campaign-search-pagination__link': true,
                            'campaign-search-pagination__link--arrow': true,
                            'campaign-search-pagination__link--arrow-disabled': paginationSettings.currentPage === paginationSettings.pageCount,
                            'campaign-search-pagination__link--arrow-side': true,
                        })}
                        onClick={requestLastPageDevelopments}
						title="Go to last page"
                        >
                            {arrowRightSvg}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

CampaignSearchPagination.propTypes = {
    getCampaignSearchUrl: PropTypes.func,
    maxResultsAmountOnPage: PropTypes.number,
    totalDevelopmentsCount: PropTypes.number,
    campaignSearchUrl: PropTypes.func,
    scrollElementRef: PropTypes.node,
    paginationSettings: PropTypes.shape({
        currentPage: PropTypes.number,
        pageCount: PropTypes.number,
        perPage: PropTypes.number,
        totalCount: PropTypes.number,
    }),
};

export default CampaignSearchPagination;
