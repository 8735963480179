import React from 'react';
import ReactDOM from 'react-dom';
import device, {
    DEVICE_TYPE_MOBILE_WIDE
} from 'general/js/device';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import PropertiesSearch from './properties-search';

const PAGE_SIZE_MOBILE = 2;
const PAGE_SIZE_DESKTOP = 3;

export default class PropertiesSearchComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.filtersConfig = this.options.filters;
        this.items = this.options.items || [];
    }

    static getNamespace() {
        return 'properties-search';
    }

    getPageSize() {
        const isMobile = device.isViewportTypeLe(DEVICE_TYPE_MOBILE_WIDE);
        return isMobile ? PAGE_SIZE_MOBILE : PAGE_SIZE_DESKTOP;
    }

    getSearchOptions() {
        const pageSize = this.getPageSize();

        return {
            items: this.items.slice(0, pageSize),
            pagination: this.options.pagination,
            endpointUrl: this.options.searchUrl,
            zeroResults: this.options.zeroResults,
            pageSize
        };
    }

    onInit() {
        device.subscribeOnDeviceTypeChange(() => {
            const isFiltersMobile = device.isViewportTypeLe(DEVICE_TYPE_MOBILE_WIDE);
            const options = this.getSearchOptions();
            this.render(isFiltersMobile, options);
        }, true);
    }

    render(isFiltersMobile, options) {
        ReactDOM.render(
            <PropertiesSearch
                filtersConfig={this.filtersConfig}
                options={options}
                isFiltersMobile={isFiltersMobile}
            />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
