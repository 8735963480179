import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withContext } from '../context.jsx';

class TabsList extends React.PureComponent {
    render() {
        return (
            <ul className="property-table__tabs-list">
                {this.props.tabs.map((tab, i) => (
                    <li
                        className="property-table__tabs-item"
                        key={tab.id + i}
                    >
                        <button
                            className={classNames({
                                'property-table__tabs-button': true,
                                'property-table__tabs-button--active': tab.id === this.props.activeTab.id
                                    || this.props.tabs.length === 1
                            })}
                            type="button"
                            onClick={tab.id !== this.props.activeTab.id ? () => this.props.onTabClick(tab) : () => { }}
                        >
                            {tab.displayName}
                        </button>
                    </li>
                ))}
            </ul>
        );
    }
}

TabsList.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    })),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string
    }),
    onTabClick: PropTypes.func
};

export default withContext(TabsList, {
    tabs: 'tabs',
    activeTab: 'activeTab'
});
