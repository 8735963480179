import DcBaseComponent from 'general/js/dc/dc-base-component';
import SlickResponsive from 'general/js/slick-carousel/js/slick-responsive';
import $ from 'jquery';
import 'slick-carousel';
import {
    DEVICE_TYPE_DESKTOP,
    DEVICE_TYPE_MOBILE,
    DEVICE_TYPE_TABLET,
} from '../../../general/js/device';

export default class ImageCardsComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.slideCarousel = null;
    }

    static getNamespace() {
        return 'image-cards';
    }

    onInit() {
        const cardSlides = $('.image-cards__slides-wrapper');
        if (cardSlides.length) {
            const slidesCount = cardSlides[0].children.length;
            if (slidesCount > 0 && !$(cardSlides).hasClass('slick-initialized')) {
                this.initSlider(cardSlides);
            }
        }
    }

    initSlider(cardSlides) {
        if (!cardSlides) {
            throw new Error('Slider should contain at least 2 sliders and navs/dots');
        }

        const $cardSlides = $(cardSlides);
        this.slideCarousel = new SlickResponsive(
            $cardSlides,
            {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                fade: false,
                accessibility: true,
                draggable: false,
                infinite: false,
                swipe: true,
                touchMove: false,
                autoplaySpeed: 2000,
                speed: 800,
                rows: 0,
            },
            {
                [DEVICE_TYPE_MOBILE]: {
                    slidesToShow: 1.18,
                    arrows: false,
                },
                [DEVICE_TYPE_TABLET]: {
                    slidesToShow: 2.18,
                    arrows: false,
                },
                [DEVICE_TYPE_DESKTOP]: {
                    slidesToShow: 4,
                    arrows: true,
                },
            }
        );
    }

    onDestroy() {
        if (this.slideCarousel !== null) {
            this.slideCarousel.slick('unslick');
            this.slideCarousel = null;
        }
    }
}
