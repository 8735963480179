import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import nanoId from 'nano-id';
import { devSearchTypeProperty } from '@Components/developments-search-v2/js/dev-search-types.js';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class PropertyDisplay extends React.Component {
    getPrice = (property) => {
        const { activeTab } = this.props;
        let showPercentage;

        if (this.props.showPercentagePricesOverride == true) {
            showPercentage = true;
          
          } else if (this.props.showPercentagePricesOverride == false) {
          
            showPercentage = false;
          } else {
            showPercentage = this.props.tableSettings.showOpsoPercentagePrices;
          }

        if(((property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !showPercentage) || (property.opsoAvailable && this.props.tableSettings.isOpsoEnabled && !property.showOpsoPricingOnWebsite))) {
            return this.props.tableSettings.sharedOwnershipLabel;
        }
        return activeTab.id === constants.TABS[constants.SELLING_TAB].id
            ? property.salesFormattedPrice
            : property.rentalFormattedPrice;
    };

    render() {
        const {
            activeTab,
            currentProperties,
            tabDisplayOption,
            isOnMobileViewport
        } = this.props;

        return (
            <tbody className="dev-property-table__table-body">
                {currentProperties.map((property) => {
                    const activeVisibleColumns = `${activeTab.shortName}TabVisibleColumns`;
                    const propertyDisplayOption = tabDisplayOption && tabDisplayOption[activeVisibleColumns];

                    return (
                        <tr className="dev-property-table__table-row" key={nanoId()}>
                            {propertyDisplayOption.number &&
                                <th className="dev-property-table__table-cell" role="cell" scope="row">
                                    {property.plotNumber}
                                </th>
                            }
                            {propertyDisplayOption.bedrooms &&
                                <td className="dev-property-table__table-cell">
                                    {property.numberOfBedrooms}
                                </td>
                            }
                            {propertyDisplayOption.prices &&
                                <td
                                    className="dev-property-table__table-cell"
                                    dangerouslySetInnerHTML={{
                                        __html: this.getPrice(property),
                                    }}
                                ></td>
                            }
                            {propertyDisplayOption.floor &&
                                <td className="dev-property-table__table-cell dev-property-table__table-cell--none-tablet">
                                    {property.floor}
                                </td>
                            }
                            {propertyDisplayOption.status && <td
                                className={classNames({
                                    'dev-property-table__table-cell': true,
                                    'dev-property-table__table-cell--none-tablet': true,
                                    'dev-property-table__table-cell--color-orange':
                                        property.status === 'Under Offer',
                                    'dev-property-table__table-cell--color-red':
                                        property.status === 'Reserved',
                                })}
                            >
                                {property.status}
                            </td>
                            }
                            {propertyDisplayOption.area &&
                                <td className="dev-property-table__table-cell dev-property-table__table-cell--none-tablet">
                                    {property.area}
                                </td>
                            }
                            <td className="dev-property-table__table-cell">
                                <a
                                    className="dev-property-table__table-cell-btn link"
                                    href={property.buttonUrl}
                                >
                                    <span className="dev-property-table__table-cell-btn-text">
                                        {isOnMobileViewport ? 'View' : 'View property'}
                                    </span>
                                    <svg className="dev-property-table__table-cell-btn-icon icon">
                                        <use xlinkHref="#icon-arrow-right-thin"></use>
                                    </svg>
                                </a>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        );
    }
}

PropertyDisplay.propTypes = {
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        plotsId: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string,
        shortName: PropTypes.string,
    }),
    tabDisplayOption: PropTypes.shape({
        rentTabVisibleColumns: PropTypes.object,
        salesTabVisibleColumns: PropTypes.object,
    }),
    currentProperties: PropTypes.arrayOf(devSearchTypeProperty),
    isOnMobileViewport: PropTypes.bool,
    tableSettings: PropTypes.objectOf(PropTypes.string),
};

export default withContext(PropertyDisplay, {
    currentProperties: 'currentProperties',
    isOnMobileViewport: 'isOnMobileViewport',
    activeTab: 'activeTab',
    tableSettings: 'tableSettings',
});
