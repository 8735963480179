import React from 'react';
import BudgetBedroom from './budget-bedroom.jsx';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import BudgetList from '../components/budget-list.jsx';
import Filter from '../store/filter';

export default function BudgetProductType({ dispatchGaEvent }) {
    const [state, dispatch] = useBudgetCalculatorStore();
    if (Filter.isFilterValid(state.productTypeFilter)) {
        const { title, options, activeValue, activeOption } = state.productTypeFilter;
        const toggleProductType = (value) => {
            dispatch({
                type: 'TOGGLE_PRODUCT_TYPE',
                value,
            });
        };

        return (
            <>
                <BudgetList
                    radios={options}
                    title={title}
                    changeCallback={toggleProductType}
                    activeValue={activeValue}
                    dispatchGaEvent={dispatchGaEvent}
                />
                {activeOption.bedroomFilter != null &&
                    Filter.isFilterValid(activeOption.bedroomFilter) && (
                        <BudgetBedroom filter={activeOption.bedroomFilter} dispatchGaEvent={dispatchGaEvent} />
                    )}
            </>
        );
    }

    return null;
}
BudgetProductType.propTypes = {
    dispatchGaEvent: PropTypes.func
};
