import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { CampaignSearchResultsContext } from '@Components/campaign-property-search/js/context';

const CampaignMobileSortingPopup = React.forwardRef((props, ref) => {
    const {
        sortingConfig,
        setLabelText,
        setIsRotatedIcon,
        labelText,
        isPopupOpened,
        sortData,
    } = props;
    const { filterOptions, locationSearchId } = useContext(CampaignSearchResultsContext);

    const handleLabelClick = (inputValue) => {
        sortData(inputValue);
    };

    const onSortChange = (target, { value, text }) => {
        handleLabelClick(target.value);
        setIsRotatedIcon(target.value === 'PriceDescending');
        setLabelText(text);
    };

    return (
        <form action=''>
            <ul
                ref={ref}
                className={cn(
                    'campaign-mobile-sorting__options-list',
                    { 'option-list-opened': isPopupOpened }
                )}>
                {sortingConfig.options.map((item) => {
                    const isChecked = filterOptions.sort === item.value;
                    const isDisabled = item.value === 'Distance' && !locationSearchId;

                    return <React.Fragment key={item.text} >
                        <li className={cn(
                            'campaign-mobile-sorting__option',
                            { 'campaign-mobile-sorting__option--active': isChecked },
                            { 'campaign-mobile-sorting__option--disabled': isDisabled }
                        )}>
                            <input
                                type="radio"
                                name='sorting'
                                value={item.value}
                                id={item.text}
                                disabled={isDisabled}
                                checked={isChecked}
                                className='campaign-mobile-sorting__input'
                                onChange={({ target }) => onSortChange(target, item)} />
                            <label
                                htmlFor={item.text}
                                className="campaign-mobile-sorting__option-label">{item.text}</label>
                        </li>
                    </React.Fragment>;
                })}
            </ul>
        </form>
    );
});

CampaignMobileSortingPopup.propTypes = {
    sortingConfig: PropTypes.object.isRequired,
    isPopupOpened: PropTypes.bool.isRequired,
    setLabelText: PropTypes.func.isRequired,
    setIsRotatedIcon: PropTypes.func.isRequired,
    labelText: PropTypes.string.isRequired,
};

export default CampaignMobileSortingPopup;
