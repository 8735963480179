import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_LABEL_STYLES, DISABLED_LABEL_STYLES }
    from '../../../developments-search-all-ownership-types/js/components/developments-search-sorting';

export default class SearchDesktopSorting extends React.PureComponent {
    render() {
        const {
            label,
            value,
            sortOptions,
            currentOptionLabel,
            onFiltersApply,
            currentLabelStyles,
            name
        } = this.props.searchProps;

        return (
            <>
                <span className='developments-search__sorting-label-v2'>
                    {label}:
                </span>
                <div className='developments-search__sorting-list-v2'>
                    <form id={'sort-by'} value={value} className='js-drop' onChange={(e) => onFiltersApply({ [name]: e.target.value })}>
                        {sortOptions.map((option, index) => {
                            const isDistanceDisabled = option.text.toLowerCase().trim() === 'distance'
                                && option.isDisabled;
                            const labelStyles = isDistanceDisabled
                                ? DISABLED_LABEL_STYLES
                                : DEFAULT_LABEL_STYLES;

                            return (
                                <label key={index} style={option.text === currentOptionLabel ? currentLabelStyles : labelStyles}>
                                    {isDistanceDisabled ?
                                        <input
                                            disabled
                                            type='radio'
                                            id={`item-${index}`}
                                            name='item'
                                            value={option.value}>
                                        </input>
                                        : <input
                                            type='radio'
                                            id={`item-${index}`}
                                            name='item'
                                            value={option.value}>
                                        </input>
                                    }
                                    <span>{option.text}</span>
                                </label>
                            );
                        })}
                    </form>
                </div>
            </>
        );
    }
}

SearchDesktopSorting.propTypes = {
    searchProps: PropTypes.shape({
        label: PropTypes.string.isRequired,
        sortOptions: PropTypes.array.isRequired,
        value: PropTypes.string.isRequired,
        onFiltersApply: PropTypes.func.isRequired,
        currentOptionLabel: PropTypes.string.isRequired,
        currentLabelStyles: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
    }),
};
