import React from 'react';
import PropTypes from 'prop-types';
import { VacanciesSearchContext } from './context';
import locationFiltersListing from '../../listing/js/location-filters-listing';
import VacanciesSearchControlsSection from './containers/vacancies-search-controls-section';
import VacanciesSearchContent from './containers/vacancies-search-content';
import PageSpinner from '../../page-spinner/js/page-spinner';

class VacanciesSearch extends React.Component {
    getContextValue() {
        const {
            options,
            filtersConfig,
            filters,
            appliedFilters,
            items,
            data,
            onFiltersApply
        } = this.props;

        return {
            items,
            onFiltersApply,
            filters,
            appliedFilters,
            filtersConfig: data.filters || filtersConfig,
            zeroResults: options.zeroResults
        };
    }

    render() {
        const { isLoading, items, isInitialRequestFulfilled, animatingContentRef } = this.props;
        return (
            <PageSpinner isActive={isLoading}>
                <VacanciesSearchContext.Provider value={this.getContextValue()}>
                    <VacanciesSearchControlsSection/>
                    <VacanciesSearchContent
                        animatingContentRef={animatingContentRef}
                        isInitialRequestFulfilled={isInitialRequestFulfilled}
                        items={items}
                    />
                </VacanciesSearchContext.Provider>
            </PageSpinner>
        );
    }
}

VacanciesSearch.defaultProps = {
    data: {}
};

VacanciesSearch.propTypes = {
    options: PropTypes.shape({
        zeroResults: PropTypes.object.isRequired
    }),
    filtersConfig: PropTypes.array.isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    onFiltersReset: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    animatingContentRef: PropTypes.func.isRequired,
};

export default locationFiltersListing(VacanciesSearch);
