
import device, { DEVICE_TYPE_TABLET } from 'general/js/device';
import constants from 'general/js/constants';
import AbstractMenu from './abstract-menu';
import AccordionManager from '../../collapsible-tabs/js/accordion-manager';

export default class DropdownNavigation extends AbstractMenu {
    constructor(id, { trigger, content, closeTrigger }) {
        super(id);
        this.trigger = trigger;
        this.content = content;
        this.isBootstraped = false;
        this.currentSubTriggerIndex = null;
        this.isInitiated = false;
        this.instance = null;

        this.subTriggers = [...this.trigger.querySelectorAll('[data-dc-header-ref="dropdownNavTrigger[]"]')];
        this.subContents = [...this.content.querySelectorAll('[data-dc-header-ref="dropdownContentInner[]"]')];
        this.innerContent = this.content.querySelector('.dropdown-navigation');
    }

    addListeners() {
        device.subscribeOnDeviceTypeChange(this.onDeviceTypeChanged, true);
    }

    setActiveState() {
        for (let contentIndex in this.subContents) {
            let links = Array.from(this.subContents[contentIndex].querySelectorAll('a'));

            if (!links.length) {
                continue;
            }

            for (let link in links) {
                let firstPathComponent = links[link].getAttribute('href').match(/^\/[\w\-]+\//);

                if (!firstPathComponent) {
                    continue;
                }
                firstPathComponent = firstPathComponent[0]

                if (window.location.pathname.indexOf(firstPathComponent) > -1) {
                    this.subTriggers[contentIndex].parentElement.classList.add('is-current-link');
                    return;
                }
            }
        }
    }

    _createDesktopAccordion() { // try to replace element and links
        if (!this.isInitiated) {
            this.subContents.forEach((element) => {
                const subContentsChildLinks = element.querySelectorAll('.dropdown-navigation__col');
                if (element.children) {
                    this.instance = new AccordionManager(false, element, subContentsChildLinks);
                }
            });
        }
    }

    canBeRegistered() {
        return this.trigger && this.content;
    }

    init() {
        // this._createDesktopAccordion();
        this.beforeBootstrapCheck();
        this.setActiveState();
        this.addListeners();
    }

    onDeviceTypeChanged = () => {
        this.beforeBootstrapCheck();
        this.addTransitionClass();
    };

    beforeBootstrapCheck = () => {
        if (device.isViewportTypeGe(DEVICE_TYPE_TABLET)) {
            if (!this.isBootstraped) {
                this.isBootstraped = true;
                this.bootstrap();
            }
        } else {
            if (this.isOpened()) {
                this.close();
            }
        }
    };

    bootstrap() {
        this.subTriggers.forEach((subTrigger, index) =>
            subTrigger.addEventListener('click', this.onSubTriggerClick.bind(this, index))
        );
        this.subContents.forEach(subContent => subContent.classList.add('is-transitioned'));
    }

    getContentElement() {
        return this.content;
    }

    handleOpen(header) {
        this.content.style.maxHeight = `calc(100vh - ${this.trigger.getBoundingClientRect().bottom}px)`
        this.showContent();
        this.trigger.setAttribute('aria-expanded', 'true');
        document.addEventListener('keydown', this.onKeydown);
        if (device.isViewportTypeGe(DEVICE_TYPE_TABLET)) {
            document.addEventListener('click', this.onOuterClick);
            document.addEventListener('touchstart', this.onOuterClick);
        }
    }

    handleClose() {
        setTimeout(() => {
            this.content.style.paddingRight = null;
        }, constants.DEFAULT_TRANSITION_DURATION);
        this.hideContent();
        this.trigger.setAttribute('aria-expanded', 'false');
        this.removeAllActiveClasses();
        document.removeEventListener('keydown', this.onKeydown);
        if (device.isViewportTypeGe(DEVICE_TYPE_TABLET)) {
            document.removeEventListener('click', this.onOuterClick);
            document.removeEventListener('touchstart', this.onOuterClick);
        }
    }

    onTriggerClick = (e, index) => {
        if (!this.isOpened()) {
            this.open();
        } else {
            this.close();
        }
    };

    onCloseTriggerClick = e => {
        if (this.isOpened()) {
            this.close();
        }
    };

    onSubTriggerClick(triggerIndex) {
        if (!this.isOpened()) {
            this.open();
        } else if (triggerIndex === this.currentSubTriggerIndex) {
            this.close();
            this.removeAllActiveClasses();
        }
        this.toggleSubContent(triggerIndex);
    }

    onKeydown = e => {
        if (e.keyCode === 27) this.close();
    };

    onOuterClick = e => {
        let target = e.target;
        if (
            this.content !== target &&
            this.trigger !== target &&
            !this.content.contains(target) &&
            !this.trigger.contains(target)
        ) {
            this.close();
            this.currentSubTriggerIndex = null;
        }
    };

    toggleSubContent(triggerIndex) {
        this.subContents.forEach((subContent, index) => {
            if (triggerIndex !== index) {
                this.hideSubContent(subContent);
                this.removeActiveClass(index);
            }
        });
        if (this.currentSubTriggerIndex === triggerIndex) {
            this.currentSubTriggerIndex = null;
        } else {
            this.currentSubTriggerIndex = triggerIndex;
            this.showSubContent(this.subContents[triggerIndex]);
            this.addActiveClass(triggerIndex);
        }
    }

    showSubContent(subcontent) {
        subcontent.style.display = 'block';
        setTimeout(() => {
            subcontent.classList.add('is-opaque');
            this.setFocusTrap();
        }, 0);
    }

    hideSubContent(subcontent) {
        subcontent.style.display = 'none';
        subcontent.classList.remove('is-opaque');
    }

    addActiveClass(triggerIndex) {
        this.subTriggers[triggerIndex].setAttribute('aria-expanded', true);
        this.subTriggers[triggerIndex].parentElement.classList.add('is-active');
    }

    removeActiveClass(triggerIndex) {
        this.subTriggers[triggerIndex].setAttribute('aria-expanded', false);
        this.subTriggers[triggerIndex].parentElement.classList.remove('is-active');
    }

    removeAllActiveClasses() {
        this.subTriggers.forEach((subContent, index) => {
            this.removeActiveClass(index);
        });
    }
}
