import Filter from './filter';
import ProductTypeFilterOption from './product-type-filter-option';

export default class ProductTypeFilter extends Filter {
    constructor({ title = 'Product Type', options }) {
        super({ title, options });

        this.options = this.setOptions({ ClassType: ProductTypeFilterOption }, options);
        this.setInitialActiveOption();
    }
}
