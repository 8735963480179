import React from 'react';
import PropTypes from 'prop-types';
import SuggestionsGroups from './suggestions-groups';
import SuggestionsItems from './suggestions-items';

const Suggestions = ({ suggestions, groups, onHighlightedElementUpdate, renderFooter }) => {
    return (
        <React.Fragment>
            <div className="predictive-search__suggestions">
                {groups && groups.length > 0 ?
                    <SuggestionsGroups
                        groups={groups}
                        onHighlightedElementUpdate={onHighlightedElementUpdate}/> :
                    <SuggestionsItems
                        items={suggestions}
                        onHighlightedElementUpdate={onHighlightedElementUpdate}/>}
            </div>
            {renderFooter ? renderFooter(suggestions) : null}
        </React.Fragment>
    );
};


Suggestions.propTypes = {
    suggestions: PropTypes.array.isRequired,
    groups: PropTypes.array,
    onHighlightedElementUpdate: PropTypes.func.isRequired,
    renderFooter: PropTypes.func,
};

export default Suggestions;
