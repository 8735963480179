import React from 'react';
import PropTypes from 'prop-types';
import StandaloneDevelopmentsPredictiveSearch from '../../developments-predictive-search/standalone-developments-predictive-search';

export default class QuickSearchBordered extends React.Component {
    constructor(props) {
        super(props);
    }

    renderButton = (buttonClass, buttonIconClass) => {
        return (
            <button
                className={`btn quick-search-bordered__search-button ${buttonClass}`}
                type="submit"
                aria-label={'Search for location'}
            >
                <div className="button-content">
                    Search
                    <svg
                        className={`icon quick-search-bordered__search-button-icon ${buttonIconClass}`}
                        width="18"
                        height="17"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-search-long" />
                    </svg>
                </div>
            </button>
        );
    };

    render() {
        const { predictiveSearchOptions, defaultSearchUrl } = this.props;

        return (
            <div className="quick-search-bordered">
                <div className="quick-search-bordered__predictive">
                    <StandaloneDevelopmentsPredictiveSearch
                        defaultSearchUrl={defaultSearchUrl}
                        predictiveSearchOptions={predictiveSearchOptions}
                        renderButton={this.renderButton}
                        inputClass={'quick-search-bordered__predictive-input'}
                        dropdownClass={'quick-search-bordered__predictive-dropdown'}
                    />
                </div>
            </div>
        );
    }
}

QuickSearchBordered.propTypes = {
    defaultSearchUrl: PropTypes.string.isRequired,
    predictiveSearchOptions: PropTypes.object.isRequired,
};
