import React from 'react';
import PropTypes from 'prop-types';
import SlotButton from './slot-button';

class TimeSlots extends React.PureComponent {
    render() {
        const { slots, selectedSlot, onSlotClick } = this.props;

        if (slots.length === 0) {
            return null;
        }

        return (
            <div className="time-slot-picker__time-slots">
                <div className="form-control">
                    <div className="form-control__header">
                        <div className="form-control__label">Select a time</div>
                        <div className="form-control__description">Viewings typically last an hour
                        </div>
                    </div>
                    <div className="form-control__content">
                        <div className="time-slot-picker__time-slots-list">
                            {
                                slots.length > 0 ?
                                    slots.map(slot => (
                                        <div className="time-slot-picker__time-slot"
                                            key={slot.date}>
                                            <SlotButton
                                                mixinClass="time-slot"
                                                onClick={() => onSlotClick(slot)}
                                                disabled={!slot.isAvailable}
                                                selected={selectedSlot === slot}>
                                                {slot.displayTitle}
                                            </SlotButton>
                                        </div>
                                    )) :
                                    <div className="time-slot-picker__zero-time-slots-message">
                                        There are no time slots available on this date. Please select another date.
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TimeSlots.propTypes = {
    slots: PropTypes.array.isRequired,
    selectedSlot: PropTypes.object,
    onSlotClick: PropTypes.func.isRequired,
};

export default TimeSlots;
