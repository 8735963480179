import utils from './utils';

export default class Help {
    constructor({ help, overlay, closeHelpButton }) {
        if (help && overlay && closeHelpButton) {
            this.help = help;
            this.overlay = overlay;
            this.closeHelpButton = closeHelpButton;

            this.overlay.addEventListener('click', this.hide, false);
            this.closeHelpButton.addEventListener('click', this.hide, false);
        } else {
            console.error('Wrong refs for image-mapper help');
        }
    }

    hide = () => {
        utils.hide(this.help);
        utils.hide(this.overlay);
        return this;
    };

    show = () => {
        utils.show(this.help);
        utils.show(this.overlay);
        return this;
    };

    destroy = () => {
        if (this.overlay) {
            this.overlay.removeEventListener('click', this.hide, false);
        }

        if (this.closeHelpButton) {
            this.closeHelpButton.removeEventListener('click', this.hide, false);
        }
    };
}
