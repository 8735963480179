import constants from 'general/js/constants';
import analyticsService from 'general/js/analytics-service';
import AbstractMenu from './abstract-menu';

const ADD_LISTENERS_DELAY = 300;

const BUTTON_ANALYTICS_CATEGORY = 'Header Navigation';
const BUTTON_ANALYTICS_ACTION = 'Header Navigation Button Click';
const BUTTON_ANALYTICS_LABEL = 'Header Navigation Button Click - Get in Touch';

const PHONE_NUMBER_ANALYTICS_CATEGORY = 'Click To Call';


export default class MobileMenu extends AbstractMenu {
    constructor(id, {
        trigger, content, closeTrigger, phoneLinks
    }) {
        super(id);
        this.trigger = trigger;
        this.content = content;
        this.closeTrigger = closeTrigger;
        this.phoneLinks = phoneLinks;

        this.isBootstraped = false;

        this.onTriggerClick = this.onTriggerClick.bind(this);
        this.onCloseTriggerClick = this.onCloseTriggerClick.bind(this);
        this.onOuterClick = this.onOuterClick.bind(this);
    }

    init() {
        this.bootstrap();
    }

    canBeRegistered() {
        return this.trigger && this.content && this.closeTrigger;
    }

    bootstrap() {
        this._trackAnalytics();
        this._addOpenListeners();
        this.addTransitionClass();
    }

    _trackAnalytics() {
        this.trigger.addEventListener('click', this._trackButtonClick.bind(this));
        this.phoneLinks.forEach((anchor) => {
            anchor.addEventListener('click', this._trackPhoneNumberClick);
        });
    }

    _addOpenListeners() {
        this.trigger.addEventListener('click', this.onTriggerClick);
    }

    _addCloseListeners() {
        setTimeout(() => {
            document.addEventListener('click', this.onOuterClick);
            document.addEventListener('touchstart', this.onOuterClick);
            this.closeTrigger.addEventListener('click', this.onCloseTriggerClick);
        }, ADD_LISTENERS_DELAY);
    }

    _removeOpenListeners() {
        this.trigger.removeEventListener('click', this.onTriggerClick);
    }

    _removeCloseListeners() {
        document.removeEventListener('click', this.onOuterClick);
        document.removeEventListener('touchstart', this.onOuterClick);
        this.closeTrigger.removeEventListener('click', this.onCloseTriggerClick);
    }

    onTriggerClick() {
        if (!this.isOpened()) {
            this._open();
        } else {
            this._close();
        }
    }

    _open() {
        this.open();
        this._addCloseListeners();
        this._removeOpenListeners();
    }

    _close() {
        this.close();
        this._removeCloseListeners();
        this._addOpenListeners();
    }

    onCloseTriggerClick() {
        if (this.isOpened()) {
            this._close();
        }
    }

    getContentElement() {
        return this.content;
    }

    handleOpen() {
        this._setPointerEventsDelay(this.closeTrigger, constants.DEFAULT_TRANSITION_DURATION);
        this.showContent();
        this.trigger.setAttribute('aria-expanded', 'true');
    }

    handleClose() {
        this.hideContent();
        this.trigger.setAttribute('aria-expanded', 'false');
    }

    onOuterClick(e) {
        const target = e.target;
        if (
            this.content !== target &&
            this.trigger !== target &&
            !this.content.contains(target) &&
            !this.trigger.contains(target)
        ) {
            this._close();
        }
    }

    _setPointerEventsDelay(element, delay) {
        element.style.pointerEvents = 'none';
        setTimeout(() => {
            element.style.pointerEvents = 'all';
        }, delay);
    }

    _trackButtonClick() {
        analyticsService.sendEvent(
            BUTTON_ANALYTICS_CATEGORY,
            BUTTON_ANALYTICS_ACTION,
            BUTTON_ANALYTICS_LABEL
        );
    }

    _trackPhoneNumberClick(event) {
        const anchorHref = event.currentTarget.href;
        const currentUrl = window.location.pathname;
        const PHONE_NUMBER_ANALYTICS_ACTION = `${anchorHref} - ${currentUrl}`;
        const label = event.currentTarget.dataset.gtmLabel || 'Other number';

        analyticsService.sendEvent(
            PHONE_NUMBER_ANALYTICS_CATEGORY,
            PHONE_NUMBER_ANALYTICS_ACTION,
            label
        );
    }
}
