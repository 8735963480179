import React, { useState, useEffect } from 'react';
import PropTypes, { element } from 'prop-types';
import cn from 'classnames';

import { CampaignMobileFilterContext } from '@Components/campaign-property-search/js/context';
import { initialPayloadState } from '@Components/campaign-property-search/js/constants';
import CampaignSearchMobileSorting from '@Components/campaign-property-search-sorting/js/mobile/campaign-search-mobile-sorting';

import MobileFilterModal from './mobile-filter-modal';

const filterSvg = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs>
        <clipPath id="clip-path">
            <rect id="Rectangle_1782" data-name="Rectangle 1782" width="20" height="20" transform="translate(385.5)" fill="#fff" />
        </clipPath>
    </defs>
    <g id="Mask_Group_50" data-name="Mask Group 50" transform="translate(-385.5)" clipPath="url(#clip-path)">
        <g id="preferences" transform="translate(385.5)">
            <path id="Path_605" data-name="Path 605" d="M19.375,2.5h-8.75A.59.59,0,0,0,10,3.125a.59.59,0,0,0,.625.625h8.75A.59.59,0,0,0,20,3.125.59.59,0,0,0,19.375,2.5Z" fill="#fff" />
            <path id="Path_606" data-name="Path 606" d="M.625,3.75h3.75V5.625A.59.59,0,0,0,5,6.25H8.125a.59.59,0,0,0,.625-.625v-5A.59.59,0,0,0,8.125,0H5a.59.59,0,0,0-.625.625V2.5H.625A.59.59,0,0,0,0,3.125.59.59,0,0,0,.625,3.75Z" fill="#fff" />
            <path id="Path_607" data-name="Path 607" d="M19.375,16.25h-8.75a.625.625,0,0,0,0,1.25h8.75a.625.625,0,0,0,0-1.25Z" fill="#fff" />
            <path id="Path_608" data-name="Path 608" d="M8.125,13.75H5a.59.59,0,0,0-.625.625V16.25H.625a.625.625,0,0,0,0,1.25h3.75v1.875A.59.59,0,0,0,5,20H8.125a.59.59,0,0,0,.625-.625v-5A.59.59,0,0,0,8.125,13.75Z" fill="#fff" />
            <path id="Path_609" data-name="Path 609" d="M19.375,9.375H17.5a.625.625,0,0,0,0,1.25h1.875a.625.625,0,0,0,0-1.25Z" fill="#fff" />
            <path id="Path_610" data-name="Path 610" d="M.625,10.625H11.25V12.5a.59.59,0,0,0,.625.625H15a.59.59,0,0,0,.625-.625v-5A.59.59,0,0,0,15,6.875H11.875a.59.59,0,0,0-.625.625V9.375H.625a.625.625,0,0,0,0,1.25Z" fill="#fff" />
        </g>
    </g>
</svg>;

const CampaignSearchMobileFilters = ({
    filtersConfig,
    campaignSearchUrl,
    onSearch,
    filterOptions,
    setFilterOptions,
    endOfStickyRef
}) => {
    const [radiobuttonValue, setRadiobuttonValue] = useState('');
    const [isModalOpened, setIsModalOpenedValue] = useState(false);
    const [isReachedTop, setIsReachedTop] = useState(false);
    const [isReachedBottom, setIsReachedBottom] = useState(false);
    const [ghostHeight, setGhostHeight] = useState(0);
    const stickyRef = React.createRef();

    const setIsModalOpened = (isOpen) => {
        setIsModalOpenedValue(isOpen);
        document.getElementsByTagName('body')[0].style.overflow = isOpen ? 'hidden' : 'unset';
    };

    const contextValue = {
        isModalOpened,
        setIsModalOpened,
        filterOptions,
        setFilterOptions,
        radiobuttonValue,
        setRadiobuttonValue
    };

    const sortingConfig = filtersConfig.find((config) => config.name === 'sort');

    useEffect(() => {
        const getTopOffset = (element) => {
            const rect = element.getBoundingClientRect();
            return rect.top + window.scrollY;
        };

        const getBottomOffset = (element, heightElement) => {
            const rect = element.getBoundingClientRect();
            return rect.bottom + window.scrollY - (heightElement.clientHeight * 2);
        };

        const stickyElTopOffset = getTopOffset(stickyRef.current);
        const stickyElBottomOffset = getBottomOffset(endOfStickyRef.current, stickyRef.current);
        setGhostHeight(stickyRef.current.clientHeight);

        const handleScroll = () => {
            const isReachedTopOfElement = stickyElTopOffset < window.pageYOffset;
            setIsReachedTop(isReachedTopOfElement);

            const isReachedBottomOfElement = stickyElBottomOffset < window.pageYOffset;
            setIsReachedBottom(isReachedBottomOfElement);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            // If the screen is now larger than modal display, then hide the modal
            if (isModalOpened && window.matchMedia("screen and (min-width: 768px)").matches) {
                setIsModalOpened(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    return (
        <>
            {/* Ghost ensures that the page doesn't jump when the _actual_ filter is pulled out of the DOM */}
            {isReachedTop && !isReachedBottom &&
                <div
                    className='campaign-search-filters__mobile-ghost'
                    style={{
                        height: ghostHeight + "px"
                    }} />
            }

            <div className={cn(
                'campaign-search-filters__mobile-filters',
                { 'is-panel-sticky': isReachedTop && !isReachedBottom }
            )} ref={stickyRef}>
                <CampaignMobileFilterContext.Provider value={contextValue}>
                    <div className="campaign-mobile-filters">
                        <CampaignSearchMobileSorting sortingConfig={sortingConfig} campaignSearchUrl={campaignSearchUrl} />
                        <button
                            className={cn({
                                'campaign-mobile-filters__main-control': true,
                            })}
                            aria-haspopup="listbox"
                            aria-expanded={isModalOpened}
                            onClick={() => setIsModalOpened(true)}
                        >
                            <div className='campaign-mobile-filters__main-control--left-side'>
                                {filterSvg}
                                <div className="campaign-mobile-filters__control-label">Filters</div>
                            </div>
                            <div className='campaign-mobile-filters__main-control--right-side'>
                                <svg
                                    width="13"
                                    height="8"
                                    className="icon campaign-search-filters__control-arrow"
                                    focusable="false"
                                    role="img"
                                    aria-hidden="true"
                                >
                                    <use xlinkHref="#icon-arrow-down" />
                                </svg>
                            </div>
                        </button>
                    </div>

                    <MobileFilterModal
                        filtersConfig={filtersConfig}
                        isOpened={isModalOpened}
                        onSearch={onSearch}
                        campaignSearchUrl={campaignSearchUrl}
                        initialPayloadState={initialPayloadState}
                    />
                </CampaignMobileFilterContext.Provider>
            </div>
        </>
    );
};

CampaignSearchMobileFilters.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
    filterOptions: PropTypes.shape({
        orderBy: PropTypes.string,
        channel: PropTypes.string,
        searchFilters: PropTypes.shape({
            availability: PropTypes.array,
            maxPrice: PropTypes.string,
            maxRent: PropTypes.string,
			maxOpsoRent: PropTypes.string,
            numberOfBedrooms: PropTypes.array,
            ownershipType: PropTypes.string,
            propertyTypes: PropTypes.array,
            searchRadius: PropTypes.string,
            sort: PropTypes.string
        }),
    }),
    setFilterOptions: PropTypes.func,
    endOfStickyRef: PropTypes.elementType
};

export default CampaignSearchMobileFilters;
