import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Scroller from 'general/js/scroller';
import ArticleCard from './article-card';
import ArticlePagination from './article-pagination';

const API_ENDPOINT = '/webapi/articles/getpaginated/';

const LoadingIndicator = (props) => (
    <div className="article-list__loading-indicator-wrapper">
        <div className="article-list__loading-indicator-content">Please wait, results are loading...</div>
    </div>
);

const ArticleList = (props) => {
    const [error, setError] = useState(false);
    const [model, setModel] = useState(props.model);
    const [isLoading, setIsLoading] = useState(false);
    const scrollRef = React.createRef();

    const _getScroller = (element) => {
        const scroller = new Scroller(element, {
            duration: 400,
            scrollOffset: +100
        });

        return scroller;
    };

    const _scrollToAnchor = (element) => {
        if (element) {
            const scroller = _getScroller(element);
            return scroller.scrollToTop(false);
        }
    };

    const onPaginate = (page) => {
        setIsLoading(true);
        _scrollToAnchor(scrollRef.current);

        axios({
            method: 'get',
            url: `${API_ENDPOINT}?datasourceguid=${props.model.datasourceGuid}&currentPageIndex=${page}&limit=${props.model.limit}`,
        }).then((res) => {
            setIsLoading(false);

            if (!res.data) {
                console.log('Failed to get data');
                setError(true);
                return;
            }

            setModel(res.data);
        }).catch(() => {
            setError(true);
            console.error('Failed to get data');
        });
    };

    return (
        <div className='article-list'>
            <div className="post-preview-container" ref={scrollRef}>
                {isLoading && <LoadingIndicator />}
                {model.articles.map((article) =>
                    <ArticleCard item={article} key={article.url} />)}

            </div>
            {model.addPagination &&
            <ArticlePagination
                currentPage={model.currentPageIndex}
                totalPages={model.totalPagesCount}
                onPaginate={onPaginate}
            />}
        </div>
    );
};

ArticleList.propTypes = {
    model: PropTypes.shape({
        articles: PropTypes.array.isRequired,
        contextPageGuid: PropTypes.string,
        currentPageIndex: PropTypes.number,
        datasourceGuid: PropTypes.string,
        limit: PropTypes.number,
        totalPagesCount: PropTypes.number
    })
};

export default ArticleList;
