import React from 'react';
import PropTypes from 'prop-types';
import api from 'general/js/api';
import SequentialRequestsProxy from '@General/js/api/sequential-requests-proxy.js';
import device, { DEVICE_TYPE_TABLET } from '@General/js/device.js';
import { devSearchTypeItem } from '@Components/developments-search-v2/js/dev-search-types.js';
import { withContext } from '@Components/developments-search-v2/js/context.jsx';
import {
    FILTER_SORT_PRICE_HIGH_LOW,
    FILTER_SORT_PRICE_LOW_HIGH,
} from '@Components/listing-v2/js/constants.js';
import DevPropertyTable from './components/dev-property-table.jsx';
import constants from './constants.js';
import { PropertyTableContext } from './context.jsx';

class DevPropertyTableDataContainer extends React.Component {
    constructor(props) {
        super(props);

        this.searchUrl = `${this.props.propertyTableSearchUrl}?developmentId=${this.props.item.development.developmentId}`;
        this.api = new SequentialRequestsProxy(api);

        this.state = this.getInitialState();
    }

    componentDidMount() {
        this.deviceChangeUnsubscribe = device.subscribeOnDeviceTypeChange(
            this.updateViewportStatus,
            false
        );
    }

    componentWillUnmount() {
        this.deviceChangeUnsubscribe();
    }

    updateViewportStatus = () => {
        this.setState({ isOnMobileViewport: !device.isViewportTypeGe(DEVICE_TYPE_TABLET) });
    };

    getInitialState = () =>
        constants.TABS.reduceRight(
            (acc, elem) => {
                if (this.props.item[elem.plotsId].length > 0) {
                    acc.tabs.unshift({ ...elem });
                    acc.activeTab = { ...elem };
                    acc.currentProperties = this.props.item[elem.plotsId].slice(
                        0,
                        constants.MAX_PROPS_ON_PAGE
                    );
                }

                return acc;
            },
            {
                tabs: [],
                activeTab: {},
                currentProperties: [],
                isGettingData: false,
                isTextShowing: false,
                message: constants.ERROR_MESSAGE,
                isOnMobileViewport: !device.isViewportTypeGe(DEVICE_TYPE_TABLET),
                ...this.getDefaultNavState(),
            }
        );

    getDefaultNavState = () => {
        const isSortByPrice =
            this.props.appliedFilters.sort === FILTER_SORT_PRICE_LOW_HIGH ||
            this.props.appliedFilters.sort === FILTER_SORT_PRICE_HIGH_LOW;

        const isDesc = this.props.appliedFilters.sort === FILTER_SORT_PRICE_HIGH_LOW;

        return {
            activeHead: isSortByPrice ? constants.SORT_ACTIVE_HEAD : constants.DEFAULT_ACTIVE_HEAD,
            activeSortType: isDesc ? constants.DESC_SORT_INDEX : constants.DEFAULT_ACTIVE_SORT,
            currentDisplayedPages: [1],
            pagination: {
                offset: 0,
                totalCount: this.getDefaultTotalCount(),
            },
        };
    };

    getDefaultTotalCount = () => {
        if (this.props.item.plotForSaleTotalCount > 0) {
            return this.props.item.plotForSaleTotalCount;
        }

        if (this.props.item.plotToRentTotalCount > 0) {
            return this.props.item.plotToRentTotalCount;
        }

        return 0;
    };

    changeActiveTab = (tab) => {
        const partialState = {
            activeTab: tab,
        };
        const data = {
            pagination: {
                offset: 0,
                totalCount: this.props.item[tab.totalCount],
            },
            properties: this.props.item[tab.plotsId],
        };
        this.setState(() => ({
            ...this.getSetNewDataAction(partialState, data),
            ...this.showPropertiesAction,
        }));
    };

    changeActiveSort = (headIndex, sortIndex) => {
        const partialState = {
            activeHead: headIndex,
            activeSortType: sortIndex,
            activeTab: this.state.activeTab,
        };
        const params = {
            offset: 0,
            channel: this.state.activeTab.id,
            orderBy:
                constants.HEADINGS[partialState.activeHead].id +
                constants.SORT_TYPES[partialState.activeSortType].id,
        };
        this.getData(params, partialState);
    };

    changeActivePage = (offset, currentDisplayedPages) => {
        const partialState = {
            pagination: {
                ...this.state.pagination,
                offset,
            },
            currentDisplayedPages,
            activeHead: this.state.activeHead,
            activeSortType: this.state.activeSortType,
            activeTab: this.state.activeTab,
        };
        const params = {
            offset: partialState.pagination.offset,
            channel: this.state.activeTab.id,
            orderBy:
                constants.HEADINGS[partialState.activeHead].id +
                constants.SORT_TYPES[partialState.activeSortType].id,
        };
        this.getData(params, partialState);
    };

    isTabWithProperties = (props) => {
        return props && Array.isArray(props) && props.length && props.length !== 0;
    };

    getData = (params, partialState) => {
        this.setState(
            {
                ...this.showGettingDataAction,
                ...partialState,
            },
            () => {
                api.post(this.searchUrl, {
                    ...params,
                    searchFilters: this.props.appliedFilters,
                })
                    .then((result) => {
                        if (this.isTabWithProperties(result.data.properties)) {
                            this.setState(() => ({
                                ...this.getSetNewDataAction(partialState, result.data),
                                ...this.showPropertiesAction,
                            }));
                        } else {
                            this.setState(() => ({
                                ...this.getSetNewDataAction(partialState, result.data),
                                ...this.showMessageWithNoPropsAction,
                            }));
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            ...this.getDefaultNavState(),
                            ...this.showServerErrorAction,
                        });
                        console.error(error);
                    });
            }
        );
    };

    getSetNewDataAction = (partialState, data) => ({
        ...this.getDefaultNavState(),
        ...partialState,
        pagination: {
            ...data.pagination,
        },
        currentProperties: [...data.properties],
    });

    showGettingDataAction = {
        isGettingData: true,
    };

    showPropertiesAction = {
        isTextShowing: false,
        isGettingData: false,
    };

    showServerErrorAction = {
        isTextShowing: true,
        isGettingData: false,
        message: constants.ERROR_MESSAGE,
    };

    showMessageWithNoPropsAction = {
        isTextShowing: true,
        isGettingData: false,
        message: constants.NO_PROPERTIES_MESSAGE,
    };

    getContextValue = () => {
        return {
            ...this.state,
            ...this.props,
            tableSettings: this.props.tableSettings,
            onTabClick: this.changeActiveTab,
            onSortClick: this.changeActiveSort,
            onPageClick: this.changeActivePage,
        };
    };

    render() {
        return (
            <PropertyTableContext.Provider
                value={{
                    ...this.getContextValue(),
                }}
            >
                <DevPropertyTable item={this.props.item}/>
            </PropertyTableContext.Provider>
        );
    }
}

DevPropertyTableDataContainer.propTypes = {
    appliedFilters: PropTypes.object,
    propertyTableSearchUrl: PropTypes.string,
    item: devSearchTypeItem,
    tableSettings: PropTypes.object,
};

export default withContext(DevPropertyTableDataContainer, {
    appliedFilters: 'appliedFilters',
    propertyTableSearchUrl: 'propertyTableSearchUrl',
    tableSettings: 'tableSettings',
});
