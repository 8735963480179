const SUNRISE_COLOR = '#f6a200';

export const AFTERLINE_PARAMS = {
    HEIGHT: {
        EXPANDED: '30px',
        NOT_EXPANDED: '13px',
    },
    MARGIN: {
        EXPANDED: '-30px 13px',
        NOT_EXPANDED: '-13px 13px',
    }
};

export const AFTERLINE_COLORS = {
    FINISHED: SUNRISE_COLOR,
    PROGRESS: '#000000',
};
