import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';

export default class NotificationsComponent extends DcBaseComponent {

    static getNamespace() {
        return 'notifications';
    }

    onInit() {
        ReactDOM.render(
            (
                <ToastContainer/>
            ), this.element
        );
    }
}
