import objectFitVideos from 'object-fit-videos';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';

export default class CoverVideoComponent extends DcBaseComponent {

    static getNamespace() {
        return 'cover-video';
    }

    onInit() {
        this._initPolyfill();
    }

    _initPolyfill() {
        objectFitVideos(this.element);
    }
}
