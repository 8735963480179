import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {
    POINT_SIZE,
    CHART_MODE,
    DEFAULT_SVG_TEXT_RATIO,
    DEFAULT_SERVICE_COLOR,
    TOOLTIP_ID,
} from '../constants.js';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import { formatForView, applyMode } from '../pure-functions.js';

export default function BudgetPiechart({ budget, sectors, point }) {
    const wrapperRef = useRef(null);
    const priceRef = useRef(null);
    const pointRef = useRef(null);
    const [ratio, setRatio] = useState(1);
    const [pointCoords, setPointCoords] = useState([0, 0]);
    const [state, dispatch] = useBudgetCalculatorStore();

    const onSectorClick = (e, sector) => {
        const wrapperCoords = wrapperRef.current.getBoundingClientRect();
        const wrapperX = wrapperCoords.x || wrapperCoords.left;
        const wrapperY = wrapperCoords.y || wrapperCoords.top;
        const left = e.clientX - wrapperX - POINT_SIZE;
        const top = e.clientY - wrapperY - POINT_SIZE;
        setPointCoords([left, top]);

        dispatch({
            type: 'SHOW_TOOLTIP',
            mode: CHART_MODE,
            text: [formatForView(applyMode(state.priceMode, sector.currentPrice)), sector.title],
            tooltipReference: pointRef.current,
            tooltipColor: sector.colorHex,
        });
    };

    const scaleText = () => {
        const width = wrapperRef.current.getBoundingClientRect().width * DEFAULT_SVG_TEXT_RATIO;
        const currentWidth = priceRef.current.offsetWidth;
        const currentRatio = width / currentWidth;
        setRatio(currentRatio);
    };

    useEffect(() => {
        const onResize = debounce(() => {
            scaleText();
        }, 500);

        window.addEventListener('resize', onResize);
        onResize();

        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        scaleText();
    }, [state.priceMode, budget]);

    const backgroundCircleColor = DEFAULT_SERVICE_COLOR;

    return (
        <div className="budget-piechart" ref={wrapperRef}>
            <svg
                viewBox="0 0 280 280"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="140"
                    cy="140"
                    r="72.5"
                    fill="transparent"
                    stroke={backgroundCircleColor}
                    strokeWidth="110"
                    transform="rotate(-90) translate(-280)"
                />
                {sectors.map((sector, i) => (
                    <circle
                        key={i}
                        cx="140"
                        cy="140"
                        r="72.5"
                        fill="transparent"
                        stroke={sector.colorHex}
                        strokeWidth="110"
                        strokeDasharray={`${sector.percent * 4.6} 460`}
                        strokeDashoffset={`${
                            (100 + sector.percent - sector.prevPercentage) * 4.6
                        }`}
                        transform="rotate(-90) translate(-280)"
                        onClick={(e) => onSectorClick(e, sector, i)}
                        aria-describedby={TOOLTIP_ID}
                    />
                ))}
                <circle cx="140" cy="140" r="72.5" fill="#FFFFFF" />
            </svg>
            <div
                className="budget-piechart__price-wrapper"
                ref={priceRef}
                style={{
                    transform: `translate(-50%, -50%) scale(${ratio})`,
                }}
            >
                <div className="budget-piechart__svg-price">{formatForView(budget)}</div>
                <div className="budget-piechart__svg-tag">{`per ${state.priceMode}`}</div>
            </div>
            <div
                className={classNames({
                    'budget-piechart__point': true,
                    'budget-piechart__point--shown':
                        state.isTooltipShown && point && state.tooltipMode === CHART_MODE,
                })}
                style={{
                    left: pointCoords[0],
                    top: pointCoords[1],
                }}
                ref={pointRef}
            ></div>
        </div>
    );
}

BudgetPiechart.propTypes = {
    budget: PropTypes.number,
    sectors: PropTypes.arrayOf(
        PropTypes.shape({
            colorHex: PropTypes.string,
            currentPrice: PropTypes.number,
            defaultPrice: PropTypes.number,
            hasTooltip: PropTypes.bool,
            icon: PropTypes.string,
            isIncluded: PropTypes.bool,
            maxPrice: PropTypes.number,
            minPrice: PropTypes.number,
            price: PropTypes.number,
            percent: PropTypes.number,
            prevPercentage: PropTypes.number,
            title: PropTypes.string,
            tip: PropTypes.string,
            tooltip: PropTypes.shape({
                icon: PropTypes.string,
                text: PropTypes.string,
            }),
        })
    ),
    point: PropTypes.bool,
};
