import DcBaseComponent from "../../../general/js/dc/dc-base-component";
import VideoCreator from '../../../general/js/video/creator';

export default class VideoThumbnailImage extends DcBaseComponent {
    constructor(element) {
        super(element);
    }

    static getNamespace() {
        return 'video-thumbnail-image';
    }

    static getRequiredRefs() {
        return ['preview'];
    }

    onInit() {
      this.video = VideoCreator.createFromOptions(this.options);

      this.loadThumbnail();
    }

    loadThumbnail() {
        const thumbnailUrlPromise = this.video.getThumbnailUrl();
        if (thumbnailUrlPromise) {
            thumbnailUrlPromise.then(thumbnailUrl => {
                if (thumbnailUrl) {
                    const img = document.createElement('img');
                    img.setAttribute('data-src', thumbnailUrl);
                    img.className = 'lazyload';
                    img.alt = this.options.title ?? 'YouTube video preview';

                    const onImageLoad = this.video.getThumbnailOnLoadFunction();
                    if (onImageLoad) {
                        img.onload = onImageLoad;
                    }

                    this.refs.preview.insertAdjacentElement('beforeend', img);
                }
            })
        }
    }
}
