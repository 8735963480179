import app from '../../../general/js/app';

let isLoaded = false;
let isLoading = false;
const onloadQueue = [];

window.onGoogleMapsIframeAPIReady = function () {
    isLoaded = true;
    onloadQueue.forEach(callback => callback());
};

function insertScript() {
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${app.getRequiredConfig(
        'GOOGLE_MAPS_API_KEY'
    )}&callback=onGoogleMapsIframeAPIReady`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
}

function onReady(callback) {
    if (!isLoading) {
        insertScript();
        isLoading = true;
    }
    if (isLoaded) {
        callback();
    } else {
        onloadQueue.push(callback);
    }
}

export default onReady;
