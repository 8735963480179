import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const CampaignSearchDesktopBadge = ({
    optionValue,
    text,
    isDisabled,
    sortData,
    setActiveItem,
    activeItem,
    filterOptions
}) => {
    const handleInputChange = (target) => {
        setActiveItem(target.value);
        sortData(target.value);
    };

    const isChecked = filterOptions.sort === optionValue;

    return (
        <li className={cn('campaign-search-desktop-sorting__badge', {
            'campaign-search-desktop-sorting__badge--active': isChecked,
            'campaign-search-desktop-sorting__badge--disabled': isDisabled,
        })}>
            <input
                className='campaign-search-desktop-sorting__input'
                type="radio"
                name="desktop-sort"
                id={`${text}-desktop`}
                disabled={isDisabled}
                checked={isChecked}
                onChange={({ target }) => handleInputChange(target)}
                value={optionValue} />
            <label className='campaign-search-desktop-sorting__label' htmlFor={`${text}-desktop`}>{text}</label>
        </li>
    );
};

CampaignSearchDesktopBadge.propTypes = {
    optionValue: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    activeItem: PropTypes.string,
    setActiveItem: PropTypes.func,
    sortData: PropTypes.func,
    filterOptions: PropTypes.object
};

export default CampaignSearchDesktopBadge;
