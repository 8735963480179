import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import BudgetLoader from './budget-loader';

export default class BudgetCalculatorComponent extends DcBaseComponent {
    static getNamespace() {
        return 'budget-calculator';
    }

    onInit() {
        this.renderTable();
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    renderTable = () => {
        ReactDOM.render(<BudgetLoader options={this.options} />, this.element);
    };
}
