import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import RecentlyViewedArticles from './recently-viewed-articles';

export default class RecentlyViewedArticlesComponent extends DcBaseComponent {
    static getNamespace() {
        return 'recently-viewed-articles';
    }

    onInit() {
        ReactDOM.render(<RecentlyViewedArticles {...this.options} />, this.element);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
