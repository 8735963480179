import React from 'react';
import { devSearchTypeDevelopment } from '@Components/developments-search-v2/js/dev-search-types.js';
import { withContext } from '@Components/developments-search-v2/js/context.jsx';
import DevSectionIcon from './dev-section-icon.jsx';

function DevSectionIcons(props) {
    return (
        <>
            <div className="dev-section-icons">
                {props.item.images && props.item.images.totalCount && (
                    <DevSectionIcon
                        className="dev-section-icons__item dev-section-icons__item--baseline link"
                        href={props.item.buttonUrl}
                        tooltipText={props.viewGalleryText}
                    >
                        <span className="dev-section-icons__item__label">{props.viewGalleryText}</span>
                        <svg
                            className="dev-section-icons__icon icon"
                            width="15"
                            height="13"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                            data-dc-accordion-ref="icon"
                        >
                            <use xlinkHref="#icon-photocam"></use>
                        </svg>
                        &nbsp;1/{props.item.images.totalCount}
                    </DevSectionIcon>
                )}
                {(props.item.sitePlanUrl && (
                    <DevSectionIcon
                        className="dev-section-icons__item link"
                        href={props.item.sitePlanUrl}
                        tooltipText={props.viewPropertiesText}
                    >
                        <span className="dev-section-icons__item__label">{props.viewPropertiesText}</span>
                        <svg
                            className="dev-section-icons__icon icon"
                            width="13"
                            height="13"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                            data-dc-accordion-ref="icon"
                        >
                            <use xlinkHref="#icon-floorplan"></use>
                        </svg>
                    </DevSectionIcon>
                )) ||
                    null}
                {(props.item.virtualTourUrl && (
                    <DevSectionIcon
                        className="dev-section-icons__item link"
                        href={props.item.virtualTourUrl}
                        tooltipText={props.viewVirtualTourText}
                    >
                        <span className="dev-section-icons__item__label">{props.viewVirtualTourText}</span>
                        <svg
                            className="dev-section-icons__icon icon"
                            width="20"
                            height="13"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                            data-dc-accordion-ref="icon"
                        >
                            <use xlinkHref="#icon-camera"></use>
                        </svg>
                    </DevSectionIcon>
                )) ||
                    null}
            </div>
        </>
    );
}

DevSectionIcons.propTypes = {
    item: devSearchTypeDevelopment,
};

export default withContext(DevSectionIcons, {
    viewGalleryText: 'viewGalleryText',
    viewPropertiesText: 'viewPropertiesText',
    viewVirtualTourText: 'viewVirtualTourText',
});
