import contentBlocker from './content-blocker';
import overlay from './overlay/js/modal-overlay';
import overlayCookie from './overlay/js/cookie-overlay';
import device from './device';
import eventBus from './event-bus';
import { CLOSE_MODAL_EVENT } from '../../components/cookie-settings/js/cookie-settings.component';

class ModalService {
    constructor(options = {}) {
        this.openedModal = null;
        this.overlay = options.useCookieOverlay ? overlayCookie : overlay;
        this.isOverlayClickable = !options.useCookieOverlay;
        this._setOuterHandler();
    }

    _setOuterHandler() {
        if (this.isOverlayClickable) {
            if (device.isTouch) {
                document.addEventListener('touchstart', this._outerClickHandle.bind(this));
            } else {
                document.addEventListener('click', this._outerClickHandle.bind(this));
            }
        }

        eventBus.addListener(CLOSE_MODAL_EVENT, this.closeOpened);
    }

    _outerClickHandle(e) {
        if (this.openedModal) {
            if (this.openedModal.element.contains(e.target)) {
                if (!this.openedModal.getWrapperElement().contains(e.target)) {
                    this.closeOpened();
                }
            }
        }
    }

    close = (modal) => {
        if (this.openedModal && this.openedModal === modal) {
            modal.onClose().then(() => {
                this._afterClose();
            });
            this.openedModal = null;
        }
    };

    swap(modal, swapModal) {
        if (this.openedModal && this.openedModal === modal) {
            modal.onClose();
            swapModal.onOpen();
            this.openedModal = swapModal;
        }
    }

    open(modal) {
        this._beforeOpen();

        if (this.openedModal) {
            if (this.openedModal !== modal) {
                this.swap(this.openedModal, modal);
            }
            return;
        }

        modal.onOpen();
        this.openedModal = modal;
    }

    _beforeOpen() {
        contentBlocker.block('modal');
        this.overlay.show('modal');
    }

    _afterOpen() {}

    _beforeClose() {}

    _afterClose() {
        contentBlocker.unblock('modal');
        this.overlay.hide('modal');
    }

    closeOpened = () => {
        if (this.openedModal) {
            this.openedModal.close();
        }
    };
}

const instance = new ModalService();
export default instance;
export { ModalService as CustomModalService };
