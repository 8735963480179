import $ from 'jquery';
import Parsley from 'parsleyjs';
import lodashGet from 'lodash/get';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import utils from 'general/js/utils';
import api from 'general/js/api';
import pageSpinner from 'general/js/page-spinner';
import analyticsService from 'general/js/analytics-service';
import createEvent from 'general/js/create-event';
import constants from 'general/js/constants';
import Deferred from 'general/js/deferred';
import loquateService from 'general/js/loquate-service';
import HtmlHelper from 'general/js/html-helper';
import { TRACKING_LVL_COOKIE_NAME, getCookie, COOKIES_TYPE } from 'general/js/cookies.js';
import formsConstants from './constants';
import errorsSummaryTemplate from '../html/errors-summary.hbs';
import { onReady } from './load-recaptcha';

const ENCTYPE_URLENCODED = 'application/x-www-form-urlencoded';
const ENCTYPE_MULTIPART = 'multipart/form-data';

const FORM_CONTROL_ATTRIBUTE = 'data-form-control';

export const PARSLEY_SERVER_CONSTRAINT_NAME = 'server';
const PARSLEY_SERVER_ERROR_KEY = 'serverErrorValue';

const RESPONSE_TYPE_VALIDATION_ERRORS = 'validationErrors';
const RESPONSE_TYPE_REDIRECT = 'redirect';

export default class FormComponent extends DcBaseComponent {
    constructor(el) {
        super(el);
        if (this.element.nodeName !== 'FORM') {
            throw new Error('element must be a form');
        }

        this.successUrl = this.options.successUrl;
        if (!this.successUrl) {
            throw new Error('success url must be set');
        }

        this.formType = this.element.getAttribute('data-form-type') || '';

        this.action = this.element.getAttribute('data-form-action') || this.element.action;
        this.enctype = this.element.enctype || ENCTYPE_URLENCODED;

        this.withRecatcha = lodashGet(this.options, 'withRecatcha', true);
        this.recatchaSitekey = lodashGet(this.options, 'recaptchaSitekey');

        this.isSubmiting = false;
        this.formControlSelector = `[${FORM_CONTROL_ATTRIBUTE}]`;
        // reset form in case of success submit
        this.resetOnSuccess =
            'resetOnSuccess' in this.element.dataset ?
                this.element.dataset.resetOnSuccess !== 'false' :
                false;
        this.errorsSummary = this.refs.errorsSummary;
        this.submitElement = this.refs.submit;

        this._parsleyForm = null;
        this._fieldsRequiredStateObserver = null;
        this.isFormTrackingSent = false;
		
		// Setup object for Mouseflow
		window._mfq = window._mfq || [];
    }

    static getNamespace() {
        return 'form';
    }

    onInit() {
        if (this._isAsync()) {
            this.getParsleyForm().on('form:submit', this.onSubmit);
        }

        this.getParsleyForm().on('field:error', this.onFieldError);
        this.getParsleyForm().on('form:error', this.onFormError);

        this.getParsleyForm().fields.forEach((field) => {
            if (field.element.getAttribute('data-dc-address-fieldset-ref') === 'searchInput') {
                return;
            }
            this._setFieldRequiredState(field.element, true);
            this.getFieldsRequiredStateObserver().observe(field.element, {
                attributes: true,
                attributeFilter: ['data-parsley-required', 'data-parsley-mincheck', 'data-parsley-pattern-message'],
            });
        });

        this._checkServerErrors();
        this._initRecaptcha();
    }

    _setFieldRequiredState(field, initial = false) {
        const isFieldRequired =
            (field.dataset.parsleyRequired || '').toLowerCase() === 'true' ||
            Number.parseInt(field.dataset.parsleyMincheck || '0') > 0;
        const isFieldSelectElement = field.tagName === 'SELECT';

        if (isFieldSelectElement) {
            this._setSelectRequiredState(field, isFieldRequired);
        } else {
            this._setInputRequiredState(field, isFieldRequired);
        }

        if (initial) {
            return;
        }

        const target = this.getParsleyForm().fields.find(({ element }) => element.getAttribute('id') === field.getAttribute('id'));
        target.validate();

        if (field.value) {
            target.validate();
        } else {
            target.reset();
        }
    }

    _setInputRequiredState(field, isRequired) {
        if (isRequired) {
            if (!field.placeholder.includes('*')) {
                field.placeholder += '*';
            }
        } else {
            field.placeholder = (field.placeholder || '').replace('*', '');
        }
    }

    _setSelectRequiredState(select, isRequired) {
        const option = select.querySelector('option[disabled]');
        if (option && isRequired) {
            if (!option.textContent.includes('*')) {
                option.textContent += '*';
            }
        } else if (option) {
            option.textContent = option.textContent.replace('*', '');
        }
    }

    _setCookieDownloadLink() {
        if (this.options.url && typeof this.options.url === 'string') {
            const date = new Date();
            date.setDate(date.getDate() + 30);
            const expires = ';expires=' + date.toGMTString() + ';path=/;';
            document.cookie = 'pdfDownload=' + this.options.url + expires;
        }
    }

    _initRecaptcha() {
        if (this.withRecatcha && this.recatchaSitekey) {
            this._captchaPromise = new Promise((resolve) => {
                const recaptchaPlaceholder = document.createElement('div');
                this.element.insertAdjacentElement('beforeEnd', recaptchaPlaceholder);
                onReady((grecaptcha) => {
                    const captchaId = grecaptcha.enterprise.render(recaptchaPlaceholder, {
                        sitekey: this.recatchaSitekey,
                        action: 'custom_forms',
                        callback: () => {
                            this.captchaIsReady.resolve();
                        },
                        'error-callback': (error) => {
                            this.captchaIsReady.reject();
                        },
                        'expired-callback': (error) => {
                            this.captchaIsReady.reject();
                        },
                        size: 'invisible',
                    });
                    // Fix accessibility issue caused by google recaptcha not having a label
                    const recaptchaTextarea = document.getElementById('g-recaptcha-response');
                    recaptchaTextarea.setAttribute('aria-hidden', true);
                    recaptchaTextarea.setAttribute('aria-label', 'do not use');
                    recaptchaTextarea.setAttribute('aria-readonly', true);

                    resolve([grecaptcha, captchaId]);
                });
            });
        } else {
            this._captchaPromise = Promise.resolve([]);
        }
    }

    _checkServerErrors() {
        if (this.options.serverErrors) {
            this._renderServerErrors(this.options.serverErrors);
        }
    }

    _getCaptcha() {
        return this._captchaPromise;
    }

    _applyRecaptcha() {
        return this._getCaptcha().then(([grecaptcha, captchaId]) => {
            if (captchaId !== 'undefined') {
                this.captchaIsReady = new Deferred();
                grecaptcha.enterprise.execute(captchaId);
                return this.captchaIsReady.promise;
            }
        });
    }

    _isValidationError({ data }) {
        return (
            typeof data === 'object' &&
            data.type === RESPONSE_TYPE_VALIDATION_ERRORS &&
            'errors' in data
        );
    }

    _getValidationErrors({ data }) {
        return data.errors;
    }

    getParsleyForm() {
        if (this._parsleyForm === null) {
            this._parsleyForm = new Parsley.Factory(this.element, {
                inputs: formsConstants.PARSLEY_INCLUDE_SELECTOR,
                excluded: (index, element) => {
                    const excludedDefault = Parsley.options.excluded;
                    const defaultExclude = element.matches(excludedDefault);
                    if (defaultExclude) {
                        return true;
                    }

                    const validateAlwaysParent = HtmlHelper.getParent(
                        element,
                        `[${constants.ATTRIBUTE_VALIDATE_ALWAYS}]`
                    );
                    return validateAlwaysParent ? false : $(element).is(':hidden');
                },
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                classHandler: (field) => field.$element.closest(this.formControlSelector),
                errorsContainer: (field) =>
                    field.$element.closest('.form-control').find('.form-control__header'),
                errorsWrapper: '<ul class="form-control__errors" aria-live="assertive"></ul>',
                errorTemplate: '<li class="form-control__error-item"></li>',
                trigger: 'focusout',
            });
        }
        return this._parsleyForm;
    }

    /**
     * Since a form field is marked as required using the data-parsley-required
     * attribute set to "true", we observe the change in the required state by
     * observing the existence of an attribute, leveraging MutationObserver API.
     */
    getFieldsRequiredStateObserver() {
        if (this._fieldsRequiredStateObserver === null) {
            this._fieldsRequiredStateObserver = new MutationObserver(
                (mutationRecord, mutationObserver) => {
                    mutationRecord.forEach((mutation) => {
                        if (mutation.type === 'attributes') {
                            this._setFieldRequiredState(mutation.target);
                        }
                    });
                }
            );
        }

        return this._fieldsRequiredStateObserver;
    }

    isMustBeTracked() {
        return this._getTrackCategory() !== null;
    }

    _getAnalyticsOptions() {
        return lodashGet(this.options, 'analytics', {});
    }

    _getTrackCategory() {
        return lodashGet(this._getAnalyticsOptions(), 'category', '');
    }

    _getTrackCategoryValue() {
        return this._getInterpolatedFormData(this._getTrackCategory());
    }

    _getTrackAction() {
        return lodashGet(this._getAnalyticsOptions(), 'action', '');
    }

    _getTrackActionValue() {
        return this._getInterpolatedFormData(this._getTrackAction());
    }

    _getTrackLabel() {
        return lodashGet(this._getAnalyticsOptions(), 'label', '');
    }

    _getTrackLabelValue() {
        return this._getInterpolatedFormData(this._getTrackLabel());
    }

    _getMapValuesToLabels() {
        return lodashGet(this._getAnalyticsOptions(), 'mapValuesToLabels', {});
    }

    /**
     * Is used when we want to send analytics data with some of the current inputs values
     * @param string
     * @return {String}
     * @private
     */
    _getInterpolatedFormData(string) {
        const map = this._getMapValuesToLabels();
        return utils.stringInterpolate(string, this._getFormDataObject(), (key, value) =>
            lodashGet(map, [key, value], value));
    }

    _isAsync() {
        return this.getChildAttribute(this.element, 'sync') !== 'true';
    }

    onSubmit = () => {
        this._applyRecaptcha().then(() => {
            if (!this.isSubmiting) {
                try {
                    this.beforeSubmit();
                    api.post(this._getUrl(), this._getRequestData()).then(
                        (response) => {
                            this.afterSubmit();
                            this.resetForm();
                            try {
                                this.sendFormTracking();
                            } catch (e) {
                                console.error('Form tracking failed', e);
                            }
                            this.handleSuccessSubmit(response);
                        },
                        (error) => {
                            this.afterSubmit();
                            this.handleErrorSubmit(error);
                        }
                    );
                } catch (err) {
                    console.error(err);
                }
            }
        });

        // prevent default form submit
        return false;
    };

    handleSuccessSubmit({ data }) {
        this._setCookieDownloadLink();

        const acceptedCookies = getCookie(TRACKING_LVL_COOKIE_NAME);
        if (acceptedCookies && acceptedCookies.includes(COOKIES_TYPE.marketing) && acceptedCookies.includes(COOKIES_TYPE.statistics)) {
            this.setGmtData(data);
        }
		
		// Tell Mouseflow the form has submitted
		window._mfq.push(['formSubmitSuccess', '#' + this.element.id]);

        switch (data.type) {
            case RESPONSE_TYPE_REDIRECT:
                this.handleRedirect(data.url || this.successUrl);
                break;
            default:
                throw new Error('unknown response type');
        }
    }

    handleErrorSubmit(error) {
        // first we have to proceed validation errors
        const response = error.response;
        if (this._isValidationError(response)) {
            const failedElements = [];
            this._renderServerErrors(this._getValidationErrors(response), failedElements);
        }
		
		// Tell Mouseflow the form has failed
		window._mfq.push(['formSubmitFailure', '#' + this.element.id]);
    }

    handleRedirect(url) {
        window.location.href = url;
    }

    _renderServerErrors(groupedErrors, failedElements = []) {
        let hasFieldErrors = false;

        this.getParsleyForm().fields.forEach((field) => {
            const element = field.$element[0];
            const fieldName = element.name ?
                element.name :
                element.getAttribute('data-dc-form-field-name');

            if (fieldName in groupedErrors) {
                hasFieldErrors = true;

                field.$element.attr(`data-parsley-${PARSLEY_SERVER_CONSTRAINT_NAME}`, 'true');

                const errors = groupedErrors[fieldName];
                delete groupedErrors[fieldName];

                // programmatically set constraint error text
                field.options.serverMessage = errors.join(', ');
                // refresh stored value
                delete field[PARSLEY_SERVER_ERROR_KEY];

                field.options.validateIfEmpty = true;

                failedElements.push(field.element);
            }
        });

        // validate to cause an error if we have field errors
        if (hasFieldErrors) {
            this.getParsleyForm().validate();
        }

        this.showErrorsSummary(groupedErrors);
    }

    showErrorsSummary(groupped) {
        if (this.errorsSummary.length > 0) {
            if (Object.keys(groupped).length) {
                const errors = Object.keys(groupped).reduce(
                    (errors, key) => [...errors, ...groupped[key]],
                    []
                );
                this.errorsSummary.forEach((summaryElement) => {
                    summaryElement.innerHTML = errorsSummaryTemplate({ errors });
                    summaryElement.classList.add('is-shown');
                });
            } else {
                this._hideErrorsSummary();
            }
        }
    }

    _hideErrorsSummary() {
        if (this.errorsSummary.length > 0) {
            this.errorsSummary.forEach((summaryElement) => {
                summaryElement.classList.remove('is-shown');
            });
        }
    }

    _getRequestData() {
        if (this.enctype === ENCTYPE_URLENCODED) {
            return this._getUrlencodedFormData();
        }
        return this._getFormData();
    }

    _getFormData() {
        return new FormData(this.element);
    }

    /**
     * @return {Array<[string, string]>}
     * @private
     */
    _getFormDataEntries() {
        return $(this.element)
            .serializeArray()
            .map(({ name, value }) => [name, value]);
    }

    _getUrlencodedFormData() {
        const parts = this._getFormDataEntries().map(
            ([name, value]) =>
                encodeURIComponent(name) + '=' + encodeURIComponent(value == null ? '' : value)
        );
        return parts.join('&');
    }

    _getFormDataObject() {
        const result = {};
        const entries = this._getFormDataEntries();
        entries.forEach(([key, value]) => {
            // add key to the entries only if there is no such key in there
            // this was done on purpose, basically, because of the way how MVC renders checkbox
            // because hidden (fallback) input goes second
            // we want to ignore it's value if the actual checkbox is checked
            if (!(key in result)) {
                result[key] = value;
            }
        });

        return result;
    }
	
	_getGmtFormDataObject() {
        const result = {};
        const entries = this._getFormDataEntries();
        entries.forEach(([key, value]) => {
            if (!(key in result)) {
                result[key] = value;
            } else {
				let valueArray = result[key];
                if (!Array.isArray(valueArray)) {
                    valueArray = [];
                    valueArray.push(result[key]);
                }
				valueArray.push(value);
                result[key] = valueArray;
            }
        });
		
        return result;
    }

    _getUrl() {
        return this.action;
    }

    sendFormTracking = () => {
        if (this.isMustBeTracked()) {
            if (!this.isFormTrackingSent) {
                this.isFormTrackingSent = true;
                analyticsService.sendEvent(
                    this._getTrackCategoryValue(),
                    this._getTrackActionValue(),
                    this._getTrackLabelValue()
                );
            }
        }
    };

    setGmtData(responseData) {
        try {
            const pathParts = window.location.pathname.split('/');
            const formFields = this._getGmtFormDataObject();
            let data = null;

            switch (this.formType) {
                case 'signUp': {
                    const firstName = formFields['NameFieldSet.FirstName'] || formFields['FirstName'] || '';
                    const lastName = formFields['NameFieldSet.LastName'] || formFields['LastName'] || '';

                    data = {
                        event: this.formType,
                        firstName: firstName.toLowerCase(),
                        lastName: lastName.toLowerCase(),
                        emailAddress: formFields['Email']?.toLowerCase(),
                        emailAddressHashed: responseData.emailHash
                    };

                    const brochureTypes = this.element.elements['updates[]'];
                    if (brochureTypes) {
                        data.brochureType = brochureTypes.value; // download/email/post
                    }

                    break;
                }
                case 'newsletterSignUp':
                case 'contactUs':
                    data = {
                        event: this.formType,
                        firstName: formFields['NameFieldSet.FirstName']?.toLowerCase(),
                        lastName: formFields['NameFieldSet.LastName']?.toLowerCase(),
                        emailAddress: formFields['Email']?.toLowerCase(),
                        emailAddressHashed: responseData.emailHash,
                    };
                    break;
                case 'unsubscribe':
                    data = { event: this.formType, };
                    break;
                default:
                    break;
            }

            if (!data) { return; }

            let formName = pathParts[pathParts.length - 1];
            if (!formName) {
                formName = pathParts[pathParts.length - 2];
            }
            data.formName = formName.replaceAll('-', ' ');
			
            data.requestOnSomeoneElseBehalf = formFields['WhoFor']?.includes('SomebodyElse') ?? formFields['ContactAndWhoForBaseFieldSet.WhoFor']?.includes('SomebodyElse') ?? formFields['ContactAndWhoForFullFieldSet.WhoFor']?.includes('SomebodyElse') ?? '';
			
			let optInTypes =  [];
			if (formFields['CommunicationPreferences.SelectedChannels[]']?.includes('OptInTelephone'))
				optInTypes.push('phone');
			if (formFields['CommunicationPreferences.SelectedChannels[]']?.includes('OptInEmail'))
				optInTypes.push('email');
			if (formFields['CommunicationPreferences.SelectedChannels[]']?.includes('OptInPost'))
				optInTypes.push('post');
			
			data.marketingOptInType = optInTypes.join(',');
			data.marketingOptIn = optInTypes.length > 0;
			
            window.dataLayer.push(data);
        } catch (error) {
            console.error(error);
        }
    }

    afterSubmit() {
        pageSpinner.hide();
        this.resetCaptcha();
        if (this.submitElement) {
            this.submitElement.disabled = false;
        }
        this.isSubmiting = false;
    }

    resetCaptcha() {
        this._getCaptcha().then(([grecaptcha, captchaId]) => {
            if (typeof captchaId !== 'undefined') {
                grecaptcha.enterprise.reset(captchaId);
            }
        });
    }

    beforeSubmit() {
        this.isSubmiting = true;
        pageSpinner.show();
        if (this.submitElement) {
            this.submitElement.disabled = true;
        }
		
		// Tell Mouseflow the form is submitting
		window._mfq.push(['formSubmitAttempt', '#' + this.element.id]);
    }

    resetForm() {
        if (this.resetOnSuccess) {
            // slow down a little so modal will be able to be hidden before reset
            setTimeout(() => {
                this.element.reset();
                this.getParsleyForm().reset();
            }, 1000);
        }

        this._hideErrorsSummary();
    }

    onDestroy() {
        this._getFieldsObserver().disconnect();
        this.getParsleyForm().destroy();
    }

    onFieldError = (field) => {
        // a11y fix
        field.$element.attr('aria-describedby', field._ui.errorsWrapperId);
        // global event to notify other components
        const errorEvent = createEvent(constants.EVENT_FIELD_VALIDATION_FAILED);
        errorEvent.failedValidators = field.validationResult.map((res) => res.assert.name);
        field.element.dispatchEvent(errorEvent);
    };

    onFormError = (form) => {
        const event = createEvent(constants.EVENT_FORM_VALIDATION_FAILED);
        event.failedElements = form.fields
            .filter((field) => field.validationResult !== true)
            .map((field) => field.element);
        this.element.dispatchEvent(event);
    };

    static hasFieldValue(field) {
        return (
            (field.element.tagName === 'INPUT' && field.element.type !== 'file') ||
            field.element.tagName === 'TEXTAREA' ||
            field.element.tagName === 'SELECT'
        );
    }
}

function handledUntrackedField(field) {
    if (field[PARSLEY_SERVER_ERROR_KEY] !== true) {
        field[PARSLEY_SERVER_ERROR_KEY] = true;
        return false;
    }

    return true;
}

Parsley.addValidator(PARSLEY_SERVER_CONSTRAINT_NAME, {
    validateString: (value, c, field) => {
        // if field cant store value we show error just once
        if (!FormComponent.hasFieldValue(field)) {
            return handledUntrackedField(field);
        }

        // otherwise field is valid only if new value do not matches old one
        if (PARSLEY_SERVER_ERROR_KEY in field) {
            return field[PARSLEY_SERVER_ERROR_KEY] !== field.getValue();
        }

        field[PARSLEY_SERVER_ERROR_KEY] = field.getValue();
        return false;
    },
    validateMultiple: (values, c, field) => handledUntrackedField(field),
    priority: 1024,
});

const TIME_SLOT_PICKER_LAST_SUCCESSFUL_VALUE_KEY = '__lastSuccessfulTimeslotValue';
Parsley.addValidator('timeSlot', {
    validate: (value, requirement, field) => {
        if (!value) {
            return true;
        }

        if (
            TIME_SLOT_PICKER_LAST_SUCCESSFUL_VALUE_KEY in field &&
            field[TIME_SLOT_PICKER_LAST_SUCCESSFUL_VALUE_KEY] === value
        ) {
            return true;
        }

        let data;
        try {
            data = JSON.parse(value);
        } catch (e) {
            return false;
        }

        const endpointUrl = requirement;
        return api.post(endpointUrl, data).then(
            (response) => {
                const { success, message } = response.data;
                if (!success) {
                    return Promise.reject(message);
                }
                field[TIME_SLOT_PICKER_LAST_SUCCESSFUL_VALUE_KEY] = value;
            },
            (error) => {
                console.error(error);
                return Promise.reject('Server unavailable, please try again in one minute');
            }
        );
    },
    priority: 512,
});

Parsley.addValidator('loqateEmail', {
    validateString: (value, endpointURL, field) => {
        if (field._failedOnce) {
            field.reset();
            return;
        }

        return loquateService.checkEmail(value).then(
            (response) => {
                const { ResponseCode, ResponseMessage } = response[0];
                switch (ResponseCode) {
                    case 0:
                        return Promise.resolve(true);
                    case 1:
                        return Promise.resolve(true);
                    case 2: {
                        return Promise.reject(ResponseMessage);
                    }
                    case 3: {
                        return Promise.reject(field.domOptions.typeError);
                    }
                }
            },
            (error) => {
                console.error(error);
                return Promise.reject('Service unavailable, please try again later');
            }
        );
    },
});

Parsley.addValidator('loqatePhoneNumber', {
    validateString: (value, endpointURL, field) => {
        if (field._failedOnce) {
            field.reset();
            return;
        }

        return loquateService.checkPhone(value).then(
            (response) => {
                const { IsValid } = response[0];
                if (IsValid !== 'Yes') {
                    return Promise.reject('Include prefix for international phone numbers.');
                }
                return Promise.resolve(true);
            },
            (error) => {
                console.error(error);
                return Promise.reject('Service unavailable, please try again later');
            }
        );
    },
});
