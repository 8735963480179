import React from 'react';
import PropTypes from 'prop-types';

export default class Spinner extends React.Component {
    render() {
        const { isActive, isFaded, mobileSmall } = this.props;

        return (
            <div
                className={`spinner ${mobileSmall ? 'spinner--mobile-small' : ''} ${isActive ? 'is-visible' : ''} ${isFaded ? 'is-faded' : ''}`
                }/>
        );
    }
}

Spinner.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFaded: PropTypes.bool,
    mobileSmall: PropTypes.bool,
};
