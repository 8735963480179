import DcBaseComponent from "../../../general/js/dc/dc-base-component";
import VideoCreator from '../../../general/js/video/creator';
import pageSpinner from '../../../general/js/page-spinner';
import ElementSpinner from '../../../general/js/element-spinner';

export default class VideoComponent extends DcBaseComponent {
    constructor(element, extendedOptions = {}, onPlayTriggered = null) {
        super(element);

        this.isStarted = false;
        this.onPlayTriggered = onPlayTriggered;
        this.options = { ...this.options, ...extendedOptions };
    }

    static getNamespace() {
        return 'video';
    }

    static getRequiredRefs() {
        return ['preview', 'video', 'player', 'playButton'];
    }

    onInit() {
        this.inline = this.options.inline;

        const options = this.options;
        options.playerElement = this.refs.player;

        this.refs.playButton.addEventListener('click', this.onPlayButtonClick);
        this.spinner = this.inline ? new ElementSpinner(this.element) : pageSpinner;
        options.onExternalStop = this.hideSpinner;

        /**
         * @type {AbstractVideo}
         */
        this.video = VideoCreator.createFromOptions(options);
        this.video.onStart(this.handleOnPlay);

        if (!this.refs.thumbnail) {
            this.loadThumbnail();
        }
    }

    onPlayButtonClick = (e) => {
        this.play();
    };

    // on video first time launched
    handleOnPlay = () => {
        if (!this.isStarted) {
            this.isStarted = true;
            // after first launch in case of inline player we should do the stuff
            if (this.inline) {
                this.refs.video.classList.add('is-started-inline');
            }
        }
    };

    async play() {
        if (!this.video.isStarting) {
            if (!this.isStarted) {
                if (this.inline) {
                    this.refs.video.classList.add('is-play-triggered-inline');
                }
                if (this.onPlayTriggered) {
                    this.onPlayTriggered();
                }
            }

            this.showSpinner();
            try {
                await this.video.play();
            } catch (e) {
                console.error(e);
            }

            this.hideSpinner();
        }
    }

    getVideo() {
        return this.video;
    }

    showSpinner = () => {
        this.spinner.show();
    };

    hideSpinner = () => {
        this.spinner.hide();
    };

    loadThumbnail() {
        const thumbnailUrlPromise = this.video.getThumbnailUrl();
        
        if (thumbnailUrlPromise) {
            thumbnailUrlPromise.then(thumbnailUrl => {
                if (thumbnailUrl) {
                    const img = document.createElement('img');

                    img.setAttribute('data-src', thumbnailUrl);
                    img.className = 'lazyload';
                    img.alt = this.options.title ?? this.video.getDefaultImageTitle();

                    const onImageLoad = this.video.getThumbnailOnLoadFunction();
                    if (onImageLoad) {
                        img.onload = onImageLoad;
                    }

                    this.refs.preview.insertAdjacentElement('beforeend', img);
                }
            })
        }
    }
}
