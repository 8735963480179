import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import constants from '../constants.js';
import { withContext } from '../context.jsx';

class Pagination extends React.PureComponent {
    getTotalPages = (pageSize, totalCount) => {
        if (totalCount <= pageSize) {
            return 1;
        }

        return Math.ceil(totalCount / pageSize);
    };

    getActivePage = (offset, pageSize) => {
        return offset / pageSize;
    };

    getOffset = (page, pageSize) => {
        return  page * pageSize;
    }

    createDisplayedPages = (page, totalPages) => {
        if (page >= totalPages) {
            throw new Error('page must be in totalPages interval');
        }

        if (totalPages <= constants.MAX_PAGES_DISPLAYED) {
            return Array.from({length: totalPages}, (x, i) => i); // [0, 1, 2]
        }

        if (page < (constants.MAX_PAGES_DISPLAYED / 2)) {
            return Array.from({length: constants.MAX_PAGES_DISPLAYED}, (x, i) => i); // [0, 1, 2, 3, 4]
        }

        if (page > (totalPages - (constants.MAX_PAGES_DISPLAYED / 2))) {
            return Array.from({length: constants.MAX_PAGES_DISPLAYED}, (x, i) => i + totalPages - constants.MAX_PAGES_DISPLAYED); // [10, 11, 12, 13, 14]
        }

        const start = page - Math.floor(constants.MAX_PAGES_DISPLAYED / 2);

        return Array.from({length: constants.MAX_PAGES_DISPLAYED}, (x, i) => i + start); // [7, 8, 9, 10, 11]
    };

    changeDisplayedPages = (page, totalPages) => {
        this.setState({
            currentDisplayedPages: this.createDisplayedPages(page, totalPages)
        });
    };

    isNewActivePageOnDisplay = (page) => {
        return this.currentDisplayedPages.includes(page);
    };

    isFirstPageOnDisplay = () => {
        return this.currentDisplayedPages.includes(0);
    };

    isLastPageOnDisplay = () => {
        return this.currentDisplayedPages.includes(this.lastPage);
    };

    onLinkClick = (page) => {
        return () => {
            this.props.onPageClick(this.getOffset(page, this.props.pagination.pageSize), this.currentDisplayedPages);
        };
    }

    render() {
        this.totalPages = this.getTotalPages(this.props.pagination.pageSize, this.props.pagination.totalCount);
        this.activePage = this.getActivePage(this.props.pagination.offset, this.props.pagination.pageSize);
        this.firstPage = 0;
        this.lastPage = this.totalPages - 1;
        this.currentDisplayedPages = this.props.currentDisplayedPages;

        if (!this.isNewActivePageOnDisplay(this.activePage)) {
            this.currentDisplayedPages = this.createDisplayedPages(this.activePage, this.totalPages);
        }

        return (
            
          
            <div className="property-table__pagination">
                {
                this.totalPages > 1 &&
                <ul className="property-table__pagination-list">
                    <li className="property-table__pagination-item">
                        <a
                            className={classNames({
                                'property-table__pagination-link': true,
                                'property-table__pagination-link--arrow': true,
                                'property-table__pagination-link--active': this.activePage !== this.firstPage
                            })}
                            onClick={this.activePage !== this.firstPage ? this.onLinkClick(this.activePage - 1) : () => {}}
                        >
                            <svg className="icon" width="4" height="8">
                                <use xlinkHref="#icon-arrow-left-thin"></use>
                            </svg>
                        </a>
                    </li>
                    {this.currentDisplayedPages.map((page, i) => (
                        <li
                            className="property-table__pagination-item"
                            key={i}
                        >
                            <a
                                className={classNames({
                                    'property-table__pagination-link': true,
                                    'property-table__pagination-link--active': page === this.activePage
                                })}
                                onClick={page !== this.activePage ? this.onLinkClick(page) : () => {}}
                            >
                                {page + 1}
                            </a>
                        </li>
                    ))}
                    {!this.isLastPageOnDisplay() && (
                        <>
                            <li className="property-table__pagination-item">
                                <a className="property-table__pagination-link">...</a>
                            </li>
                            <li className="property-table__pagination-item">
                                <a
                                    className="property-table__pagination-link"
                                    onClick={this.onLinkClick(this.lastPage)}
                                >
                                    {this.lastPage + 1}
                                </a>
                            </li>
                        </>
                    )}
                    <li className="property-table__pagination-item property-table__pagination-item--ml-4">
                        <a
                            className={classNames({
                                'property-table__pagination-link': true,
                                'property-table__pagination-link--arrow': true,
                                'property-table__pagination-link--active': this.activePage !== this.lastPage
                            })}
                            onClick={this.activePage !== this.lastPage ? this.onLinkClick(this.activePage + 1) : () => {}}
                        >
                            <svg className="icon" width="4" height="8">
                                <use xlinkHref="#icon-arrow-right-thin"></use>
                            </svg>
                        </a>
                    </li>
                </ul>
            }
            </div>
        );
    }
}

Pagination.propTypes = {
    onPageClick: PropTypes.func,
    currentDisplayedPages: PropTypes.arrayOf(PropTypes.number),
    pagination: PropTypes.shape({
        offset: PropTypes.number,
        pageSize: PropTypes.number,
        totalCount: PropTypes.number
    })
};

export default withContext(Pagination, {
    currentDisplayedPages: 'currentDisplayedPages',
    pagination: 'pagination'
});
