import React from 'react';
import PropTypes from 'prop-types';
import OverlayWrapper from '@General/js/overlay/js/overlay-wrapper.jsx';
import defaultOverlay from '@General/js/overlay/js/default-overlay';
import { isFilterValueDirty } from '../listing-helper';
import DropdownChecklist from './dropdown-checklist.jsx';

export default class SearchDesktopFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeDropdown: null,
            isApplyHidden: true,
        };
        this.rootRef = React.createRef();
    }

    componentDidUpdate() {
        ['click', 'touchstart'].forEach((event) =>
            document.addEventListener(event, this.onOuterClick));

        document.addEventListener('keydown', this.onDocumentKeydown);
    }

    componentWillUnmount() {
        ['click', 'touchstart'].forEach((event) =>
            document.removeEventListener(event, this.onOuterClick));

        document.removeEventListener('keydown', this.onDocumentKeydown);
    }

    onOuterClick = (e) => {
        if (this.hasActive() && !this.rootRef.current.contains(e.target)) {
            this.resetActive();
        }
    };

    onDocumentKeydown = (e) => {
        if (e.which === 27 && this.hasActive() && !this.rootRef.current.contains(e.target)) {
            this.resetActive();
        }
    };

    onClose = () => {
        if (this.hasActive()) {
            this.resetActive();
        }
    };

    resetActive() {
        this.setState({ activeDropdown: null });
    }

    hideApply = () => {
        this.setState({ isApplyHidden: true });
    };

    showApply = () => {
        this.setState({ isApplyHidden: false });
    };

    onClick = (name) => {
        if (this.state.activeDropdown === name) {
            this.resetActive();
        } else {
            this.setState({
                activeDropdown: name,
            });
        }
    };

    onChange = (config, newValue) => {
        this.props.onFiltersChange({ [config.name]: newValue });
        this.showApply();
    };

    onApply = () => {
        this.props.onSearch();
        this.resetActive();
        this.hideApply();
    };

    onClear = (name) => {
        this.props.onFiltersReset([name]);
        this.resetActive();
    };

    afterPopupDescriptionOpen = () => {
        this.resetActive();
    };

    isFilterDirty(config) {
        const { appliedFilters } = this.props;
        return isFilterValueDirty(appliedFilters[config.name], config);
    }

    getIsActiveClass() {
        return this.hasActive() ? 'is-active' : '';
    }

    getIsStretchedClass() {
        const { isStretched } = this.props;
        return isStretched ? 'is-stretched' : '';
    }

    hasActive() {
        return this.state.activeDropdown !== null;
    }

    getHiddenFilters() {
        const { filtersConfig, filters } = this.props;
        const hiddenFilters = [];

        filtersConfig.forEach((config) => {
            config.options.forEach((option) => {
                if (option.dependentFilters?.length && filters[config.name] !== option.value) {
                    hiddenFilters.push(...option.dependentFilters);
                }
            });
        });

        return hiddenFilters;
    }

    removeSelectedValues(fields) {
        fields.forEach((field) => {
            this.props.filters[field] = '';
        });
    }

    render() {
        const { filtersConfig, filters, isLoading, filtersApplyButtonText } = this.props;
        const { activeDropdown, isApplyHidden } = this.state;
        const hiddenFilters = this.getHiddenFilters();

        this.removeSelectedValues(hiddenFilters);

        return (
            <OverlayWrapper
                overlay={defaultOverlay}
                isActive={activeDropdown !== null}
                retainerId="filters"
            >
                <div
                    ref={this.rootRef}
                    className={`search-desktop-filters-v2 ${this.getIsActiveClass()} ${this.getIsStretchedClass()}`}
                >
                    {filtersConfig.map((config) => (
                        config.name !== 'sort' && <DropdownChecklist
                            key={config.name}
                            label={config.label}
                            options={config.options}
                            type={config.type}
                            value={filters[config.name]}
                            isDisabled={config.isDisabled}
                            isHidden={hiddenFilters.includes(config.name)}
                            isActive={activeDropdown === config.name}
                            isClearDisabled={!this.isFilterDirty(config)}
                            clickTrigger={config.clickTrigger}
                            onClick={() => this.onClick(config.name)}
                            afterPopupDescriptionOpen={this.afterPopupDescriptionOpen}
                            onChange={(newValue) => this.onChange(config, newValue)}
                            onApply={this.onApply}
                            onClose={this.onClose}
                            onClear={() => this.onClear(config.name)}
                        />
                    ))}
                </div>
                {!isLoading && !isApplyHidden && (
                    <button
                        className="search-desktop-filters-v2__apply-button"
                        type="button"
                        onClick={this.onApply}
                    >
                        {filtersApplyButtonText}
                    </button>
                )}
            </OverlayWrapper>
        );
    }
}

SearchDesktopFilters.defaultProps = {
    isStretched: false,
    filtersApplyButtonText: 'Apply',
};

SearchDesktopFilters.propTypes = {
    isStretched: PropTypes.bool,
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onFiltersReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    filtersApplyButtonText: PropTypes.string.isRequired,
};
