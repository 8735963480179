import React from 'react';
import PropTypes from 'prop-types';

class TabTrigger extends React.PureComponent {
    render() {
        const { onClick, id, isActive, children } = this.props;
        return (
            <button
                type="button"
                onClick={() => onClick(id)}
                className={`developments-search__tabs-button ${isActive ? 'is-active' : ''}`}
            >
                {children}
            </button>
        );
    }
}

TabTrigger.propTypes = {
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.array
};

export default TabTrigger;
