import React from 'react';
import { withContext } from '../context';
import SearchMobileFilters from '../../../listing/js/components/search-mobile-filters.jsx';

export default withContext(SearchMobileFilters, {
    filters: 'filters',
    appliedFilters: 'appliedFilters',
    onFiltersChange: 'onFiltersChange',
    onSearch: 'onSearch',
    onFiltersReset: 'onFiltersReset',
});
