import React from 'react';
import PropTypes from 'prop-types';

export default class RecentSearches extends React.Component {
    render() {
        const { items } = this.props;

        if (!items || items.length === 0) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="menu-popup-subtitle">Recent searches:</div>
                <div className="recent-searches">
                    {items.map((item, index) => (
                        <a key={index} className="recent-searches__item link" href={item.url}>
                            {item.title}
                            <svg
                                className="recent-searches__icon icon"
                                width="9"
                                height="15"
                                focusable="false"
                                role="img"
                                aria-hidden="true"
                            >
                                <use xlinkHref="#icon-arrow-right-thin" />
                            </svg>
                        </a>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

RecentSearches.propTypes = {
    items: PropTypes.array
};
