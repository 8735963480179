import FilterOption from './filter-option';
import { PROPERTY_ICONS } from '../constants';

export default class BedroomFilterOption extends FilterOption {
    constructor({ amount, price }) {
        super({ label: amount.toString() });

        this.price = Number(price);
        this.value = price.toString();
        this.svgIcon = PROPERTY_ICONS.bedroom;
    }
}
