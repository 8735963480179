import UrlHelper from 'general/js/url-helper';
import ObjectHelper from 'general/js/object-helper';

export function getDefaultFilters(filters) {
    return filters?.reduce((defaultFilters, filterConfig) => {
        const value = getFilterDefaultValue(filterConfig);

        if (value !== null) {
            defaultFilters[filterConfig.name] = value;
        }

        return defaultFilters;
    }, {});
}

function getFilterDefaultValue(filterConfig) {
    if (!('defaultValue' in filterConfig)) {
        return null;
    }

    return filterConfig.defaultValue;
}

export function getPathWithQueryString(location, exceptParams = [], customPath = false) {
    const baseUrl = customPath || location.pathname;
    const searchObject = UrlHelper.getSearchFromLocation(location);
    const paramsKeys = Object.keys(searchObject).filter((key) => exceptParams.indexOf(key) === -1);
    const paramsObject = paramsKeys.reduce((result, key) => {
        result[key] = searchObject[key];
        return result;
    }, {});

    return UrlHelper.getUrlByParams(baseUrl, paramsObject);
}

export function isFilterValuesEqual(parameter1, parameter2) {
    if (
        (ObjectHelper.isObject(parameter1) && ObjectHelper.isObject(parameter2)) ||
        (Array.isArray(parameter1) && Array.isArray(parameter2))
    ) {
        return ObjectHelper.isEqual(parameter1, parameter2);
    }

    return parameter1 === parameter2;
}

export function getFilterConfigByName(filtersConfigs, filterName) {
    return filtersConfigs.find((filtersConfig) => filtersConfig.name === filterName);
}

export function getDirtyFiltersConfigs(filters, filtersConfigs) {
    return filtersConfigs.filter((config) => isFilterValueDirty(filters[config.name], config));
}

export function isFilterValueDirty(filterValue, filterConfig) {
    return !isFilterValuesEqual(filterValue, getFilterDefaultValue(filterConfig));
}

export function isFiltersConfigsDirty(filtersConfigs, filters) {
    return filtersConfigs.some((filterConfig) => {
        const name = filterConfig.name;
        return isFilterValueDirty(filters[name], filterConfig);
    });
}

export function getTrackLabelFromFilterObject(filterObject) {
    return Object.entries(filterObject).reduce((acc, [key, value]) => {
        let valuePart;

        if (!value || (Array.isArray(value) && value.length === 0)) {
            valuePart = 'empty';
        } else {
            valuePart = value.toString();
        }

        return acc + key.toString() + ' > ' + valuePart + ';\n';
    }, '');
}
