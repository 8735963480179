import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import VacanciesSearchCard from '../components/vacancies-search-card';

class VacanciesSearchItem extends React.PureComponent {
    render() {
        const { item, index } = this.props;
        return (
            <li className="vacancies-search__item">
                <VacanciesSearchCard item={item} index={index} />
            </li>
        );
    }
}

VacanciesSearchItem.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default withContext(VacanciesSearchItem, {});
