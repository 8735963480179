import React from 'react';
import PropTypes from 'prop-types';

class SelectedFilterValue extends React.PureComponent {
    render() {
        const {
            text, selectedText, value, onClick, filterLabel
        } = this.props;
        return (
            <button
                onClick={() => onClick(value)}
                className="developments-search__selected-filter-value-v2"
                aria-label={`Remove filter ${filterLabel} ${text}`}
            >
                {selectedText || text}
                <svg
                    className="icon developments-search__selected-filter-icon"
                    width="16"
                    height="16"
                    focusable="false"
                    role="img" aria-hidden="true"
                >
                    <use xlinkHref="#icon-cross" />
                </svg>
            </button>
        );
    }
}

SelectedFilterValue.propTypes = {
    filterLabel: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    selectedText: PropTypes.string,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default SelectedFilterValue;
