import React from 'react';
import PropTypes from 'prop-types';
import { withSearchMobileFiltersContext } from '../search-mobile-filters-context.js';
import MobileFiltersCheckbox from './mobile-filters-checkbox.jsx';
import trackerService from '@General/js/tracker-service.js'

class MobileFiltersChecklist extends React.Component {
    onCheckboxChange = (changedCheckboxValue, isChecked) => {
        const currentValue = this.props.value;
        let newValue = null;

        if (isChecked) {
            newValue = [...currentValue, changedCheckboxValue];
        } else {
            newValue = currentValue.filter((value) => value !== changedCheckboxValue);
        }

        this.props.onChange(this.props.config.name, newValue);
    };

    render() {
        const { config, value } = this.props;

        return (
            <ul className="mobile-filters-checklist">
                {config.options.map((option, index) => {
                    const isChecked = value.includes(option.value);
                    return (
                        <li
                            className="mobile-filters-checklist__item"
                            role="option"
                            aria-selected={isChecked}
                            key={index}
                        >
                            <MobileFiltersCheckbox
                                isDisabled={option.isDisabled}
                                isChecked={isChecked}
                                text={option.mobileText}
                                gtmSelector="ExplorePropertiesDropdownCheckbox"
                                onChange={(e) => {
                                    this.onCheckboxChange(option.value, e.target.checked);
                                    trackerService.track(config.clickTrigger);
                                }}
                            />
                        </li>
                    );
                })}
            </ul>
        );
    }
}

MobileFiltersChecklist.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default withSearchMobileFiltersContext(MobileFiltersChecklist, {
    onChange: 'onChange',
});
