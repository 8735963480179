import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context.jsx';
import SearchDesktopFilters from '../../../listing/js/components/search-desktop-filters';
import CirclesPreloader from '../../../circles-preloader/js/circles-preloader';

class DevelopmentsSearchDesktopFilters extends React.PureComponent {
    render() {
        const {
            filtersConfig, filters, appliedFilters, onFiltersChange, onFiltersReset, onSearch, overlayPreloader, isLoading
        } = this.props;

        return (
            <div className="developments-search__desktop-filters">
                <div className="developments-search__desktop-filters-label">
                    Refine your results:
                </div>
                <div className="developments-search__desktop-filters-content">
                    <SearchDesktopFilters
                        filtersConfig={filtersConfig}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        onFiltersChange={onFiltersChange}
                        onFiltersReset={onFiltersReset}
                        onSearch={onSearch}/>
                    {!overlayPreloader && (
                        <div className="developments-search__filters-preloader">
                            <CirclesPreloader
                                isVisible={isLoading}
                                caption={'Loading Developments'}/>
                        </div>
                    )}
                </div>
            </div>

        );
    }
}

DevelopmentsSearchDesktopFilters.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    // context
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onFiltersReset: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    overlayPreloader: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default withContext(DevelopmentsSearchDesktopFilters, {
    filters: 'filters',
    appliedFilters: 'appliedFilters',
    onFiltersChange: 'onFiltersChange',
    onFiltersReset: 'onFiltersReset',
    onSearch: 'onSearch',
    overlayPreloader: 'overlayPreloader',
    isLoading: 'isLoading',
});
