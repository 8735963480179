import React from 'react';
import PropTypes from 'prop-types';
import MobileFiltersModal from './mobile-filters-modal.jsx';
import { SearchMobileFiltersContext } from '../search-mobile-filters-context.js';

export default class SearchMobileFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            label: 'Filter results',
        };
    }

    getContextValue = () => {
        const { filtersConfig, filters, onFiltersChange } = this.props;

        return {
            filters,
            filtersConfig,
            onFiltersChange,
            onChange: this.onChange,
            onApply: this.onApply,
            onCloseClick: this.onCloseClick,
            onOuterClick: this.onOuterClick,
        };
    };

    onOuterClick = () => {
        this.props.onSearch();
        this.close();
    };

    onClick = () => {
        this.open();
    };

    open() {
        this.setState({ isOpened: true });
    }

    close() {
        this.setState({ isOpened: false });
    }

    onChange = (name, value) => {
        this.props.onFiltersChange({ [name]: value });
    };

    onApply = () => {
        this.props.onSearch();
        this.close();
    };

    onCloseClick = () => {
        this.props.onSearch();
        this.close();
    };

    render() {
        const { isOpened, label } = this.state;

        return (
            <SearchMobileFiltersContext.Provider value={this.getContextValue()}>
                <div className="search-mobile-filters">
                    <button
                        className="search-mobile-filters__control-main-v2"
                        aria-label={label}
                        aria-haspopup="listbox"
                        aria-expanded={isOpened}
                        onClick={this.onClick}
                    >
                        <div className="search-mobile-filters__control-label-v2">{label}</div>
                        <svg
                            width="13"
                            height="8"
                            className="icon search-mobile-filters__control-arrow"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                        >
                            <use xlinkHref="#icon-arrow-down" />
                        </svg>
                    </button>
                    <MobileFiltersModal isOpened={isOpened} onChange={this.onChange} />
                </div>
            </SearchMobileFiltersContext.Provider>
        );
    }
}

SearchMobileFilters.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
};
