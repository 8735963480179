import React from 'react';
import PropTypes from 'prop-types';

export default function BudgetDivider({ children }) {
    return <div className="budget-divider">{children}</div>;
}

BudgetDivider.propTypes = {
    children: PropTypes.any,
};
