import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@Components/spinner/js/spinner.jsx';
import constants from '../constants.js';
import { withContext } from '../context.jsx';
import PropertiesDisplay from './properties-display.jsx';

import DevTablePagination from './dev-table-pagination.jsx';

class DevPropertyTable extends React.Component {
    constructor(props) {
        super(props);

        this.accordion = null;
        this.state = {
            isExpanded: true,
        };
    }

    onAccordionChange = (accordion) => {
        this.setState({ isExpanded: !accordion._getIsExpanded() });
    };

    render() {
        const { salesTabVisibleColumns, rentTabVisibleColumns } = this.props.item;
        const displayProps = {
            salesTabVisibleColumns,
            rentTabVisibleColumns
        };
        return (
         
            this.props.item.totalPlotsCount != 0 ?
            <div className="dev-property-table  dev-property-table-v2 is-expanded" ref={this.accordionRef}>
                <div className="dev-property-table__accordion-body" data-dc-accordion-ref="content">
                   
                    {!this.props.isTextShowing && (
                        <div className="dev-property-table__tab-section">
                            <div className="dev-property-table__table">
                                <PropertiesDisplay showPercentagePricesOverride={this.props.item.development.showPercentagePricesOverride} opsoPercentagePriceLabel={this.props.item.development.opsoPercentagePriceLabel}  />
                            </div>
                        </div>
                    )}
                    {this.props.isTextShowing && (
                        <div className="dev-property-table__message">
                            <h4 className="dev-property-table__message-title">
                                {this.props.message.title}
                            </h4>
                            <p className="dev-property-table__message-text">
                                {this.props.message.text}
                            </p>
                        </div>
                    )}
                </div>
                 <div className="dev-property-table-v2__bottom">
                        <DevTablePagination isExpanded={this.state.isExpanded} /> 
                </div>
                {this.props.isGettingData && (
                    <div className="dev-property-table__spinner-container">
                        <Spinner isActive={true} />
                    </div>
                )}
            </div>
            :""
            
        );
    }
}

DevPropertyTable.propTypes = {
    isGettingData: PropTypes.bool,
    isTextShowing: PropTypes.bool,
    message: PropTypes.shape({
        text: PropTypes.string,
        title: PropTypes.string,
    }),
    item: PropTypes.shape({
        salesTabVisibleColumns: PropTypes.object,
        rentTabVisibleColumns: PropTypes.object,
    })
};

export default withContext(DevPropertyTable, {
    isGettingData: 'isGettingData',
    isTextShowing: 'isTextShowing',
    message: 'message',
});
