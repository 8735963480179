import React from 'react';
import PropTypes from 'prop-types';

export default class FaqQuestionPreview extends React.Component {
    render() {
        const { question, additionText } = this.props;

        return (
            <div className="faq-search__preview">
                <div className="faq-search__answer">
                    <div className="richtext"
                        dangerouslySetInnerHTML={{ __html: question.answer }}/>
                </div>
                <div className="faq-search__addition">
                    <div className="richtext"
                        dangerouslySetInnerHTML={{ __html: additionText }}/>
                </div>
            </div>
        );
    }
}

FaqQuestionPreview.propTypes = {
    question: PropTypes.object.isRequired,
    additionText: PropTypes.string.isRequired
};
