import React from 'react';
import PropTypes from 'prop-types';
import api from 'general/js/api';
import SequentialRequestsProxy from '@General/js/api/sequential-requests-proxy.js';
import device, { DEVICE_TYPE_TABLET } from '@General/js/device.js';
import { devSearchTypeItem } from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import { withContext } from '@Components/developments-search-all-ownership-types/js/context.jsx';
import {
    FILTER_SORT_PRICE_HIGH_LOW,
    FILTER_SORT_PRICE_LOW_HIGH,
} from '@Components/listing-v2/js/constants.js';
import DevPropertyTable from './components/dev-property-table.jsx';
import constants from './constants.js';
import { PropertyTableContext } from './context.jsx';

class DevPropertyTableDataContainer extends React.Component {
    constructor(props) {
        super(props);
        this.searchUrl = `${this.props.propertyTableSearchUrl}?developmentId=${this.props.item.development.developmentId}`;
        this.api = new SequentialRequestsProxy(api);
        this.state = this.getInitialState(); 
        
    }

    componentDidMount() {       
        this.deviceChangeUnsubscribe = device.subscribeOnDeviceTypeChange(
            this.updateViewportStatus,
            false
        );        
    }

    componentWillUnmount() {
        this.deviceChangeUnsubscribe();
    }

    updateViewportStatus = () => {
        this.setState({ isOnMobileViewport: !device.isViewportTypeGe(DEVICE_TYPE_TABLET) });
    };

    getInitialState = () =>
        ({
            currentProperties: Array.from(this.props.item.plots
                                    .reduce((m, o) => m.set(o.plotNumber, o), new Map)
                                    .values())
                                    .slice(
                                        0,
                                        constants.MAX_PROPS_ON_DESKTOP
                                    ),
            isGettingData: false,
            isTextShowing: false,
            message: constants.ERROR_MESSAGE,
            isOnMobileViewport: !device.isViewportTypeGe(DEVICE_TYPE_TABLET),
            ...this.getDefaultNavState(),
        });
    
    getDefaultNavState = () => {
        return {
            currentDisplayedPages: [1],
            pagination: {
                offset: 0,
                pageSize: constants.MAX_PROPS_ON_DESKTOP,
                totalCount: this.getDefaultTotalCount(),
            },
        };
    };

    getDefaultTotalCount = () => {        
        if (this.props.item.totalPlotsCount > 0) {
            return this.props.item.totalPlotsCount;
        }
        return 0;
    };

    changeActivePage = (offset, currentDisplayedPages) => {
        let ownershipType;
        if(this.props.appliedFilters.ownershipType === "sale") {
            ownershipType =  "Selling";
        }else if(this.props.appliedFilters.ownershipType === "rent") {
            ownershipType =  "Rental";
		}else if(this.props.appliedFilters.ownershipType === "opso") {
            ownershipType =  "Opso";
        }else {
            ownershipType = null;
        }

        const isSortByPrice =
            this.props.appliedFilters.sort === FILTER_SORT_PRICE_LOW_HIGH ||
            this.props.appliedFilters.sort === FILTER_SORT_PRICE_HIGH_LOW;
        const isDesc = this.props.appliedFilters.sort === FILTER_SORT_PRICE_HIGH_LOW;

        const orderBy = (isSortByPrice ? constants.FIELDS.Prices : constants.FIELDS.Status) + (isDesc ? constants.SORT.Desc : constants.SORT.Asc);
        
        const partialState = {
            pagination: {
                ...this.state.pagination,
                pageSize: constants.MAX_PROPS_ON_DESKTOP,
                offset,
            },
            currentDisplayedPages,
        };
        const params = {
            offset: partialState.pagination.offset,
            pageSize: constants.MAX_PROPS_ON_DESKTOP,
            channel: ownershipType,
            orderBy
        };
        this.getData(params, partialState);
    };

    isTabWithProperties = (props) => {
        return props && Array.isArray(props) && props.length && props.length !== 0;
    };

    getData = (params, partialState) => {
        this.setState(
            {
                ...this.showGettingDataAction,
                ...partialState,
            },
            () => {
                api.post(this.searchUrl, {
                    ...params,
                    searchFilters: this.props.appliedFilters,
                })
                    .then((result) => {
                        if (this.isTabWithProperties(result.data.properties)) {
                            this.setState(() => ({
                                ...this.getSetNewDataAction(partialState, result.data),
                                ...this.showPropertiesAction,
                            }));
                        } else {
                            this.setState(() => ({
                                ...this.getSetNewDataAction(partialState, result.data),
                                ...this.showMessageWithNoPropsAction,
                            }));
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            ...this.getDefaultNavState(),
                            ...this.showServerErrorAction,
                        });
                        console.error(error);
                    });
            }
        );
    };

    getSetNewDataAction = (partialState, data) => ({
        ...this.getDefaultNavState(),
        ...partialState,
        pagination: {
            ...data.pagination,
        },
        currentProperties: [...data.properties],
    });

    showGettingDataAction = {
        isGettingData: true,
    };

    showPropertiesAction = {
        isTextShowing: false,
        isGettingData: false,
    };

    showServerErrorAction = {
        isTextShowing: true,
        isGettingData: false,
        message: constants.ERROR_MESSAGE,
    };

    showMessageWithNoPropsAction = {
        isTextShowing: true,
        isGettingData: false,
        message: constants.NO_PROPERTIES_MESSAGE,
    };

    getContextValue = () => {
        return {
            ...this.state,
            ...this.props,
            tableSettings: this.props.tableSettings,
            onPageClick: this.changeActivePage,
        };
    };

    render() {
        return (
            <PropertyTableContext.Provider
            value={this.getContextValue()}
            >
                <DevPropertyTable item={this.props.item}/>
            </PropertyTableContext.Provider>
        );
    }
}

DevPropertyTableDataContainer.propTypes = {
    appliedFilters: PropTypes.object,
    propertyTableSearchUrl: PropTypes.string,
    item: devSearchTypeItem,
    tableSettings: PropTypes.object,
};

export default withContext(DevPropertyTableDataContainer, {
    appliedFilters: 'appliedFilters',
    propertyTableSearchUrl: 'propertyTableSearchUrl',
    tableSettings: 'tableSettings',
});
