import React from 'react';
import PropTypes from 'prop-types';
import constants from 'general/js/constants';
import LazyImage from '../../../general/js/lazy-image';

export const BACKGROUND_WHITE = 'white';
export const BACKGROUND_GREY = 'grey';
export const BADGE_WHITE = 'white';

class DevPlotCard extends React.PureComponent {
    _containerRef = React.createRef();
    getPropertyTypeClass() {
        const { productType } = this.props;

        switch (productType) {
            case constants.DEVELOPMENT_PROPERTY_TYPE_LL:
                return 'dev-plot-card--ll';
            case constants.DEVELOPMENT_PROPERTY_TYPE_RL:
                return 'dev-plot-card--rl';
            case constants.DEVELOPMENT_PROPERTY_TYPE_RL_PLUS:
                return 'dev-plot-card--rl-plus';
            default:
                return '';
        }
    }

    getDirectionClass() {
        const { alwaysVertical } = this.props;
        return alwaysVertical ? 'dev-plot-card--always-vertical' : '';
    }

    getHighlightedClass() {
        const { isHighlighted } = this.props;
        return isHighlighted ? 'is-highlighted' : '';
    }

    getSlimClass() {
        const { isSlim } = this.props;
        return isSlim ? 'dev-plot-card--slim' : '';
    }

    getDynamicClasses() {
        const classes = [
            this.getPropertyTypeClass(),
            this.getDirectionClass(),
            this.getHighlightedClass(),
            this.getSlimClass(),
        ];
        return classes.filter(String).join(' ');
    }

    render() {
        const {
            img,
            statusText,
            servicesText,
            showButton,
            buttonText,
            buttonUrl,
            specificClass,
            url,
            developmentName,
            gtmSelector,
        } = this.props;

        const isButtonVisible = showButton && buttonText && buttonUrl;

        return (
            <div
                className={`dev-plot-card ${specificClass} ${this.getDynamicClasses()}`}
                data-tooltip-container
                ref={this._containerRef}
            >

                <div className="dev-plot-card__inner">
                    <a
                        aria-label={this.getLinkAriaLabel()}
                        className="dev-plot-card__header"
                        data-dc-gtm-selector={gtmSelector}
                        href={buttonUrl ? buttonUrl : url}
                    >
                        <div className="dev-plot-card__img">
                            <LazyImage image={img} />
                        </div>
                        {statusText && <div className="dev-plot-card__img-label">{statusText}</div>}
                    </a>
                    <div className="dev-plot-card__content">
                        <div className="dev-plot-card__content-header">
                            {servicesText && (
                                <div className="dev-plot-card__services-badge">{servicesText}</div>
                            )}
                            <div className="property-card__subtitle">{developmentName}</div>
                        </div>
                        {this.props.renderHeader()}
                        {this.props.renderContent()}
                        {this.props.renderReservationBadge && this.props.renderReservationBadge()}
                        {this.props.renderPriceList && this.props.renderPriceList()}
                        <div className="dev-plot-card__btn-wrapper dev-plot-card__footer">
                            <a
                                aria-label={this.getLinkAriaLabel()}
                                className="link-arrow"
                                data-dc-gtm-selector={gtmSelector}
                                href={buttonUrl ? buttonUrl : url}
                            >
                                {buttonText ? buttonText : 'View development'}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getLinkAriaLabel() {
        const { title } = this.props;
        return `Go to ${title}`;
    }
}

DevPlotCard.defaultProps = {
    specificClass: '',
    alwaysVertical: false,
    showButton: false,
    isHighlighted: false,
    background: BACKGROUND_WHITE,
    isSlim: false,
};

DevPlotCard.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    renderHeader: PropTypes.func.isRequired,
    renderContent: PropTypes.func.isRequired,
    img: PropTypes.object,
    servicesText: PropTypes.string,
    developmentName: PropTypes.string,
    statusText: PropTypes.string,
    specificClass: PropTypes.string,
    alwaysVertical: PropTypes.bool,
    showButton: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    isHighlighted: PropTypes.bool,
    background: PropTypes.string,
    badgeColour: PropTypes.string,
    isSlim: PropTypes.bool,
    gtmSelector: PropTypes.string,
    renderReservationBadge: PropTypes.func,
};

export default DevPlotCard;
