import React from 'react';
import PropTypes from 'prop-types';
import StickyColumnComponent from '../../../sticky-column/js/sticky-column.component';

class StickyColumn extends React.PureComponent {
    constructor(props) {
        super(props);

        this.columnRef = React.createRef();
    }

    componentDidMount() {
        this.stickyColumnInstance = new StickyColumnComponent(this.columnRef.current);
        this.stickyColumnInstance.init();
    }

    componentWillUnmount() {
        this.stickyColumnInstance.destroy();
    }

    render() {
        const { children } = this.props;
        return (
            <div className="developments-search-layout__sidebar-inner" ref={this.columnRef}>
                {children}
            </div>
        );
    }
}

StickyColumn.propTypes = {};

export default StickyColumn;
