const RECENT_SEARCHES_STORAGE_KEY = '__recent-searches__';

const STORED_RECENT_SEARCHES_COUNT = 3;
const STORED_RECENT_SEARCHES_TTL = 2592000000;

class RecentSearchesService {
    constructor() {
        this._storage = {};
        this._recentSearches = null;
        this._isLocalStorageAvailable = false;
        this._setLocalStorageProp();
    }

    getRecentSearches() {
        if (this._recentSearches === null && this._isLocalStorageAvailable) {
            const storedRecentSearches = this._getStoredRecentSearches();
            this._recentSearches = this._filterExpired(storedRecentSearches);
        }

        return this._recentSearches;
    }

    add(title, url) {
        if (title && url) {
            this._saveRecentSearch({ title, url, timestamp: Date.now() });
        } else {
            throw new Error('title and url of the search must be defined');
        }
    }

    _setLocalStorageProp() {
        try {
            this._isLocalStorageAvailable = true;
            this._storage = localStorage;
        } catch (error) {
            this._isLocalStorageAvailable = false;
        }
    }

    _filterExpired(searches) {
        const currentTimestamp = Date.now();
        return searches.filter(
            ({ timestamp }) => currentTimestamp < timestamp + STORED_RECENT_SEARCHES_TTL
        );
    }

    /**
     * @return {Array}
     * @private
     */
    _getStoredRecentSearches() {
        let result = [];
        const storedValue = this._storage.getItem(RECENT_SEARCHES_STORAGE_KEY);
        if (storedValue !== null) {
            result = JSON.parse(storedValue);
        }
        return result;
    }

    /**
     * @param {Array} value
     * @private
     */
    _updateStorage(value) {
        const storedValue = JSON.stringify(value);
        this._storage.setItem(RECENT_SEARCHES_STORAGE_KEY, storedValue);
    }

    /**
     * @param {Object} newRecentSearch
     * @private
     */
    _saveRecentSearch(newRecentSearch) {
        let currentRecentSearches = this.getRecentSearches();
        currentRecentSearches = currentRecentSearches.filter(
            search => search.url !== newRecentSearch.url
        );
        currentRecentSearches.unshift(newRecentSearch);
        currentRecentSearches = currentRecentSearches.slice(0, STORED_RECENT_SEARCHES_COUNT);
        this._updateRecentSearches(currentRecentSearches);
    }

    _updateRecentSearches(newRecentSearches) {
        try {
            this._updateStorage(newRecentSearches);
            this._recentSearches = newRecentSearches;
        } catch (e) {
            console.error('Couldn\'t save new recent searches', newRecentSearches);
        }
    }
}

export default new RecentSearchesService();
