class AnalyticsService {
    _send(event, data) {
        if (window.dataLayer) {
            window.dataLayer.push({ event, ...data });
        }
    }

    sendEvent(category, action, label, clientId = '') {
        this._send('eventTrack', {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            clientId,
        });
    }

    sendVirtualUrl(value) {
        this._send('pageviewTrack', { virtualPage: value });
    }

    disableAnalytics = () => {
        if (window.gaData) {
            window[`ga-disable-${Object.keys(window.gaData)[0]}`] = true;
        }
    };

    getSearchFilterData = (filters) => {
        return {
            'availability': filters.availability.toString(),
            'maxPrice': filters.maxPrice,
            'maxRent': filters.maxRent,
			'maxOpsoPrice': filters.maxOpsoPrice,
            'numberOfBedrooms': filters.numberOfBedrooms.toString(),
            'ownershipType': filters.ownershipType,
            'propertyTypes': filters.propertyTypes.toString(),
            'searchRadius': filters.searchRadius
        }
    }
}

export default new AnalyticsService();
