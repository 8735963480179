import React from 'react';
import PropTypes from 'prop-types';
import { devSearchTypeItem } from '../dev-search-types.js';
import DevSectionCard from '../../../dev-section-card/js/dev-section-card.jsx';

function DevelopmentSearchCard(props) {
    return (
        <>
            <DevSectionCard
                item={props.item}
                isHighlighted={props.isHighlighted}
                gtmSelector={props.gtmSelector}
            />
        </>
    );
}

DevelopmentSearchCard.defaultProps = {
    gtmSelector: 'ViewPropertiesCardButton',
};

DevelopmentSearchCard.propTypes = {
    item: devSearchTypeItem,
    isHighlighted: PropTypes.bool,
    gtmSelector: PropTypes.string,
};

export default DevelopmentSearchCard;
