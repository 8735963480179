import React from 'react';
import PropTypes from 'prop-types';

const ArticleTags = ({ tags, title }) => (
    <div className='article-tags'>
        <div className='article-tags__label'>{title}</div>
        <div className='article-tags__items'>
            {tags.map((tag, index) => (
                <div key={`article-tag${index}`} className='article-tags__item'>{tag}</div>
            ))}
        </div>
    </div>
);

ArticleTags.propTypes = {
    tags: PropTypes.array,
    title: PropTypes.string
};

export default ArticleTags;
