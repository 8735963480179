import React from 'react';
import PropTypes from 'prop-types';

export default class SearchResultsMessage extends React.PureComponent {
    render() {
        const { html } = this.props;

        return (
            <div className="search-results-message">
                <div
                    className="richtext"
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
        );
    }
}

SearchResultsMessage.propTypes = {
    html: PropTypes.string.isRequired
};
