import DcBaseComponent from 'general/js/dc/dc-base-component';
import { mobileDeviceChangeCheck } from './cta.device-change';

const CSS_BODY_CLASS = 'has-sub-menu';
export default class CtaComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.isActive = false;
        this.agrs = args;
    }

    static getNamespace() {
        return 'sticky-cta';
    }

    onInit() {
        document.body.classList.add(CSS_BODY_CLASS);

        mobileDeviceChangeCheck();
        window.addEventListener('resize', mobileDeviceChangeCheck);
    }

    onDestroy() {
        document.body.classList.remove(CSS_BODY_CLASS);
    }
}
