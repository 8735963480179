// https://github.com/calvellido/focus-options-polyfill
const checkSupport = () => {
    let supports = false;
    try {
        const focusElem = document.createElement('div');
        focusElem.addEventListener(
            'focus',
            (event) => {
                event.preventDefault();
                event.stopPropagation();
            },
            true
        );
        // document.documentElement.focus(
        focusElem.focus(
            Object.defineProperty({}, 'preventScroll', {
                get() {
                    supports = true;
                }
            })
        );
    } catch (e) {}
    return supports;
};

const supportsPreventScrollOption = checkSupport();
export default supportsPreventScrollOption;
