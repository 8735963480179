import DcBaseComponent from 'general/js/dc/dc-base-component';
import trackerService from '@General/js/tracker-service.js'

export default class EventsStackComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.items = this.refs.items || [];
    }

    static getNamespace() {
        return 'events-stack';
    }

    static getRequiredRefs() {
        return ['items'];
    }

    onInit() {

        this.refs.items.forEach(item => {
            const cta = item.querySelector('.event-card__booking a');
            if (cta) {
                cta.addEventListener('click', this.onCtaClick);
            }
        });
    }

    onCtaClick = () => {
        trackerService.track('ButtonClick_Event');
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'eventRsvpCta'
        });
    };
}
