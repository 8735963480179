import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { usePopper } from 'react-popper';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import { SERVICE_MODE, CHART_MODE, TOOLTIP_ID } from '../constants.js';

export default function BudgetTooltip() {
    const [state, dispatch] = useBudgetCalculatorStore();

    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(state.tooltipReference, popperElement, {
        placement: state.isOnMobileViewport ? 'bottom' : 'right',
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            { name: 'offset', options: { offset: [0, 10] } },
        ],
    });

    useEffect(() => {
        const onResize = debounce(() => {
            dispatch({ type: 'HIDE_TOOLTIP' });
        }, 500);

        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, [dispatch]);

    return (
        <>
            <div
                className={classNames({
                    'budget-calculator__overlay': true,
                    'budget-calculator__overlay--shown': state.isTooltipShown,
                })}
                onClick={() => dispatch({ type: 'HIDE_TOOLTIP' })}
            ></div>
            <div
                className={classNames({
                    'budget-tooltip': state.tooltipMode === SERVICE_MODE,
                    'budget-piechart__tip': state.tooltipMode === CHART_MODE,
                })}
                ref={setPopperElement}
                style={{ ...styles.popper, color: state.tooltipColor }}
                {...attributes.popper}
                id={TOOLTIP_ID}
                role="tooltip"
                data-show={state.isTooltipShown}
            >
                {state.tooltipText.map((text, i) => (
                    <p
                        className={classNames({
                            'budget-tooltip__text': state.tooltipMode === SERVICE_MODE,
                            'budget-piechart__text': state.tooltipMode === CHART_MODE,
                        })}
                        key={i}
                    >
                        {text}
                    </p>
                ))}
                <div
                    className={classNames({
                        'budget-tooltip__arrow': state.tooltipMode === SERVICE_MODE,
                        'budget-piechart__arrow': state.tooltipMode === CHART_MODE,
                    })}
                    ref={setArrowElement}
                    style={styles.arrow}
                />
                {state.tooltipMode === CHART_MODE && (
                    <button
                        className="budget-piechart__close"
                        type="button"
                        aria-label="close tooltip"
                        onClick={() => {
                            dispatch({ type: 'HIDE_TOOLTIP' });
                        }}
                    >
                        <svg className="icon" width="10" height="10" focusable="false">
                            <use xlinkHref="#icon-cross"></use>
                        </svg>
                    </button>
                )}
            </div>
        </>
    );
}
