import DcBaseComponent from 'general/js/dc/dc-base-component';
import ModalTriggerComponent from '../../modal/js/modal-trigger.component';

const COOKIE_MODAL_OPTIONS = {
    cssModifier: 'modal--cookie-settings',
    useCookieOverlay: true
};

export default class CookieSettingsTriggerComponent extends DcBaseComponent {
    constructor(props) {
        super(props);

        this.modalTrigger = null;
    }

    static getNamespace() {
        return 'cookie-settings-trigger';
    }

    onInit() {
        console.log('cookie settings trigger')
        this.handleModal(this.modalTrigger, this.element, COOKIE_MODAL_OPTIONS);
    }

    onDestroy() {
        this.handleModal(this.modalTrigger);
    }

    handleModal = (trigger, element, options) => {
        if (element) {
            if (trigger === null) {
                trigger = new ModalTriggerComponent(element, null, options);
                trigger.init();
            }
        } else if (trigger !== null) {
            trigger.destroy();
            trigger = null;
        }
    };
}
