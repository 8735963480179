import Filter from './filter';
import PropertyTypeFilterOption from './property-type-filter-option';

export default class PropertyTypeFilter extends Filter {
    constructor({ title = 'Property type', options }) {
        super({ title });

        this.options = this.setOptions({ ClassType: PropertyTypeFilterOption }, options);
        this.setInitialActiveOption();
    }

    get bedroomActiveOption() {
        if (this.activeOption != null && Filter.isFilterValid(this._activeOption.bedroomFilter)) {
            return this._activeOption.bedroomFilter.activeOption;
        }

        return null;
    }

    get activePrice() {
        if (this.bedroomActiveOption != null) {
            return this.bedroomActiveOption.price;
        }

        return null;
    }

    get zeroPriceText() {
        return this.activeOption.zeroPriceText;
    }

    get hideCalculations() {
        if (
            this.activeOption != null &&
            this.activeOption.hideCalculations != null &&
            this.activeOption.replacementDescription != null
        ) {
            return this.activeOption.hideCalculations;
        }

        return false;
    }

    updateBedroomActiveOption = (value) => {
        const bedroomFilter = this.activeOption.bedroomFilter;
        if (Filter.isFilterValid(bedroomFilter)) {
            bedroomFilter.updateActiveOption(value);
        }
        return this;
    };

    updateBedroomActiveOptionByLabel = (label) => {
        const bedroomFilter = this.activeOption.bedroomFilter;
        if (Filter.isFilterValid(bedroomFilter)) {
            bedroomFilter.updateActiveOptionByLabel(label);
        }
        return this;
    };
}
