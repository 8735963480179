import React from 'react';
import PropTypes from 'prop-types';
import Suggestions from '../components/suggestions';
import ZeroSuggestionsMessage from '../components/zero-suggestions-message';

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.rootRef = React.createRef();
    }

    onHighlightedElementUpdate = (element) => {
        this.scrollToHighlightedSuggestion(element);
        this.props.setActiveSuggestionId(element);
    };

    scrollToHighlightedSuggestion(element) {
        const elementTopCoord = element.offsetTop;
        const elementBottomCoord = elementTopCoord + element.clientHeight;
        const dropdownElement = this.rootRef.current;
        if (elementTopCoord < this.getDropdownTopCoord(dropdownElement)) {
            this.scrollDropdownUp(dropdownElement, elementTopCoord);
        } else if (elementBottomCoord > this.getDropdownBottomCoord(dropdownElement)) {
            this.scrollDropdownDown(dropdownElement, elementBottomCoord);
        }
    }

    getDropdownTopCoord(dropdownElement) {
        return dropdownElement.scrollTop;
    }

    getDropdownBottomCoord(dropdownElement) {
        return this.getDropdownTopCoord(dropdownElement) + dropdownElement.clientHeight;
    }

    render() {
        const {
            suggestions,
            groups,
            listboxId,
            zeroSuggestionsMessage,
            dropdownClass,
            dropdownInlineStyle,
            renderFooter,
            isZeroSuggestionsVisible
        } = this.props;

        return (
            <div
                style={dropdownInlineStyle}
                className={`predictive-search__dropdown ${dropdownClass}`}
                role="listbox"
                id={listboxId}
                ref={this.rootRef}
            >
                {suggestions.length > 0 && (
                    <Suggestions
                        renderFooter={renderFooter}
                        suggestions={suggestions}
                        groups={groups}
                        onHighlightedElementUpdate={this.onHighlightedElementUpdate}
                    />
                ) }
            </div>
        );
    }

    scrollDropdownUp(dropdownElement, elementTopCoord) {
        dropdownElement.scrollTop = Math.max(elementTopCoord, 0);
    }

    scrollDropdownDown(dropdownElement, elementBottomCoord) {
        const diff = elementBottomCoord - this.getDropdownBottomCoord(dropdownElement);
        dropdownElement.scrollTop += diff;
    }
}

Dropdown.defaultProps = {
    dropdownClass: '',
    dropdownInlineStyle: {}
};

Dropdown.propTypes = {
    renderFooter: PropTypes.func,
    setActiveSuggestionId: PropTypes.func,
    suggestions: PropTypes.array.isRequired,
    groups: PropTypes.array,
    listboxId: PropTypes.string.isRequired,
    zeroSuggestionsMessage: PropTypes.string.isRequired,
    dropdownClass: PropTypes.string,
    dropdownInlineStyle: PropTypes.string,
    isZeroSuggestionsVisible: PropTypes.bool
};

export default Dropdown;
