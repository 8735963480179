import React from 'react';
import PropTypes from 'prop-types';

export default class LazyImage extends React.PureComponent {
    render() {
        const { image } = this.props;

        return image ? (
            <img
                onLoad={this.props.onImgLoaded}
                className="lazyload"
                data-srcset={image.srcset}
				src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                alt={image.alt}
                data-sizes="auto"
            />
        ) : null;
    }
}
LazyImage.propTypes = {
    image: PropTypes.shape({
        srcset: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    onImgLoaded: PropTypes.func.isRequired
};
