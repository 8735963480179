import { toast } from 'react-toastify';

const DEFAULT_OPTIONS = {
    hideProgressBar: true,
    autoClose: false,
    newestOnTop: true,
};

export class Notification {
    constructor(options = {}) {
        this.options = { ...DEFAULT_OPTIONS, ...options };
    }

    default(content) {
        toast(content, this.options);
    }

    error(content) {
        toast.error(content, this.options);
    }

    warn(content) {
        toast.warn(content, this.options);
    }

    info(content) {
        toast.info(content, this.options);
    }

    success(content) {
        toast.success(content, this.options);
    }
}

export default (new Notification());
