import React from 'react';
import PropTypes from 'prop-types';

export default function BudgetMessage({ title, text }) {
    return (
        <div className="budget-calculator">
            <div className="budget-calculator__calculator">
                <div className="budget-message">
                    <h3 className="budget-message__title">{title}</h3>
                    <p className="budget-message__text">{text}</p>
                </div>
            </div>
        </div>
    );
}

BudgetMessage.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
};
