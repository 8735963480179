import React from 'react';
import PropTypes from 'prop-types';
import UrlHelper from 'general/js/url-helper';
import analyticsService from '@General/js/analytics-service.js';
import Listing from './listing.jsx';
import {
    FILTER_OWNERSHIP_RENT,
	FILTER_OWNERSHIP_OPSO,
    PATHNAME_SALE,
    PATHNAME_RENT,
	PATHNAME_OPSO,
    FILTER_TRACK_CATEGORY,
    FILTER_TRACK_ACTION,
    GA_EVENT_SEARCH_FILTER,
    GA_EVENT_SORTBY
} from './constants.js';
import {
    getDefaultFilters,
    getPathWithQueryString,
    getTrackLabelFromFilterObject,
} from './listing-helper.js';

export default function (Component, staticOptions = {}) {
    class LocationFiltersListing extends React.Component {
        constructor(props) {
            super(props);
            this.listing = React.createRef();
            const { filtersConfig } = this.props;
            this.defaultFilters = getDefaultFilters(filtersConfig);

            window.addEventListener('popstate', this.onPopstate.bind(this));
        }

        onPopstate() {
            this.getListing().setFilters(this.getFiltersFromLocation(), true, true);
        }

        getFiltersFromLocation() {
            return { ...this.defaultFilters, ...this.getFiltersByLocation(document.location) };
        }

        getListing() {
            return this.listing.current;
        }

        onFiltersChange = (filterObject) => {
            this._updateFilters(filterObject, false);
        };

        onFiltersApply = (filterObject, debounce = false) => {
            analyticsService._send(
                GA_EVENT_SORTBY,
                filterObject
            );
            this._updateFilters(filterObject, true, debounce);
            
        };

        onFiltersReset = (filterNames) => {
            const update = filterNames.reduce((result, filterName) => {
                result[filterName] = this.defaultFilters[filterName];
                return result;
            }, {});
            this._updateFilters(update);
        };

        onSearch = () => {
            analyticsService._send(
                GA_EVENT_SEARCH_FILTER,
                analyticsService.getSearchFilterData(this.getUpdatedFilters())
            );
            this._updateFilters();
        };

        getBaseUrl = (updatedFilters) => {
            if (window.location.pathname === PATHNAME_RENT || window.location.pathname === PATHNAME_OPSO) {
                // reset if we came from rent journey to keep existing logic working
                this.defaultFilters.ownershipType = '';
            }

            const customPath =
				updatedFilters.ownershipType === FILTER_OWNERSHIP_RENT ?
					PATHNAME_RENT :
					updatedFilters.ownershipType === FILTER_OWNERSHIP_OPSO ?
						PATHNAME_OPSO :
						PATHNAME_SALE;

            return getPathWithQueryString(
                window.location,
                Object.keys(this.defaultFilters),
                customPath
            );
        };

        _updateFilters(filterObject = {}, apply = true, debounce = false) {
            const passedFilters = this.getValidFilters(filterObject);
            this.getListing().updateFilters(passedFilters, apply, debounce);

            if (apply) {
                const updatedFilters = { ...this.getListing().getFilters(), ...passedFilters };
                const url = UrlHelper.getUrlByParams(
                    this.getBaseUrl(updatedFilters),
                    updatedFilters,
                    this.defaultFilters
                );

                analyticsService.sendEvent(
                    FILTER_TRACK_CATEGORY,
                    FILTER_TRACK_ACTION,
                    getTrackLabelFromFilterObject(updatedFilters)
                );
                window.history.pushState(updatedFilters, '', url);
            }
        }

        getUpdatedFilters(filterObject = {}, apply = true, debounce = false) {
            const passedFilters = this.getValidFilters(filterObject);
            this.getListing().updateFilters(passedFilters, apply, debounce);

            return  { ...this.getListing().getFilters(), ...passedFilters };
        }

        getInitialFilters() {
            return this.getFiltersFromLocation();
        }

        /**
         * We are parsing location but count only filters that we are aware of
         * @param location
         */
        getFiltersByLocation(location) {
            const search = UrlHelper.getSearchFromLocation(location);
            return this.getValidFilters(search);
        }

        getValidFilters(filters) {
            const result = {};
            for (const i in filters) {
                if (i in this.defaultFilters) {
                    result[i] = filters[i];
                }
            }
            return result;
        }

        render() {
            const { options } = this.props;

            return (
                <Listing
                    initialFilters={this.getInitialFilters()}
                    staticOptions={staticOptions}
                    options={options}
                    ref={this.listing}
                    render={({
                        isInitialRequestFulfilled,
                        filters,
                        appliedFilters,
                        items,
                        data,
                        pagination,
                        totalDevelopmentsCount,
                        totalPlotsCount,
                        isLoading,
                        isLoadingMore,
                        animatingContentRef,
                        innerComponentRef,
                        featuredComponent,
                    }) => (
                        <Component
                            {...this.props}
                            isInitialRequestFulfilled={isInitialRequestFulfilled}
                            filters={filters}
                            appliedFilters={appliedFilters}
                            items={items}
                            data={data}
                            pagination={pagination}
                            totalDevelopmentsCount={totalDevelopmentsCount}
                            totalPlotsCount={totalPlotsCount}
                            isLoading={isLoading}
                            isLoadingMore={isLoadingMore}
                            ref={innerComponentRef}
                            animatingContentRef={animatingContentRef}
                            onFiltersChange={this.onFiltersChange}
                            onFiltersApply={this.onFiltersApply}
                            onSearch={this.onSearch}
                            onFiltersReset={this.onFiltersReset}
                            featuredComponent={featuredComponent}
                        />
                    )}
                />
            );
        }
    }

    LocationFiltersListing.propTypes = {
        filtersConfig: PropTypes.array.isRequired,
        options: PropTypes.object.isRequired,
    };

    return LocationFiltersListing;
}
