import React from 'react';
import ReactDOM from 'react-dom';
import device, {
    DEVICE_TYPE_TABLET,
    DEVICE_TYPE_MOBILE
} from 'general/js/device';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import DevelopmentsSearch from './developments-search';
import viewport from "../../../general/js/viewport";
import item from './containers/developments-search-item'
export default class DevelopmentsSearchComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.searchOptions = {
            endpointUrl: this.options.searchUrl,
            items: this.options.items,
            pagination: this.options.pagination,
            mapConfig: this.options.map,
            zeroResults: this.options.zeroResults,
            hasExactMatches: this.options.hasExactMatches,
            noExactMatches: this.options.noExactMatches,
        };
        this.filtersConfig = this.options.filters;
        this.analyticsOptions = this.options.analyticsOptions;
        this.locationName = this.options.locationName;
    }

    static getNamespace() {
        return 'developments-search';
    }

    onViewportResize = () => {
        this._calculateViewportDate();
        this.render();
    };

    onDeviceTypeChange = () => {
        this._calculateDeviceTypeData();
        this.render();
    };

    _calculateViewportDate() {
        this.overlayPreloader = !device.isGreaterOrEqualCustomWidth(1400);
    }

    _calculateDeviceTypeData() {
        this.tabsMode = device.isViewportTypeLe(DEVICE_TYPE_TABLET);
        this.isFiltersMobile = device.isViewportTypeLt(DEVICE_TYPE_TABLET);
    }

    onInit() {
        this._calculateViewportDate();
        this._calculateDeviceTypeData();
        viewport.subscribeOnResize(this.onViewportResize);
        device.subscribeOnDeviceTypeChange(this.onDeviceTypeChange);
        this.getItemId()
        this.render();
       
    }
    getItemId(){
        console.log(item.id,'id item')
    }
  
    render() {
        ReactDOM.render(
            <DevelopmentsSearch
                filtersConfig={this.filtersConfig}
                options={this.searchOptions}
                tabsMode={this.tabsMode}
                isFiltersMobile={this.isFiltersMobile}
                overlayPreloader={this.overlayPreloader}
                analyticsOptions={this.analyticsOptions}
                locationName={this.locationName}
            />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
