import React from 'react';
import { useStore } from 'effector-react/compat';
import AnalyticsService from '@General/js/analytics-service.js';
import { useInteractiveSitePlanStore } from './use-interactive-site-plan-store.jsx';
import nanoId from 'nano-id';

const getFormattedPrice = (price) => {
    price = price.toString();

    if (price.length > 3) {
        const formattedPrice = price.split('').reduce((acc, val, i, arr) => {
            if (i === arr.length - 3) {
                return acc + ',' + val;
            }

            return acc + val;
        }, '');

        return formattedPrice;
    }

    return price;
};

const OpsoPrice = ({ price, label, tooltip, opsoAvailable, opsoAvailableText, renderId }) => (
    <>
        {opsoAvailable && (price !== '' && price !== null && price !== 0) && (
            <>
				{label}{tooltip && (<>
                    <div className="tooltip" data-tooltip>
                        <button aria-describedby={`tooltip_${renderId}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                        <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}`} role="tooltip">
                            <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                __html: tooltip,
                            }}></div>
                        </div>
                    </div>
                </>)}: <strong>{decodeHtml(price)}</strong>
            </>
        )}
		{opsoAvailable && (price === '' || price === null || price === 0) && (
			<p>{opsoAvailableText}</p>
		)}
    </>
);

const PurchasePrice = ({ price, opsoAvailable }) => (
    <>
        {!opsoAvailable && (price !== '' && price !== null && price !== 0) && (
            <p>
                Purchase price: <strong>{decodeHtml(price)}</strong>
            </p>
        )}
    </>
);

const RentalPrice = ({ price, opsoAvailable }) => (
    <>
        {!opsoAvailable && (price !== '' && price !== null && price !== 0) && (
            <p>
                Rental price: <strong>{decodeHtml(price)}</strong>
            </p>
        )}
    </>
);

const BedroomsAmount = ({ amount }) => (
    <>
        {amount === 1 && <p>{amount} bedroom</p>}
        {amount > 1 && <p>{amount} bedrooms</p>}
    </>
);

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

export default function PropertyPopup() {
    const [store, api] = useInteractiveSitePlanStore();
    const state = useStore(store);

    return (
        <div
            className="interactive-site-plan__overlay"
            onClick={(event) => {
                if (event.target === event.currentTarget) {
                    api.closePopupEvt();
                }
            }}
        >
            <div className="interactive-site-plan__popup">
                <h4 className="interactive-site-plan__popup-title">
					{state.popupArea.subTitle !== '' && (<span className="interactive-site-plan__popup-subtitle">{state.popupArea.subTitle}</span>)}
					<span>{state.popupArea.title}</span>
				</h4>
                <div className="interactive-site-plan__boxes">
                    <div className="interactive-site-plan__price-box">
						<OpsoPrice price={state.popupArea.opsoPrice} label={state.popupArea.opsoLabel} tooltip={state.popupArea.opsoTooltip} opsoAvailable={state.popupArea.opsoAvailable} opsoAvailableText={state.opsoAvailableText} renderId={nanoId()} />
						<PurchasePrice price={state.popupArea.purchasePrice} opsoAvailable = {state.popupArea.opsoAvailable} />
						<RentalPrice price={state.popupArea.rentalPrice} opsoAvailable = {state.popupArea.opsoAvailable} />
                    </div>
                    <div className="interactive-site-plan__info-box">
                        <BedroomsAmount amount={state.popupArea.bedroomAmount} />
                        <p>{state.popupArea.sqm} SQ.M</p>
                    </div>
                </div>
                <a
                    className="interactive-site-plan__popup-btn btn-secondary btn-secondary--green"
                    href={state.popupArea.href}
                    onClick={() => {
                        AnalyticsService.sendEvent(
                            'Interactive floor plan',
                            state.developmentName,
                            state.popupArea.title
                        );
                    }}
                >
                    View property
                </a>
                <button
                    className="interactive-site-plan__popup-close"
                    type="button"
                    aria-label="close popup"
                    onClick={api.closePopupEvt}
                >
                    <svg className="icon" width="15" height="15" focusable="false">
                        <use xlinkHref="#icon-cross"></use>
                    </svg>
                </button>
            </div>
        </div>
    );
}
