import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '@Components/spinner/js/spinner.jsx';
import AccordionComponent from '@Components/accordion/js/accordion.component';
import constants from '../constants.js';
import { withContext } from '../context.jsx';
import DevTabsList from './dev-tabs-list.jsx';
import SortingRow from './sorting-row.jsx';
import PropertyDisplay from './property-display.jsx';
import DevToggleButton from './dev-toggle-button.jsx';
import DevTablePagination from './dev-table-pagination.jsx';
import DevPaginationText from './dev-pagination-text.jsx';

class DevPropertyTable extends React.Component {
    constructor(props) {
        super(props);

        this.accordion = null;
        this.state = {
            isExpanded: true,
        };
    }

    accordionRef = (element) => {
        if (element) {
            if (this.accordion === null) {
                this.accordion = new AccordionComponent(element, {
                    onAfterClickCb: this.onAccordionChange,
                });
                this.accordion.init();
            }
        } else if (this.accordion !== null) {
            this.accordion.destroy();
            this.accordion = null;
        }
    };

    onAccordionChange = (accordion) => {
        this.setState({ isExpanded: !accordion._getIsExpanded() });
    };

    render() {
        const { salesTabVisibleColumns, rentTabVisibleColumns } = this.props.item;
        const displayProps = {
            salesTabVisibleColumns,
            rentTabVisibleColumns
        };
        return (
            <div className="dev-property-table is-expanded" ref={this.accordionRef}>
                <div className="dev-property-table__accordion-body" data-dc-accordion-ref="content">
                    {!this.props.isTextShowing && (
                        <>
                            <DevTabsList />
                            <div className="dev-property-table__tab-section">
                                <table className="dev-property-table__table">
                                    <SortingRow
                                        headings={constants.HEADINGS}
                                        sortTypes={constants.SORT_TYPES}
                                        tabDisplayOption={displayProps}
                                        activeTab={this.props.activeTab}
                                    />
                                    <PropertyDisplay tabDisplayOption={displayProps} showPercentagePricesOverride={this.props.item.development.showPercentagePricesOverride} />
                                </table>
                            </div>
                          
                        
                        </>
                    )}
                    {this.props.isTextShowing && (
                        <div className="dev-property-table__message">
                            <h4 className="dev-property-table__message-title">
                                {this.props.message.title}
                            </h4>
                            <p className="dev-property-table__message-text">
                                {this.props.message.text}
                            </p>
                        </div>
                    )}
                </div>
                <div className="dev-property-table__accordion-row">
                    <div className="dev-property-table__accordion-column dev-property-table__accordion-column--flex-shrink-0">
                        <DevTablePagination isExpanded={this.state.isExpanded} />
                    </div>
                    <div className="dev-property-table__accordion-column dev-property-table__accordion-column--left-space">
                        <DevPaginationText isExpanded={this.state.isExpanded} />
                    </div>
                    <div className="dev-property-table__accordion-column dev-property-table__accordion-column--left-space dev-property-table__accordion-column--flex-shrink-0">
                        <DevToggleButton />
                    </div>
                </div>
                {this.props.isGettingData && (
                    <div className="dev-property-table__spinner-container">
                        <Spinner isActive={true} />
                    </div>
                )}
            </div>
        );
    }
}

DevPropertyTable.propTypes = {
    isGettingData: PropTypes.bool,
    isTextShowing: PropTypes.bool,
    message: PropTypes.shape({
        text: PropTypes.string,
        title: PropTypes.string,
    }),
    item: PropTypes.shape({
        salesTabVisibleColumns: PropTypes.object,
        rentTabVisibleColumns: PropTypes.object,
    }),
    activeTab: PropTypes.shape({
        id: PropTypes.string,
        plotsId: PropTypes.string,
        displayName: PropTypes.string,
        textName: PropTypes.string,
        shortName: PropTypes.string,
    }),
};

export default withContext(DevPropertyTable, {
    isGettingData: 'isGettingData',
    isTextShowing: 'isTextShowing',
    message: 'message',
    activeTab: 'activeTab',
});
