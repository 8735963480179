import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ArticleTags from './article-tags';

export default class ArticleTagsComponent extends DcBaseComponent {
    static getNamespace() {
        return 'article-tags';
    }

    onInit() {
        ReactDOM.render(
            <ArticleTags {...this.options} />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
