import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class TimelineComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this.events = this.refs.events; // get events list
        this.expandButton = this.refs.expand; // get expand button
        this.expanded = false; // set contrcted as default
    }

    static getNamespace() {
        return 'timeline';
    }

    static getRequiredRefs() {
        return ['events', 'expand'];
    }

    onInit() {
        const eventItems = this.events.querySelectorAll('[data-timeline-item]'); // get timeline items

        if (eventItems.length > 3) { // if more than 3 items
            this.events.classList.add('timeline__time--contracted'); // show contracted view

            const itemCompleteIndex = this.events.querySelectorAll('[data-timeline-item="complete"]').length - 1; // get last completed item index

            this.events.querySelectorAll('[data-timeline-item]').forEach((item, itemIndex) => { // loop timeline items
                if (
                    (itemCompleteIndex < 1 && itemIndex > 2) // no/first item complete
                    ||
                    (itemCompleteIndex === eventItems.length - 1 && itemIndex < itemCompleteIndex - 2) // last item complete
                    ||
                    (itemCompleteIndex > 0 && itemCompleteIndex !== eventItems.length - 1 && (itemIndex < itemCompleteIndex - 1 || itemIndex > itemCompleteIndex + 1)) // mid timeline complete
                ) {
                    item.classList.add('timeline__event--expanded-only'); // hide from contracted view
                }
            });

            // expand button
            this.expandButton.expandText = this.expandButton.textContent; // set expanded button text
            this.expandButton.contractText = this.expandButton.getAttribute('data-timeline-contract-text'); // set contracted button text
            this.expandButton.classList.add('timeline__expand-button--active'); // show button
            this.expandButton.addEventListener('click', this.expandToggle.bind(this)); // add button click
        }
    }

    expandToggle() {
        if (this.expanded) { // if expanded
            this.events.classList.add('timeline__time--contracted'); // show contracted view
            this.expandButton.textContent = this.expandButton.expandText; // show expand button text
        } else {
            this.events.classList.remove('timeline__time--contracted'); // show expanded view
            this.expandButton.textContent = this.expandButton.contractText; // show contract button text
        }

        this.expanded = !this.expanded; // toggle expanded value
    }
}
