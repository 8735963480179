const isIosDeviceWithSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

export default function printHtmlElement() {
    function printElement(element, opts) {
        const elementHtml = element.outerHTML;

        _print(elementHtml, opts);
    }

    function printHtml(html, opts) {
        _print(html, opts);
    }

    function printMobileElem(htmlElement, styles) {
        const newWindow = window.open('', 'print', 'height=800, width=750');

        newWindow.document.write('<html><head><title>' + document.title + '</title>');
        newWindow.document.write('</head><body>');
        newWindow.document.write(styles);
        newWindow.document.write(htmlElement);
        newWindow.document.write('</body></html>');

        newWindow.document.close(); // necessary for IE >= 10
        newWindow.focus(); // necessary for IE >= 10*/

        newWindow.print();
    }

    function _print(html, opts) {
        opts = opts || {};
        opts = {
            printMode: opts.printMode || '',
            pageTitle: opts.pageTitle || '',
            templateString: opts.templateString || '',
            popupProperties: opts.popupProperties || '',
            closePopup: opts.closePopup || true,
            stylesheets: opts.stylesheets || null,
            styles: opts.styles || null
        };

        const markup = _getMarkup(html, opts);
        let printWindow;
        let printIframe;
        let printDocument;
        let printElementID;

        if (opts.printMode.toLowerCase() === 'popup') {
            printWindow = window.open('about:blank', 'printElementWindow', opts.popupProperties);
            printDocument = printWindow.document;
        } else {
            printElementID = 'printElement_' + (Math.round(Math.random() * 99999)).toString();

            printIframe = document.createElement('iframe');
            printIframe.setAttribute('id', printElementID);
            printIframe.setAttribute('src', 'about:blank');
            printIframe.setAttribute('scrolling', 'no');
            printIframe.setAttribute('style', 'display:block;position:fixed;bottom:100%;right:100%;border:none;');

            document.body.appendChild(printIframe);

            printDocument = (printIframe.contentWindow || printIframe.contentDocument);
            if (printDocument.document) {
                printDocument = printDocument.document;
            }

            printIframe = document.frames ? document.frames[printElementID] : document.getElementById(printElementID);
            printWindow = printIframe.contentWindow || printIframe;
        }

        focus();
        printDocument.open();
        
        setTimeout(() => {            
            printDocument.write(markup);
            printDocument.close();
        }, 100);

        _callPrint(printWindow, printIframe);
    }

    function _callPrint(printWindow, iframe) {
        if (printWindow && printWindow.printPage) {
            printWindow.printPage();

            if (iframe && !isIosDeviceWithSafari) {
                setTimeout(() => {                    
                    document.body.removeChild(iframe);
                }, 100);
            }
        } else {
            setTimeout(() => {
                _callPrint(printWindow, iframe);
            }, 50);
        }
    }

    function _getBaseHref() {
        const port = (window.location.port) ? ':' + window.location.port : '';
        return window.location.protocol + '//' + window.location.hostname + port + window.location.pathname;
    }

    function _getMarkup(elementHtml, opts) {
        const template = opts.templateString;
        const templateRegex = new RegExp(/{{\s*printBody\s*}}/gi);

        const html = [];
        let stylesheets;
        let styles;

        if (template && templateRegex.test(template)) {
            elementHtml = template.replace(templateRegex, elementHtml);
        }

        html.push('<html><head><title>' + (opts.pageTitle || '') + '</title>');

        if (opts.stylesheets) {
            stylesheets = Array.isArray(opts.stylesheets) ? opts.stylesheets : [opts.stylesheets];
        } else {
            stylesheets = Array.prototype.slice
                .call(document.getElementsByTagName('link'))
                .map((link) => link.href);
        }

        stylesheets.forEach((href) => {
            if (href.includes('css')) {
                html.push('<link type="text/css" rel="stylesheet" href="' + href + '">');
            } else if (href.includes('.ico')) {
                html.push('<link rel="icon" href="' + href + '">');
            } else if (href.includes('font')) {
                html.push('<link as="font" type="font/woff2" href="' + href + '">');
            }
        });

        if (opts.styles) {
            styles = Array.isArray(opts.styles) ? opts.styles : [opts.styles];
        } else {
            styles = Array.prototype.slice
                .call(document.getElementsByTagName('style'))
                .map((style) => {
                    return style.innerHTML;
                });
        }

        styles.forEach((style) => {
            if (!isIosDeviceWithSafari) {
                html.push('<style>' + style + '</style>');
            }
        });

        html.push('<base href="' + _getBaseHref() + '" />');
        html.push('</head><body class="pe-body">');
        html.push(elementHtml);

        const nonce = window.nonce || '';

        if (isIosDeviceWithSafari) {
            html.push(`<script nonce=${nonce} type="text/javascript">function printPage(){focus();document.execCommand("print", false, null);
            ${opts.printMode.toLowerCase() === 'popup' && opts.closePopup ? 'close();' : ''}}</script>`);
        } else {
            html.push(`<script nonce=${nonce} type="text/javascript">function printPage(){focus();print()`
                + ((opts.printMode.toLowerCase() === 'popup') ? 'setTimeout(() => {close();}, 1000)' : '') + '}</script>');
        }

        html.push('</body></html>');
        return html.join('');
    }

    return {
        printElement, printHtml, printMobileElem
    };
}
