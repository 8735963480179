import React from 'react';
import PropTypes from 'prop-types';

class DevelopmentsSearchSorting extends React.PureComponent {
    render() {
        const { sortConfig, filters, onFiltersApply } = this.props;
        const name = sortConfig.name;
        const value = filters[name];
        const label = sortConfig.label;
        const currentOption = sortConfig.options.find(option => option.value === value);
        const currentOptionLabel = currentOption && currentOption.text;
        return (
            <div className="developments-search__sorting">
                <label className="developments-search__sorting-label" htmlFor={label}>
                    {label}:
                </label>
                <div className="developments-search__sorting-select">
                    <div className="developments-search__sorting-select-value">
                        {currentOptionLabel}
                    </div>
                    <svg className="icon" width="14" height="7" focusable="false" role="img" aria-hidden="true">
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                    <select
                        id={label}
                        value={value}
                        onChange={e => onFiltersApply({ [name]: e.target.value })}
                    >
                        {sortConfig.options.map((option, index) => (
                            <option key={index} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    }
}

DevelopmentsSearchSorting.propTypes = {
    sortConfig: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired
};

export default DevelopmentsSearchSorting;
