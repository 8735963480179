import React from 'react';
import PropTypes from 'prop-types';
import SlickCarousel from 'general/js/slick-carousel/js/slick-carousel';
import { DEVICE_TYPE_DESKTOP, DEVICE_TYPE_TABLET } from 'general/js/device';
import SlotButton from './slot-button';

class DaysCarousel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.carouselOptions = {
            infinite: false,
            variableWidth: true,
            slidesToScroll: 3,
            zIndex: 1,
            arrows: true,
            dots: false,
            swipe: true,
            prevArrow: `<button type="button" class="btn time-slot-picker__arrow-button time-slot-picker__arrow-button--prev" aria-label="Previous Slide">
                            <svg width="8" height="14" class="icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-left-thin"></use>
                            </svg>
                        </button>`,
            nextArrow: `<button type="button" class="btn time-slot-picker__arrow-button time-slot-picker__arrow-button--next" aria-label="Next Slide">
                            <svg width="8" height="14" class="icon" focusable="false" role="img" aria-hidden="true">
                                <use xlink:href="#icon-arrow-right-thin"></use>
                            </svg>
                        </button>`
        };

        this.carouselResponsiveOptions = {
            [DEVICE_TYPE_TABLET]: {
                slidesToScroll: 5
            },
            [DEVICE_TYPE_DESKTOP]: {
                variableWidth: false,
                slidesToShow: 7
            }
        };
    }

    render() {
        const { days, onDayClick, selectedDate } = this.props;

        return (
            <SlickCarousel
                slides={days}
                rootClass="time-slot-picker__days-carousel"
                options={this.carouselOptions}
                responsiveOptions={this.carouselResponsiveOptions}
            >
                {days.map(day => (
                    <div className="time-slot-picker__days-slide" key={day.date}>
                        <SlotButton
                            mixinClass="day-slot"
                            onClick={() => onDayClick(day.date)}
                            disabled={!day.isAvailable}
                            selected={selectedDate === day.date}
                        >
                            <div className="day-slot__date">{day.number}</div>
                            <div className="day-slot__weekday">{day.name}</div>
                        </SlotButton>
                    </div>
                ))}
            </SlickCarousel>
        );
    }
}

DaysCarousel.propTypes = {
    days: PropTypes.array.isRequired,
    selectedDate: PropTypes.string,
    onDayClick: PropTypes.func.isRequired
};

export default DaysCarousel;
