import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FILTER_NAME_PRICE, FILTER_NAME_RENT, FILTER_NAME_OPSO } from '@Components/campaign-property-search/js/constants';
import { CampaignMobileFilterContext } from '@Components/campaign-property-search/js/context';
import MobileFiltersItem from './mobile-filters-item';

const MobileFiltersModal = (props) => {
    const {
        setIsModalOpened,
        filterOptions,
        setFilterOptions,
        setRadiobuttonValue
    } = useContext(CampaignMobileFilterContext);
    const rootRef = React.createRef();

    const onSubmit = (e) => {
        e.preventDefault();
    };

    const onCloseClick = () => setIsModalOpened(false);

    const {
        isOpened,
        filtersConfig,
        campaignSearchUrl,
        onSearch,
        initialPayloadState
    } = props;

    const handleOnResultsClick = () => {
        onCloseClick();
        onSearch(campaignSearchUrl, filterOptions);
    };

    const handleClearFilters = () => {
        if (filterOptions.searchRadius !== '') {
            setFilterOptions({
                ...initialPayloadState,
                sort: filterOptions.sort,
                searchRadius: '30'
            });
        } else {
            setFilterOptions({
                ...initialPayloadState,
                sort: filterOptions.sort,
                searchRadius: ''
            });
        }

        setRadiobuttonValue('');
    };

    return (
        <div
            className={classNames({
                'campaign-mobile-filters__modal': true,
                'is-opened': isOpened,
            })}
            ref={rootRef}
            tabIndex={-1}
        >
            <form
                onSubmit={onSubmit}
                className="campaign-mobile-filters__modal-inner"
            >
                <div className="campaign-mobile-filters__content">
                    <button
                        onClick={onCloseClick}
                        type="button"
                        aria-label="Close filters"
                        className="btn campaign-mobile-filters__close-button"
                    >
                        <svg
                            width="16"
                            height="16"
                            className="icon"
                            focusable="false"
                            role="img"
                            aria-hidden="true"
                        >
                            <use xlinkHref="#icon-cross" />
                        </svg>
                    </button>
                    {filtersConfig.map((config) => {
                        if (
                            config.name !== FILTER_NAME_PRICE &&
                            config.name !== FILTER_NAME_RENT &&
							config.name !== FILTER_NAME_OPSO
                        ) {
                            return (
                                config.name !== 'sort' && <MobileFiltersItem
                                    allFilters={filtersConfig}
                                    key={config.name}
                                    config={config}
                                />
                            );
                        }
                        return false;
                    })}
                </div>
                <div className="campaign-mobile-filters__footer">
                    <button
                        type="submit"
                        aria-label="Show results"
                        className="btn campaign-mobile-filters__clear-button"
                        onClick={handleClearFilters}
                    >Clear all</button>
                    <button
                        type="submit"
                        aria-label="Show results"
                        className="btn campaign-mobile-filters__apply-button"
                        onClick={handleOnResultsClick}
                    >
                        Show results
                    </button>
                </div>
            </form>
        </div>
    );
};

MobileFiltersModal.defaultProps = {};

MobileFiltersModal.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onApply: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
    initialPayloadState: PropTypes.object,
};

export default MobileFiltersModal;
