import React from 'react';
import PropTypes from 'prop-types';
import Scroller from '../../../../general/js/scroller';
import DevelopmentsSearchItemPopup from '../components/developments-search-item-popup';
import { withContext } from '../context';
import DevelopmentSearchCard from '../components/development-search-card';

class DevelopmentsSearchItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.rootRef = React.createRef();
        this.state = {
            isMarkerActive: false
        };
    }

    onMarkerStateChange = isActive => {
        this.setState({ isMarkerActive: isActive });
        const { tabsMode } = this.props;
        if (!tabsMode && isActive) {
            new Scroller(this.rootRef.current, { duration: 400 }).shortestScrollTo();
        }
    };


    render() {
        const { isMarkerActive } = this.state;
        const { item, marker } = this.props;

        return (
            <div
                className={`developments-search__item `}
                ref={this.rootRef}>
                <DevelopmentSearchCard item={item} isHighlighted={isMarkerActive}/>
                <DevelopmentsSearchItemPopup
                    item={item}
                    marker={marker}
                    onMarkerStateChange={this.onMarkerStateChange}
                />
            </div>
        );
    }
    getItemId(){
        console.log(this.props.item.id,'id item')
    }
}

DevelopmentsSearchItem.propTypes = {
    marker: PropTypes.object,
    item: PropTypes.object.isRequired,
    tabsMode: PropTypes.bool.isRequired
};

export default withContext(DevelopmentsSearchItem, {
    tabsMode: 'tabsMode'
});
