import utils from './utils';

export default class Loader {
    constructor({ loader }) {
        if (loader) {
            this.loader = loader;
        } else {
            console.error('Wrong refs for image-mapper loader');
        }
    }

    hide = () => {
        utils.hide(this.loader);
        return this;
    };

    show = () => {
        utils.show(this.loader);
        return this;
    };
}
