import Modal from '../modal';

export default class AbstractVideo {
    constructor({ playerElement, inline = true, onExternalStop = null, isEagerInit = false }) {
        this.playerElement = playerElement;
        this.inline = inline;
        this.onExternalStop = onExternalStop;
        this.isEagerInit = isEagerInit;

        this._modalInstance = null;

        this.onInitSubscribers = [];
        this.onStartSubscribers = [];
        this.onPlaySubscribers = [];
        this.onPauseSubscribers = [];

        // video is starting via public method
        this.isStarting = false;
        // video is playing now
        this.isPlaying = false;
        // player is loaded, UI is ready
        this.isInited = false;
        // video has been started
        this.isStarted = false;
    }

    init() {
        // override init logic in descendants
    }

    getPlayerElement() {
        return this.playerElement;
    }

    async play() {
        if (!this.isPlaying && !this.isStarting) {
            this.isStarting = true;
            try {
                if (this.inline) {
                    await this._playInline();
                } else {
                    await this._playModal();
                }
                this.isStarting = false;
            } catch (e) {
                this.isStarting = false;
                throw e;
            }
        }
    }

    pause() {
        // we should not check this.isInited or this.isIsPlaying because it is possible we call
        // this method before player is loaded
        // (for example when we close modal during player initialization)
        if (this.inline) {
            this._pauseInline();
        } else {
            this._pauseModal();
        }
    }

    async _playInline() {
        await this._triggerPlay();
    }

    _pauseInline() {
        this._triggerPause();
    }

    async _playModal() {
        await this.getModal().open();
        await this._triggerPlay();
    }

    _onModalClose = () => {
        this._triggerPause();
        this._handleOnExternalStop();
    };

    _pauseModal() {
        const modal = this.getModal();
        this._triggerPause();
        modal.close();
    }

    getModal() {
        if (this._modalInstance === null) {
            this._modalInstance = new Modal(this.getPlayerElement(), {
                cssModifier: 'modal--video',
                onClose: this._onModalClose,
                width: '100%',
            });
        }

        return this._modalInstance;
    }

    async _triggerPlay() {
        await this.handlePlay();
    }

    _triggerPause() {
        this.handlePause();
    }

    _handleOnExternalStop() {
        if (this.onExternalStop) {
            this.onExternalStop();
        }
    }

    onInit(cb) {
        if (this.isInited) {
            cb();
        } else {
            this.onInitSubscribers.push(cb);
        }
    }

    emitInit() {
        if (!this.isInited) {
            this.isInited = true;
            this.onInitSubscribers.forEach((cb) => cb());
        }
    }

    onStart(cb) {
        if (this.isStarted) {
            cb();
        } else {
            this.onStartSubscribers.push(cb);
        }
    }

    emitStart() {
        if (!this.isStarted) {
            this.isStarted = true;
            this.onStartSubscribers.forEach((cb) => cb());
        }
    }

    onPlay(cb, invokeImmediately = false) {
        this.onPlaySubscribers.push(cb);
        if (invokeImmediately && this.isPlaying) {
            cb();
        }

        return () => {
            this.onPlaySubscribers = this.onPlaySubscribers.filter((item) => item !== cb);
        };
    }

    emitPlay() {
        this.isPlaying = true;
        this.onPlaySubscribers.forEach((cb) => cb());
    }

    onPause(cb, invokeImmediately = false) {
        this.onPauseSubscribers.push(cb);
        if (invokeImmediately && this.isStarted && !this.isPlaying) {
            cb();
        }
        return () => {
            this.onPauseSubscribers = this.onPauseSubscribers.filter((item) => item !== cb);
        };
    }

    emitPause() {
        this.isPlaying = false;
        this.onPauseSubscribers.forEach((cb) => cb());
    }

    async handlePlay() {
        throw new Error('Method AbstractVideo::play has to be implemented');
    }

    handlePause() {
        throw new Error('Method AbstractVideo::play has to be implemented');
    }

    async getThumbnailUrl() {
        return null;
    }

    getDefaultImageTitle() {
        return 'Video preview';
    }

    getThumbnailOnLoadFunction()  { return null; }
}
