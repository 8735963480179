import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ArticlePageHeader from './article-page-header';

export default class ArticlePageHeaderComponent extends DcBaseComponent {
    static getNamespace() {
        return 'article-page-header';
    }

    onInit() {
        ReactDOM.render(
            <ArticlePageHeader {...this.options} />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
