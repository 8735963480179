import '../scss/index.scss';
import constants from 'general/js/constants';
import contentBlocker from '../../../general/js/content-blocker';
import overlay from '../../../general/js/overlay/js/default-overlay';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import DropdownNavigation from './dropdown-navigation';
import Search from './search';
import MobileMenu from './mobile-menu';
import PhoneMenu from './phone-menu';
import scrollbarHandler from '../../../general/js/scrollbar-handler';

const MENU_ID_SEARCH = 'search';
const MENU_ID_DROPDOWN_NAV = 'dropdown';
const MENU_ID_MOBILE_MENU = 'mobile-menu';
const MENU_ID_PHONE_MENU = 'phone-menu';
const IS_OPENED_CLASS = 'is-opened';

export default class Header extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.menuCollection = {};
        this.openedMenuId = null;
    }

    static getNamespace() {
        return 'header';
    }

    static getRequiredRefs() {
        return [
            'dropdownNavTriggers',
            'dropdownContent',
            'searchTrigger',
            'searchContent',
            'searchTriggerWrapper',
            'mobileSearchMenuCloseTrigger',
            'mobileMenuTrigger',
            'mobileMenuContent',
            'mobileMenuCloseTrigger',
            'phoneMenuTrigger',
            'quickSearch',
        ];
    }

    onInit() {
        const dropdownNavigation = new DropdownNavigation(MENU_ID_DROPDOWN_NAV, {
            trigger: this.refs.dropdownNavTriggers,
            content: this.refs.dropdownContent,
        });
        dropdownNavigation.bindTo(this);

        const search = new Search(MENU_ID_SEARCH, {
            trigger: this.refs.searchTrigger,
            content: this.refs.searchContent,
            triggerWrapper: this.refs.searchTriggerWrapper,
            closeTrigger: this.refs.mobileSearchMenuCloseTrigger,
            quickSearchElement: this.refs.quickSearch,
        });
        search.bindTo(this);
        const mobileMenu = new MobileMenu(MENU_ID_MOBILE_MENU, {
            trigger: this.refs.mobileMenuTrigger,
            content: this.refs.mobileMenuContent,
            closeTrigger: this.refs.mobileMenuCloseTrigger,
        });
        mobileMenu.bindTo(this);
        this.initPhoneMenu();
    }

    initPhoneMenu = () => {
        const trigger = this.refs.phoneMenuTrigger;
        // dc-factory doesn't look outside of base element, so i'm using querySelector
        const content = document.querySelector(
            `[data-dc-${this.getNamespace()}-ref="phoneMenuContent"]`
        );
        const closeTrigger = document.querySelector(
            `[data-dc-${this.getNamespace()}-ref="phoneMenuCloseTrigger"]`
        );
        const phoneLinks = [
            ...document.querySelectorAll(
                `[data-dc-${this.getNamespace()}-ref="phoneMenuNumbers[]"]`
            ),
        ];

        if (trigger && content && closeTrigger) {
            const phoneMenu = new PhoneMenu(MENU_ID_PHONE_MENU, {
                trigger,
                content,
                closeTrigger,
                phoneLinks,
            });
            phoneMenu.bindTo(this);
        }
    };

    onMenuRegister(menu) {
        this.menuCollection[menu.getId()] = menu;
    }

    handleMenuOpening(id) {
        return new Promise((resolve, reject) => {
            // here can be some specific logic depended on current menu id and new menu id
            // for now we've got simple logic
            if (this.openedMenuId !== null && this.openedMenuId !== id) {
                this.getMenu(this.openedMenuId)
                    .close()
                    .then(
                        () => {
                            resolve();
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            } else {
                resolve();
            }
        }).then(() => {
            this.openedMenuId = id;
            this.element.classList.add(IS_OPENED_CLASS);
            if (scrollbarHandler.hasScrollBar()) {
                this.element.style.paddingRight =
                    parseFloat(scrollbarHandler.getScrollbarSize()) + 'px';
            }
            this.blockScreen();
        });
    }

    async onMenuClosed(id) {
        await this.unblockScreen(id);
        if (this.openedMenuId && this.openedMenuId === id) {
            setTimeout(() => {
                if (!this.openedMenuId) {
                    this.element.style.paddingRight = null;
                    this.element.classList.remove(IS_OPENED_CLASS);
                }
            }, constants.DEFAULT_TRANSITION_DURATION);
            this.openedMenuId = null;
        }
    }

    blockScreen() {
        contentBlocker.block(this.openedMenuId);
        overlay.show(this.openedMenuId);
    }

    async unblockScreen(id) {
        return new Promise((resolve) =>
            setTimeout(() => {
                contentBlocker.unblock(id);
                overlay.hide(id);
                resolve();
            }, constants.DEFAULT_TRANSITION_DURATION)
        );
    }

    getMenu(id) {
        return this.menuCollection[id];
    }
}
