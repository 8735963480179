import React from 'react';
import PropTypes from 'prop-types';
import PropertiesSearchMobileFilters from '../containers/properties-search-mobile-filters';
import PropertiesSearchDesktopFilters from '../containers/properties-search-desktop-filters';

class PropertiesSearchFilters extends React.PureComponent {
    render() {
        const { filtersConfig, isFiltersMobile } = this.props;

        return (
            <div className="properties-search__filters">
                {isFiltersMobile ?
                    <PropertiesSearchMobileFilters filtersConfig={filtersConfig}/> :
                    <PropertiesSearchDesktopFilters filtersConfig={filtersConfig} isStretched={true}/>
                }
            </div>
        );
    }
}

PropertiesSearchFilters.propTypes = {
    isFiltersMobile: PropTypes.bool.isRequired,
    filtersConfig: PropTypes.array.isRequired,
};

export default PropertiesSearchFilters;
