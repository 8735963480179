import DcBaseComponent from 'general/js/dc/dc-base-component';
import FormComponent from '../../form/js/form.component';
import FormWizardStep from "./form-wizard-step";
import ElementSpinner from "general/js/element-spinner";
import constants from "general/js/constants";

export default class FormWizardComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        /**
         * @type {FormWizardStep[]}
         */
        this._steps = [];
        this._currentStepIndex = null;
        this.spinner = new ElementSpinner(this.element, { isFaded: true });
    }

    static getNamespace() {
        return 'form-wizard';
    }

    static getRequiredRefs() {
        return ['form', 'steps'];
    }

    onInit() {
        // add listener to form first in order to be able to prevent event propagation in some cases
        this.refs.form.addEventListener('submit', this._onSubmit);

        this.form = new FormComponent(this.refs.form);
        this.form.init();

        this._steps = this.refs.steps.map((stepElement, index) => {
            const step = new FormWizardStep(stepElement, {
                    index,
                    onContinue: this._onContinue,
                    onBack: this._onBack
                }
            );
            step.init();
            return step;
        });

        if (this._steps.length > 0) {
            this._initStartStep();
        }

        this.element.addEventListener(constants.EVENT_FORM_VALIDATION_FAILED, this._onFormValidationError);
    }

    _initStartStep() {
        this._setStep(0);
    }

    /**
     * @return {FormWizardStep}
     * @private
     */
    _getCurrentStep() {
        return this._currentStepIndex !== null ? this._steps[this._currentStepIndex] : null;
    }

    _canGoToStep(nextStepIndex, highlight = false) {
        const promises = [this._isFormStepValid(nextStepIndex, highlight)];

        const currentStepInstance = this._getCurrentStep();
        if (currentStepInstance) {
            const currentStepValidity = currentStepInstance.isValid();
            if (currentStepValidity) {
                promises.push(currentStepValidity);
            }
        }
        return Promise.all(promises);
    }

    _isFormStepValid(newStepIndex, highlight = false) {
        const previousSteps = this._steps.filter((step) => step.getIndex() < newStepIndex);
        if (previousSteps.length > 0) {
            const promises = [];
            previousSteps.forEach((step) => {
                let stepPromise;
                if (highlight) {
                    stepPromise = this.form.getParsleyForm().whenValidate({ group: step.getGroup() });
                } else {
                    stepPromise = this.form.getParsleyForm().whenValid({ group: step.getGroup() });
                }
                promises.push(stepPromise);
            });
            return Promise.all(promises);
        }

        return Promise.resolve();
    }

    _onContinue = () => {
        this._continue();
    };

    _onBack = () => {
        this._back();
    };

    _startLoading() {
        this.spinner.show();
    }

    _stopLoading() {
        this.spinner.hide();
    }

    _continue() {
        const nextStepIndex = this._currentStepIndex + 1;
        this._goTo(nextStepIndex);
    }

    _back() {
        const nextStepIndex = this._currentStepIndex - 1;
        this._goTo(nextStepIndex);
    }

    _goTo(nextStepIndex) {
        this._startLoading();
        this._canGoToStep(nextStepIndex, true).then(() => {
            this._setStep(nextStepIndex);
            this._stopLoading();
        }, (error) => {
            this._stopLoading();
        });
    }

    _onNavigate = (newStepIndex) => {
        this._canGoToStep(newStepIndex).then(() => {
            this._setStep(newStepIndex);
        }, (error) => {
        });
    };

    _onSubmit = (e) => {
        // submit is allowed only from the last step
        // in other cases, e.g user clicks on Enter must not trigger submit
        if (this._currentStepIndex !== null && this._currentStepIndex !== (Object.keys(this._steps).length - 1)) {
            e.preventDefault();
            e.stopImmediatePropagation();
            this.continue();
        }
    };

    _onFormValidationError = ({ failedElements }) => {
        if (failedElements.length > 0) {
            const firstFailedElement = failedElements[0];
            const targetStepIndex = this._steps.findIndex(step => step.getElement().contains(firstFailedElement));
            if (targetStepIndex !== -1) {
                this._setStep(targetStepIndex);
            }
        }
    };

    _setStep(newStepIndex) {
        if (this._currentStepIndex !== newStepIndex) {
            const currentStep = this._getCurrentStep();
            if (currentStep) {
                currentStep.hide()
            }
            const nextStep = this._steps[newStepIndex];
            nextStep.show();
            this._currentStepIndex = newStepIndex;
            this._steps.forEach(step => step.afterStepChange(newStepIndex));
        }
    }
}
