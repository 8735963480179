import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import QuickSearchMini from './quick-search-mini';

export default class QuickSearchMiniComponent extends DcBaseComponent {
    static getNamespace() {
        return 'quick-search-mini';
    }

    onInit() {
        const {
            defaultSearchUrl,
            predictiveSearchOptions
        } = this.options;

        ReactDOM.render(
            <QuickSearchMini
                defaultSearchUrl={defaultSearchUrl}
                predictiveSearchOptions={predictiveSearchOptions}
            />,
            this.element
        );
    }
}
