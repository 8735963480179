import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context.jsx';
import {
    PROPERTY_TYPE_APARTMENT,
    PROPERTY_TYPE_BUNGALOW,
    PROPERTY_TYPE_COTTAGE,
    OWNERSHIP_TYPE_SALE,
    OWNERSHIP_TYPE_RENT,
	OWNERSHIP_TYPE_OPSO,
} from '../constants.js';

class DevelopmentsSearchDescriptionUpdater extends React.PureComponent {
    getNumberOfBedroomString = () => {
        const { numberOfBedrooms } = this.props.appliedFilters;

        switch (numberOfBedrooms.length) {
            case 0:
                return '';
            case 1:
                return `${numberOfBedrooms[0]} bedroom `;
            case 2:
                return `${numberOfBedrooms[0]} and ${numberOfBedrooms[1]} bedroom `;
            case 3:
                return `${numberOfBedrooms[0]}, ${numberOfBedrooms[1]} and ${numberOfBedrooms[2]} bedroom `;
            default:
                return '';
        }
    };

    getPropertyString = () => {
        const { propertyTypes } = this.props.appliedFilters;

        if (propertyTypes.length === 1) {
            switch (propertyTypes[0]) {
                case PROPERTY_TYPE_APARTMENT:
                    return 'apartments';
                case PROPERTY_TYPE_BUNGALOW:
                    return 'bungalows';
                case PROPERTY_TYPE_COTTAGE:
                    return 'cottages';
                default:
                    return 'properties';
            }
        }

        return 'properties';
    };

    getOwnershipString = () => {
        const { appliedFilters, filtersConfig } = this.props;
        const { ownershipType } = appliedFilters;

        const ownershipConfig = filtersConfig
            .find((filter) => filter.name === 'ownershipType')
            .options.find((option) => option.value === ownershipType);

        if (
            ownershipConfig.dependentFilters.length > 0 &&
            appliedFilters[ownershipConfig.dependentFilters[0]]
        ) {
            const dependentText = filtersConfig
                .find((filter) => filter.name === ownershipConfig.dependentFilters[0])
                .options.find(
                    (option) => option.value === appliedFilters[ownershipConfig.dependentFilters[0]]
                ).text;

            return `${ownershipConfig.inlineText} under ${dependentText}`;
        }

        if (
            ownershipConfig.value === OWNERSHIP_TYPE_SALE ||
            ownershipConfig.value === OWNERSHIP_TYPE_RENT
        ) {
            return ownershipConfig.inlineText;
        }
		
		if (
            ownershipConfig.value === OWNERSHIP_TYPE_OPSO
        ) {
            return ownershipConfig.inlineText;
        }

        return 'Buy or Rent';
    };
	
	getOwnershipPrefixString = () => {
        const { appliedFilters, filtersConfig } = this.props;
        const { ownershipType } = appliedFilters;

        const ownershipConfig = filtersConfig
            .find((filter) => filter.name === 'ownershipType')
            .options.find((option) => option.value === ownershipType);
		
		if (
            ownershipConfig.value === OWNERSHIP_TYPE_OPSO
        ) {
            return 'for';
        }

        return 'to';
    };

    updateDescription = () => {
        try {
            const container = document.querySelector('#developments-search-description-text');
            const text =
                this.getNumberOfBedroomString() +
                this.getPropertyString() +
                ' available ' +
				this.getOwnershipPrefixString() + ' ' +
                this.getOwnershipString();
            container.innerText = text.charAt(0).toUpperCase() + text.slice(1);
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        this.updateDescription();

        return null;
    }
}

DevelopmentsSearchDescriptionUpdater.propTypes = {
    appliedFilters: PropTypes.object,
    filtersConfig: PropTypes.object,
};

export default withContext(DevelopmentsSearchDescriptionUpdater, {
    appliedFilters: 'appliedFilters',
    filtersConfig: 'filtersConfig',
});
