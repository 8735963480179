import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class CarouselComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        // elements
        this.expandableComponent = this.element;
        this.toggleButton = this.refs.toggleButton;
        this.expander = this.refs.expander;
        this.content = this.refs.content;

        // variables
        this.animating = false;
        this.expanded = false;
        this.speed = 500;

        // functions
        this.toggleExpander = this.toggleExpander.bind(this);
    }

    static getNamespace() {
        return 'expandable-card';
    }

    static getRequiredRefs() {
        return ['content', 'expander', 'toggleButton'];
    }

    onInit() {
        this.expandableComponent.classList.add('expandable-card--active');
        this.toggleButton.addEventListener('click', this.toggleExpander);
    }

    toggleExpander() {
        if (!this.animating) {
            this.animating = true;
            this.expandableComponent.classList.add('expandable-card--animating');

            if (this.expanded) {
                this.expander.style.height = `${this.content.clientHeight}px`;
                this.expandableComponent.classList.remove('expandable-card--expanded');

                setTimeout(() => {
                    this.expander.removeAttribute('style');
                }, 0);
            } else {
                this.expander.style.height = `${this.content.clientHeight}px`;
                this.expandableComponent.classList.add('expandable-card--expanded');

                setTimeout(() => {
                    this.expander.removeAttribute('style');
                }, this.speed);
            }

            setTimeout(() => {
                this.animating = false;
                this.expandableComponent.classList.remove('expandable-card--animating');
            }, this.speed);

            this.expanded = !this.expanded;
        }
    }
}
