import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { devSearchTypeItem } from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import DevPropertiesTableDataContainer from '@Components/dev-properties-table/js/dev-property-table-data-container';

import DevSectionFlags from './dev-section-flags.jsx';
import DevSectionPlot from './dev-section-plot.jsx';
import DevSectionGallery from './dev-section-gallery.jsx';
import DevSectionIcons from './dev-section-icons.jsx';

export default function DevSectionCard(props) {
    return (
        <div
            className={classNames({
                'dev-section-card': true,
                'dev-section-card--layout': true,
                'is-highlighted': props.isHighlighted,
            })}
			data-dev-min-sales-price={props.item.development.salesPrice}
			data-dev-max-sales-price={props.item.development.maximumSalesPrice}
			data-dev-min-opso-price={props.item.development.opsoSalesPrice}
			data-dev-max-opso-price={props.item.development.opsoMaximumSalesPrice}
			data-dev-min-rent-price={props.item.development.rentalPrice}
			data-dev-max-rent-price={props.item.development.maximumRentalPrice}
        >
            <div className="dev-section-card__row-one">
                <DevSectionGallery gtmSelector={props.gtmSelector} item={props.item.development} />
                <DevSectionFlags item={props.item.development} />
                <DevSectionIcons item={props.item.development} />
            </div>
            <div className="dev-section-card__row-two">
                <DevSectionPlot gtmSelector={props.gtmSelector} item={props.item.development} />
            </div>
            {props.item.development.statusText && (
                <div className="dev-section-card__status-label">
                    {props.item.development.statusText}
                </div>
            )}

            {<DevPropertiesTableDataContainer item={props.item} />}
        </div>
    );
}

DevSectionCard.propTypes = {
    item: devSearchTypeItem,
    gtmSelector: PropTypes.string,
    isHighlighted: PropTypes.bool,
};
