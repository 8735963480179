import React from 'react';
import PropTypes from 'prop-types';

const Input = function ({
    text,
    onFocus,
    onBlur,
    onTouchStart,
    onClick,
    onTextChange,
    onKeyDown,
    placeholderText,
    inputClass,
    inputRef,
    listboxId,
    inputId,
    ariaDescribedby,
    ariaActivedescendant
}) {
    return (
        <input
            id={inputId}
            ref={inputRef}
            className={`predictive-search__input ${inputClass}`}
            type="text"
            aria-autocomplete="list"
            aria-controls={listboxId}
            aria-owns={listboxId}
            aria-activedescendant={ariaActivedescendant}
            aria-label={placeholderText}
            aria-describedby={ariaDescribedby}
            placeholder={placeholderText}
            value={text}
            onFocus={onFocus}
            onBlur={onBlur}
            onTouchStart={onTouchStart}
            onClick={onClick}
            onChange={e => onTextChange(e.target.value)}
            onKeyDown={onKeyDown}
        />
    );
};

Input.defaultProps = {
    placeholderText: '',
    inputClass: ''
};

Input.propTypes = {
    listboxId: PropTypes.string.isRequired,
    inputId: PropTypes.string.isRequired,
    ariaDescribedby: PropTypes.string.isRequired,
    ariaActivedescendant: PropTypes.string.isRequired,
    inputRef: PropTypes.object.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    inputClass: PropTypes.string,
    placeholderText: PropTypes.string,
    // context
    text: PropTypes.string.isRequired,
    onTextChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onTouchStart: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired
};

export default Input;
