import React from 'react';
import PropTypes from 'prop-types';
import FacebookFeedPost from './facebook-feed-post.jsx';
import Spinner from '../../spinner/js/spinner.jsx';
import utils from '../../../general/js/utils';

export default class FacebookFeed extends React.PureComponent {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.addPost = this.addPost.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.stopSpinner = this.stopSpinner.bind(this);
        this.hasFreeSpace = this.hasFreeSpace.bind(this);
        this.hasMorePosts = this.hasMorePosts.bind(this);
        this.showError = this.showError.bind(this);

        this.state = {
            isLoading: true,
            isError: false,
            nextPostMarker: 0,
            activePosts: []
        };
    }

    componentDidMount() {
        if (this.hasMorePosts()) {
            this.addPost();
        } else {
            this.stopSpinner();
            this.showError();
        }
    }

    updateHeight() {
        // RIC fixes delay between FB custom event and DOM update in IE11
        requestIdleCallback(() => {
            if (this.hasFreeSpace() && this.hasMorePosts()) {
                this.addPost();
            } else {
                this.stopSpinner();
            }
        });
    }

    hasFreeSpace() {
        const currentHeight = this.containerRef.current.clientHeight;
        const maxHeight = this.props.rootElem.clientHeight;

        return currentHeight < maxHeight;
    }

    hasMorePosts() {
        return (this.state.nextPostMarker < this.props.posts.length) && this.props.posts.length;
    }

    addPost() {
        this.setState((prevState, props) => ({
            nextPostMarker: (prevState.nextPostMarker + 1),
            activePosts: [...prevState.activePosts, props.posts[prevState.nextPostMarker]]
        }));
    }

    showError() {
        this.setState({ isError: true });
    }

    stopSpinner() {
        this.setState({ isLoading: false });
    }

    render() {
        return (
            <React.Fragment>
                <div className='facebook-feed__container' ref={this.containerRef}>
                    {this.state.activePosts.map((postId, i) => (
                        <FacebookFeedPost
                            pageId={this.props.pageId}
                            postId={postId}
                            width='auto'
                            showText={true}
                            onMount={this.updateHeight}
                            key={postId + i}
                        />
                    ))}
                    {this.state.isError &&
                    <div className='facebook-feed__error-message'>
                        Sorry, no posts were received from Facebook!
                    </div>
                    }
                    {this.state.isLoading &&
                    <div className='facebook-feed__spinner-container'>
                        <Spinner isActive={this.state.isLoading} />
                    </div>
                    }
                </div>
                {utils.isSitecoreExperienceEditor &&
                    <div className="facebook-feed__disable-click-overlay"></div>
                }
            </React.Fragment>
        );
    }
}

FacebookFeed.propTypes = {
    posts: PropTypes.array.isRequired,
    pageId: PropTypes.string.isRequired,
    rootElem: PropTypes.instanceOf(Element).isRequired
};
