import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import LoadMoreButton from '../../../listing/js/components/load-more-button';

class PropertiesSearchLoadMoreButton extends React.PureComponent {
    render() {
        const { onLoadMore, pagination, items, isLoadingMore } = this.props;

        return pagination.totalCount > items.length ?
            (
                <div className="properties-search__load-more-button-section">
                    <LoadMoreButton
                        isLoading={isLoadingMore}
                        caption={'Load more properties'}
                        onClick={onLoadMore}/>
                </div>
            ) : null;
    }
}

PropertiesSearchLoadMoreButton.propTypes = {
    onLoadMore: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    isLoadingMore: PropTypes.bool.isRequired,
};

export default withContext(PropertiesSearchLoadMoreButton, {
    onLoadMore: 'onLoadMore',
    pagination: 'pagination',
    items: 'items',
    isLoadingMore: 'isLoadingMore',
});
