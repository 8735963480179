import DcBaseComponent from 'general/js/dc/dc-base-component';
import api from 'general/js/api';
import SequentialRequestsProxy from 'general/js/api/sequential-requests-proxy';
import Area from './area';
import AppEvent from './app-event';
import utils from './utils';
import Help from './help.js';
import Loader from './loader.js';
import floorsSelectView from '../html/floors-select.hbs';
import propertySelectView from '../html/property-select.hbs';
import toggleInteractiveModeView from '../html/toggle-interactive-mode.hbs';

const KEYS = {
    ESC: 27,
    TOP: 38,
    BOTTOM: 40,
    LEFT: 37,
    RIGHT: 39,
    DELETE: 46,
    I: 73,
    S: 83,
    C: 67,
};

export default class ImageMapper extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.api = new SequentialRequestsProxy(api);
        this.dataUrl = this.options.dataUrl;
        this.updateUrl = this.options.updateUrl;
        this.removeUrl = this.options.removeUrl;
        this.toggleInteractiveModeUrl = this.options.toggleInteractiveModeUrl;
        this.infoBlockWidth = null;

        this.state = {
            offset: {
                x: 0,
                y: 0,
            },
            floors: null,
            appMode: null, // drawing || editing || preview
            currentType: null,
            editType: null,
            newArea: null,
            selectedArea: null,
            areas: [],
            events: [],
            isDraw: false,
            isToggleInteractiveModeActive: null,
            image: {
                src: null,
                width: 0,
                height: 0,
            },
        };
    }

    static getNamespace() {
        return 'image-mapper';
    }

    static getRequiredRefs() {
        return [
            'wrapper',
            'container',
            'img',
            'svg',
            'overlay',
            'help',
            'closeHelpButton',
            'floorsSelect',
            'editProperty',
            'propertySelect',
            'saveProperty',
            'removeProperty',
            'closeEditPropertyButton',
            'propertyTitle',
            'loader',
            'sync',
            'info',
            'toggleInteractiveMode',
        ];
    }

    onInit() {
        /* Get offset value */
        window.addEventListener('resize', this._recalcOffsetValues, false);
        /* Disable selection */
        this.refs.container.addEventListener(
            'mousedown',
            (e) => {
                e.preventDefault();
            },
            false
        );
        /* Disable image dragging */
        this.refs.img.addEventListener(
            'dragstart',
            (e) => {
                e.preventDefault();
            },
            false
        );
        this.refs.container.addEventListener('mousedown', this._onSvgMousedown, false);
        this.refs.container.addEventListener('click', this._onSvgClick, false);
        this.refs.container.addEventListener('dblclick', this._onAreaDblClick, false);
        this.refs.floorsSelect.addEventListener('change', this._onFloorsSelectChange, false);
        this.refs.toggleInteractiveMode.addEventListener(
            'click',
            this._onToggleInteractiveModeClick,
            false
        );
        document.addEventListener('keydown', this._onDocumentKeyDown, false);

        this._initBlocks();
        this.restartApp();
    }

    setFloorsData = (floors) => {
        this.state.floors = floors;
        return this;
    };

    setFloorsSelectOptions = (floors) => {
        const floorsSelectContent = floorsSelectView({ floors });
        this.refs.floorsSelect.innerHTML = floorsSelectContent;
        return this;
    };

    setToggleInteractiveMode = (isToggleInteractiveModeActive) => {
        this.state.isToggleInteractiveModeActive = isToggleInteractiveModeActive;
        const toggleInteractiveModeContent = toggleInteractiveModeView({
            isToggleInteractiveModeActive,
        });
        this.refs.toggleInteractiveMode.innerHTML = toggleInteractiveModeContent;
        return this;
    };

    resetToggleInteractiveMode = () => {
        this.state.isToggleInteractiveModeActive = null;
        this.refs.toggleInteractiveMode.innerHTML = '';
        return this;
    };

    selectDefaultFloor = (floors) => {
        const defaultFloor = floors[0].name;

        if (defaultFloor) {
            this.setFloor(defaultFloor);
        }
    };

    getImageParams = (image) => {
        const infoBlockWidth = parseInt(window.getComputedStyle(this.refs.info, null)
            .getPropertyValue('width'), 10);
        const imageParams = image.split('&');

        const imageInitialParams = imageParams[0].split('?');
        const imageDefaultSrc = imageInitialParams[0];
        const hashQuery = imageParams[imageParams.length - 1];
        const maxImageWidth = imageInitialParams[1].split('=')[1];

        const currentWidth = maxImageWidth > infoBlockWidth
            ? infoBlockWidth
            : maxImageWidth;

        return {
            imageDefaultSrc, hashQuery, currentWidth, maxImageWidth
        };
    }

    getCurrentFloor = (floorName) => this.state.floors.find((el) => el.name === floorName);

    getCurrentFloorAreas = (floorName) =>
        this.state.floors.find((el) => el.name === floorName).map.areas;

    loadImage = ({ image, originalWidth }, cb) => {
        const imageParams = this.getImageParams(image);
        const { imageDefaultSrc, hashQuery, currentWidth, maxImageWidth } = imageParams;
        const url = imageDefaultSrc + `?mw=${currentWidth}&${hashQuery}`;

        this.refs.img.src = url;
        this.state.image.src = url;
        this.loader.show();
        this.showInfo('Loading image');
        this.refs.img.onload = () => {
            this.show()
                .setDimensions(this.refs.img.width, this.refs.img.height)
                .recalcOffsetValues();
            this.refs.img.onload = '';
            this.refs.img.onerror = '';
            this.state.image.currentWidth = currentWidth;
            this.state.image.maxWidth = maxImageWidth;
            this.state.image.width = this.refs.img.width;
            this.state.image.height = this.refs.img.height;
            this.state.image.originalWidth = originalWidth;
            cb();
            this.loader.hide();
        };
        this.refs.img.onerror = () => {
            this.refs.img.onload = '';
            this.refs.img.onerror = '';
            cb();
            this.loader.hide();
            this.showInfo('Can not load image, try again later');
        };
        return this;
    };

    _initBlocks = () => {
        this.help = new Help({
            overlay: this.refs.overlay,
            help: this.refs.help,
            closeHelpButton: this.refs.closeHelpButton,
        });

        this.loader = new Loader({
            loader: this.refs.loader,
        });

        /* Edit selected area info */
        this.info = (function (app) {
            // TODO: refactor to a ES6 class syntax
            const form = app.refs.editProperty;
            const title = app.refs.propertyTitle;
            const select = app.refs.propertySelect;
            const closeButton = app.refs.closeEditPropertyButton;
            const saveButton = app.refs.saveProperty;
            const removeButton = app.refs.removeProperty;
            let obj;
            let x;
            let y;
            let tempX;
            let tempY;

            function selectFirstOption() {
                app.info.setSelectOptions(app.getCurrentFloorAreas(app.state.currentFloor));
            }

            function save(e) {
                e.preventDefault();
                const currentProp = app.state.floors
                    .find((el) => el.name === app.state.currentFloor)
                    .map.areas.find((el) => el.title === select.value);
                const currentObjProp = obj.toMapElement();
                const scaledBackCoords = app.scaleCoords(
                    { direction: 'TO_ORIGIN' },
                    {
                        originalWidth: app.state.image.originalWidth,
                        width: app.state.image.width,
                        coords: currentObjProp.coords,
                    }
                );
                const params = {
                    ...currentProp,
                    coords: scaledBackCoords,
                    shape: currentObjProp.shape,
                };

                app.loader.show();
                app.api
                    .post(app.updateUrl, params)
                    .then(() => {
                        app.loader.hide();
                        obj.setInfoAttributes({ ...params });
                        app.showInfo('Drawing mode');
                        app.showInfo(`${params.title} updated successfully`);
                        unload();
                        selectFirstOption();
                    })
                    .catch((error) => {
                        console.log(error);
                        app.loader.hide();
                        app.showInfo(`${params.title} failed to update, try again later`);
                        selectFirstOption();
                    });
            }

            function remove(e) {
                e.preventDefault();
                const currentObjProp = obj.toMapElement();
                const currentProp = app.state.floors
                    .find((el) => el.name === app.state.currentFloor)
                    .map.areas.find((el) => el.title === currentObjProp.title);
                const params = {
                    ...currentProp,
                    coords: currentObjProp.coords,
                    shape: currentObjProp.shape,
                };

                app.loader.show();
                app.api
                    .post(app.removeUrl, params)
                    .then(() => {
                        app.loader.hide();
                        app.removeObject(obj);
                        unload();
                        app.showInfo(`${params.title} removed successfully`);
                        selectFirstOption();
                    })
                    .catch((error) => {
                        console.log(error);
                        app.loader.hide();
                        app.showInfo(`${params.title} failed to remove, try again later`);
                        selectFirstOption();
                    });
            }

            function unload() {
                obj = null;
                utils.hide(form);
            }

            function load(object, newX, newY) {
                obj = object;
                title.innerHTML = obj.toMapElement().title || 'Unassigned';
                utils.show(form);
                if (newX && newY) {
                    x = newX;
                    y = newY;
                    setCoords(x, y);
                }
            }

            function setSelectOptions(areas) {
                const propertySelectContent = propertySelectView({ areas });
                select.innerHTML = propertySelectContent;
                return this;
            }

            function setCoords(newX, newY) {
                form.style.left = newX + 5 + 'px';
                form.style.top = newY + 5 + 'px';
            }

            function moveEditBlock(e) {
                setCoords(x + e.pageX - tempX, y + e.pageY - tempY);
            }

            function stopMoveEditBlock(e) {
                x = x + e.pageX - tempX;
                y = y + e.pageY - tempY;
                setCoords(x, y);

                app.removeAllEvents();
            }

            saveButton.addEventListener('click', save, false);
            closeButton.addEventListener('click', unload, false);
            removeButton.addEventListener('click', remove, false);

            form.addEventListener(
                'mousedown',
                (e) => {
                    tempX = e.pageX;
                    tempY = e.pageY;

                    app.addEvent(document, 'mousemove', moveEditBlock);
                    app.addEvent(form, 'mouseup', stopMoveEditBlock);
                },
                false
            );

            return {
                unload,
                load,
                setSelectOptions,
            };
        })(this);

        /* Buttons and actions */
        (function buttons(app) {
            const rectangle = app.refs.rectangle;
            const polygon = app.refs.polygon;
            const edit = app.refs.edit;
            const sync = app.refs.sync;
            const showHelp = app.refs.showHelp;
            const all = [rectangle, polygon, edit, sync, showHelp];

            function deselectAll() {
                all.forEach((x) => {
                    x.classList.remove(Area.CLASS_NAMES.SELECTED);
                });
                app.showInfo('');
            }

            function selectOne(button) {
                deselectAll();
                button.classList.add(Area.CLASS_NAMES.SELECTED);
            }

            function onShapeButtonClick(e) {
                app.recalcOffsetValues();
                app.setMode('drawing')
                    .setDrawClass()
                    .setShape(this.dataset.dcImageMapperRef)
                    .deselectAll();
                selectOne(this);
                app.showInfo('Drawing mode');

                e.preventDefault();
            }

            function onSyncButtonClick(e) {
                if (app.state.currentFloor) {
                    app.restartFloor(app.state.currentFloor);
                } else {
                    app.restartApp();
                }
                deselectAll();
                e.preventDefault();
            }

            function onEditButtonClick(e) {
                if (app.getMode() === 'editing') {
                    app.setMode(null).setDefaultClass().deselectAll();
                    deselectAll();
                    // utils.show(domElements.svg);
                } else {
                    app.setShape(null).setMode('editing').setEditClass();
                    selectOne(this);
                    app.showInfo('Editing mode');
                }
                e.preventDefault();
            }

            function onShowHelpButtonClick(e) {
                app.help.show();

                e.preventDefault();
            }

            rectangle.addEventListener('click', onShapeButtonClick, false);
            polygon.addEventListener('click', onShapeButtonClick, false);
            sync.addEventListener('click', onSyncButtonClick, false);
            edit.addEventListener('click', onEditButtonClick, false);
            showHelp.addEventListener('click', onShowHelpButtonClick, false);

            app.deselectHeaderButtons = deselectAll.bind(this);
        })(this);
    };

    _recalcOffsetValues = () => {
        this.state.offset = utils.getOffset(this.refs.container);
    };

    _onSvgMousedown = (e) => {
        if (this.state.appMode !== 'editing') {
            return;
        }

        if (e.target.parentNode.tagName === 'g') {
            this.info.unload();
            this.state.selectedArea = e.target.parentNode.obj;

            this.deselectAll();
            this.state.selectedArea.select();
            this.state.selectedArea.editingStartPoint = {
                x: e.pageX,
                y: e.pageY,
            };

            if (e.target.classList.contains('helper')) {
                const helper = e.target;
                this.state.editType = helper.action;

                if (helper.n >= 0) {
                    // if typeof selected_area == polygon
                    this.state.selectedArea.selected_point = helper.n;
                }

                this.addEvent(
                    this.refs.container,
                    'mousemove',
                    this.state.selectedArea.onProcessEditing.bind(this.state.selectedArea)
                ).addEvent(
                    this.refs.container,
                    'mouseup',
                    this.state.selectedArea.onStopEditing.bind(this.state.selectedArea)
                );
            } else if (
                e.target.tagName === 'rect' ||
                e.target.tagName === 'circle' ||
                e.target.tagName === 'polygon'
            ) {
                this.state.editType = 'move';

                this.addEvent(
                    this.refs.container,
                    'mousemove',
                    this.state.selectedArea.onProcessEditing.bind(this.state.selectedArea)
                ).addEvent(
                    this.refs.container,
                    'mouseup',
                    this.state.selectedArea.onStopEditing.bind(this.state.selectedArea)
                );
            }
        } else {
            this.deselectAll();
            this.info.unload();
        }
    };

    _onSvgClick = (e) => {
        if (!(this.state.appMode === 'drawing' && !this.state.isDraw && this.state.currentType)) {
            return;
        }

        this.setIsDraw(true);
        this.state.newArea = utils.CONSTRUCTORS[this.state.currentType].createAndStartDrawing(
            utils.getRightCoords(e.pageX, e.pageY, this),
            this
        );
    };

    _onAreaDblClick = (e) => {
        if (this.state.appMode !== 'editing') {
            return;
        }

        if (e.target.tagName !== 'rect' && e.target.tagName !== 'polygon') {
            return;
        }

        this.state.selectedArea = e.target.parentNode.obj;
        this.info.load(
            this.state.selectedArea,
            e.pageX - this.state.offset.x,
            e.pageY - this.state.offset.y
        );
    };

    _onDocumentKeyDown = (e) => {
        switch (e.keyCode) {
            case KEYS.ESC:
                this.help.hide();
                if (this.state.isDraw) {
                    this.state.isDraw = false;
                    this.state.newArea.remove();
                    this.state.areas.pop();
                    this.removeAllEvents();
                } else if (this.state.appMode === 'editing') {
                    this.state.selectedArea.redraw();
                    this.removeAllEvents();
                }

                break;

            case KEYS.TOP:
                if (this.state.appMode === 'editing' && this.state.selectedArea) {
                    this.state.selectedArea.move(0, -1);
                    e.preventDefault();
                }

                break;

            case KEYS.BOTTOM:
                if (this.state.appMode === 'editing' && this.state.selectedArea) {
                    this.state.selectedArea.move(0, 1);
                    e.preventDefault();
                }
                break;

            case KEYS.LEFT:
                if (this.state.appMode === 'editing' && this.state.selectedArea) {
                    this.state.selectedArea.move(-1, 0);
                    e.preventDefault();
                }

                break;

            case KEYS.RIGHT:
                if (this.state.appMode === 'editing' && this.state.selectedArea) {
                    this.state.selectedArea.move(1, 0);
                    e.preventDefault();
                }

                break;

            case KEYS.DELETE:
                if (this.state.appMode === 'editing' && this.state.selectedArea) {
                    this.removeObject(this.state.selectedArea);
                    this.state.selectedArea = null;
                    this.info.unload();
                }

                break;

            case KEYS.I:
                if (this.state.appMode === 'editing' && this.state.selectedArea) {
                    const coordsForAttributesForm = this.state.selectedArea.getCoordsForDisplayingInfo();
                    this.info.load(
                        this.state.selectedArea,
                        coordsForAttributesForm.x,
                        coordsForAttributesForm.y
                    );
                }

                break;

            default:
        }
    };

    _onFloorsSelectChange = (e) => {
        this.restartFloor(e.target.value);
    };

    _onToggleInteractiveModeClick = () => {
        const enableInteractiveSitePlan = !this.state.isToggleInteractiveModeActive;
        this.loader.show();
        this.showInfo('Loading');
        this.api
            .post(this.toggleInteractiveModeUrl, {
                enableInteractiveSitePlan,
            })
            .then(() => {
                this.restartApp();
            })
            .catch(() => {
                this.loader.hide();
                this.showInfo('Failed to update status of an Interactive Site Plan');
            });
    };

    restartApp = () => {
        this.setMode(null)
            .setDefaultClass()
            .setShape(null)
            .setIsDraw(false)
            .deselectAll()
            .clear()
            .hide();

        this.loader.show();
        this.showInfo('Loading');
        this.api
            .post(this.dataUrl)
            .then(({ data }) => {
                this.loader.hide();
                this.showInfo('FloorData loaded successfully');
                this.setFloorsData(data.floors)
                    .setToggleInteractiveMode(data.enableInteractiveSitePlan)
                    .setFloorsSelectOptions(data.floors)
                    .selectDefaultFloor(data.floors);
            })
            .catch(() => {
                this.loader.hide();
                this.resetToggleInteractiveMode();
                this.showInfo('Failed to load data, try again later');
            });
    };

    restartFloor = (currentFloor) => {
        this.setMode(null)
            .setDefaultClass()
            .setShape(null)
            .setIsDraw(false)
            .deselectAll()
            .clear()
            .hide();

        this.loader.show();
        this.showInfo('Loading');
        this.api
            .post(this.dataUrl)
            .then(({ data }) => {
                this.loader.hide();
                this.showInfo('FloorData loaded successfully');
                this.setFloorsData(data.floors)
                    .setToggleInteractiveMode(data.enableInteractiveSitePlan)
                    .setFloor(currentFloor);
            })
            .catch(() => {
                this.loader.hide();
                this.resetToggleInteractiveMode();
                this.showInfo('Failed to load data, try again later');
            });
    };

    setFloor = (currentFloor) => {
        this.setMode(null)
            .setDefaultClass()
            .setShape(null)
            .setIsDraw(false)
            .deselectAll()
            .clear()
            .hide()
            .loadImage(this.getCurrentFloor(currentFloor), () => {
                this.state.currentFloor = currentFloor;
                this.getCurrentFloorAreas(currentFloor).forEach((area) => {
                    const ConstrFig = utils.CONSTRUCTORS[area.shape];
                    if (area.shape && area.coords && ConstrFig) {
                        const scaledCoords = this.scaleCoords(
                            { direction: 'FROM_ORIGIN' },
                            {
                                originalWidth: this.state.image.originalWidth,
                                width: this.state.image.width,
                                coords: area.coords,
                            }
                        );
                        this.state.areas.push(
                            new ConstrFig(
                                ConstrFig.transformCoordsForInit(scaledCoords),
                                area,
                                this
                            )
                        );
                    }
                });

                this.info.setSelectOptions(this.getCurrentFloorAreas(currentFloor));
                this.deselectHeaderButtons();
                this.showInfo('Floor synced with server successfully');
            });
    };

    scaleCoords = ({ direction }, { originalWidth, width, coords }) => {
        let scale;
        switch (direction) {
            case 'FROM_ORIGIN':
                scale = width && originalWidth && originalWidth > 0 ? width / originalWidth : 1;
                break;
            case 'TO_ORIGIN':
                scale = width && originalWidth && width > 0 ? originalWidth / width : 1;
                break;
            default:
                scale = 1;
                break;
        }
        return coords.map((coord) => coord * scale);
    };

    hide = () => {
        utils.hide(this.refs.container);
        return this;
    };

    show = () => {
        utils.show(this.refs.container);
        return this;
    };

    recalcOffsetValues = () => {
        this._recalcOffsetValues();
        return this;
    };

    setDimensions = (width, height) => {
        this.refs.svg.setAttribute('width', width);
        this.refs.svg.setAttribute('height', height);
        this.refs.container.style.width = width + 'px';
        this.refs.container.style.height = height + 'px';
        return this;
    };

    addNodeToSvg = (node) => {
        this.refs.svg.appendChild(node);
        return this;
    };

    removeNodeFromSvg = (node) => {
        this.refs.svg.removeChild(node);
        return this;
    };

    getOffset = (arg) => {
        switch (arg) {
            case 'x':
            case 'y':
                return this.state.offset[arg];
            default:
                throw new Error('Invalid argument value. Expected "x" or "y"');
        }
    };

    clear = () => {
        this.state.areas.length = 0;
        while (this.refs.svg.childNodes[0]) {
            this.refs.svg.removeChild(this.refs.svg.childNodes[0]);
        }
        this.info.unload();
        return this;
    };

    removeObject = (obj) => {
        this.state.areas.forEach((x, i) => {
            if (x === obj) {
                this.state.areas.splice(i, 1);
            }
        });
        obj.remove();
        return this;
    };

    deselectAll = () => {
        this.state.areas.forEach((x) => {
            x.deselect();
        });
        return this;
    };

    getIsDraw = () => this.state.isDraw;

    setIsDraw = (arg) => {
        this.state.isDraw = arg;
        return this;
    };

    setMode = (arg) => {
        this.state.appMode = arg;
        return this;
    };

    getMode = () => this.state.appMode;

    setShape = (arg) => {
        this.state.currentType = arg;
        return this;
    };

    getShape = () => this.state.currentType;

    addObject = (object) => {
        this.state.areas.push(object);
        return this;
    };

    getNewArea = () => this.state.newArea;

    resetNewArea = () => {
        this.state.newArea = null;
        return this;
    };

    getSelectedArea = () => this.state.selectedArea;

    setSelectedArea = (obj) => {
        this.state.selectedArea = obj;
        return this;
    };

    getEditType = () => this.state.editType;

    setEditClass = () => {
        this.refs.container.classList.remove('draw');
        this.refs.container.classList.add('edit');
        return this;
    };

    setDrawClass = () => {
        this.refs.container.classList.remove('edit');
        this.refs.container.classList.add('draw');
        return this;
    };

    setDefaultClass = () => {
        this.refs.container.classList.remove('edit');
        this.refs.container.classList.remove('draw');
        return this;
    };

    addEvent = (target, eventType, func) => {
        this.state.events.push(new AppEvent(target, eventType, func));
        return this;
    };

    removeAllEvents = () => {
        this.state.events.forEach((x) => {
            x.remove();
        });
        this.state.events.length = 0;
        return this;
    };

    getJSONCode = (arg) => {
        let jsonCode = '[ <br />';
        if (arg) {
            if (!this.state.areas.length) {
                return '0 objects';
            }
            utils.foreachReverse(this.state.areas, (x) => {
                jsonCode +=
                    '&nbsp;&nbsp;&nbsp;&nbsp;' + JSON.stringify(x.toMapElement()) + ',<br />';
            });
        } else {
            utils.foreachReverse(this.state.areas, (x) => {
                jsonCode += JSON.stringify(x.toMapElement());
            });
        }
        jsonCode += ']';

        return jsonCode;
    };

    showInfo = (text) => {
        if (this.refs.info) {
            this.refs.info.innerHTML = text + '';
        }

        return this;
    };

    static fromJSON = (params, app) => {
        const AreaConstructor = utils.CONSTRUCTORS[params.type];

        if (!AreaConstructor) {
            throw new Error('This area type is not valid');
        }

        if (!AreaConstructor.testCoords(params.coords)) {
            throw new Error('This coords is not valid for ' + params.type);
        }

        app.setIsDraw(true);
        const area = new AreaConstructor(params.coords, params.attributes, app);
        app.setIsDraw(false).resetNewArea();

        return area;
    };

    static copy = (originalArea, app) =>
        ImageMapper.fromJSON(originalArea.toJSON(), app).move(10, 10).select();
}
