import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function RecipeSection(props) {
    const { ingredientSections, methodSections, className } = props;

    const sections = ingredientSections || methodSections;

    const sectionList = (list) => list.items.map((item, index) => <li key={index}>{item}</li>);

    const ingredientsAndMethodSections = sections.map((section) => (
        <Fragment key={section.title}>
            <h3>{section.title}</h3>
            {section.items.length > 0 && ingredientSections && <ul>{sectionList(section)}</ul>}
            {section.items.length > 0 && methodSections && <ol>{sectionList(section)}</ol>}
        </Fragment>
    ));

    return (
        <div className={className}>
            {ingredientSections && <h2>Ingredients</h2>}
            {methodSections && <h2>Method</h2>}
            {ingredientsAndMethodSections}
        </div>
    );
}

RecipeSection.propTypes = {
    ingredientSections: PropTypes.array,
    methodSections: PropTypes.array,
    className: PropTypes.string,
};

export default RecipeSection;
