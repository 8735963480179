import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import SuggestionsItems from './suggestions-items';

const SuggestionGroup = function ({ group, onHighlightedElementUpdate, renderGroup }) {
    return (
        <div className="predictive-search__suggestions-group">
            {renderGroup(group)}
            <SuggestionsItems
                items={group.suggestions}
                onHighlightedElementUpdate={onHighlightedElementUpdate}/>
        </div>
    );
};

SuggestionGroup.propTypes = {
    group: PropTypes.object.isRequired,
    renderGroup: PropTypes.func.isRequired,
    onHighlightedElementUpdate: PropTypes.func.isRequired,
};

export default withContext(SuggestionGroup, {
    renderGroup: 'renderGroup'
});
