import Rectangle from './rectangle.js';
import Polygon from './polygon.js';

export default {
    CONSTRUCTORS: {
        rectangle: Rectangle,
        polygon: Polygon,
        rect: Rectangle,
        poly: Polygon,
    },

    getOffset(node) {
        const boxCoords = node.getBoundingClientRect();

        return {
            x: Math.round(boxCoords.left + window.pageXOffset),
            y: Math.round(boxCoords.top + window.pageYOffset)
        };
    },

    getRightCoords(x, y, app) {
        return {
            x: x - app.getOffset('x'),
            y: y - app.getOffset('y')
        };
    },

    id(str) {
        return document.getElementById(str)
    },

    hide(node) {
        node.style.display = 'none';
        return this;
    },

    show(node) {
        node.style.display = 'block';
        return this;
    },

    foreachReverse(arr, func) {
        for (let i = arr.length - 1; i >= 0; i--) {
            func(arr[i], i);
        }
    },

    stopEvent(e) {
        e.stopPropagation();
        e.preventDefault();
        return this;
    }
};
