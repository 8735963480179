import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withContext } from '@Components/developments-search-v2/js/context.jsx';
import { devSearchTypeDevelopment } from '@Components/developments-search-v2/js/dev-search-types.js';
import {
    OWNERSHIP_TYPE_SALE,
    OWNERSHIP_TYPE_RENT,
	OWNERSHIP_TYPE_OPSO,
    POA_FULL_TAG,
    POA_SMALL_TAG,
    FR_TAG,
	AN_TAG
} from '@Components/developments-search-v2/js/constants.js';

function DevSectionFlags(props) {
    const shouldSalePriceBe =
        props.item.salesFormattedPrice &&
        !(props.appliedFilters.ownershipType === OWNERSHIP_TYPE_RENT || props.appliedFilters.ownershipType === OWNERSHIP_TYPE_OPSO) &&
        !props.item.isAllOpso;
    const shouldRentalPriceBe =
        props.item.rentalFormattedPrice &&
        !(props.appliedFilters.ownershipType === OWNERSHIP_TYPE_SALE || props.appliedFilters.ownershipType === OWNERSHIP_TYPE_OPSO) &&
        !props.item.isAllOpso;
    const allOpsoProperties = props.item.isAllOpso;

    const getStatusTag = (isSmall) => {
        if (props.item.fullyReserved) {
            return FR_TAG;
        }
        if (props.item.isInSellingStage) {
            return AN_TAG;
        }

        return isSmall ? POA_SMALL_TAG : POA_FULL_TAG;
    };

    return (
        <div className="dev-section-flags">
            {(shouldSalePriceBe && (
                <div className="dev-section-flags__column dev-section-flags__column--primary">
                    Buy&nbsp;from&ensp;
                    <strong
                        dangerouslySetInnerHTML={{
                            __html: props.item.salesFormattedPrice,
                        }}
                    />
                </div>
            )) ||
                null}

            {(shouldRentalPriceBe && (
                <div className="dev-section-flags__column dev-section-flags__column--primary">
                    Rent&nbsp;from&ensp;{' '}
                    <strong
                        dangerouslySetInnerHTML={{
                            __html: props.item.rentalFormattedPrice,
                        }}
                    />
                </div>
            )) ||
                null}

			{(allOpsoProperties && (
                <div className="dev-section-flags__column dev-section-flags__column--primary">
                    <strong
                        dangerouslySetInnerHTML={{
                            __html: props.item.opsoAvailableText,
                        }}
                    />
                </div>
            )) ||
                null}

            {!shouldSalePriceBe && !shouldRentalPriceBe && !allOpsoProperties && (
                <div
                    className={classNames({
                        'dev-section-flags__column': true,
                        'dev-section-flags__column--primary': !props.item.promo,
                        'dev-section-flags__column--accent': props.item.promo,
                    })}
                >
                    {props.item.promo ? props.item.promo : getStatusTag(false)}
                </div>
            )}

            {props.item.promo && (
                <div
                    className={classNames({
                        'dev-section-flags__column': true,
                        'dev-section-flags__column--promo': true,
                        'dev-section-flags__column--primary': !(
                            shouldSalePriceBe || shouldRentalPriceBe || allOpsoProperties
                        ),
                        'dev-section-flags__column--accent':
                            shouldSalePriceBe || shouldRentalPriceBe || allOpsoProperties,
                    })}
                >
                    <strong>
                        {shouldSalePriceBe || shouldRentalPriceBe || allOpsoProperties
                            ? props.item.promo
                            : getStatusTag(true)}
                    </strong>
                </div>
            )}
        </div>
    );
}

DevSectionFlags.propTypes = {
    item: devSearchTypeDevelopment,
    appliedFilters: PropTypes.object,
};

export default withContext(DevSectionFlags, {
    appliedFilters: 'appliedFilters',
});
