import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import notificationsService, { Notification } from '@General/js/notifications-service';
import { SUGGESTION_TYPE_DEVELOPMENT } from '@Components/predictive-search/js/constants.js';
import PredictiveSearch, {
    LOOKUP_ERROR_TEXT_DEFAULT,
} from '@Components/predictive-search/js/predictive-search';
import DevelopmentIcon from './development-icon';
import { CampaignSearchResultsContext } from '../campaign-property-search/js/context';
import analyticsService from '../../general/js/analytics-service';

const MIN_CHAR_FOR_SUGGESTION = 3;
const NOT_ENOUGH_LETTERS_MESSAGE = 'Please enter at least 3 letters';

const NOTIFICATION_AUTO_CLOSE_TIME = 15000;

const GA_EVENTS_LOCATION = 'locationSearch';

export default function DevelopmentsPredictiveSearch(props) {
    const [suggestions, setSuggestions] = useState([]);
    const [hasTextChanged, setHasTextChanged] = useState([]);
    const [groups, setGroups] = useState([]);
    const {
        setResponseData,
        setIsLoading,
        setIsError,
        filterOptions,
        setFilterOptions,
        setLocationSearchId,
        setPlaceId
    } = useContext(CampaignSearchResultsContext) || { responseData: [], isLoading: false };

    const predictiveSearchRef = React.createRef();

    const requestCampaignData = () => {
        const defaultSearchState = {
            ...filterOptions,
            offset: 0,
            sort: 'Price',
        };
        setFilterOptions(defaultSearchState);
        axios({
            method: 'post',
            url: props.campaignSearchUrl,
            data: defaultSearchState,
        })
            .then((res) => {
                if (!res.data) {
                    if (setIsError) {
                        setIsError(true);
                    }
                    console.log('failed to get data');
                    return;
                }

                if (setResponseData) {
                    setResponseData(res.data);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log('Could not perform request', error);
            });
    };

    useEffect(() => {
        if (props.campaignSearchUrl) {
            setIsLoading(true);
            requestCampaignData();
        }
    }, []);

    const showSuggestedDevelopment = () => {
        const { text, onEmptyTextSubmit } = props;

        if (text === '' && onEmptyTextSubmit) {
            onEmptyTextSubmit();
            return;
        }

        if (getPredictiveSearch().isReady) {
            if (getPredictiveSearch().isMinLengthReached(text)) {
                if (suggestions.length > 0) {
                    let suggestion = getPredictiveSearch().getDefaultSuggestion();
                    handleSuggestion(suggestion);
                } else {
                    if (hasTextChanged === true) {
                        handleFallbackQuerySubmit(text);
                    }
                }
            } else {
                handleFallbackQuerySubmit(text);
            }
        }
    };

    const onSubmit = (e) => {
        const { text } = props;
        e.preventDefault();

        analyticsService._send(GA_EVENTS_LOCATION);

        if (props.campaignSearchUrl) {
            setIsLoading(true);
            if (text === '') {
                setLocationSearchId(null);
                setPlaceId(null);
                requestCampaignData();
                return;
            }
            showSuggestedDevelopment();
        } else {
            showSuggestedDevelopment();
        }
    };

    const onSuggestionSelect = (suggestion) => {
        handleSuggestion(suggestion);
    };

    const onTextChange = (text) => {
        setHasTextChanged(true);
        props.onTextChange(text);
    };

    function handleSuggestion(suggestion) {
        props.onSuggestionSelect(suggestion);
    }

    function handleFallbackQuerySubmit(text) {
        if (props.onFallbackQuerySubmit)
            props.onFallbackQuerySubmit(text);
        else
            window.location.href = `${props.defaultSearchUrl}?q=${text}}`;
    }

    function getPredictiveSearch() {
        return predictiveSearchRef.current;
    }

    const handleSuccessReceive = (result) => {
        const responseGroups = result.data.groups;
        const responseSuggestions = responseGroups.reduce(
            (res, group) => [...res, ...group.suggestions],
            []
        );
        setGroups(responseGroups);
        setSuggestions(responseSuggestions);
    };

    const renderSuggestion = (suggestion, { isHighlighted, isLast, isFirst, itemId }) => {
        const suggestionStateClasses = `
        ${isHighlighted ? 'is-highlighted' : ''} ${isLast ? 'is-last' : ''} ${
            isFirst ? 'is-first' : ''
        }`;

        return (
            <div className={`developments-predictive-search__suggestion ${suggestionStateClasses}`}>
                <div className="developments-predictive-search__suggestion-text">
                    {suggestion.text}
                </div>
                {suggestion.type === SUGGESTION_TYPE_DEVELOPMENT && suggestion.productType ? (
                    <DevelopmentIcon productType={suggestion.productType} />
                ) : null}
            </div>
        );
    };

    const renderGroup = (group) => {
        return (
            <div className="developments-predictive-search__suggestions-group-name">
                {group.text}
            </div>
        );
    };

    const {
        text,
        predictiveSearchUrl,
        placeholderMessage,
        noResultsSuggestionText,
        onActivate,
        onDeactivate,
        renderButton,
        inputClass,
        dropdownClass,
        buttonTrackingClass,
    } = props;

    return (
        <form className="developments-predictive-search" onSubmit={onSubmit} role="search">
            <div className="developments-predictive-search__input-wrapper">
                <PredictiveSearch
                    minCharsCount={MIN_CHAR_FOR_SUGGESTION}
                    ref={predictiveSearchRef}
                    text={text}
                    suggestions={suggestions}
                    groups={groups}
                    renderSuggestion={renderSuggestion}
                    renderGroup={renderGroup}
                    handleSuccessReceive={handleSuccessReceive}
                    predictiveSearchUrl={predictiveSearchUrl}
                    onSuggestionSelect={onSuggestionSelect}
                    onTextChange={onTextChange}
                    onActivate={onActivate}
                    onDeactivate={onDeactivate}
                    placeholderText={placeholderMessage}
                    noResultsSuggestionText={noResultsSuggestionText}
                    inputClass={`developments-predictive-search__input ${inputClass}`}
                    dropdownClass={`developments-predictive-search__dropdown ${dropdownClass}`}
                />
            </div>
            {renderButton(
                'developments-predictive-search__button',
                'developments-predictive-search__button-icon',
                buttonTrackingClass
            )}
        </form>
    );
}

DevelopmentsPredictiveSearch.defaultProps = {
    inputClass: '',
    dropdownClass: '',
    lookupErrorText: LOOKUP_ERROR_TEXT_DEFAULT,
};

DevelopmentsPredictiveSearch.propTypes = {
    noResultsSuggestionText: PropTypes.string.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
    noResultsMessage: PropTypes.string.isRequired,
    placeholderMessage: PropTypes.string.isRequired,
    predictiveSearchUrl: PropTypes.string.isRequired,
    renderButton: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    onTextChange: PropTypes.func.isRequired,
    onSuggestionSelect: PropTypes.func.isRequired,
    onFallbackQuerySubmit: PropTypes.func,
    onEmptyTextSubmit: PropTypes.func,
    inputClass: PropTypes.string,
    dropdownClass: PropTypes.string,
    lookupErrorText: PropTypes.string,
    buttonTrackingClass: PropTypes.string,
};
