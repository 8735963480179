import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import FacebookFeed from './facebook-feed.jsx';
import eventBus from '../../../general/js/event-bus';

export const FB_SCRIPT_LOADED = 'fbScriptLoaded';
export const FB_SCRIPT_FAILED = 'fbScriptFailed';
const DEFAULT_SCRIPT_URL = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0';

export default class FacebookFeedComponent extends DcBaseComponent {
    static getNamespace() {
        return 'facebook-feed';
    }

    onInit() {
        eventBus.addListener(FB_SCRIPT_LOADED, this._renderComponent.bind(this));
        eventBus.addListener(FB_SCRIPT_FAILED, this._renderError.bind(this));

        if (!FacebookFeedComponent.isFbScriptLoading) {
            FacebookFeedComponent.isFbScriptLoading = true;
            this.options.scriptUrl = this.options.scriptUrl || DEFAULT_SCRIPT_URL;
            this._loadFbScript();
        }
    }

    _renderComponent() {
        ReactDOM.render(
            <FacebookFeed
                rootElem={this.element}
                posts={this.options.posts}
                pageId={this.options.pageId}
            />,
            this.element
        );
    }

    _renderError() {
        ReactDOM.render(
            <div className='facebook-feed__error-message'>
                Facebook script failed to load!
            </div>,
            this.element
        );
    }

    _loadFbScript() {
        const script = document.createElement('script');
        script.src = this.options.scriptUrl;
        script.async = true;
        script.defer = true;
        script.addEventListener('load', this.onLoad.bind(this));
        script.addEventListener('error', this.onError.bind(this));
        document.head.appendChild(script);
    }

    onLoad() {
        eventBus.emit(FB_SCRIPT_LOADED);
    }

    onError() {
        // eslint-disable-next-line no-console
        console.error(`Facebook script failed to load in ${this.constructor.name}`);
        eventBus.emit(FB_SCRIPT_FAILED);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}

FacebookFeedComponent.isFbScriptLoading = false;
