import React from 'react';
import { useStore } from 'effector-react/compat';
import NewImageMapper from './image-mapper-v2.jsx';
import PropertyPopup from './property-popup.jsx';
import { useInteractiveSitePlanStore } from './use-interactive-site-plan-store.jsx';
import PropertyKeys from './property-keys';
import FloorTabs from './floor-tabs';

export default function NewInteractiveSitePlan({isNewVersion}) {
    const [store] = useInteractiveSitePlanStore();
    const state = useStore(store);

    return (
        <>
            <FloorTabs />
            <div className="interactive-site-plan__item">
                {isNewVersion && <PropertyKeys />}
                {state.floors.length !== 1 ? (
                    <div>
                        <NewImageMapper />
                        {state.isPopupOpen && <PropertyPopup />}
                    </div>
                ) : (
                    <>
                        <NewImageMapper />
                        {state.isPopupOpen && <PropertyPopup />}
                    </>
                )}
            </div>
        </>
    );
}
