import DcBaseComponent from 'general/js/dc/dc-base-component';
import CollapsibleTabs from 'components/collapsible-tabs/js/collapsible-tabs.component.js';

export default class VirtualTour extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.tabsInstance = null;
    }

    static getNamespace() {
        return 'virtual-tour';
    }

    static getRequiredRefs() {
        return ['slides', 'collapsibleTabs'];
    }

    onInit() {
        const collapsibleTabs = this.refs.collapsibleTabs;
        collapsibleTabs.querySelectorAll('.tabs__content')
            .forEach((item) => {
                if (!item.querySelector('iframe')) item.remove();
            });
        this.tabsInstance = new CollapsibleTabs(collapsibleTabs, this._initSlide.bind(this));
        this.tabsInstance.init();

        const intersectionObserver = new IntersectionObserver(this._onIntersect.bind(this));
        intersectionObserver.observe(this.element);
    }

    _initSlide(index) {
        const slide = this.refs.slides[index];

        if (!slide) {
            return Promise.reject(new Error(`No virtual tour found at index ${index}`));
        }

        return new Promise((resolve) => {
            slide.src = slide.dataset.src;
            resolve();
        });
    }

    _onIntersect(entries, observer) {
        const shouldInit = entries.some(({ isIntersecting }) => isIntersecting);
        if (shouldInit) {
            observer.unobserve(this.element);
            this._initSlide(0);
        }
    }
}
