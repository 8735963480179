import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ParticipatingDevelopmentsCard from './participating-developments-card';

export default class ParticipatingDevelopmentsCardComponent extends DcBaseComponent {

    static getNamespace() {
        return 'participating-developments-card';
    }

    onInit() {
        this.list = this.getChildAttribute(this.element, 'list') === 'true';
        this.render();
    }

    render() {
        ReactDOM.render(<ParticipatingDevelopmentsCard item={this.options}
            list={this.list}/>, this.element);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
