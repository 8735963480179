import React from 'react';
import PropTypes from 'prop-types';
import api from 'general/js/api';
import Spinner from "../../spinner/js/spinner";
import SequentialRequestsProxy from 'general/js/api/sequential-requests-proxy';
import TimeSlots from "./time-slots";
import DatePicker from "./date-picker";
import axios from "axios";

class TimeSlotPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            months: [],
            days: [],
            selectedDate: null,
            slots: [],
            selectedSlot: null,
            isLoading: false,
        };

        this.api = new SequentialRequestsProxy(api);
    }

    componentDidMount() {
        this.fetchInitialData();
    }

    fetchInitialData() {
        const { initDataUrl } = this.props;

        this.setState({ isLoading: true });
        this.api.get(initDataUrl).then((response) => {
            const { months, visitDays } = response.data;
            this.setState({
                months,
                days: visitDays,
                isLoading: false,
            });
        }, (error) => {
            if (!axios.isCancel(error)) {
                this.setState({ isLoading: false });
            }
        });
    }

    onMonthChange = (monthIndex) => {
        this.loadMonthDays(monthIndex);
    };

    loadMonthDays(monthIndex) {
        const { getDaysUrl } = this.props;
        const { months } = this.state;
        const selectedMonth = months[monthIndex];

        this.setState({ isLoading: true });
        this.api.get(getDaysUrl, {
            params: {
                month: selectedMonth.number,
                year: selectedMonth.year
            }
        }).then((response) => {
            this.setState({ isLoading: false, days: response.data });
        }, (error) => {
            if (!axios.isCancel(error)) {
                this.setState({ isLoading: false });
            }
        });
    }

    onDayClick = (date) => {
        this.loadDate(date);
    };

    onSlotClick = (slot) => {
        const { onSelect } = this.props;
        this.setState({ selectedSlot: slot });
        onSelect(slot);
    };

    refresh() {
        if (this.state.selectedDate) {
            this.loadDate(this.state.selectedDate);
        }
    }

    loadDate(date) {
        const { getTimeSlotUrl, onSelect } = this.props;

        this.setState({ selectedDate: date, isLoading: true });
        onSelect(null);
        this.api.get(getTimeSlotUrl, {
            params: {
                date,
            }
        }).then((response) => {
            this.setState({
                isLoading: false,
                slots: response.data
            });
        }, (error) => {
            if (!axios.isCancel(error)) {
                this.setState({ isLoading: false });
            }
        });
    }

    render() {
        const { months, days, selectedDate, isLoading, slots, selectedSlot } = this.state;

        return (
            <div className="time-slot-picker">
                <Spinner isActive={isLoading} isFaded={true}/>
                <DatePicker
                    months={months}
                    days={days}
                    selectedDate={selectedDate}
                    onDayClick={this.onDayClick}
                    onMonthChange={this.onMonthChange}/>
                <TimeSlots
                    slots={slots}
                    onSlotClick={this.onSlotClick}
                    selectedSlot={selectedSlot}/>
            </div>
        );
    }
}

TimeSlotPicker.propTypes = {
    initDataUrl: PropTypes.string.isRequired,
    getDaysUrl: PropTypes.string.isRequired,
    getTimeSlotUrl: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired
};

export default TimeSlotPicker;
