import GoogleMarker from '../google-marker';
import pinGrey from '../../img/pin-public-house-grey.svg';
import pinGreen from '../../img/pin-public-house-green.svg';

export default class GoogleMarkerPublicHouse extends GoogleMarker {
    getIconGrey() {
        return pinGrey;
    }

    getIconGreen() {
        return pinGreen;
    }
}
