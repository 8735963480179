import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';

class VacanciesSearchZeroResults extends React.PureComponent {
    render() {
        const { zeroResults } = this.props;

        return (
            <div className="vacancies-search__zero-results">
                <div
                    className="richtext"
                    dangerouslySetInnerHTML={{ __html: zeroResults.message }}
                />
            </div>
        );
    }
}

VacanciesSearchZeroResults.propTypes = {
    zeroResults: PropTypes.shape({
        message: PropTypes.string.isRequired
    }).isRequired
};

export default withContext(VacanciesSearchZeroResults, {
    zeroResults: 'zeroResults'
});
