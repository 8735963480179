import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentsSearchFilters from '../components/developments-search-filters';
import DevelopmentsSearchSorting from '../components/developments-search-sorting';
import { withContext } from '../context';
import SelectedFilters from '../components/selected-filters';
import { getDirtyFiltersConfigs } from '../../../listing-v3/js/listing-helper';

class DevelopmentsSearchControlsSection extends React.PureComponent {
    render() {
        const {
            filtersConfig, filters, onFiltersApply, isFiltersMobile, appliedFilters
        } = this.props;

        if (!filtersConfig || filtersConfig.length === 0) {
            return null;
        }

        const sortConfig = filtersConfig.find(filter => filter.isSort);
        const restFilters = filtersConfig.filter(filter => filter !== sortConfig);
        const mainFiltersConfigs = filtersConfig.filter(config => !config.isSort);
        const dirtyFiltersConfigs = getDirtyFiltersConfigs(appliedFilters, mainFiltersConfigs);

        return (
            <div className="developments-search__controls-section">
                <div className="section">
                    <div className="container">
                        <div className="developments-search__controls-section-inner-v2">
                            <DevelopmentsSearchFilters filtersConfig={restFilters}
                                isFiltersMobile={isFiltersMobile}/>

                            {sortConfig && isFiltersMobile ?
                                <DevelopmentsSearchSorting sortConfig={sortConfig}
                                    filters={filters}
                                    onFiltersApply={onFiltersApply}
                                    isFiltersMobile={isFiltersMobile} /> : null}
                        </div>

                        <div className="developments-search__selected-filters-v2">
                            <SelectedFilters
                            filters={appliedFilters}
                            selectedFiltersConfigs={dirtyFiltersConfigs}
                            onFiltersApply={onFiltersApply}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

DevelopmentsSearchControlsSection.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    isFiltersMobile: PropTypes.bool.isRequired,
    appliedFilters: PropTypes.object.isRequired
};

export default withContext(DevelopmentsSearchControlsSection, {
    filtersConfig: 'filtersConfig',
    filters: 'filters',
    onFiltersApply: 'onFiltersApply',
    appliedFilters: 'appliedFilters',
    isFiltersMobile: 'isFiltersMobile',
});
