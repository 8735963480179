import React from 'react';
import DevelopmentIcon from '../../developments-predictive-search/development-icon';

function GenericLocationSearchAutocomplete(props) {
    const getSuggestions = () => {
        const suggestions = [];

        if (props.searchAutocomplete && props.searchAutocomplete.groups) {
            props.searchAutocomplete.groups.forEach((group, groupIndex) => {
                if (group.suggestions) {
                    suggestions.push(<div className="generic-location-search-autocomplete__category" key={`${props.renderId}-group-${groupIndex}`}>{group.text}</div>)
                    group.suggestions.forEach((suggestion, suggestionIndex) => {
                        suggestions.push(
                            <button
                                className={`generic-location-search-autocomplete__button ${suggestion.id === props.searchAutocompleteSelectedId ? 'generic-location-search-autocomplete__button--selected' : ''}`}
                                key={`${props.renderId}-group-${groupIndex}-suggestion-${suggestionIndex}`}
                                onClick={() => props.onSearchAutocomplete(suggestion.query ? suggestion.query : suggestion.id, suggestion.query ? false : true, suggestion.text, suggestion.url)}
                                onFocus={() => props.onSearchAutocompleteFocusHover(suggestion.id)}
                                onMouseEnter={() => props.onSearchAutocompleteFocusHover(suggestion.id)}
                                role="option"
                                type="button">{suggestion.text}{suggestion.productType && <DevelopmentIcon productType={suggestion.productType} />}</button>
                        );
                    });
                }
            });
        } else if (props.searchAutocompleteActive) {
            suggestions.push(<div className="generic-location-search-autocomplete__message generic-location-search__preloader" key="autocomplete-preloader">Loading suggestions...</div>);
        }

        return suggestions;
    }

    return (
        <div aria-label="Suggested locations" aria-live="polite" className={`generic-location-search-autocomplete ${props.wide ? 'generic-location-search-autocomplete--wide' : ''}`} id={`${props.renderId}-autocomplete`} role="listbox">
            {getSuggestions()}
        </div>
    )
}

export default GenericLocationSearchAutocomplete;
