import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '../../../general/js/dc/dc-base-component';
import QuickSearchBordered from './quick-search-bordered';

export default class QuickSearchBorderedComponent extends DcBaseComponent {
    static getNamespace() {
        return 'quick-search-bordered';
    }

    onInit() {
        const {
            defaultSearchUrl,
            predictiveSearchOptions
        } = this.options;

        ReactDOM.render(
            <QuickSearchBordered
                defaultSearchUrl={defaultSearchUrl}
                predictiveSearchOptions={predictiveSearchOptions}
            />,
            this.element
        );
    }
}
