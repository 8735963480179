import React from 'react';
import PropTypes from 'prop-types';

import MobileFiltersCheckbox from './mobile-filters-checkbox';
import trackerService from '@General/js/tracker-service.js'

const MobileFiltersCheckboxList = ({ config, onCheckboxChange }) => {
    const { options } = config;

    return (
        <div className="campaign-mobile-filters__checkbox-list">
            {options.map((option) => {
                return (
                    <MobileFiltersCheckbox
                        config={config}
                        key={option.value}
                        id={option.value}
                        value={option.value}
                        text={option.mobileActiveText}
                        onCheckboxChange={(name, value) => {
                            onCheckboxChange(name, value);
                            trackerService.track(config.clickTrigger);                            
                        }} />
                );
            })}
        </div>
    );
};

MobileFiltersCheckboxList.propTypes = {
    config: PropTypes.array.isRequired,
    onCheckboxChange: PropTypes.func.isRequired,
};

export default MobileFiltersCheckboxList;
