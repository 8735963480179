import Filter from './filter';
import BedroomFilterOption from './bedroom-filter-option';

export default class BedroomFilter extends Filter {
    constructor({ title = 'Bedrooms', options }) {
        super({ title });

        this.options = this.setOptions({ ClassType: BedroomFilterOption }, options);
        this.setInitialActiveOption();
    }
}
