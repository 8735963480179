import React from 'react';
import PropTypes from 'prop-types';

class SlotButton extends React.Component {
    getStateClasses() {
        const { selected, disabled } = this.props;
        return `${selected ? 'slot-button--selected' : ''} ${disabled ? 'slot-button--disabled' : ''}`;
    }

    render() {
        const {
            mixinClass, onClick, disabled, selected
        } = this.props;

        return (
            <button type="button"
                className={`btn slot-button ${this.getStateClasses()} ${mixinClass}`}
                onClick={onClick}
                disabled={disabled}>
                <div className="slot-button__content">
                    {this.props.children}
                </div>
                <div className={`slot-button__validity-state-icon ${selected ? 'is-valid' : ''}`}>
                    <div className="validity-state-icon"/>
                </div>
                <svg className="slot-button__crossing"
                    width="100%"
                    height="100%"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="none">
                    <line x1="100" y1="0" x2="0" y2="100" strokeWidth="4px"/>
                </svg>
            </button>
        );
    }
}

SlotButton.defaultPtops = {
    mixinClass: '',
    disabled: false,
};

SlotButton.propTypes = {
    selected: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    mixinClass: PropTypes.string,
    onClick: PropTypes.func,
};

export default SlotButton;
