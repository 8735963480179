export default class Overlay {
    constructor(id, className) {
        this.id = id;
        this.className = className;
        this.retainers = {};
        this.isShown = false;
        this._element = document.getElementById(this.id);
        this.overlayClickCallbacks = [];
        this.initElement();
    }

    // init in advance to prevent layout blink on first show
    initElement() {
        const fakeElement = this.getElement();
        fakeElement.addEventListener('click', this._invokeOverlayClick);
    }

    show(retainerId) {
        const element = this.getElement();
        if (element) {
            this.retainers[retainerId] = true;
            if (!this.isShown) {
                element.classList.add('is-visible');
                this.isShown = true;
            }
        }
    }

    hide(retainerId) {
        const element = this.getElement();
        if (element) {
            this.retainers[retainerId] = false;
            if (this.isFree()) {
                element.classList.remove('is-visible');
                this.isShown = false;
            }
        }
    }

    isFree() {
        return Object.keys(this.retainers).every(
            retainerId => this.retainers[retainerId] === false
        );
    }

    getElement() {
        if (!this._element) {
            this._element = document.createElement('div');
            this._element.id = this.id;
            this._element.className = this.className;
            document.body.appendChild(this._element);
        }
        return this._element;
    }

    subscribeOnOverlayClick(cb) {
        this.overlayClickCallbacks.push(cb);

        // return unsubscribe method
        return () => {
            this.overlayClickCallbacks = this.overlayClickCallbacks.filter(
                storedCb => storedCb !== cb
            );
        };
    }

    _invokeOverlayClick = e => {
        this.overlayClickCallbacks.forEach(cb => cb());
    };
}
