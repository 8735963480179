const MARKER_TYPE_DEVELOPMENT = 'development';

const MARKER_TYPE_BANK = 'bank';
const MARKER_TYPE_BELL = 'bell';
const MARKER_TYPE_BUS = 'bus';
const MARKER_TYPE_CINEMA = 'cinema';
const MARKER_TYPE_COFFEE_SHOP = 'coffee-shop';
const MARKER_TYPE_DENTIST = 'dentist';
const MARKER_TYPE_DOCTOR = 'doctor';
const MARKER_TYPE_HAIRDRESSER = 'hairdresser';
const MARKER_TYPE_KEY = 'key';
const MARKER_TYPE_LEISURE = 'leisure';
const MARKER_TYPE_LIBRARY = 'library';
const MARKER_TYPE_MUSEUM = 'museum';
const MARKER_TYPE_OPEN_SPACE = 'open-space';
const MARKER_TYPE_OPTICIANS = 'opticians';
const MARKER_TYPE_PETROL_STATION = 'petrol-station';
const MARKER_TYPE_PHARMACY = 'pharmacy';
const MARKER_TYPE_PLACE_OF_WORSHIP = 'place-of-worship';
const MARKER_TYPE_POST = 'post';
const MARKER_TYPE_PUBLIC_HOUSE = 'public-house';
const MARKER_TYPE_RESTAURANT = 'restaurant';
const MARKER_TYPE_SHOPPING = 'shopping';
const MARKER_TYPE_SPADE = 'spade';
const MARKER_TYPE_THEATRE = 'theatre';
const MARKER_TYPE_TRAIN = 'train';

const DEFAULT_POPUP_VERTICAL_OFFSET = 90;

const MAP_TYPE_DYNAMIC = 'dynamic';
const MAP_TYPE_STATIC = 'static';

export default {
    MARKER_TYPE_DEVELOPMENT,
    MARKER_TYPE_BANK,
    MARKER_TYPE_BELL,
    MARKER_TYPE_BUS,
    MARKER_TYPE_CINEMA,
    MARKER_TYPE_COFFEE_SHOP,
    MARKER_TYPE_DENTIST,
    MARKER_TYPE_DOCTOR,
    MARKER_TYPE_HAIRDRESSER,
    MARKER_TYPE_KEY,
    MARKER_TYPE_LEISURE,
    MARKER_TYPE_LIBRARY,
    MARKER_TYPE_MUSEUM,
    MARKER_TYPE_OPEN_SPACE,
    MARKER_TYPE_OPTICIANS,
    MARKER_TYPE_PETROL_STATION,
    MARKER_TYPE_PHARMACY,
    MARKER_TYPE_PLACE_OF_WORSHIP,
    MARKER_TYPE_POST,
    MARKER_TYPE_PUBLIC_HOUSE,
    MARKER_TYPE_RESTAURANT,
    MARKER_TYPE_SHOPPING,
    MARKER_TYPE_SPADE,
    MARKER_TYPE_THEATRE,
    MARKER_TYPE_TRAIN,
    DEFAULT_POPUP_VERTICAL_OFFSET,
    MAP_TYPE_DYNAMIC,
    MAP_TYPE_STATIC
};
