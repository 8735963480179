import React from 'react';
import PropTypes from 'prop-types';
import OverlayWrapper from 'general/js/overlay/js/overlay-wrapper.jsx';
import defaultOverlay from 'general/js/overlay/js/default-overlay';
import StandaloneDevelopmentsPredictiveSearch from '../../developments-predictive-search/standalone-developments-predictive-search';

export default class QuickSearchMini extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        };
    }

    onActivate = () => {
        this.setState({ isActive: true });
    };

    onDeactivate = () => {
        this.setState({ isActive: false });
    };

    renderButton = (buttonClass, buttonIconClass) => {
        return (
            <button
                className={`btn quick-search-mini__search-button ${buttonClass}`}
                type="submit"
                aria-label={'Submit button'}
            >
                <div className="button-content">
                    <svg
                        className={`icon quick-search-mini__search-button-icon ${buttonIconClass}`}
                        width="18"
                        height="17"
                        focusable="false"
                        role="img" aria-hidden="true"
                    >
                        <use xlinkHref="#icon-search-long" />
                    </svg>
                </div>
            </button>
        );
    };

    render() {
        const { isActive } = this.state;
        const { predictiveSearchOptions, defaultSearchUrl } = this.props;

        return (
            <OverlayWrapper
                overlay={defaultOverlay}
                isActive={isActive}
                retainerId="quick-search-mini-input"
            >
                <div className={`quick-search-mini ${isActive ? 'is-active' : ''}`}>
                    <div className="quick-search-mini__predictive">
                        <StandaloneDevelopmentsPredictiveSearch
                            defaultSearchUrl={defaultSearchUrl}
                            predictiveSearchOptions={predictiveSearchOptions}
                            onActivate={this.onActivate}
                            onDeactivate={this.onDeactivate}
                            renderButton={this.renderButton}
                            inputClass={'quick-search-mini__predictive-input'}
                            dropdownClass={'quick-search-mini__predictive-dropdown'}
                        />
                    </div>
                </div>
            </OverlayWrapper>
        );
    }
}

QuickSearchMini.propTypes = {
    defaultSearchUrl: PropTypes.string.isRequired,
    predictiveSearchOptions: PropTypes.object.isRequired
};
