import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import Checkbox from '@Components/checkbox-v2/js/checkbox-v2.jsx';
import Radiobutton from '@Components/radiobutton-v2/js/radiobutton-v2.jsx';
import { CONTROL_TYPE_CHECKLIST, CONTROL_TYPE_RADIOGROUP } from '../constants.js';
import CheckboxPopupDescription from './checkbox-popup-description.jsx';
import trackerService from '@General/js/tracker-service.js'

export default class DropdownChecklist extends React.Component {
    constructor(props) {
        super(props);

        this.triggerRef = React.createRef();
        this.fieldId = uniqueId('filter-field-');
    }

    onCheckboxChange = (changedCheckboxValue, isChecked) => {
        const currentValue = this.props.value;
        let newValue = null;
        if (isChecked) {
            newValue = [...currentValue, changedCheckboxValue];
        } else {
            newValue = currentValue.filter((value) => value !== changedCheckboxValue);
        }
        this.props.onChange(newValue);
    };

    onRadioChange = (changedCheckboxValue) => {
        const newValue = changedCheckboxValue;
        this.props.onChange(newValue);
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.props.onApply();
    };

    onKeyDown = (e) => {
        if (e.which === 27) {
            this.props.onClose();
            if (this.props.isActive) {
                this.triggerRef.current.focus();
            }
        }
    };

    onButtonClick = () => {
        this.props.onClick();
    };

    render() {
        const {
            label,
            options,
            type,
            value,
            isActive,
            isHidden,
            afterPopupDescriptionOpen,
            isDisabled,
            clickTrigger
        } = this.props;

        const checkedValuesCount = options.filter((option) => {
            return value.includes(option.value) && option.value !== '';
        }).length;

        const checkedValuesCountShow =
            type === CONTROL_TYPE_CHECKLIST ? `(${checkedValuesCount})` : null;

        const checkedValue = options.filter((option) => {
            return value === option.value;
        })[0];

        const labelText =
            type === CONTROL_TYPE_RADIOGROUP && checkedValuesCount ? checkedValue.text : label;

        return (
            <div
                onKeyDown={this.onKeyDown}
                className={`search-desktop-filters-v2__control ${isActive ? 'is-active' : ''} ${
                    isHidden ? 'is-hidden' : ''
                }`}
            >
                <button
                    ref={this.triggerRef}
                    className={classNames({
                        'search-desktop-filters-v2__control-main': true,
                        'search-desktop-filters-v2__control-main--disabled': isDisabled,
                    })}
                    aria-label={`filter ${label}`}
                    aria-haspopup="listbox"
                    aria-expanded={isActive}
                    onClick={() => {
                        if (!isDisabled) {
                            this.onButtonClick();
                            trackerService.track(clickTrigger);
                        }
                    }}
                    data-dc-gtm-selector="ExplorePropertiesDropdownTrigger"
                >
                    <div className="search-desktop-filters-v2__control-label">
                        {labelText} {checkedValuesCountShow}
                    </div>
                    <svg
                        width="13"
                        height="8"
                        className="icon search-desktop-filters-v2__control-arrow"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </button>
                <div className="search-desktop-filters-v2__control-dropdown">
                    <form onSubmit={this.onSubmit}>
                        <ul className="search-desktop-filters-v2__control-checklist" role="listbox">
                            {options.map((option, index) => {
                                let isChecked;
                                if (type === CONTROL_TYPE_RADIOGROUP) {
                                    isChecked = option.value === value;
                                    return (
                                        <li
                                            className="search-desktop-filters-v2__control-checklist-item"
                                            role="option"
                                            aria-selected={isChecked}
                                            key={index}
                                        >
                                            <Radiobutton
                                                isDisabled={option.isDisabled}
                                                isChecked={isChecked}
                                                id={this.fieldId}
                                                text={option.text}
                                                gtmSelector="ExplorePropertiesDropdownCheckbox"
                                                value={option.value}
                                                onChange={(e) =>
                                                    this.onRadioChange(
                                                        option.value,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            <CheckboxPopupDescription
                                                text={option.popupText}
                                                url={option.popupUrl}
                                                afterOpen={afterPopupDescriptionOpen}
                                            />
                                        </li>
                                    );
                                }
                                isChecked = value.includes(option.value);
                                return (
                                    <li
                                        className="search-desktop-filters-v2__control-checklist-item"
                                        role="option"
                                        aria-selected={isChecked}
                                        key={index}
                                    >
                                        <Checkbox
                                            isDisabled={option.isDisabled}
                                            isChecked={isChecked}
                                            text={option.text}
                                            gtmSelector="ExplorePropertiesDropdownCheckbox"
                                            onChange={(e) =>
                                                this.onCheckboxChange(
                                                    option.value,
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        <CheckboxPopupDescription
                                            text={option.popupText}
                                            url={option.popupUrl}
                                            afterOpen={afterPopupDescriptionOpen}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </form>
                </div>
            </div>
        );
    }
}

DropdownChecklist.defaultProps = {
    value: [],
};

DropdownChecklist.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    type: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    isActive: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isHidden: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    afterPopupDescriptionOpen: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
