import React from 'react';
import PropTypes from 'prop-types';
import { ParticipatingDevelopmentsSearchContext } from './context';
import inMemoryFiltersListing from '../../listing/js/in-memory-filters-listing';
import ParticipatingDevelopmentsSearchControl
    from './containers/participating-developments-search-control';
import ParticipatingDevelopmentsSearchContent
    from './containers/participating-developments-search-content';
import Spinner from '../../spinner/js/spinner';

class ParticipatingDevelopmentsSearch extends React.Component {
    getContextValue() {
        const {
            items,
            isInitialRequestFulfilled,
            onFiltersApply,
            zeroResults,
        } = this.props;

        return {
            items,
            isInitialRequestFulfilled,
            onFiltersApply,
            zeroResults,
        };
    }

    render() {
        const {
            isLoading, isInitialRequestFulfilled, animatingContentRef, predictiveSearchOptions, items
        } = this.props;

        return (
            <ParticipatingDevelopmentsSearchContext.Provider value={this.getContextValue()}>
                <div className="participating-developments-search">
                    <Spinner isActive={isLoading}/>
                    <ParticipatingDevelopmentsSearchControl options={predictiveSearchOptions}/>
                    <ParticipatingDevelopmentsSearchContent
                        isInitialRequestFulfilled={isInitialRequestFulfilled}
                        animatingContentRef={animatingContentRef}
                        totalCount={items.length}
                    />
                </div>
            </ParticipatingDevelopmentsSearchContext.Provider>
        );
    }
}

ParticipatingDevelopmentsSearch.propTypes = {
    zeroResults: PropTypes.object.isRequired,
    predictiveSearchOptions: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    onFiltersApply: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    animatingContentRef: PropTypes.func.isRequired,
};

export default inMemoryFiltersListing(ParticipatingDevelopmentsSearch, {
    makeInitialRequest: false
});
