import React from 'react';
import PropTypes from 'prop-types';
import LocationButton from './location-button';
import StandaloneDevelopmentsPredictiveSearch from '../../developments-predictive-search/standalone-developments-predictive-search';

const TYPE_HEADER = 'header';
const TYPE_HERO = 'hero';
const TYPE_PROPERTY = 'property';

export default class QuickSearch extends React.Component {
    getTypeClass() {
        switch (this.props.type) {
            case TYPE_HEADER:
                return 'quick-search--header';
            case TYPE_HERO:
                return 'quick-search--hero';
            case TYPE_PROPERTY:
                return 'quick-search--property';
            default:
                return '';
        }
    }

    renderButton = (mainButtonClass, buttonIconClass, trackingClass) => {
        return (
            <button
                className={`btn ${mainButtonClass} ${trackingClass}`}
                type="submit"
                aria-label={'Find homes'}
            >
                <div className="button-content">
                    Find homes
                    <svg
                        className={`icon ${buttonIconClass}`}
                        width="18"
                        height="17"
                        focusable="false"
                        role="img"
                        aria-hidden="true"
                    >
                        <use xlinkHref="#icon-search-long" />
                    </svg>
                </div>
            </button>
        );
    };

    render() {
        const {
            predictiveSearchOptions,
            searchByLocation,
            defaultSearchUrl,
            buttonTrackingClass,
            campaignSearchUrl,
            placeMatchUrl,
        } = this.props;

        return (
            <div className={`quick-search ${this.getTypeClass()}`}>
                <div className="quick-search__predictive">
                    <StandaloneDevelopmentsPredictiveSearch
                        campaignSearchUrl={campaignSearchUrl}
                        predictiveSearchOptions={predictiveSearchOptions}
                        defaultSearchUrl={defaultSearchUrl}
                        buttonTrackingClass={buttonTrackingClass}
                        placeMatchUrl={placeMatchUrl}
                        renderButton={this.props.renderButton || this.renderButton}
                    />
                </div>
                {searchByLocation && <LocationButton searchByLocation={searchByLocation} />}
            </div>
        );
    }
}

QuickSearch.propTypes = {
    predictiveSearchOptions: PropTypes.object.isRequired,
    searchByLocation: PropTypes.object.isRequired,
    defaultSearchUrl: PropTypes.string.isRequired,
    campaignSearchUrl: PropTypes.string,
    buttonTrackingClass: PropTypes.string,
    type: PropTypes.string,
};
