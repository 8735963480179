export const UNMATCHED_DEVELOPMENT = 'UNMATCHED';
export const PROPERTY_TYPE_APARTMENT = 'Apartment';
export const PROPERTY_TYPE_BUNGALOW = 'Bungalow';
export const PROPERTY_TYPE_COTTAGE = 'Cottage';
export const OWNERSHIP_TYPE_SALE = 'sale';
export const OWNERSHIP_TYPE_RENT = 'rent';
export const OWNERSHIP_TYPE_OPSO = 'opso';
export const POA_FULL_TAG = 'Properties not released';
export const POA_SMALL_TAG = 'Properties not released';
export const FR_TAG = 'Fully reserved';
export const AN_TAG = 'Available Now';
