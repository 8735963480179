import React from 'react';
import PropTypes from 'prop-types';
import nanoId from 'nano-id';
import { withContext } from '../context';
import DevelopmentsSearchList from './developments-search-list';
import DevelopmentsSearchVillage from './development-search-village';
import SearchResultsMessage from '../../../zero-search-results/js/search-results-message';
import { devSearchTypeItem } from '../dev-search-types.js';

class DevelopmentsSearchResults extends React.PureComponent {
    hasNoExactMatches() {
        const { isInitialRequestFulfilled, hasExactMatches } = this.props;

        return isInitialRequestFulfilled && !hasExactMatches;
    }

    render() {
        const { markers, noExactMatches, onCardScroll, items } = this.props;
        let hasVillage = false;

        for (const item of items) {
            if (item.development && item.development.address && item.development.address.toLowerCase().includes('wimborne')) {
                hasVillage = true;
                break;
            }
        };

        return (
            <div className="developments-search__list-wrapper">
                {this.hasNoExactMatches() ? (
                    <div className="developments-search__no-exact-matches">
                        <SearchResultsMessage html={noExactMatches.message} />
                    </div>
                ) : null}
                {hasVillage && <DevelopmentsSearchVillage />}
                <DevelopmentsSearchList
                    key={nanoId()}
                    items={items}
                    markers={markers}
                    onCardScroll={onCardScroll}
                />
            </div>
        );
    }
}

DevelopmentsSearchResults.defaultProps = {
    hasExactMatches: true,
};

DevelopmentsSearchResults.propTypes = {
    markers: PropTypes.object,
    hasExactMatches: PropTypes.bool,
    noExactMatches: PropTypes.shape({
        message: PropTypes.string.isRequired,
    }).isRequired,
    isInitialRequestFulfilled: PropTypes.bool.isRequired,
    onCardScroll: PropTypes.func,
    items: PropTypes.arrayOf(devSearchTypeItem),
};

export default withContext(DevelopmentsSearchResults, {
    items: 'items',
    hasExactMatches: 'hasExactMatches',
    noExactMatches: 'noExactMatches',
    isInitialRequestFulfilled: 'isInitialRequestFulfilled',
});
