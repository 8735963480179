import DcBaseComponent from 'general/js/dc/dc-base-component';
import {
    setContentToState,
    collapse,
    expand,
    resetContentStyles,
} from 'general/js/animations/accordion';
import FormValidityExpander from './form-validity-expander';

export default class Accordion extends DcBaseComponent {
    constructor(element, extendedOptions = {}) {
        super(element);

        this.options = { ...this.options, ...extendedOptions };
        this._isAnimating = false;

        if (this.element.classList.contains('is-expanded')) {
            this._isExpanded = true;
        } else if (this.element.classList.contains('is-collapsed')) {
            this._isExpanded = false;
        } else {
            throw new Error(
                'You have to specify one of accordion state: is-collapsed, is-expanded'
            );
        }

        this.wasExpanded = this._isExpanded;

        this._onClickTrigger = this._onClickTrigger.bind(this);
        this._onKeydownTrigger = this._onKeydownTrigger.bind(this);
        this._onAnimationComplete = this._onAnimationComplete.bind(this);

        this.triggers = Array.isArray(this.refs.trigger) ? this.refs.trigger : [this.refs.trigger];
    }

    static getNamespace() {
        return 'accordion';
    }

    static getRequiredRefs() {
        return ['trigger', 'content'];
    }

    _getIsExpanded = () => {
        return this._isExpanded;
    };

    onInit() {
        setContentToState(this.refs.content, this._isExpanded);
        this.formValidityExpander = new FormValidityExpander(
            this.element,
            this.expand,
            this._getIsExpanded
        );
        this.setAccessibilityState(this._isExpanded);
        this._addListeners();
    }

    _addListeners() {
        this.triggers.forEach((x) => {
            this.addListener(x, 'click', this._onClickTrigger);
            this.addListener(x, 'keydown', this._onKeydownTrigger);
        });
    }

    _onClickTrigger(event) {
        if (typeof event !== 'undefined' && typeof event.preventDefault === 'function') {
            event.preventDefault();
        }

        if (this._isAnimating) return;

        if (this.options.isDelegatedControl) {
            this.options.onClickCb(this);
        } else if (this._isExpanded) {
            this.collapse();
        } else {
            this.expand();
        }

        if (typeof this.options.onAfterClickCb === 'function') {
            this.options.onAfterClickCb(this);
        }
    }

    _onKeydownTrigger(event) {
        const keyCode = event.which;

        switch (keyCode) {
            case 13:
            case 32:
                this._onClickTrigger(event);
                break;
            default:
                break;
        }
    }

    expand = () => {
        if (!this.wasExpanded) {
            this.wasExpanded = true;
        }
        this._isAnimating = true;
        this.element.classList.remove('is-collapsed');
        this.element.classList.add('is-expanding');
        expand(this.refs.content, this._onAnimationComplete, 100);
    };

    collapse() {
        this._isAnimating = true;
        this.element.classList.remove('is-expanded');
        this.element.classList.add('is-collapsing');

        collapse(this.refs.content, this._onAnimationComplete, 100);
    }

    _onAnimationComplete = () => {
        this._isAnimating = false;
        this._isExpanded = !this._isExpanded;

        if (this._isExpanded) {
            this.element.classList.remove('is-expanding');
            this.element.classList.add('is-expanded');
        } else {
            this.element.classList.remove('is-collapsing');
            this.element.classList.add('is-collapsed');
        }

        this.setAccessibilityState(this._isExpanded);
    };

    setAccessibilityState(isExpanded) {
        this.triggers.forEach((x) => {
            x.setAttribute('aria-expanded', isExpanded);
        });
        this.refs.content.setAttribute('aria-hidden', !isExpanded);
    }

    onDestroy() {
        resetContentStyles(this.refs.content);
        this.formValidityExpander.destroy();
    }
}
