import React from 'react';
import PropTypes from 'prop-types';
import DevelopmentsSearchItem from './developments-search-item';

class DevelopmentsSearchGroup extends React.PureComponent {
    render() {
        const { group, markers } = this.props;
        return (
            <div className="developments-search__group">
                {group.title ? (
                    <h2 className="developments-search__group-title">{group.title}</h2>
                ) : null}
                <div className="developments-search__group-items">
                    {group.items.map(item => (
                        <DevelopmentsSearchItem
                            key={item.id}
                            item={item}
                            marker={markers[item.id]}
                        />
                    ))}
                </div>
            </div>
        );
    }
    

}
 

DevelopmentsSearchGroup.defauptProps = {
    markers: {}
};

DevelopmentsSearchGroup.propTypes = {
    markers: PropTypes.object,
    group: PropTypes.object.isRequired
};

export default DevelopmentsSearchGroup;
