import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { webkitLineClamp } from 'webkit-line-clamp';
import { devSearchTypeDevelopment } from '@Components/developments-search-all-ownership-types/js/dev-search-types.js';
import analyticsService from '@Assets/general/js/analytics-service';
const GA_EVENT_MAP_CLICKTHROUGH = "mapClickthrough";
export default function DevSectionPlot(props) {
    const clampedRef = useCallback((node) => {
        // this callback is a polyfill for IE11
        if (node !== null && typeof node.style.webkitLineClamp === 'undefined') {
            webkitLineClamp(node, 3);

            if (node.children[0]) {
                webkitLineClamp(node.children[0], 3);
            }
        }
    }, []);
    const developmentClick = () => {

        if(props.item) {

            const data = {
                'developmentName': props.item.developmentName,
                'developmentId': props.item.developmentId,
                'developmentDivision': props.item.divisionName,
                'developmentProduct': props.item.productType,
                'developmentStatus': props.item.statusText,
                'salesforcePlotID': props.item.siteId,
                'RAGStatus': props.item.ragStatus,
            }
            analyticsService._send(GA_EVENT_MAP_CLICKTHROUGH, data)
        }
        window.location.href= props.item.buttonUrl;
    }
    return (
        <div className="dev-section-plot">
            <h2 className="dev-section-plot__title">{props.item.developmentName}</h2>
            <p className="dev-section-plot__address">{props.item.address}</p>
            <div
                className="dev-section-plot__desc"
                ref={clampedRef}
                dangerouslySetInnerHTML={{
                    __html: props.item.desc,
                }}
            ></div>
            <div className="dev-section-plot__btn-wrapper">
                <a
                    className="dev-section-plot__btn dev-section-plot__btn--arrow link"
                    onClick={developmentClick}
                    data-dc-gtm-selector={props.gtmSelector}
                >
                    <span className="dev-plot-card__btn-text">{props.item.buttonText}</span>
                    <svg className="dev-plot-card__btn-icon icon" width="8" height="14">
                        <use xlinkHref="#icon-arrow-right-thin"></use>
                    </svg>
                </a>
            </div>
        </div>
    );
}

DevSectionPlot.propTypes = {
    item: devSearchTypeDevelopment,
    gtmSelector: PropTypes.string,
};
