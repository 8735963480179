import React from 'react';
import PropTypes from 'prop-types';
import FaqPredictiveSearch from './faq-predictive-search';
import FaqQuestionPreview from './faq-question-preview';

export default class FaqSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: null
        };
    }

    onSuggestionSelect = question => {
        this.setState({ currentQuestion: question });
    };

    onClearButtonClick = () => {
        this.setState({ currentQuestion: null });
    };

    render() {
        const { currentQuestion } = this.state;
        const {
            predictiveSearchUrl,
            placeholderMessage,
            noResultsSuggestionText,
            additionText,
            defaultItemsToShow,
            maxItemsToShow
        } = this.props;

        return (
            <div className="faq-search">
                <div className="faq-search__predictive-wrapper">
                    <div role="Search" aria-label="FAQ search" className="faq-search__predictive">
                        <FaqPredictiveSearch
                            defaultItemsToShow={defaultItemsToShow}
                            maxItemsToShow={maxItemsToShow}
                            onSuggestionSelect={this.onSuggestionSelect}
                            onClearButtonClick={this.onClearButtonClick}
                            predictiveSearchUrl={predictiveSearchUrl}
                            placeholderMessage={placeholderMessage}
                            noResultsSuggestionText={noResultsSuggestionText}
                        />
                    </div>
                </div>
                <div className="faq-search__content">
                    <div className="faq-search__content-inner">
                        {currentQuestion && (
                            <FaqQuestionPreview
                                question={currentQuestion}
                                additionText={additionText}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

FaqSearch.propTypes = {
    predictiveSearchUrl: PropTypes.string.isRequired,
    noResultsSuggestionText: PropTypes.string.isRequired,
    additionText: PropTypes.string.isRequired,
    placeholderMessage: PropTypes.string.isRequired,
    defaultItemsToShow: PropTypes.number.isRequired,
    maxItemsToShow: PropTypes.number.isRequired
};
