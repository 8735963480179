import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import DevelopmentsPredictiveSearch from '../../../developments-predictive-search/developments-predictive-search';

class ParticipatingDevelopmentsSearchControl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        };
    }

    onTextChange = text => {
        this.setState({ text });
    };

    renderButton = (buttonClass, buttonIconClass) => {
        return (
            <button className={`btn ${buttonClass}`} type="submit" aria-label={'Find homes'}>
                <div className="button-content">
                    Find homes
                    <svg
                        className={`icon ${buttonIconClass}`}
                        width="18"
                        height="17"
                        focusable="false"
                        role="img" aria-hidden="true"
                    >
                        <use xlinkHref="#icon-search-long" />
                    </svg>
                </div>
            </button>
        );
    };

    onSuggestionSelect = suggestion => {
        const { onFiltersApply } = this.props;
        this.setState({ text: suggestion.text });
        onFiltersApply({ googlePlaceId: suggestion.id });
    };

    render() {
        const { text } = this.state;
        const { options } = this.props;

        return (
            <div className="quick-property-search">
                <div className="container container--narrow">
                    <div className="quick-property-search__inner">
                        <div className="quick-property-search__title">
                            Search for your perfect property
                        </div>
                        <div className={`quick-search quick-search--property`}>
                            <div className="quick-search__predictive">
                                <DevelopmentsPredictiveSearch
                                    {...options}
                                    text={text}
                                    onTextChange={this.onTextChange}
                                    renderButton={this.renderButton}
                                    onSuggestionSelect={this.onSuggestionSelect}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ParticipatingDevelopmentsSearchControl.propTypes = {
    options: PropTypes.shape({
        predictiveSearchUrl: PropTypes.string.isRequired,
        noResultsSuggestionText: PropTypes.string.isRequired,
        noResultsMessage: PropTypes.string.isRequired,
        placeholderMessage: PropTypes.string.isRequired
    }).isRequired,
    onFiltersApply: PropTypes.func.isRequired
};

export default withContext(ParticipatingDevelopmentsSearchControl, {
    onFiltersApply: 'onFiltersApply'
});
