import DcBaseComponent from 'general/js/dc/dc-base-component';
import { setContentToState, expand, collapse } from 'general/js/animations/accordion';

export default class DevelopmentPageSummary extends DcBaseComponent {
    static getNamespace() {
        return 'development-page-summary';
    }

    onInit() {
        const { moreButton, preview, content } = this.refs;
        if (moreButton && preview && content) {
            this._handleInit();
        }
    }

    _handleInit() {
        this.appendMoreButton();
        setContentToState(this.refs.content, false);
    }

    appendMoreButton() {
        const button = this.getToggleButton(this.refs.moreButton, this.refs.preview);
        if (button) {
            button.addEventListener('click', this.onMoreClick);
        }
    }

    getToggleButton(button, container) {
        const childrenCount = container.children.length;
        if (childrenCount > 0) {
            const lastChild = container.children[childrenCount - 1];
            lastChild.insertAdjacentElement('beforeend', button);
            button.classList.add('is-shown');
            this.setAccessibilityAttributes(button, false);
            return button;
        }
    }

    onMoreClick = () => {
        expand(this.refs.content);
        this.hideButton(this.refs.moreButton);
        this.refs.moreButton.removeEventListener('click', this.onMoreClick);
    };

    hideButton(button) {
        this.setAccessibilityAttributes(button, true);
        button.classList.remove('is-shown');
    }

    setAccessibilityAttributes(button, isExpanded) {
        button.setAttribute('aria-expanded', isExpanded);
        this.refs.content.setAttribute('aria-hidden', !isExpanded);
    }
}
