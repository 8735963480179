import React from 'react';
import PropTypes from 'prop-types';

export default class Radiobutton extends React.Component {
    onChange = e => {
        const { isDisabled, isChecked, onChange } = this.props;
        if (!isDisabled || isChecked) {
            onChange(e);
        }
    };

    render() {
        const { isDisabled, isChecked, text, gtmSelector, id, value } = this.props;
        return (
            <div className="checkbox-v2">
                <label className="checkbox-v2__label" data-dc-gtm-selector={gtmSelector}>
                    <input
                        type="radio"
                        className={`${isDisabled ? 'is-disabled' : ''}`}
                        checked={isChecked}
                        name = {id}
                        value={value}
                        onChange={this.onChange}
                    />
                    <span className="checkbox-v2__text">{text}</span>
                </label>
            </div>
        );
    }
}

Radiobutton.defaultProps = {
    isDisabled: false
};

Radiobutton.propTypes = {
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    gtmSelector: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};
