import React from 'react';
import PropTypes from 'prop-types';
import MobileFiltersModal from './mobile-filters-modal.jsx';
import { isFiltersConfigsDirty } from '../../../listing/js/listing-helper';

export default class SearchMobileFilters extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            label: 'Refine results'
        };
    }

    onOuterClick = () => {
        this.close();
    };

    onClick = () => {
        this.open();
    };

    open() {
        this.setState({ isOpened: true });
    }

    close() {
        this.setState({ isOpened: false });
    }

    onChange = (name, value) => {
        this.props.onFiltersChange({ [name]: value });
    };

    onApply = () => {
        this.props.onSearch();
        this.close();
    };

    onClear = () => {
        const { filtersConfig, onFiltersReset } = this.props;
        const configsNames = filtersConfig.map(config => config.name);
        onFiltersReset(configsNames);
        this.close();
    };

    onCloseClick = () => {
        this.close();
    };

    hasDirtyFilters() {
        const { filtersConfig, appliedFilters } = this.props;
        return isFiltersConfigsDirty(filtersConfig, appliedFilters);
    }

    render() {
        const { isOpened, label } = this.state;
        const { filtersConfig, filters } = this.props;

        const hasDirtyFilters = this.hasDirtyFilters();

        return (
            <div className={`search-mobile-filters ${hasDirtyFilters ? 'has-dirty-filters' : ''}`}>
                <button
                    className="search-mobile-filters__control-main"
                    aria-label={label}
                    aria-haspopup="listbox"
                    aria-expanded={isOpened}
                    onClick={this.onClick}
                >
                    <div className="search-mobile-filters__control-label">{label}</div>
                    <svg
                        width="13"
                        height="8"
                        className="icon search-mobile-filters__control-arrow"
                        focusable="false"
                        role="img" aria-hidden="true"
                    >
                        <use xlinkHref="#icon-arrow-down" />
                    </svg>
                </button>
                <MobileFiltersModal
                    isOpened={isOpened}
                    filtersConfig={filtersConfig}
                    filters={filters}
                    clearEnabled={hasDirtyFilters}
                    onClear={this.onClear}
                    onChange={this.onChange}
                    onApply={this.onApply}
                    onCloseClick={this.onCloseClick}
                    onOuterClick={this.onOuterClick}
                />
            </div>
        );
    }
}

SearchMobileFilters.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    appliedFilters: PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onFiltersReset: PropTypes.func.isRequired
};
