import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class PrintElementComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this.button = this.refs.button;
        this.printElement = this.printElement.bind(this);
    }

    static getNamespace() {
        return 'print-element';
    }

    static getRequiredRefs() {
        return ['button'];
    }

    onInit() {
        this.button.addEventListener('click', this.printElement);
    }

    printElement() {
        // clear any old print iframes
        document.querySelectorAll('[data-print-iframe]').forEach(oldIframe => {
            oldIframe.remove();
        });

        // get element to be printed
        const printElement = this.element.cloneNode(true);

        // remove lazy load from images
        printElement.querySelectorAll('img').forEach(image => {
            image.removeAttribute('loading');
        });

        // create iFrame
        const printIframe = document.createElement('iframe');

        printIframe.setAttribute('data-print-iframe', 'true');
        printIframe.setAttribute('scrolling', 'no');
        printIframe.setAttribute('src', 'about:blank');
        printIframe.setAttribute('style', 'border:none;bottom:100%;display:block;height:0;position:fixed;right:100%;visibility:hidden;width:0;');

        // append iFrame to body
        document.body.appendChild(printIframe);

        // get iFrame content
        const printIframeContent = (printIframe.contentWindow || printIframe.contentDocument);

        // add event listener to print when loaded
        printIframe.addEventListener('load', () => {
            printIframeContent.focus();
            printIframeContent.print();
        });

        // get iFrame document
        const printIframeDocument = printIframeContent.document ? printIframeContent.document : printIframeContent;

        // populate iFrame document
        printIframeDocument.write(`<html><head><title>${document.title}</title>`);

        // add style sheets
        document.querySelectorAll('[rel="stylesheet"]').forEach(stylesheet => {
            printIframeDocument.write(stylesheet.outerHTML);
        });

        printIframeDocument.write(`</head><body class="body body--print">${printElement.outerHTML}</body></html>`);
        printIframeDocument.close();
    }
}
