import api from './api';
import app from './app';

export const EVENT_BOOKING_ERROR_VALUE = -1;

class EventsService {
    constructor(apiInstance) {
        this._api = apiInstance;
        /**
         *
         * @type {Map<string, Promise>}
         * @private
         */
        this._cachedRequests = new Map();
        /**
         *
         * @type {Map<string, Object>}
         * @private
         */
        this._cachedEvents = new Map();

        this._eventBookingPercentageUrl = app.getRequiredConfig('EVENT_BOOKING_PERCENTAGE_URL');
        this._eventsListBookingPercentageUrl = app.getRequiredConfig('EVENTS_LIST_BOOKING_PERCENTAGE_URL');
    }

    _getCacheKey(companyId, id) {
        return `${companyId}_${id}`;
    }

    _isInCache(companyId, id) {
        return this._cachedEvents.has(this._getCacheKey(companyId, id));
    }

    _getFromCache(companyId, id) {
        return this._cachedEvents.get(this._getCacheKey(companyId, id));
    }

    _saveToCache(companyId, id, value) {
        this._cachedEvents.set(this._getCacheKey(companyId, id), value);
    }

    _isInRequestsCache(companyId, id) {
        return this._cachedRequests.has(this._getCacheKey(companyId, id));
    }

    _getFromRequestsCache(companyId, id) {
        return this._cachedRequests.get(this._getCacheKey(companyId, id));
    }

    _saveToRequestsCache(companyId, id, value) {
        this._cachedRequests.set(this._getCacheKey(companyId, id), value);
    }

    /**
     * @param {string} companyId
     * @param {string} id
     * @return {Promise<Object>}
     */
    getEventBookingPercentage(companyId, id) {
        if (this._isInCache(companyId, id)) {
            return Promise.resolve(this._getFromCache(companyId, id));
        }
        return this._requestEventBookingPercentage(companyId, id);
    }

    /**
     * @param {string} companyId
     * @param {string[]} ids
     * @return {Promise<Object[]>}
     */
    getEventsListBookingPercentage(companyId, ids) {
        return new Promise((resolve) => {
            const idsToRequest = [];
            let requestListPromise = null;

            // build an array of ids which require making a request
            ids.forEach((id) => {
                if (!this._isInCache(companyId, id)) {
                    idsToRequest.push(id);
                }
            });

            if (idsToRequest.length > 0) {
                requestListPromise = this._requestEventsListBookingPercentage(companyId, idsToRequest)
                    .then(events =>
                        // build a hash-table to get values corresponding ids later
                        idsToRequest.reduce((result, id, index) => {
                            result[id] = events[index];
                            return result;
                        }, {}));
            } else {
                requestListPromise = Promise.resolve({});
            }

            // after all not cached values are fetched we can return the final result
            requestListPromise.then((eventsData) => {
                const result = ids.map(id => (idsToRequest.includes(id) ? eventsData[id] : this._getFromCache(companyId, id)));
                resolve(result);
            });
        });
    }

    /**
     * @param {string} companyId
     * @param {string} id
     * @return {Promise<Object>}
     * @private
     */
    _requestEventBookingPercentage(companyId, id) {
        if (!this._isInRequestsCache(companyId, id)) {
            const request = this._api.get(this._eventBookingPercentageUrl, {
                params: {
                    companyId,
                    id
                }
            }).then((response) => {
                const event = response.data;
                this._saveToCache(companyId, id, event);
                return event;
            }, (error) => {
                console.error(error);
                return EVENT_BOOKING_ERROR_VALUE;
            });
            this._saveToRequestsCache(companyId, id, request);
        }

        return this._getFromRequestsCache(companyId, id);
    }

    /**
     * @param {string} companyId
     * @param {string[]} ids
     * @return {Promise<Object[]>}
     * @private
     */
    _requestEventsListBookingPercentage(companyId, ids) {
        return this._api.get(this._eventsListBookingPercentageUrl, {
            params: {
                companyId,
                ids
            }
        }).then((response) => {
            const eventsList = response.data;
            ids.forEach((id, index) => {
                if (!this._isInCache(companyId, id)) {
                    this._saveToCache(companyId, id, eventsList[index]);
                }
            });
            return eventsList;
        }, (error) => {
            console.error(error);
            return ids.map(_ => EVENT_BOOKING_ERROR_VALUE);
        });
    }
}

export default (new EventsService(api));
