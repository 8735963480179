import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import ArticleSearchListing from './articles-search-listing';

export default class ArticleSearchListinghComponent extends DcBaseComponent {
    static getNamespace() {
        return 'articles-search-listing';
    }

    onInit() {
        ReactDOM.render(
            <ArticleSearchListing {...this.options} />,
            this.element
        );
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
