import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from 'general/js/dc/dc-base-component';
import PropertyCarouselCard from './property-carousel-card';

export default class PropertyCarouselCardComponent extends DcBaseComponent {

    static getNamespace() {
        return 'property-carousel-card';
    }

    onInit() {
        this.render();
    }

    render() {
        ReactDOM.render(<PropertyCarouselCard item={this.options} />, this.element);
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }
}
