import React from 'react';

function EventSearchSummary(props) {
    return (
        <div className="event-search-summary">
            <strong>{props.events}</strong> upcoming {props.events === 1 ? 'event' : 'events'} found
            {!props.isDevelopmentSearch && <> {props.developments === 1 ? 'at' : 'across'} <strong>{props.developments}</strong> {props.developments === 1 ? 'development' : 'developments'}</>}
            {props.location && <> {props.isDevelopmentSearch ? 'at' : <>within <strong>{props.milesRadius}</strong> {props.milesRadius === 1 ? 'mile' : 'miles'} of</>} <strong>{props.location}</strong></>}
        </div>
    )
}

export default EventSearchSummary;
