import device, { DEVICE_TYPE_TABLET } from 'general/js/device';

export const GET_BROCHURE_BUTTON_SELECTOR = '[href*="download-brochure"]';

export const mobileDeviceChangeCheck = () => {
    if (device.isViewportTypeLt(DEVICE_TYPE_TABLET)) {
        const buttonIrlInterval = setInterval(() => {
            if (document.querySelectorAll(GET_BROCHURE_BUTTON_SELECTOR).length) {
                const brochureRequestButtons = document.querySelectorAll(GET_BROCHURE_BUTTON_SELECTOR);

                brochureRequestButtons.forEach((button) => {
                    const mobileUrl = button.getAttribute('href')
                        .replace('download-brochure', 'post-brochure');

                    button.setAttribute('href', mobileUrl);
                });
            } else {
                clearInterval(buttonIrlInterval);
            }
        }, 500);
    }
};