window.nonce = document.currentScript?.nonce;
/* general styles */

import './general/scss/index.scss';
import './general/scss/normalize.css';

/* global stuff & polyfils */
import 'intersection-observer';
import 'what-input';
import './general/js/lazysizes';
import './general/js/overlay';
import './general/js/request-idle-callback-polyfill';
import './general/js/slick-carousel';
import './general/js/swiper-carousel';
import './general/js/tooltip';
import './general/js/track-event';

/* components */
import './components/accordion';
import './components/accordion-group';
import './components/address-fieldset';
import './components/advanced-search-bar';
import './components/amenities';
import './components/amenity-map';
import './components/anchor-scroll';
import './components/article-author';
import './components/article-list';
import './components/article-page-header';
import './components/article-slider';
import './components/article-tags';
import './components/articles-landing-page-title';
import './components/articles-search';
import './components/articles-search-listing';
import './components/award-showcase';
import './components/awards';
import './components/book-place-cta';
import './components/breadcrumbs';
import './components/brochure-request-methods';
import './components/budget-calculator';
import './components/bullet-list-image';
import './components/campaign-cta-and-text';
import './components/campaign-dev-card';
import './components/campaign-dev-card-ownership-types';
import './components/campaign-property-search';
import './components/campaign-property-table';
import './components/card-carousel';
import './components/carousel';
import './components/carousel-testimonial';
import './components/category-area';
import './components/category-thumbnail';
import './components/chatbot';
import './components/checkbox';
import './components/checkbox-v2';
import './components/circles-preloader';
import './components/collapsible-item';
import './components/collapsible-tabs';
import './components/communication-preferences';
import './components/contact-block';
import './components/contact-details';
import './components/content-area';
import './components/content-grid';
import './components/content-hub-page';
import './components/content-signpost';
import './components/cookie-settings';
import './components/cover-video';
import './components/cta-block';
import './components/customers-recommendation';
import './components/dev-plot-card';
import './components/dev-properties-table';
import './components/dev-property-table';
import './components/dev-section-card';
import './components/dev-section-card-ownership-types';
import './components/development-brochure';
import './components/development-card';
import './components/development-carousel-card';
import './components/development-field';
import './components/development-key-features';
import './components/development-offers';
import './components/development-page';
import './components/development-page-summary';
import './components/development-timeline';
import './components/developments-predictive-search';
import './components/developments-search';
import './components/developments-search-all-ownership-types';
import './components/developments-search-header';
import './components/developments-search-input-v2';
import './components/developments-search-v2';
import './components/epc';
import './components/error-page';
import './components/event-card';
import './components/event-host';
import './components/event-location';
import './components/event-search';
import './components/event-status';
import './components/event-x1';
import './components/events-stack';
import './components/expandable-card'
import './components/facebook-feed';
import './components/faq-search';
import './components/featured-developments';
import './components/featured-events';
import './components/featured-locations';
import './components/featured-properties';
import './components/flexible-ways';
import './components/floor-plan';
import './components/fluid-container';
import './components/footer';
import './components/form';
import './components/form-container';
import './components/form-extended-submit';
import './components/form-header';
import './components/form-info';
import './components/form-page';
import './components/form-secured';
import './components/form-wizard';
import './components/full-width-form';
import './components/generic-bar';
import './components/generic-card';
import './components/generic-cta';
import './components/generic-location-search';
import './components/generic-video';
import './components/header';
import './components/hero';
import './components/hero-carousel';
import './components/hero-lite';
import './components/hero-video';
import './components/highlights';
import './components/icon-card';
import './components/image-cards';
import './components/image-container';
import './components/image-gallery';
import './components/image-mapper';
import './components/info-box';
import './components/inner-page';
import './components/instagram-media';
import './components/interactive-site-plan';
import './components/key-features';
import './components/landing-page';
import './components/latest-developments';
import './components/latest-developments-card';
import './components/lifestyle-tabs';
import './components/lightbox-trigger';
import './components/listing';
import './components/listing-v2';
import './components/living-in';
import './components/load-more-button';
import './components/map';
import './components/media-with-caption';
import './components/mixed-content-slide';
import './components/modal';
import './components/modal-iframe';
import './components/more-articles';
import './components/multimedia-gallery';
import './components/notification-bar';
import './components/notifications';
import './components/original-aspect-ratio';
import './components/our-offices';
import './components/ownership-types';
import './components/page-navigation';
import './components/page-overview';
import './components/page-spinner';
import './components/pagination';
import './components/participating-developments';
import './components/participating-developments-card';
import './components/participating-developments-grid';
import './components/participating-developments-carousel';
import './components/participating-developments-search';
import './components/plot-features';
import './components/plot-page-summary';
import './components/pods';
import './components/popup-page';
import './components/post-preview';
import './components/predictive-search';
import './components/print-element';
import './components/product-boxes';
import './components/properties-search';
import './components/properties-table';
import './components/property-card';
import './components/property-carousel-card';
import './components/property-shared-ownership';
import './components/property-table';
import './components/quick-property-search';
import './components/quick-search';
import './components/quick-search-bordered';
import './components/quick-search-mini';
import './components/quick-search-mini-v2';
import './components/radiobutton-v2';
import './components/recent-searches';
import './components/recently-viewed';
import './components/recently-viewed-articles';
import './components/recipe';
import './components/resales-banner';
import './components/richtext';
import './components/richtext-table-container';
import './components/search-bar';
import './components/service-charges';
import './components/show-if';
import './components/show-more-button';
import './components/shrink-text';
import './components/signpost';
import './components/site-plan';
import './components/sitecore-forms';
import './components/skip-to-main';
import './components/spinner';
import './components/sticky-column';
import './components/sub-navigation';
import './components/sub-navigation-bottom';
import './components/tabbed-card';
import './components/tabs';
import './components/team';
import './components/testimonial-carousel-v2';
import './components/testimonial-quotes-v2';
import './components/testimonial-v2';
import './components/testimonial-x1';
import './components/testimonial-x3';
import './components/text-and-media';
import './components/text-and-video';
import './components/thank-you-page';
import './components/time-slot-picker';
import './components/timeline';
import './components/tooltip';
import './components/trustpilot-widget';
import './components/twitter-feed';
import './components/vacancies-search';
import './components/vacancies-search-wrapper';
import './components/validate-if';
import './components/video';
import './components/video-thumbnail-image';
import './components/video-with-related-videos';
import './components/virtual-tour';
import './components/welcome';
import './components/zero-search-results';
// general components

/* init app */
import app from './general/js/app';

app.init();

/* require svg */
const files = require.context('./general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);

// do not focus sprite in IE
const spriteNode = document.getElementById('__SVG_SPRITE_NODE__');

if (spriteNode) {
    spriteNode.setAttribute('focusable', 'false');
    spriteNode.setAttribute('aria-hidden', 'true');
}
