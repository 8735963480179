import DcBaseComponent from 'general/js/dc/dc-base-component';
import device, { DEVICE_TYPE_TABLET } from '../../../general/js/device';

export default class SitePlanComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);
        this.control = this.refs.control;
        this.controlOptions = this.refs.options;
        this.sitePlans = this.refs.sitePlans;
    }

    static getNamespace() {
        return 'site-plan';
    }

    static getRequiredRefs() {
        return ['control', 'options'];
    }

    onInit() {
        this.toggleSitePlans(this.sitePlans[0].id);
        this.addListeners();
    }

    addListeners() {
        this.control.addEventListener('change', this.onControlChange);
    }

    onControlChange = e => {
        this.toggleSitePlans(e.target.value);
    };

    toggleSitePlans(id) {
        let sitePlanToShow = null;
        this.sitePlans.forEach(sitePlan => {
            if (sitePlan.id !== id) {
                this.hideSitePlan(sitePlan);
            } else {
                sitePlanToShow = sitePlan;
            }
        });
        if (sitePlanToShow) {
            this.showSitePlan(sitePlanToShow);
        }
    }

    showSitePlan(target) {
        target.classList.remove('is-hidden');
    }

    hideSitePlan(target) {
        target.classList.add('is-hidden');
    }

    onDestroy() {
        if (this.deviceTypeChangeUnsubscribe) {
            this.deviceTypeChangeUnsubscribe();
            this.deviceTypeChangeUnsubscribe = null;
        }
        this._destroysInstance();
    }
}
