import React from 'react';
import PropTypes from 'prop-types';
import BudgetRadio from './budget-radio.jsx';

export default function BudgetList({ radios, title, className, changeCallback, activeValue, dispatchGaEvent }) {
    const activeValueHandler = (value) => {
        if (typeof changeCallback === 'function') {
            changeCallback(value);
        }
    };

    return (
        <div className={`budget-list ${className}`}>
            <h3 className="budget-subtitle">{`${title}:`}</h3>
            <ul className="budget-list__list">
                {radios.map((radio, index) => {
                    const isButtonVisible = radio.isVisible === undefined || radio.isVisible;

                    return (
                        isButtonVisible && (
                            <BudgetRadio
                                key={index}
                                changeCallback={activeValueHandler}
                                isChecked={radio.value === activeValue}
                                {...radio}
                                dispatchGaEvent={dispatchGaEvent}
                            />
                        )
                    );
                })}
            </ul>
        </div>
    );
}

BudgetList.propTypes = {
    radios: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    className: PropTypes.string,
    changeCallback: PropTypes.func,
    activeValue: PropTypes.string,
    dispatchGaEvent: PropTypes.func
};
