import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import DropdownChecklist from './dropdown-checklist';

const CampaignSearchDesktopFilters = (props) => {
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [isApplyHidden, setIsApplyHidden] = useState(true);
    const [isRadiusEnabled, setIsRadiusEnabled] = useState(false);

    const {
        filtersConfig,
        isLoading,
        filtersApplyButtonText,
        onSearch,
        filterOptions,
        setFilterOptions,
        locationSearchId
    } = props;

    const rootRef = React.createRef();

    const resetActive = () => {
        setActiveDropdown(null);
    };

    const onClose = () => {
        if (activeDropdown !== null) {
            resetActive();
        }
    };

    const hideApply = () => {
        setIsApplyHidden(true);
    };

    const showApply = () => {
        setIsApplyHidden(false);
    };

    const onClick = (name) => {
        if (activeDropdown) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(name);
        }
    };

    const manageFiltersArrayValue = (array, value) => {
        let newArray = array.slice();

        if (newArray.indexOf(value) === -1) {
            newArray = [...newArray, value];
        } else {
            const index = newArray.indexOf(value);
            newArray.splice(index, 1);
        }

        return newArray;
    };

    const onFiltersChange = (filterName, newValue) => {
        switch (filterName) {
            case 'maxPrice':
            case 'maxRent':
			case 'maxOpsoPrice':
            case 'searchRadius':
            case 'ownershipType':
                setFilterOptions({
                    ...filterOptions,
                    [filterName]: newValue
                });
                break;
            case 'availability':
                setFilterOptions({
                    ...filterOptions,
                    [filterName]: manageFiltersArrayValue(filterOptions.availability, newValue)
                });
                break;
            case 'numberOfBedrooms':
                setFilterOptions({
                    ...filterOptions,
                    [filterName]: manageFiltersArrayValue(filterOptions.numberOfBedrooms, newValue)
                });
                break;
            case 'propertyTypes':
                setFilterOptions({
                    ...filterOptions,
                    [filterName]: manageFiltersArrayValue(filterOptions.propertyTypes, newValue)
                });
                break;
            default:
                break;
        }
    };

    const onChange = (config, newValue) => {
        onFiltersChange(config.name, newValue);
        showApply();
    };

    const onApply = () => {
        onSearch(props.campaignSearchUrl, { ...filterOptions, offset: 0 });
        resetActive();
        hideApply();
    };

    const afterPopupDescriptionOpen = () => {
        resetActive();
    };

    const handleOuterClick = ({ target }) => {
        const outsideClickCondition = (!target.closest('.campaign-search-filters__control') &&
            !target.closest('.campaign-search-filters__control-dropdown')) ||
            target.closest('.campaign-search-filters__control-radiolist-item');

        if (outsideClickCondition) {
            setActiveDropdown(null);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOuterClick);

        return () => {
            document.removeEventListener('click', handleOuterClick);
        };
    }, []);

    useEffect(() => {
        setIsRadiusEnabled(locationSearchId !== null);
    }, [locationSearchId]);

    return (
        <div className="developments-search__desktop-filters">
            <div className="developments-search__desktop-filters-content">
                <div
                    ref={rootRef}
                    className={
                        cn('search-desktop-filters-v2',
                            { 'is-active': activeDropdown !== null })
                    }>
                    {filtersConfig.map((config) => {
                        const isRadiusDisabled = !isRadiusEnabled && config.name === 'searchRadius';
                        const isPriceDisabled = config.name === 'maxPrice' && filterOptions.ownershipType === '';

                        if (filterOptions.ownershipType === 'sale' || filterOptions.ownershipType === '') {
                            return (
                                (config.name !== 'sort' && config.name !== 'maxRent' && config.name !== 'maxOpsoPrice') && <DropdownChecklist
                                    config={config}
                                    key={config.name}
                                    label={config.label}
                                    options={config.options}
                                    type={config.type}
                                    value={filterOptions[config.name]}
                                    afterPopupDescriptionOpen={afterPopupDescriptionOpen}
                                    isDisabled={isRadiusDisabled || isPriceDisabled}
                                    isActive={activeDropdown === config.name}
                                    onClick={() => onClick(config.name)}
                                    onChange={(newValue) => onChange(config, newValue)}
                                    onApply={onApply}
                                    onClose={onClose}
                                />
                            );
                        }
                        if (filterOptions.ownershipType === 'rent') {
                            return (
                                (config.name !== 'sort' && config.name !== 'maxPrice' && config.name !== 'maxOpsoPrice') && <DropdownChecklist
                                    config={config}
                                    key={config.name}
                                    label={config.label}
                                    options={config.options}
                                    type={config.type}
                                    value={filterOptions[config.name]}
                                    afterPopupDescriptionOpen={afterPopupDescriptionOpen}
                                    isDisabled={isRadiusDisabled}
                                    isActive={activeDropdown === config.name}
                                    onClick={() => onClick(config.name)}
                                    onChange={(newValue) => onChange(config, newValue)}
                                    onApply={onApply}
                                    onClose={onClose}
                                />
                            );
                        }
						if (filterOptions.ownershipType === 'opso') {
                            return (
                                (config.name !== 'sort' && config.name !== 'maxPrice' && config.name !== 'maxRent') && <DropdownChecklist
                                    config={config}
                                    key={config.name}
                                    label={config.label}
                                    options={config.options}
                                    type={config.type}
                                    value={filterOptions[config.name]}
                                    afterPopupDescriptionOpen={afterPopupDescriptionOpen}
                                    isDisabled={isRadiusDisabled}
                                    isActive={activeDropdown === config.name}
                                    onClick={() => onClick(config.name)}
                                    onChange={(newValue) => onChange(config, newValue)}
                                    onApply={onApply}
                                    onClose={onClose}
                                />
                            );
                        }

                        return (
                            config.name !== 'sort' && <DropdownChecklist
                                config={config}
                                key={config.name}
                                label={config.label}
                                options={config.options}
                                type={config.type}
                                value={filterOptions[config.name]}
                                afterPopupDescriptionOpen={afterPopupDescriptionOpen}
                                isDisabled={isRadiusDisabled || config.isDisabled}
                                isActive={activeDropdown === config.name}
                                onClick={() => onClick(config.name)}
                                onChange={(newValue) => onChange(config, newValue)}
                                onApply={onApply}
                                onClose={onClose}
                            />
                        );
                    })}
                </div>
                {!isLoading && !isApplyHidden && (
                    <button
                        className="search-desktop-filters-v2__apply-button"
                        type="button"
                        onClick={onApply}
                    >
                        {filtersApplyButtonText}
                    </button>
                )}
            </div>
        </div>
    );
};

CampaignSearchDesktopFilters.defaultProps = {
    isStretched: false,
    filtersApplyButtonText: 'Apply',
};

CampaignSearchDesktopFilters.propTypes = {
    filtersConfig: PropTypes.array.isRequired,
    campaignSearchUrl: PropTypes.string.isRequired,
    filterOptions: PropTypes.object.isRequired,
    setFilterOptions: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    filtersApplyButtonText: PropTypes.string.isRequired,
    locationSearchId: PropTypes.oneOfType([
        PropTypes.string, null
    ]),
};

export default CampaignSearchDesktopFilters;
