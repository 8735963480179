import React from 'react';
import PropTypes from 'prop-types';
import CirclesPreloader from '../../../circles-preloader/js/circles-preloader';

export default class DevelopmentsSearchOverlayPreloader extends React.PureComponent {
    getStateClasses() {
        return this.props.isVisible ? 'is-visible' : '';
    }

    render() {
        return (
            <div className={`development-search-overlay-preloader ${this.getStateClasses()}`}>
                <div className="development-search-overlay-preloader__inner">
                    <CirclesPreloader
                        isVisible={true}
                        caption={'Loading Developments'}/>
                </div>
            </div>
        );
    }
}

DevelopmentsSearchOverlayPreloader.propTypes = {
    isVisible: PropTypes.bool.isRequired
};
