import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Range, getTrackBackground } from 'react-range';
import { useBudgetCalculatorStore } from '../store/budget-calculator-store.jsx';
import {
    SERVICE_ICONS,
    DEFAULT_SERVICE_COLOR,
    TOOLTIP_ICONS,
    DEFAULT_STEP,
    TOOLTIP_ID,
    SERVICE_MODE,
    DEFAULT_TOOLTIP_COLOR,
    DEFAULT_TRACK_COLOR,
} from '../constants.js';
import {
    applyMode,
    extractMode,
    formatForView,
    getNumberFromString,
    extractPoundSign,
    addPoundSign,
} from '../pure-functions.js';

const GA_EVENT_CALCULATOR_SLIDER = 'calculatorSlider';

export default function BudgetService({
    title,
    hasTooltip,
    tooltip,
    currentPrice,
    maxPrice,
    minPrice,
    icon,
    colorHex,
    serviceIndex,
    dispatchGaEvent
}) {
    const [state, dispatch] = useBudgetCalculatorStore();
    const [currentValue, setCurrentValue] = useState([
        currentPrice > maxPrice ? maxPrice : currentPrice,
    ]);
    const [currentUserValue, setCurrentUserValue] = useState('');

    // used to cover priceMode switch miscalculations with number from user
    const [correctUserValue, setCorrectUserValue] = useState(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [tooltipButton, setTooltipButton] = useState(null);
    const currentIcon = SERVICE_ICONS[icon] || SERVICE_ICONS.default;
    const currentColor = colorHex || DEFAULT_SERVICE_COLOR;
    const currentTooltipName = TOOLTIP_ICONS[tooltip.icon] || TOOLTIP_ICONS.default;

    const changeRangeHandler = ([value], slider) => {
        dispatchGaEvent(GA_EVENT_CALCULATOR_SLIDER, { slider });
        
        setCurrentValue(value);
        setCorrectUserValue(null);
        dispatch({ type: 'UPDATE_SERVICE_PRICE', serviceIndex, currentPrice: value });
    };

    const changeInputHandler = (e) => {
        const value = extractPoundSign(e.target.value);
        const userNumber = getNumberFromString(value);
        if (userNumber || userNumber === 0) {
            const extractedUserNumber = extractMode(state.priceMode, userNumber);

            if (extractedUserNumber >= 0) {
                setCorrectUserValue(userNumber);
                dispatch({
                    type: 'UPDATE_SERVICE_PRICE',
                    serviceIndex,
                    currentPrice: extractedUserNumber,
                });

                if (extractedUserNumber <= minPrice) {
                    setCurrentValue(minPrice);
                } else if (extractedUserNumber >= maxPrice) {
                    setCurrentValue(maxPrice);
                } else {
                    setCurrentValue(extractedUserNumber);
                }
            }
        }

        setCurrentUserValue(value);
    };

    useEffect(() => {
        setCorrectUserValue(null);
    }, [state.priceMode]);

    const getDisplayedValue = () => {
        if (isInputFocused) {
            return addPoundSign(currentUserValue);
        }

        if (correctUserValue) {
            return formatForView(correctUserValue);
        }
        return formatForView(applyMode(state.priceMode, currentPrice));
    };

    return (
        <div className="budget-service">
            <div className="budget-service__row">
                <svg
                    className="budget-service__icon icon"
                    width={currentIcon.width}
                    height={currentIcon.height}
                    focusable="false"
                    role="img"
                    aria-hidden="true"
                    style={{ fill: currentColor }}
                >
                    <use xlinkHref={`#icon-service-${currentIcon.name}`}></use>
                </svg>
                <h3 className="budget-service__title">{title}</h3>
                {hasTooltip && (
                    <button
                        className="budget-service__tooltip-button"
                        type="button"
                        aria-label="toggle service tooltip"
                        aria-describedby={TOOLTIP_ID}
                        ref={setTooltipButton}
                        onClick={() =>
                            dispatch({
                                type: 'SHOW_TOOLTIP',
                                mode: SERVICE_MODE,
                                text: [tooltip.text],
                                tooltipReference: tooltipButton,
                                tooltipColor: DEFAULT_TOOLTIP_COLOR,
                            })
                        }
                    >
                        {currentTooltipName.name}
                    </button>
                )}
            </div>
            <div className="budget-service__row budget-service__row--mt-10">
                <p className="budget-service__description-text">I pay:</p>
                <Range
                    step={DEFAULT_STEP}
                    min={minPrice}
                    max={maxPrice}
                    values={[currentValue]}
                    onChange={(value) => changeRangeHandler(value, title)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            className="budget-service__range"
                            style={{
                                ...props.style,
                            }}
                        >
                            <div
                                className="budget-service__track"
                                style={{
                                    ...props.style,
                                    background: getTrackBackground({
                                        min: minPrice,
                                        max: maxPrice,
                                        values: [currentValue],
                                        colors: [currentColor, DEFAULT_TRACK_COLOR],
                                    }),
                                }}
                            ></div>
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            className="budget-service__thumb"
                            style={{
                                ...props.style,
                                backgroundColor: currentColor,
                            }}
                        />
                    )}
                />
                <input
                    className="budget-service__input"
                    type="text"
                    value={getDisplayedValue()}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => {
                        setIsInputFocused(false);
                        setCurrentUserValue('');
                    }}
                    onChange={changeInputHandler}
                />
            </div>
            <div className="budget-service__row budget-service__row--mt-10">
                <p className="budget-tip">Included with McCarthy Stone</p>
            </div>
        </div>
    );
}

BudgetService.propTypes = {
    title: PropTypes.string,
    hasTooltip: PropTypes.bool,
    tooltip: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
    }),
    currentPrice: PropTypes.number,
    maxPrice: PropTypes.number,
    minPrice: PropTypes.number,
    icon: PropTypes.string,
    colorHex: PropTypes.string,
    serviceIndex: PropTypes.number,
    dispatchGaEvent: PropTypes.func
};
