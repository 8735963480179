import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { webkitLineClamp } from 'webkit-line-clamp';
import { devSearchTypeDevelopment } from '@Components/developments-search-v2/js/dev-search-types.js';

const CampaignDevPlot = ({
    sendMeUpdatesCTAText,
    requestAVisitCTAText,
    waitingListCTAText,
    item,
    isComingSoon
}) => {
    const clampedRef = useCallback((node) => {
        // this callback is a polyfill for IE11
        if (node !== null && typeof node.style.webkitLineClamp === 'undefined') {
            webkitLineClamp(node, 3);

            if (node.children[0]) {
                webkitLineClamp(node.children[0], 3);
            }
        }
    }, []);

    let developmentCTAText;

    if (isComingSoon) {
        developmentCTAText = sendMeUpdatesCTAText;
    } else if (item.fullyReserved) {
        developmentCTAText = waitingListCTAText;
    } else {
        if (item.showRequestAVisit) {
            developmentCTAText = requestAVisitCTAText;
        }
        else {
            developmentCTAText = sendMeUpdatesCTAText;
        }
    }

    return (
        <div className="campaign-dev-plot">
            <a
                className="campaign-dev-plot__dev-link"
                href={item.buttonUrl}>
                <h3 className="campaign-dev-plot__title">{item.developmentName}</h3>
            </a>
            <p className="campaign-dev-plot__address">{item.address}</p>
            <div
                className="campaign-dev-plot__desc"
                ref={clampedRef}
                dangerouslySetInnerHTML={{
                    __html: item.desc,
                }}
            ></div>
            <div className="campaign-dev-plot__btn-wrapper">
                <a
                    className="btn btn-secondary campaign-dev-plot__btn"
                    href={item.ctaButtonUrl}
                >
                    <span className="campaign-dev-plot__btn-text">{developmentCTAText}</span>
                </a>
            </div>
        </div>
    );
}

CampaignDevPlot.propTypes = {
    item: devSearchTypeDevelopment,
    sendMeUpdatesCTAText: PropTypes.string,
    requestAVisitCTAText: PropTypes.string,
    waitingListCTAText: PropTypes.string,
    isComingSoon: PropTypes.bool
};

export default CampaignDevPlot;
