import anime from 'animejs';

const DURATION = 300;
const EASING = 'linear';

export function setContentToState(content, isExpanded) {
    if (isExpanded) {
        content.style.overflow = 'visible';
        content.style.display = 'block';
        content.style.maxHeight = 'none';
        content.style.opacity = 1;
    } else {
        content.style.maxHeight = 0;
        content.style.opacity = 0;
        content.style.overflow = 'hidden';
        content.style.display = 'none';
    }
}

export function resetContentStyles(content) {
    content.removeAttribute('style');
}

export function collapse(content, onComplete = null, duration = DURATION, easing = EASING) {
    content.style.overflow = 'hidden';
    content.style.maxHeight = `${content.scrollHeight}px`;

    anime({
        targets: content,
        maxHeight: [content.scrollHeight, 0],
        opacity: 0,
        duration,
        easing,
        complete: () => {
            setContentToState(content, false);

            if (onComplete) {
                onComplete();
            }
        }
    });
}

export function expand(content, onComplete = null, duration = DURATION, easing = EASING) {
    content.style.display = 'block';

    anime({
        targets: content,
        maxHeight: [0, content.scrollHeight],
        opacity: 1,
        duration,
        easing,
        complete: () => {
            setContentToState(content, true);

            if (onComplete) {
                onComplete();
            }
        }
    });
}

export { DURATION };
