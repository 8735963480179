import { logoSvg, phoneSvg } from './amenities-map-svgs';

export function getPrintMarkup(mapSrc, thumbnailSrc, printButton) {
    const developmentName = document.querySelector('.amenities__dev-title').textContent;
    const fullAdress = document.querySelector('.amenities__dev-address').textContent.split(',');
    const developmentAddress = fullAdress.join(',');
    const summaryBlock = printButton.closest('.amenities__summary-col').querySelector('.richtext');
    const locationText = (summaryBlock && summaryBlock.innerHTML) || developmentAddress;
    const printMap = `
        <div id="print-area">
            <div class="print-map__wrapper" 
                style="display: flex;
                    flex-direction: column;
                    align-items: center;">
                    <header class="print-map__header" 
                    style="display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 620px;
                    padding-top: 25px;

                    @font-face {
                        font-family: AzoSans;
                        font-weight: 400;
                        font-style: normal;
                        font-display: swap;
                        src: url('../fonts/azo-sans-normal.woff2') format('woff2'),
                            url('../fonts/azo-sans-normal.woff') format('woff');
                    }">
                    <div class="print-map__logo" 
                        style="width: 166px;height: 44px;">
                        ${logoSvg}
                    </div>
                    <div class="print-map__phone" 
                        style="display: flex;font-size: 28px;">
                        ${phoneSvg}
                        <span class="print-map__phone-number" 
                        style="margin-left: 8px; 
                            font-size: 29px;
                            font-weight: 400;
                            margin-left: 7px;">0800 201 4811</span>
                    </div>
                </header>
                <div class="print-map__development-info" 
                    style="display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 30px;
                        width: 620px;
                        padding-top: 25px;">
                    <div class="print-map__development-image-wrapper" 
                    style="width: 158px;
                        height: 96px;">
                        <img class="print-map__development-thumbnail" 
                            data-sizes="auto" 
                            alt="development thumbnail" 
                            data-srcset="${thumbnailSrc}" 
                            srcset="${thumbnailSrc}" 
							src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            style="display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;"/>
                    </div>
                    <div class="print-map__development-description" 
                    style="display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-weight: 400;
                        font-size: 18px;
                        height: 96px;
                        width: 437px;">
                        <h2 class="print-map__development-name" 
                        style="font-weight: 400;
                            font-size: 33px;
                            margin: 0;">${developmentName}</h2>         
                        <p class="print-map__development-address" 
                        style="margin: 0;
                            padding: 0;">${developmentAddress}</p>
                    </div>
                </div>
                <div class="print-map__map-wrapper"
                    style="display: flex;
                        flex-direction: column;
                        width: 620px;
                        max-height: 580px">
                    <div class='print-map__map' style="border: 1px solid #cabc96;">
                        <img class="amenities__map-image" src="${mapSrc}" style="width: 100%; max-height: 500px"/>
                    </div>
                    <div class='print-map__location'                    
                        style="padding: 25px;
                            font-size: 16px;
                            max-height: 135px;
                            border: 1px solid #cabc96;
                            border-top: none;">
                        <div class='print-map__location-suggestion'
                            style="display: flex; flex-direction: column;">
                            ${locationText}
                        </div>
                    </div>
                </div>
            </div>
        </div>`;

    return printMap;
}
