import DcBaseComponent from 'general/js/dc/dc-base-component';

export default class PageNavigationComponent extends DcBaseComponent {
    constructor(element) {
        super(element);

        this.pageNavigation = this.element;
        this.pageNavigationClone;
        this.pageNavigationPosition = this.pageNavigationPosition.bind(this);
    }

    static getNamespace() {
        return 'page-navigation';
    }

    onInit() {
        // stop native sticky behaviour
        this.element.classList.add('page-navigation--static');

        // listen for scroll event
        document.addEventListener('scroll', this.pageNavigationPosition);
    }

    pageNavigationPosition() {
        // if scroll is beyond element
        if (this.pageNavigation.getBoundingClientRect().y < -Math.abs(this.pageNavigation.getBoundingClientRect().height)) {
            // if the clone hasn't been created
            if (!this.pageNavigationClone) {
                // clone the element and add to the body
                this.pageNavigationClone = this.pageNavigation.cloneNode(true);
                this.pageNavigationClone.classList.remove('page-navigation--static');
                this.pageNavigationClone.classList.add('page-navigation--clone');
                this.pageNavigationClone.setAttribute('aria-hidden', 'true');
                document.body.appendChild(this.pageNavigationClone);
            }

            // calculate new clone postion
            const scrollOffset = Math.abs(this.pageNavigation.getBoundingClientRect().bottom) > this.pageNavigationClone.getBoundingClientRect().height ?
                this.pageNavigationClone.getBoundingClientRect().height : Math.abs(this.pageNavigation.getBoundingClientRect().bottom);

            this.pageNavigationClone.style.transform = `translateY(${scrollOffset}px)`;

        } else if (this.pageNavigationClone) { // if clone created
            // remove the clone
            document.body.removeChild(this.pageNavigationClone);
            this.pageNavigationClone = null;
        }
    }
}
