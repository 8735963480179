import React from 'react';
import PropTypes from 'prop-types';
import nanoId from 'nano-id';
import DevPlotCard from '../../dev-plot-card/js/dev-plot-card';

class PropertyCard extends React.PureComponent {
    renderHeader = () => {
        const { item, titleSize } = this.props;

        //todo remove excess markup
        if (titleSize === 'h3') {
            return <h3 className="property-card__title">{item.plotNumber}</h3>;
        } else if (titleSize === 'h4') {
            return <h4 className="property-card__title">{item.plotNumber}</h4>;
        }
    };

    renderContent = () => {
        const { item } = this.props;
        return (
            <React.Fragment>
                {(item.propertyDescription) && (
                    <div className="property-card__property-description">{item.propertyDescription}</div>
                )}

                {(item.price) && (
                    <div className="property-card__price" dangerouslySetInnerHTML={{ __html: item.price }} />
                )}
            </React.Fragment>
        );
    };

    getFullTitle() {
        const { item } = this.props;
        return item.developmentName + ' ' + item.plotNumber;
    }

    renderReservationBadge = () => {
        const { item } = this.props;
        const status = item.reservationStatus;
        if (status) {
            return <div className="property-card__reservation-status">{status}</div>;
        }
    };

    renderPriceList = () => {
        const { priceList } = this.props.item;
        const renderId = nanoId();

        if (priceList) {
            return (
                <ul className="property-card__price-list">
                    {priceList.map((item, i) => (
                        <li key={i} className="property-card__price-list-item">
                            {item.title}
							{item.tooltip && (
                                <div className="tooltip tooltip--dark tooltip--force-above" data-tooltip>
                                    <button aria-describedby={`tooltip_${renderId}`} className="tooltip__trigger" data-tooltip-trigger type="button">?</button>
                                    <div className="tooltip__content" data-tooltip-content id={`tooltip_${renderId}`} role="tooltip">
                                        <div className="tooltip__content__inner" dangerouslySetInnerHTML={{
                                            __html: item.tooltip,
                                        }}></div>
                                    </div>
                                </div>
							)}
							{' '}
                            <div
                                className="property-card__price property-card__price--small"
                                dangerouslySetInnerHTML={{ __html: item.priceValue }}
                            ></div>
                        </li>
                    ))}
                </ul>
            );
        }
    };

    render() {
        const { item } = this.props;

        return (
            <DevPlotCard
                {...this.props}
                img={item.img}
                url={item.url}
                developmentName={item.developmentName}
                title={this.getFullTitle()}
                renderHeader={this.renderHeader}
                renderContent={this.renderContent}
                renderReservationBadge={this.renderReservationBadge}
                renderPriceList={this.renderPriceList}
                productType={item.productType}
                servicesText={item.servicesText}
                specificClass={'property-card'}
                buttonText={item.buttonText}
                buttonUrl={item.buttonUrl}
            />
        );
    }
}

PropertyCard.propTypes = {
    item: PropTypes.object.isRequired
};

export default PropertyCard;
