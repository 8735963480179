// https://stackoverflow.com/questions/16282330/find-centerpoint-of-polygon-in-javascript
export default class Region {
    constructor(points) {
        this.points = points || [];
        this.length = points.length;
    }

    area() {
        let area = 0;
        let i;
        let j;
        let point1;
        let point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
            point1 = this.points[i];
            point2 = this.points[j];
            area += point1.x * point2.y;
            area -= point1.y * point2.x;
        }
        area /= 2;

        return area;
    }

    centroid() {
        let x = 0;
        let y = 0;
        let i;
        let j;
        let f;
        let point1;
        let point2;

        for (i = 0, j = this.length - 1; i < this.length; j = i, i += 1) {
            point1 = this.points[i];
            point2 = this.points[j];
            f = point1.x * point2.y - point2.x * point1.y;
            x += (point1.x + point2.x) * f;
            y += (point1.y + point2.y) * f;
        }

        f = this.area() * 6;

        return [x / f, y / f];
    }
}
