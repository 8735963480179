import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withContext } from '../context.jsx';
import nanoId from 'nano-id';
import analyticsService from '@General/js/analytics-service.js';

class FiltersList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.toggleRef = React.createRef();
        this.filterBtnRef = React.createRef();
        this.mobileClose = React.createRef();

        this.state = {
            ownershipValue: this.props.tableFilters.ownershipType[0].ownershipValue,
            price: '',
            priceText: 'No Max',
            bedrooms: [],
            active: false,
            activePrices: false,
            totalCount: 0,
        };
        this.toggleClass = this.toggleClass.bind(this);
        this.closePrices = this.closePrices.bind(this);
        this.onOuterClick = this.onOuterClick.bind(this);
        this.initialState = { ...this.state };
    }

    setFilterValues() {
        if (!this.props.filterValues) {
            this.setState({
                ownershipValue: this.props.tableFilters.ownershipType[0].ownershipValue,
                price: '',
                priceText: 'No Max',
                bedrooms: [],
                active: false,
                activePrices: false,
                totalCount: 0,
            });
            this.unCheck();
        } else {
            this.setState({
                ...this.props.filterValues,
            });
        }
        this.closeFilters();
    }

    onOuterClick(event) {
        if (this.props.isOnMobileViewport === false) {
            if (
                event.pointerId > 0 &&
                this.toggleRef.current &&
                this.toggleRef.current.contains(event.target) === false &&
                this.filterBtnRef.current.contains(event.target) === false
            ) {
                this.setFilterValues();
            }
        } else {
            if (this.mobileClose.current.contains(event.target) === true) {
                this.setFilterValues();
            }
        }
        event.stopPropagation();
    }

    closePrices() {
        this.setState({ activePrices: false });
    }

    toggleClass() {
        let currentState = this.state.active;
        this.setState({ active: !currentState });

        if (!currentState) {
            this.toggleRef = React.createRef();
            document.addEventListener('click', this.onOuterClick);
        }
    }

    onValueChange = (event) => {
        this.setState({
            ownershipValue: event.target.value,
            price: '',
            priceText: 'No Max',
            activePrices: true,
        });
    };

    onPriceChange = (event) => {
        this.setState({
            price: event.target.value,
            priceText: this.props.isOnMobileViewport
                ? event.target[event.target.selectedIndex].text
                : '',
            ownershipValue: this.state.ownershipValue,
        });
    };

    onCheckValChange = (e) => {
        const bedrooms = [...this.state.bedrooms];
        let index;

        if (e.target.checked) {
            bedrooms.push(+e.target.value);
        } else {
            index = bedrooms.indexOf(+e.target.value);
            bedrooms.splice(index, 1);
        }
        bedrooms.sort();
        this.setState({ bedrooms: bedrooms });
    };
	
	sendAnalytics = () => {
		
		var ownershipType = null;
		if (this.state.ownershipValue === 'For sale') {
			ownershipType = 'Sale';
		} else if (this.state.ownershipValue === 'Rental') {
			ownershipType = 'Rent';
		} else if (this.state.ownershipValue === 'Shared ownership') {
			ownershipType = 'OPSO';
		}
		
		var bedrooms = this.state.bedrooms.length > 0 ? this.state.bedrooms : null;
		
		var data = 
			{
				'ownershipType': ownershipType,
				'numberOfBedrooms': bedrooms
			};
		analyticsService._send('propertyOwnershipFilter', data);
	};

    onApply = () => {
        this.props.onApply(this.state);
        this.closeFilters();
		this.sendAnalytics();
    };

    onResetFilter = () => {
        this.setState({
            ownershipValue: this.props.tableFilters.ownershipType[0].ownershipValue,
            price: '',
            priceText: 'No Max',
            bedrooms: [],
            active: false,
            activePrices: false,
            totalCount: 0,
        });
        this.unCheck();
        this.props.onApply(this.initialState);
		this.sendAnalytics();
    };

    unCheck = () => {
        let x = document.getElementsByClassName('checkbox-bedroom');
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false;
        }
    };
    closeFilters = () => {
        this.setState({ active: false });
        document.removeEventListener('click', this.onOuterClick);
    };

    toggleMaxPrice = (event) => {
        this.setState({
            ownershipValue: event.target.value,
            activePrices: true,
        });
    };

    render() {
        return this.props.isOnMobileViewport ? this.getMobileView() : this.getDesktopView();
    }

    getDesktopView() {
        let filterCount =
            Object.values(this.state)
                .slice(0, 2)
                .filter(function (filterCount) {
                    return filterCount != '';
                }).length + this.state.bedrooms.length;

        let filterCountClass = filterCount !== 0 ? 'filter-count is--active' : 'filter-count';

        return (
            <div className="properties-table-filters">
                <div className="properties-table-filters__wrapper">
                    <button
                        className="properties-table-filters__button"
                        onClick={this.toggleClass}
                        ref={this.filterBtnRef}
                    >
                        {this.props.tableFilters.filtersLabel}
                        <span className={filterCountClass}>{filterCount}</span>
                    </button>
                    <div
                        className={
                            this.state.active
                                ? 'is-visible properties-table-filters__dropdown'
                                : 'properties-table-filters__dropdown'
                        }
                        ref={this.toggleRef}
                    >
                        <form>
                            <div className="properties-table-filters__ownership-type">
                                <h4>{this.props.tableFilters.mobileHeading}</h4>
                                {this.props.tableFilters.ownershipType.map(
                                    (ownershipType, index) => {
                                        return (
                                            <div className="filter-ownership-type" key={nanoId()}>
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id={`ownership-type-${index}`}
                                                        name="ownershipValue"
                                                        value={ownershipType.ownershipValue}
                                                        checked={
                                                            this.state.ownershipValue ===
                                                            ownershipType.ownershipValue
                                                        }
                                                        onChange={this.onValueChange}
                                                        onClick={this.toggleMaxPrice}
                                                    />
                                                    <label htmlFor={`ownership-type-${index}`}>
                                                        {ownershipType.ownershipValue}
                                                        {ownershipType.filterExtraInfo && (
                                                            <i>{ownershipType.filterExtraInfo}</i>
                                                        )}
                                                        {ownershipType.prices && (
                                                            <span className="arrow-icon">
                                                                <svg className="property-table__table-cell-btn-icon icon">
                                                                    <use xlinkHref="#icon-arrow-right-thin"></use>
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                {this.state.ownershipValue ===
                                                    ownershipType.ownershipValue && (
                                                    <div className="ownership-type-prices">
                                                        {ownershipType.prices && (
                                                            <div
                                                                className={
                                                                    this.state.activePrices
                                                                        ? 'is-visible ownership-type-prices__wrapper'
                                                                        : 'ownership-type-prices__wrapper'
                                                                }
                                                            >
                                                                {ownershipType.prices && (
                                                                    <h4>
                                                                        {
                                                                            ownershipType.filterHeadline
                                                                        }
                                                                    </h4>
                                                                )}

                                                                {ownershipType.prices !== null
                                                                    ? ownershipType.prices.map(
                                                                          (value, key) => (
                                                                              <div
                                                                                  className="radio"
                                                                                  key={nanoId()}
                                                                              >
                                                                                  <input
                                                                                      type="radio"
                                                                                      id={`price-${key}`}
                                                                                      name="price"
                                                                                      value={
                                                                                          value.value
                                                                                      }
                                                                                      checked={
                                                                                          this.state
                                                                                              .price ===
                                                                                          value.value
                                                                                      }
                                                                                      onChange={
                                                                                          this
                                                                                              .onPriceChange
                                                                                      }
                                                                                  />
                                                                                  <label
                                                                                      htmlFor={`price-${key}`}
                                                                                  >
                                                                                      {value.key}
                                                                                  </label>
                                                                              </div>
                                                                          )
                                                                      )
                                                                    : ''}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            <div className="properties-table-filters__bedrooms">
                                <h4>{this.props.tableFilters.bedroomsLabel}</h4>
                                {this.props.tableFilters.bedrooms.map((bedroom, index) => {
                                    const isSelected = this.state.bedrooms.indexOf(+bedroom) >= 0;

                                    return (
                                        <div className="filter-bedrooms" key={nanoId()}>
                                            <div className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    id={`bedroom-${index}`}
                                                    name="bedroom"
                                                    className="checkbox-bedroom"
                                                    value={bedroom}
                                                    checked={isSelected}
                                                    onChange={this.onCheckValChange}
                                                />
                                                <label htmlFor={`bedroom-${index}`}>
                                                    {bedroom}&nbsp;
                                                    {bedroom === '1'
                                                        ? this.props.tableFilters.bedroomsLabel.slice(
                                                              0,
                                                              -1
                                                          )
                                                        : this.props.tableFilters.bedroomsLabel}
                                                </label>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="filter__buttons">
                                <button
                                    className="button-apply"
                                    type="button"
                                    onClick={this.onApply}
                                >
                                    {this.props.tableFilters.apply}
                                </button>
                                <button
                                    className="button-reset"
                                    type="button"
                                    onClick={this.onResetFilter}
                                >
                                    {this.props.tableFilters.reset}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    getMobileView() {
        let filterCount =
            Object.values(this.state)
                .slice(0, 2)
                .filter(function (filterCount) {
                    return filterCount != '';
                }).length + this.state.bedrooms.length;

        let filterCountClass = filterCount !== 0 ? 'filter-count is--active' : 'filter-count';

        return (
            <div className="properties-table-filters">
                <div className="properties-table-filters__wrapper">
                    <button
                        className="properties-table-filters__button"
                        onClick={this.toggleClass}
                        ref={this.filterBtnRef}
                    >
                        {this.props.tableFilters.filtersLabel}
                        <span className={filterCountClass}>{filterCount}</span>
                    </button>
                    <div
                        className={
                            this.state.active
                                ? 'is-visible properties-table-filters__dropdown'
                                : 'properties-table-filters__dropdown'
                        }
                        ref={this.toggleRef}
                    >
                        <button
                            ref={this.mobileClose}
                            type="button"
                            aria-label="Close filters"
                            className="btn search-mobile-filters__close-button"
                        >
                            <svg
                                width="9"
                                height="9"
                                className="icon"
                                focusable="false"
                                role="img"
                                aria-hidden="true"
                            >
                                <use xlinkHref="#icon-cross" />
                            </svg>
                        </button>

                        <form>
                            <div className="properties-table-filters__ownership-type">
                                <div className="search-mobile-filters__item">
                                    <div className="search-mobile-filters__item-label">
                                        {this.props.tableFilters.mobileHeading}
                                    </div>
                                    <div className="search-mobile-filters__item-content">
                                        {this.props.tableFilters.ownershipType.map(
                                            (ownershipType, index) => {
                                                return (
                                                    <div className="mobile-filters-ownership" key={nanoId()}>
                                                        <div className="mobile-filters-ownership__item">
                                                            <div className="mobile-filters-radiobutton">
                                                                <label
                                                                    className="mobile-filters-radiobutton__label"
                                                                    htmlFor={`ownership-type-${index}`}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        id={`ownership-type-${index}`}
                                                                        name="ownershipValue"
                                                                        value={
                                                                            ownershipType.ownershipValue
                                                                        }
                                                                        checked={
                                                                            this.state
                                                                                .ownershipValue ==
                                                                            ownershipType.ownershipValue
                                                                        }
                                                                        onChange={
                                                                            this.onValueChange
                                                                        }
                                                                        onClick={
                                                                            this.toggleMaxPrice
                                                                        }
                                                                        className={classNames({
                                                                            'mobile-filters-radiobutton__input': true,
                                                                        })}
                                                                    />
                                                                    <span className="mobile-filters-radiobutton__placeholder"></span>
                                                                    <span className="mobile-filters-radiobutton__text">
                                                                        {
                                                                            ownershipType.ownershipValue
                                                                        }
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {ownershipType.prices && (
                                                            <div className="mobile-filters-ownership__item">
                                                                <div
                                                                    className={classNames({
                                                                        'mobile-filters-prices': true,
                                                                        'mobile-filters-prices--disabled':
                                                                            this.state
                                                                                .ownershipValue !==
                                                                            ownershipType.ownershipValue,
                                                                    })}
                                                                >
                                                                    <label
                                                                        className="mobile-filters-prices__label"
                                                                        htmlFor={
                                                                            ownershipType.filterHeadline
                                                                        }
                                                                    >
                                                                        {
                                                                            ownershipType.filterHeadline
                                                                        }
                                                                    </label>
                                                                    <div className="mobile-filters-prices__select">
                                                                        <div
                                                                            className="mobile-filters-prices__select-value"
                                                                            id={
                                                                                'mobile-filter-price__' +
                                                                                ownershipType.ownershipValue
                                                                            }
                                                                        >
                                                                            {this.state
                                                                                .ownershipValue ===
                                                                            ownershipType.ownershipValue
                                                                                ? this.state
                                                                                      .priceText
                                                                                : 'No Max'}
                                                                        </div>
                                                                        <svg
                                                                            className="icon"
                                                                            width="14"
                                                                            height="7"
                                                                            focusable="false"
                                                                            role="img"
                                                                            aria-hidden="true"
                                                                        >
                                                                            <use xlinkHref="#icon-arrow-down" />
                                                                        </svg>
                                                                        <select
                                                                            id={
                                                                                ownershipType.filterHeadline
                                                                            }
                                                                            onChange={
                                                                                this.onPriceChange
                                                                            }
                                                                            disabled={
                                                                                this.state
                                                                                    .ownershipValue !==
                                                                                ownershipType.ownershipValue
                                                                            }
                                                                        >
                                                                            {ownershipType.prices !==
                                                                            null
                                                                                ? ownershipType.prices.map(
                                                                                      (
                                                                                          value,
                                                                                          key
                                                                                      ) => (
                                                                                          <option
                                                                                              key={nanoId()}
                                                                                              value={
                                                                                                  value.value
                                                                                              }
                                                                                              selected={
                                                                                                  value.value ===
                                                                                                  this
                                                                                                      .state
                                                                                                      .price
                                                                                              }
                                                                                          >
                                                                                              {
                                                                                                  value.key
                                                                                              }
                                                                                          </option>
                                                                                      )
                                                                                  )
                                                                                : ''}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="search-mobile-filters__item">
                                <div className="search-mobile-filters__item-label">
                                    {this.props.tableFilters.bedroomsLabel}
                                </div>
                                <div className="search-mobile-filters__item-content">
                                    <ul className="mobile-filters-checklist">
                                        {this.props.tableFilters.bedrooms.map((bedroom, index) => {
                                            const isSelected =
                                                this.state.bedrooms.indexOf(+bedroom) >= 0;

                                            return (
                                                <li
                                                    className="mobile-filters-checklist__item"
                                                    role="option"
                                                    aria-selected={isSelected}
                                                    key={nanoId()}
                                                >
                                                    <div className="mobile-filters-checkbox">
                                                        <label className="mobile-filters-checkbox__label">
                                                            <input
                                                                type="checkbox"
                                                                id={`bedroom-${index}`}
                                                                name="bedroom"
                                                                className={classNames({
                                                                    'checkbox-bedroom': true,
                                                                    'mobile-filters-checkbox__input': true,
                                                                    'visually-hidden': true,
                                                                    'is-disabled': isSelected,
                                                                })}
                                                                value={bedroom}
                                                                checked={isSelected}
                                                                onChange={this.onCheckValChange}
                                                            />
                                                            <span
                                                                className="mobile-filters-checkbox__placeholder"
                                                                htmlFor={`bedroom-${index}`}
                                                            >
                                                                <span className="mobile-filters-checkbox__text">
                                                                    {bedroom} &nbsp;
                                                                    {bedroom === '1'
                                                                        ? this.props.tableFilters.bedroomsLabel.slice(
                                                                              0,
                                                                              -1
                                                                          )
                                                                        : this.props.tableFilters
                                                                              .bedroomsLabel}
                                                                </span>
                                                                <span className="mobile-filters-checkbox__cross"></span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="filter__buttons">
                                <button
                                    className="button-apply"
                                    type="button"
                                    onClick={this.onApply}
                                >
                                    {this.props.tableFilters.apply}
                                </button>
                                <button
                                    className="button-reset"
                                    type="button"
                                    onClick={this.onResetFilter}
                                >
                                    {this.props.tableFilters.reset}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

FiltersList.propTypes = {
    tableFilters: PropTypes.objectOf(PropTypes.string),
    onApply: PropTypes.func,
    onResetFilter: PropTypes.func,
    isOnMobileViewport: PropTypes.bool,
};

export default withContext(FiltersList, {
    tableFilters: 'tableFilters',
    isOnMobileViewport: 'isOnMobileViewport',
});
