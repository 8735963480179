import React from 'react';
import PropTypes from 'prop-types';
import { withContext } from '../context';
import VacanciesSearchItem from './vacancies-search-item';
import LoadMoreButton from '../../../listing/js/components/load-more-button';

const VISIBLE_ITEMS = 6;

class VacanciesSearchList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visibleItems: VISIBLE_ITEMS
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.items.length !== prevProps.items.length) {
            this.setState({ visibleItems: VISIBLE_ITEMS });
        }
    }

    onLoadMore = () => {
        this.setState({
            visibleItems: this.state.visibleItems + VISIBLE_ITEMS
        });
    };

    render() {
        const { items } = this.props;
        const visibleItems = this.state.visibleItems;
        const hasMoreItems = items.length > visibleItems;

        return (
            <React.Fragment>
                <ul className="vacancies-search__list">
                    {items.slice(0, visibleItems).map((item, index) => (
                        <VacanciesSearchItem key={item.id} item={item} index={index}/>
                    ))}
                </ul>
                {hasMoreItems && (
                    <div className="vacancies-search__load-more">
                        <LoadMoreButton
                            onClick={this.onLoadMore}
                            caption={'Load more results'}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

VacanciesSearchList.propTypes = {
    items: PropTypes.array.isRequired
};

export default withContext(VacanciesSearchList, {
    items: 'items'
});
