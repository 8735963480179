import React from 'react';
import PropTypes from 'prop-types';
import SearchResultsMessage from './search-results-message';
import ZeroSearchResalesBanner from './zero-search-resales-banner';

export default class ZeroSearchResults extends React.PureComponent {
    render() {
        const { html, reSales, filters, locationName } = this.props;
        const isRentFilter = filters.ownershipType === 'rent';
        const reSalesResult = reSales && !isRentFilter
            ? <ZeroSearchResalesBanner reSales={reSales} locationName={locationName} />
            : null;
        return (
            <div className="zero-search-results">
                <div className="zero-search-results__content">
                    <SearchResultsMessage html={html} />
                </div>
                {reSalesResult}
            </div>
        );
    }
}

ZeroSearchResults.propTypes = {
    html: PropTypes.string.isRequired,
    reSales: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    locationName: PropTypes.string
};
