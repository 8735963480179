import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CampaignMobileFilterContext } from '@Components/campaign-property-search/js/context';

const MobileFiltersCheckbox = ({
    isDisabled,
    isChecked,
    text,
    id,
    value,
    config,
    onCheckboxChange
}) => {
    const { filterOptions } = useContext(CampaignMobileFilterContext);
    const optionName = config.name;

    return (
        <div className="campaign-mobile-filters__checkbox">
            <label
                className="campaign-mobile-filters__checkbox-label"
            >
                <input
                    type="checkbox"
                    className={classNames({
                        'campaign-mobile-filters__checkbox-input': true,
                        'visually-hidden': true,
                    })}
                    checked={filterOptions[optionName].includes(value)}
                    name={id}
                    value={value}
                    onChange={(e) => onCheckboxChange(optionName, e.target.value)}
                />
                <span className="campaign-mobile-filters__checkbox-text">{text}</span>
                <span className="campaign-mobile-filters__checkbox-placeholder"></span>
            </label>
        </div>
    );
};

MobileFiltersCheckbox.propTypes = {
    isDisabled: PropTypes.bool,
    isChecked: PropTypes.bool,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    config: PropTypes.array,
    onCheckboxChange: PropTypes.func.isRequired,
};

export default MobileFiltersCheckbox;
