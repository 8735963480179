import React from 'react';
import PropTypes from 'prop-types';

class VacanciesSearchSelect extends React.PureComponent {
    render() {
        const {
            options, value, name, onFiltersApply, label
        } = this.props;
        return (
            <select
                value={value}
                name={name}
                aria-label={label}
                onChange={e => onFiltersApply({ [name]: e.target.value })}
                className="vacancies-search__filter"
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value} disabled={option.isDisabled}>
                        {option.text}
                    </option>
                ))}
            </select>
        );
    }
}

VacanciesSearchSelect.propTypes = {
    onFiltersApply: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default VacanciesSearchSelect;
