import React from 'react';
import PropTypes from 'prop-types';

function RecentlyViewedCard(props) {
    const { article } = props;

    return (
        <>
            {article.title && (
                <div className="recently-viewed-articles-card">

                        <img
                            alt={article.thumbnailImageAlt}
                            className="recently-viewed-articles-card__img"
                            loading="lazy"
                            src={article.thumbnailImageSrc}
                            srcSet={article.thumbnailImageSrcSet}
                        />

                    <div className="recently-viewed-articles-card__content">

                    <a href={article.url}><span>{article.categoryLabel}</span></a>
                            <h4>{article.title}</h4>
                            <p>{article.description}</p>

                    </div>
                </div>
            )}
        </>
    );
}

RecentlyViewedCard.propTypes = {
    article: PropTypes.array,
};

export default RecentlyViewedCard;
