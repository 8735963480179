import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import DevelopmentSearchCard from './development-search-card';

class DevelopmentsSearchItemPopup extends React.PureComponent {
    constructor(props) {
        super(props);

        this.renderElement = document.createElement('div');
        this.markerStateChangeUnsubscribe = null;
    }

    componentDidMount() {
        this.applyMarker();
    }

    componentDidUpdate(prevProps) {
        const { marker } = this.props;

        if (marker !== prevProps.marker) {
            this.applyMarker();
        }
    }

    componentWillUnmount() {
        const { marker } = this.props;
        if (marker) {
            marker.reset();
        }
        if (this.markerStateChangeUnsubscribe) {
            this.markerStateChangeUnsubscribe();
        }
    }

    applyMarker() {
        const { marker, onMarkerStateChange } = this.props;
        if (marker) {
            marker.setPopupElement(this.renderElement);
            this.markerStateChangeUnsubscribe = marker.onStateChange(onMarkerStateChange);
        }
    }

    renderPopup() {
        const { item } = this.props;
        return (
            <div className="map-popup__bubble-anchor">
                <div className="map-popup__bubble-content" data-map-content>1
                    <DevelopmentSearchCard
                        hasBorder={false}
                        isSlim={true}
                        item={item}
                        alwaysVertical={true}
                        gtmSelector="ViewPropertiesPopupButton"
                    />
                </div>
            </div>
        );
    }

    render() {
        return ReactDOM.createPortal(this.renderPopup(), this.renderElement);
    }
}

DevelopmentsSearchItemPopup.propTypes = {
    item: PropTypes.object.isRequired,
    marker: PropTypes.object,
    onMarkerStateChange: PropTypes.func.isRequired
};

export default DevelopmentsSearchItemPopup;
