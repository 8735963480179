import DcBaseComponent from "../../../general/js/dc/dc-base-component";

export default class ThankYouComponent extends DcBaseComponent {
    constructor(...args) {
        super(...args);

        this.imageCarousel = null;
        this.testimonialCarousel = null;
        this.isAutoplayStopped = false;
    }

    static getNamespace() {
        return 'thank-you';
    }

    onInit() {
        this._processFile();
    }

    _processFile() {
        var url = this._getCookie("pdfDownload");
        if (url) {
            this._removeCookie("pdfDownload");

            if(this._isMobileOrTablet()){
                this._openFile(url);
            }
            else{
                this._downloadFile(url);
            }
        }
    }

    _downloadFile(url){
        var xhr = new XMLHttpRequest();
        var filename = url.split("/");
        filename = filename.pop();
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                if (!window.navigator.msSaveOrOpenBlob) {
                    const a = document.createElement("a");
                    a.style.display = "none";
                    const aUrl = window.URL.createObjectURL(xhr.response);
                    a.href = aUrl;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(aUrl);
                } else {
                    window.navigator.msSaveOrOpenBlob(new Blob([xhr.response]), filename);
                }
            } else {
                console.log("The request failed!");
            }
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
    }
    
    _openFile(url){
        setTimeout(function () {
            window.open(url, "_self");
        }, 1000);
    }
    
    _removeCookie(cookieName){
        document.cookie = `${cookieName}=;expires=Thu, 15 Mar 1970 00:00:01 GMT;path=/;`;
    }
    
    _getCookie(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2)
            return parts.pop().split(";").shift();
    } 
    
    _isMobileOrTablet() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /Macintosh/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
}

