import React from 'react';
import ReactDOM from 'react-dom';
import DcBaseComponent from '@General/js/dc/dc-base-component';
import DevelopmentTimeline from './development-timeline.jsx';

export default class DevelopmentTimelineComponent extends DcBaseComponent {
    static getNamespace() {
        return 'development-timeline';
    }

    onInit() {
        this.render();
    }

    onDestroy() {
        ReactDOM.unmountComponentAtNode(this.element);
    }

    render() {
        ReactDOM.render(
            <DevelopmentTimeline
                stages={this.options.steps}
                title={this.options.title}
                showTimelineDates={this.options.showTimelineDates}
            />,
            this.element
        );
    }
}
