import React from 'react';
import PropTypes from 'prop-types';

export default class FacebookFeedPost extends React.PureComponent {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.renderXFBML = this.renderXFBML.bind(this);
        this.postHref = `https://www.facebook.com/${this.props.pageId}/posts/${this.props.postId}`;
    }

    componentDidMount() {
        this.renderXFBML();
    }

    renderXFBML() {
        // https://developers.facebook.com/docs/reference/javascript/FB.XFBML.parse
        window.FB.XFBML.parse(this.wrapperRef.current, this.props.onMount);
    }

    render() {
        return (
            <div className='facebook-feed__post' ref={this.wrapperRef}>
                <div
                    className='fb-post'
                    data-href={this.postHref}
                    data-width={this.props.width}
                    data-show-text={this.props.showText}
                />
            </div>
        );
    }
}

FacebookFeedPost.propTypes = {
    pageId: PropTypes.string.isRequired,
    postId: PropTypes.string.isRequired,
    width: PropTypes.string,
    showText: PropTypes.bool,
    onMount: PropTypes.func
};

FacebookFeedPost.defaultProps = {
    width: 'auto',
    showText: true
};
